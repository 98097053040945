<template>
  <ValidationProvider :name="name" :rules="rules" v-slot="{ errors }" slim>
    <v-text-field
      :error-messages="errors"
      @input="$emit('input', $event)"
      :value="value"
      filled
      :background-color="$colors['f-white-blue-4']"
    ></v-text-field>
  </ValidationProvider>
</template>
<script>
export default {
  props: {
    name: String,
    rules: String,
    value: {},
  },
}
</script>
