<template>
  <div style="background-color: white">
    <div class="contents__wrapper">
      <div class="contents__main">
        <v-row no-gutters>
          <v-col cols="12" class="text-center">
            <p class="ma-0 font-size--23 font-weight-bold line-height--27">
              {{ fNum }}호
              {{ !!fundingDataTitle ? fundingDataTitle : '' }} 리포트
            </p>
          </v-col>
        </v-row>
        <hr />
        <v-row class="pt-8" no-gutters>
          <v-col cols="12">
            <p class="font-size--18 font-weight-bold">
              투자 내역<span v-if="fNum > 2000000" class="font-size--15"
                >(투자일: {{ investmentDate }})</span
              >
            </p>
          </v-col>
          <v-col class="table-col" cols="12">
            <div v-if="isInvestmentLoading" class="text-center">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </div>
            <div v-else class="data-table-wrapper">
              <table class="width-100">
                <thead>
                  <tr>
                    <th width="5%" class="sticky-v-col">번호</th>
                    <th width="35%" style="min-width: 120px">상점명</th>
                    <th style="min-width: 70px">수익률</th>
                    <th style="min-width: 100px">투자액</th>
                    <th style="min-width: 110px">누적<br />상환원금</th>
                    <th style="min-width: 110px">누적<br />상환이자</th>
                    <th style="min-width: 60px">상환<br />상태</th>
                  </tr>
                </thead>
                <template v-if="!!investInfo && investInfo.length > 0">
                  <tbody>
                    <tr v-for="(inv, index) in investInfo" :key="inv.storeName">
                      <td class="sticky-v-col">{{ index + 1 }}</td>
                      <td class="cursor--pointer" @click="openDialog(inv)">
                        {{ inv.storeName }}
                      </td>
                      <td>{{ inv.interestRate | floatFormat }} %</td>
                      <td>{{ inv.investmentAmount | commaFormat }} 원</td>
                      <td>
                        <LoadingText :is-loading="isRepaymentLoading">
                          {{ inv.principal | commaFormat }} 원
                        </LoadingText>
                      </td>
                      <td>
                        <LoadingText :is-loading="isRepaymentLoading">
                          {{ inv.interest | commaFormat }} 원
                        </LoadingText>
                      </td>
                      <td
                        :class="
                          repaymentComplete(inv) && isRepaying(inv)
                            ? 'color--green'
                            : 'color--funda-basic'
                        "
                      >
                        <template v-if="isOverdueResolve(inv)">
                          <span
                            class="cursor--pointer"
                            @click="openOverdueStatus(inv.fnNum)"
                          >
                            연체해소
                            <i class="icon-doc-text-2"></i>
                          </span>
                        </template>

                        <template v-else>
                          <template v-if="repaymentComplete(inv)">
                            상환완료
                          </template>
                          <template v-else>
                            <template v-if="isInsolvent(inv) || isOverdue(inv)">
                              <span
                                class="cursor--pointer"
                                :class="{
                                  'overdue-status__4': isInsolvent(inv),
                                }"
                                @click="openOverdueStatus(inv.fnNum)"
                              >
                                {{ isInsolvent(inv) ? '부도' : '연체' }}
                                <i class="icon-doc-text-2"></i>
                              </span>
                            </template>
                            <template v-else> 상환중 </template>
                          </template>
                        </template>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td class="sticky-v-col">합계</td>
                      <td colspan="2"></td>
                      <td>{{ total.investmentAmount | commaFormat }} 원</td>
                      <td>
                        <LoadingText :is-loading="isRepaymentLoading">
                          {{ total.rpyPrincipal | commaFormat }} 원
                        </LoadingText>
                      </td>
                      <td>
                        <LoadingText :is-loading="isRepaymentLoading">
                          {{ total.rpyInterest | commaFormat }} 원
                        </LoadingText>
                      </td>
                      <td></td>
                    </tr>
                  </tfoot>
                </template>
                <template v-else>
                  <tbody>
                    <tr>
                      <td colspan="7">투자내역이 없습니다.</td>
                    </tr>
                  </tbody>
                </template>
              </table>
            </div>
          </v-col>
        </v-row>

        <v-row class="mt-10" no-gutters>
          <v-col cols="12">
            <p class="font-size--18 font-weight-bold">상환 내역</p>
          </v-col>
          <v-col class="table-col" cols="12">
            <div v-if="isRepaymentLoading" class="text-center">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </div>
            <div v-else class="data-table-wrapper">
              <table class="width-100">
                <thead class="rpy">
                  <tr>
                    <th width="9%" class="sticky-v-col rpy">회차</th>
                    <th width="25%" style="min-width: 85px">
                      상환일<br />(상점명)
                    </th>
                    <th style="min-width: 120px">총액</th>
                    <th style="min-width: 120px">원금</th>
                    <th style="min-width: 80px">이자<br />(세전)</th>
                    <th style="min-width: 80px">세금</th>
                    <th style="min-width: 80px">수수료</th>
                    <th v-if="hasPressFee" style="min-width: 80px">
                      추심<br />수수료
                    </th>
                  </tr>
                </thead>
                <template v-if="!!rpyInfo && rpyInfo.length > 0">
                  <tbody>
                    <template v-for="(rpy, index) in rpyInfo">
                      <tr
                        :key="index"
                        class="cursor--pointer"
                        :class="{ active: rpy.showChild }"
                        @click="rpy.showChild = !rpy.showChild"
                      >
                        <td class="sticky-v-col rpy">
                          <span
                            class="detail-arrow"
                            :class="{ active: rpy.showChild }"
                          ></span>
                          {{ index + 1 }}
                        </td>
                        <td class="font-weight-bold">
                          {{ rpy.repaymentDate }}
                        </td>
                        <td>
                          {{ rpy.totalRepaymentAfterCost | commaFormat }} 원
                        </td>
                        <td>{{ rpy.principal | commaFormat }} 원</td>
                        <td>{{ rpy.interest | commaFormat }} 원</td>
                        <td>{{ rpy.tax | commaFormat }} 원</td>
                        <td>{{ rpy.fee | commaFormat }} 원</td>
                        <td v-if="hasPressFee">
                          {{ rpy.pressFee | commaFormat }} 원
                        </td>
                      </tr>

                      <tr
                        v-show="rpy.showChild"
                        v-for="(child, index2) in rpy.details"
                        :key="`${index}-${index2}_${child.storeName}`"
                        class="active"
                      >
                        <td class="sticky-v-col rpy active">-</td>
                        <td>{{ child.storeName }}</td>
                        <td>
                          {{ child.totalRepaymentAfterCost | commaFormat }} 원
                        </td>
                        <td>{{ child.principal | commaFormat }} 원</td>
                        <td>{{ child.interest | commaFormat }} 원</td>
                        <td>{{ child.tax | commaFormat }} 원</td>
                        <td>{{ child.fee | commaFormat }} 원</td>
                        <td v-if="hasPressFee">
                          {{ child.pressFee | commaFormat }} 원
                        </td>
                      </tr>
                    </template>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td class="sticky-v-col rpy">합계</td>
                      <td></td>
                      <td>{{ total.rpyTotalAfterCost | commaFormat }} 원</td>
                      <td>{{ total.rpyPrincipal | commaFormat }} 원</td>
                      <td>{{ total.rpyInterest | commaFormat }} 원</td>
                      <td>{{ total.rpyTax | commaFormat }} 원</td>
                      <td>{{ total.rpyFee | commaFormat }} 원</td>
                      <td v-if="hasPressFee">
                        {{ total.rpyPressFee | commaFormat }} 원
                      </td>
                    </tr>
                  </tfoot>
                </template>
                <template v-else>
                  <tbody>
                    <tr>
                      <td colspan="7">상환내역이 없습니다.</td>
                    </tr>
                  </tbody>
                </template>
              </table>
            </div>
          </v-col>

          <v-col cols="12">
            <v-dialog v-model="fnStoreRepaymentDialog" width="1100px">
              <v-card v-if="!!selectedFnStore">
                <v-card-title>
                  <span class="headline">
                    [ {{ selectedFnStore.storeName }} ] 상환내역 (투자원금:
                    {{ selectedFnStore.investmentAmount | commaFormat }} 원)
                  </span>
                </v-card-title>
                <v-card-text>
                  <FnCreditorRepayment
                    :key="repaymentKey"
                    :user-idx="userIdx"
                    :funding-data-idx="fNum"
                    :fn-store-idx="selectedFnStore.sNum"
                  ></FnCreditorRepayment>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="green darken-1"
                    text
                    @click="fnStoreRepaymentDialog = false"
                  >
                    닫기
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import FnCreditorRepayment from '@/components/funda/fundanow/FnCreditorRepayment'
import LoadingText from '@/components/common/text/LoadingText'

export default {
  props: {
    userIdx: {
      type: Number,
      default: null,
    },
    fNum: {
      type: Number,
      default: null,
    },
  },
  components: {
    FnCreditorRepayment,
    LoadingText,
  },
  data() {
    return {
      isInvestmentLoading: true,
      isRepaymentLoading: true,
      noticeLoading: true,
      fundingData: null,
      fundingDataTitle: null,
      fStatus: null,
      executeDate: null,
      investmentDate: null,
      useDays: null,
      total: {
        investAmount: 0,
        rpyTotalAfterCost: 0,
        rpyPrincipal: 0,
        rpyInterest: 0,
        rpyTax: 0,
        rpyFee: 0,
        rpyPressFee: 0,
      },
      investInfo: null,
      rpyInfo: null,
      showNotice: false,
      fnStoreRepaymentDialog: false,
      selectedFnStore: null,
      hasPressFee: false,
    }
  },
  computed: {
    repaymentComplete() {
      return (detail) => {
        // 상환완료여부
        return parseInt(detail.repaymentStatus) === 1
      }
    },
    isRepaying(detail) {
      return (detail) => {
        // 상환중
        return parseInt(detail.overdueStatus) < 1
      }
    },
    isOverdueResolve(detail) {
      return (detail) => {
        // 연체해소여부
        return parseInt(detail.overdueStatus) === 1
      }
    },
    isInsolvent(detail) {
      return (detail) => {
        // 부도
        return parseInt(detail.maxOverdueStatus) > 3
      }
    },
    isOverdue(detail) {
      return (detail) => {
        // 연체
        let status = parseInt(detail.overdueStatus)
        return status > 1 && status < 5
      }
    },
    repaymentKey() {
      if (!!!this.selectedFnStore) {
        return null
      }
      return `${this.fNum}-${this.selectedFnStore.sNum}`
    },
  },
  async created() {
    if (!!this.fNum) {
      await this.getFnInvestment()
      this.getFnRepayment()
    }
  },
  methods: {
    async getFnInvestment() {
      let q = `
        data: getFnInvestment(userIdx: ${this.userIdx}, fundingDataIdx: ${this.fNum})
      `

      this.isInvestmentLoading = true

      try {
        let data = await this.$fundaApi.query(gql`{${q}}`)

        let d = JSON.parse(data)
        let result = d.data

        if (result.length < 1) {
          return
        }

        result.forEach((info) => {
          info.principal = 0
          info.interest = 0
          info.pressFee = 0

          this.total.investAmount += info.investAmount
        })

        this.fundingDataTitle = result[0].fundingDataTitle
        this.fStatus = result[0].fStatus
        this.executeDate = result[0].executeDate
        this.investmentDate = result[0].investmentDate
        this.useDays = result[0].useDays

        this.investInfo = result
      } finally {
        this.isInvestmentLoading = false
      }
    },
    async getFnRepayment() {
      if (this.investInfo === null) {
        this.isRepaymentLoading = false
        return
      }

      let q = `
        data: getFnRepayment(userIdx: ${this.userIdx}, fundingDataIdx: ${this.fNum})
      `

      this.isRepaymentLoading = true

      try {
        let data = await this.$fundaApi.query(gql`{${q}}`)

        let d = JSON.parse(data)
        let result = d.data

        this.investInfo.forEach((inv) => {
          const sNum = inv.sNum
          inv.principal = result.summary[sNum].principal
          inv.interest = result.summary[sNum].interest
          inv.pressFee = result.summary[sNum].pressFee

          if (inv.pressFee > 0) {
            this.hasPressFee = true
          }
        })

        result.details.forEach((rpy) => {
          rpy.showChild = false

          this.total.rpyTotalAfterCost += rpy.totalRepaymentAfterCost
          this.total.rpyPrincipal += rpy.principal
          this.total.rpyInterest += rpy.interest
          this.total.rpyTax += rpy.tax
          this.total.rpyFee += rpy.fee
          this.total.rpyPressFee += rpy.pressFee
        })

        this.rpyInfo = result.details
      } finally {
        this.isRepaymentLoading = false
      }
    },
    openDialog(detail) {
      this.selectedFnStore = detail
      this.fnStoreRepaymentDialog = true
    },
    openOverdueStatus(fnIdx) {
      let r = this.$router.resolve({
        name: 'fnOverdueManagement',
        query: { fnIdx: fnIdx },
      })

      window.open(r.href, '_blank')
    },
  },
  filters: {
    floatFormat: function (value) {
      let v = parseFloat(value) * 100
      return parseFloat(v.toFixed(2))
    },
  },
}
</script>

<style lang="scss" scoped>
.contents {
  &__wrapper {
    max-width: 1185px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 60px;
    padding-bottom: 60px;
  }

  &__main {
    background: #fff;
    border: 1px solid #e7edf6;
    border-radius: 3px;
    position: relative;
    padding: 30px 25px 20px 25px;
    margin-bottom: 30px;

    .table-col {
      position: relative;

      @media screen and (max-width: 425px) {
        &::before {
          content: '';
          position: absolute;
          top: 33px;
          width: 15px;
          height: 15px;
          right: 10px;
          z-index: 5;
        }
      }
    }

    .data-table-wrapper {
      position: relative;
      overflow: auto;

      table {
        border-collapse: collapse;
        border-top: 1px solid #333333;
        border-bottom: 1px solid #8a94a2;

        .sticky-v-col {
          &.rpy {
            min-width: 75px;
          }
        }

        thead {
          background-color: #f9fcff;

          &.rpy {
            background-color: white;
            border-bottom: 1px solid #e7edf6;

            th {
              padding-top: 15px !important;
              padding-bottom: 15px !important;
            }
          }

          th {
            margin: 0;
            padding-top: 20px;
            padding-bottom: 20px;
            text-align: center;

            &:not(.rpy).sticky-v-col {
              background-color: #f9fcff !important;
            }
          }
        }

        tbody {
          tr {
            &.active {
              background-color: #f5f5f5;

              .sticky-v-col {
                &.rpy {
                  background-color: #f5f5f5;
                }
              }
            }
          }

          td {
            padding-top: 12px;
            padding-bottom: 12px;
            color: #565a5c;
            text-align: center;
          }
        }

        tfoot {
          border-top: 1px solid #e7edf6;

          td {
            padding-top: 12px;
            padding-bottom: 12px;
            color: #565a5c;
            font-weight: 700;
            text-align: center;
          }
        }
      }
    }
  }
}
</style>
