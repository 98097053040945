<template>
  <div>
    <div v-if="isLoading" class="text-center">
      <p class="mt-1 mb-5 font-size--14">그래프를 불러오는 중입니다.</p>
    </div>
    <div :id="id" class="mx-auto" style="max-width: 750px"></div>
  </div>
</template>
<script>
/**
 *
 * props id
 * 차트 그릴때 사용
 * new window.google.visualization.LineChart(document.getElementById(this.id))
 *
 */
/* eslint-disable */
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    settings: {
      type: Array,
      required: true,
    },
    rawData: {
      type: Array,
      required: true
    },
    options: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      isLoading: false,
    }
  },
  created() {
    
    this.drawChart()
  },
  methods: {
    async drawChart() {
      this.isLoading = true
      window.google.charts.load('current', { packages: ['corechart'] })
      await window.google.charts.setOnLoadCallback(this.drawStacked)
      this.isLoading = false
    },
    drawStacked() {
      let data = new window.google.visualization.DataTable()

      data.addColumn('string', this.settings[0])
      data.addColumn('number', this.settings[1])
      data.addColumn('number', this.settings[2])
      data.addRows(this.rawData)

      let materialChart = new window.google.visualization.LineChart(
        document.getElementById(this.id)
      )
      materialChart.draw(data, this.options)
    },
  },
}
</script>
