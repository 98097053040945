import client from '../client'

export default {
  getCodes() {
    return client.call({
      url: '/codes',
      method: 'GET',
    })
  },
}
