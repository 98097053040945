<template>
  <v-dialog v-model="dialog" width="700" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="title" color="primary" large block v-bind="attrs" v-on="on">
        연체율 공시 등록
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="py-1 headline f-light-grey">
        연체율 공시 등록
        <v-spacer></v-spacer>
        <v-btn
          color="black"
          text
          icon
          x-large
          :disabled="isLoading"
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <OverdueAmountForm
          v-if="dialog"
          :key="dialog"
          @cancel="close"
          @success="success"
          @loading="isLoading = $event"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import OverdueAmountForm from './OverdueAmountForm.vue'
export default {
  components: { OverdueAmountForm },
  data() {
    return {
      dialog: false,
      isLoading: false,
    }
  },
  methods: {
    close() {
      console.log('cancel')
      this.isLoading = false
      this.dialog = false
    },
    success() {
      this.close()
      this.$emit('success')
    },
  },
}
</script>
