<template>
  <div>
    <v-row v-if="!!userInfo && !!userInfo.userForcedWithdraw" no-gutters>
      <v-col cols="12">
        <p class="my-2 font-size--15 color--black">
          {{ userInfo.name }} 님은
          <br />
          강제출금 대상입니다.
        </p>

        <p class="my-1 color--black">
          메모:
          {{ userInfo.userForcedWithdraw.memo }}
        </p>
        <p class="my-1 color--black">
          등록일시:
          {{ userInfo.userForcedWithdraw.createTime | datetimeFormat }}
        </p>

        <hr class="hr-solid" />
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col cols="12">
        <ul>
          <li class="my-1 font-size--15 font-weight-bold">
            고객으로부터 받은 출금계좌 정보를 입력한 뒤 강제출금 설정을 할 수
            있습니다.
          </li>
          <li class="my-1 font-size--15 font-weight-bold">
            기등록된 출금계좌와 일치하는지 다시 한 번 확인해주세요.
          </li>
          <li class="my-1 font-size--15 font-weight-bold">
            강제출금 대상에서 제외하려면 문의바랍니다.
          </li>
        </ul>
      </v-col>
    </v-row>

    <v-row class="mt-5" no-gutters>
      <v-col cols="12">
        <p class="my-1 font-size--14">기등록된 출금계좌 정보</p>
      </v-col>

      <v-col cols="12">
        <v-card min-height="70" class="pa-3" outlined>
          <template v-if="!!userInfo && userInfo.bankAccount">
            <v-row no-gutters>
              <v-col cols="4">
                <label class="body-1 pa-0 ma-0 mr-5"> 은행 / 예금주: </label>
              </v-col>
              <v-col cols="8">
                <p class="body-1 pa-0 ma-0">
                  {{ userInfo.bankAccount.bank }}
                  /
                  {{ userInfo.bankAccount.accountHolder }}
                </p>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="4">
                <label class="body-1 pa-0 ma-0 mr-5"> 계좌번호: </label>
              </v-col>
              <v-col cols="8">
                <p class="body-1 pa-0 ma-0">
                  {{ userInfo.bankAccount.accountWoHyphen }}
                </p>
              </v-col>
            </v-row>
          </template>
          <template v-else>
            <v-row no-gutters>
              <v-col cols="12">
                <p class="body-1 pa-0 ma-0">기등록된 출금계좌가 없습니다.</p>
              </v-col>
            </v-row>
          </template>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mt-2" no-gutters>
      <v-col cols="3">
        <BankSelect
          v-model="bankCode"
          :bankCode.sync="bankCode"
          :isLoading="isLoading"
        />
      </v-col>
      <v-col cols="7">
        <v-text-field
          label="계좌 번호"
          v-model.trim="accountNo"
          :disabled="isLoading"
          autofocus
          outlined
          hide-details
        />
      </v-col>

      <v-col cols="2">
        <AccountInfoButton
          :bankCode="bankCode"
          :accountNo="accountNo"
          :realAccountName.sync="realAccountName"
          class="btn__account-info"
        />
      </v-col>

      <p v-if="realAccountName" class="primary--text my-1">
        조회된 예금주명: {{ realAccountName }}
      </p>
    </v-row>

    <v-row no-gutters>
      <v-col cols="12" class="mt-2 py-3">
        <v-textarea
          v-model="memo"
          label="특이사항 및 메모"
          style="width: 50%; margin: 0 auto"
          rows="5"
          outlined
          hide-details
        ></v-textarea>
      </v-col>
      <v-col cols="12" class="py-2 text-center">
        <v-btn
          :color="$colors['f-dark-grey']"
          class="btn__register"
          depressed
          width="50%"
          height="56px"
          :loading="isLoading"
          :disabled="!!!realAccountName || isLoading"
          @click="registerForcedWithdraw()"
        >
          강제출금 등록
        </v-btn>
      </v-col>

      <v-col cols="12">
        <ul style="width: 50%; margin: 0 auto">
          <li v-if="isValidAccountName === false" class="error--text my-1">
            예금주명이 일치하지 않습니다.
          </li>
          <li v-if="isValidAccountNo === false" class="error--text my-1">
            계좌번호가 일치하지 않습니다.
          </li>
        </ul>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import BankSelect from '@/components/payment/common/select/BankSelect.vue'
import AccountInfoButton from '@/components/payment/common/button/AccountInfoButton.vue'

export default {
  components: {
    BankSelect,
    AccountInfoButton,
  },
  props: {
    userIdx: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      getDataLoading: false,
      userInfo: null,
      memo: null,
      bankCode: null,
      accountNo: null,
      realAccountName: null,
    }
  },
  computed: {
    isValidAccountName() {
      if (!!!this.userInfo || !!!this.realAccountName) {
        return true
      }
      return this.userInfo?.bankAccount?.accountHolder === this.realAccountName
    },
    isValidAccountNo() {
      if (!!!this.userInfo || !!!this.accountNo) {
        return true
      }
      return this.userInfo?.bankAccount?.accountWoHyphen === this.accountNo
    },
  },
  watch: {
    accountNo() {
      this.realAccountName = null
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      let q = `
        data: getUserInfo(userIdx: ${this.userIdx}) {
          id
          name
          email
          userPhone
          userBirthdate
          registerDate
          investmentLimitType
          affiliateCode
          memo

          bankAccount{
            accountHolder
            bank
            accountWoHyphen
          }

          userForcedWithdraw {
            idx
            memo
            createTime
          }
        }
      `

      this.getDataLoading = true

      try {
        let data = await this.$fundaApi.query(gql`{${q}}`)
        this.userInfo = data
      } finally {
        this.getDataLoading = false
      }
    },
    async registerForcedWithdraw() {
      if (!!!this.userInfo) {
        return
      }

      if (!!this.userInfo.userForcedWithdraw) {
        this.$swal.basic.alert('이미 강제출금 대상으로 등록되어 있습니다')
        return
      }

      let confMsg = `${this.userInfo.name} 유저를 강제출금 대상으로 등록합니다.`

      if (this.isValidAccountName !== true || this.isValidAccountNo !== true) {
        confMsg =
          '예금주명 혹은 계좌번호가 일치하지 않습니다. 계속 진행하시겠습니까?'
      }

      let conf = await this.$swal.basic.confirm(confMsg)

      if (conf.isConfirmed !== true) {
        return
      }

      this.isLoading = true

      let q = `
        registerUserForcedWithdraw(userIdx: ${this.userIdx},
          account: {
            bankCode: "${this.bankCode}"
            accountNo: "${this.accountNo}"
            accountName: "${this.realAccountName}"
          }
          ${!!this.memo ? ', memo: "' + this.memo + '"' : ''}
        ) {
          idx
          userIdx
          paymentUserIdx
          memo
          createTime
        }
      `

      try {
        let data = await this.$fundaApi.mutation(gql`{${q}}`)
        this.userInfo.userForcedWithdraw = data
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.btn__account-info {
  max-height: 56px;
  height: 100% !important;
}

.btn__register:not(.v-btn--disabled) {
  color: white !important;
}
</style>
