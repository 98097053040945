<template>
  <PageSection title="출금계좌 정보" :isLoading="isLoading">
    <template slot="content" v-if="waccount != null">
      <v-simple-table>
        <template v-slot:default>
          <tbody>
            <template v-if="waccount && waccount.bank">
              <tr>
                <td>index</td>
                <td>{{ waccount.id }}</td>
              </tr>
              <tr>
                <td>계좌주명</td>
                <td>{{ waccount.holderName }}</td>
              </tr>
              <tr>
                <td>은행</td>
                <td>{{ waccount.bank | bankCodeName }}</td>
              </tr>
              <tr>
                <td>계좌번호</td>
                <td>{{ waccount.accountNumber }}</td>
              </tr>
              <tr>
                <td>생성 시간</td>
                <td>{{ waccount.created | moment('YYYY-MM-DD HH:mm:ss') }}</td>
              </tr>
              <tr>
                <td>수정 시간</td>
                <td>{{ waccount.modified | moment('YYYY-MM-DD HH:mm:ss') }}</td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td class="py-5 text-center subtitle-1 grey--text">
                  아직 등록된 입금용 주계좌가 없습니다.
                </td>
              </tr>
            </template>
          </tbody>
        </template>
      </v-simple-table>
      <div v-if="paymentUserIdx && withdrawalAccountForViewHistories">
        <WithdrawAccountHistoriesDialogButton
          :paymentUserIdx="paymentUserIdx"
          :withdrawAccount="withdrawalAccountForViewHistories"
        />
      </div>
    </template>
  </PageSection>
</template>
<script>
import WithdrawAccountHistoriesDialogButton from '@/components/funda/user/withdraw/WithdrawAccountHistoriesDialogButton.vue'
export default {
  components: { WithdrawAccountHistoriesDialogButton },
  props: {
    userId: {
      type: Number,
      required: true,
    },
    paymentUserIdx: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      waccount: null,
      isLoading: false,
      withdrawalAccountForViewHistories: null,
    }
  },
  async created() {
    try {
      this.isLoading = true

      const { data } = await this.$fundaNowApi.getUserWithdrawalaccount(
        this.userId
      )
      this.waccount = data
      this.withdrawalAccountForViewHistories = {
        bank: data.bank,
        accountWoHyphen: data.accountNumber,
        accountHolder: data.holderName,
      }
    } finally {
      this.isLoading = false
    }
  },
}
</script>
<style lang="scss" scoped>
tr > td:last-child {
  word-break: break-all;
}
</style>
