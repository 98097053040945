<template>
  <div>
    <PageHeader title="고객 상세 정보" />
    <div v-if="userInfo === null">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <div v-else class="pb-5">
      <v-row no-gutters>
        <v-col cols="12" class="px-2 py-4">
          <v-btn
            color="#393939"
            class="mr-3"
            dark
            depressed
            @click="openRepayments()"
          >
            이자지급내역
          </v-btn>

          <v-btn color="#393939" dark depressed> SMS </v-btn>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="12" md="4" class="px-2">
          <v-card min-height="810" class="pa-5" outlined>
            <v-row class="pb-4" no-gutters>
              <v-col cols="6" class="d-flex align-center">
                <h3 class="ma-0">기본 정보</h3>
              </v-col>
              <v-col cols="6" class="text-right">
                <v-btn color="#393939" @click="saveUser()" dark>
                  기본 정보 저장
                </v-btn>
              </v-col>
            </v-row>

            <v-row
              v-for="normal in userNormalInfo"
              :key="normal.column"
              no-gutters
            >
              <v-col cols="4">
                <v-subheader class="subtitle-1 black--text pa-0">
                  {{ normal.label }}
                </v-subheader>
              </v-col>

              <v-col cols="8">
                <UserField
                  v-model="userInfo[normal.column]"
                  :field="normal.field"
                  :disabled="normal.disabled"
                  :filter="normal.filter"
                  :hint="normal.hint"
                  :items="selector[normal.select]"
                ></UserField>
              </v-col>
            </v-row>

            <hr class="hr-solid" />

            <v-row class="my-5" no-gutters>
              <v-col cols="12" class="d-flex align-center">
                <h3 class="ma-0">부가 정보</h3>
              </v-col>
            </v-row>

            <v-row v-for="addi in userAddiInfo" :key="addi.column" no-gutters>
              <v-col cols="4">
                <v-subheader class="subtitle-1 black--text pa-0">
                  {{ addi.label }}
                </v-subheader>
              </v-col>

              <v-col cols="8" @click="fieldEvent(addi)">
                <UserField
                  :value="getModelValue(userInfo[addi.column], addi.key)"
                  :field="addi.field"
                  :disabled="addi.disabled"
                  :filter="addi.filter"
                  :hint="addi.hint"
                ></UserField>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="4">
                <v-subheader class="subtitle-1 black--text pa-0">
                  유저등급
                </v-subheader>
              </v-col>
              <v-col cols="8" class="d-flex align-center pa-0">
                <span
                  v-for="item in userInfo.userFundaClasses"
                  :key="item.fundaClass.className"
                  class="font-weight-bold mr-1"
                >
                  {{ item.fundaClass.className }},
                </span>
              </v-col>
            </v-row>
          </v-card>

          <v-row no-gutters>
            <v-col cols="12">
              <v-dialog v-model="recommender.dialog" width="1300px">
                <v-card>
                  <v-card-title>
                    <span class="headline">
                      {{ recommender.title }}
                    </span>
                  </v-card-title>
                  <v-card-text>
                    <keep-alive>
                      <ListUsedCode
                        :key="recommender.code"
                        :recommender-code="recommender.code"
                      ></ListUsedCode>
                    </keep-alive>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="green darken-1"
                      text
                      @click="recommender.dialog = false"
                    >
                      닫기
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" md="4" class="px-2">
          <v-card min-height="810" class="pa-5" outlined>
            <v-row no-gutters>
              <v-col cols="2">
                <v-subheader class="subtitle-1 black--text pa-0">
                  메모
                </v-subheader>
              </v-col>
              <v-col cols="10">
                <v-textarea
                  v-model="userInfo.memo"
                  class="flex-grow-0"
                  rows="3"
                  style="max-width: 400px; width: 100%"
                  hint="기본 정보 저장을 실행하면 저장됩니다."
                  persistent-hint
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>

            <hr class="hr-solid" />

            <v-row class="pb-4" no-gutters>
              <v-col cols="12">
                <h3 class="ma-0">투자 정보</h3>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12">
                <v-row class="mb-3" no-gutters>
                  <v-col cols="12">
                    <v-dialog v-model="investBoardDialog" width="1400px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="#393939"
                          width="100%"
                          x-large
                          dark
                          v-bind="attrs"
                          v-on="on"
                        >
                          투자현황(고객화면)
                        </v-btn>
                      </template>
                      <v-card class="card__my-funda">
                        <v-card-text class="pt-10 pb-0">
                          <MyFunda
                            :user-idx="userIdx"
                            :user-info="userInfo"
                          ></MyFunda>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="green darken-1"
                            text
                            @click="investBoardDialog = false"
                          >
                            닫기
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-col>
                  <v-col cols="12" class="my-5">
                    <DisposalDetailButton :userIdx="userIdx" />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <hr class="hr-solid mx-auto" style="width: 70%" />

            <v-row no-gutters>
              <v-col cols="12">
                <v-row justify="center" no-gutters>
                  <v-col cols="12" class="my-3">
                    <v-row no-gutters>
                      <v-col cols="6" class="d-flex align-center">
                        <span class="subtitle-1 font-weight-bold">
                          자동투자정보
                        </span>
                      </v-col>
                      <v-col cols="6" class="text-right">
                        <v-dialog v-model="historyDialog" width="1200px">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              color="#393939"
                              dark
                              v-bind="attrs"
                              v-on="on"
                            >
                              변경기록
                            </v-btn>
                          </template>
                          <v-card>
                            <v-card-title>
                              <span class="headline">
                                {{ userInfo.id }} 자동투자 변경기록
                              </span>
                            </v-card-title>
                            <v-card-text>
                              <AutoInvestHistory
                                :user-idx="userIdx"
                              ></AutoInvestHistory>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                color="green darken-1"
                                text
                                @click="historyDialog = false"
                              >
                                닫기
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row no-gutters>
                  <v-col cols="12">
                    <v-card
                      v-if="autoInvestInfo.data"
                      min-height="270"
                      class="pa-3"
                      outlined
                    >
                      <template v-if="autoInvestInfo.loading">
                        <v-row no-gutters>
                          <v-col cols="12" sm="12" md="12">
                            <v-progress-circular
                              indeterminate
                              color="primary"
                            ></v-progress-circular>
                          </v-col>
                        </v-row>
                      </template>

                      <template v-else>
                        <v-row no-gutters>
                          <v-card-title class="pa-0">
                            <v-col cols="12" md="12" class="pa-0">
                              펀다나우
                              <span class="red--text mx-1">
                                {{
                                  autoInvestInfo.data['isEnabled']
                                    ? '활성화'
                                    : '비활성화'
                                }}
                              </span>
                              상태입니다.
                            </v-col>
                          </v-card-title>
                        </v-row>

                        <v-row
                          v-if="!!autoInvestInfo.data['isEnabled']"
                          no-gutters
                        >
                          <v-divider class="ml-8" inset></v-divider>

                          <v-col cols="12" class="mb-2">
                            <v-row no-gutters>
                              <v-col cols="12">
                                <span class="subtitle-1 font-weight-bold"
                                  >펀다나우 자동투자</span
                                >
                              </v-col>
                            </v-row>
                          </v-col>

                          <v-col cols="6">
                            <v-row no-gutters>
                              <v-col cols="6">
                                <v-subheader
                                  class="subtitle-2 pa-0 black--text"
                                >
                                  순번(idx)
                                </v-subheader>
                              </v-col>
                              <v-col cols="6">
                                <v-subheader>
                                  {{ autoInvestInfo.data['idx'] }}
                                </v-subheader>
                              </v-col>
                            </v-row>
                          </v-col>

                          <v-col cols="6">
                            <v-row no-gutters>
                              <v-col cols="6">
                                <v-subheader
                                  class="subtitle-2 pa-0 black--text"
                                >
                                  채권당 <br />
                                  상점당
                                </v-subheader>
                              </v-col>
                              <v-col cols="6">
                                <v-subheader>
                                  {{
                                    autoInvestInfo.data['totalAmount']
                                      | commaFormat
                                  }}
                                  <br />
                                  {{
                                    autoInvestInfo.data['maxAmountPerProduct']
                                      | commaFormat
                                  }}
                                </v-subheader>
                              </v-col>
                            </v-row>
                          </v-col>

                          <v-col cols="6">
                            <v-row no-gutters>
                              <v-col cols="6">
                                <v-subheader
                                  class="subtitle-2 pa-0 black--text"
                                >
                                  타입
                                </v-subheader>
                              </v-col>
                              <v-col cols="6">
                                <v-subheader>
                                  {{
                                    autoInvestInfo.data['isDiversified']
                                      ? '완전분산'
                                      : '금액선택'
                                  }}
                                </v-subheader>
                              </v-col>
                            </v-row>
                          </v-col>

                          <v-col cols="6"> </v-col>

                          <v-col cols="12">
                            <v-row no-gutters>
                              <v-col cols="3">
                                <v-subheader
                                  class="subtitle-2 pa-0 black--text"
                                >
                                  상품유형
                                </v-subheader>
                              </v-col>
                              <v-col cols="9">
                                <v-subheader>
                                  <template
                                    v-for="inv in autoInvestInfo.autoInvestmentTypes"
                                  >
                                    {{ inv.autoInvestmentTypeLabel }},
                                  </template>
                                </v-subheader>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </template>
                    </v-card>
                    <v-card
                      v-else
                      min-height="270"
                      class="d-flex justify-center align-center pa-3"
                      outlined
                    >
                      <v-btn
                        color="#393939"
                        class="white--text"
                        @click="getAutoInvest()"
                        depressed
                        x-large
                      >
                        정보 가져오기
                      </v-btn>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-col>

        <v-col cols="12" md="4" class="px-2">
          <v-card min-height="810" class="pa-5" outlined>
            <v-row class="pb-4" no-gutters>
              <v-col cols="12">
                <h3 class="ma-0">기타</h3>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="4">
                <v-subheader
                  class="subtitle-1 black--text font-weight-bold pa-0"
                >
                  강제출금
                </v-subheader>
              </v-col>

              <v-col cols="8">
                <v-switch
                  :input-value="!!userInfo.userForcedWithdraw"
                  hint="ON일 경우 본인인증 불가"
                  persistent-hint
                  disabled
                  dense
                  class="ma-2"
                ></v-switch>
              </v-col>

              <v-col cols="12">
                <ForcedWithdrawButton :userIdx="userIdx" />
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12">
                <v-subheader
                  class="subtitle-1 black--text font-weight-bold pa-0"
                >
                  회원탈퇴
                </v-subheader>
              </v-col>

              <v-col cols="12">
                <RemoveUserButton :userIdx="userIdx" />
              </v-col>
            </v-row>

            <hr class="hr-solid" />

            <v-row class="mb-5" no-gutters>
              <v-col cols="12">
                <span
                  class="
                    subtitle-1
                    font-weight-bold
                    d-flex
                    align-center
                    justify-space-between
                    my-3
                  "
                >
                  상환계좌
                  <WithdrawAccountHistoriesDialogButton
                    v-if="
                      !!userInfo && userInfo.bankAccount && userInfo.paymentUser
                    "
                    :paymentUserIdx="Number(userInfo.paymentUser.idx)"
                    :withdrawAccount="userInfo.bankAccount"
                    :userInfoLoading="getDataLoading"
                    @refresh:userInfo="fetchData"
                  />
                </span>
                <v-card min-height="70" class="pa-3" outlined>
                  <template v-if="!!userInfo && userInfo.bankAccount">
                    <v-row no-gutters>
                      <v-col cols="4">
                        <label class="body-1 pa-0 ma-0 mr-5">
                          은행 / 예금주:
                        </label>
                      </v-col>
                      <v-col cols="8">
                        <p class="body-1 pa-0 ma-0">
                          {{ userInfo.bankAccount.bank }}
                          /
                          {{ userInfo.bankAccount.accountHolder }}
                        </p>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col cols="4">
                        <label class="body-1 pa-0 ma-0 mr-5"> 계좌번호: </label>
                      </v-col>
                      <v-col cols="8">
                        <p class="body-1 pa-0 ma-0">
                          {{ userInfo.bankAccount.accountWoHyphen }}
                        </p>
                      </v-col>
                    </v-row>
                  </template>
                </v-card>
              </v-col>
            </v-row>

            <v-row class="mb-5" no-gutters>
              <v-col cols="12">
                <span class="subtitle-1 font-weight-bold d-block mb-3"
                  >가상계좌</span
                >
                <v-card min-height="70" class="pa-3" outlined>
                  <template v-if="!!userInfo && userInfo.virtualAccount">
                    <v-row no-gutters>
                      <v-col cols="4">
                        <label class="body-1 pa-0 ma-0 mr-5">
                          은행 / 예금주:
                        </label>
                      </v-col>
                      <v-col cols="8">
                        <p class="body-1 pa-0 ma-0">
                          {{ userInfo.virtualAccount.bank }}
                          /
                          {{ userInfo.virtualAccount.accountHolder }}
                        </p>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col cols="4">
                        <label class="body-1 pa-0 ma-0 mr-5"> 계좌번호: </label>
                      </v-col>
                      <v-col cols="8">
                        <p class="body-1 pa-0 ma-0">
                          {{ userInfo.virtualAccount.account }}
                        </p>
                      </v-col>
                    </v-row>
                    <v-row no-gutters v-if="welcomeVirtualAccount">
                      <v-col cols="4">
                        <label class="body-1 pa-0 ma-0 mr-5">
                          웰컴 guid:
                        </label>
                      </v-col>
                      <v-col cols="8">
                        <p class="body-1 pa-0 ma-0">
                          <WelcomeGuid
                            :userIdx="welcomeVirtualAccount.paymentUserIdx"
                            :userVirtualAccountIdx="welcomeVirtualAccount.idx"
                          />
                        </p>
                      </v-col>
                    </v-row>
                  </template>
                </v-card>

                <v-divider class="my-5"></v-divider>

                <v-row class="ma-0" no-gutters>
                  <v-col cols="12">
                    <v-dialog v-model="balanceDepositDialog" width="1200px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="#393939"
                          class="white--text"
                          depressed
                          x-large
                          width="100%"
                          v-bind="attrs"
                          v-on="on"
                        >
                          입출금 내역
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title>
                          <span class="headline">
                            {{ userInfo.id }} 입출금 내역
                          </span>
                        </v-card-title>
                        <v-card-text>
                          <BalanceDepositTransaction
                            :user-idx="parseInt(userIdx)"
                          ></BalanceDepositTransaction>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="green darken-1"
                            text
                            @click="balanceDepositDialog = false"
                          >
                            닫기
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-col>
                </v-row>

                <v-row class="my-5" no-gutters>
                  <v-col cols="12">
                    <v-dialog v-model="PointDepositDialog" width="1200px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="#393939"
                          class="white--text"
                          depressed
                          x-large
                          width="100%"
                          v-bind="attrs"
                          v-on="on"
                        >
                          포인트 내역
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title>
                          <span class="headline">
                            {{ userInfo.id }} 포인트 내역
                          </span>
                        </v-card-title>
                        <v-card-text>
                          <BalanceDepositPoint
                            :user-idx="parseInt(userIdx)"
                          ></BalanceDepositPoint>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="green darken-1"
                            text
                            @click="PointDepositDialog = false"
                          >
                            닫기
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-col>
                </v-row>

                <v-row
                  class="my-5"
                  no-gutters
                  v-if="
                    !!userInfo && userInfo.bankAccount && userInfo.paymentUser
                  "
                >
                  <v-col cols="12">
                    <WithdrawalsDialogButton
                      :paymentUserIdx="Number(userInfo.paymentUser.idx)"
                    />
                  </v-col>
                </v-row>

                <v-row class="my-5" no-gutters v-if="!!userInfo">
                  <v-col cols="12">
                    <UserAuthDetailsButton :userIdx="userIdx" />
                  </v-col>
                </v-row>

                <v-row
                  class="my-5"
                  no-gutters
                  v-if="
                    !!userInfo && userInfo.bankAccount && userInfo.paymentUser
                  "
                >
                  <payment-user-by-funda-user
                    :paymentUserIdx="userInfo.paymentUser.idx"
                  />
                </v-row>

                <v-divider class="my-5"></v-divider>

                <v-row no-gutters>
                  <v-col cols="12">
                    <span class="subtitle-1 font-weight-bold">참여 이벤트</span>
                  </v-col>

                  <v-col cols="12">
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-center">이벤트명</th>
                            <th class="text-center">시작일</th>
                            <th class="text-center">종료일</th>
                          </tr>
                        </thead>
                        <tbody>
                          <template v-if="eventInvests.length > 0">
                            <tr
                              v-for="e in eventInvests"
                              :key="e.idx"
                              :class="{ 'event--active': e.onEventTime }"
                            >
                              <td class="text-center">
                                {{ e.eventName }}
                              </td>
                              <td class="text-center">
                                {{ e.startDatetime | datetimeFormat }}
                              </td>
                              <td class="text-center">
                                {{ e.endDatetime | datetimeFormat }}
                              </td>
                            </tr>
                          </template>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="mt-5" no-gutters>
        <v-col cols="12" class="px-2">
          <v-card class="pa-5" outlined>
            <v-row class="pb-4" no-gutters>
              <v-col cols="2" class="d-flex align-center">
                <h3 class="ma-0">투자내역</h3>
              </v-col>
              <v-col cols="10">
                <v-progress-circular
                  v-if="gridLoading"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
                <v-btn
                  :loading="gridLoading"
                  :disabled="gridLoading"
                  color="#393939"
                  dark
                  @click="getFundingInfoByUser()"
                >
                  투자내역 가져오기
                </v-btn>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12">
                <v-dialog v-model="scheduleAndRepaymentDialog" width="1300px">
                  <v-card>
                    <v-card-title>
                      <span class="headline">
                        {{ userInfo.id }} {{ fNum }}호 스케줄/상환내역
                      </span>
                    </v-card-title>
                    <v-card-text>
                      <ScheduleDetail
                        :key="fNum"
                        :user-idx="parseInt(userIdx)"
                        :f-num="parseInt(fNum)"
                      ></ScheduleDetail>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="green darken-1"
                        text
                        @click="scheduleAndRepaymentDialog = false"
                      >
                        닫기
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
              <v-col cols="12">
                <v-dialog v-model="fnReportDialog" width="1300px">
                  <v-card>
                    <v-card-title>
                      <span class="headline">
                        {{ userInfo.id }} 펀다나우 투자/상환내역
                      </span>
                    </v-card-title>
                    <v-card-text>
                      <FundaNowDetail
                        :key="fNum"
                        :user-idx="parseInt(userIdx)"
                        :f-num="parseInt(fNum)"
                      ></FundaNowDetail>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="green darken-1"
                        text
                        @click="fnReportDialog = false"
                      >
                        닫기
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
              <v-col cols="12">
                <keep-alive>
                  <TuiGrid
                    :columns="gridProps.columns"
                    :data="gridProps.data"
                    :pageOptions="gridProps.pageOptions"
                    :clickEvent="gridProps.clickEvent"
                    @selectFNum="openFnReport"
                  ></TuiGrid>
                </keep-alive>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="my-5" no-gutters>
        <v-col cols="12">
          <v-dialog v-model="documentDialog" width="1500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="#393939"
                class="white--text"
                depressed
                x-large
                width="100%"
                v-bind="attrs"
                v-on="on"
              >
                신분증/사업자등록증 보기
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">
                  {{ userInfo.id }} 신분증/사업자등록증
                </span>
              </v-card-title>
              <v-card-text>
                <v-row no-gutters>
                  <v-col cols="6" sm="12" md="6" style="pr-2">
                    <S3FileReader :awsS3FileIdx="selectedFileIdx" />
                  </v-col>
                  <v-col cols="6" sm="12" md="6" class="pl-2" style="border-left: 1px solid #eee">
                    <v-list>
                      <v-list-group no-action :value="true">
                        <template v-slot:activator>
                          <v-list-item-content>
                            <v-list-item-title>
                              <h2 class="black--text text-center">신분증 정보</h2>
                            </v-list-item-title>
                          </v-list-item-content>
                        </template>
                        <v-list-item class="d-block pa-0">
                          <v-row no-gutters>
                            <v-col cols="3">
                              <v-subheader class="subtitle-1 black--text pa-0">
                                등록 신분증:
                              </v-subheader>
                            </v-col>
                            <v-col cols="9">
                              <v-subheader class="pa-0">
                                <h3
                                  class="color--funda-basic cursor--pointer"
                                  @click="selectedFileIdx = userValidation && userValidation.userCdds && userValidation.userCdds[0] && userValidation.userCdds[0].userDoc ? userValidation.userCdds[0].userDoc.awsS3FileIdx : null"
                                >
                                  {{ userValidation && userValidation.userCdds && userValidation.userCdds[0] ? (userValidation.userCdds[0].type === 'RESIDENT' ? '주민등록증' : '운전면허증') : '' }}
                                </h3>
                              </v-subheader>
                            </v-col>
                          </v-row>
                        </v-list-item>
                      </v-list-group>
                    </v-list>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="green darken-1"
                  text
                  @click="documentDialog = false"
                >
                  닫기
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { datetimeParser } from '@/filter/date'

import AuthService from '@/service/auth/AuthService'
import AutoInvestHistory from '@/components/autoinvest/AutoInvestHistory.vue'
import MyFunda from '@/components/funda/myfunda/Index.vue'
import UserField from '@/components/funda/user/UserField.vue'
import TuiGrid from '@/components/toast/Grid.vue'
import ListUsedCode from '@/components/funda/user/ListUsedCode.vue'
import ForcedWithdrawButton from '@/components/funda/user/withdraw/ForcedWithdrawButton.vue'
import ScheduleDetail from '@/components/funda/myfunda/schedule-detail/ScheduleDetail.vue'
import FundaNowDetail from '@/components/funda/myfunda/schedule-detail/FundaNowDetail.vue'
import BalanceDepositTransaction from '@/components/funda/myfunda/balance/BalanceDepositTransaction.vue'
import BalanceDepositPoint from '@/components/funda/myfunda/balance/BalanceDepositPoint.vue'
import WithdrawAccountHistoriesDialogButton from '@/components/funda/user/withdraw/WithdrawAccountHistoriesDialogButton.vue'
import WithdrawalsDialogButton from '@/components/funda/user/withdraw/WithdrawalsDialogButton.vue'
import PaymentUserByFundaUser from '@/components/payment/user/PaymentUserByFundaUser.vue'
import UserAuthDetailsButton from '@/components/funda/user/auth/UserAuthDetailsButton.vue'
import WelcomeGuid from '@/components/payment/user/WelcomeGuid.vue'
import DisposalDetailButton from '@/components/funda/myfunda/schedule-detail/DisposalDetailButton.vue'
import RemoveUserButton from '@/components/funda/user/auth/RemoveUserButton.vue'
import S3FileReader from '@/components/common/reader/S3FileReader.vue'

export default {
  props: {
    userIdx: {
      type: String,
      default: null,
    },
  },
  components: {
    AutoInvestHistory,
    MyFunda,
    UserField,
    TuiGrid,
    ListUsedCode,
    ForcedWithdrawButton,
    ScheduleDetail,
    FundaNowDetail,
    BalanceDepositTransaction,
    BalanceDepositPoint,
    WithdrawAccountHistoriesDialogButton,
    WithdrawalsDialogButton,
    PaymentUserByFundaUser,
    UserAuthDetailsButton,
    WelcomeGuid,
    DisposalDetailButton,
    RemoveUserButton,
    S3FileReader,
  },
  data() {
    return {
      getDataLoading: false,
      userInfo: null,
      selectedFileIdx: null,
      documentDialog: false,
      userValidation: null,
      userNormalInfo: [
        {
          column: 'isAdmin',
          label: '어드민여부',
          field: 'checkbox',
          filter: null,
          type: null,
          disabled: AuthService.hasAnyRole(['ROLE_SUPERADMIN']) === false,
        },
        {
          column: 'id',
          label: '유저아이디',
          field: 'text',
          filter: null,
          type: null,
          disabled: true,
        },
        {
          column: 'name',
          label: '유저이름',
          field: 'text',
          filter: null,
          type: null,
          disabled: true,
        },
        {
          column: 'email',
          label: '유저이메일',
          field: 'text',
          filter: null,
          type: null,
          disabled: false,
        },
        {
          column: 'userPhone',
          label: '휴대폰번호',
          field: 'text',
          type: null,
          disabled: true,
        },
        {
          column: 'userBirthdate',
          label: '생년월일',
          field: 'text',
          filter: null,
          type: null,
          disabled: true,
        },
        {
          column: 'investmentLimitType',
          label: '투자자타입',
          field: 'select',
          filter: null,
          type: null,
          disabled: false,
          select: 'investmentLimits',
        },
        {
          column: 'registerDate',
          label: '가입일',
          field: 'text',
          filter: 'datetimeFormat',
          type: null,
          disabled: true,
        },
        {
          column: 'lastLoginDate',
          label: '최근로그인',
          field: 'text',
          filter: 'datetimeFormat',
          type: null,
          disabled: true,
        },
        {
          column: 'affiliateCode',
          label: '프로모션코드',
          field: 'text',
          filter: null,
          type: null,
          hint: '수정은 가능하나 포인트는 지급되지 않습니다.',
          disabled: false,
        },
      ],
      userAddiInfo: [
        {
          column: 'codeRecommender',
          label: '추천인코드',
          field: 'text',
          filter: null,
          disabled: true,
          hint: '해당유저의 추천인코드입니다.',
          type: 'object',
          key: 'code',
        },
        {
          column: 'listUsedCode',
          label: '피추천인코드',
          field: 'text',
          filter: null,
          disabled: true,
          hint: '회원가입할 때 입력한 다른회원의 코드입니다.',
          type: 'object',
          key: 'codeRecommender.code',
        },
        {
          column: 'newsletterPhone',
          label: '뉴스레터수신',
          field: 'checkbox',
          filter: null,
          disabled: true,
          type: 'object',
          key: 'valid',
        },
      ],
      selector: {
        investmentLimits: [],
        fundaClasses: [
          {
            className: '선택하세요',
          },
        ],
      },
      userInvestmentList: {
        loading: false,
        data: null,
      },
      investBoardDialog: false,
      autoInvestInfo: {
        loading: false,
        data: null,
      },
      recommender: {
        dialog: false,
        code: null,
        title: null,
      },
      historyDialog: false,
      balanceDepositDialog: false,
      PointDepositDialog: false,
      eventInvests: [],
      scheduleAndRepaymentDialog: false,
      fnReportDialog: false,
      fNum: null,
      gridLoading: false,
      gridProps: {
        columns: [
          {
            header: '상품호수',
            name: 'fundingDataIdx',
            align: 'center',
            maxWidth: 80,
            sortable: true,
          },
          {
            header: '상품명',
            name: 'title',
            align: 'center',
            sortable: true,
          },
          {
            header: '타입',
            name: 'autoInvestmentType',
            align: 'center',
            filter: 'select',
          },
          {
            header: '상품상태',
            name: 'fundingStatus',
            align: 'center',
            filter: 'select',
            sortable: true,
          },
          {
            header: '상환상태',
            name: 'repaymentStatus',
            align: 'center',
            filter: 'select',
            sortable: true,
          },
          {
            header: '투자상태',
            name: 'investmentState',
            align: 'center',
            filter: 'select',
          },
          {
            header: '이자율',
            name: 'interestRate',
            align: 'center',
            formatter({ value }) {
              let v = parseFloat(value) * 100
              return parseFloat(v.toFixed(2))
            },
            sortable: true,
          },
          {
            header: '공시금액',
            name: 'totalAmount',
            align: 'center',
            formatter({ value }) {
              return Intl.NumberFormat().format(value)
            },
            sortable: true,
          },
          {
            header: '투자액',
            name: 'investmentAmt',
            align: 'center',
            formatter({ value }) {
              return Intl.NumberFormat().format(value)
            },
            sortable: true,
          },
          {
            header: '포인트',
            name: 'pointAmount',
            align: 'center',
            filter: 'select',
            formatter({ value }) {
              return Intl.NumberFormat().format(value)
            },
            sortable: true,
          },
          {
            header: '투자일시',
            name: 'datetime',
            align: 'center',
            formatter({ value }) {
              return datetimeParser(value)
            },
          },
          {
            header: '만기일',
            name: 'expirationDate',
            align: 'center',
          },
        ],
        data: [],
        pageOptions: {
          useClient: true,
          perPage: 500,
          visiblePages: 5,
          pageItems: [10, 20, 50, 100, 500],
          pagination: {
            position: 'bottom'
          }
        },
        clickEvent(ev) {
          if (ev.columnName === 'fundingDataIdx' || ev.columnName === 'title') {
            let row = this.$refs.tuiGrid.invoke('getRow', ev.rowKey)

            if (!!!row) {
              return
            }

            this.$emit('selectFNum', row)
          }
        },
      },
    }
  },
  watch: {
    isReservation(v) {
      if (v === false) {
        this.form.reserveTime = null
      }
    },
  },
  computed: {
    showIsAllDepositable() {
      if (this.userInfo === null || this.userInfo.paymentUser === null) {
        return true
      }

      let virtualAccount = this.userInfo.paymentUser.virtualAccounts.find(
        (vaccount) => {
          return vaccount.type === 'USER' && vaccount.isClosed === false
        }
      )

      return !!virtualAccount ? !virtualAccount.isAllDepositable : true
    },
    welcomeVirtualAccount() {
      if (this.userInfo === null || this.userInfo.paymentUser === null) {
        return null
      }

      return this.userInfo.paymentUser.virtualAccounts.find((vaccount) => {
        return vaccount.type === 'USER' && vaccount.isClosed === false
      })
    },
  },
  created() {
    this.fetchData()
    this.getInvestmentLimits()
    this.getFundaClass()
    this.getEventInvests()
  },
  methods: {
    openRepayments() {
      let r = this.$router.resolve({
        name: 'fundaUserRepayments',
        params: { userIdx: this.userIdx },
      })

      window.open(r.href, '_blank')
    },
    selectFNum() {},
    openFnReport(row) {
      if (row.autoInvestmentType === '일반') {
        if (row.investmentState === '투자완료') {
          this.scheduleAndRepaymentDialog = true
        }
      } else {
        this.fnReportDialog = true
      }

      this.fNum = row.fundingDataIdx
    },
    getModelValue(obj, keyStr) {
      if (!!!obj) {
        return
      }

      let keyArr = keyStr.split('.')
      let v = obj[keyArr[0]]

      if (!!v && typeof v === 'object') {
        return this.getModelValue(v, keyArr[1])
      } else {
        return v
      }
    },
    async fetchData() {
      let q = `
        data: getUserInfo(userIdx: ${this.userIdx}) {
          id
          name
          email
          userPhone
          userBirthdate
          isAdmin
          registerDate
          lastLoginDate
          investmentLimitType
          affiliateCode
          memo

          userValidation {
            approvalUserCdds {
              idx
              type
              status
              decryptInfoJson

              userDoc {
                idx
                awsS3FileIdx
              }
            }
          }

          bankAccount{
            accountHolder
            bank
            accountWoHyphen
          }
          virtualAccount: newBankAccountFunda {
            accountHolder
            bank
            account
          }
          newsletterPhone {
            valid
          }
          codeRecommender {
            code
          }
          listUsedCode {
            codeRecommender {
              code
            }
          }
          investmentLimit {
            idx
            type
            totalAmountLimit
            sameStoreLimit
          }

          userFundaClasses {
            createdAt
            fundaClass {
              idx
              classCode
              className
            }
          }
          
          paymentUser {
            idx

            virtualAccounts {
              idx
              paymentUserIdx
              bankCode
              accountNo
              accountName
              type
              balance
              isClosed
              isAllDepositable
            }
            
            withdrawAccount {
              idx
              paymentUserIdx
              paymentUserWithdrawAccountIdx
              bankCode
              accountNo
              accountName
              isVerify
              isChangeable
            }
          }

          userForcedWithdraw {
            idx
            memo
            createTime
          }
        }
      `

      this.getDataLoading = true

      try {
        let data = await this.$fundaApi.query(gql`{${q}}`)
        this.userInfo = data
        this.userValidation = data.userValidation
        console.log('this.userValidation 0', this.userValidation)

        if (this.userValidation && this.userValidation.approvalUserCdds && this.userValidation.approvalUserCdds[0] && this.userValidation.approvalUserCdds[0].userDoc) {
          this.selectedFileIdx = this.userValidation.approvalUserCdds[0].userDoc.awsS3FileIdx
          console.log('this.selectedFileIdx 1', this.selectedFileIdx)
        }
      } finally {
        this.getDataLoading = false
      }
    },
    fieldEvent(info) {
      if (info.column === 'codeRecommender' || info.column === 'listUsedCode') {
        if (info.column === 'codeRecommender') {
          this.recommender.title = `${this.userInfo.id} 를 추천한 유저목록`
        } else {
          this.recommender.title = `${this.userInfo.id} 이 추천한 유저를 추천한 유저목록 (...)`
        }

        this.recommender.code = this.getModelValue(
          this.userInfo[info.column],
          info.key
        )

        if (!!this.recommender.code) {
          this.recommender.dialog = true
        } else {
          this.$swal.basic.alert('피추천인 코드를 입력하지 않았습니다.')
        }
      }
    },
    async getInvestmentLimits() {
      let q = `
          data: getInvestmentLimits {
              idx
              type
              totalAmountLimit
              sameStoreLimit
          }
      `

      let data = await this.$fundaApi.query(gql`{${q}}`)

      data.forEach((v) => {
        this.selector.investmentLimits.push({
          val: v.idx,
          text: v.type,
        })
      })
    },
    async getFundaClass() {
      let q = `
        data: getFundaClass {
          idx
          classCode
          className
          fnWeight
          createdAt
        }
      `

      let data = await this.$fundaApi.query(gql`{${q}}`)

      data.forEach((v) => {
        this.selector.fundaClasses.push({
          val: v.idx,
          text: v.className,
        })
      })
    },
    async getAutoInvest() {
      let q = `
        info: getFnAutoInvestInfo(userIdx: ${this.userIdx}) {
          idx
          isEnabled
          isDiversified
          receivesSms
          totalAmount
          maxAmountPerProduct

          createdAt
          modifiedAt
        }

        info2: getUserAutoInvests(userIdx: ${this.userIdx}) {
          autoInvestmentType
          autoInvestmentTypeLabel
        }
      `
      let v = this.autoInvestInfo
      v.loading = true

      let { info, info2 } = await this.$fundaApi.query(gql`{${q}}`)

      if (!!!info) {
        info = {
          isEnabled: false,
        }
      }

      v.data = info
      v.autoInvestmentTypes = info2

      v.loading = false
    },
    async getEventInvests() {
      let q = `
        data: getMyEventInvest(userIdx: ${this.userIdx}, onTime: false) {
          categoryName
          eventName
          startDatetime
          endDatetime
          createdTime
          onEventTime
        }
      `

      let data = await this.$fundaApi.query(gql`{${q}}`)

      this.eventInvests = data
    },
    async getFundingInfoByUser() {
      const alert = await this.$swal.basic.confirm('투자 내역을 가져옵니다??')

      if (alert.isConfirmed === false) {
        return
      }

      const q = `
        data: getFundingInfoByUser(userIdx: ${this.userIdx}) {
          fundingDataIdx
          title
          totalAmount
          loanPeriod
          interestRate
          supportRate
          safeplanIdx
          investmentAmt
          pointAmount
          investmentState
          datetime
          fundingStatus
          repaymentStatus
          investmentType
          autoInvestmentType
          bankruptcyNoticeForCreditors
          expirationDate
          isMajungmul
        }
      `

      this.gridLoading = true

      try {
        const data = await this.$fundaApi.query(gql`{${q}}`)

        const arr = await Promise.all(
          data.map(async (v) => {
            const isOldFN =
              parseInt(v.investmentType) === 13 &&
              parseInt(v.fundingDataIdx) < 2900000

            const type = !!v.autoInvestmentType ? v.autoInvestmentType : '일반'
            v.autoInvestmentType = type

            if (
              isOldFN &&
              v.investmentState === '투자완료' &&
              v.fundingStatus !== '중도상환마감' &&
              v.repaymentStatus !== '연체'
            ) {
              const sq = `
              data: getMyFundanowInvestmentStatus(userIdx: ${this.userIdx}, fundingDataIdx: ${v.fundingDataIdx})
            `

              const status = await this.$fundaApi.query(gql`{${sq}}`)
              v.repaymentStatus = status
            }

            return v
          })
        )

        this.gridProps.data = arr
      } finally {
        this.gridLoading = false
      }
    },
    async saveUser() {
      let alert = await this.$swal.basic.confirm('유저정보를 저장합니다?')

      if (alert.isConfirmed === false) {
        return
      }

      this.getDataLoading = true

      let inputUser = {
        idx: this.userIdx,
        memo: this.userInfo.memo,
      }

      this.userNormalInfo.forEach((v) => {
        // 이메일은 중복검사를 해야해서 나중에
        if (v.disabled === false && v.column !== 'email') {
          inputUser[v.column] = this.userInfo[v.column]
        }
      })

      let q = `
        data: updateUser(utdUser: $input, inputUserFundaClasses: []) {
          id
          name
          email
          userPhone
          userBirthdate
          isAdmin
          registerDate
          lastLoginDate
          investmentLimitType
          affiliateCode
          memo
        }
      `

      try {
        let data = await this.$fundaApi.mutation(
          gql`{${q}}`,
          'call($input: InputUser!)',
          { input: inputUser }
        )

        if (!!data) {
          this.$swal.basic.alert({
            title: '완료!!',
            text: '기본 정보가 저장되었습니다.',
          })
        } else {
          this.$swal.basic.error({
            title: '실패ㅠㅠ',
            text: '에러가 발생했습니다...',
          })
        }
      } finally {
        this.getDataLoading = false
      }
    },
    async beforeSubmit(submit) {
      try {
        await submit()
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>

<style lang="scss">
.v-messages__wrapper > .v-messages__message {
  color: black !important;
}

.card__my-funda {
  background-color: #f6f8fa !important;
}

.event--active {
  background-color: rgb(27, 170, 241, 0.3);
}
</style>
