import client from '../client'
import qs from 'query-string'

export default {
  /**
   * 사용자
   */
  getUsers(param) {
    return client.call({
      url: `/users?${qs.stringify(param)}`,
      method: HttpMethod.GET,
    })
  },
  getSearchUser(param) {
    return client.call({
      url: `/user?${qs.stringify(param)}`,
      method: HttpMethod.GET,
    })
  },
  getUser(userIdx) {
    return client.call({
      url: `/user/${userIdx}`,
      method: HttpMethod.GET,
    })
  },
  getAllowBanks() {
    return client.call({
      url: '/user/1/withdraw/allow/banks',
      method: HttpMethod.GET,
    })
  },
  // 페이징 처리된 /v2 사용
  getSearchUserVirtualAccounts(param) {
    return client.call({
      url: `/user/virtual/account/v2?${qs.stringify(param)}`,
      method: HttpMethod.GET,
    })
  },
  getUserVirtualAccounts(userIdx) {
    // 100개까진 될일이 없으니 100개까지만..
    return client.call({
      url: `/user/${userIdx}/virtual/accounts?page=1&size=100`,
      method: HttpMethod.GET,
    })
  },
  getUserVirtualAccountByIdx(userVirtualAccountIdx) {
    // 가상계좌 idx만 가지고 조회
    return client.call({
      url: `/user/virtual/account/${userVirtualAccountIdx}`,
      method: HttpMethod.GET,
    })
  },
  getUserVirtualAccountBalance(
    userIdx,
    userVirtualAccountIdx,
    param = { isApiBalance: false }
  ) {
    return client.call({
      url: `/user/${userIdx}/virtual/account/${userVirtualAccountIdx}/balance?isApiBalance=${param.isApiBalance}`,
      method: HttpMethod.GET,
    })
  },
  postUserWithdrawRequest(userIdx, body) {
    return client.call({
      url: `/user/${userIdx}/withdraw`,
      method: HttpMethod.POST,
      body: {
        amount: body.amount,
        remark: body.remark,
        verifyType: 'SMS',
      },
    })
  },
  postUserWithdrawConfirm(userIdx, body) {
    return client.call({
      url: `/user/${userIdx}/withdraw/confirm`,
      method: HttpMethod.POST,
      body: {
        userWithdrawIdx: body.userWithdrawIdx,
        verifyWord: body.verifyWord,
      },
    })
  },
  getUserVirtualAccountTransactions(userVirtualAccountIdx, param) {
    return client.call({
      url: `/user/virtual/account/${userVirtualAccountIdx}/transactions?${qs.stringify(
        param,
        {
          skipEmptyString: true,
          skipNull: true,
        }
      )}`,
      method: HttpMethod.GET,
    })
  },
  getUserVirtualAccountBalanceWelcome(userIdx, userVirtualAccountIdx) {
    return client.call({
      url: `/user/${userIdx}/virtual/account/${userVirtualAccountIdx}/balance/welcome`,
      method: HttpMethod.GET,
    })
  },
  /**
   *
   * @param {
        "from":{
          "userIdx": 1,
          "userVirtualAccountIdx": 1
        },
        "to":{
          "userIdx": 1,
          "userVirtualAccountIdx": 1
        },
        "amount": 1,
        "remark": "테스트 이체"
      } body
   */
  postUserVirtualAccountTransfer(data) {
    return client.call({
      url: '/user/virtual/account/transfer',
      method: HttpMethod.POST,
      data,
    })
  },
  getUserVirtualAccountWithdrawAccount(userVirtualAccountIdx) {
    return client.call({
      url: `/user/virtual/account/${userVirtualAccountIdx}/withdraw/account`,
      method: HttpMethod.GET,
    })
  },
  getUserVirtualAccount(userVirtualAccountIdx) {
    return client.call({
      url: `/user/virtual/account/${userVirtualAccountIdx}`,
      method: HttpMethod.GET,
    })
  },
  getWelcomeUser(userIdx, userVirtualAccountIdx) {
    return client.call({
      url: `/user/${userIdx}/virtual/account/${userVirtualAccountIdx}/welcome/user`,
      method: HttpMethod.GET,
    })
  },
  getUserVirtualAccountTransferReservations(param) {
    return client.call({
      url: `/user/virtual/account/transfers/reservations?${qs.stringify(param, {
        skipNull: true,
        skipEmptyString: true,
      })}`,
      method: HttpMethod.GET,
    })
  },
  disableWithdrawAccountVerify(userIdx) {
    return client.call({
      url: `/user/${userIdx}/withdraw/account/verify/disable`,
      method: HttpMethod.PUT,
    })
  },
  renameVirtualAccount(userVirtualAccountIdx, rename) {
    return client.call({
      url: `/user/virtual/account/${userVirtualAccountIdx}/rename`,
      method: HttpMethod.PUT,
      data: { rename },
    })
  },
}
