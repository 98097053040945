export default [
  {
    name: 'fundingDetails',
    path: '/funda/funding/:fundingDataIdx?',
    meta: { title: '일반 상품 상세' },
    component: () => import('@/pages/funda/funding/Details.vue'),
  },
  {
    name: 'dongsanRepayment',
    path: '/funda/dongsan/repayment',
    meta: { title: '동산 상환' },
    component: () => import('@/pages/funda/funding/DongsanManagement.vue'),
  },
]
