import AuthService from '@/service/auth/AuthService'

function isPaymentAdminUser(to, from, next) {
  if (
    AuthService.hasAnyRole([
      'ROLE_SUPERADMIN',
      'ROLE_PAYMENT_ADMIN',
      'ROLE_LOAN_ADMIN',
    ]) === false
  ) {
    alert('권한없음')
    next(false)
  } else {
    next()
  }
}

const routes = [
  {
    name: 'payment/callbacks',
    path: '/payment/callbacks',
    props: (route) => ({ query: route.query }),
    meta: { title: '콜백' },
    component: require('@/pages/payment/callback/Callbacks.vue').default,
  },
  {
    name: 'payment/callbacks/detail',
    path: '/payment/callbacks/:callbackIdx',
    props: (route) => ({ callbackIdx: Number(route.params.callbackIdx) }),
    meta: { title: '콜백 상세' },
    component: require('@/pages/payment/callback/CallbackDetail.vue').default,
  },
  {
    name: 'payment/users',
    path: '/payment/users',
    props: (route) => ({ query: route.query }),
    meta: { title: '사용자' },
    component: require('@/pages/payment/user/Users.vue').default,
  },
  {
    name: 'payment/users/detail',
    path: '/payment/users/:userIdx',
    props: (route) => ({ userIdx: Number(route.params.userIdx) }),
    meta: { title: '사용자 상세' },
    component: require('@/pages/payment/user/UserDetail.vue').default,
  },
  {
    name: 'payment/users/virtual/accounts/transactions',
    path: '/payment/users/:userIdx/virtual/accounts/:userVirtualAccountIdx/transactions',
    props: (route) => ({
      userIdx: Number(route.params.userIdx),
      userVirtualAccountIdx: Number(route.params.userVirtualAccountIdx),
      query: route.query,
    }),
    meta: { title: '가상계좌 거래내역' },
    component:
      require('@/pages/payment/user/virtualAccount/UserVirtualAccountTransactions.vue')
        .default,
  },
  {
    name: 'payment/users/virtual/accounts',
    path: '/payment/users/virtual/accounts',
    props: (route) => ({
      query: route.query,
    }),
    meta: { title: '가상계좌' },
    component:
      require('@/pages/payment/user/virtualAccount/UserVirtualAccounts.vue')
        .default,
  },
  {
    name: 'payment/user/refunds',
    path: '/payment/user/refunds',
    props: (route) => ({
      query: route.query,
    }),
    meta: { title: '반환' },
    component: require('@/pages/payment/user/refund/UserRefunds.vue').default,
  },
  {
    name: 'payment/user/virtual/account/transfers/reservations',
    path: '/payment/user/virtual/account/transfers/reservations',
    props: (route) => ({
      query: route.query,
    }),
    meta: { title: '예약 이체' },
    component:
      require('@/pages/payment/user/virtualAccount/transfer/Reservations.vue')
        .default,
  },
  {
    name: 'payment/admin',
    path: '/payment/admin',
    component: require('@/pages/payment/admin/AbstractView.vue').default,
    redirect: {
      name: 'payment/admin/system/users',
    },
    children: [
      {
        name: 'payment/admin/system/users',
        path: 'system/users',
        alias: '',
        component: require('@/pages/payment/admin/system/SystemUsers.vue')
          .default,
        meta: { title: '시스템 계좌' },
      },
    ],
  },
  {
    name: 'payment/user/virtual/account/transfers',
    path: '/payment/user/virtual/account/transfers',
    meta: { title: '페이먼트 - 이체' },
    component:
      require('@/pages/payment/user/virtualAccount/transfer/Transfer.vue')
        .default,
  },
  {
    name: 'payment/payout/reserve',
    path: '/payment/payout/reserve',
    component: require('@/pages/payment/payout/reserve/AbstractView.vue')
      .default,
    redirect: {
      name: 'payment/payout/reserve/step2',
    },
    children: [
      {
        name: 'payment/payout/reserve/step2',
        path: 'step2',
        alias: '',
        component:
          require('@/pages/payment/payout/reserve/PayoutReservesStep2.vue')
            .default,
        meta: { title: '상환 step2 실행' },
      },
    ],
  },
]

routes.forEach((router) => {
  router.beforeEnter = (to, from, next) => {
    isPaymentAdminUser(to, from, next)
  }
  // if (router.children) {
  //   router.children.forEach(child => {
  //     child.beforeEnter = (to, from, next) => {
  //       isPaymentAdminUser(to, from, next)
  //     }
  //   })
  // }
})

export default routes
