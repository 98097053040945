<template>
  <v-dialog v-model="dialog" width="800">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="warning" large v-bind="attrs" v-on="on">
        사용자 가상 계좌로 이체
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="py-1 headline f-light-grey">
        가상 계좌 간 이체
        <v-spacer></v-spacer>
        <v-btn
          color="black"
          text
          icon
          x-large
          :disabled="isLoading"
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <Transfer
          v-if="dialog"
          :key="dialog"
          :propsFromAccount="propsFromAccount"
          @success="success"
          @loading="isLoading = $event"
        />
      </v-card-text>
      <v-divider></v-divider>
    </v-card>
  </v-dialog>
</template>
<script>
import Transfer from './Transfer.vue'
export default {
  components: { Transfer },
  props: {
    propsFromAccount: {
      type: Object,
      default: (_) => {
        return null
      },
    },
  },
  data() {
    return {
      dialog: false,
      isLoading: false,
    }
  },
  methods: {
    success() {
      this.isLoading = false
      this.dialog = false
      this.$emit('success')
    },
  },
}
</script>
