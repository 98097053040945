<template>
  <v-app>
    <AppLoading v-if="initStatus === false" />
    <RootLayout v-else>
      <router-view :key="$route.fullPath + '&' + $store.state.time.current" />
    </RootLayout>
    <Errors />
  </v-app>
</template>
<script>
import AppLoading from '@/components/AppLoading'
import RootLayout from '@/layouts/RootLayout'
import Errors from '@/components/error/FooterErrors'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'App',
  components: {
    AppLoading,
    RootLayout,
    Errors,
  },
  metaInfo() {
    return this.metaInfo
  },
  methods: {
    ...mapActions('ui', ['setIsMobile']),
  },
  created() {
    this.metaInfo = _seo()
  },
  mounted() {
    this.setIsMobile(this.$vuetify.breakpoint.mobile)
    // this.$store.dispatch('ui/setIsMobile', this.$vuetify.breakpoint.mobile)
    window.onresize = () => {
      this.setIsMobile(this.$vuetify.breakpoint.mobile)
      // this.$store.dispatch('ui/setIsMobile', this.$vuetify.breakpoint.mobile)
    }
  },
  computed: {
    ...mapGetters('system', {
      initStatus: 'getInit',
    }),
  },
}
</script>
