<template>
  <div>
    <ValidationObserver v-slot="{ invalid }" ref="validForm">
      <v-card class="px-2 py-3 mb-3">
        <v-card-title class="pt-1">발송 제목 작성</v-card-title>
        <v-card-subtitle class="error--text"
          >해당 발송건에 제목을 붙여주세요. 관리용 정보이므로 고객에게 보이지
          않습니다.</v-card-subtitle
        >
        <v-card-text class="pb-0">
          <ValidationProvider
            v-slot="{ errors }"
            name="alimtalk_title"
            rules="required|min:5|max:200"
          >
            <v-text-field
              v-model="title"
              name="alimtalk_title"
              class="my-3"
              type="text"
              label="제목"
              placeholder="알림톡 발송 건에 대한 설명을 입력하세요"
              outlined
              :hide-details="!errors"
              :error-messages="errors"
            />
          </ValidationProvider>
        </v-card-text>
      </v-card>
      <!-- <v-card class="px-2 py-3 mb-3">
        <v-card-title class="pt-1">실패시 문자 메세지로 발송 여부</v-card-title>
        <v-card-text>
          <v-checkbox
            color="primary"
            v-model="isFailSendSms"
            v-ripple
            label="실패시 문자 메세지로 발송"
            hide-details
            class="mt-0"
          />
        </v-card-text>
      </v-card> -->
      <v-btn
        :disabled="invalid || isSendable == false"
        :loading="isLoading"
        block
        color="primary"
        large
        class="title"
        @click="send"
      >
        <template v-if="receivers && receivers.length > 0">
          {{ receivers.length }}건
        </template>
        발송
      </v-btn>
    </ValidationObserver>
  </div>
</template>
<script>
export default {
  props: {
    variables: {
      type: Array,
      default: (_) => {
        return []
      },
    },
    receivers: {
      type: Array,
      default: (_) => {
        return []
      },
    },
    template: {
      type: Object,
      required: true,
    },
    reserveTime: {
      type: String,
      default: null,
    },
    talkTitle: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      title: null,
      isFailSendSms: false,
    }
  },
  computed: {
    isSendable() {
      return this.receivers != null && this.receivers.length > 0
    },
  },
  watch: {
    talkTitle: {
      immediate: true,
      handler(v) {
        this.title = v
      },
    },
  },
  methods: {
    async send(showAlert = true) {
      if (this.isLoading === true && showAlert !== false) {
        return
      }

      try {
        if (showAlert !== false) {
          let btnResult = await this.$swal.basic.confirm({
            title: '알림톡 발송',
            text: `총 ${this.receivers.length}건의 알림톡을 발송 하시겠습니까?`,
            cancelButtonColor: 'red',
          })

          if (btnResult.isConfirmed !== true) {
            return
          }
        }

        this.isLoading = true
        let forms = []
        for (let i = 0; i < this.receivers.length; i++) {
          const receiverData = this.receivers[i]
          // content, buttons 세팅
          let formObj = {
            content: receiverData.replacedTemplate.content,
            buttons: receiverData.replacedTemplate.buttons,
            mobileNo: receiverData.mobileNo,
            profileTemplateIdx: this.template.idx,
          }

          if (this.reserveTime != null) {
            formObj.reserveTime = this.reserveTime
          }

          if (this.isFailSendSms === true) {
            formObj.isFailSendSms = true
          }

          if (formObj.buttons && formObj.buttons.length > 0) {
            formObj.buttons.forEach((button) => {
              delete button.idx
              delete button.alimtalkIdx
              delete button.profileTemplateIdx
            })
          }

          forms.push(formObj)
        }

        let body = {
          title: this.title,
          alimtalks: forms,
        }

        if (this.reserveTime != null) {
          body.reserveTime = this.reserveTime
        }

        // console.log(body)
        const { data: messageGroup } =
          await this.$alimtalkApi.postMessageGroupAlimtalks(body)

        // 예약발송 아닌 경우 즉시 발송
        if (this.reserveTime == null) {
          await this.$alimtalkApi.postMessageGroupSend(messageGroup.idx)
        }

        if (showAlert !== false) {
          await this.$swal.basic.alert({
            title: '알림톡 발송 완료',
            text: '발송이 완료되었습니다.',
          })

          this.$router.push({
            name: 'alimtalk/message/groups/detail',
            params: { messageGroupIdx: messageGroup.idx },
          })
        }
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
