var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"validForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"v-data-table theme--light"},[_c('div',{staticClass:"v-data-table__wrapper"},[_c('table',[_c('thead',{staticClass:"v-data-table-header"},[_c('tr',[_c('th',[_vm._v("구분")]),_c('th',[_vm._v("대출 잔액")]),_c('th',[_vm._v("연체 금액")]),_c('th',[_vm._v("연체율")])])]),_c('tbody',[_c('tr',[_c('th',[_vm._v("신용")]),_c('td',{staticClass:"pt-6 pb-0"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('ValidationProvider',{attrs:{"name":"amount","rules":"required|numeric|min_value:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"input-comma-format",rawName:"v-input-comma-format"}],attrs:{"label":"신용대출잔액","outlined":"","dense":"","error-messages":errors,"disabled":_vm.isLoading},on:{"focus":_vm.test},model:{value:(_vm.creditLoanBalance),callback:function ($$v) {_vm.creditLoanBalance=$$v},expression:"creditLoanBalance"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,true)},[_c('table',{staticClass:"text-center"},[_c('tr',[_c('td'),_c('td',[_vm._v("잔액")]),_c('td',{staticClass:"pl-2"},[_vm._v("연체금액")])]),_c('tr',[_c('td',[_vm._v("신용")]),_c('td',[_vm._v("-")]),_c('td',[_vm._v("-")])]),_c('tr',[_c('td',[_vm._v("동산")]),_c('td',[_vm._v("-")]),_c('td',[_vm._v("-")])])]),_vm._v(" 형태의 엑셀 데이터를 붙여넣을 수 있습니다. ")])],1),_c('td',{staticClass:"pt-6 pb-0"},[_c('ValidationProvider',{attrs:{"name":"amount","rules":"required|numeric|min_value:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"input-comma-format",rawName:"v-input-comma-format"}],attrs:{"label":"신용연체금액","outlined":"","dense":"","error-messages":errors,"disabled":_vm.isLoading},model:{value:(_vm.creditLoanOverdueBalance),callback:function ($$v) {_vm.creditLoanOverdueBalance=$$v},expression:"creditLoanOverdueBalance"}})]}}],null,true)})],1),_c('td',[_vm._v(_vm._s(_vm.creditOverdueRate)+"%")])]),_c('tr',[_c('th',[_vm._v("동산")]),_c('td',{staticClass:"pt-6 pb-0"},[_c('ValidationProvider',{attrs:{"name":"amount","rules":"required|numeric|min_value:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"input-comma-format",rawName:"v-input-comma-format"}],attrs:{"label":"동산대출잔액","outlined":"","dense":"","error-messages":errors,"disabled":_vm.isLoading},model:{value:(_vm.movableLoanBalance),callback:function ($$v) {_vm.movableLoanBalance=$$v},expression:"movableLoanBalance"}})]}}],null,true)})],1),_c('td',{staticClass:"pt-6 pb-0"},[_c('ValidationProvider',{attrs:{"name":"amount","rules":"required|numeric|min_value:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"input-comma-format",rawName:"v-input-comma-format"}],attrs:{"label":"동산연체금액","outlined":"","dense":"","error-messages":errors,"disabled":_vm.isLoading},model:{value:(_vm.movableLoanOverdueBalance),callback:function ($$v) {_vm.movableLoanOverdueBalance=$$v},expression:"movableLoanOverdueBalance"}})]}}],null,true)})],1),_c('td',[_vm._v(_vm._s(_vm.movableOverdueRate)+"%")])]),_c('tr',[_c('th',[_vm._v("합계")]),_c('td',[_vm._v(_vm._s(_vm._f("commaFormat")(_vm.totalLoanBalance)))]),_c('td',[_vm._v(_vm._s(_vm._f("commaFormat")(_vm.totalOverdueAmount)))]),_c('td',[_vm._v(" "+_vm._s(isNaN(_vm.totalOverdueRate) ? '' : _vm.totalOverdueRate)+"% ")])])])])])])])],1),_c('v-row',[_c('v-col',{staticClass:"px-4",attrs:{"cols":"12"}},[_c('CommonDateTimePicker',{attrs:{"dateTime":_vm.baseTime,"label":"공시 금액 기준일 선택"},on:{"update:dateTime":function($event){_vm.baseTime=$event},"update:date-time":function($event){_vm.baseTime=$event}}}),(_vm.baseTime == null)?_c('p',{staticClass:"mb-n2 font-size--13"},[_vm._v(" 기준일을 선택해주세요. ")]):_vm._e()],1)],1),_c('v-divider'),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6","md":"6","sm":"12"}}),_c('v-col',{staticClass:"px-4",attrs:{"cols":"12","lg":"3","md":"3","sm":"12"}},[_c('v-btn',{attrs:{"color":"error","block":"","type":"button","disabled":_vm.isLoading},on:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v(" 취소하기 ")])],1),_c('v-col',{staticClass:"px-4",attrs:{"cols":"12","lg":"3","md":"3","sm":"12"}},[_c('v-btn',{attrs:{"color":"primary","block":"","type":"submit","disabled":_vm.baseTime == null || invalid || _vm.isLoading,"loading":_vm.isLoading}},[_vm._v(" 등록하기 ")])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }