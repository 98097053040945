<template>
  <v-card>
    <v-card-title>
      템플릿 내용 {{ disabled ? '조회' : '입력' }}
      <!-- <v-spacer />
      <v-checkbox
        color="warning"
        class="mt-0"
        v-model="isInputable"
        v-ripple
        label="직접입력"
        hide-details
      /> -->
    </v-card-title>
    <v-card-text>
      <ValidationObserver ref="validForm">
        <ProfileSelect
          v-model="profile.idx"
          class="mb-3"
          @change="setProfile"
          rules="required"
          :disabled="disabled"
        />
        <ValidationProvider
          v-slot="{ touched, errors }"
          name="alimtalk_template_code"
          rules="required"
        >
          <v-text-field
            v-model="form.id"
            name="id"
            class="my-3"
            type="text"
            label="템플릿 코드"
            :placeholder="`템플릿 코드를 ${placeholder}`"
            :hide-details="touched == false || errors.length == 0"
            :error-messages="touched == false ? [] : errors"
            outlined
            :readonly="!isInputable"
            :clearable="!disabled"
            @paste="paste"
            @input="input"
          />
        </ValidationProvider>
        <ValidationProvider
          v-slot="{ touched, errors }"
          name="alimtalk_template_name"
          rules="required"
        >
          <v-text-field
            v-model="form.name"
            name="name"
            class="my-3"
            type="text"
            label="템플릿 명"
            :placeholder="`템플릿 명을 ${placeholder}`"
            :hide-details="touched == false || errors.length == 0"
            :error-messages="touched == false ? [] : errors"
            outlined
            :readonly="!isInputable"
            :clearable="!disabled"
            @paste="paste"
            @input="input"
          />
        </ValidationProvider>
        <ValidationProvider
          v-slot="{ touched, errors }"
          name="alimtalk_template_content"
          rules="required"
        >
          <v-textarea
            v-model="form.content"
            name="content"
            label="내용"
            placeholder="내용을 붙여넣으세요.(ctrl + v)"
            outlined
            readonly
            :clearable="!disabled"
            no-resize
            auto-grow
            :error-messages="touched == false ? [] : errors"
            @paste="paste"
            @input="input"
          />
        </ValidationProvider>
      </ValidationObserver>
    </v-card-text>
  </v-card>
</template>
<script>
import ProfileSelect from '../../form/ProfileSelect.vue'
export default {
  components: { ProfileSelect },
  props: {
    template: {
      type: Object,
      default: (_) => {
        return null
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      profile: {
        idx: null,
      },
      form: {
        id: null,
        profileIdx: null,
        name: null,
        content: null,
        isActive: true,
      },
      isInputable: false,
    }
  },
  created() {
    this.setPropsTemplate()
  },
  computed: {
    placeholder() {
      return this.isInputable === false
        ? '붙여넣으세요.(ctrl + v)'
        : '입력하세요.'
    },
  },
  watch: {
    template() {
      this.setPropsTemplate()
    },
  },
  methods: {
    setPropsTemplate() {
      if (this.template != null) {
        console.log(this.template)
        this.profile.idx = this.template.profileIdx
        this.form.id = this.template.id
        this.form.profileIdx = this.template.profileIdx
        this.form.name = this.template.name
        this.form.content = this.template.content
        this.form.isActive = this.template.isActive
        this.validate()
        this.$emit('change', this.form)
      }
    },
    setProfile(e) {
      this.profile = e
      this.form.profileIdx = e.idx
      this.$emit('change', this.form)
      this.validate()
    },
    paste(e) {
      if (this.disabled === true) {
        return
      }
      if (this.isInputable === true && e.target.name !== 'content') {
        return
      }
      this.form[e.target.name] = e.clipboardData.getData('text').trim()
      this.$emit('change', this.form)
      this.validate()
    },
    input(e) {
      this.$emit('change', this.form)
      this.validate()
    },
    validate() {
      this.$nextTick(async (_) => {
        const isValid = await this.$refs.validForm.validate()
        this.$emit('valid', isValid)
      })
    },
  },
}
</script>
