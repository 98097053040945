<template>
  <v-card :loading="isLoading">
    <v-card-title class="pb-1">템플릿 목록</v-card-title>
    <v-card-text>
      <v-list
        class="pt-0"
        style="overflow-y: scroll"
        height="205"
        max-height="205"
        width="100%"
      >
        <div
          v-if="templates == null || templates.length == 0"
          class="mt-16 subtitle-1 grey--text text-center"
        >
          <p>아직 등록된 템플릿이 없습니다.</p>
        </div>
        <v-list-item-group
          v-model="selectedTemplate"
          color="primary"
          @change="select"
        >
          <v-list-item
            v-for="(template, index) in templates"
            :key="index"
            :value="template"
          >
            <v-list-item-content>
              <v-list-item-title>
                <v-chip
                  v-if="template.idx > 100000"
                  color="error"
                  x-small
                  class="mr-1"
                  >TEST</v-chip
                >
                {{ template.name }}&#40;{{
                  template.id
                }}&#41;</v-list-item-title
              >
              <v-list-item-subtitle>{{
                template.content
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card-text>
  </v-card>
</template>
<script>
import testData from '../form/testData'
import AuthService from '@/service/auth/AuthService'

export default {
  props: {
    profileIdx: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      templates: [],
      selectedTemplate: null,
      isLoading: false,
    }
  },
  watch: {
    profileIdx(v) {
      this.getTemplates()
    },
  },
  created() {
    this.getTemplates()
  },
  methods: {
    async getTemplates() {
      this.isLoading = true
      const { data } = await this.$alimtalkApi.getTemplates(this.profileIdx, {
        isActive: true,
      })
      this.templates = data
      this.selectedTemplate = data[0]
      this.select(data[0])
      this.isLoading = false

      // 테스트 템플릿
      if (
        this.profileIdx === 1 &&
        AuthService.hasAnyRole(['ROLE_SUPERADMIN'])
      ) {
        this.templates = this.templates.concat(testData.templates.fundainvest)
      }
    },
    async select(template) {
      if (template != null) {
        // 테스트 템플릿
        if (template.idx > 100000) {
          return this.$emit('change', template)
        }

        // 템플릿 상세 조회
        let { data } = await this.$alimtalkApi.getProfilesDetailTemplate(
          this.profileIdx,
          template.idx
        )
        this.$emit('change', data)
      } else {
        this.$emit('change', null)
      }
    },
  },
}
</script>
