import Vue from 'vue'
import axios from 'axios'
import AuthService from '@/service/auth/AuthService'

const _axios = axios.create({
  baseURL: `${process.env.common.fundaApi.host}`,
})

_axios.interceptors.request.use(
  (config) => {
    if (config.isInterceptor !== false) {
      if (
        config.headers.Authorization == null ||
        config.headers.Authorization.startsWith('Basic') === false
      ) {
        let token = localStorage.getItem(process.env.common.token.name)
        if (token != null) {
          config.headers.Authorization = `Bearer ${token}`
        }
      }
    }

    return config
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error)
  }
)

_axios.interceptors.response.use(
  (resp) => {
    let data = resp.data
    if (data.errors) {
      let responseErr = data.errors
      if (data.errors.length > 0) {
        let err = data.errors[0].extensions
        responseErr = {
          code: err.code,
          status: err.status,
        }

        try {
          responseErr.message = JSON.parse(err.message).message
        } catch (e) {
          responseErr.message = data.errors[0].message
        }
      }
      if (responseErr.status === 401) {
        location.reload()
      }
      return Promise.reject(responseErr)
    } else {
      let r = resolveResp(resp)
      return Promise.resolve(r)
    }
  },
  (error) => {
    return Promise.reject(error.response)
  }
)

function resolveResp(resp) {
  const result = resp.data ?? false
  if (result !== false) {
    resp = resolveResp(resp.data)
  }
  return resp
}

_axios.call = async function (option) {
  if (option.data != null && option.data.isAwait === false) {
    delete option.data.isAwait
  } else {
    await AuthService.sync()
  }
  return _axios(Vue.util.extend({}, option))
}

export default _axios
