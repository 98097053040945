<template>
  <div>
    <v-card class="py-3">
      <v-card-title
        class="py-1 headline f-light-grey justify-center text-center"
        style="background-color: white"
      >
        <v-row no-gutters>
          <v-col cols="2"> </v-col>
          <v-col cols="8" class="position--relative">
            {{ searchDate }} 투자자
          </v-col>
          <v-col cols="2" class="text-right">
            <ExportExcelButton2
              :headers="exportHeaders"
              :data="investors"
              :options="{ skipHeader: true }"
              fileName="투자자들"
            >
              <template v-slot:button="{ download }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" @click="download">
                      <v-icon>mdi-export-variant</v-icon>
                    </v-btn>
                  </template>
                </v-tooltip>
              </template>
            </ExportExcelButton2>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="py-2">
        <v-row no-gutters>
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="investors"
              :no-data-text="noDataText"
              :no-results-text="noDataText"
              :loading="isLoading"
              :footer-props="{
                'items-per-page-options': [10, 20],
              }"
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td>{{ item.userIdx }}</td>
                  <td>{{ item.userId }}</td>
                  <td>{{ item.userName }}</td>
                  <td>{{ item.sumInvestmentAmount | commaFormat }} 원</td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ExportExcelButton2 from '@/components/common/datatable/ExportExcelButton2'

export default {
  props: {
    searchDate: {
      type: String,
      default: null,
    },
  },
  components: {
    ExportExcelButton2,
  },
  data() {
    return {
      isLoading: false,
      headers: [
        { text: '인덱스', value: 'userIdx' },
        { text: '아이디', value: 'userId' },
        { text: '이름', value: 'userName' },
        { text: '총투자금액', value: 'sumInvestmentAmount' },
      ],
      exportHeaders: [
        { text: '인덱스', value: 'userIdx' },
        { text: '아이디', value: 'userId' },
        { text: '이름', value: 'userName' },
        { text: '휴대폰번호', value: 'userPhone' },
        { text: '투자자타입', value: 'investmentLimitType' },
        { text: '문자수신여부', value: 'promotionSms' },
        {
          text: '총투자금액',
          value: 'sumInvestmentAmount',
          formatter: this.$options.filters.commaFormat,
        },
        { text: '총투자포인트', value: 'sumPointAmount' },
        { text: '투자일시(MIN)', value: 'minInvestmentDatetime' },
        { text: '투자일시(MAX)', value: 'maxInvestmentDatetime' },
      ],
      investors: [],
    }
  },
  computed: {
    noDataText() {
      return `${!!this.searchDate ? this.searchDate : ''} 투자자가 없습니다.`
    },
  },
  watch: {
    searchDate: {
      immediate: true,
      handler(v) {
        if (!!v) {
          this.getInvestors()
        }
      },
    },
  },
  methods: {
    async getInvestors() {
      if (this.isLoading) {
        return
      }

      const q = `
        data: getInvestorsByDate(searchDate: "${this.searchDate}")
      `

      this.isLoading = true

      try {
        let data = await this.$fundaApi.query(gql`{${q}}`)

        data = JSON.parse(data)

        this.investors = data
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
