<template>
  <div>
    <template v-if="remainPrincipal != null">
      {{ remainPrincipal | commaFormat }} 원
    </template>
    <template v-else-if="isLoading">
      <v-progress-circular
        :size="25"
        indeterminate
        :color="$colors['f-blue-grey-lighten-1']"
      ></v-progress-circular>
    </template>
  </div>
</template>
<script>
export default {
  props: {
    loanId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      remainPrincipal: null,
      isLoading: false,
    }
  },
  watch: {
    async loanId(v) {
      if (v) {
        await this.getData()
      }
    },
  },
  async created() {
    await this.getData()
  },
  methods: {
    async getData() {
      try {
        this.isLoading = true
        const { data } = await this.$fundaNowApi.getLoanRemainPrincipal(
          this.loanId
        )
        this.remainPrincipal = data
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
<style lang="scss" scoped>
tr > td:last-child {
  word-break: break-all;
}
</style>
