<template>
  <div>
    <v-row no-gutters>
      <v-dialog v-model="dialog" max-width="1300px" width="90%">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :color="$colors['f-dark-grey']"
            dark
            depressed
            x-large
            block
            v-bind="attrs"
            v-on="on"
          >
            채권매각
          </v-btn>
        </template>

        <v-card>
          <v-card-title>
            <span class="headline"> 채권 매각 현황 </span>
          </v-card-title>
          <v-card-text>
            <keep-alive>
              <DisposalDetail
                :key="userIdx + '_인증'"
                :userIdx="parseInt(userIdx)"
              ></DisposalDetail>
            </keep-alive>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="dialog = false">
              닫기
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import DisposalDetail from './DisposalDetail.vue'

export default {
  props: {
    userIdx: {
      required: true,
      default: null,
    },
  },
  components: {
    DisposalDetail,
  },
  data() {
    return {
      dialog: false,
    }
  },
}
</script>

<style lang="scss" scoped></style>
