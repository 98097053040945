<template>
  <div>
    <v-row class="mt-3" no-gutters>
      <v-col cols="12" sm="12" md="12">
        <v-tabs
          v-model="tab"
          height="60"
          fixed-tabs
          color="#ffffff"
          background-color="#393939"
          dark
        >
          <v-tab @click="showTab = 'creditorFunding'"> 상품별 </v-tab>

          <v-tab @click="showTab = 'creditorCalendar'"> 상환일별 </v-tab>
        </v-tabs>

        <v-card min-width="100%" min-height="400" flat>
          <v-tabs-items v-model="tab" style="width: 100%">
            <v-tab-item>
              <template v-if="showTab === 'creditorFunding'">
                <FundingDataSearch
                  @selectFundingData="(v) => (selectedFundingDataIdx = v.idx)"
                ></FundingDataSearch>

                <FundingDataRepayment
                  :funding-data-idx="selectedFundingDataIdx"
                  @passCreditorList="(v) => (smsCreditorList = v)"
                >
                </FundingDataRepayment>
              </template>
            </v-tab-item>

            <v-tab-item>
              <template v-if="showTab === 'creditorCalendar'">
                <FundingDataRepayment
                  :funding-data-idx="selectedFundingDataIdx"
                  :show-calendar="true"
                  @passCreditorList="(v) => (smsCreditorList = v)"
                >
                </FundingDataRepayment>
              </template>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import FundingDataSearch from '@/components/funda/funding/FundingDataSearch'
import FundingDataRepayment from '@/components/funda/funding/FundingDataRepayment'
export default {
  components: {
    FundingDataSearch,
    FundingDataRepayment,
  },
  watch: {
    smsCreditorList(v) {
      this.$emit('passCreditorList', v)
    },
  },
  data() {
    return {
      tab: null,
      showTab: 'creditorFunding',
      selectedFundingDataIdx: null,
      smsCreditorList: null,
    }
  },
}
</script>

<style lang="scss" scoped></style>
