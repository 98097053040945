<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-autocomplete
        @update:search-input="search"
        @change="select"
        @click:clear="clear"
        :loading="isLoading"
        :disabled="isLoading"
        :disable-lookup="isLoading"
        :items="searchResultItems"
        :dense="dense"
        v-model="selectedItem"
        label="사용자 검색"
        placeholder="검색어 입력"
        return-object
        hide-details
        clearable
        ref="autocomplete"
        outlined
        no-filter
        v-bind="attrs"
        v-on="on"
      >
        <template
          v-slot:append-item
          v-if="searchResultTotalCount > searchResultMaxSize"
        >
          <div class="text-center pt-3 body-2 grey--text">
            <v-icon> mdi-dots-vertical </v-icon>
          </div>
          <div class="text-center pa-3 body-2 grey--text">
            검색 결과는 {{ searchResultMaxSize }}개까지만 표시됩니다.
          </div>
        </template>
      </v-autocomplete>
    </template>
    <span>검색 가능 : idx / id / 이름 / 핸드폰 / 이메일</span>
  </v-tooltip>
</template>
<script>
export default {
  props: {
    dense: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchDelay: 500,
      searchTimeout: null,
      searchResultItems: [],
      searchResultTotalCount: 0,
      searchResultMaxSize: 100,
      isLoading: false,
      selectedItem: null,
    }
  },
  methods: {
    getLabelText(rowData) {
      return `(${rowData.idx}) ${rowData.name} ${rowData.userPhone}`
    },
    search(e) {
      if (this.searchTimeout != null) {
        clearTimeout(this.searchTimeout)
      }

      // item 선택시 input value가 선택된 item의 text로 바뀌면서
      // v-autocomplete에서 @update:search-input 이벤트 발생
      if (this.selectedItem != null && e === this.selectedItem.text) {
        return
      }

      if (e == null || e.trim().length === 0 || this.isLoading === true) {
        return
      }

      this.searchTimeout = setTimeout(async (_) => {
        this.isLoading = true
        try {
          const q = `
            users: getUsersByKeyword(req: {
                page: 1
                size: ${this.searchResultMaxSize}
                keyword: "${e}"
              }) {
              data {
                idx
                name
                userPhone
                paymentUser {
                  userIdx
                  virtualAccounts {
                    idx
                  }
                }
              },

              paging {
                size
                page
                totalCount
              }
            }
          `

          const {
            users: {
              data,
              paging: { totalCount },
            },
          } = await this.$fundaApi.query(gql`{${q}}`)

          this.searchResultItems.splice(0, this.searchResultItems.length)
          for (let i = 0; i < data.length; i++) {
            const rowData = data[i]
            const item = {
              text: this.getLabelText(rowData),
              value: rowData.idx,
              data: rowData,
            }
            this.searchResultItems.push(item)
          }
          this.searchResultTotalCount = totalCount
        } finally {
          this.isLoading = false
          this.searchTimeout = null
          // v-autocomplete에서 disabled props가 변경되면 focus가 해제됨
          this.$nextTick((_) => {
            this.$refs.autocomplete.focus()
          })
        }
      }, this.searchDelay)
    },
    async select(e) {
      if (e == null) {
        return this.$emit('select', null)
      }

      this.$emit('select', e.data)
    },
    clear() {
      this.select(null)
    },
  },
}
</script>
