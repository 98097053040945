export default {
  name: 'ui',
  namespaced: true,
  state: {
    isMobile: false,
  },
  mutations: {
    setIsMobile(state, value) {
      state.isMobile = value
    },
  },
  actions: {
    setIsMobile({ commit }, value) {
      commit('setIsMobile', value)
    },
  },
}
