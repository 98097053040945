<template>
  <v-select
    :items="items"
    v-model="selectedValues"
    :label="label"
    outlined
    multiple
    dense
    hide-details
    item-text="name"
    item-value="code"
    prepend-inner-icon="mdi-filter-plus"
    class="mr-3"
    @blur="blur"
  >
  </v-select>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    nameSpace: {
      type: String,
      required: true,
    },
    code: {
      type: String,
      required: true,
    },
    selected: {
      type: Array,
      default: (_) => {
        return []
      },
    },
  },
  data() {
    return {
      selectedValues: this.selected,
    }
  },
  methods: {
    multiSelectToggle() {
      this.selectedValues.splice(0, this.selectedValues.length)
    },
    blur(e) {
      if (this.selected.length !== this.selectedValues.length) {
        return this.emitSelect()
      }

      const isEqualValues = this.selected.every((propValue) => {
        return (
          this.selectedValues.find(
            (selectedValue) => propValue === selectedValue
          ) != null
        )
      })
      if (isEqualValues !== true) {
        this.emitSelect()
      }
    },
    emitSelect() {
      this.$emit('update:selected', this.selectedValues)
    },
  },
  watch: {
    selectedValues(v) {
      if (this.items.length === v.length) {
        this.multiSelectToggle()
      }
    },
  },
  computed: {
    selectAll() {
      return this.selectedValues.length === 0
    },
    selectSome() {
      return this.selectedValues.length > 0
    },
    icon() {
      if (this.selectAll) return 'mdi-close-box'
      if (this.selectSome) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
    items() {
      let items =
        this.$store.getters[`${this.nameSpace}/code/getCategory`](this.code) ||
        []
      if (this.code === 'LoansLoan.Status') {
        items = items.map(item => ({
          ...item,
          name: item.name === '실행완료' ? '상환중' : item.name
        }))
      }
      return items
    },
  },
}
</script>
