export default [
  {
    name: 'fundanow/users',
    path: '/fundanow/users',
    meta: { title: '고객' },
    props: (route) => ({
      query: route.query,
    }),
    component: require('@/pages/fundanow/user/Users.vue').default,
  },
  {
    name: 'fundanow/user',
    path: '/fundanow/users/:userId',
    component: require('@/pages/fundanow/user/AbstractView.vue').default,
    props: (route) => ({ userId: Number(route.params.userId) }),
    redirect: {
      name: 'fundanow/users/detail',
    },
    children: [
      {
        name: 'fundanow/users/detail',
        alias: '',
        path: 'detail',
        meta: { title: '고객 정보' },
        props: (route) => ({ userId: Number(route.params.userId) }),
        component: require('@/pages/fundanow/user/UserDetail.vue').default,
      },
      {
        name: 'repaymentvirtualaccount/transactions',
        path: 'repaymentvirtualaccount/transactions',
        meta: { title: '상환용 가상계좌 거래내역' },
        props: (route) => ({ userId: Number(route.params.userId) }),
        component:
          require('@/pages/fundanow/user/UserRepaymentVirtualAccountTransactions.vue')
            .default,
      },
    ],
  },
]
