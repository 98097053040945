export default [
  {
    name: 'fundanow/mails',
    path: '/fundanow/mails',
    meta: { title: '메일 발송 내역' },
    component: require('@/pages/fundanow/mail/Mails.vue').default,
    props: (route) => ({
      query: route.query,
    }),
  },
  {
    name: 'fundanow/mails/detail',
    path: '/fundanow/mails/:mailId',
    props: (route) => ({
      mailId: Number(route.params.mailId),
    }),
    meta: { title: '메일 내역 상세' },
    component: require('@/pages/fundanow/mail/MailDetail.vue').default,
  },
]
