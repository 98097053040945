var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-3",staticStyle:{"padding-bottom":"100px"}},[_c('PageHeader',{attrs:{"title":"고객 인증 신청 목록"}}),_c('v-row',{staticClass:"my-3",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mb-3 pr-2",attrs:{"cols":"12","sm":"2","md":"2","lg":"2"}},[_c('v-card',[_c('v-card-text',[_c('v-btn',{staticClass:"white--text mr-2",attrs:{"color":"#393939","width":"100%","height":"40px"},on:{"click":function($event){return _vm.$router.push({ name: 'reqChangeLimit' })}}},[_vm._v(" 타입 변경 요청 ")])],1)],1)],1),_c('v-col',{staticClass:"mb-3 pr-2",attrs:{"cols":"12","sm":"2","md":"2","lg":"2"}},[_c('v-card',[_c('v-card-text',[_c('v-btn',{staticClass:"white--text",attrs:{"color":"#393939","width":"100%","height":"40px"},on:{"click":function($event){return _vm.changeStatus()}}},[_vm._v(" "+_vm._s(_vm.currentStatus === 'WAITING' ? '완료목록' : '대기목록')+" 보기 ")])],1)],1)],1),_c('v-col',{staticClass:"mb-3 pr-2",attrs:{"cols":"12","sm":"2","md":"2","lg":"2"}},[_c('v-card',[_c('v-card-text',{staticClass:"d-flex justify-space-between"},_vm._l((_vm.investmentLimitTypes),function(limitType){return _c('v-btn',{key:'btn_' + limitType.text,staticClass:"white--text mr-2",attrs:{"color":_vm.userAuthsearch.investmentLimitType === limitType.value
                ? '#1baaf1'
                : '#393939',"width":"90px","height":"40px"},on:{"click":function($event){return _vm.changeLimitType(limitType.value)}}},[_vm._v(" "+_vm._s(limitType.text)+" ")])}),1)],1)],1),_c('v-col',{staticClass:"mb-3",attrs:{"cols":"12","sm":"6","md":"6","lg":"6"}},[_c('v-card',[_c('v-card-text',[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.search(_vm.userAuthsearch.keyword)}}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"검색어","outlined":"","hide-details":"","dense":"","clearable":"","append-icon":"mdi-magnify"},on:{"click:append":function($event){return _vm.search(_vm.userAuthsearch.keyword)},"click:clear":function($event){return _vm.search(null)}},model:{value:(_vm.userAuthsearch.keyword),callback:function ($$v) {_vm.$set(_vm.userAuthsearch, "keyword", $$v)},expression:"userAuthsearch.keyword"}},'v-text-field',attrs,false),on))]}}])},[_c('span',[_vm._v(" 검색 가능: 유저인덱스 ")])])],1)])],1)],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"cols":"12"}},[_c('SendAuthTalk',{ref:"sendAuthTalk"})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',{staticClass:"align-end justify-space-between"},[_c('p',{staticClass:"ma-0"},[_vm._v(" [ "+_vm._s(_vm.currentStatus === 'WAITING' ? '대기' : '완료')+" ] 리스트 ")]),(_vm.currentStatus === 'APPROVAL')?[_c('v-btn',{attrs:{"icon":"","loading":_vm.exportExcelLoading,"disabled":_vm.exportExcelLoading},on:{"click":function($event){return _vm.downloadExcel()}}},[_c('v-icon',[_vm._v("mdi-export-variant")])],1),_c('div',{staticStyle:{"position":"absolute","opacity":"0"}},[_c('ExportExcelButton2',{ref:"btnExportExcel",attrs:{"headers":_vm.exportHeaders,"options":{ skipHeader: true },"fileName":"유저_인증완료"}})],1)]:_vm._e()],2),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"footer-props":_vm.footer,"items":_vm.userAuthList,"loading":_vm.isLoading},on:{"update:options":_vm.changePage,"click:row":_vm.openDetails},scopedSlots:_vm._u([{key:"item.investmentLimit.type",fn:function(ref){
                var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold",class:item.investmentLimit.idx > 1 ? 'error--text' : 'black--text'},[_vm._v(" "+_vm._s(item.investmentLimit.type)+" ")])]}}],null,true)})],1)],1),_c('v-dialog',{attrs:{"max-width":"1500px","width":"90%"},model:{value:(_vm.dialog.details),callback:function ($$v) {_vm.$set(_vm.dialog, "details", $$v)},expression:"dialog.details"}},[(!!_vm.selectedUser)?_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.selectedUser.idx)+" // "+_vm._s(_vm.selectedUser.id)+" // "+_vm._s(_vm.selectedUser.investmentLimit.type)+" ")])]),_c('v-card-text',[_c('keep-alive',[_c('UserAuthDetails',{key:_vm.selectedUser.id,attrs:{"userIdx":_vm.selectedUser.idx,"isApproval":_vm.currentStatus === 'APPROVAL'},on:{"decision-complete":_vm.decisionComplete}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.dialog.details = false}}},[_vm._v(" 닫기 ")])],1)],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }