<template>
  <v-container fluid class="pt-0">
    <PageHeader
      title="알림톡 발송 내역 조회"
      subtitle="알림톡 발송 내역 조회 페이지"
    />
    <v-card class="my-3" width="100%">
      <v-card-text>
        <v-row no-gutters>
          <v-col cols="12" lg="2" mg="4" sm="6">
            <MultiSelect
              v-if="selectStatusItems"
              :items="selectStatusItems"
              label="발송 상태"
              v-model="statuses"
              @select="selectStatuses"
            />
          </v-col>
          <v-col cols="12" lg="3" mg="4" sm="6">
            <v-text-field
              label="키워드 검색(제목/핸드폰 번호)"
              append-icon="mdi-magnify"
              clearable
              outlined
              dense
              hide-details
              v-model.trim="searchWord"
              @keyup.enter="search"
              @click:clear="clearSearch"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-data-table
      :items="alimtalks.data"
      :loading="isLoading"
      :headers="alimtalks.headers"
      :server-items-length="alimtalks.totalCount"
      :footer-props="alimtalks.footer"
      :options.sync="alimtalks.options"
      class="elevation-1"
      :hide-default-header="!isMobile"
    >
      <template v-slot:loading>
        <p class="text-center title my-2">Loading items...</p>
      </template>
      <!-- <template v-if="isMobile" v-slot:header="{ props: { headers, options: { sortBy, sortDesc } }, on: { sort } }">
        {{headers}}{{sortBy}}{{sortDesc}}{{sort}}
      </template> -->
      <template v-if="isMobile" v-slot:header> </template>
      <template
        v-else
        v-slot:header="{
          props: {
            headers,
            options: { sortBy, sortDesc },
          },
          on: { sort },
        }"
      >
        <thead>
          <tr>
            <th
              v-for="(header, index) in headers"
              :key="index"
              class="px-3 py-2 text-center body-2"
              :style="{ width: header.width + 'px' }"
            >
              <template v-if="header.sortable != false">
                <v-btn
                  :color="sortBy[0] != header.value ? 'black' : 'primary'"
                  text
                  @click="sort(header.value)"
                  class="px-0 font-weight-bold header__button"
                  block
                >
                  {{ header.text }}
                  <v-icon>
                    <template v-if="sortBy[0] != header.value">
                      mdi-reorder-horizontal
                    </template>
                    <template v-else-if="sortDesc[0] == true">
                      mdi-arrow-down
                    </template>
                    <template v-else> mdi-arrow-up </template>
                  </v-icon>
                </v-btn>
              </template>
              <template v-else>{{ header.text }}</template>
            </th>
          </tr>
        </thead>
      </template>

      <template v-slot:[`item.idx`]="{ item }">
        <router-link
          :to="{
            name: 'alimtalk/message/groups/detail',
            params: { messageGroupIdx: item.idx },
            query: mobileNoSearchQuery,
          }"
        >
          {{ item.idx }}
        </router-link>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <MessageGroupsStatus :status="item.status" />
      </template>

      <template v-slot:[`item.createTime`]="{ item }">
        {{ item.createTime | moment('YYYY-MM-DD HH:mm:ss') }}
      </template>

      <template v-slot:[`item.reserveTime`]="{ item }">
        {{ item.reserveTime | moment('YYYY-MM-DD HH:mm:ss') }}
      </template>

      <template v-slot:[`item.totalCount`]="{ item }">
        {{ item.totalCount | commaFormat }}
      </template>

      <template v-slot:[`item.successCount`]="{ item }">
        {{ item.successCount | commaFormat }}
      </template>

      <template v-slot:[`item.failCount`]="{ item }">
        {{ item.failCount | commaFormat }}
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import MessageGroupsStatus from '../../components/alimtalk/MessageGroupsStatus.vue'
import MultiSelect from '../../components/alimtalk/MultiSelect.vue'
import Status from '@/const/messageGroupStatus'
import { mapState } from 'vuex'

export default {
  components: {
    MessageGroupsStatus,
    MultiSelect,
  },
  props: {
    query: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      searchWord: this.query.keyword,
      queryCopy: JSON.parse(JSON.stringify(this.query)),
      isLoading: false,
      alimtalks: {
        options: null,
        totalCount: 0,
        headers: [
          { text: 'index', value: 'idx', width: '50', align: 'center' },
          {
            text: '상태',
            value: 'status',
            width: '50',
            sortable: false,
            align: 'center',
          },
          { text: '제목', value: 'title', width: '200', align: 'center' },
          {
            text: '등록 시간',
            value: 'createTime',
            width: '200',
            align: 'center',
          },
          {
            text: '예약 시간',
            value: 'reserveTime',
            width: '200',
            align: 'center',
          },
          {
            text: '총 개수',
            value: 'totalCount',
            width: '80',
            sortable: false,
          },
          {
            text: '성공 개수',
            value: 'successCount',
            width: '80',
            sortable: false,
          },
          {
            text: '실패 개수',
            value: 'failCount',
            width: '80',
            sortable: false,
          },
          //   { text: '발송 시작 시간', value: 'startTime', sortable: false },
          //   { text: '발송 실패 시간', value: 'failTime', sortable: false },
          //   { text: '발송 성공 시간', value: 'completeTime', sortable: false },
        ],
        data: [],
        footer: {
          'items-per-page-options': [1, 5, 10, 20],
        },
      },
      selectStatusItems: null,
    }
  },
  watch: {
    'alimtalks.options': {
      handler: function (options, oldValue) {
        if (oldValue == null) {
          return
        }
        const optionObj = {
          page: options.page,
          size: options.itemsPerPage,
          orderBy: options.sortBy[0] || this.alimtalks.options.sortBy[0],
          isOrderDesc: options.sortDesc[0] || false,
        }
        this.$router.push({
          name: this.$route.name,
          query: Object.assign(this.queryCopy, optionObj),
        })
      },
      deep: true,
    },
  },
  computed: {
    statuses() {
      if (this.query.statuses == null) {
        return []
      } else if (this.query.statuses instanceof Array) {
        return this.query.statuses
      } else {
        return [this.query.statuses]
      }
    },
    mobileNoSearchQuery() {
      const keyword = this.query.keyword
      if (keyword == null || keyword.length === 0) {
        return null
      }
      return /^01\d{8,9}$/.test(keyword) ||
        /^\d{3}-\d{3,4}-\d{4}$/.test(keyword)
        ? { keyword }
        : null
    },
    ...mapState('ui', ['isMobile']),
  },
  async created() {
    try {
      this.isLoading = true

      this.getSelectStatusItems()

      this.alimtalks.options = {
        sortBy: this.query.orderBy ? [this.query.orderBy] : ['idx'],
        // eslint-disable-next-line
        sortDesc: this.query.isOrderDesc != null ? [this.query.isOrderDesc === true || this.query.isOrderDesc === 'true'] : [true],
        page: this.query.page ? Number(this.query.page) : 1,
        itemsPerPage: this.query.size ? Number(this.query.size) : 20,
      }

      // const { data } = await this.$alimtalkApi.getMessageGroups({
      //   page: this.alimtalks.options.page,
      //   size: this.alimtalks.options.itemsPerPage,
      //   statuses: this.query.statuses,
      //   orderBy: this.query.orderBy,
      //   isOrderDesc: this.query.isOrderDesc,
      //   keyword: this.query.keyword,
      // })
      await this.$store.dispatch('alimtalk/message/groups/setItems', {
        page: this.alimtalks.options.page,
        size: this.alimtalks.options.itemsPerPage,
        statuses: this.query.statuses,
        orderBy: this.query.orderBy,
        isOrderDesc: this.query.isOrderDesc,
        keyword: this.query.keyword,
      })
      const data = this.$store.getters['alimtalk/message/groups/getItems']
      const paging = this.$store.getters['alimtalk/message/groups/getPaging']
      console.log(data, paging)
      this.alimtalks.totalCount = paging.totalCount
      this.alimtalks.data = data
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    getSelectStatusItems() {
      let items = []
      const keys = Object.getOwnPropertyNames(Status)
      keys.forEach((key) => {
        items.push({
          text: Status[key],
          value: key,
        })
      })
      this.selectStatusItems = items
    },
    updateParam(option) {
      this.$router.push({
        query: Object.assign(this.queryCopy, option),
      })
    },
    selectStatuses(e) {
      this.updateParam({
        page: 1,
        statuses: e,
      })
    },
    clearSearch() {
      this.searchWord = null
      this.search()
    },
    search() {
      const word = this.searchWord
      if (this.query.keyword == null && word == null) {
        return
      }
      if (this.query.keyword !== word) {
        this.updateParam({
          page: 1,
          keyword: word,
        })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.header__button {
  letter-spacing: -0.4px;
}
</style>
