<template>
  <div>
    <v-row class="my-3" no-gutters>
      <v-col cols="12" sm="12" md="12">
        <h3 class="text-center">
          <template v-if="!!repayments">
            {{ fundingDataIdx }}호 {{ fundingData.title }} [
            {{ fnStore.storeName }} ] 상환내역
          </template>
          <template v-else> 상환내역 </template>
        </h3>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col cols="12">
        <TuiGrid
          :columns="columns"
          :data="repayments"
          :summary="summary"
        ></TuiGrid>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TuiGrid from '@/components/toast/Grid'

export default {
  props: {
    fundingDataIdx: {
      type: Number,
      default: null,
    },
    fnStoreIdx: {
      type: Number,
      default: null,
    },
  },
  components: {
    TuiGrid,
  },
  computed: {
    repayments() {
      if (!!!this.fundingDataIdx || !!!this.fnStoreIdx) {
        return []
      }
      return this.fnStoreRepayments
    },
  },
  data() {
    return {
      isLoading: false,
      columns: [
        {
          header: '상환일',
          name: 'createdDate',
          align: 'center',
          sortable: true,
        },
        {
          header: '대출잔액',
          name: 'balance',
          align: 'center',
          sortable: true,
          formatter({ value }) {
            return `${Intl.NumberFormat().format(value)} 원`
          },
        },
        {
          header: '총상환액',
          name: 'totalRepayment',
          align: 'center',
          sortable: true,
          formatter({ value }) {
            return `${Intl.NumberFormat().format(value)} 원`
          },
        },
        {
          header: '상환원금',
          name: 'principal',
          align: 'center',
          sortable: true,
          formatter({ value }) {
            return `${Intl.NumberFormat().format(value)} 원`
          },
        },
        {
          header: '이자',
          name: 'interest',
          align: 'center',
          sortable: true,
          formatter({ value }) {
            return `${Intl.NumberFormat().format(value)} 원`
          },
        },
        {
          header: '연체이자',
          name: 'overdueInterest',
          align: 'center',
          sortable: true,
          formatter({ value }) {
            return `${Intl.NumberFormat().format(value)} 원`
          },
        },
        {
          header: '세이프플랜',
          name: 'isSafeplan',
          align: 'center',
          sortable: true,
          formatter({ value }) {
            return value === 1 ? '세이프' : 'X'
          },
        },
      ],
      summary: {
        height: 40,
        position: 'top',
        columnContent: {
          totalAmount: {
            template: function (valueMap) {
              return `<p class="ma-0 text-center">총합: ${Intl.NumberFormat().format(
                valueMap.sum
              )} 원</p>`
            },
          },
          principal: {
            template: function (valueMap) {
              return `<p class="ma-0 text-center">총 원금: ${Intl.NumberFormat().format(
                valueMap.sum
              )} 원</p>`
            },
          },
          interest: {
            template: function (valueMap) {
              return `<p class="ma-0 text-center">총 이자: ${Intl.NumberFormat().format(
                valueMap.sum
              )} 원</p>`
            },
          },
          overdueInterest: {
            template: function (valueMap) {
              return `<p class="ma-0 text-center">총 연체이자: ${Intl.NumberFormat().format(
                valueMap.sum
              )} 원</p>`
            },
          },
        },
      },
      fundingData: {
        title: '',
      },
      fnStore: {
        storeName: '',
      },
      fnStoreRepayments: [],
    }
  },
  created() {
    this.getRepayments()
  },
  methods: {
    async getRepayments() {
      if (!!!this.fundingDataIdx || !!!this.fnStoreIdx) {
        return null
      }

      let q = `
        fundingData: getFundingData(fundingDataIdx: ${this.fundingDataIdx}) {
          title
        }

        fnStore: getFnStoreByIdx(fnStoreIdx: ${this.fnStoreIdx}) {
          storeName
        }

        repayments: getFnStoreRepaymentsByFundingData(fundingDataIdx: ${this.fundingDataIdx}, fnStoreIdx: ${this.fnStoreIdx}) {
          idx
          paybackPresentIdx
          fundingDataIdx
          fnStoreIdx
          balance
          principal
          interest
          overdueInterest
          isSafeplan
          repaymentDate
          createdAt
          createdDate
        }
      `

      this.isLoading = true

      try {
        let { fundingData, fnStore, repayments } = await this.$fundaApi.query(
          gql`{${q}}`
        )
        repayments.forEach((v) => {
          v.totalRepayment = v.principal + v.interest + v.overdueInterest
        })
        this.fundingData = fundingData
        this.fnStore = fnStore
        this.fnStoreRepayments = repayments
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
