<template>
  <div v-if="dataTable.items">
    <v-data-table
      v-model="selected"
      :headers="dataTable.headers"
      :items="showItems"
      :options.sync="dataTable.options"
      :loading="dataTable.isLoading"
      :footer-props="dataTable.footer"
      class="elevation-2 my-3"
      hide-default-footer
      show-select
      item-key="loan.id"
      :items-per-page="-1"
    >
      <template v-slot:top>
        <v-btn
          v-for="loanType in loanTypes"
          :key="loanType.code"
          :color="selTypes.includes(loanType.code) ? 'primary' : ''"
          @click="selectType(loanType.code)"
          >{{ loanType.name }}</v-btn
        >
      </template>
      <template v-slot:[`item.id`]="{ item }">
        {{ item.loan.id }}
      </template>
      <template v-slot:[`item.loan.type`]="{ item }">
        {{ item.loan.type | fundanow.code('LoansLoan.Type') }}
      </template>
      <template v-slot:[`item.amount`]="{ item }">
        {{ item.loan.amount | commaFormat }} 원
      </template>
      <template v-slot:[`item.remainPrincipal`]="{ item: { remainPrincipal } }">
        {{ remainPrincipal | commaFormat }} 원
      </template>
      <template v-slot:[`item.executedAt`]="{ item }">
        {{ item.loan.executedAt | moment('yyyy-MM-DD') }}
      </template>
      <template v-slot:[`item.repayRatio`]="{ item }">
        <input
          label="상환비율"
          @change="inputRepayRatio(item, $event)"
          :value="item.repayRatio"
          @paste="pasteRatio(item, $event)"
          :class="{ editable: !submitted }"
          class="rounded pa-2 mr-2"
          :disabled="submitted"
        />%
      </template>
    </v-data-table>
    <v-btn v-if="!submitted" @click="selectLoans" color="primary">
      선택한 채권들 상환하기
    </v-btn>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
export default {
  props: {
    loansRepaymentPreDataId: {
      type: Number,
      required: true,
    },
    incomeAmount: {
      type: Number,
      required: true,
    },
    baseDate: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selTypes: ['normal', 'marketing', 'second_mortgage', 'installment'],
      selected: [],
      submitted: false,
      dataTable: {
        isLoading: false,
        options: null,
        headers: [
          { text: 'index', value: 'id', sortable: false },
          {
            text: 'type',
            value: 'loan.type',
            sortable: false,
          },
          { text: '실행일', value: 'executedAt', sortable: false },
          { text: '만기일', value: 'dueDate', sortable: false },
          { text: '실행금액', value: 'amount', sortable: false },
          { text: '잔여원금', value: 'remainPrincipal', sortable: false },
          {
            text: '상환비율(붙여넣기 가능)',
            value: 'repayRatio',
            sortable: false,
          },
        ],
        items: [],
        footer: {
          'items-per-page-options': [1, 5, 10, 20, 50, 100, -1],
        },
      },
    }
  },
  computed: {
    ...mapState('fundanow/code', ['code']),
    loanTypes() {
      if (!this.code || !this.code['LoansLoan.Type']) {
        return []
      }
      return this.code['LoansLoan.Type']
    },
    showItems() {
      return this.dataTable.items.filter((o) =>
        this.selTypes.includes(o.loan.type)
      )
    },
  },
  async created() {
    await this.init()
    await this.getData()
  },
  methods: {
    ...mapActions('fundanow/code', ['init']),
    async getData() {
      this.dataTable.isLoading = true

      let plans = []
      try {
        const { data } =
          await this.$fundaNowApi.getLoansRepaymentPreDataTargetLoans(
            this.loansRepaymentPreDataId
          )
        for (let i = 0; i < data.length; i++) {
          const loan = data[i]

          // 미상환 금액에 대한 조회
          let { data: receivableData } =
            await this.$fundaNowApi.getLoansReceivables(loan.id)

          let { data: plan } = await this.$fundaNowApi.getLoansRepaymentPlan(
            loan.id,
            {
              isPrepayment: true,
              repayDate: this.baseDate,
            }
          )

          // plan.loan = loan
          plan.repayRatio = 0
          // 미상환 금액을 설정해준다.
          plan.unpaidInterest = receivableData.interest
          plan.unpaidOverdueInterest = receivableData.overdueInterest
          plan.unpaidFee = receivableData.fee
          plan.unpaidLegalFee = receivableData.legalExpenses
          plans.push(plan)
        }
      } catch (e) {
        this.$emit('update:baseDate', null)

        if (e && e.data && e.data.message) {
          this.$swal.basic.alert({
            title: '상환일 설정 오류',
            text: e.data.message,
          })
        }

        throw e
      } finally {
        this.dataTable.isLoading = false
      }
      this.dataTable.items = plans
    },
    inputRepayRatio(item, e) {
      this.setRatio(item, e.target.value)
    },
    setRatio(item, value) {
      let ratio = isNaN(Number(value.replace(/[^\d.]/g, '')))
        ? 0
        : Number(value.replace(/[^\d.]/g, ''))
      item.repayRatio = ratio
      item.repaySource = Math.floor((this.incomeAmount / 100) * ratio)
      this.$set(this.dataTable.items, this.dataTable.items.indexOf(item), item)
    },
    pasteRatio(item, e) {
      const clipboardData = e.clipboardData.getData('text').trim()
      if (clipboardData.indexOf('\n') !== -1) {
        e.preventDefault()
        const ratios = clipboardData.split('\n')
        let startIndex = this.dataTable.items.indexOf(item)
        for (let i = 0; i < ratios.length; i++) {
          const ratio = ratios[i]
          if (this.dataTable.items.length - 1 < startIndex + i) {
            break
          }
          let item = this.dataTable.items[startIndex + i]
          this.setRatio(item, ratio)
        }
      }
    },
    selectLoans() {
      if (this.selected.length < 1) {
        this.$swal.basic.alert({
          title: '상환 대상 없음',
          text: '선택된 채권이 없습니다.',
        })
        return
      }
      this.$emit('select', this.selected)
      this.submitted = true
    },
    selectType(type) {
      const r = this.selTypes.indexOf(type)
      if (r < 0) {
        this.selTypes.push(type)
      } else {
        this.selTypes.splice(r, 1)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
input {
  height: 40px;
}
.editable {
  border: 1px solid #08a7f6;
  font-weight: 900;
}
.input:disabled {
  cursor: not-allowed;
}
</style>
