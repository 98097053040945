<template>
  <div>
    <v-row class="my-3" no-gutters>
      <v-col cols="4" md="4"></v-col>
      <v-col cols="4" md="4">
        <h3 class="ma-0 text-center">상환 상세</h3>
      </v-col>
      <v-col cols="4" md="4" class="d-flex align-center justify-end">
        <v-btn
          color="#393939"
          class="white--text mr-2"
          depressed
          @click="passCreditorList(false)"
        >
          전체 내보내기
        </v-btn>

        <v-btn
          color="#393939"
          class="white--text mr-2"
          depressed
          @click="passCreditorList(true)"
        >
          선택 내보내기
        </v-btn>
      </v-col>
    </v-row>

    <v-row class="mb-3" no-gutters>
      <v-col cols="12" sm="12" md="12">
        <v-data-table
          no-data-text="상환 기록이 없습니다."
          no-results-text="상환 기록이 없습니다."
          loading-text="데이터를 가져오는 중입니다..."
          :headers="repaymentHeaders"
          :loading="isLoading"
          :items="repayments"
          show-select
        >
          <template v-slot:item="{ item }">
            <tr class="clickable-data-row cursor--pointer">
              <td>
                <v-checkbox
                  v-model="selectedRepayments"
                  :value="item"
                  color="indigo darken-3"
                  style="margin: 0px; padding: 0px"
                  hide-details
                ></v-checkbox>
              </td>
              <td class="text-center">
                {{ item.userIdx }}
              </td>
              <td class="text-center">
                {{ item.userId }}
              </td>
              <td class="text-center font-weight-bold">
                {{ item.userName }}
              </td>
              <td class="text-center">
                {{ item.userPhone }}
              </td>
              <td class="text-center">
                {{ item.userEmail }}
              </td>
              <td class="text-center font-weight-bold">
                {{ item.fundingDataIdx }}
              </td>
              <td class="text-center font-weight-bold">
                {{ item.fundingDataTitle }}
              </td>
              <td class="text-center font-weight-bold">
                {{ item.totalAmountAfterTax | commaFormat }}
              </td>
              <td class="text-center">
                {{ item.repaymentPrincipal | commaFormat }}
              </td>
              <td class="text-center">
                {{ item.repaymentInterest | commaFormat }}
              </td>
              <td class="text-center">
                {{ item.repaymentOverdueInterest | commaFormat }}
              </td>
              <td class="text-center">
                {{ item.repaymentAdditionalInterest | commaFormat }}
              </td>
              <td class="text-center">
                {{ item.repaymentTax | commaFormat }}
              </td>
              <td class="text-center">
                {{ item.repaymentFee | commaFormat }}
              </td>
              <td class="text-center">
                {{ item.repaymentTime | dateFormat2 }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    fundingDataRepaymentIdx: {
      type: Number,
      default: null,
    },
  },
  watch: {
    fundingDataRepaymentIdx: {
      immediate: true,
      handler(v) {
        if (!!v) {
          this.getCreditorRepayments()
        }
      },
    },
  },
  data: function () {
    return {
      isLoading: false,
      modal: false,
      repaymentDate: null,
      repaymentHeaders: [
        { text: '유저인덱스', value: 'userIdx', align: 'center' },
        { text: '아이디', value: 'userId', align: 'center', sortable: false },
        { text: '이름', value: 'userName', align: 'center', sortable: false },
        {
          text: '휴대폰',
          value: 'userPhone',
          align: 'center',
          sortable: false,
        },
        {
          text: '이메일',
          value: 'userEmail',
          align: 'center',
          sortable: false,
        },
        { text: '상품호수', value: 'fundingDataIdx', align: 'center' },
        { text: '상품명', value: 'fundingDataTitle', align: 'center' },
        { text: '총상환액', value: 'totalAmountAfterTax', align: 'center' },
        { text: '상환원금', value: 'repaymentPrincipal', align: 'center' },
        { text: '상환이자', value: 'repaymentInterest', align: 'center' },
        {
          text: '연체이자',
          value: 'repaymentOverdueInterest',
          align: 'center',
          sortable: false,
        },
        {
          text: '추가이자',
          value: 'repaymentAdditionalInterest',
          align: 'center',
          sortable: false,
        },
        {
          text: '세금',
          value: 'repaymentTax',
          align: 'center',
          sortable: false,
        },
        {
          text: '수수료',
          value: 'repaymentFee',
          align: 'center',
          sortable: false,
        },
        {
          text: '상환일',
          value: 'repaymentTime',
          align: 'center',
          sortable: false,
        },
      ],
      repayments: [],
      selectedRepayments: [],
      selectedRepayment: {},
    }
  },
  created: function () {},
  methods: {
    datePick() {
      this.$refs.dialog.save(this.repaymentDate)
      // this.getCreditorRepayments()
    },
    selectRepayment(item) {
      console.log('selectCreditorRepayment!')
      this.selectedRepayment = item
      this.$emit('selectCreditorRepayment', item)
    },
    async passCreditorList(selectedOnly) {
      let yes = await this.$swal.basic.confirm('문자목록으로 보냅니다?')

      if (yes.isConfirmed === false) {
        return
      }

      let temp = []
      if (selectedOnly === false) {
        temp = this.repayments
      } else {
        temp = this.selectedRepayments
      }

      console.log('passCreditorList!')
      this.$emit('passCreditorList', {
        headers: this.repaymentHeaders,
        item: temp,
      })
    },
    async getCreditorRepayments() {
      if (!!!this.fundingDataRepaymentIdx) {
        return
      }

      this.isLoading = true

      let q = `
          data: getCreditorRepaymentsByRepayment(fundingDataRepaymentIdx: ${this.fundingDataRepaymentIdx})
        `

      try {
        let data = await this.$fundaApi.query(gql`{${q}}`)

        data = JSON.parse(data)
        this.repayments = data
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
