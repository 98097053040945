<template>
  <div>
    <v-progress-linear v-if="isLoading" indeterminate />
    <v-row>
      <v-col cols="12" lg="6" md="6" sm="6">
        <v-card>
          <v-card-title> 보내는 계좌 </v-card-title>
          <v-card-text>
            <SystemUserSelect
              :userVirtualAccountIdx="fromAccount ? fromAccount.idx : null"
              :isDisabled="isLoading"
              @change="fromAccount = $event"
            />
            <v-spacer class="my-3" />
            <PaymentUserVirtualAccountAutocomplete
              :propsAccount="fromAccount"
              :propsIsLoading="isLoading"
              @select="fromAccount = $event"
              ref="fromAccountAutocomplete"
            />
          </v-card-text>
          <v-slide-y-transition>
            <v-card-text v-if="fromAccount">
              <PaymentUserTransferVirtualAccount
                :userIdx="fromAccount.userIdx"
                :userVirtualAccountIdx="fromAccount.idx"
              />
            </v-card-text>
          </v-slide-y-transition>
        </v-card>
      </v-col>
      <v-col cols="12" lg="6" md="6" sm="6">
        <v-card>
          <v-card-title> 받는 계좌 </v-card-title>
          <v-card-text>
            <SystemUserSelect
              :userVirtualAccountIdx="toAccount ? toAccount.idx : null"
              :isDisabled="isLoading"
              @change="toAccount = $event"
            />
            <v-spacer class="my-3" />
            <PaymentUserVirtualAccountAutocomplete
              :propsAccount="toAccount"
              :propsIsLoading="isLoading"
              @select="toAccount = $event"
              ref="toAccountAutocomplete"
            />
          </v-card-text>
          <v-slide-y-transition>
            <v-card-text v-if="toAccount">
              <PaymentUserTransferVirtualAccount
                :userIdx="toAccount.userIdx"
                :userVirtualAccountIdx="toAccount.idx"
              />
            </v-card-text>
          </v-slide-y-transition>
        </v-card>
      </v-col>
    </v-row>
    <v-slide-y-transition>
      <ValidationObserver ref="validForm" v-slot="{ handleSubmit, invalid }">
        <form @submit.prevent="handleSubmit(transfer)">
          <v-row v-if="fromAccount && toAccount">
            <v-col cols="12" lg="4" md="4" sm="4">
              <ValidationProvider
                v-slot="{ errors }"
                name="amount"
                :rules="`required|numeric|min_value:1|max_value:${fromAccount.balance}`"
              >
                <v-text-field
                  label="보낼 금액"
                  v-model="amount"
                  v-input-comma-format
                  :error-messages="errors"
                  :disabled="isLoading"
                  autofocus
                />
              </ValidationProvider>
            </v-col>
            <v-col cols="12" lg="4" md="4" sm="4">
              <ValidationProvider
                v-slot="{ errors }"
                name="remark"
                rules="required|min:2"
              >
                <v-text-field
                  label="remark"
                  v-model="remark"
                  :error-messages="errors"
                  :disabled="isLoading"
                />
              </ValidationProvider>
            </v-col>
            <v-col cols="12" lg="4" md="4" sm="4">
              <v-btn
                color="primary"
                block
                large
                :disabled="invalid || isLoading"
                type="submit"
              >
                실행
              </v-btn>
            </v-col>
          </v-row>
        </form>
      </ValidationObserver>
    </v-slide-y-transition>
  </div>
</template>
<script>
import SystemUserSelect from '../common/select/SystemUserSelect.vue'
import PaymentUserTransferVirtualAccount from './TransferVirtualAccount.vue'
import PaymentUserVirtualAccountAutocomplete from './VirtualAccountAutocomplete.vue'

export default {
  components: {
    PaymentUserVirtualAccountAutocomplete,
    PaymentUserTransferVirtualAccount,
    SystemUserSelect,
  },
  props: {
    propsFromAccount: {
      type: Object,
      default: (_) => {
        return null
      },
    },
  },
  data() {
    return {
      fromAccount: this.propsFromAccount,
      toAccount: null,
      amount: null,
      remark: null,
      isLoading: false,
    }
  },
  watch: {
    fromAccount(v) {
      if (v != null && this.toAccount == null) {
        this.$refs.toAccountAutocomplete.$refs.autocomplete.focus()
      }
    },
    isLoading(v) {
      this.$emit('loading', v)
    },
  },
  mounted() {
    this.$nextTick((_) => {
      this.$refs[
        `${this.fromAccount != null ? 'to' : 'from'}AccountAutocomplete`
      ].$refs.autocomplete.focus()
    })
  },
  // destroyed() {
  //   this.$swal.close()
  // },
  methods: {
    async transfer() {
      if (
        this.isLoading === true ||
        this.fromAccount == null ||
        this.toAccount == null
      ) {
        return
      }

      let loadingAlert
      try {
        this.isLoading = true

        let btnResult = await this.$swal.basic.confirm({
          title: '가상계좌간 이체',
          text: '이체하시겠습니까?',
          cancelButtonColor: 'red',
          focusConfirm: true,
        })

        if (btnResult.isConfirmed !== true) {
          return
        }

        loadingAlert = this.$swal.basic.loading()
        await this.$paymentApi.postUserVirtualAccountTransfer({
          from: {
            userVirtualAccountIdx: this.fromAccount.idx,
          },
          to: {
            userVirtualAccountIdx: this.toAccount.idx,
          },
          amount: this.amount,
          remark: this.remark,
        })

        loadingAlert.close()
        loadingAlert = null
        await this.$swal.basic.alert({
          title: '이체 완료',
          text: '이체 완료되었습니다.',
        })
        this.$emit('success')
      } finally {
        this.isLoading = false
        if (loadingAlert != null) {
          loadingAlert.close()
        }
      }
    },
  },
}
</script>
