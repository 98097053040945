import client from '../client'

export default {
  /**
   * 고객 상환용 가상계좌 자동 상환여부 변경
   * @param {*} virtualAccountId
   * @param {*} body
   * @returns
   */
  postVirtualAccountAutoStatus(virtualAccountId, body) {
    return client.call({
      url: `/funda/paygate/virtual/account/${virtualAccountId}/auto/status`,
      method: 'POST',
      body,
    })
  },
  /**
   * 고객 상환용 가상계좌 자동 상환여부 조회
   * @param {*} userId
   * @returns
   */
  getVirtualAccountAutoStatus(virtualAccountId) {
    return client.call({
      url: `/funda/paygate/virtual/account/${virtualAccountId}/auto/status`,
      method: 'GET',
    })
  },
}
