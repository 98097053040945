<template>
  <div>
    <p v-if="title != null" class="title mb-2 font-weight-bold">{{ title }}</p>
    <SystemUserSelect
      class="mb-3"
      :userVirtualAccountIdx="vAccount ? vAccount.idx : null"
      @change="changeAccount"
    />
    <VirtualAccountAutocomplete
      :propsAccount="vAccount"
      @select="changeAccount"
    />
    <v-slide-y-transition>
      <div class="mt-3" v-if="vAccount != null">
        <v-card style="border: 1px solid #00000061" elevation="0">
          <v-card-title>
            <template v-if="!!vAccount.label">{{ vAccount.label }}</template>
            <template v-else>{{ vAccount.accountName }}</template>
            <span class="mx-1">(</span>
            <router-link
              target="_blank"
              :to="{
                name: 'payment/users/detail',
                params: {
                  userIdx: vAccount.userIdx,
                },
              }"
            >
              {{ vAccount.userIdx }}
            </router-link>
            <span class="mx-1">/</span>
            <router-link
              target="_blank"
              :to="{
                name: 'payment/users/virtual/accounts/transactions',
                params: {
                  userIdx: vAccount.userIdx,
                  userVirtualAccountIdx: vAccount.idx,
                },
              }"
            >
              {{ vAccount.idx }}
            </router-link>
            <span class="mx-1">)</span>
          </v-card-title>
          <v-card-text>
            <v-list class="py-0">
              <v-list-item class="px-0">
                <v-list-item-content class="py-0">
                  <v-list-item-subtitle
                    class="d-flex align-center flex-wrap"
                    :class="
                      isFullLetter(vAccount.accountName.trim()) ? 'ml-n2' : ''
                    "
                  >
                    {{ vAccount.accountName.trim() }}
                    <v-chip
                      x-small
                      class="ml-1 px-2 font-weight-bold"
                      :color="$colors['f-blue-grey-lighten-4']"
                      :text-color="$colors['f-blue-grey-darken-1']"
                    >
                      {{
                        vAccount.type | payment.code('UserVirtualAccount.Type')
                      }}
                    </v-chip>
                  </v-list-item-subtitle>
                  <v-list-item-title>{{
                    vAccount.accountNo
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="px-0">
                <v-list-item-content class="py-0">
                  <v-list-item-subtitle>잔액</v-list-item-subtitle>
                  <v-list-item-title
                    >{{ vAccount.balance | commaFormat }} 원</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </div>
    </v-slide-y-transition>
  </div>
</template>
<script>
import SystemUserSelect from '../../../../common/select/SystemUserSelect.vue'
import VirtualAccountAutocomplete from '../../../VirtualAccountAutocomplete.vue'
export default {
  components: { SystemUserSelect, VirtualAccountAutocomplete },
  props: {
    vAccount: {
      type: Object,
      default: (_) => {
        return null
      },
    },
    title: {
      type: String,
      default: null,
    },
  },
  methods: {
    changeAccount(account) {
      this.$emit('update:vAccount', account)
    },
    isFullLetter(value) {
      if (/^[가-힣]/.test(value)) {
        return false
      }
      const c = value.charCodeAt(0)
      if (c < 256 || (c >= 0xff61 && c <= 0xff9f)) {
        return false
      }
      return true
    },
  },
}
</script>
