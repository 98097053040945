<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12">
        <v-data-table
          no-data-text="추천한 유저가 없습니다"
          :headers="headers"
          :loading="isLoading"
          :items="usedCodes"
          :footer-props="{ 'items-per-page-options': [10, 20, -1] }"
        >
          <template v-slot:item="{ item }">
            <tr class="cursor--pointer clickable-data-row">
              <td class="text-center">
                <a @click="openDetails(item.signInUser.idx)">
                  {{ item.signInUser.idx }}
                </a>
              </td>
              <td class="text-center">
                <a @click="openDetails(item.signInUser.idx)">
                  {{ item.signInUser.id }}
                </a>
              </td>
              <td class="text-center">{{ item.signInUser.name }}</td>
              <td class="text-center">
                {{ item.signInUser.registerDate | datetimeFormat }}
              </td>
              <td class="text-center">{{ item.signInUser.memo }}</td>
              <td
                class="text-center"
                :class="item.providedToRecommender ? 'blue--text' : 'red--text'"
              >
                {{ item.providedToRecommender ? '지급' : '미지급' }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    recommenderCode: {
      type: String,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      headers: [
        {
          text: '인덱스',
          value: 'signInUser.idx',
          align: 'center',
          sortable: false,
        },
        {
          text: '유저아이디',
          value: 'signInUser.id',
          align: 'center',
          sortable: false,
        },
        {
          text: '이름',
          value: 'signInUser.name',
          align: 'center',
          sortable: false,
        },
        {
          text: '가입일',
          value: 'signInUser.registerDate',
          align: 'center',
          sortable: false,
        },
        {
          text: '메모',
          value: 'signInUser.memo',
          align: 'center',
          sortable: false,
        },
        {
          text: '포인트지급여부',
          value: 'providedToRecommender',
          align: 'center',
          sortable: false,
        },
      ],
      usedCodes: [],
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      let q = `
          data: getUsersByInputRecommenderCode(inputRecommenderCode: "${this.recommenderCode}") {
            providedToRecommender
            
            signInUser {
              idx
              id
              name
              email
              userPhone
              registerDate
              investmentLimitType
              memo
            }
          }
        `

      this.isLoading = true

      try {
        let data = await this.$fundaApi.query(gql`{${q}}`)

        this.usedCodes = data
      } finally {
        this.isLoading = false
      }
    },
    openDetails(idx) {
      let r = this.$router.resolve({
        name: 'fundaUserDetails',
        params: { userIdx: idx },
      })

      window.open(r.href, '_blank')
    },
  },
}
</script>

<style lang="scss" scoped></style>
