<template>
  <v-dialog v-model="dialog" width="800">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        large
        color="primary"
        dark
        block
        :disabled="isLoading"
        v-bind="attrs"
        v-on="on"
      >
        템플릿 엑셀 업로드
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="headline grey lighten-2">
        템플릿 엑셀 업로드
        <v-spacer></v-spacer>
        <v-btn
          color="black"
          text
          icon
          x-large
          absolute
          right
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-subtitle class="grey lighten-2 pb-3 mb-4">
        지금은 한 건씩만 등록 가능합니다.
      </v-card-subtitle>
      <v-card-text>
        <FileUploadButton
          :isLoading="isLoading"
          singleFile
          :files.sync="file"
          @update:files="updateFile"
          block
        />
      </v-card-text>
      <template v-if="template">
        <v-divider></v-divider>
        <v-card-text class="pt-3">
          <v-row>
            <v-col cols="12" lg="6" md="6" sm="6">
              <TemplatePreview :template="template" :variables="variables" />
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="6">
              <v-card class="mb-3">
                <v-card-text class="py-1">
                  <v-list class="pa-0">
                    <v-list-item class="pa-0">
                      <v-list-item-content>
                        <v-list-item-subtitle>프로필(id)</v-list-item-subtitle>
                        <v-list-item-title
                          >{{ profile.name }}({{
                            profile.channelId
                          }})</v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item class="pa-0">
                      <v-list-item-content>
                        <v-list-item-subtitle
                          >템플릿(코드)</v-list-item-subtitle
                        >
                        <v-list-item-title
                          >{{ template.name }}({{
                            template.id
                          }})</v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
              <TemplateOption :template="template" :variables="variables" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text large @click="save(false)"> 저장 </v-btn>
          <v-btn color="primary" text large @click="save(true)">
            저장 후 발송
          </v-btn>
        </v-card-actions>
      </template>
    </v-card>
  </v-dialog>
</template>
<script>
import XLSX from 'xlsx'
import FileUploadButton from '../../../components/common/form/FileUploadButton.vue'
import TemplateOption from './TemplateOption.vue'
import TemplatePreview from './TemplatePreview.vue'
import { getVariables } from '../../../service/alimtalk/AlimtalkService'

export default {
  components: { FileUploadButton, TemplatePreview, TemplateOption },
  data() {
    return {
      dialog: false,
      file: null,
      profiles: null,
      profile: null,
      template: null,
      variables: [],
      isLoading: false,
    }
  },
  methods: {
    async getProfile(name) {
      if (this.profiles == null) {
        const { data: profiles } = await this.$alimtalkApi.getProfiles()
        this.profiles = profiles
      }
      this.profile = this.profiles.find((profile) => profile.name === name)
      return this.profile
    },
    async updateFile(file) {
      try {
        if (/\.xlsx$/.test(file.name) === false) {
          await this.$swal.basic.error({
            title: '파일 형식 오류',
            text: 'xlsx형식의 파일만 업로드 가능합니다.',
          })
          return
        }

        await this.readFile(file)
      } catch (e) {
        await this.$swal.basic.error({
          title: '업로드 실패',
          text: e.message,
        })
      }
    },
    readFile(file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader()

        reader.onload = async (e) => {
          let data = reader.result
          let workbook = XLSX.read(data, {
            type: 'binary',
            // cellText: false,
            cellDates: true,
            dateNF: 'yyyy-mm-dd',
          })
          const sheet = workbook.Sheets[workbook.SheetNames[0]]
          const rows = XLSX.utils.sheet_to_json(sheet, {
            raw: false,
            // dateNF: 'yyyy-mm-dd',
          })
          // console.log(rows)
          //   const header = rows[0]
          const content = rows[1]
          if (content == null || content['템플릿코드'] == null) {
            return reject(new Error('양식에 맞는 파일이 아닙니다'))
          }

          const profile = await this.getProfile(content['업체명'])

          let buttons = []
          for (let i = 0; i < 5; i++) {
            let name = `버튼${i + 1}`
            if (content[name] == null) {
              break
            }

            const mergedCellName = '__EMPTY'

            const nameColIdx = i * 5
            let nameColName = mergedCellName
            if (i > 0) {
              nameColName += `_${nameColIdx}`
            }

            const linkColIdx = nameColIdx + 1
            const linkColName = mergedCellName + '_' + linkColIdx

            buttons.push({
              link: content[linkColName],
              name: content[nameColName],
              type: 'WL',
            })
          }

          let template = {
            id: content['템플릿코드'],
            name: content['템플릿명'],
            profileIdx: profile.idx,
            content: content['템플릿내용'],
          }

          if (buttons.length > 0) {
            template.buttons = buttons
          }

          this.template = template
          this.variables = getVariables(template)
          return resolve(template)
        }

        reader.readAsBinaryString(file)
      })
    },
    async save(isAfterSend) {
      if (this.isLoading === true) {
        return
      }

      this.isLoading = true
      try {
        let btnResult = await this.$swal.basic.confirm({
          title: '템플릿 등록',
          text: '템플릿을 등록하시겠습니까?',
          cancelButtonColor: 'red',
        })

        if (btnResult.isConfirmed !== true) {
          return
        }

        let form = {
          id: this.template.id,
          name: this.template.name,
          content: this.template.content,
          buttons: this.template.buttons,
        }

        const { data: template } =
          await this.$alimtalkApi.postProfilesTemplates(
            this.template.profileIdx,
            form
          )
        await this.$swal.basic.alert({
          title: '템플릿 등록 완료',
          text: '템플릿을 등록이 완료되었습니다.',
        })

        if (isAfterSend === true) {
          this.$router.push({
            name: 'alimtalk/templates/detail/send/form',
            params: { idx: template.idx },
          })
        } else {
          this.$router.push({
            name: 'alimtalk/templates/detail',
            params: { templateIdx: template.idx },
          })
        }
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
