import qs from 'query-string'
import client from '../client'

export default {
  getIndex() {
    return client.call({
      url: '/',
      method: 'GET',
    })
  },
  /**
   * 수동 상환 대상건 조회(임시)
   * @returns
   */
  refund(loansRepaymentPreDataId) {
    return client.call({
      url: `/loans/repayment/predata/manual/${loansRepaymentPreDataId}/refund`,
      method: 'PUT',
    })
  },
  /**
   * 수동 상환 대상건 조회(임시)
   * @returns
   */
  getLoansRepaymentPreDataManualRepaymentTargets() {
    return client.call({
      url: '/loans/repayment/predata/manual/targets',
      method: 'GET',
    })
  },
  /**
   * 수동 상환 내역 조회(임시)
   * @returns
   */
  getLoansRepaymentPreDataManualRepaymentHistories() {
    return client.call({
      url: '/loans/repayment/predata/manual/histories',
      method: 'GET',
    })
  },
  /**
   * loansRepaymentPreData id로 조회
   * @param {*} loansRepaymentPreDataId
   * @returns
   */
  getLoansRepaymentPreData(loansRepaymentPreDataId) {
    return client.call({
      url: '/loans/repayment/predata/' + loansRepaymentPreDataId,
      method: 'GET',
    })
  },
  /**
   * LoansRepaymentPreData 의 상환 대상 채권 목록 조회
   * @param {*} loansRepaymentPreDataId
   * @returns
   */
  getLoansRepaymentPreDataTargetLoans(loansRepaymentPreDataId) {
    return client.call({
      url: `/loans/repayment/predata/${loansRepaymentPreDataId}/loans`,
      method: 'GET',
    })
  },
  /**
   * 상환 예상금액 계산
   * @param {*} loanId
   * @param {*} param
   * @returns
   */
  getLoansRepaymentPlan(loanId, param) {
    return client.call({
      url: `/loans/${loanId}/repayment/plan?${qs.stringify(param)}`,
      method: 'GET',
    })
  },
  /**
   * 채권 잔여 원금 조회
   * @param {*} loanId
   * @returns
   */
  getLoanRemainPrincipal(loanId) {
    return client.call({
      url: `/loans/${loanId}/remain/principal`,
      method: 'GET',
    })
  },
  /**
   * @param {*} loanId
   * @returns
   */
  getLoan(loanId) {
    return client.call({
      url: `/loans/${loanId}`,
      method: 'GET',
    })
  },
  /**
   * @param {*} loanId
   * @returns
   */
  getLoansOffer(loanId) {
    return client.call({
      url: `/loans/${loanId}/offer`,
      method: 'GET',
    })
  },

  /**
   * 대출 상환 내역 조회
   * @param {*} loanId
   * @returns
   */
  getLoansRepayments(loanId) {
    console.log('!!!!!!!!lloanId', loanId)
    return client.call({
      url: `/loans/${loanId}/repayments`,
      method: 'GET',
    })
  },

  /**
   * 수기상환
   * @param {*} loansRepaymentPreDataId
   * @returns
   */
  postLoansRepaymentPreDataManualRepayment(loansRepaymentPreDataId, body) {
    return client.call({
      url: `/loans/repayment/predata/${loansRepaymentPreDataId}/manual/repayment`,
      method: 'POST',
      body,
    })
  },
  /**
   * LoansRepaymentPreData 페이징 조회
   *
   * @param {*} param
   * @returns
   */
  getLoansRepaymentPreDataPage(param) {
    return client.call({
      url: `/loans/repayment/predata?${qs.stringify(param, { encode: false })}`,
      method: 'GET',
    })
  },
  /**
   * LoansRepaymentPreDataTransaction 조회
   *
   * @param {*} loansRepaymentPreDataId
   * @returns
   */
  getLoansRepaymentPreDataTransactions(loansRepaymentPreDataId) {
    return client.call({
      url: `/loans/repayment/predata/${loansRepaymentPreDataId}/transactions`,
      method: 'GET',
    })
  },

  /**
   * 미수 이자, 수수료, 법무비 내역  조회
   *
   * @param {*} loanId
   * @returns
   */
  getLoansReceivables(loanId) {
    return client.call({
      url: `/loans/${loanId}/receivables`,
      method: 'GET',
    })
  },
  getFundaPaygateLoan(loanId) {
    return client.call({
      url: `/loans/${loanId}/funda/paygate/loan`,
      method: 'GET',
    })
  },
  /**
   * 상환 계획 조회(현재는 아파트론 용)
   *
   * @param {*} loanId
   * @param {*} param
   * @returns
   */
  getLoansRepaymentsPlan(loanId, param) {
    return client.call({
      url: `/loans/${loanId}/repayments/plan?${qs.stringify(param, {
        encode: false,
      })}`,
      method: 'GET',
    })
  },
  /**
   * 상환 방식 조회(현재는 아파트론 용)
   * @param {*} loanId
   * @returns
   */
  getLoansRepaymentsMethod(loanId) {
    return client.call({
      url: `/loans/${loanId}/repayments/method`,
      method: 'GET',
    })
  },
  /**
   * 다음 상환일 조회(현재는 아파트론 용)
   * @param {*} loanId
   * @returns
   */
  getNextRepaymentDate(loanId) {
    return client.call({
      url: `/loans/${loanId}/next-repayment-date`,
      method: 'GET',
    })
  },
  /**
   * 만기일 조회(현재는 아파트론 용)
   * @param {*} loanId
   * @returns
   */
  getDueDate(loanId) {
    return client.call({
      url: `/loans/${loanId}/due-date`,
      method: 'GET',
    })
  },
}
