<template>
  <v-btn
    color="error"
    :loading="isLoading"
    :disabled="!isCancelable"
    @click="cancelRefund"
  >
    취소
  </v-btn>
</template>
<script>
export default {
  props: {
    refund: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      isCancelable: false,
    }
  },
  created() {
    this.getIsCancelable()
  },
  watch: {
    refund() {
      this.getIsCancelable()
    },
  },
  methods: {
    getIsCancelable() {
      const startTime = this.$moment().subtract(15, 'minutes')
      const endTime = this.$moment().subtract(1, 'minutes')
      const createTime = this.$moment(this.refund.createTime)

      return (this.isCancelable =
        createTime.isBefore(endTime) &&
        createTime.isAfter(startTime) &&
        this.refund.status === 'TRANSFER_BYPASS_SUCCESS')
    },
    async cancelRefund() {
      if (this.isLoading === true) {
        return
      }
      try {
        if (this.getIsCancelable() === false) {
          return this.$swal.basic.alert({
            title: '취소 불가',
            text: '취소할 수 없는 상태입니다.',
          })
        }
        this.isLoading = true

        let btnResult = await this.$swal.basic.confirm({
          title: '반환 취소',
          text: '반환 취소하시겠습니까?',
          cancelButtonColor: 'red',
        })

        if (btnResult.isConfirmed !== true) {
          return
        }

        const { data } = await this.$paymentApi.deleteUserRefund(
          this.refund.userIdx,
          this.refund.userVirtualAccountIdx,
          this.refund.idx
        )
        await this.$swal.basic.alert({
          title: '취소 완료',
          text: '반환 취소 완료되었습니다.',
        })

        this.$emit('update:refund', data)
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
