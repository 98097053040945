<template>
  <v-container fluid class="pt-0">
    <PageHeader title="메일 상세 조회" subtitle="메일 상세 조회"> </PageHeader>
    <v-row v-if="loan">
      <v-col lg="4" md="6" sm="6" cols="12">
        <Loan :loanId="loan.id" />
      </v-col>
      <v-col lg="4" md="6" sm="6" cols="12">
        <LoansOffer :loanId="loan.id" @update:userId="userId = $event" />
      </v-col>
      <v-col lg="4" md="6" sm="6" cols="12" v-if="userId">
        <User :userId="userId" />
      </v-col>
    </v-row>
    <v-row>
      <v-col lg="6" md="6" sm="6" cols="12">
        <PageSection
          :title="`메일 정보(index:${mail ? mail.id : ''})`"
          :isLoading="false"
        >
          <template slot="content" v-if="mail">
            <v-simple-table dense>
              <template v-slot:default>
                <tbody>
                  <template v-if="mail">
                    <tr>
                      <td colspan="2">제목 : {{ mail.subject }}</td>
                    </tr>
                    <tr>
                      <td>발신 : {{ mail.fromAddr }}</td>
                      <td>수신 : {{ mail.toAddr }}</td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        발송 시간 :
                        {{ mail.created | moment('yyyy-MM-DD HH:mm:ss') }}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        refId : {{ mail.refId ? mail.refId : '-' }}
                      </td>
                    </tr>
                    <tr v-if="loan">
                      <td>
                        <router-link
                          :to="{
                            name: 'fundanow/loan',
                            params: { loanId: loan.id },
                          }"
                        >
                          연결된 채권({{ loan.id }})
                        </router-link>
                      </td>
                      <td>
                        상태 :
                        {{ mail.status | fundanow.code('MailMail.Status') }}
                      </td>
                    </tr>
                  </template>
                </tbody>
              </template>
            </v-simple-table>
          </template>
        </PageSection>
      </v-col>
      <v-col lg="6" md="6" sm="6" cols="12">
        <PageSection title="첨부파일 목록" :isLoading="false">
          <template slot="content" v-if="attachments">
            <v-list>
              <v-list-item-group
                @change="selectAattachment"
                v-model="selectedAattachment"
                color="primary"
              >
                <v-list-item
                  :value="item"
                  v-for="(item, i) in attachments"
                  :key="i"
                >
                  <v-list-item-avatar>
                    <v-icon color="primary">mdi-file-document-outline</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="item.fileName"
                      class="primary--text font-weight-bold"
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </template>
        </PageSection>
      </v-col>
    </v-row>
    <v-row>
      <v-col lg="6" md="6" sm="6" cols="12">
        <PageSection title="메일 내용" :isLoading="false">
          <template slot="content" v-if="mail">
            <iframe
              :srcdoc="mail.htmlBody"
              frameborder="0"
              width="100%"
              height="500"
            ></iframe>
          </template>
        </PageSection>
      </v-col>
      <v-col
        lg="6"
        md="6"
        sm="6"
        cols="12"
        v-if="mail && selectedAattachment && link"
      >
        <PageSection title="첨부파일 보기" :isLoading="false">
          <template slot="content">
            <iframe
              frameborder="0"
              style="height: 500px; width: 500px"
              :src="link"
            ></iframe>
          </template>
        </PageSection>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Loan from '@/components/fundanow/loan/Loan.vue'
import LoansOffer from '@/components/fundanow/loan/LoansOffer.vue'
import User from '@/components/fundanow/user/User.vue'

export default {
  components: {
    Loan,
    LoansOffer,
    User,
  },
  data() {
    return {
      mail: null,
      attachments: null,
      selectedAattachment: null,
      link: null,
      loan: null,
      userId: null,
    }
  },
  props: {
    mailId: {
      type: Number,
      required: true,
    },
  },
  async created() {
    // 메일 조회
    const { data: mail } = await this.$fundaNowApi.getMail(this.mailId)
    this.mail = mail
    // 메일 첨부파일 조회
    const { data: attachments } = await this.$fundaNowApi.getMailAttachments(
      this.mailId
    )
    this.attachments = attachments
    // 계약서 교부건인경우? 대출 조회
    const { data: loan } = await this.$fundaNowApi.getLoanLinkedToMail(
      this.mailId
    )
    this.loan = loan
  },
  methods: {
    async selectAattachment(item) {
      if (!item) {
        return
      }
      const {
        data: { base64EncodedData: attachmentStr },
      } = await this.$fundaNowApi.getMailAttachmentFile(this.mailId, item.id)
      var arrrayBuffer = base64ToArrayBuffer(attachmentStr)
      function base64ToArrayBuffer(base64) {
        var binaryString = window.atob(base64)
        var binaryLen = binaryString.length
        var bytes = new Uint8Array(binaryLen)
        for (var i = 0; i < binaryLen; i++) {
          var ascii = binaryString.charCodeAt(i)
          bytes[i] = ascii
        }
        return bytes
      }
      // 일단 pdf로 고정
      this.link = window.URL.createObjectURL(
        new Blob([arrrayBuffer], { type: 'application/pdf' })
      )
    },
  },
}
</script>
<style lang="scss" scoped>
iframe::-webkit-scrollbar {
  width: 2px;
}
</style>
