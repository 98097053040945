<template>
  <div class="pt-5">
    <v-row no-gutters>
      <v-col cols="12">
        <p class="mb-0">
          아래 입력칸에

          <span class="font-weight-black">
            {{ placeholder }}
          </span>

          를 입력해주세요.
        </p>
      </v-col>
      <v-col cols="12" class="mt-2">
        <v-text-field
          solo
          single-line
          hide-details
          outlined
          :placeholder="placeholder"
          v-model="confirmText"
        ></v-text-field>
      </v-col>

      <v-col cols="12" class="mt-4">
        <v-btn
          depressed
          color="warning"
          :loading="isLoading"
          :disabled="disabled"
          @click="removeUser()"
        >
          회원 탈퇴
        </v-btn>
      </v-col>

      <v-col v-if="errorDetails.length > 0" cols="12" class="mt-4">
        <v-fade-transition>
          <div class="px-5">
            <ul class="error-list color--error">
              <li
                v-for="(errorDetail, index) in errorDetails"
                :key="index"
                class="margin-l-10"
              >
                {{ errorDetail.text }}
              </li>
            </ul>
          </div>
        </v-fade-transition>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  props: {
    userIdx: {
      type: Number,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      confirmText: null,
      errorDetails: [],
    }
  },
  computed: {
    ...mapGetters('auth', {
      adminUser: 'getProfile',
    }),
    placeholder() {
      return `${this.userIdx} e프라이버시 회원탈퇴 요청`
    },
    disabled() {
      if (this.isLoading) {
        return true
      }

      return this.confirmText !== this.placeholder
    },
  },
  created() {
    this.getAdminProfile()
  },
  methods: {
    ...mapActions('auth', {
      getAdminProfile: 'getProfile',
    }),
    inputConfirm(value) {
      this.confirmText = value
    },
    async removeUser() {
      if (this.disabled) {
        return
      }

      const alert = await this.$swal.basic.confirm('회원탈퇴를 진행합니다')

      if (alert.isConfirmed !== true) {
        return
      }

      const q = `
        result: deleteUser(userIdx: ${this.userIdx}, reason: "${this.confirmText} by ${this.adminUser.id}")
      `
      const { result } = await this.$fundaApi.mutation(gql`{${q}}`)

      // 결과가 success면 탈퇴 성공
      const resultObj = JSON.parse(result)
      // 실패시 result = 'fail' 성공시 result = 'ok'
      if (resultObj.result !== 'ok') {
        if (resultObj.error_msg != null && resultObj.error_msg.length > 0) {
          this.errorDetails.splice(0, this.errorDetails.length)
          resultObj.error_msg.forEach((text) => {
            this.errorDetails.push({ text })
          })
        }
        this.$swal.basic.alert({
          title: '탈퇴 실패',
          text: '회원 탈퇴에 실패했습니다.',
        })
        return
      }

      this.$swal.basic.alert({
        text: '회원탈퇴가 완료되었습니다.',
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.error-list {
  margin-bottom: 5px;
  padding-top: 5px;
  border-top: 1px solid #ddd;
}
</style>
