<template>
  <div>
    <v-data-table
      :headers="dataTable.headers"
      :items="dataTable.items"
      :options.sync="dataTable.options"
      :loading="dataTable.isLoading"
      :server-items-length="dataTable.totalCount"
      :footer-props="dataTable.footer"
      class="elevation-2"
    >
    </v-data-table>
  </div>
</template>
<script>
export default {
  props: {
    isQueryPaging: {
      type: Boolean,
      default: false,
    },
    query: {
      type: Object,
      default: (_) => {
        return {}
      },
    },
  },
  data() {
    return {
      param: {
        page:
          this.isQueryPaging && this.query.page ? Number(this.query.page) : 1,
        size: this.query.size ? Number(this.query.size) : 20,
        userIdx: this.query.userIdx ? Number(this.query.userIdx) : null,
      },
      dataTable: {
        isLoading: false,
        options: null,
        headers: [
          { text: '대출 index', value: 'idx', sortable: false },
          { text: '사용자 index', value: 'userIdx', sortable: false },
          {
            text: '가상계좌 index',
            value: 'userVirtualAccountIdx',
            sortable: false,
          },
          { text: '대출명', value: 'title', sortable: false },
          { text: '상태', value: 'status', sortable: false },
          { text: '총 대출액', value: 'totalAmount', sortable: false },
          { text: '마감 시간', value: 'closedTime', sortable: false },
          { text: '실행 시간', value: 'executeTime', sortable: false },
          { text: '생성 시간', value: 'createTime', sortable: false },
        ],
        items: [],
        totalCount: 0,
        footer: {
          'items-per-page-options': [1, 5, 10, 20, 50, 100],
        },
      },
    }
  },
  watch: {
    'dataTable.options': {
      handler: function (options, oldValue) {
        if (oldValue === null) {
          return
        }
        const optionObj = {
          page: options.page,
          size: options.itemsPerPage,
          userIdx: options.userIdx,
        }
        this.updateOption(optionObj)
      },
      deep: true,
    },
  },
  created() {
    this.initTableOption()
    this.getPageData(this.param)
  },
  methods: {
    initTableOption() {
      this.dataTable.options = {
        page: this.param.page,
        itemsPerPage: this.param.size,
        userIdx: this.param.userIdx,
      }
    },
    updateOption(option) {
      if (this.isQueryPaging === true) {
        return this.$router.push({
          query: option,
        })
      } else {
        this.getPageData(option)
      }
    },
    async getPageData(option) {
      if (this.dataTable.isLoading === true) {
        return
      }
      try {
        this.dataTable.isLoading = true

        const userIdx = option.userIdx
        delete option.userIdx
        const {
          data: {
            paging: { totalCount },
            data,
          },
        } = await this.$paymentApi.getUserLoans(userIdx, option)
        this.dataTable.items = data
        this.dataTable.totalCount = totalCount
      } finally {
        this.dataTable.isLoading = false
      }
    },
  },
}
</script>
