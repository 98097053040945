<template>
  <div>
    <v-card class="px-8 pt-8" outlined>
      <v-row class="mb-3" no-gutters>
        <v-col cols="12" md="12">
          <h2 class="text-center">펀다나우 상점 검색</h2>
        </v-col>
      </v-row>

      <v-row class="mb-3" no-gutters>
        <v-col cols="3" sm="3" md="3"> </v-col>
        <v-col cols="6" sm="6" md="6">
          <v-text-field
            v-model="search.keyword"
            :disabled="getDataLoading"
            placeholder="펀다나우 상점명을 입력해주세요"
            @keyup.enter="getFnStoreByTitle()"
          >
          </v-text-field>
        </v-col>
        <v-col cols="3" sm="3" md="3">
          <v-btn
            :disabled="search.keyword.length < 1 || getDataLoading"
            :loading="getDataLoading"
            color="#393939"
            class="white--text"
            height="41"
            depressed
            @click="getFnStoreByTitle()"
          >
            검색
          </v-btn>
        </v-col>
      </v-row>

      <v-row class="mb-3" no-gutters>
        <v-col cols="12" sm="1" md="1"> </v-col>
        <v-col cols="12" sm="10" md="10">
          <v-card flat>
            <v-data-table
              no-data-text="이곳에 상점정보가 표시됩니다."
              no-results-text="이곳에 상점정보가 표시됩니다."
              loading-text="데이터를 가져오는 중입니다..."
              :headers="headers"
              :loading="getDataLoading"
              :items="fnStores"
              :items-per-page="5"
              :footer-props="{ 'items-per-page-options': [5, 10, 20, 50, -1] }"
              :server-items-length="paging.totalCount"
              @update:options="changePage"
            >
              <template v-slot:item="{ item }">
                <tr
                  class="clickable-data-row"
                  :class="selectedFnStore.idx == item.idx ? 'selected' : ''"
                  style="cursor: pointer"
                  @click="selectFnStore(item)"
                >
                  <td class="text-center">{{ item.idx }}</td>
                  <td class="text-center">{{ item.storeName }}</td>
                  <td class="text-center">{{ item.regNum }}</td>
                  <td class="text-center">
                    {{ item.createdAt | datetimeFormat }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
        <v-col cols="12" sm="1" md="1"> </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      getDataLoading: false,
      headers: [
        { text: '인덱스', value: 'idx', align: 'center', sortable: false },
        {
          text: '상점명',
          value: 'storeName',
          align: 'center',
          sortable: false,
        },
        {
          text: '사업자등록번호',
          value: 'regNum',
          align: 'center',
          sortable: false,
        },
        {
          text: '최초등록일',
          value: 'createdAt',
          align: 'center',
          sortable: false,
        },
      ],
      search: {
        page: 1,
        size: 10,
        keyword: '',
      },
      paging: {
        page: 1,
        size: 10,
        totalCount: 1,
      },
      fnStores: [],
      selectedFnStore: { idx: null },
    }
  },
  methods: {
    changePage(option) {
      this.search.size =
        option.itemsPerPage === -1
          ? this.paging.totalCount
          : option.itemsPerPage

      this.search.page = option.page

      if (this.search.keyword.length > 0) {
        this.getFnStoreByTitle()
      }
    },
    async getFnStoreByTitle() {
      if (this.search.keyword.length < 1) {
        return
      }

      this.getDataLoading = true

      let q = `
          stores: getFnStores(fundaNowReq: {
            page: ${this.search.page}
            size: ${this.search.size}
            storeName: "${this.search.keyword}"
          }) {
            data {
              idx
              regNum
              storeName
              createdAt
            }

            paging {
              size
              page
              totalCount
            }
          }
        `

      try {
        let {
          stores: { data, paging },
        } = await this.$fundaApi.query(gql`{${q}}`)

        this.fnStores = data
        this.paging = paging
      } finally {
        this.getDataLoading = false
        this.selectFnStore({ idx: null })
      }
    },
    selectFnStore(fnStore) {
      console.log('selectFnStore!')
      this.selectedFnStore = fnStore
      this.$emit('selectFnStore', fnStore)
    },
  },
}
</script>

<style lang="scss" scoped></style>
