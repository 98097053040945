<template>
  <ValidationObserver ref="validForm" v-slot="{ handleSubmit, invalid }">
    <v-form @submit.prevent="handleSubmit(submit)">
      <v-row>
        <v-col cols="12">
          <div class="v-data-table theme--light">
            <div class="v-data-table__wrapper">
              <table>
                <thead class="v-data-table-header">
                  <tr>
                    <th>구분</th>
                    <th>대출 잔액</th>
                    <th>연체 금액</th>
                    <th>연체율</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>신용</th>
                    <td class="pt-6 pb-0">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <ValidationProvider
                            v-slot="{ errors }"
                            name="amount"
                            :rules="`required|numeric|min_value:1`"
                          >
                            <v-text-field
                              v-model="creditLoanBalance"
                              v-bind="attrs"
                              v-on="on"
                              label="신용대출잔액"
                              outlined
                              dense
                              :error-messages="errors"
                              :disabled="isLoading"
                              v-input-comma-format
                              @focus="test"
                            />
                          </ValidationProvider>
                        </template>
                        <table class="text-center">
                          <tr>
                            <td></td>
                            <td>잔액</td>
                            <td class="pl-2">연체금액</td>
                          </tr>
                          <tr>
                            <td>신용</td>
                            <td>-</td>
                            <td>-</td>
                          </tr>
                          <tr>
                            <td>동산</td>
                            <td>-</td>
                            <td>-</td>
                          </tr>
                        </table>
                        형태의 엑셀 데이터를 붙여넣을 수 있습니다.
                      </v-tooltip>
                    </td>
                    <td class="pt-6 pb-0">
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="amount"
                        :rules="`required|numeric|min_value:1`"
                      >
                        <v-text-field
                          v-model="creditLoanOverdueBalance"
                          label="신용연체금액"
                          outlined
                          dense
                          v-input-comma-format
                          :error-messages="errors"
                          :disabled="isLoading"
                        />
                      </ValidationProvider>
                    </td>
                    <td>{{ creditOverdueRate }}%</td>
                  </tr>
                  <tr>
                    <th>동산</th>
                    <td class="pt-6 pb-0">
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="amount"
                        :rules="`required|numeric|min_value:1`"
                      >
                        <v-text-field
                          v-model="movableLoanBalance"
                          label="동산대출잔액"
                          outlined
                          dense
                          v-input-comma-format
                          :error-messages="errors"
                          :disabled="isLoading"
                        />
                      </ValidationProvider>
                    </td>
                    <td class="pt-6 pb-0">
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="amount"
                        :rules="`required|numeric|min_value:1`"
                      >
                        <v-text-field
                          v-model="movableLoanOverdueBalance"
                          label="동산연체금액"
                          outlined
                          dense
                          v-input-comma-format
                          :error-messages="errors"
                          :disabled="isLoading"
                        />
                      </ValidationProvider>
                    </td>
                    <td>{{ movableOverdueRate }}%</td>
                  </tr>
                  <tr>
                    <th>합계</th>
                    <td>{{ totalLoanBalance | commaFormat }}</td>
                    <td>{{ totalOverdueAmount | commaFormat }}</td>
                    <td>
                      {{ isNaN(totalOverdueRate) ? '' : totalOverdueRate }}%
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="px-4">
          <CommonDateTimePicker
            :dateTime.sync="baseTime"
            label="공시 금액 기준일 선택"
          />
          <p v-if="baseTime == null" class="mb-n2 font-size--13">
            기준일을 선택해주세요.
          </p>
        </v-col>
      </v-row>
      <v-divider />
      <v-row>
        <v-col cols="12" lg="6" md="6" sm="12"> </v-col>
        <v-col cols="12" lg="3" md="3" sm="12" class="px-4">
          <v-btn
            color="error"
            block
            type="button"
            :disabled="isLoading"
            @click="$emit('cancel')"
          >
            취소하기
          </v-btn>
        </v-col>
        <v-col cols="12" lg="3" md="3" sm="12" class="px-4">
          <v-btn
            color="primary"
            block
            type="submit"
            :disabled="baseTime == null || invalid || isLoading"
            :loading="isLoading"
          >
            등록하기
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </ValidationObserver>
</template>
<script>
import CommonDateTimePicker from '../../common/form/CommonDateTimePicker.vue'
export default {
  components: { CommonDateTimePicker },
  data() {
    return {
      creditLoanBalance: null,
      creditLoanOverdueBalance: null,
      movableLoanBalance: null,
      movableLoanOverdueBalance: null,
      baseTime: null,
      isLoading: false,
    }
  },
  computed: {
    creditOverdueRate() {
      return this.getOverdueRate(
        this.creditLoanOverdueBalance,
        this.creditLoanBalance
      )
    },
    movableOverdueRate() {
      return this.getOverdueRate(
        this.movableLoanOverdueBalance,
        this.movableLoanBalance
      )
    },
    totalLoanBalance() {
      return this.getSumAmount(this.creditLoanBalance, this.movableLoanBalance)
    },
    totalOverdueAmount() {
      return this.getSumAmount(
        this.creditLoanOverdueBalance,
        this.movableLoanOverdueBalance
      )
    },
    totalOverdueRate() {
      return this.getOverdueRate(this.totalOverdueAmount, this.totalLoanBalance)
    },
  },
  watch: {
    isLoading(v) {
      this.$emit('loading', v)
    },
  },
  created() {
    this.baseTime = this.$moment()
      .subtract(1, 'months')
      .endOf('month')
      .format('yyyy-MM-DDTHH:mm:ss')
  },
  methods: {
    getPlainNumber(str) {
      return str.toString().replace(/\D/g, '')
    },
    async test() {
      const text = await navigator.clipboard.readText()
      const names = ['신용', '동산', '잔액', '연체']
      const isDataTable = names.every((name) => {
        return text.indexOf(name) !== -1
      })
      if (isDataTable !== true) {
        return
      }

      let balanceIdx = null
      let overdueIdx = null
      let creditIdx = null
      let movableIdx = null

      let dataGrid = []
      const rows = text.split('\n')
      rows.forEach((row, rowIdx) => {
        const cols = row.split('\t')
        dataGrid.push(cols)
        cols.forEach((col, colIdx) => {
          if (col.indexOf('신용') !== -1) {
            creditIdx = rowIdx
          } else if (col.indexOf('동산') !== -1) {
            movableIdx = rowIdx
          } else if (col.indexOf('잔액') !== -1) {
            balanceIdx = colIdx
          } else if (col.indexOf('연체') !== -1) {
            overdueIdx = colIdx
          }
        })
      })

      this.creditLoanBalance = this.getPlainNumber(
        dataGrid[creditIdx][balanceIdx]
      )
      this.creditLoanOverdueBalance = this.getPlainNumber(
        dataGrid[creditIdx][overdueIdx]
      )
      this.movableLoanBalance = this.getPlainNumber(
        dataGrid[movableIdx][balanceIdx]
      )
      this.movableLoanOverdueBalance = this.getPlainNumber(
        dataGrid[movableIdx][overdueIdx]
      )
    },
    getOverdueRate(overdueAmount, balance) {
      if (overdueAmount == null || balance == null) {
        return null
      }

      const plainOverdueNum = this.getPlainNumber(overdueAmount)
      const plainBalanceNum = this.getPlainNumber(balance)

      if (plainOverdueNum.length === 0 || plainBalanceNum.length === 0) {
        return null
      }

      const result = ((plainOverdueNum / plainBalanceNum) * 100).toFixed(2)
      return result
    },
    getSumAmount(creditAmount, movableAmount) {
      const credit = creditAmount || 0
      const movable = movableAmount || 0
      return Number(credit) + Number(movable)
    },
    async submit() {
      if (this.isLoading === true) {
        return
      }
      try {
        this.isLoading = true

        let btnResult = await this.$swal.basic.confirm({
          title: '연체율 공시 등록',
          text: '등록하시겠습니까?',
          cancelButtonColor: 'red',
        })
        if (btnResult.isConfirmed !== true) {
          return
        }

        const form = {
          body: {
            creditLoanBalance: this.creditLoanBalance,
            creditLoanOverdueBalance: this.creditLoanOverdueBalance,
            movableLoanBalance: this.movableLoanBalance,
            movableLoanOverdueBalance: this.movableLoanOverdueBalance,
          },
          isVisible: false,
          baseTime: this.baseTime,
        }

        await this.$fundaApi.mutation(
          gql`
            {
              addMainOfficialStats(inputMainOfficialStats: $value) {
                idx
              }
            }
          `,
          'test($value: InputMainOfficialStats!)',
          {
            value: form,
          }
        )

        this.$emit('success')
      } catch (e) {
        this.$swal.basic.alert({
          text: '등록에 실패했습니다.',
        })
        throw e
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
<style lang="scss" scoped>
p.error-msg {
  position: absolute;
  font-size: 13px;
  margin-bottom: 0;
}
</style>
