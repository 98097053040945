<template>
  <div>
    <v-row>
      <v-col cols="12" lg="8" md="8" sm="12">
        <TransferForm
          @add="add"
          @submit="transferItem"
          :isTransferProgress="isTransferProgress"
        />

        <TransfersTable
          :transfers.sync="transfers"
          :amount.sync="amount"
          :isTransferProgress="isTransferProgress"
        />
      </v-col>
      <v-col cols="12" lg="4" md="4" sm="12">
        <SummaryCard
          :transfers.sync="transfers"
          :amount.sync="amount"
          :isTransferProgress="isTransferProgress"
          @transfer="transferItems(transfers)"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import SummaryCard from './datatable/SummaryCard.vue'
import TransfersTable from './datatable/TransfersTable.vue'
import TransferForm from './form/TransferForm.vue'
export default {
  components: { TransferForm, TransfersTable, SummaryCard },
  data() {
    return {
      amount: 0,
      transfers: [],
      isTransferProgress: false,
    }
  },
  created() {},
  methods: {
    add(item) {
      this.transfers.push(item)
    },
    async execTransfer(item) {
      await this.$paymentApi.postUserVirtualAccountTransfer({
        from: {
          userIdx: item.fromUserVirtualAccount.userIdx,
          userVirtualAccountIdx: item.fromUserVirtualAccount.idx,
        },
        to: {
          userIdx: item.toUserVirtualAccount.userIdx,
          userVirtualAccountIdx: item.toUserVirtualAccount.idx,
        },
        amount: item.amount,
        remark: item.remark,
      })
    },
    async transferItem(item) {
      if (this.isTransferProgress === true) {
        return
      }
      try {
        this.isTransferProgress = true

        let btnResult = await this.$swal.basic.confirm({
          title: '가상계좌간 이체',
          text: '이체하시겠습니까?',
          cancelButtonColor: 'red',
          focusConfirm: true,
        })
        if (btnResult.isConfirmed !== true) {
          return
        }

        await this.execTransfer(item)
      } catch (e) {
        if (e.data && e.data.errors) {
          this.$swal.basic.error({
            title: '이체 실패',
            text: e.data.errors[0].message,
          })
        } else {
          throw e
        }
      } finally {
        this.isTransferProgress = false
      }
    },
    async transferItems(items) {
      if (this.isTransferProgress === true) {
        return
      }
      if (items.length < 1) {
        return
      }
      try {
        this.isTransferProgress = true

        let btnResult = await this.$swal.basic.confirm({
          title: '가상계좌간 이체',
          text: '이체하시겠습니까?',
          focusConfirm: true,
        })
        if (btnResult.isConfirmed !== true) {
          return
        }

        if (
          items.some((item) => {
            return item.status != null
          })
        ) {
          let btnDuplicateResult = await this.$swal.basic.confirm({
            title: '실행된 건 존재',
            text: '이미 실행된 건이 있습니다. 계속 진행하시겠습니까?',
          })
          if (btnDuplicateResult.isConfirmed !== true) {
            return
          }
        }

        let errorCount = 0
        let successCount = 0
        for (let i = 0; i < items.length; i++) {
          const item = items[i]

          let status = '요청 실패'
          try {
            await this.execTransfer(item)
            status = '요청 완료'
            successCount++
          } catch (e) {
            item.error = JSON.stringify(e.data || e)
            errorCount++
          } finally {
            item.status = status
            this.$set(this.transfers, this.transfers.indexOf(item), item)
          }
        }

        if (errorCount === 0 && items.length === successCount) {
          this.$swal.basic.success({
            text: '이체가 완료되었습니다.',
          })
        } else {
          this.$swal.basic.error({
            text: `${items.length}건 중 ${successCount}건 성공, ${errorCount}건 실패했습니다.`,
          })
        }
      } finally {
        this.isTransferProgress = false
      }
    },
  },
}
</script>
