<template>
  <div>
    <v-row no-gutters>
      <v-dialog v-model="dialog" max-width="1000px" width="90%">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :color="$colors['f-dark-grey']"
            dark
            depressed
            x-large
            block
            v-bind="attrs"
            v-on="on"
          >
            회원 탈퇴
          </v-btn>
        </template>

        <v-card>
          <v-card-title>
            <span class="headline"> 회원 탈퇴 </span>
          </v-card-title>
          <v-card-text>
            <p class="mb-0 f-dark-grey--text">
              <v-icon small>mdi-chevron-right</v-icon>
              e프라이버시 회원 탈퇴 요청이 들어온 경우에만 사용하세요.
            </p>
            <p class="mb-0 f-dark-grey--text" style="line-height: 1.4">
              <v-icon small>mdi-chevron-right</v-icon>
              e프라이버시 외 고객이 요청한 경우에는 홈페이지에서 직접 탈퇴하여야
              합니다. (미인증 유저의 경우 제외)
            </p>
            <p class="mb-0 f-dark-grey--text" style="line-height: 1.4">
              <v-icon small>mdi-chevron-right</v-icon>
              남길 내용이 있다면 고객 메모를 활용해 주세요.
            </p>
            <p class="mb-0 red--text">
              <v-icon small>mdi-chevron-right</v-icon>
              탈퇴 후 복구할 수 없습니다.
            </p>
            <keep-alive>
              <RemoveUser
                :key="userIdx + '_탈퇴'"
                :userIdx="parseInt(userIdx)"
              ></RemoveUser>
            </keep-alive>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="dialog = false">
              닫기
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import RemoveUser from './RemoveUser.vue'

export default {
  props: {
    userIdx: {
      required: true,
      default: null,
    },
  },
  components: {
    RemoveUser,
  },
  data() {
    return {
      dialog: false,
    }
  },
}
</script>
