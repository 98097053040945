var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-2 my-3",attrs:{"headers":_vm.dataTable.headers,"items":_vm.dataTable.items,"options":_vm.dataTable.options,"loading":_vm.dataTable.isLoading,"footer-props":_vm.dataTable.footer,"items-per-page":-1,"hide-default-footer":""},on:{"update:options":function($event){return _vm.$set(_vm.dataTable, "options", $event)}},scopedSlots:_vm._u([{key:"item.idx",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{
          name: 'payment/users/virtual/accounts/transactions',
          params: {
            userIdx: item.userIdx,
            userVirtualAccountIdx: item.idx,
          },
        }}},[_vm._v(" "+_vm._s(item.idx)+" ")])]}},{key:"item.userIdx",fn:function(ref){
        var item = ref.item;
return [_c('router-link',{attrs:{"to":{
          name: 'payment/users/detail',
          params: {
            userIdx: item.userIdx,
          },
        }}},[_vm._v(" "+_vm._s(item.userIdx)+" ")])]}},{key:"item.bankCode",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("bankCodeName")(item.bankCode))+" ")]}},{key:"item.type",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("payment.code")(item.type,'UserVirtualAccount.Type'))+" ")]}},{key:"item.balance",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("commaFormat")(item.balance))+" ")]}},{key:"item.isClosed",fn:function(ref){
        var item = ref.item;
return [_c('v-switch',{staticClass:"mt-0",attrs:{"input-value":item.isClosed,"readonly":"","disabled":"","hide-details":""}})]}},{key:"item.isDepositStopped",fn:function(ref){
        var item = ref.item;
return [_c('v-switch',{staticClass:"mt-0",attrs:{"input-value":item.isDepositStopped,"readonly":"","disabled":"","hide-details":""}})]}},{key:"item.isAllDepositable",fn:function(ref){
        var item = ref.item;
return [_c('v-switch',{staticClass:"mt-0",attrs:{"input-value":!item.isAllDepositable,"readonly":"","disabled":"","hide-details":""}})]}},{key:"item.createTime",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.createTime,'YYYY-MM-DD HH:mm:ss'))+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }