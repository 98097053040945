var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-list',{attrs:{"dense":""}},[_c('VisitHistory',{attrs:{"activeMenu":_vm.activeMenu}}),_vm._l((_vm.accessItems),function(item){return [(item.children)?_c('v-list-group',{key:item.text,attrs:{"prepend-icon":item.icon,"no-action":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.text)}})],1)]},proxy:true}],null,true),model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}},_vm._l((_vm.accessChildren(item.children)),function(child,i){return _c('v-list-item',{key:i,class:[
          {
            'router-link-active--false':
              child.route && _vm.activeMenu != child.route.name,
          },
          {
            'v-list-item--active':
              child.route && _vm.activeMenu == child.route.name,
          } ],attrs:{"to":child.route,"href":child.href,"target":child.route ? '_self' : '_blank'}},[_c('v-list-item-content',[(!!child.count)?[_c('CountText',{key:child.count,attrs:{"count-type":child.count}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(child.text)}})],1)]:[_c('v-list-item-title',{domProps:{"textContent":_vm._s(child.text)}})]],2)],1)}),1):_c('v-list-item',{key:item.text,class:{
        'router-link-active--false':
          item.route && _vm.activeMenu != item.route.name,
      },attrs:{"link":"","to":item.route},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}},[_c('v-list-item-action',[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.text))])],1)],1)]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }