<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12">
        <h2
          class="
            mt-5
            mb-2
            pl-1
            color--gray-3
            font-size--22
            font-weight-black
            text-center
          "
        >
          월간공시
        </h2>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col cols="12" class="d-flex justify-center">
        <v-select
          v-model="year"
          :items="years"
          label="년도"
          outlined
          style="max-width: 140px"
        ></v-select>

        <v-select
          v-model="month"
          :items="months"
          label="월"
          outlined
          style="max-width: 140px; padding-left: 20px"
        ></v-select>
      </v-col>
    </v-row>

    <OverdueRateAndDetails
      v-if="!!year && !!month"
      :table-title="'회사의 연계대출 채권 현황 및 연체율 등 연체에 관한 현황'"
      :year="year"
      :month="month"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import OverdueRateAndDetails from './OverdueRateAndDetails.vue'

export default {
  components: {
    OverdueRateAndDetails,
  },
  data() {
    return {
      isLoading: false,
      yearMonth: {},
      year: null,
      month: null,
    }
  },
  computed: {
    years() {
      return Object.keys(this.yearMonth)
    },
    months() {
      return this.yearMonth[this.year]
    },
  },
  watch: {
    years() {
      this.year = this.years[this.years.length - 1]
    },
    year() {
      this.month = this.months?.[0] ?? 1
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    ...mapActions('time', ['getKrDate']),
    async fetchData() {
      const krDate = await this.getKrDate()
      const ym = {}
      const year = krDate.getFullYear()
      for (let yyyy = 2016; yyyy <= year; yyyy++) {
        const months = []
        let maxMonth = 12

        if (yyyy === year) {
          // month는 값을 꺼내면 이미 -1 되어있다
          maxMonth = krDate.getMonth()
        }
        for (let mm = 1; mm <= maxMonth; mm++) {
          months.push(mm)
        }

        ym[yyyy] = months
      }

      this.yearMonth = ym
    },
  },
}
</script>
