export default [
  {
    name: 'second-mortgage-loan',
    path: '/second-mortgage-loan',
    component: require('@/pages/second-mortgage-loan/AbstractView.vue').default,
    redirect: {
      name: 'second-mortgage-loan/loans',
    },
    children: [
      {
        name: 'second-mortgage-loan/loans',
        query: {
          loanTypes: ['second_mortgage'],
          statuses: ['executed'],
        },
        path: 'loans',
        meta: { title: '아파트론 목록' },
        props: (route) => ({
          query: Object.assign(
            {
              loanTypes: ['second_mortgage'],
              statuses: ['executed'],
            },
            route.query
          ),
        }),
        component: require('@/pages/second-mortgage-loan/Loans.vue').default,
      },
    ],
  },
]
