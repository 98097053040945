import gql from 'graphql-tag'

global.HttpMethod = Object.freeze({
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
  FETCH: 'FETCH',
})

global.gql = gql
