var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.investUser)?_c('div',[_c('v-card',{staticStyle:{"border":"1px solid #00000061"},attrs:{"elevation":"0"}},[_c('v-card-title',[_c('span',{staticClass:"mx-1"},[_vm._v("(")]),_c('router-link',{attrs:{"target":"_blank","to":{
          name: 'fundaUserDetails',
          params: {
            userIdx: _vm.investUser.idx,
          },
        }}},[_vm._v(" "+_vm._s(_vm.investUser.idx)+" ")]),_c('span',{staticClass:"mx-1"},[_vm._v(")")]),_vm._v(" "+_vm._s(_vm.investUser.name)+" ")],1),(_vm.userVirtualAccount)?_c('v-card-text',[_c('v-list',{staticClass:"py-0"},[_c('v-list-item',{staticClass:"px-0"},[_c('v-list-item-content',{staticClass:"py-0"},[_c('v-list-item-subtitle',{staticClass:"d-flex align-center flex-wrap"},[_vm._v(" 가상계좌 : "+_vm._s(_vm.userVirtualAccount.accountName)+" "),_c('span',{staticClass:"mx-1"},[_vm._v("(")]),_c('router-link',{attrs:{"target":"_blank","to":{
                  name: 'payment/users/detail',
                  params: {
                    userIdx: _vm.userVirtualAccount.userIdx,
                  },
                }}},[_vm._v(" "+_vm._s(_vm.userVirtualAccount.userIdx)+" ")]),_c('span',{staticClass:"mx-1"},[_vm._v("/")]),_c('router-link',{attrs:{"target":"_blank","to":{
                  name: 'payment/users/virtual/accounts/transactions',
                  params: {
                    userIdx: _vm.userVirtualAccount.userIdx,
                    userVirtualAccountIdx: _vm.userVirtualAccount.idx,
                  },
                }}},[_vm._v(" "+_vm._s(_vm.userVirtualAccount.idx)+" ")]),_c('span',{staticClass:"mx-1"},[_vm._v(")")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.userVirtualAccount.accountNo))])],1)],1),_c('v-list-item',{staticClass:"px-0"},[_c('v-list-item-content',{staticClass:"py-0"},[_c('v-list-item-subtitle',[_vm._v("투자 가능 잔액")]),_c('v-list-item-title',[_vm._v(_vm._s(_vm._f("commaFormat")(_vm.investUserBalance))+" 원")])],1)],1)],1)],1):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }