var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-simple-table',{attrs:{"dense":_vm.dense},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('tr',[_c('td',[_vm._v("index")]),_c('td',[_c('router-link',{attrs:{"to":{
              name: 'payment/users/virtual/accounts/transactions',
              params: {
                userIdx: _vm.vAccount.userIdx,
                userVirtualAccountIdx: _vm.vAccount.idx,
              },
            },"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.vAccount.idx)+" ")])],1)]),_c('tr',[_c('td',[_vm._v("사용자 index")]),_c('td',[_c('router-link',{attrs:{"to":{
              name: 'payment/users/detail',
              params: { userIdx: _vm.vAccount.userIdx },
            },"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.vAccount.userIdx)+" ")])],1)]),_c('tr',[_c('td',[_vm._v("label")]),_c('td',[_vm._v(_vm._s(_vm.vAccount.label))])]),_c('tr',[_c('td',[_vm._v("target index")]),_c('td',[_vm._v(_vm._s(_vm.vAccount.targetIdx))])]),_c('tr',[_c('td',[_vm._v("가상계좌 type")]),_c('td',[_vm._v(_vm._s(_vm._f("payment.code")(_vm.vAccount.type,'UserVirtualAccount.Type')))])]),_c('tr',[_c('td',[_vm._v("은행")]),_c('td',[_vm._v(_vm._s(_vm._f("bankCodeName")(_vm.vAccount.bankCode)))])]),_c('tr',[_c('td',[_vm._v("계좌 번호")]),_c('td',[_vm._v(_vm._s(_vm.vAccount.accountNo))])]),_c('tr',[_c('td',[_vm._v("계좌 명")]),_c('td',[_vm._v(_vm._s(_vm.vAccount.accountName))])]),_c('tr',[_c('td',[_vm._v("잔액")]),_c('td',[_vm._v(_vm._s(_vm._f("commaFormat")(_vm.vAccount.balance)))])]),_c('tr',[_c('td',[_vm._v("해지 여부")]),_c('td',{staticClass:"pt-2 pb-3"},[_c('v-switch',{staticClass:"mt-0",attrs:{"input-value":_vm.vAccount.isClosed,"readonly":"","disabled":"","hide-details":""}})],1)]),_c('tr',[_c('td',[_vm._v("입금 정지 여부")]),_c('td',{staticClass:"pt-2 pb-3"},[_c('v-switch',{staticClass:"mt-0",attrs:{"input-value":_vm.vAccount.isDepositStopped,"readonly":"","disabled":"","hide-details":""}})],1)]),_c('tr',[_c('td',[_vm._v("입금 계좌 제한 여부")]),_c('td',{staticClass:"pt-2 pb-3"},[_c('v-switch',{staticClass:"mt-0",attrs:{"input-value":!_vm.vAccount.isAllDepositable,"readonly":"","disabled":"","hide-details":""}})],1)])])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }