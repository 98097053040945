<template>
  <PageSection title="사용자 입금용 주계좌 정보" :isLoading="isLoading">
    <template slot="content">
      <v-simple-table>
        <template v-slot:default>
          <tbody>
            <template v-if="waccount != null">
              <tr>
                <td>은행</td>
                <td>{{ waccount.bankCode | bankCodeName }}</td>
              </tr>
              <tr>
                <td>계좌번호</td>
                <td>{{ waccount.accountNo }}</td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td class="py-5 text-center subtitle-1 grey--text">
                  아직 등록된 입금용 주계좌가 없습니다.
                </td>
              </tr>
            </template>
          </tbody>
        </template>
      </v-simple-table>
    </template>
  </PageSection>
</template>

<script>
export default {
  props: {
    userVirtualAccountIdx: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      waccount: null,
      isLoading: false,
    }
  },
  async created() {
    try {
      this.isLoading = true
      const { data } =
        await this.$paymentApi.getUserVirtualAccountWithdrawAccount(
          this.userVirtualAccountIdx
        )
      if (data instanceof Object === false) {
        return
      }
      this.waccount = data
    } finally {
      this.isLoading = false
    }
  },
}
</script>
