var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-2 my-3",attrs:{"headers":_vm.dataTable.headers,"items":_vm.dataTable.items,"options":_vm.dataTable.options,"loading":_vm.dataTable.isLoading,"hide-default-footer":"","items-per-page":-1},on:{"update:options":function($event){return _vm.$set(_vm.dataTable, "options", $event)}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.id)+" ")]}},{key:"item.usedDays",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("commaFormat")(item.usedDays))+" ")]}},{key:"item.overdueDays",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("commaFormat")(item.overdueDays))+" ")]}},{key:"item.interest",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("commaFormat")(item.interest))+" 원 ")]}},{key:"item.overdueInterest",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("commaFormat")(item.overdueInterest))+" 원 ")]}},{key:"item.fee",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("commaFormat")(item.fee))+" 원 ")]}},{key:"item.principal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("commaFormat")(item.principal))+" 원 ")]}},{key:"item.remainPrincipal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("commaFormat")(item.remainPrincipal))+" 원 ")]}},{key:"item.totalRepayment",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("commaFormat")(item.fee + item.principal + item.interest + item.overdueInterest))+" 원 ")]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }