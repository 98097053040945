<template>
  <div>
    <v-row v-if="hideFnStoreSearch === false" no-gutters>
      <v-col cols="12" sm="12" md="12">
        <FnStore @selectFnStore="(v) => (selectedFnStore = v)"></FnStore>
      </v-col>
    </v-row>

    <v-row class="my-3" no-gutters>
      <v-col cols="12" sm="12" md="12">
        <h3 class="text-center">
          {{ !!selectedFnStore ? selectedFnStore.storeName : '' }}
          대출 정보
        </h3>
      </v-col>

      <v-col v-if="hideFnStoreSearch === true" cols="12" sm="12" md="12">
        <v-card v-if="!!selectedFundingData" outline>
          <v-row class="my-3 pa-3" no-gutters>
            <v-col cols="2" sm="2" md="2"></v-col>
            <v-col cols="3" sm="3" md="3">
              <p class="ma-0 mb-2" style="font-size: 18px">
                상품명:
                <span style="font-size: 20px">
                  {{ selectedFundingData.title }}
                </span>
              </p>

              <p class="ma-0 mb-2" style="font-size: 18px">
                총 모집금액:
                <span style="font-size: 20px">
                  {{ selectedFundingData.totalAmount | commaFormat }} 원
                </span>
              </p>

              <p class="ma-0" style="font-size: 18px">
                상점 수:
                <span style="font-size: 20px">
                  {{ summary.storeCount }}
                </span>
              </p>
            </v-col>

            <v-col cols="2" sm="2" md="2"> </v-col>

            <v-col cols="5" sm="5" md="5">
              <p class="ma-0 mb-2" style="font-size: 18px">
                총 연체 수:
                <span style="font-size: 20px">
                  {{ summary.overdueCount + summary.resolveCount }} 건
                  (연체해소: {{ summary.resolveCount }} 건 포함)
                </span>
              </p>

              <p class="ma-0 mb-2" style="font-size: 18px">
                총 연체금액:
                <span style="font-size: 20px">
                  {{ summary.overdueAmount | commaFormat }} 원
                </span>
              </p>
            </v-col>

            <v-col cols="1" sm="1" md="1"></v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col cols="12" sm="12" md="12">
        <v-card outlined>
          <v-data-table
            no-data-text="이곳에 상점정보가 표시됩니다."
            no-results-text="데이터가 없습니다."
            loading-text="데이터를 가져오는 중입니다..."
            :headers="headers"
            :loading="getDataLoading"
            :items="fnStoreFundingDatas"
            :server-items-length="paging.totalCount"
            :items-per-page="dataTableFeature.itemsPerPage"
            :footer-props="{
              'items-per-page-options': dataTableFeature.options,
            }"
            @update:options="changePage"
          >
            <template v-slot:item="{ item }">
              <tr
                class="clickable-data-row"
                :class="
                  !!selectedFnStoreFundingData &&
                  selectedFnStoreFundingData.idx == item.idx
                    ? 'selected'
                    : ''
                "
                style="cursor: pointer"
                @click="selectFnStoreFundingData(item)"
              >
                <td class="text-center">{{ item.idx }}</td>
                <td class="text-center">{{ item.fnStore.storeName }}</td>
                <td class="text-center">{{ item.fnStore.regNum }}</td>
                <td class="text-center font-weight-bold">
                  {{ item.fundingDataIdx }} 호
                </td>
                <td class="text-center font-weight-bold">
                  <template v-if="!!item.fundingData">
                    {{ item.fundingData.registerDate | dateFormat }}
                  </template>
                </td>
                <td class="text-center">
                  {{ item.bisangAmount | commaFormat }} 원
                </td>
                <td class="text-center">
                  {{ item.repaymentPrincipal | commaFormat }}
                </td>
                <td class="text-center">
                  {{
                    (item.bisangAmount - item.repaymentPrincipal) | commaFormat
                  }}
                </td>
                <td class="text-center" :class="getStatusColor(item)">
                  {{ getStatusName(item) }}
                </td>
                <td class="text-center">
                  <v-btn
                    v-if="item.minOverdueStatusIdx !== 0"
                    @click="openDetails(item.idx)"
                  >
                    연체 관리
                  </v-btn>

                  <span v-else class="schedule-color__done cursor--pointer">
                    -
                  </span>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import FnStore from '@/components/funda/fundanow/FnStore'

export default {
  props: {
    hideFnStoreSearch: {
      type: Boolean,
      default: true,
    },
    isOverdue: {
      type: Boolean,
      default: false,
    },
    fundingDataIdx: {
      type: Number,
      default: null,
    },
    fnStoreFundingDataIdx: {
      type: Number,
      default: null,
    },
  },
  components: {
    FnStore,
  },
  watch: {
    fundingDataIdx: {
      immediate: true,
      handler(newV, oldV) {
        if (!!newV && newV !== oldV) {
          this.getFundingData()
          this.getFnStoreFundingDatasByFundingDataIdx()
        }
      },
    },
    fnStoreFundingDataIdx: {
      immediate: true,
      handler(newV, oldV) {
        if (!!newV && newV !== oldV) {
          this.getFnStoreFundingDataByIdx()
        }
      },
    },
    'selectedFnStore.idx': function (v) {
      this.search.fnStoreIdx = v
      this.selectFnStoreFundingData(null)
      this.getFnStoreFundingDatasByFnStoreIdx()
    },
  },
  data: function () {
    return {
      getDataLoading: false,
      headers: [
        { text: '채권번호', value: 'idx', align: 'center' },
        {
          text: '상점명',
          width: '14%',
          value: 'storeName',
          align: 'center',
          sortable: false,
        },
        {
          text: '사업자등록번호',
          value: 'regNum',
          align: 'center',
          sortable: false,
        },
        {
          text: '상품호수',
          value: 'fundingDataIdx',
          align: 'center',
          sortable: false,
        },
        {
          text: '대출일',
          value: 'registerDate',
          align: 'center',
          sortable: false,
        },
        {
          text: '대출금액',
          value: 'bisangAmount',
          align: 'center',
          sortable: false,
        },
        { text: '상환원금', value: 'repaymentPrincipal', align: 'center' },
        { text: '잔액', align: 'center' },
        { text: '상태', value: 'minOverdueStatusIdx', align: 'center' },
        { text: '상태관리', width: '10%', align: 'center', sortable: false },
      ],
      dataTableFeature: {
        itemsPerPage: 50,
        options: [50, 100, -1],
      },
      search: {
        page: 1,
        size: 5,
        fnStoreIdx: null,
        fundingDataIdx: null,
      },
      paging: {
        page: 1,
        size: 5,
        totalCount: 1,
      },
      fnStoreFundingDatas: [],
      selectedFundingData: null,
      summary: {
        storeCount: 0,
        overdueCount: 0,
        overdueAmount: 0,
        resolveCount: 0,
      },
      selectedFnStore: { idx: null },
      selectedFnStoreFundingData: null,
    }
  },
  created() {
    if (this.hideFnStoreSearch === false) {
      this.dataTableFeature = {
        itemsPerPage: 5,
        options: [5, 20, 50, -1],
      }
    } else if (this.fundingDataIdx !== null) {
      this.getFnStoreFundingDatasByFundingDataIdx()
    }
  },
  methods: {
    openDetails(fnIdx) {
      let r = this.$router.resolve({
        name: 'fnOverdueManagement',
        query: { fnIdx: fnIdx },
      })

      window.open(r.href, '_blank')
    },
    getStatusColor(item) {
      if (item.repaymentStatus === 0 || item.minOverdueStatusIdx === 1) {
        // 상환중 혹은 연체해소
        return 'schedule-color__expect'
      } else if (item.repaymentPrincipal >= item.bisangAmount) {
        // 상환원금이 대출액과 같으면 상환완료라고 본다
        return 'schedule-color__done'
      } else {
        // 나머지는 그냥 연체라고 본다
        return 'red--text'
      }
    },
    getStatusName(item) {
      if (item.repaymentStatus === 0) {
        return '상환중'
      } else {
        if (item.repaymentStatus === 1 && item.minOverdueStatusIdx === 0) {
          return '상환완료'
        } else if (item.minOverdueStatusIdx === 1) {
          return '연체해소'
        } else {
          return '연체'
        }
      }
    },
    changePage(option) {
      if (this.hideFnStoreSearch) {
        return
      }
      this.search.size =
        option.itemsPerPage === -1
          ? this.paging.totalCount
          : option.itemsPerPage
      this.search.page = option.page
      if (!!this.search.fnStoreIdx) {
        this.getFnStoreFundingDatasByFnStoreIdx()
      }
    },
    selectFnStoreFundingData(fnStoreFundingData) {
      console.log('selectFnStoreFundingData!')
      this.selectedFnStoreFundingData = fnStoreFundingData
      this.$emit('selectFnStoreFundingData', fnStoreFundingData)
    },
    async getFundingData() {
      let q = `
        data: getFundingData(fundingDataIdx: ${this.fundingDataIdx}) {
          idx
          title
          totalAmount
          minInvestAmount
          maxInvestAmount
          unitInvestAmount
          interestRate
          loanPeriod
          registerDate
          status
          repaymentStatus
        }
      `

      let data = await this.$fundaApi.query(gql`{${q}}`)
      this.selectedFundingData = data
    },
    async getFnStoreFundingDatas(q, isPaging) {
      this.getDataLoading = true

      let summary = {
        storeCount: 0,
        overdueCount: 0,
        overdueAmount: 0,
        resolveCount: 0,
      }

      try {
        let data = await this.$fundaApi.query(gql`{${q}}`)

        if (isPaging === true) {
          this.fnStoreFundingDatas = data.resp.data
          this.paging = data.resp.paging
        } else {
          if (Array.isArray(data)) {
            this.fnStoreFundingDatas = data
          } else {
            this.fnStoreFundingDatas.push(data)
          }

          summary.storeCount = this.fnStoreFundingDatas.length
          this.paging.totalCount = this.fnStoreFundingDatas.length

          this.fnStoreFundingDatas.forEach((v) => {
            if (v.minOverdueStatusIdx === 1) {
              summary.resolveCount++
            } else if (v.repaymentStatus === 2) {
              summary.overdueCount++
              summary.overdueAmount += v.bisangAmount - v.repaymentPrincipal
            }
          })
        }
        this.summary = { ...summary }
      } catch (e) {
        console.log(e)
      } finally {
        this.getDataLoading = false
      }
    },
    getFnStoreFundingDataByIdx() {
      if (this.fnStoreFundingDatas.length > 0) {
        return
      }
      let q = `
        data: getFnStoreFundingData(idx: ${this.fnStoreFundingDataIdx}) {
          idx
          fundingDataIdx
          fnStoreIdx
          bisangAmount
          creditRate
          repaymentStatus
          interestRate
          fnLoanIdx

          minOverdueStatusIdx
          repaymentPrincipal
      
          fnStore {
            idx
            regNum
            storeName
          }

          fundingData{
            idx
            title
            registerDate
          }
        }
      `
      this.getFnStoreFundingDatas(q)
    },
    getFnStoreFundingDatasByFnStoreIdx() {
      let q = `
          resp: getFnStoreFundingDatasByFnStoreIdx(fundaNowReq: {
            page: ${this.search.page}
            size: ${this.search.size}
            fnStoreIdx: ${this.search.fnStoreIdx}
            isOverdue: ${this.isOverdue}
          }) {
            data {
              idx
              fundingDataIdx
              fnStoreIdx
              bisangAmount
              creditRate
              repaymentStatus
              interestRate
              fnLoanIdx

              minOverdueStatusIdx
              repaymentPrincipal
          
              fnStore {
                  idx
                  regNum
                  storeName
              }

              fundingData{
                  idx
                  title
                  registerDate
              }
            }
            
            paging {
              size
              page
              totalCount
            }
          }
        `

      this.getFnStoreFundingDatas(q, true)
    },
    getFnStoreFundingDatasByFundingDataIdx() {
      let q = `
        data: getFnStoreFundingDatasByFundingDataIdx(fundingDataIdx: ${this.fundingDataIdx}, isOverdue: ${this.isOverdue}) {
          idx
          fundingDataIdx
          fnStoreIdx
          bisangAmount
          creditRate
          repaymentStatus
          interestRate
          fnLoanIdx

          minOverdueStatusIdx
          repaymentPrincipal
      
          fnStore {
            idx
            regNum
            storeName
          }
        }
      `

      this.getFnStoreFundingDatas(q)
    },
  },
}
</script>

<style lang="scss" scoped></style>
