import qs from 'query-string'
import client from '../client'

export default {
  postAlimtalks(data) {
    return client.call({
      url: '/alimtalks',
      method: 'POST',
      data: data,
    })
  },
  postMessageGroupAlimtalks(data) {
    return client.call({
      url: '/message/groups/alimtalks',
      method: 'POST',
      data: data,
    })
  },
  postMessageGroupSend(idx) {
    return client.call({
      url: `/message/groups/${idx}/send`,
      method: 'POST',
    })
  },
  getMessageGroups(param) {
    return client.call({
      url: `/message/groups?${qs.stringify(param)}`,
      method: 'GET',
    })
  },
  getMessageGroup(messageGroupIdx) {
    return client.call({
      url: `/message/groups/${messageGroupIdx}`,
      method: 'GET',
    })
  },
  getMessageGroupAlimtalks(messageGroupIdx, param) {
    return client.call({
      url: `/message/groups/${messageGroupIdx}/alimtalks?${qs.stringify(
        param
      )}`,
      method: 'GET',
    })
  },
  putMessageGroupCancel(messageGroupIdx) {
    return client.call({
      url: `/message/groups/${messageGroupIdx}/cancel`,
      method: 'PUT',
    })
  },
  getProfileTemplateDetail(profileTemplateIdx) {
    return client.call({
      url: `/profiles/templates/${profileTemplateIdx}`,
      method: 'GET',
    })
  },
  getAlimtalkDetail(alimtalkIdx) {
    return client.call({
      url: `/alimtalks/${alimtalkIdx}`,
      method: 'GET',
    })
  },
}
