<template>
  <v-row>
    <v-col lg="8" mg="8" sm="12" cols="12">
      <TemplateVariableDataTable
        :key="template.idx"
        :template="template"
        :variables="variables"
        @change="receivers = $event"
        @select="previewVariableData = $event"
      />
    </v-col>
    <v-col lg="4" mg="4" sm="12" cols="12">
      <TemplatePreview
        :variables="variables"
        :template="template"
        :row="previewVariableData"
        class="mb-4"
        :flat="true"
      />
      <TemplateOption
        :template="template"
        :variables="variables"
        class="mb-4"
      />
      <ReservTimePicker class="mb-4" @change="reserveTime = $event" />
      <AlimtalkSendButton
        :template="template"
        :receivers="receivers"
        :reserve-time="reserveTime"
        :variables="variables"
      />
    </v-col>
  </v-row>
</template>

<script>
import TemplateOption from '@/components/alimtalk/template/TemplateOption.vue'
import TemplateVariableDataTable from '@/components/alimtalk/form/datatable/TemplateVariableDataTable.vue'
import ReservTimePicker from '@/components/alimtalk/form/ReservTimePicker.vue'
import AlimtalkSendButton from '@/components/alimtalk/form/AlimtalkSendButton'
import TemplatePreview from '../TemplatePreview.vue'

export default {
  props: {
    template: null,
    variables: null,
  },
  components: {
    TemplateOption,
    TemplateVariableDataTable,
    AlimtalkSendButton,
    ReservTimePicker,
    TemplatePreview,
  },
  data() {
    return {
      receivers: null,
      previewVariableData: null,
      onDrag: false,
      reserveTime: null,
    }
  },
}
</script>

<style></style>
