export default [
  {
    name: 'fundanow/repayment/manual',
    path: '/fundanow/repayment/manual',
    component: require('@/pages/fundanow/repayment/manual/AbstractView.vue')
      .default,
    redirect: {
      name: 'fundanow/repayment/manual/targets',
    },
    children: [
      {
        name: 'fundanow/repayment/manual/targets',
        alias: '',
        path: 'targets',
        meta: { title: '수동 상환 대상 목록' },
        component: require('@/pages/fundanow/repayment/manual/Targets.vue')
          .default,
      },
      {
        name: 'fundanow/repayment/manual/histories',
        path: 'histories',
        meta: { title: '수동 상환 내역 목록' },
        props: (route) => ({
          query: route.query,
        }),
        component: require('@/pages/fundanow/repayment/manual/Histories.vue')
          .default,
      },
    ],
  },
  {
    name: 'fundanow/repayment/manual/targets/form',
    path: '/fundanow/repayment/manual/targets/:loansRepaymentPreDataId/form',
    props: (route) => ({
      loansRepaymentPreDataId: Number(route.params.loansRepaymentPreDataId),
    }),
    meta: { title: '수동 상환' },
    component: require('@/pages/fundanow/repayment/manual/RepaymentForm.vue')
      .default,
  },
  {
    name: 'fundanow/repayment/manual/histories/detail',
    path: '/fundanow/repayment/manual/histories/:loansRepaymentPreDataId',
    props: (route) => ({
      loansRepaymentPreDataId: Number(route.params.loansRepaymentPreDataId),
    }),
    meta: { title: '수동 상환 내역 상세' },
    component: require('@/pages/fundanow/repayment/manual/HistoryDetail.vue')
      .default,
  },
]
