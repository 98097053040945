<template>
  <v-card>
    <v-card-title>시스템 계좌 목록</v-card-title>
    <v-card-text class="py-1">
      <v-item-group v-model="selected" @change="onSelect">
        <v-row>
          <v-col
            v-for="(systemUser, index) in systemUsers"
            :key="index"
            cols="12"
            lg="3"
            md="4"
            sm="6"
            class="pa-1"
          >
            <v-item
              v-slot="{ active, toggle }"
              active-class="active-item"
              :value="systemUser.idx"
            >
              <v-card
                height="100%"
                class="d-flex align-center"
                :style="active == false ? 'border: 1px solid #d7e1ea;' : ''"
                @click="toggle"
                elevation="0"
              >
                <v-card-text
                  class="text-center pa-1"
                  :class="active == true ? 'white--text' : 'color--gray-3'"
                >
                  <p
                    class="font-size--17 mb-0 font-weight-bold"
                    style="word-break: keep-all"
                  >
                    {{ systemUser.label }}
                  </p>
                  <p
                    class="font-size--13 mb-0 font-weight-light"
                    style="line-height: 1"
                  >
                    {{ systemUser.accountNo }}
                  </p>
                </v-card-text>
              </v-card>
            </v-item>
          </v-col>
        </v-row>
      </v-item-group>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    userVirtualAccountIdx: {
      type: Number,
      default: null,
    },
  },
  watch: {
    userVirtualAccountIdx(v) {
      this.selected = v
    },
  },
  data() {
    return {
      selected: this.userVirtualAccountIdx,
    }
  },
  created() {},
  computed: {
    ...mapGetters('payment/system', {
      systemUsers: 'getVirtualAccounts',
    }),
  },
  methods: {
    onSelect(v) {
      this.$emit(
        'click',
        this.systemUsers.find((systemUser) => {
          return systemUser.idx === v
        })
      )
    },
  },
}
</script>
<style lang="scss" scoped>
.active-item {
  color: white;
  background-color: #333333;
}
</style>
