<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card class="elevation-12">
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title>Funda Admin Signin</v-toolbar-title>
          </v-toolbar>
          <v-card-actions class="justify-center">
            <v-btn
              @click="signin()"
              :disabled="btnSigninLoading"
              :loading="btnSigninLoading"
            >
              <v-icon>mdi-google</v-icon>oogle Account
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import AuthService from '@/service/auth/AuthService'

export default {
  data() {
    return {
      btnSigninLoading: false,
    }
  },
  methods: {
    async signin(user) {
      this.btnSigninLoading = true
      try {
        await AuthService.signin(user)

        let redirectUri = this.$route.query.redirectUri
        if (redirectUri == null) {
          redirectUri = '/'
        }
        this.$router.push({ path: redirectUri })
      } catch (e) {
        console.log(e)
        console.log(e.error)
        if (e.message === '준비안됨') {
          this.$swal.basic.error(e.message)
        } else if (e.error === 'popup_closed_by_user') {
        } else {
          throw e
        }
      } finally {
        this.btnSigninLoading = false
      }
    },
  },
}
</script>
