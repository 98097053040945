<template>
  <v-container fluid class="pt-0">
    <PageHeader title="금융실비 내역" subtitle="금융실비 내역" tabMenu>
    </PageHeader>
    <BankFeesTable
      :isQueryPaging="true"
      :query="query"
      @error="handleError"
    />
  </v-container>
</template>

<script>
import BankFeesTable from '@/components/accounting/BankFeesTable.vue'
import PageHeader from '@/components/common/page/PageHeader.vue'

export default {
  components: { BankFeesTable, PageHeader },
  props: {
    query: {
      type: Object,
      default() {
        return {
          page: 1,
          size: 10
        }
      },
    },
  },
  methods: {
    handleError(error) {
      console.error('API Error:', error)
    }
  }
}
</script>
