<template>
  <v-container fluid class="pt-0">
    <PageHeader
      title="상환용 가상계좌 거래내역 조회"
      subtitle="거래내역 조회"
      tabMenu
    >
    </PageHeader>
    <PaymentVirtualAccountTransactions
      v-if="vaccount != null"
      :userVirtualAccountIdx="vaccount.id"
      :isQueryPaging="true"
    />
    <div v-else>
      <p class="f-blue-grey--text title text-center mt-16">
        상환용 가상계좌가 없습니다.
      </p>
    </div>
  </v-container>
</template>
<script>
import PaymentVirtualAccountTransactions from '../../../components/payment/PaymentVirtualAccountTransactions.vue'
export default {
  components: {
    PaymentVirtualAccountTransactions,
  },
  props: {
    userId: {
      type: Number,
      required: true,
    },
    query: {
      type: Object,
      default: (_) => {
        return null
      },
    },
  },
  data() {
    return {
      vaccount: null,
    }
  },
  async created() {
    const { data: vaccount } =
      await this.$fundaNowApi.getUsersFundaPaygateRepaymentvirtualaccount(
        this.userId
      )
    if (vaccount) {
      this.vaccount = vaccount
      console.log(vaccount, 'vaccount')
    }
  },
}
</script>
