<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-autocomplete
        @update:search-input="search"
        @change="select"
        @click:clear="clear"
        :loading="isLoading"
        :disabled="isLoading"
        :disable-lookup="isLoading"
        :items="searchResultItems"
        return-object
        v-model="selectedItem"
        hide-details
        :label="label"
        placeholder="검색(사용자index, 계좌index, 계좌명)"
        clearable
        ref="autocomplete"
        outlined
        :dense="dense"
        v-bind="attrs"
        v-on="on"
      >
        <template
          v-slot:append-item
          v-if="searchResultTotalCount > searchResultMaxSize"
        >
          <div class="text-center pt-3 body-2 grey--text">
            <v-icon> mdi-dots-vertical </v-icon>
          </div>
          <div class="text-center pa-3 body-2 grey--text">
            검색 결과는 {{ searchResultMaxSize }}개까지만 표시됩니다.
          </div>
        </template>
      </v-autocomplete>
    </template>
    <span>검색 가능 : 사용자index, 계좌index, 계좌명</span>
  </v-tooltip>
</template>
<script>
export default {
  props: {
    propsAccount: {
      type: Object,
      default: (_) => {
        return null
      },
    },
    propsIsLoading: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    userVirtualAccountIdx: {
      type: Number,
      default: null,
    },
    label: {
      type: String,
      default: '가상계좌 검색',
    },
  },
  data() {
    return {
      searchDelay: 500,
      searchTimeout: null,
      searchResultItems: [],
      searchResultTotalCount: 0,
      searchResultMaxSize: 100,
      isLoading: false,
      selectedItem: null,
    }
  },
  watch: {
    propsAccount() {
      this.getPropsAccount()
    },
    propsIsLoading(v) {
      this.isLoading = v
    },
  },
  created() {
    this.getPropsAccount()
  },
  methods: {
    async getPropsAccount() {
      if (this.propsAccount == null && this.userVirtualAccountIdx == null) {
        this.selectedItem = null
        return
      }

      this.isLoading = true
      try {
        let accountData = this.propsAccount
        if (accountData == null) {
          const { data } = await this.$paymentApi.getUserVirtualAccountByIdx(
            this.userVirtualAccountIdx
          )
          accountData = data
        }

        let item = {
          text: this.getLabelText(accountData),
          value: accountData.idx,
          data: accountData,
        }

        this.searchResultItems = [item]
        this.selectedItem = item
      } finally {
        this.isLoading = false
      }
    },
    getLabelText(rowData) {
      return `(${rowData.userIdx}/${rowData.idx}) ${
        rowData.accountName
      } [${this.$options.filters.commaFormat(rowData.balance)}원] ${
        rowData.accountNo
      }`
    },
    search(e) {
      if (this.searchTimeout != null) {
        clearTimeout(this.searchTimeout)
      }

      // item 선택시 input value가 선택된 item의 text로 바뀌면서
      // v-autocomplete에서 @update:search-input 이벤트 발생
      if (this.selectedItem != null && e === this.selectedItem.text) {
        return
      }

      if (e == null || e.trim().length === 0 || this.isLoading === true) {
        return
      }

      this.searchTimeout = setTimeout(async (_) => {
        this.isLoading = true
        try {
          const {
            data: {
              data,
              paging: { totalCount },
            },
          } = await this.$paymentApi.getSearchUserVirtualAccounts({
            size: this.searchResultMaxSize,
            isClosed: false,
            keyword: e,
          })

          this.searchResultItems.splice(0, this.searchResultItems.length)
          for (let i = 0; i < data.length; i++) {
            const rowData = data[i]
            const item = {
              text: this.getLabelText(rowData),
              value: rowData.idx,
              data: rowData,
            }
            this.searchResultItems.push(item)
          }
          this.searchResultTotalCount = totalCount
        } finally {
          this.isLoading = false
          this.searchTimeout = null
          // v-autocomplete에서 disabled props가 변경되면 focus가 해제됨
          this.$nextTick((_) => {
            this.$refs.autocomplete.focus()
          })
        }
      }, this.searchDelay)
    },
    select(e) {
      this.$emit('select', e ? e.data : null)
      this.$emit('update:userVirtualAccountIdx', e ? e.data.idx : null)
    },
    clear() {
      this.select(null)
      this.$emit('update:userVirtualAccountIdx', null)
    },
  },
}
</script>
