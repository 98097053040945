<template>
  <PageSection title="상환용 가상계좌 정보" :isLoading="isLoading">
    <template slot="content" v-if="vaccount != null">
      <v-simple-table>
        <template v-slot:default>
          <tbody>
            <template v-if="vaccount && vaccount.bank">
              <tr>
                <td>index</td>
                <td>{{ vaccount.id }}</td>
              </tr>
              <tr>
                <td>계좌주명</td>
                <td>{{ vaccount.holderName }}</td>
              </tr>
              <tr>
                <td>은행</td>
                <td>{{ vaccount.bank | bankCodeName }}</td>
              </tr>
              <tr>
                <td>계좌번호</td>
                <td>{{ vaccount.accountNumber }}</td>
              </tr>
              <tr>
                <td>생성 시간</td>
                <td>{{ vaccount.created | moment('YYYY-MM-DD HH:mm:ss') }}</td>
              </tr>
              <tr>
                <td>수정 시간</td>
                <td>{{ vaccount.modified | moment('YYYY-MM-DD HH:mm:ss') }}</td>
              </tr>
              <tr>
                <td>자동 상환 여부</td>
                <td>
                  <AutoRepaymentSwitch
                    :fundaPaygateRepaymentvirtualaccountId="vaccount.id"
                  />
                </td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td class="py-5 text-center subtitle-1 grey--text">
                  아직 등록된 상환용 가상 계좌가 없습니다.
                </td>
              </tr>
            </template>
          </tbody>
        </template>
      </v-simple-table>
    </template>
  </PageSection>
</template>
<script>
import AutoRepaymentSwitch from './AutoRepaymentSwitch.vue'
export default {
  components: { AutoRepaymentSwitch },
  props: {
    userId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      vaccount: null,
      isLoading: false,
    }
  },
  async created() {
    try {
      this.isLoading = true

      const { data } =
        await this.$fundaNowApi.getUsersFundaPaygateRepaymentvirtualaccount(
          this.userId
        )
      this.vaccount = data
    } finally {
      this.isLoading = false
    }
  },
}
</script>
<style lang="scss" scoped>
tr > td:last-child {
  word-break: break-all;
}
</style>
