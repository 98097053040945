<template>
  <div>
    <slot
      name="table"
      :loading="isLoading"
      :totalCount="totalCount"
      :items="items"
    ></slot>
  </div>
</template>
<script>
export default {
  props: {
    options: {
      type: Object,
      default: (_) => {
        return {}
      },
    },
  },
  data() {
    return {
      paramNames: ['page', 'size', 'mobileNo', 'keyword'],
      isLoading: false,
      totalCount: 0,
      items: [],
      getDataOptions: null,
    }
  },
  watch: {
    isLoading(v) {
      this.$emit('loading', v)
    },
    options: {
      handler: function (newVal) {
        const oldVal = this.getDataOptions
        if (newVal == null || JSON.stringify(newVal) === '{}') {
          return
        }

        const isChange =
          oldVal == null ||
          this.paramNames.some((option) => {
            return newVal[option] !== oldVal[option]
          })

        if (isChange === true) {
          this.getPage(newVal)
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    async getPage(options) {
      if (this.isLoading === true) {
        return
      }
      this.isLoading = true
      try {
        let param = {}
        this.paramNames.forEach((paramName) => {
          param[paramName] = options[paramName]
        })

        const {
          data: {
            paging: { totalCount },
            data,
          },
        } = await this.$smsApi.getSmses(param)
        // , page, size

        this.getDataOptions = JSON.parse(JSON.stringify(options))
        // this.$emit('update:options', options)

        this.totalCount = totalCount

        this.items = data
        return data
      } catch (e) {
        console.error(e)
        this.$emit('error', e)
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
