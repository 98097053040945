<template>
  <div>
    <v-row no-gutters>
      <v-col></v-col>
      <v-col v-if="!!info" cols="12">
        현재
        <v-chip
          class="ma-2"
          :color="info.visible === true ? 'primary' : 'orange'"
          @click="toggleVisible()"
        >
          {{ info.visible === true ? '공개' : '비공개' }}
        </v-chip>
        상태입니다
      </v-col>

      <v-col cols="12">
        <h2 class="my-2 pl-1 color--gray-3 font-size--18 font-weight-black">
          {{ tableTitle }}
        </h2>
      </v-col>
    </v-row>

    <v-row no-gutters class="mb-5">
      <v-col cols="12">
        <div class="data-table-wrapper">
          <table v-if="!!info">
            <thead>
              <tr>
                <th class="text-center">구분</th>
                <th
                  v-for="h in header"
                  :key="'header_' + h.text"
                  class="text-center"
                >
                  {{ h.text }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="k in keys" :key="k.text">
                <td class="text-center">{{ k.text }}</td>
                <td
                  v-for="h in header"
                  :key="k.text + '_' + h.text"
                  class="text-right"
                >
                  <template
                    v-if="h.value === '평균금리' || h.value === '연체율'"
                  >
                    {{ info.details[k.value][h.value] | rateFormat }} %
                  </template>
                  <template v-else>
                    {{ info.details[k.value][h.value] | commaFormat }}
                  </template>
                </td>
              </tr>
            </tbody>
          </table>

          <p v-else class="my-5 font-size--16">내역이 없습니다.</p>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    tableTitle: {
      type: String,
      default: '회사의 연계대출 채권 현황 및 연체율 등 연체에 관한 현황',
    },
    year: {
      required: true,
    },
    month: {
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      monthlyNotice: null,
      keys: [
        { text: '부동산 PF', value: '부동산PF' },
        { text: '부동산담보', value: '부동산담보' },
        {
          text: '기타담보 (어음, 매출채권 담보 제외)',
          value: '기타담보(어음.매출채권담보제외)',
        },
        { text: '어음, 매출채권 담보', value: '어음.매출채권담보' },
        { text: '개인사업자 신용', value: '개인사업자신용대출' },
        { text: '법인 신용', value: '법인사업자신용대출' },
        { text: '합계', value: '합계' },
      ],
      header: [
        { text: '누적연계 대출금액', value: '누적연계대출금액' },
        { text: '연계대출 잔액', value: '연계대출잔액' },
        { text: '평균금리', value: '평균금리' },
        { text: '연체채권 잔액', value: '연체채권잔액' },
        { text: '연체율', value: '연체율' },
      ],
    }
  },
  computed: {
    padMonth() {
      let result = `${this.month}`
      result = result.padStart(2, '0')
      return result
    },
    yearHyphenMonth() {
      return `${this.year}-${this.padMonth}`
    },
    info() {
      return this.monthlyNotice?.[this.yearHyphenMonth]
    },
  },
  watch: {
    yearHyphenMonth: {
      immediate: true,
      async handler() {
        await this.fetchData()
      },
    },
  },
  methods: {
    getDefault() {
      const details = {}
      this.keys.forEach((k) => {
        details[k.value] = {}
        this.header.forEach((h) => {
          details[k.value][h.value] = 0
        })
      })

      return { details, visible: false }
    },
    async fetchData() {
      if (this.isLoading || !!this.info) {
        return
      }

      const temp = { ...this.monthlyNotice }

      const q = `
          data: getMonthlyNoticeForAdmin(category: "OVERDUE_DETAILS", yyyy: "${this.year}", mm: "${this.padMonth}") {
            idx
            yyyy
            mm
            details
            visible
            createTime
          }
        `

      this.isLoading = true
      try {
        const data = await this.$fundaApi.query(gql`{${q}}`)
        data.details = JSON.parse(data.details)
        temp[this.yearHyphenMonth] = data
      } catch (e) {
        this.$swal.basic.error(e.message)
        temp[this.yearHyphenMonth] = this.getDefault()
        throw e
      } finally {
        this.monthlyNotice = temp
        this.isLoading = false
      }
    },
    async toggleVisible() {
      if (!this.info.idx) {
        return
      }

      const confirm = await this.$swal.basic.confirm(
        `${this.yearHyphenMonth} 공시의 공개상태를 변경하시겠습니까?`
      )
      if (confirm.isConfirmed === false) {
        return
      }

      const q = `
        data: toggleMonthlyNotice(idx: ${
          this.monthlyNotice[this.yearHyphenMonth].idx
        }) {
          idx
          category
          yyyy
          mm
          visible
          createTime
          updateTime
        }
      `

      this.isLoading = true
      try {
        const data = await this.$fundaApi.mutation(gql`{${q}}`)
        this.monthlyNotice[this.yearHyphenMonth].visible = data.visible
        this.$swal.basic.success(
          `${this.yearHyphenMonth} 공시의 공개상태가 변경되었습니다.`
        )
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
