<template>
  <v-container fluid class="pt-0">
    <PageHeader title="고객 상세 조회" subtitle="고객 상세 조회" tabMenu>
    </PageHeader>
    <v-row>
      <v-col lg="4" md="6" sm="6" cols="12">
        <User :userId="userId" @update:user="user = $event" />
        <PaymentUserByFundanowUser
          v-if="fundaPaygateUser"
          :paymentUserIdx="fundaPaygateUser.id"
        />
      </v-col>
      <v-col lg="4" md="6" sm="6" cols="12">
        <Store :userId="userId" />
      </v-col>
      <v-col lg="4" md="6" sm="6" cols="12">
        <WithdrawalAccount
          :userId="userId"
          v-if="fundaPaygateUser"
          :paymentUserIdx="fundaPaygateUser.id"
        />
      </v-col>
      <v-col lg="4" md="6" sm="6" cols="12">
        <RepaymentVirtualAccount :userId="userId" />
      </v-col>
      <v-col lg="4" md="6" sm="6" cols="12">
        <Kyc :userId="userId" />
      </v-col>
      <v-col
        :lg="collectionAccountTabs.fullWidth ? 12 : 4"
        :md="collectionAccountTabs.fullWidth ? 12 : 6"
        :sm="collectionAccountTabs.fullWidth ? 12 : 6"
        cols="12"
      >
        <CollectionAccountTabs
          :userId="userId"
          :loading="!user"
          :birthDate="user ? user.birthdate : null"
          :isExpanded.sync="collectionAccountTabs.fullWidth"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <div>
          <ValidationProvider name="bankCode" rules="required">
            <BankSelect
              v-model="bankCode"
              :bankCode.sync="bankCode"
              :isLoading="isLoading"
            />
          </ValidationProvider>
          <v-spacer class="my-3" />
          <ValidationProvider
            name="accountNo"
            rules="required|numeric"
            v-slot="{ errors }"
          >
            <v-text-field
              label="계좌 번호"
              v-model="accountNo"
              :error-messages="errors"
              :disabled="isLoading"
              autofocus
              outlined
              :hide-details="errors.length == 0"
            />
          </ValidationProvider>
          <v-spacer class="my-3" />
          <AccountInfoButton
            :bankCode="bankCode"
            :accountNo="accountNo"
            :realAccountName.sync="realAccountName"
          />
          <p v-if="realAccountName" class="primary--text">
            조회된 계좌 명: {{ realAccountName }}
            <v-btn @click="copy(realAccountName)">복사</v-btn>
          </p>
          <v-spacer class="my-3" />
          <v-btn
            v-if="realAccountName"
            color="warning"
            block
            large
            :loading="isInitializing"
            :disabled="isInitializing"
            @click="initializeAccount"
          >
            해당 이름으로 계좌초기화
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <PaymentUserVirtualAccounts
          v-if="fundaPaygateUser"
          :key="virtualAccountsKey"
          :userIdx="fundaPaygateUser.id"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import User from '@/components/fundanow/user/User.vue'
import Store from '@/components/fundanow/user/Store.vue'
import WithdrawalAccount from '@/components/fundanow/user/WithdrawalAccount.vue'
import Kyc from '@/components/fundanow/user/Kyc.vue'
import PaymentUserByFundanowUser from '@/components/payment/user/PaymentUserByFundanowUser.vue'
import RepaymentVirtualAccount from '@/components/fundanow/user/RepaymentVirtualAccount.vue'
import PaymentUserVirtualAccounts from '@/components/payment/user/VirtualAccounts.vue'
import CollectionAccountTabs from '@/components/fundanow/user/bnk/CollectionAccountTabs.vue'
import AccountInfoButton from '@/components/payment/common/button/AccountInfoButton.vue'
import BankSelect from '@/components/payment/common/select/BankSelect.vue'
import Util from '@/util'

export default {
  components: {
    User,
    Store,
    WithdrawalAccount,
    Kyc,
    PaymentUserByFundanowUser,
    RepaymentVirtualAccount,
    PaymentUserVirtualAccounts,
    CollectionAccountTabs,
    AccountInfoButton,
    BankSelect,
  },
  props: {
    userId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      bankCode: null,
      accountNo: null,
      realAccountName: null,
      virtualAccountsKey: 0,
      fundaPaygateUser: null,
      user: null,
      isLoading: false,
      isInitializing: false,
      collectionAccountTabs: {
        fullWidth: false,
      },
    }
  },
  async created() {
    const { data } = await this.$fundaNowApi.getUserFundaPaygateUser(
      this.userId
    )
    this.fundaPaygateUser = data
  },
  methods: {
    async copy(msg) {
      Util.textCopy(msg)
      await this.$swal.basic.alert('복사 되었습니다.')
    },
    async initializeAccount() {
      if (!this.realAccountName || !this.fundaPaygateUser) {
        await this.$swal.basic.alert({
          title: '계좌초기화 실패',
          text: !this.fundaPaygateUser
            ? '페이먼트 유저 정보를 찾을 수 없습니다.'
            : '계좌주 정보가 없습니다.',
        })
        return
      }
      try {
        this.isInitializing = true

        // 1. 계좌 검증 해제 API 호출
        const { data: disableData } = await this.$paymentApi.disableWithdrawAccountVerify(
          this.fundaPaygateUser.id
        )

        // 2. 가상계좌 이름 변경 API 호출
        await this.$paymentApi.renameVirtualAccount(
          disableData.userVirtualAccountIdx,
          this.realAccountName
        )

        console.log(disableData, this.fundaPaygateUser.id)

        await this.$swal.basic.alert({
          title: '계좌초기화 완료',
          text: '계좌가 성공적으로 초기화되었습니다.',
        })
        this.virtualAccountsKey++ // 가상계좌 목록 새로고침
      } catch (error) {
        await this.$swal.basic.alert({
          title: '계좌초기화 실패',
          text: error.message || '계좌초기화 중 오류가 발생했습니다.',
        })
      } finally {
        this.isInitializing = false
      }
    },
  },
}
</script>
