<template>
  <v-btn
    fab
    dark
    color="warning"
    elevation="0"
    :disabled="fromAccount == null && toAccount == null"
    @click="swap"
  >
    <v-icon class="d-none d-lg-block">mdi-swap-horizontal</v-icon>
    <v-icon class="d-block d-lg-none">mdi-swap-vertical</v-icon>
  </v-btn>
</template>
<script>
export default {
  props: {
    fromAccount: {
      type: Object,
      default: (_) => {
        return null
      },
    },
    toAccount: {
      type: Object,
      default: (_) => {
        return null
      },
    },
  },
  methods: {
    swap() {
      this.$emit('update:fromAccount', this.toAccount)
      this.$emit('update:toAccount', this.fromAccount)
    },
  },
}
</script>
<style lang="scss" scoped>
.theme--dark.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined)
  ::v-deep {
  background-color: #e1e9f3 !important;
  cursor: not-allowed;
  &.v-btn__content {
    color: #8a94a2 !important;
    & i {
      color: inherit !important;
    }
  }
}
</style>
