<template>
  <div class="width-100 mt-1">
    <div class="width-100 balance-deposit width-100 font-nanumsquare mb-5">
      <div
        class="my-balance-deposit-table__filter d-flex mb-2 align-center"
        v-if="filters != null"
      >
        <div
          v-for="(filter, index) in filters"
          :key="index"
          class="text-center cursor--pointer py-2 px-4"
          :class="[
            { active: filter.value == selectedFilter },
            filter.value == selectedFilter
              ? 'color-default-gray font-weight-black'
              : 'color--gray font-weight-bold',
          ]"
          @click="changeOption(filter.value)"
        >
          {{ filter.name }}
        </div>
        <div class="point-balance d-flex justify-end align-center mt-4 mb-2">
          <span class="d-flex justify-center align-center white--text mr-1"
            >P</span
          >
          <span>{{ pointBalance | commaFormat }}</span>
        </div>
      </div>
      <BalanceDepositTable
        :data="pagingData"
        :headers="headers"
        :is-loading="tableData.isLoading"
      >
        <template slot="col" slot-scope="slotProps">
          <div
            :style="styles.dateTime.pc"
            class="justify-center color--gray font-weight-bold"
          >
            {{ slotProps.row.dateTime | dateFormat2 }}
          </div>
          <div
            :style="styles.selectedFilterName.pc"
            class="justify-center font-weight-black"
            style="font-size: 13px"
          >
            {{ selectedFilterName }}
          </div>
          <div
            :style="styles.memo.pc"
            class="
              justify-center
              font-weight-bold
              my-balance-deposit-table__text
            "
          >
            <span>{{ slotProps.row.memo }}</span>
          </div>
          <div
            :style="styles.amount.pc"
            class="justify-end font-weight-black"
            style="font-size: 14px"
          >
            {{ slotProps.row.amount | commaFormat }} P
          </div>
          <div
            :style="
              selectedFilter != 'GIVE' ? 'display: none;' : styles.expireTime.pc
            "
            class="justify-center color--gray font-weight-bold"
          >
            {{ slotProps.row.expireTime | dateFormat2 }}
          </div>
        </template>
      </BalanceDepositTable>
    </div>
    <PageMaker
      v-if="tableData.transactions"
      :key="selectedFilter"
      :is-reverse="true"
      :page="paging.page"
      :size="paging.size"
      :total-count="paging.totalCount"
      :raw-data="tableData.transactions"
      :data-loading="tableData.isLoading"
      :is-white="false"
      @currentData="setPageData($event)"
      @currentPage="paging.page = $event"
    />
  </div>
</template>

<script>
import BalanceDepositTable from './BalanceDepositTable'
import PageMaker from '../PageMaker'

export default {
  props: {
    userIdx: Number,
  },
  components: {
    BalanceDepositTable,
    PageMaker,
  },
  data() {
    return {
      pagingData: null,
      selectedFilter: null,
      selectedFilterName: null,
      filters: [
        { name: '사용', value: 'USE' },
        { name: '소멸', value: 'EXPIRE' },
        { name: '지급', value: 'GIVE' },
      ],
      styles: {
        dateTime: {
          pc: {
            width: '15%',
          },
          mobile: {
            order: 1,
            width: '10%',
            minWidth: '90px',
            justifyContent: 'end',
          },
        },
        selectedFilterName: {
          pc: {
            width: '10%',
          },
          mobile: {
            order: 3,
            width: '30%',
            justifyContent: 'end',
          },
        },
        memo: {
          pc: {
            width: '53%',
          },
          mobile: {
            order: 2,
            width: '90%',
            maxWidth: 'calc(100% - 90px)',
            justifyContent: 'flex-end',
            marginBottom: '7px',
          },
        },
        amount: {
          pc: {
            width: '22%',
          },
          mobile: {
            order: 4,
            width: '70%',
            justifyContent: 'flex-end',
          },
        },
        expireTime: {
          pc: {
            width: '15%',
          },
        },
      },
      headers: null,
      headersWithExp: [
        { name: '날짜', width: '15%' },
        { name: '구분', width: '10%' },
        { name: '내역', width: '38%' },
        { name: '포인트', width: '22%' },
        { name: '유효기간', width: '15%' },
      ],
      headersDefault: [
        { name: '날짜', width: '15%' },
        { name: '구분', width: '10%' },
        { name: '내역', width: '53%' },
        { name: '포인트', width: '22%' },
      ],
      tableData: {
        transactions: null,
        paging: null,
        isLoading: false,
      },
      pointBalance: null,
      totalData: null,
    }
  },
  async created() {
    this.tableData.isLoading = true
    this.getPointBalance()

    this.selectedFilter = 'USE'
    this.selectedFilterName = this.filters.find(
      (filter) => filter.value === this.selectedFilter
    ).name
    this.page = this.size = 20

    this.setTableHeader()
    this.getPointContents()
  },
  methods: {
    setTableHeader() {
      if (this.selectedFilter === 'GIVE') {
        this.headers = this.headersWithExp
        this.styles.memo.pc.width = '38%'
      } else {
        this.headers = this.headersDefault
        this.styles.memo.pc.width = '53%'
      }
    },
    setCategoryData(datas) {
      let selectListName = null
      if (this.selectedFilter === 'GIVE') {
        selectListName = '지급내역'
      } else if (this.selectedFilter === 'USE') {
        selectListName = '사용내역'
      } else if (this.selectedFilter === 'EXPIRE') {
        selectListName = '소멸내역'
      }

      this.tableData.transactions = datas[selectListName]
      this.paging = {
        size: this.size,
        page: 1,
        totalCount: datas[selectListName].length,
      }
    },
    setPageData(datas) {
      let headers = ['dateTime', 'memo', 'amount']
      let dataColumnNames = null
      if (this.selectedFilter === 'GIVE') {
        headers.push('expireTime')
        dataColumnNames = {
          dateTime: '지급일',
          memo: '비고',
          amount: '금액',
          expireTime: '유효기간',
        }
      } else if (this.selectedFilter === 'USE') {
        dataColumnNames = {
          dateTime: '사용일',
          memo: '사용처',
          amount: '금액',
        }
      } else if (this.selectedFilter === 'EXPIRE') {
        dataColumnNames = {
          dateTime: '소멸일',
          memo: '소멸사유',
          amount: '금액',
        }
      }

      let arr = []
      for (let i = 0; i < datas.length; i++) {
        let row = datas[i]

        let obj = {}
        for (let j = 0; j < headers.length; j++) {
          obj[headers[j]] = row[dataColumnNames[headers[j]]]
        }
        arr.push(obj)
      }
      this.pagingData = arr
    },
    async getPointContents() {
      let q = `
        data: getPointContents(userIdx: ${this.userIdx})
      `

      let data = await this.$fundaApi.query(gql`{${q}}`)
      data = JSON.parse(data)

      this.totalData = data
      this.setCategoryData(data)
      this.tableData.isLoading = false
    },
    async getPointBalance() {
      let q = `
          data: getMyPointDeposit(userIdx: ${this.userIdx})
        `

      let data = await this.$fundaApi.query(gql`{${q}}`)
      this.pointBalance = data
    },
    changeOption(value) {
      this.selectedFilter = value
      this.selectedFilterName = this.filters.find(
        (filter) => filter.value === this.selectedFilter
      ).name
      this.setTableHeader()
      if (this.totalData != null) {
        this.setCategoryData(this.totalData)
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
