<template>
  <v-select
    :items="items"
    label="isProcess"
    outlined
    dense
    hide-details
    @change="$emit('change', $event)"
    v-model="selectedValue"
    class="mr-3"
  ></v-select>
</template>
<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      selectedValue: this.value,
      items: [
        { text: '전체', value: null },
        { text: '미완', value: false },
        { text: '완료', value: true },
      ],
    }
  },
}
</script>
