<template>
  <PageSection title="사용자 정보" :isLoading="isLoading">
    <template slot="content" v-if="user != null">
      <v-simple-table>
        <template v-slot:default>
          <tbody>
            <tr>
              <td>index</td>
              <td v-if="$route.name === 'payment/users/detail'">
                {{ user.idx }}
              </td>
              <td v-else>
                <router-link
                  :to="{
                    name: 'payment/users/detail',
                    params: { userIdx: user.idx },
                  }"
                  target="_blank"
                >
                  {{ user.idx }}
                </router-link>
              </td>
            </tr>
            <tr>
              <td>apiClientIdx</td>
              <td>{{ user.apiClientIdx }}</td>
            </tr>
            <tr>
              <td>아이디</td>
              <td>{{ user.id }}</td>
            </tr>
            <tr>
              <td>이름</td>
              <td>{{ user.name }}</td>
            </tr>
            <tr>
              <td>핸드폰 번호</td>
              <td>{{ user.mobileNo }}</td>
            </tr>
            <tr>
              <td>생성 시간</td>
              <td>{{ user.createTime | moment('YYYY-MM-DD HH:mm:ss') }}</td>
            </tr>
            <tr>
              <td>탈퇴 여부</td>
              <td class="pt-2 pb-3">
                <v-switch
                  class="mt-0"
                  :input-value="user.isWithdrawal"
                  readonly
                  disabled
                  hide-details
                />
              </td>
            </tr>
            <!-- 투자ㅣ 유저인 경우에만  -->
            <tr v-if="user.apiClientIdx === 3">
              <td>투자유저</td>
              <td><funda-user-by-payment :user="user" /></td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </template>
  </PageSection>
</template>
<script>
import FundaUserByPayment from './FundaUserByPayment.vue'
export default {
  components: { FundaUserByPayment },
  props: {
    userIdx: {
      type: Number,
      default: (_) => {
        return null
      },
    },
  },
  data() {
    return {
      user: null,
      isLoading: false,
      fundaUser: null,
    }
  },
  async created() {
    try {
      this.isLoading = true

      if (this.userIdx) {
        const { data } = await this.$paymentApi.getUser(this.userIdx)
        this.user = data
        this.$emit('change', data)
      }
    } finally {
      this.isLoading = false
    }
  },
}
</script>
<style lang="scss" scoped>
tr > td:last-child {
  word-break: break-all;
}
</style>
