import client from '../client'

export default {
  getBizmBalance() {
    return client.call({
      url: '/bizm/balance',
      method: 'GET',
    })
  },
}
