<template>
  <span>
    <v-switch
      class="mt-0"
      v-model="switchValue"
      @click="clickSwitch"
      :disabled="isLoading"
      hide-details
      v-show="!isLoading"
    >
    </v-switch>
    <v-progress-circular
      v-show="isLoading"
      :indeterminate="isLoading"
      :value="0"
      size="24"
      class="ml-2"
    ></v-progress-circular>
  </span>
</template>
<script>
export default {
  props: {
    fieldName: {
      type: String,
      required: true
    },
    isDone: {
      type: Boolean,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    userId: {
      type: Number,
      required: true
    },
    kycId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      switchValue: this.isDone,
      isLoading: false
    }
  },
  watch: {
    isDone() {
      this.syncSwitchValue()
    }
  },
  methods: {
    syncSwitchValue() {
      // props 값으로 변경
      this.switchValue = this.isDone
    },
    async clickSwitch() {
      if (this.isLoading) {
        return
      }
      try {
        this.isLoading = true

        let btnResult = await this.$swal.basic.confirm({
          title: '인증 변경',
          text: `${this.label}를 ${this.switchValue ? '인증' : '인증해제'} 상태로 변경하시겠습니까?`,
        })

        if (btnResult.isConfirmed !== true) {
          return
        }

        let body = {}
        body[this.fieldName] = this.switchValue

        const { data } = await this.$fundaNowApi.patchUserKyc(this.userId, this.kycId, body)
        this.$emit('update:kyc', data)
      } finally {
        // 스위치 클릭이벤트 발생하면 무조건 값 바뀜;
        // finally에서 무조건 props 값으로 변경
        this.syncSwitchValue()
        this.isLoading = false
      }
    }
  }
}
</script>
