<template>
  <PageSection title="오퍼(약정 금리 정보)" :isLoading="isLoading">
    <template slot="content" v-if="offer != null">
      <v-simple-table>
        <template v-slot:default>
          <tbody>
            <tr>
              <td>index</td>
              <td>{{ offer.id }}</td>
            </tr>
            <tr>
              <td>이자율</td>
              <td>{{ offer.interestRate }}</td>
            </tr>
            <tr>
              <td>연체이자율</td>
              <td>{{ offer.overdueInterestRate.toFixed(4) }}</td>
            </tr>
            <tr>
              <td>최고금리</td>
              <td>{{ offer.maxInterestRate }}</td>
            </tr>
            <tr>
              <td>선취수수료율</td>
              <td>{{ offer.commissionRate }}</td>
            </tr>
            <tr>
              <td>수수료율</td>
              <td>{{ offer.feeRate }}</td>
            </tr>
            <tr>
              <td>상환기간</td>
              <td>{{ offer.repaymentPeriodInDays }}</td>
            </tr>
            <tr>
              <td>오퍼 생성 시간</td>
              <td>{{ offer.created | moment('YYYY-MM-DD HH:mm:ss') }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </template>
  </PageSection>
</template>
<script>
export default {
  props: {
    loanId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      offer: null,
      isLoading: false,
    }
  },
  async created() {
    try {
      this.isLoading = true
      const { data } = await this.$fundaNowApi.getLoansOffer(this.loanId)
      this.offer = data
      this.$emit('update:userId', data.ownerId)
    } finally {
      this.isLoading = false
    }
  },
}
</script>
<style lang="scss" scoped>
tr > td:last-child {
  word-break: break-all;
}
</style>
