import client from '../client'
import qs from 'query-string'

export default {
  /**
   * 상태 조회(수수료)
   * @param {date: 조회할 날짜} param
   */
  getAdminStatus(param) {
    return client.call({
      uri: `/admin/status?${qs.stringify(param)}`,
      method: HttpMethod.GET,
    })
  },
}
