import Vue from 'vue'
import pluginValue from 'sweetalert2'
const pluginName = '$swal'

const confirmButtonText = '확인'
const cancelButtonText = '취소'

function checkText(option) {
  if (typeof option === 'string') {
    option = {
      text: option,
    }
  }
  return option
}

pluginValue.basic = {
  alert(option) {
    option = checkText(option)
    return pluginValue.fire(
      Object.assign(
        {
          confirmButtonText,
          customClass: {
            container: 'custom-alert__container',
            popup: 'custom-alert__popup',
            content: 'custom-alert__content',
            confirmButton: 'custom-alert__ok-btn',
          },
        },
        option
      )
    )
  },
  warning(option) {
    option = checkText(option)
    return pluginValue.fire(
      Object.assign(
        {
          icon: 'warning',
          confirmButtonText,
          reverseButtons: true,
          customClass: {
            container: 'custom-alert__container icon-container',
            popup: 'custom-alert__popup',
            content: 'custom-alert__content',
            confirmButton: 'custom-alert__ok-btn',
          },
        },
        option
      )
    )
  },
  confirm(option) {
    option = checkText(option)
    return pluginValue.fire(
      Object.assign(
        {
          showCancelButton: true,
          confirmButtonText,
          cancelButtonColor: 'red',
          cancelButtonText,
          reverseButtons: true,
          customClass: {
            container: 'custom-alert__container',
            popup: 'custom-alert__popup',
            content: 'custom-alert__content',
            confirmButton: 'custom-alert__confirm-btn',
            cancelButton: 'custom-alert__cancel-btn',
          },
        },
        option
      )
    )
  },
  success(option) {
    option = checkText(option)
    return pluginValue.fire(
      Object.assign(
        {
          // title: '성공',
          icon: 'success',
          confirmButtonText,
          reverseButtons: true,
          allowOutsideClick: false,
          customClass: {
            container: 'custom-alert__container icon-container',
            popup: 'custom-alert__popup',
            content: 'custom-alert__content',
            confirmButton: 'custom-alert__ok-btn',
          },
        },
        option
      )
    )
  },
  error(option) {
    option = checkText(option)
    return pluginValue.fire(
      Object.assign(
        {
          confirmButtonText,
          reverseButtons: true,
          customClass: {
            container: 'custom-alert__container',
            popup: 'custom-alert__popup',
            content: 'custom-alert__content',
            confirmButton: 'custom-alert__ok-btn',
          },
        },
        option
      )
    )
    // option = checkText(option)
    // return pluginValue.fire(Object.assign({
    //   // title: '실패!!',
    //   icon: 'error',
    //   // timer: 2500,
    //   confirmButtonText,
    //   // toast: true,
    //   showConfirmButton: true,
    //   allowOutsideClick: false,
    //   timerProgressBar: true
    // }, option))
  },
  custom(option) {
    option = checkText(option)
    return pluginValue.fire(Object.assign({}, option))
  },
  loading(option) {
    option = checkText(option)
    return pluginValue.fire(
      Object.assign(
        {
          title: '실행중...',
          icon: 'info',
          showConfirmButton: true,
          timerProgressBar: true,
          allowOutsideClick: false,
          didOpen() {
            pluginValue.showLoading()
          },
        },
        option
      )
    )
  },
}

const plugin = {
  install(Vue) {
    window[pluginName] = pluginValue
    Vue.prototype[pluginName] = pluginValue
  },
}

Vue.use(plugin)

export default plugin
