<template>
  <div>
    <v-btn @click="test">test</v-btn>
    {{ 'TRANSFER_SUCCESS' | payment.code('Invest.Status') }}
    {{ 'TRANSFER_SUCCESS' | payment.code('Invest.Status') }}
    {{ 'TRANSFER_FAIL' | payment.code('Invest.Status') }}
    {{ '1' | bankCodeName }}
    {{ '1' | bankCodeName }}
    {{ test1 }}
    <!-- <div style="height:100%">
      <iframe src="http://dev-dashboard.funda.kr" style="height: 100%; width: 100%; border: 0"></iframe>
    </div> -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  data() {
    return {
      file: null,
    }
  },
  computed: {
    ...mapGetters('payment/system', {
      test1: 'getVirtualAccounts',
    }),
  },
  async created() {},
  methods: {
    ...mapActions('payment/system', ['getVirtualAccounts']),
    async test() {
      await this.$fundaApi.query(gql`
        {
          getPaymentUserWithdrawAccountAllowBanks {
            code
            name
          }
        }
      `)
      // await this.getVirtualAccounts()
    },
  },
}
</script>
