<template>
  <div style="background-color: white">
    <div class="contents__wrapper">
      <div class="contents__main">
        <v-row no-gutters>
          <v-col cols="12" class="text-center">
            <p class="ma-0 font-size--23 font-weight-bold line-height--27">
              채권 매각
            </p>
          </v-col>
          <v-col cols="12">
            <VoteForWriteOffPopup
              v-if="disposals.length > 0"
              :userIdx="userIdx"
              :vote.sync="voteStores"
            />
          </v-col>
        </v-row>
        <hr />
        <v-row class="pt-4" no-gutters>
          <v-col v-if="isLoading" cols="12">
            <div class="text-center">
              <MyLoader />
            </div>
          </v-col>
          <template v-else>
            <v-col cols="12">
              <p class="mb-1 font-size--14" style="line-height: 1.4">
                * 매각 예정 : 매각을 위해 투자자 투표가 진행 중인 채권입니다.
                투표 결과에 따라 매각이 진행되지 않을 수 있으며, 매각이 진행될
                경우 추후 매각 후 회수액이 표기됩니다.
              </p>

              <div class="mb-3">
                <p
                  class="mt-3 mb-1 font-size--14 font-weight-bold"
                  style="line-height: 1.4"
                >
                  - 매각 예정 총 투자액:
                  {{ voteAmt.fInvestmentAmount | commaFormat }} 원
                </p>

                <template v-if="voteAmt.rejectedInvestmentAmount > 0">
                  <p class="mb-1 pl-2" style="line-height: 1.4">
                    ㄴ 매각 확정 금액:
                    {{
                      (voteAmt.fInvestmentAmount -
                        voteAmt.rejectedInvestmentAmount)
                        | commaFormat
                    }}
                    원
                  </p>
                  <p class="mb-1 pl-2" style="line-height: 1.4">
                    ㄴ 매각 제외 금액:
                    {{ voteAmt.rejectedInvestmentAmount | commaFormat }} 원
                  </p>
                </template>
              </div>

              <div class="mb-3">
                <p
                  class="mb-1 font-size--14 font-weight-bold"
                  style="line-height: 1.4"
                >
                  - 매각 예정 총 상환액:
                  {{ voteAmt.rpyAmount | commaFormat }} 원
                </p>

                <template v-if="voteAmt.rejectedRpyAmount > 0">
                  <p class="mb-1 pl-2" style="line-height: 1.4">
                    ㄴ 매각 확정 금액:
                    {{
                      (voteAmt.rpyAmount - voteAmt.rejectedRpyAmount)
                        | commaFormat
                    }}
                    원
                  </p>
                  <p class="mb-1 pl-2" style="line-height: 1.4">
                    ㄴ 매각 제외 금액:
                    {{ voteAmt.rejectedRpyAmount | commaFormat }} 원
                  </p>
                </template>
              </div>

              <div class="mb-3">
                <p
                  class="mb-1 font-size--14 font-weight-bold"
                  style="line-height: 1.4"
                >
                  - 매각 예정 총 잔액:
                  {{ voteAmt.balance | commaFormat }} 원
                </p>

                <template v-if="voteAmt.rejectedBalance > 0">
                  <p class="mb-1 pl-2" style="line-height: 1.4">
                    ㄴ 매각 확정 금액:
                    {{
                      (voteAmt.balance - voteAmt.rejectedBalance) | commaFormat
                    }}
                    원
                  </p>
                  <p class="mb-1 pl-2" style="line-height: 1.4">
                    ㄴ 매각 제외 금액:
                    {{ voteAmt.rejectedBalance | commaFormat }} 원
                  </p>
                </template>
              </div>

              <p class="mb-1 font-size--14" style="line-height: 1.4">
                => 예상 회수 금액 (채권별 평가율이 다름):

                <span v-if="voteStores">
                  {{ voteAmt.expected | commaFormat }}
                  원
                </span>

                <span v-else class="red--text">
                  위 매각 투표하기 버튼을 통해 투표를 선택해야 예상 회수 금액이
                  표기됩니다.
                </span>
              </p>
              <p class="mb-1 font-size--14" style="line-height: 1.4">
                => 매각 후 총 상환액:

                <span v-if="voteStores">
                  {{ (voteAmt.expected + voteAmt.rpyAmount) | commaFormat }}
                  원
                </span>
              </p>

              <p
                class="mt-4 mb-1 font-size--14 text-right"
                style="line-height: 1.4"
              >
                ◎
                <span v-if="waitingFunds.length > 0">
                  매각 투표 : {{ waitingFunds.length | commaFormat }} 건 /
                </span>

                <span v-if="rejectedFunds.length > 0">
                  매각 제외 : {{ rejectedFunds.length | commaFormat }} 건 /

                  <br />
                </span>

                <span v-if="approvalFunds.length > 0">
                  매각 확정 : {{ approvalFunds.length | commaFormat }} 건 /
                </span>
                매각 완료 : {{ doneFunds.length | commaFormat }} 건
              </p>
            </v-col>
            <v-col class="table-col" cols="12">
              <div class="data-table-wrapper">
                <table class="width-100">
                  <thead>
                    <tr>
                      <th width="5%" class="sticky-v-col">번호</th>
                      <th style="min-width: 80px">호수</th>
                      <th width="17%" style="min-width: 120px">상품명</th>
                      <th width="17%" style="min-width: 120px">상점명</th>
                      <th style="min-width: 100px">투자액</th>
                      <th style="min-width: 100px">대출자<br />상환액</th>
                      <th style="min-width: 100px">매각 후<br />회수액</th>
                      <th style="min-width: 100px">총<br />상환액</th>
                      <th style="min-width: 120px">상환일</th>
                      <th style="min-width: 80px">공지</th>
                    </tr>
                  </thead>
                  <template v-if="disposals.length > 0">
                    <tbody>
                      <tr
                        v-for="(dis, index) in disposals"
                        :key="`${dis.fNum}_${dis.storeName}`"
                      >
                        <td class="sticky-v-col">{{ index + 1 }}</td>
                        <td>{{ dis.fNum }} 호</td>
                        <td>{{ dis.fundingDataTitle }}</td>
                        <td>{{ dis.storeName }}</td>
                        <td>{{ dis.fInvestmentAmount | commaFormat }} 원</td>
                        <td>
                          <template v-if="dis.status === 'WAITING'">
                            {{ dis.rpyAmount | commaFormat }} 원
                          </template>
                          <template v-else>
                            <LoadingText :isLoading="repaymentLoading">
                              {{
                                (dis.rpyAmount - getRepaymentPrincipal(dis))
                                  | commaFormat
                              }}
                            </LoadingText>
                            원
                          </template>
                        </td>
                        <td>
                          <template v-if="dis.status === 'DONE'">
                            <LoadingText :isLoading="repaymentLoading">
                              {{ getRepaymentPrincipal(dis) | commaFormat }}
                            </LoadingText>
                            원
                          </template>
                          <template v-else> - </template>
                        </td>
                        <td class="total-amt">
                          {{ dis.rpyAmount | commaFormat }} 원
                        </td>
                        <td>
                          {{ dis.lastRepaymentDate }}
                        </td>
                        <td :class="getStatusColorClass(dis.status)">
                          <span
                            class="cursor--pointer text-decoration--underline"
                            @click="openOverdueStatus(dis.fnNum)"
                          >
                            {{ getStatusTxt(dis.status) }}
                            <i class="icon-doc-text-2"></i>
                          </span>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td class="sticky-v-col">합계</td>
                        <td colspan="3"></td>
                        <td>{{ totalInvestmentAmount | commaFormat }} 원</td>
                        <td colspan="2"></td>
                        <td>{{ totalRepaymentAmount | commaFormat }} 원</td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tfoot>
                  </template>
                  <template v-else>
                    <tbody>
                      <tr>
                        <td colspan="10">
                          투자중인 상품 중 채권 매각 대상이 없습니다.
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </table>
              </div>
            </v-col>
          </template>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import MyLoader from '@/components/funda/MyLoader.vue'
import LoadingText from '@/components/common/text/LoadingText.vue'
import VoteForWriteOffPopup from '@/components/funda/popups/VoteForWriteOffPopup.vue'

export default {
  props: {
    userIdx: {
      type: Number,
      default: null,
      required: true,
    },
  },
  components: {
    MyLoader,
    LoadingText,
    VoteForWriteOffPopup,
  },
  data() {
    return {
      isLoading: false,
      repaymentLoading: false,
      disposals: [],
      repayments: {},
      voteAmt: {
        rejectedInvestmentAmount: 0,
        rejectedRpyAmount: 0,
        rejectedBalance: 0,
      },
      voteStores: null,
    }
  },
  computed: {
    totalInvestmentAmount() {
      let total = 0
      this.disposals.forEach((dis) => {
        total += dis.fInvestmentAmount
      })

      return total
    },
    totalRepaymentAmount() {
      let total = 0
      this.disposals.forEach((dis) => {
        total += dis.rpyAmount
      })

      return total
    },
    doneFunds() {
      const result = this.disposals.filter((v) => {
        return v.status === 'DONE'
      })

      return result
    },
    voteFunds() {
      const result = this.disposals.filter((v) => {
        return v.status !== 'DONE'
      })

      return result || []
    },
    waitingFunds() {
      const result = this.voteFunds.filter((v) => {
        return v.status === 'WAITING'
      })

      return result
    },
    approvalFunds() {
      const result = this.voteFunds.filter((v) => {
        return v.status === 'APPROVAL'
      })

      return result
    },
    rejectedFunds() {
      const result = this.voteFunds.filter((v) => {
        return v.status === 'REJECTION'
      })

      return result
    },
    ratioMap() {
      const ratioMap = this.voteStores?.reduce((result, vote) => {
        const temp = result[vote.sNum] || { funding: {} }

        vote.values.forEach((v) => {
          temp.funding[v.fNum] = v.valueRatio
        })

        result[vote.sNum] = temp

        return result
      }, {})

      return ratioMap
    },
  },
  watch: {
    voteFunds: {
      deep: true,
      handler(v) {
        let inv = 0
        let rpy = 0
        v.forEach((w) => {
          inv += w.fInvestmentAmount
          rpy += w.rpyAmount
        })

        this.voteAmt.fInvestmentAmount = inv
        this.voteAmt.rpyAmount = rpy
        this.voteAmt.balance = inv - rpy
      },
    },
    rejectedFunds: {
      deep: true,
      handler(v) {
        let inv = 0
        let rpy = 0
        v.forEach((w) => {
          inv += w.fInvestmentAmount
          rpy += w.rpyAmount
        })

        this.voteAmt.rejectedInvestmentAmount = inv
        this.voteAmt.rejectedRpyAmount = rpy
        this.voteAmt.rejectedBalance = inv - rpy
      },
    },
    ratioMap(v) {
      if (!!v) {
        const expected = this.voteFunds.reduce((amt, f) => {
          if (f.status === 'REJECTION') {
            return amt
          }
          const ratio = v[f.sNum]?.funding[f.fNum] ?? 0
          amt += parseInt(f.balance * ratio, 10)
          return amt
        }, 0)

        this.voteAmt.expected = expected
      }
    },
  },
  created() {
    this.getDisposals()
    this.getRepayments()
  },
  methods: {
    getStatusTxt(status) {
      let result = '매각 완료'
      if (status === 'WAITING') {
        result = '매각 투표'
      } else if (status === 'REJECTION') {
        result = '매각 제외'
      } else if (status === 'APPROVAL') {
        result = '매각 확정'
      }
      return result
    },
    getStatusColorClass(status) {
      let result = 'done'
      if (status === 'WAITING' || status === 'APPROVAL') {
        result = 'color--funda-basic'
      }
      return result
    },
    openOverdueStatus(fnIdx) {
      let r = this.$router.resolve({
        name: 'fnOverdueManagement',
        query: { fnIdx: fnIdx },
      })

      window.open(r.href, '_blank')
    },
    async getDisposals() {
      const q = `
        data: getWriteOffListByUserIdx(userIdx: ${this.userIdx}) {
          fNum
          fundingDataTitle
          sNum
          storeName
          status
          repaymentStatus
          loanAmount
          interestRate
          fInvestmentAmount
          rpyAmount
          investmentDatetime
          useDays
          dueDate
          lastRepaymentDate
          
          balance
        }
      `

      this.isLoading = true

      try {
        let data = await this.$fundaApi.query(gql`{${q}}`)
        this.disposals = data
      } finally {
        this.isLoading = false
      }
    },
    async getRepayments() {
      const q = `
        data: getWriteOffRepayments(userIdx: ${this.userIdx})
      `

      this.repaymentLoading = true

      try {
        let data = await this.$fundaApi.query(gql`{${q}}`)

        data = JSON.parse(data)

        data.forEach((r) => {
          const { principal, fNum, sNum } = r
          const key = `${fNum}_${sNum}`
          this.repayments[key] = principal
        })
      } finally {
        this.repaymentLoading = false
      }
    },
    getRepaymentPrincipal(obj) {
      const key = `${obj?.fNum}_${obj?.sNum}`
      const result = this.repayments[key]
      return !!result ? result : 0
    },
  },
}
</script>

<style lang="scss" scoped>
.btn__same-store-auto {
  max-width: 110px;
  width: 110px;
  box-shadow: unset;
  padding: 25px 0px !important;
  font-size: 15px;
  font-weight: bold;

  &.agree {
    color: white;
    background-color: #1baaf1 !important;
  }
}

.contents {
  &__wrapper {
    max-width: 1185px;
    margin-left: auto;
    margin-right: auto;
  }

  &__main {
    background: #fff;
    border: 1px solid #e7edf6;
    border-radius: 3px;
    position: relative;
    padding: 30px 25px 20px 25px;
    margin-bottom: 30px;

    .table-col {
      position: relative;

      @media screen and (max-width: 425px) {
        &::before {
          content: '';
          position: absolute;
          top: 24px;
          width: 15px;
          height: 15px;
          right: 10px;
          background: url('~@/assets/images/myfunda/btn-btn-arrow-b.svg')
            no-repeat center;
          z-index: 5;
        }
      }
    }

    .data-table-wrapper {
      position: relative;
      overflow: auto;

      table {
        border-collapse: collapse;
        border-top: 1px solid #333333;
        border-bottom: 1px solid #8a94a2;

        .sticky-v-col {
          &.rpy {
            min-width: 75px;
          }
        }

        .total-amt {
          background-color: rgb(238, 238, 238, 0.3);
        }

        thead {
          background-color: #f9fcff;

          &.rpy {
            background-color: white;
            border-bottom: 1px solid #e7edf6;

            th {
              padding-top: 15px !important;
              padding-bottom: 15px !important;
            }
          }

          th {
            margin: 0;
            padding-top: 20px;
            padding-bottom: 20px;
            text-align: center;

            &:not(.rpy).sticky-v-col {
              background-color: #f9fcff !important;
            }
          }
        }

        tbody {
          tr {
            &.active {
              background-color: #f5f5f5;

              .sticky-v-col {
                &.rpy {
                  background-color: #f5f5f5;
                }
              }
            }
          }

          td {
            padding-top: 12px;
            padding-bottom: 12px;
            color: #565a5c;
            text-align: center;
          }
        }

        tfoot {
          border-top: 1px solid #e7edf6;

          td {
            padding-top: 12px;
            padding-bottom: 12px;
            color: #565a5c;
            font-weight: 700;
            text-align: center;

            &.done {
              color: #727d8c;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}
</style>
