<template>
  <ValidationProvider
    v-slot="{ touched, errors }"
    name="alimtalk_profile"
    :rules="rules"
  >
    <v-select
      :items="profiles"
      item-text="name"
      item-value="idx"
      :value="value"
      label="프로필 선택"
      outlined
      :disabled="isLoading || disabled"
      :loading="isLoading"
      :hide-details="touched == false || errors.length == 0"
      :error-messages="touched == false ? [] : errors"
      @change="select"
    />
  </ValidationProvider>
</template>
<script>
export default {
  props: {
    value: {
      type: Number,
      default: null,
    },
    rules: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      profiles: [],
      isLoading: false,
    }
  },
  async created() {
    this.isLoading = true
    const { data } = await this.$alimtalkApi.getProfiles()
    this.profiles = data
    this.isLoading = false
  },
  methods: {
    select(idx) {
      const profile = this.profiles.find((profile) => profile.idx === idx)
      this.$emit('change', profile)
    },
  },
}
</script>
