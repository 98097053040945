export default [
  {
    name: 'alimtalk/message/groups/form',
    path: '/alimtalk/message/groups/form',
    meta: { title: '알림톡 발송' },
    component: require('@/pages/alimtalks/AlimtalkForm.vue').default,
  },
  {
    name: 'alimtalk/message/groups',
    path: '/alimtalk/message/groups',
    props: (route) => ({ query: route.query }),
    meta: { title: '알림톡 발송 내역' },
    component: require('@/pages/alimtalks/MessageGroups.vue').default,
  },
  {
    name: 'alimtalk/message/groups/detail',
    path: '/alimtalk/message/groups/:messageGroupIdx',
    props: (route) => ({
      messageGroupIdx: Number(route.params.messageGroupIdx),
    }),
    meta: { title: '알림톡 발송 내역 상세' },
    component: require('@/pages/alimtalks/MessageGroupsDetail.vue').default,
  },
  {
    name: 'alimtalk/templates/form',
    path: '/alimtalk/templates/form',
    meta: { title: '알림톡 템플릿 등록' },
    component: require('@/pages/alimtalks/templates/TemplateForm.vue').default,
  },
  {
    name: 'alimtalk/templates/detail',
    path: '/alimtalk/templates/:templateIdx',
    props: (route) => ({ templateIdx: Number(route.params.templateIdx) }),
    meta: { title: '알림톡 템플릿 상세' },
    component: require('@/pages/alimtalks/templates/TemplateDetail.vue')
      .default,
  },
  {
    name: 'alimtalk/templates/detail/form',
    path: '/alimtalk/templates/:templateIdx/form',
    props: (route) => ({ templateIdx: Number(route.params.templateIdx) }),
    meta: { title: '알림톡 템플릿 수정' },
    component: require('@/pages/alimtalks/templates/TemplateUpdateForm.vue')
      .default,
  },
  {
    name: 'alimtalk/templates',
    path: '/alimtalk/templates',
    props: (route) => ({ query: route.query }),
    meta: { title: '알림톡 템플릿 목록' },
    component: require('@/pages/alimtalks/templates/Templates.vue').default,
  },
  {
    name: 'alimtalk/templates/detail/send/form',
    path: '/alimtalk/templates/:idx/send/form',
    meta: { title: '알림톡 발송' },
    component: require('@/pages/alimtalks/templates/TemplateSendForm.vue')
      .default,
  },
]
