<template>
  <span>
    <v-switch
      class="mt-0"
      v-model="switchValue"
      @click="clickSwitch"
      :disabled="isLoading"
      hide-details
      v-show="!isLoading"
    >
    </v-switch>
    <p
      v-show="!isLoading"
      class="font-size--12 mb-0"
    >
      최근 수정 시간: {{ modified | moment('yyyy-MM-DD HH:mm:ss') }}
    </p>
    <v-progress-circular
      v-show="isLoading"
      :indeterminate="isLoading"
      :value="0"
      size="24"
      class="ml-2"
    ></v-progress-circular>
  </span>
</template>
<script>
export default {
  props: {
    fundaPaygateRepaymentvirtualaccountId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      switchValue: true,
      isLoading: false,
      isAutoRepayment: null,
      modified: null
    }
  },
  async created() {
    const { data } = await this.$fundaNowApi.getVirtualAccountAutoStatus(this.fundaPaygateRepaymentvirtualaccountId)
    if (data) {
      this.setData(data)
    }
  },
  methods: {
    setData(autoStatusData) {
      this.isAutoRepayment = autoStatusData.isAutoRepayment
      this.modified = autoStatusData.created
      this.syncSwitchValue()
    },
    syncSwitchValue() {
      // props 값으로 변경
      this.switchValue = this.isAutoRepayment
    },
    async clickSwitch() {
      if (this.isLoading) {
        return
      }
      try {
        this.isLoading = true

        let btnResult = await this.$swal.basic.confirm({
          title: '자동 상환 여부 변경',
          text: `${this.switchValue ? '자동 상환 활성화' : '자동 상환 중지'} 상태로 변경하시겠습니까?`,
        })

        if (btnResult.isConfirmed !== true) {
          return
        }

        const { data } = await this.$fundaNowApi.postVirtualAccountAutoStatus(this.fundaPaygateRepaymentvirtualaccountId, { isAutoRepayment: this.switchValue })
        this.setData(data)
      } finally {
        // 스위치 클릭이벤트 발생하면 무조건 값 바뀜;
        // finally에서 무조건 props 값으로 변경
        this.syncSwitchValue()
        this.isLoading = false
      }
    }
  }
}
</script>
