<template>
  <v-container fluid class="pt-0">
    <PageHeader
      title="가상계좌 거래내역 조회"
      subtitle="가상계좌 거래내역 조회"
      :navigation="navigation"
    >
      <template v-slot:right>
        <v-col cols="4" lg="4" mg="4" sm="4" align-self="center" class="pr-0">
          <div class="text-right font-weight-bold">거래내역 바로가기</div>
        </v-col>
        <v-col cols="4" lg="4" mg="4" sm="4">
          <PaymentUserVirtualAccountAutocomplete
            :dense="true"
            @select="shortcut"
          />
        </v-col>
        <v-col cols="4" lg="4" mg="4" sm="4">
          <SystemUserSelect
            label="시스템 유저 거래내역 바로가기"
            :dense="true"
            @change="shortcut"
          />
        </v-col>
      </template>
    </PageHeader>
    <PaymentVirtualAccountTransactions
      :userIdx="userIdx"
      :userVirtualAccountIdx="userVirtualAccountIdx"
      :isQueryPaging="true"
    />
  </v-container>
</template>
<script>
import PaymentUserVirtualAccountAutocomplete from '../../../../components/payment/user/VirtualAccountAutocomplete.vue'
import SystemUserSelect from '../../../../components/payment/common/select/SystemUserSelect.vue'
import PaymentVirtualAccountTransactions from '../../../../components/payment/PaymentVirtualAccountTransactions.vue'
export default {
  components: {
    PaymentVirtualAccountTransactions,
    SystemUserSelect,
    PaymentUserVirtualAccountAutocomplete,
  },
  props: {
    userIdx: {
      type: Number,
      required: true,
    },
    userVirtualAccountIdx: {
      type: Number,
      required: true,
    },
    query: {
      type: Object,
      default: (_) => {
        return null
      },
    },
  },
  data() {
    return {
      navigation: [
        {
          text: '페이먼트',
        },
        {
          text: '사용자 목록 조회',
          to: { name: 'payment/users' },
          exact: true,
        },
        {
          text: `사용자 상세 조회(${this.userIdx})`,
          to: {
            name: 'payment/users/detail',
            params: { userIdx: this.userIdx },
          },
          exact: true,
        },
        {
          text: `가상계좌(${this.userVirtualAccountIdx})`,
        },
        {
          text: '거래내역 조회',
        },
      ],
    }
  },
  methods: {
    shortcut(e) {
      if (e == null) {
        return
      }
      if (this.userVirtualAccountIdx !== e.idx) {
        this.$router.push({
          params: {
            userIdx: e.userIdx,
            userVirtualAccountIdx: e.idx,
          },
        })
      }
    },
  },
}
</script>
