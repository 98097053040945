<template>
  <div>
    <FileUploadButton
      :isLoading="isLoading"
      :disabled="disabled"
      singleFile
      :files.sync="file"
      @update:files="updateFile"
      :label="label"
      :block="block"
    />
  </div>
</template>

<script>
import XLSX from 'xlsx'
import FileUploadButton from '@/components/common/form/FileUploadButton.vue'

export default {
  props: {
    block: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '엑셀 업로드',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    FileUploadButton,
  },
  data() {
    return {
      file: null,
      isLoading: false,
    }
  },
  methods: {
    async updateFile(file) {
      try {
        if (/\.xlsx$/.test(file.name) === false) {
          await this.$swal.basic.error({
            title: '파일 형식 오류',
            text: 'xlsx형식의 파일만 업로드 가능합니다.',
          })
          return
        }

        const data = await this.readFile(file)
        this.$emit('update:readExcel', data)
      } catch (e) {
        await this.$swal.basic.error({
          title: '업로드 실패',
          text: e.message,
        })
      }
    },
    readFile(file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader()

        reader.onload = async () => {
          let data = reader.result
          let workbook = XLSX.read(data, {
            type: 'binary',
            cellText: false,
            cellDates: true,
            cellNF: true,
            dateNF: 'yyyy-mm-dd',
          })
          const sheet = workbook.Sheets[workbook.SheetNames[0]]
          const header = XLSX.utils.sheet_to_json(sheet, { header: 1 })[0]
          const rows = XLSX.utils.sheet_to_json(sheet, {
            raw: false,
            dateNF: 'yyyy-mm-dd',
          })

          const resp = {
            header,
            data: rows,
          }

          return resolve(resp)
        }

        reader.readAsBinaryString(file)
      })
    },
  },
}
</script>
