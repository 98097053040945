<template>
  <v-container fluid class="pt-0">
    <PageHeader title="은행 점검 상세 조회" subtitle="은행 점검 상세 조회">
    </PageHeader>
    <v-row>
      <v-col lg="4" md="6" sm="6" cols="12">
        <PageSection
          title="점검 내용"
          :isLoading="!bankInspection || isLoading"
        >
          <template slot="content" v-if="bankInspection">
            <v-checkbox
              v-model="isEditMode"
              :label="`${isEditMode ? '수정취소' : '수정하기'}`"
              dense
              hide-details
              class="mt-0"
            ></v-checkbox>
            <!-- orgdata: {{ JSON.stringify(bankInspection) }} -->
            <BankInspectionForm
              :orgData="bankInspection"
              :disabled="!isEditMode"
              @submit="updateBankInspection"
            />
            <v-btn
              type="button"
              block
              @click="changeActive"
              :color="bankInspection.isActive ? 'warning' : 'primary'"
            >
              <span v-if="bankInspection.isActive">비활성화 하기</span>
              <span v-else>활성화 하기</span>
            </v-btn>
          </template>
        </PageSection>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { simpleClone } from '@/util'
import BankInspectionForm from '@/components/bank/inspection/BankInspectionForm.vue'
export default {
  components: { BankInspectionForm },
  data() {
    return {
      bankInspection: null,
      isEditMode: false,
      isLoading: false,
    }
  },
  props: {
    idx: {
      type: Number,
      required: true,
    },
  },
  async created() {
    // 조회
    const { data: bankInspection } = await this.$paymentApi.getBankInspection(
      this.idx
    )
    this.bankInspection = bankInspection
  },
  methods: {
    /**
     * 점검 활성화 상태 변경
     */
    async changeActive() {
      let changeActiveForm = simpleClone(this.bankInspection)
      changeActiveForm.isActive = !changeActiveForm.isActive
      await this.updateBankInspection(changeActiveForm)
    },
    /**
     * 점검 내용 수정
     */
    async updateBankInspection(formData) {
      if (this.isLoading === true) {
        return
      }
      try {
        this.isLoading = true

        let btnResult = await this.$swal.basic.confirm({
          title: '은행 점검 내용 수정',
          text: '수정하시겠습니까?',
          cancelButtonColor: 'red',
        })
        if (btnResult.isConfirmed !== true) {
          return
        }

        // 수정 후 상세페이지 리로드용
        const {
          data: { idx },
        } = await this.$paymentApi.putBankInspection(this.idx, formData)

        this.$router.push({
          name: 'bank/inspections/detail',
          params: { idx },
        })
      } catch (e) {
        this.$swal.basic.alert({
          text: '수정에 실패했습니다.',
        })
        throw e
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
