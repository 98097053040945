<template>
  <div class="px-3" style="padding-bottom: 100px">
    <PageHeader title="고객 목록" />
    <v-row class="my-3" no-gutters>
      <v-col cols="12">
        <UserList :user-idx="userIdx" :get-first="true" />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import UserList from '@/components/funda/user/List'

export default {
  components: {
    UserList,
  },
  data() {
    return {
      userIdx: null,
    }
  },
  created() {
    this.userIdx = this.$route.query.userIdx
  },
}
</script>
