<template>
   <v-container fluid class="pt-0">
    <PageHeader
      title="수기 상환"
      subtitle="* 저장 전 상환 기준일 및 금액 검사 필수"
      color="error"
    >
    </PageHeader>
    <div v-if="loansRepaymentPreData">
      <v-card class="mt-3 pa-4">
        <p>가상계좌 :
          <TransactionsRouterLink
            :userVirtualAccountIdx="loansRepaymentPreData.fundaPaygateRepaymentvirtualaccountId"
          />
        </p>
        <p>입금액 : {{ loansRepaymentPreData.incomeAmount | commaFormat  }}</p>
        <p :class="{ 'error--text': !isRepayable }">상환 금액: {{ totalRepaymentAmount | commaFormat  }}</p>
        <p>반환 금액: {{ loansRepaymentPreData.incomeAmount - totalRepaymentAmount | commaFormat  }}</p>
        <p v-if="!isRepayable" class="error--text">상환액이 입금액보다 큽니다.</p>
        상환일:&nbsp;
        <template v-if="baseDate">
          {{ baseDate }}
        </template>
        <template v-else>
          <CommonDatePicker
            v-model="baseDate"
            label="상환 기준일 선택(선택한 날짜가 상환일로 저장됩니다)"
            @change="baseDate = $event"
          />
        </template>
      </v-card>
      <TargetLoansTable
        v-if="baseDate != null"
        :loansRepaymentPreDataId="loansRepaymentPreDataId"
        :incomeAmount="loansRepaymentPreData.incomeAmount"
        :baseDate.sync="baseDate"
        @select="plans = $event"
      />
      <RepaymentTransactionsFormTable
        v-if="plans != null"
        :plans="plans"
        :totalRepaymentAmount.sync="totalRepaymentAmount"
        :totalUnpaidRepayment.sync="totalUnpaidRepayment"
        :totalTargetFee.sync="totalTargetFee"
        :totalPaidFee.sync="totalPaidFee"
        :transactions.sync="transactions"
      />
      <v-btn
        v-if="plans && totalRepaymentAmount > 0"
        @click="repay"
        :disabled="!isRepayable || isRepayProgress"
        color="primary"
        large
      >
        상환하기
      </v-btn>
      <p v-if="!isRepayable" class="error--text">상환 실행 불가: 상환액이 입금액보다 큽니다.</p>
    </div>
  </v-container>
</template>
<script>
import TransactionsRouterLink from '@/components/payment/user/virtualAccount/TransactionsRouterLink.vue'
import TargetLoansTable from '@/components/fundanow/repayment/manual/TargetLoansTable.vue'
import RepaymentTransactionsFormTable from '@/components/fundanow/repayment/manual/RepaymentTransactionsFormTable.vue'
import CommonDatePicker from '@/components/common/form/CommonDatePicker.vue'
export default {
  components: { TransactionsRouterLink, TargetLoansTable, RepaymentTransactionsFormTable, CommonDatePicker },
  props: {
    loansRepaymentPreDataId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      loansRepaymentPreData: null,
      plans: null,
      totalRepaymentAmount: 0,
      totalUnpaidRepayment: 0,
      totalTargetFee: 0,
      totalPaidFee: 0,
      baseDate: null,
      transactions: null,
      isRepayProgress: false
    }
  },
  computed: {
    isRepayable() {
      return this.loansRepaymentPreData && this.loansRepaymentPreData.incomeAmount >= this.totalRepaymentAmount
    }
  },
  async created() {
    let { data } = await this.$fundaNowApi.getLoansRepaymentPreData(this.loansRepaymentPreDataId)
    if (data.manualStatus !== 'INIT') {
      await this.$swal.basic.alert({
        title: '상환 불가',
        text: data.manualStatus === 'SUCCESS' ? '이미 상환 완료된 건 입니다.' : '상환 가능한 상태가 아닙니다.',
      })
      this.$router.replace({ name: 'fundanow/repayment/manual/targets' })
    }
    this.loansRepaymentPreData = data
  },
  methods: {
    async repay() {
      if (this.isRepayProgress) {
        return
      }
      try {
        this.isRepayProgress = true

        if (!this.isRepayable) {
          this.$swal.basic.alert({
            title: '상환 불가',
            text: '상환액이 입금액보다 큽니다.',
          })
          return
        }

        if (this.transactions.every(transaction => transaction.totalRepayment > 0) === false) {
          this.$swal.basic.alert({
            title: '상환 불가',
            text: '상환하는 금액이 없는 상환건이 있습니다.',
          })
          return
        }

        if (this.transactions.every(transaction => transaction.remainPrincipal >= 0) === false) {
          this.$swal.basic.alert({
            title: '상환 불가',
            text: '상환 후 잔여원금이 음수인 상환건이 있습니다.',
          })
          return
        }

        let msg = `입금액: ${Intl.NumberFormat().format(this.loansRepaymentPreData.incomeAmount)}원<br />`
        msg += `총상환액: ${Intl.NumberFormat().format(this.totalRepaymentAmount)}원<br />`
        msg += `반환액: ${Intl.NumberFormat().format(this.loansRepaymentPreData.incomeAmount - this.totalRepaymentAmount)}원<br />`
        msg += `미수금액: ${Intl.NumberFormat().format(this.totalUnpaidRepayment)}원<br /><br /><br />`
        msg += `수취 수수료: ${Intl.NumberFormat().format(this.totalPaidFee)}원<br />`
        msg += `미수취 수수료: ${Intl.NumberFormat().format(this.totalTargetFee - this.totalPaidFee)}원<br />`
        if ((this.loansRepaymentPreData.incomeAmount - this.totalRepaymentAmount) > 0 && (this.totalTargetFee - this.totalPaidFee) > 0) {
          msg += '<b>반환금액을 줄이고 수수료상환을 해주세요!!</b><br />'
        }
        msg += '<br />위 금액대로 상환하시겠습니까?<br /><br /><br /><br />'

        let btnResult = await this.$swal.basic.confirm({
          title: '상환하기',
          html: msg,
          cancelButtonColor: 'red',
          focusConfirm: true,
        })

        if (btnResult.isConfirmed !== true) {
          return
        }

        this.transactions.forEach(transaction => {
          transaction.repaymentDate = this.baseDate
        })
        let { data } = await this.$fundaNowApi.postLoansRepaymentPreDataManualRepayment(this.loansRepaymentPreDataId, {
          refundAmount: this.loansRepaymentPreData.incomeAmount - this.totalRepaymentAmount,
          unpaidAmount: this.totalUnpaidRepayment,
          transactions: this.transactions
        })
        await this.$swal.basic.alert({
          title: '상환 완료',
          text: '상환이 완료되었습니다.',
        })
        console.log(data)
        this.$router.replace({ name: 'fundanow/repayment/manual/targets' })
      } finally {
        this.isRepayProgress = false
      }
    }
  }
}
</script>
