<template>
  <v-select
    :items="items"
    :label="label"
    outlined
    dense
    hide-details
    @change="$emit('update:selected', $event)"
    v-model="selectedValue"
    class="mr-3"
  ></v-select>
</template>
<script>
export default {
  props: {
    selected: {
      type: Boolean,
      default: null,
    },
    label: {
      type: String,
      required: true,
    },
    trueLabel: {
      type: String,
      default: 'true',
    },
    falseLabel: {
      type: String,
      default: 'true',
    },
    nullLabel: {
      type: String,
      default: '전체',
    },
    isNullable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      selectedValue: this.selected,
      items: [
        {
          text: this.nullLabel,
          value: null,
          disabled: this.isNullable === false,
        },
        { text: this.falseLabel, value: false },
        { text: this.trueLabel, value: true },
      ],
    }
  },
}
</script>
