export default {
  transactions: {
    paging: { page: 9, size: 30, totalCount: 248, totalPage: 9 },
    data: [
      {
        idx: 6978734,
        userIdx: null,
        userVirtualAccountIdx: 25531,
        type: 'INVEST',
        inOut: 'OUT',
        amount: 50000,
        balance: 357525,
        remark: '11월 03일 펀다나우 투자_18167',
        createTime: '2020-11-03T18:24:18',
      },
      {
        idx: 6977190,
        userIdx: null,
        userVirtualAccountIdx: 25531,
        type: 'INVEST',
        inOut: 'OUT',
        amount: 50000,
        balance: 407525,
        remark: '11월 03일 펀다나우 투자_18159',
        createTime: '2020-11-03T18:17:17',
      },
      {
        idx: 6976073,
        userIdx: null,
        userVirtualAccountIdx: 25531,
        type: 'INVEST',
        inOut: 'OUT',
        amount: 50000,
        balance: 457525,
        remark: '11월 03일 펀다나우 투자_18157',
        createTime: '2020-11-03T18:15:22',
      },
      {
        idx: 6934621,
        userIdx: null,
        userVirtualAccountIdx: 25531,
        type: 'DEPOSIT',
        inOut: 'IN',
        amount: 507525,
        balance: 507525,
        remark: '이현우',
        createTime: '2020-11-03T13:06:01',
      },
      {
        idx: 6336587,
        userIdx: null,
        userVirtualAccountIdx: 25531,
        type: 'WITHDRAW',
        inOut: 'OUT',
        amount: 1117854,
        balance: 0,
        remark: '출금(홈페이지에서 출금 신청)',
        createTime: '2020-10-26T11:28:36',
      },
      {
        idx: 6283817,
        userIdx: null,
        userVirtualAccountIdx: 25531,
        type: 'PAYOUT',
        inOut: 'IN',
        amount: 1117854,
        balance: 1117854,
        remark: '부자언니 펀드 1-2호_상환_4867_1회',
        createTime: '2020-10-23T14:21:21',
      },
      {
        idx: 2946435,
        userIdx: null,
        userVirtualAccountIdx: 25531,
        type: 'WITHDRAW',
        inOut: 'OUT',
        amount: 4936,
        balance: 0,
        remark: '출금(홈페이지에서 출금 신청)',
        createTime: '2020-09-02T16:57:18',
      },
      {
        idx: 2316744,
        userIdx: null,
        userVirtualAccountIdx: 25531,
        type: 'PAYOUT',
        inOut: 'IN',
        amount: 4936,
        balance: 4936,
        remark: '부자언니 펀드 1-6호_상환_4871_1회',
        createTime: '2020-08-25T19:20:21',
      },
      {
        idx: 6978734,
        userIdx: null,
        userVirtualAccountIdx: 25531,
        type: 'INVEST',
        inOut: 'OUT',
        amount: 50000,
        balance: 357525,
        remark: '11월 03일 펀다나우 투자_18167',
        createTime: '2020-11-03T18:24:18',
      },
      {
        idx: 6977190,
        userIdx: null,
        userVirtualAccountIdx: 25531,
        type: 'INVEST',
        inOut: 'OUT',
        amount: 50000,
        balance: 407525,
        remark: '11월 03일 펀다나우 투자_18159',
        createTime: '2020-11-03T18:17:17',
      },
      {
        idx: 6976073,
        userIdx: null,
        userVirtualAccountIdx: 25531,
        type: 'INVEST',
        inOut: 'OUT',
        amount: 50000,
        balance: 457525,
        remark: '11월 03일 펀다나우 투자_18157',
        createTime: '2020-11-03T18:15:22',
      },
      {
        idx: 6934621,
        userIdx: null,
        userVirtualAccountIdx: 25531,
        type: 'DEPOSIT',
        inOut: 'IN',
        amount: 507525,
        balance: 507525,
        remark: '이현우',
        createTime: '2020-11-03T13:06:01',
      },
      {
        idx: 6336587,
        userIdx: null,
        userVirtualAccountIdx: 25531,
        type: 'WITHDRAW',
        inOut: 'OUT',
        amount: 1117854,
        balance: 0,
        remark: '출금(홈페이지에서 출금 신청)',
        createTime: '2020-10-26T11:28:36',
      },
      {
        idx: 6283817,
        userIdx: null,
        userVirtualAccountIdx: 25531,
        type: 'PAYOUT',
        inOut: 'IN',
        amount: 1117854,
        balance: 1117854,
        remark: '부자언니 펀드 1-2호_상환_4867_1회',
        createTime: '2020-10-23T14:21:21',
      },
      {
        idx: 2946435,
        userIdx: null,
        userVirtualAccountIdx: 25531,
        type: 'WITHDRAW',
        inOut: 'OUT',
        amount: 4936,
        balance: 0,
        remark: '출금(홈페이지에서 출금 신청)',
        createTime: '2020-09-02T16:57:18',
      },
      {
        idx: 2316744,
        userIdx: null,
        userVirtualAccountIdx: 25531,
        type: 'PAYOUT',
        inOut: 'IN',
        amount: 4936,
        balance: 4936,
        remark: '부자언니 펀드 1-6호_상환_4871_1회',
        createTime: '2020-08-25T19:20:21',
      },
      {
        idx: 6978734,
        userIdx: null,
        userVirtualAccountIdx: 25531,
        type: 'INVEST',
        inOut: 'OUT',
        amount: 50000,
        balance: 357525,
        remark: '11월 03일 펀다나우 투자_18167',
        createTime: '2020-11-03T18:24:18',
      },
      {
        idx: 6977190,
        userIdx: null,
        userVirtualAccountIdx: 25531,
        type: 'INVEST',
        inOut: 'OUT',
        amount: 50000,
        balance: 407525,
        remark: '11월 03일 펀다나우 투자_18159',
        createTime: '2020-11-03T18:17:17',
      },
      {
        idx: 6976073,
        userIdx: null,
        userVirtualAccountIdx: 25531,
        type: 'INVEST',
        inOut: 'OUT',
        amount: 50000,
        balance: 457525,
        remark: '11월 03일 펀다나우 투자_18157',
        createTime: '2020-11-03T18:15:22',
      },
      {
        idx: 6934621,
        userIdx: null,
        userVirtualAccountIdx: 25531,
        type: 'DEPOSIT',
        inOut: 'IN',
        amount: 507525,
        balance: 507525,
        remark: '이현우',
        createTime: '2020-11-03T13:06:01',
      },
      {
        idx: 6336587,
        userIdx: null,
        userVirtualAccountIdx: 25531,
        type: 'WITHDRAW',
        inOut: 'OUT',
        amount: 1117854,
        balance: 0,
        remark: '출금(홈페이지에서 출금 신청)',
        createTime: '2020-10-26T11:28:36',
      },
      {
        idx: 6283817,
        userIdx: null,
        userVirtualAccountIdx: 25531,
        type: 'PAYOUT',
        inOut: 'IN',
        amount: 1117854,
        balance: 1117854,
        remark: '부자언니 펀드 1-2호_상환_4867_1회',
        createTime: '2020-10-23T14:21:21',
      },
      {
        idx: 2946435,
        userIdx: null,
        userVirtualAccountIdx: 25531,
        type: 'WITHDRAW',
        inOut: 'OUT',
        amount: 4936,
        balance: 0,
        remark: '출금(홈페이지에서 출금 신청)',
        createTime: '2020-09-02T16:57:18',
      },
      {
        idx: 2316744,
        userIdx: null,
        userVirtualAccountIdx: 25531,
        type: 'PAYOUT',
        inOut: 'IN',
        amount: 4936,
        balance: 4936,
        remark: '부자언니 펀드 1-6호_상환_4871_1회',
        createTime: '2020-08-25T19:20:21',
      },
      {
        idx: 6978734,
        userIdx: null,
        userVirtualAccountIdx: 25531,
        type: 'INVEST',
        inOut: 'OUT',
        amount: 50000,
        balance: 357525,
        remark: '11월 03일 펀다나우 투자_18167',
        createTime: '2020-11-03T18:24:18',
      },
      {
        idx: 6977190,
        userIdx: null,
        userVirtualAccountIdx: 25531,
        type: 'INVEST',
        inOut: 'OUT',
        amount: 50000,
        balance: 407525,
        remark: '11월 03일 펀다나우 투자_18159',
        createTime: '2020-11-03T18:17:17',
      },
      {
        idx: 6976073,
        userIdx: null,
        userVirtualAccountIdx: 25531,
        type: 'INVEST',
        inOut: 'OUT',
        amount: 50000,
        balance: 457525,
        remark: '11월 03일 펀다나우 투자_18157',
        createTime: '2020-11-03T18:15:22',
      },
      {
        idx: 6934621,
        userIdx: null,
        userVirtualAccountIdx: 25531,
        type: 'DEPOSIT',
        inOut: 'IN',
        amount: 507525,
        balance: 507525,
        remark: '이현우',
        createTime: '2020-11-03T13:06:01',
      },
      {
        idx: 6336587,
        userIdx: null,
        userVirtualAccountIdx: 25531,
        type: 'WITHDRAW',
        inOut: 'OUT',
        amount: 1117854,
        balance: 0,
        remark: '출금(홈페이지에서 출금 신청)',
        createTime: '2020-10-26T11:28:36',
      },
      {
        idx: 6283817,
        userIdx: null,
        userVirtualAccountIdx: 25531,
        type: 'PAYOUT',
        inOut: 'IN',
        amount: 1117854,
        balance: 1117854,
        remark: '부자언니 펀드 1-2호_상환_4867_1회',
        createTime: '2020-10-23T14:21:21',
      },
      {
        idx: 2946435,
        userIdx: null,
        userVirtualAccountIdx: 25531,
        type: 'WITHDRAW',
        inOut: 'OUT',
        amount: 4936,
        balance: 0,
        remark: '출금(홈페이지에서 출금 신청)',
        createTime: '2020-09-02T16:57:18',
      },
      {
        idx: 2316744,
        userIdx: null,
        userVirtualAccountIdx: 25531,
        type: 'PAYOUT',
        inOut: 'IN',
        amount: 4936,
        balance: 4936,
        remark: '부자언니 펀드 1-6호_상환_4871_1회',
        createTime: '2020-08-25T19:20:21',
      },
      {
        idx: 6978734,
        userIdx: null,
        userVirtualAccountIdx: 25531,
        type: 'INVEST',
        inOut: 'OUT',
        amount: 50000,
        balance: 357525,
        remark: '11월 03일 펀다나우 투자_18167',
        createTime: '2020-11-03T18:24:18',
      },
      {
        idx: 6977190,
        userIdx: null,
        userVirtualAccountIdx: 25531,
        type: 'INVEST',
        inOut: 'OUT',
        amount: 50000,
        balance: 407525,
        remark: '11월 03일 펀다나우 투자_18159',
        createTime: '2020-11-03T18:17:17',
      },
      {
        idx: 6976073,
        userIdx: null,
        userVirtualAccountIdx: 25531,
        type: 'INVEST',
        inOut: 'OUT',
        amount: 50000,
        balance: 457525,
        remark: '11월 03일 펀다나우 투자_18157',
        createTime: '2020-11-03T18:15:22',
      },
      {
        idx: 6934621,
        userIdx: null,
        userVirtualAccountIdx: 25531,
        type: 'DEPOSIT',
        inOut: 'IN',
        amount: 507525,
        balance: 507525,
        remark: '이현우',
        createTime: '2020-11-03T13:06:01',
      },
      {
        idx: 6336587,
        userIdx: null,
        userVirtualAccountIdx: 25531,
        type: 'WITHDRAW',
        inOut: 'OUT',
        amount: 1117854,
        balance: 0,
        remark: '출금(홈페이지에서 출금 신청)',
        createTime: '2020-10-26T11:28:36',
      },
      {
        idx: 6283817,
        userIdx: null,
        userVirtualAccountIdx: 25531,
        type: 'PAYOUT',
        inOut: 'IN',
        amount: 1117854,
        balance: 1117854,
        remark: '부자언니 펀드 1-2호_상환_4867_1회',
        createTime: '2020-10-23T14:21:21',
      },
      {
        idx: 2946435,
        userIdx: null,
        userVirtualAccountIdx: 25531,
        type: 'WITHDRAW',
        inOut: 'OUT',
        amount: 4936,
        balance: 0,
        remark: '출금(홈페이지에서 출금 신청)',
        createTime: '2020-09-02T16:57:18',
      },
      {
        idx: 2316744,
        userIdx: null,
        userVirtualAccountIdx: 25531,
        type: 'PAYOUT',
        inOut: 'IN',
        amount: 4936,
        balance: 4936,
        remark: '부자언니 펀드 1-6호_상환_4871_1회',
        createTime: '2020-08-25T19:20:21',
      },
    ],
    headers: [
      { text: 'index', value: 'idx' },
      { text: '거래유형', value: 'type' },
      { text: '입금/출금', value: 'inOut', sortable: false },
      { text: '금액', value: 'amount', sortable: false },
      { text: '잔액', value: 'balance', sortable: false },
      { text: 'remark', value: 'remark', sortable: false },
      { text: '시간', value: 'createTime', sortable: false },
    ],
  },
}
