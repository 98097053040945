<template>
  <ValidationObserver ref="validForm" v-slot="{ handleSubmit, invalid }">
    <form @submit.prevent="handleSubmit(rollback)">
      <ValidationProvider
        v-slot="{ errors }"
        name="remark"
        rules="required|min:3"
      >
        <v-text-field
          label="remark"
          v-model="remark"
          outlined
          dense
          :error-messages="errors"
          :disabled="isRollbackable == false || isLoading"
        >
        </v-text-field>
      </ValidationProvider>
      <v-btn
        type="submit"
        :disabled="invalid || isRollbackable == false || isLoading"
        color="error"
        block
        large
        class="title"
      >
        {{ isRollbackable == false ? '실행 불가' : '원복 실행' }}
      </v-btn>
    </form>
  </ValidationObserver>
</template>
<script>
export default {
  props: {
    callbackIdx: {
      type: Number,
      required: true,
    },
    isRollbackable: {
      type: Boolean,
      default: true,
    },
    defaultRemark: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      remark: this.defaultRemark,
      isLoading: false,
    }
  },
  methods: {
    async rollback() {
      if (this.isRollbackable === false || this.isLoading === true) {
        return
      }

      let btnResult = await this.$swal.basic.confirm({
        title: '실패 금액 복구',
        text: '실패 금액을 복구 하시겠습니까?',
        cancelButtonColor: 'red',
      })

      if (btnResult.isConfirmed !== true) {
        return
      }

      this.isLoading = true
      try {
        await this.$paymentApi.postCallbackRollback(this.callbackIdx, {
          remark: this.remark,
        })
        this.$emit('success')
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
