<template>
  <router-link
    v-if="userVirtualAccount"
    :to="{
      name: 'payment/users/virtual/accounts/transactions',
      params: {
        userIdx: userVirtualAccount.userIdx,
        userVirtualAccountIdx: userVirtualAccount.idx
      },
    }"
  >
    {{ userVirtualAccount.accountName }}({{ userVirtualAccount.idx }})
  </router-link>
</template>
<script>
export default {
  props: {
    userVirtualAccountIdx: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      userVirtualAccount: null
    }
  },
  async created() {
    const { data } = await this.$paymentApi.getUserVirtualAccountByIdx(this.userVirtualAccountIdx)
    this.userVirtualAccount = data
  }
}
</script>
