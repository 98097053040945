import { extend } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'

Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule])
})

// extend('required', {
//   validate(value) {
//     return {
//       required: true,
//       valid: ['', null, undefined].indexOf(value) === -1
//     }
//   },
//   computesRequired: true
// })

// // test console
// extend('required', value => {
//   console.log('required')
//   return rules['required'].validate(value)
// })
// extend('max_value', (value, max) => {
//   console.log('max_value')
//   return rules['max_value'].validate(value, max)
// })
// // test console

extend('co_kor', (value) => {
  // 가-힣
  const regex = /^[\uAC00-\uD7A3]*$/
  return regex.test(value)
})

extend('num_dash', (value) => {
  const regex = /^[\d\\-]*$/
  return regex.test(value)
})

extend('birthdate', (value) => {
  const regex = /^(19|20)[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])$/
  return regex.test(value)
})

extend('birthdate_dash', (value) => {
  const regex = /^(19|20)[0-9]{2}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/
  return regex.test(value)
})

extend('phone', (value) => {
  const regex = /^01\d{8,9}$/
  return regex.test(value)
})
