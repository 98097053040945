<template>
  <PageSection title="상환 내역" :isLoading="isLoading">
    <template slot="content">
      <v-data-table
        :headers="headers"
        :items="repayments"
        :items-per-page="10"
        class="elevation-1"
      >
        <template v-slot:[`item.created`]="{ item }">
          {{ item.created | moment('YYYY-MM-DD HH:mm:ss') }}
        </template>
        <template v-slot:[`item.amount`]="{ item }">
          {{ calculateAmount(item).toLocaleString() }}
        </template>
        <template v-slot:[`item.principal`]="{ item }">
          {{ item.principal.toLocaleString() }}
        </template>
        <template v-slot:[`item.interest`]="{ item }">
          {{ item.interest.toLocaleString() }}
        </template>
        <template v-slot:[`item.fee`]="{ item }">
          {{ item.fee.toLocaleString() }}
        </template>
        <template v-slot:[`item.overdueInterest`]="{ item }">
          {{ item.overdueInterest.toLocaleString() }}
        </template>
      </v-data-table>
    </template>
  </PageSection>
</template>

<script>
import PageSection from '@/components/common/page/PageSection.vue'

export default {
  name: 'Repayments',
  components: { PageSection },
  props: {
    loanId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      repayments: [],
      headers: [
        { text: '상환일', value: 'created' },
        { text: '상환금액', value: 'amount' },
        { text: '원금', value: 'principal' },
        { text: '이자', value: 'interest' },
        { text: '수수료', value: 'fee' },
        { text: '연체이자', value: 'overdueInterest' }
      ]
    }
  },
  methods: {
    calculateAmount(item) {
      return (
        item.principal +
        item.interest +
        item.overdueInterest +
        item.fee
      )
    }
  },
  async created() {
    try {
      this.isLoading = true
      const { data } = await this.$fundaNowApi.getLoansRepayments(this.loanId)
      this.repayments = data
      this.repayments = this.repayments.map(repayment => {
        const createdDate = new Date(repayment.created)
        createdDate.setDate(createdDate.getDate() + 1)
        return {
          ...repayment,
          created: createdDate.toISOString()
        }
      })
    } catch (error) {
      console.error('상환 내역 조회 실패:', error)
    } finally {
      this.isLoading = false
    }
  }
}
</script>
