/* eslint-disable prettier/prettier */
<template>
  <v-data-table
    :headers="dataTable.headers"
    :items="dataTable.items"
    :options.sync="dataTable.options"
    :loading="dataTable.isLoading"
    class="elevation-2 my-3"
    hide-default-footer
    :items-per-page="-1"
  >
    <template v-slot:[`item.id`]="{ item }">
      {{ item.id }}
    </template>
    <template v-slot:[`item.usedDays`]="{ item }">
      {{ item.usedDays | commaFormat }}
    </template>
    <template v-slot:[`item.overdueDays`]="{ item }">
      {{ item.overdueDays | commaFormat }}
    </template>
    <template v-slot:[`item.interest`]="{ item }">
      {{ item.interest | commaFormat }} 원
    </template>
    <template v-slot:[`item.overdueInterest`]="{ item }">
      {{ item.overdueInterest | commaFormat }} 원
    </template>
    <template v-slot:[`item.fee`]="{ item }">
      {{ item.fee | commaFormat }} 원
    </template>
    <template v-slot:[`item.principal`]="{ item }">
      {{ item.principal | commaFormat }} 원
    </template>
    <template v-slot:[`item.remainPrincipal`]="{ item }">
      {{ item.remainPrincipal | commaFormat }} 원
    </template>
    <template v-slot:[`item.totalRepayment`]="{ item }">
      {{ item.fee + item.principal + item.interest + item.overdueInterest | commaFormat }} 원
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: {
    loansRepaymentPreDataId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      dataTable: {
        isLoading: false,
        options: null,
        headers: [
          { text: '대출index', value: 'loanId', sortable: false },
          { text: '이자율', value: 'interestRate', sortable: false },
          { text: '사용일수', value: 'usedDays', sortable: false },
          { text: '이자', value: 'interest', sortable: false },
          { text: '연체율', value: 'overdueInterestRate', sortable: false },
          { text: '연체사용일수', value: 'overdueDays', sortable: false },
          { text: '연체이자', value: 'overdueInterest', sortable: false },
          { text: '수수료율', value: 'feeRate', sortable: false },
          { text: '수수료', value: 'fee', sortable: false },
          { text: '원금', value: 'principal', sortable: false },
          { text: '상환 후 잔여원금', value: 'remainPrincipal', sortable: false },
          { text: '상환 총액', value: 'totalRepayment', sortable: false },
        ],
        items: [],
      },
    }
  },
  async created() {
    if (this.dataTable.isLoading === true) {
      return
    }
    try {
      this.dataTable.isLoading = true
      // eslint-disable-next-line prettier/prettier
      const { data } = await this.$fundaNowApi.getLoansRepaymentPreDataTransactions(this.loansRepaymentPreDataId)

      if (data.length > 0) {
        // eslint-disable-next-line no-unused-vars
        let { receivableData } = await this.$fundaNowApi.getLoansReceivables(data.loanId)
      }

      this.dataTable.items = data
      this.dataTable.totalCount = data.length
    } finally {
      this.dataTable.isLoading = false
    }
  }
}
</script>
<style lang="scss" scoped>
input {
  height: 40px;
  border: 1px solid #eee;
}
</style>
