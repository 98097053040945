<template>
  <v-select
    :items="items"
    v-model="multiSelect.selectedValues"
    :label="label"
    outlined
    multiple
    dense
    hide-details
    :item-text="itemText"
    :item-value="itemValue"
    prepend-inner-icon="mdi-filter-plus"
    class="mr-3"
    @blur="blur"
  >
    <!-- <template v-slot:prepend-item>
      <v-list-item
        ripple
        @click="multiSelectToggle"
        class="pt-1 mt-n2"
      >
        <v-list-item-action>
          <v-icon :color="multiSelect.selectedValues.length > 0 ? 'indigo darken-4' : ''">
            {{ icon }}
          </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            전체
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider />
    </template> -->
  </v-select>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    itemText: {
      type: String,
      default: 'text',
    },
    itemValue: {
      type: String,
      default: 'value',
    },
    items: {
      type: Array,
      required: true,
    },
    value: {
      type: Array,
      default: (_) => {
        return []
      },
    },
  },
  data() {
    return {
      multiSelect: {
        selectedValues: this.value,
      },
    }
  },
  methods: {
    multiSelectToggle() {
      this.multiSelect.selectedValues.splice(
        0,
        this.multiSelect.selectedValues.length
      )
    },
    blur(e) {
      if (this.value.length !== this.multiSelect.selectedValues.length) {
        return this.emitSelect()
      }

      const isEqualValues = this.value.every((propValue) => {
        return (
          this.multiSelect.selectedValues.find(
            (selectedValue) => propValue === selectedValue
          ) != null
        )
      })
      if (isEqualValues !== true) {
        this.emitSelect()
      }
    },
    emitSelect() {
      this.$emit('select', this.multiSelect.selectedValues)
    },
  },
  watch: {
    'multiSelect.selectedValues'(v) {
      if (this.items.length === v.length) {
        this.multiSelectToggle()
      }
    },
  },
  computed: {
    selectAll() {
      return this.multiSelect.selectedValues.length === 0
    },
    selectSome() {
      return this.multiSelect.selectedValues.length > 0
    },
    icon() {
      if (this.selectAll) return 'mdi-close-box'
      if (this.selectSome) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
  },
}
</script>
