import Vue from 'vue'

export function datetimeParser(datetime) {
  if (!datetime) {
    return ''
  }
  const d = datetime.split('T')
  const ymd = d[0]
  const hm = d[1].slice(0, 5)
  return `${ymd} ${hm}`
}

Vue.filter('datetimeFormat', function (datetime) {
  if (!datetime) {
    return ''
  }
  const d = datetime.split('T')
  const ymd = d[0]
  const hm = d[1].slice(0, 5)
  return `${ymd} \n${hm}`
})

Vue.filter('dateFormat', function (datetime) {
  if (!datetime) {
    return ''
  }
  const d = datetime.split('T')
  const ymd = d[0]
  return `${ymd}`
})

Vue.filter('dateFormat2', function (date) {
  if (!date) {
    return ''
  }
  const d = date.split(' ')
  const ymd = d[0]
  return `${ymd}`
})

Vue.filter('dateKorean', function (date) {
  if (!!!date) {
    return ''
  }

  const dateValue = new Date(date)
  const yyyy = dateValue.getFullYear()
  const mm = dateValue.getMonth() + 1
  const dd = dateValue.getDate()
  const dateText = `${yyyy}년 ${mm}월 ${dd}일`
  return dateText
})

Vue.filter('dateDot', function (date) {
  if (!!!date) {
    return ''
  }

  let dateValue = new Date(date).toISOString().substr(0, 10)
  dateValue = dateValue.replace(/-/g, '.')
  return dateValue
})
