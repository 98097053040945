<template>
  <div>
    <v-card class="my-3" width="100%">
      <v-card-text class="py-1">
        <v-form @submit.prevent="searchParam()">
          <v-row>
            <v-col cols="12" lg="2" md="4" sm="6">
              <CommonDateTimePicker
                :dateTime.sync="param.startDate"
                label="시작일 선택"
              />
            </v-col>
            <v-col cols="12" lg="2" md="4" sm="6">
              <CommonDateTimePicker
                :dateTime.sync="param.endDate"
                label="종료일 선택"
              />
            </v-col>
            <v-col cols="12" lg="2" md="2" sm="6" align-self="center">
              <v-btn color="primary" type="submit"> 조회 </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <v-data-table
      :headers="dataTable.headers"
      :items="dataTable.items"
      :options.sync="dataTable.options"
      :loading="dataTable.isLoading"
      :server-items-length="dataTable.totalCount"
      :footer-props="dataTable.footer"
      class="elevation-2"
    >
      <template v-slot:[`item.createdAt`]="{ item: { createdAt } }">
        {{ createdAt | moment('yyyy-MM-DD HH:mm:ss') }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import CommonDateTimePicker from '@/components/common/form/CommonDateTimePicker.vue'
import accountingApi from '@/plugins/api/now/accounting'

export default {
  components: { CommonDateTimePicker },
  props: {
    isQueryPaging: {
      type: Boolean,
      default: false,
    },
    query: {
      type: Object,
    },
  },
  data() {
    return {
      param: {
        page: this.isQueryPaging && this.query.page ? Number(this.query.page) : 1,
        size: this.query.size ? Number(this.query.size) : 10,
        startDate: this.isQueryPaging && this.$route.query.startDate
          ? this.$route.query.startDate
          : null,
        endDate: this.isQueryPaging && this.$route.query.endDate
          ? this.$route.query.endDate
          : null,
      },
      dataTable: {
        isLoading: false,
        options: null,
        headers: [
          { text: '발생일시', value: 'createdAt', sortable: false },
          { text: '은행명', value: 'bankName', sortable: false },
          { text: '계좌번호', value: 'accountNumber', sortable: false },
          { text: '금액', value: 'amount', sortable: false },
          { text: '내용', value: 'description', sortable: false },
        ],
        items: [],
        totalCount: 0,
        footer: {
          'items-per-page-options': [1, 5, 10, 20],
        },
      },
    }
  },
  watch: {
    'dataTable.options': {
      handler(options, oldValue) {
        if (!oldValue) {
          return
        }
        const optionObj = {
          page: options.page,
          size: options.itemsPerPage,
          startDate: options.startDate,
          endDate: options.endDate,
        }
        this.updateOption(optionObj)
      },
      deep: true,
    },
  },
  created() {
    this.dataTable.options = {
      page: this.param.page,
      itemsPerPage: this.param.size,
      startDate: this.param.startDate,
      endDate: this.param.endDate,
    }
    this.getPageData(this.param)
  },
  methods: {
    updateOption(option) {
      if (this.isQueryPaging === true) {
        return this.$router.push({
          query: option,
        })
      } else {
        this.getPageData(option)
      }
    },
    async getPageData(option) {
      if (this.dataTable.isLoading === true) {
        return
      }
      try {
        this.dataTable.isLoading = true

        const validOption = {
          'start-date': option.startDate,
          'end-date': option.endDate
        }

        const {
          data: {
            paging: { totalCount },
            data,
          },
        } = await accountingApi.getBankFees(validOption)

        this.dataTable.items = data
        this.dataTable.totalCount = totalCount
      } catch (error) {
        console.error('API Error:', error)
        this.$emit('error', error)
      } finally {
        this.dataTable.isLoading = false
      }
    },
    searchParam() {
      this.dataTable.options = Object.assign(
        this.dataTable.options,
        this.param,
        {
          page: 1,
        }
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.v-data-table::v-deep {
  td {
    padding: 10px 16px !important;
  }
}
</style>
