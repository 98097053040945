<template>
  <div>
    <v-card class="my-3" width="100%">
      <v-card-text class="py-1">
        <v-form @submit.prevent="searchParam()">
          <v-row>
            <v-col cols="12" lg="2" md="3" sm="6">
              <CommonDateTimePicker
                :dateTime.sync="param.startCreateTime"
                label="검색 시작일 선택"
              />
            </v-col>
            <v-col cols="12" lg="2" md="3" sm="6">
              <CommonDateTimePicker
                :dateTime.sync="param.endCreateTime"
                label="검색 종료일 선택"
              />
            </v-col>
            <v-col cols="12" lg="2" md="3" sm="6">
              <CodeSelect
                label="상태 선택"
                nameSpace="payment"
                type="UserRefund.Status"
                :selected.sync="param.status"
              />
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6">
              <v-text-field
                v-model.trim="param.keyword"
                outlined
                dense
                clearable
                label="검색"
                placeholder="remark, 사용자 이름"
                prepend-inner-icon="mdi-magnify"
                hide-details
              />
            </v-col>
            <v-col cols="12" lg="2" md="2" sm="6" align-self="center">
              <v-btn color="primary" type="submit"> 검색 </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <v-data-table
      :headers="dataTable.headers"
      :items="dataTable.items"
      :options.sync="dataTable.options"
      :loading="dataTable.isLoading"
      :server-items-length="dataTable.totalCount"
      :footer-props="dataTable.footer"
      class="elevation-2"
    >
      <template v-slot:[`item.userIdx`]="{ item }">
        <router-link
          :to="{
            name: 'payment/users/detail',
            params: {
              userIdx: item.userIdx,
            },
          }"
        >
          {{ item.userIdx }}
        </router-link>
      </template>

      <template v-slot:[`item.userVirtualAccountIdx`]="{ item }">
        <router-link
          :to="{
            name: 'payment/users/virtual/accounts/transactions',
            params: {
              userIdx: item.userIdx,
              userVirtualAccountIdx: item.userVirtualAccountIdx,
            },
          }"
        >
          {{ item.userVirtualAccountIdx }}
        </router-link>
      </template>

      <template v-slot:[`item.bankCode`]="{ item }">
        {{ item.bankCode | bankCodeName }}
      </template>

      <template v-slot:[`item.amount`]="{ item }">
        {{ item.amount | commaFormat }}
      </template>

      <template v-slot:[`item.status`]="{ item }">
        {{ item.status | payment.code('UserRefund.Status') }}
      </template>

      <template v-slot:[`item.createTime`]="{ item }">
        {{ item.createTime | moment('yyyy-MM-DD HH:mm:ss') }}
      </template>

      <template v-slot:[`item._cancel`]="{ item }">
        <UserRefundCancel
          :refund="item"
          @update:refund="updateItem(item, $event)"
        />
      </template>
    </v-data-table>
  </div>
</template>
<script>
import CodeSelect from '../../../../components/common/form/CodeSelect.vue'
import CommonDateTimePicker from '../../../../components/common/form/CommonDateTimePicker.vue'
import UserRefundCancel from './UserRefundCancel.vue'
export default {
  components: { CommonDateTimePicker, CodeSelect, UserRefundCancel },
  props: {
    isQueryPaging: {
      type: Boolean,
      default: false,
    },
    query: {
      type: Object,
      default: (_) => {
        return null
      },
    },
  },
  data() {
    return {
      param: {
        page:
          this.isQueryPaging && this.query.page ? Number(this.query.page) : 1,
        size: this.query.size ? Number(this.query.size) : 20,
        keyword:
          this.isQueryPaging && this.query.keyword ? this.query.keyword : null,
        startCreateTime:
          this.isQueryPaging && this.query.startCreateTime
            ? this.query.startCreateTime
            : null,
        endCreateTime:
          this.isQueryPaging && this.query.endCreateTime
            ? this.query.endCreateTime
            : null,
        status:
          this.isQueryPaging && this.query.status ? this.query.status : null,
        userIdx: this.query.userIdx ? Number(this.query.userIdx) : null,
      },
      dataTable: {
        isLoading: false,
        options: null,
        headers: [
          { text: 'idx', value: 'idx', sortable: false },
          { text: '사용자 idx', value: 'userIdx', sortable: false },
          {
            text: '가상계좌 idx',
            value: 'userVirtualAccountIdx',
            sortable: false,
          },
          { text: '대출 idx', value: 'loanIdx', sortable: false },
          {
            text: '출금계좌 idx',
            value: 'userWithdrawAccountIdx',
            sortable: false,
          },
          { text: '은행', value: 'bankCode', sortable: false },
          { text: '계좌번호', value: 'accountNo', sortable: false },
          { text: '금액', value: 'amount', sortable: false },
          { text: 'remark', value: 'remark', sortable: false },
          { text: '상태', value: 'status', sortable: false },
          { text: '생성 시간', value: 'createTime', sortable: false },
          { text: '취소', value: '_cancel', sortable: false },
        ],
        items: [],
        totalCount: 0,
        footer: {
          'items-per-page-options': [1, 5, 10, 20, 50, 100],
        },
      },
    }
  },
  watch: {
    'dataTable.options': {
      handler: function (options, oldValue) {
        if (oldValue === null) {
          return
        }
        const optionObj = {
          page: options.page,
          size: options.itemsPerPage,
          keyword: options.keyword,
          startCreateTime: options.startCreateTime,
          endCreateTime: options.endCreateTime,
          userIdx: options.userIdx,
          status: options.status,
        }
        this.updateOption(optionObj)
      },
      deep: true,
    },
  },
  created() {
    this.initTableOption()
    this.getPageData(this.param)
  },
  methods: {
    initTableOption() {
      this.dataTable.options = {
        page: this.param.page,
        itemsPerPage: this.param.size,
        keyword: this.param.keyword,
        startCreateTime: this.param.startCreateTime,
        endCreateTime: this.param.endCreateTime,
        userIdx: this.param.userIdx,
        status: this.param.status,
      }
    },
    updateOption(option) {
      if (this.isQueryPaging === true) {
        return this.$router.push({
          query: option,
        })
      } else {
        this.getPageData(option)
      }
    },
    async getPageData(option) {
      if (this.dataTable.isLoading === true) {
        return
      }
      try {
        this.dataTable.isLoading = true
        const {
          data: {
            paging: { totalCount },
            data,
          },
        } = await this.$paymentApi.getUserRefunds(option)
        this.dataTable.items = data
        this.dataTable.totalCount = totalCount
      } finally {
        this.dataTable.isLoading = false
      }
    },
    searchParam() {
      this.dataTable.options = Object.assign(
        this.dataTable.options,
        this.param,
        {
          page: 1,
        }
      )
    },
    updateItem(orgItem, newItem) {
      this.$set(
        this.dataTable.items,
        this.dataTable.items.indexOf(orgItem),
        newItem
      )
    },
  },
}
</script>
