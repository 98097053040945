<template>
  <div>
    <v-divider class="ma-8"></v-divider>

    <v-row no-gutters>
      <v-col cols="12">
        <p class="title pl-10">펀다나우 자동투자 변경이력</p>
      </v-col>

      <v-col cols="12">
        <v-data-table
          no-data-text="펀다나우 자동투자 변경기록이 없습니다."
          :headers="fnAutoInvestHeaders"
          :loading="getDataLoading"
          :items="fnAutoInvestHistories"
        >
          <template v-slot:item="{ item }">
            <tr class="cursor--pointer clickable-data-row">
              <td
                class="text-center"
                :class="item.isEnabled ? 'red--text' : 'blue--text'"
              >
                {{ item.isEnabled ? 'ON' : 'OFF' }}
              </td>
              <td :class="item.receivesSms ? 'red--text' : 'blue--text'">
                {{ item.receivesSms ? '수신' : '미수신' }}
              </td>
              <td class="font-weight-bold">
                {{ item.isDiversified ? '완전분산' : '금액선택' }}
              </td>
              <td>{{ item.totalAmount | commaFormat }} 원</td>
              <td>{{ item.maxAmountPerProduct | commaFormat }} 원</td>
              <td>{{ item.memo }}</td>
              <td>{{ item.createdAt | datetimeFormat }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    userIdx: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      getDataLoading: true,
      fnAutoInvestHeaders: [
        {
          text: '활성화',
          value: 'isEnabled',
          align: 'center',
          class: 'black--text',
          sortable: false,
        },
        {
          text: '문자수신',
          value: 'receivesSms',
          align: 'center',
          class: 'black--text',
          sortable: false,
        },
        {
          text: '투자타입',
          value: 'isDiversified',
          align: 'center',
          class: 'black--text',
          sortable: false,
        },
        {
          text: '총투자금액',
          value: 'totalAmount',
          align: 'center',
          class: 'black--text',
          sortable: false,
        },
        {
          text: '상점당금액',
          value: 'maxAmountPerProduct',
          align: 'center',
          class: 'black--text',
          sortable: false,
        },
        {
          text: '상품유형',
          value: 'memo',
          align: 'center',
          class: 'black--text',
          sortable: false,
        },
        {
          text: '변경일',
          value: 'createdAt',
          class: 'black--text',
          align: 'center',
        },
      ],
      autoInvestHistories: [],
      fnAutoInvestHistories: [],
    }
  },
  created() {
    if (!!this.userIdx) {
      this.getFnAutoInvestHistories()
    }
  },
  methods: {
    async getFnAutoInvestHistories() {
      let q = `
        data: getFnAutoInvestHistories(userIdx: ${this.userIdx}) {
          idx
          isEnabled
          receivesSms
          isDiversified
          totalAmount
          maxAmountPerProduct
          memo
          createdAt
        }
      `

      this.getDataLoading = true

      try {
        let data = await this.$fundaApi.query(gql`{${q}}`)

        this.fnAutoInvestHistories = data
      } finally {
        this.getDataLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
tr {
  td {
    text-align: center;
  }
}
</style>
