import Vue from 'vue'
import moment from 'moment'
import vueMoment from 'vue-moment'

// 한글 기본으로 변경
moment.locale('ko')
// 디폴트 포멧 변경
moment.defaultFormat = 'YYYY-MM-DD'

Vue.use(vueMoment, {
  moment,
})
