<template>
  <div>
    <v-card class="my-3" width="100%">
      <v-card-text class="py-1">
        <v-form @submit.prevent="searchParam()">
          <v-row>
            <v-col cols="12" lg="2" md="4" sm="6">
              <CommonDateTimePicker
                :dateTime.sync="param.startCreated"
                label="신청 시작일 선택"
              />
            </v-col>
            <v-col cols="12" lg="2" md="4" sm="6">
              <CommonDateTimePicker
                :dateTime.sync="param.endCreated"
                label="신청 종료일 선택"
              />
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" align-self="center">
              <CodeMultiSelect
                nameSpace="fundanow"
                code="LoansLoan.Status"
                label="상태"
                :selected="param.statuses"
                @update:selected="param.statuses = $event"
              />
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" align-self="center">
              <CodeMultiSelect
                nameSpace="fundanow"
                code="LoansLoan.Type"
                label="타입"
                :selected="param.loanTypes"
                @update:selected="param.loanTypes = $event"
              />
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" align-self="center">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-bind="attrs"
                    v-on="on"
                    v-model.trim="param.keyword"
                    outlined
                    dense
                    clearable
                    label="검색"
                    placeholder="대출 index, 고객명, 상점명, 핸드폰번호, 사업자번호 검색 가능"
                    prepend-inner-icon="mdi-magnify"
                    hide-details
                  />
                </template>
                <span>
                  대출 index, 고객명, 상점명, 핸드폰번호, 사업자번호 검색 가능
                </span>
              </v-tooltip>
            </v-col>
            <v-col cols="12" lg="2" md="2" sm="6" align-self="center">
              <v-btn color="primary" type="submit"> 조회 </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <v-data-table
      :headers="dataTable.headers"
      :items="dataTable.items"
      :options.sync="dataTable.options"
      :loading="dataTable.isLoading"
      :server-items-length="dataTable.totalCount"
      :footer-props="dataTable.footer"
      class="elevation-2"
    >
      <template v-slot:[`item.loanId`]="{ item }">
        <router-link
          v-if="item && item.loanId"
          :to="{
            name: 'fundanow/loan',
            params: { loanId: String(item.loanId) }
          }"
        >
          {{ item.loanId }}
        </router-link>
      </template>

      <template v-slot:[`item.created`]="{ item: { created } }">
        {{ created | moment('yyyy-MM-DD HH:mm:ss') }}
      </template>
      <template v-slot:[`item.executedAt`]="{ item: { executedAt } }">
        {{ executedAt | moment('yyyy-MM-DD HH:mm:ss') }}
      </template>
      <template v-slot:[`item.userName`]="{ item }">
        <router-link
          :to="{
            name: 'fundanow/users/detail',
            params: { userId: item.userId },
          }"
        >
          {{ item.userName }}({{ item.userId }})
        </router-link>
      </template>

      <template v-slot:[`item.amount`]="{ item }">
        <LoanAmount
          :loan="item"
          :fundaPaygateLoanId="item.fundaPaygateLoanId"
        />
      </template>

      <template v-slot:[`item.loanType`]="{ item: { loanType } }">
        {{ loanType | fundanow.code('LoansLoan.Type') }}
      </template>

      <template v-slot:[`item.repaymentPeriodInDays`]="{ item }">
        {{ item.repaymentPeriodInDays }}
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <template
          v-if="
            (item.status == 'invested' || item.status == 'investing') &&
            new Date(item.contractStartTime).getTime() < Date.now()
          "
        >
          <span class="error--text">{{item.status}} 계약서작성필요</span>
        </template>
        <template v-else>
          {{ getStatusDisplay(item.status) }}
        </template>
      </template>

      <template v-slot:[`item.gongsiIdx`]="{ item: { gongsiIdx } }">
        {{ gongsiIdx }}
      </template>
    </v-data-table>
  </div>
</template>
<script>
import CodeMultiSelect from '@/components/common/form/CodeMultiSelect.vue'
import LoanAmount from './cell/LoanAmount.vue'
import CommonDateTimePicker from '@/components/common/form/CommonDateTimePicker.vue'

export default {
  components: { LoanAmount, CodeMultiSelect, CommonDateTimePicker },
  props: {
    isQueryPaging: {
      type: Boolean,
      default: false,
    },
    query: {
      type: Object,
    },
  },
  data() {
    return {
      param: {
        page:
          this.isQueryPaging && this.query.page ? Number(this.query.page) : 1,
        size: this.query.size ? Number(this.query.size) : 10,
        keyword:
          this.isQueryPaging && this.query.keyword ? this.query.keyword : null,
        startCreated:
          this.isQueryPaging && this.$route.query.startCreated
            ? this.$route.query.startCreated
            : null,
        endCreated:
          this.isQueryPaging && this.$route.query.endCreated
            ? this.$route.query.endCreated
            : null,
        statuses:
          this.isQueryPaging && this.$route.query.statuses
            ? this.$route.query.statuses
            : null,
        loanTypes:
          this.isQueryPaging && this.$route.query.loanTypes
            ? this.$route.query.loanTypes
            : null,
      },
      dataTable: {
        isLoading: false,
        options: null,
        headers: [
          { text: 'index', value: 'loanId', sortable: false },
          { text: '모집시작', value: 'created', sortable: false },
          { text: '기표', value: 'executedAt', sortable: false },
          { text: '고객', value: 'userName', sortable: false },
          { text: '상점', value: 'storeName', sortable: false },
          { text: '금액', value: 'amount', sortable: false },
          { text: '상환기간(일)', value: 'repaymentPeriodInDays', sortable: false },
          { text: '타입', value: 'loanType', sortable: false },
          { text: '상태', value: 'status', sortable: false },
          { text: '공시idx', value: 'gongsiIdx', sortable: false },
        ],
        items: [],
        totalCount: 0,
        footer: {
          'items-per-page-options': [1, 5, 10, 20],
        },
      },
    }
  },
  watch: {
    'dataTable.options': {
      handler(options, oldValue) {
        if (!oldValue) {
          return
        }
        const optionObj = {
          page: options.page,
          size: options.itemsPerPage,
          keyword: options.keyword,
          statuses: options.statuses,
          startCreated: options.startCreated,
          endCreated: options.endCreated,
          loanTypes: options.loanTypes,
        }
        this.updateOption(optionObj)
      },
      deep: true,
    },
  },
  created() {
    this.initStatusParam()
    this.initLoanTypesParam()

    this.dataTable.options = {
      page: this.param.page,
      itemsPerPage: this.param.size,
      keyword: this.param.keyword,
      statuses: this.param.statuses,
      startCreated: this.param.startCreated,
      endCreated: this.param.endCreated,
      loanTypes: this.param.loanTypes,
    }
    this.getPageData(this.param)
  },
  methods: {
    getStatusDisplay(status) {
      const statusName = this.$options.filters['fundanow.code'](status, 'LoansLoan.Status')
      return statusName === '실행완료' ? '상환중' : statusName
    },
    initStatusParam() {
      // 상태 필터 초기화
      let initStatuses = []
      if (this.isQueryPaging === true) {
        if (this.query.statuses == null) {
          initStatuses = []
        } else if (this.query.statuses instanceof Array) {
          initStatuses = this.query.statuses
        } else {
          initStatuses = [this.query.statuses]
        }
      }
      this.param.statuses = initStatuses
    },
    initLoanTypesParam() {
      // 상태 필터 초기화
      let initLoanTypes = []
      if (this.isQueryPaging === true) {
        if (this.query.loanTypes == null) {
          initLoanTypes = []
        } else if (this.query.loanTypes instanceof Array) {
          initLoanTypes = this.query.loanTypes
        } else {
          initLoanTypes = [this.query.loanTypes]
        }
      }
      this.param.loanTypes = initLoanTypes
    },
    updateOption(option) {
      if (this.isQueryPaging === true) {
        return this.$router.push({
          query: option,
        })
      } else {
        this.getPageData(option)
      }
    },
    async getPageData(option) {
      if (this.dataTable.isLoading === true) {
        return
      }
      try {
        this.dataTable.isLoading = true

        // option 유효성 검사 추가
        const validOption = {
          ...option,
          page: Number(option.page) || 1,
          size: Number(option.size) || 10,
          loanTypes: Array.isArray(option.loanTypes) ? option.loanTypes : ['normal'],
          statuses: Array.isArray(option.statuses) ? option.statuses : ['investing', 'invested', 'executing', 'executed']
        }

        // 숫자 타입 체크
        if (isNaN(validOption.page) || isNaN(validOption.size)) {
          throw new Error('Invalid page or size parameter')
        }

        const {
          data: {
            paging: { totalCount },
            data,
          },
        } = await this.$fundaNowApi.getFunds(validOption)
        this.dataTable.items = data
        this.dataTable.totalCount = totalCount
      } catch (error) {
        console.error('API Error:', error)
        this.$emit('error', error)
      } finally {
        this.dataTable.isLoading = false
      }
    },
    selectStatus(e) {
      this.param.statuses = e
      this.searchParam()
    },
    selectLoanTypes(e) {
      this.param.loanTypes = e
      this.searchParam()
    },
    searchParam() {
      this.dataTable.options = Object.assign(
        this.dataTable.options,
        this.param,
        {
          page: 1,
        }
      )
    },
  },
}
</script>
<style lang="scss" scoped>
.v-data-table::v-deep {
  td {
    padding: 10px 16px !important;
  }
}
</style>
