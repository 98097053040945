/**
 * 텍스트 복사
 * @param value
 */
export const textCopy = (value) => {
  let el = document.createElement('textArea')
  document.body.appendChild(el)
  el.value = value
  el.select()
  document.execCommand('Copy')
  el.remove()
}

export const jsLoad = async (attr) => {
  return new Promise((resolve, reject) => {
    const org = document.getElementById(attr.id)
    if (org) {
      resolve(org)
      return
    }
    const sc = document.createElement('script')
    for (let key in attr) {
      sc[key] = attr[key]
    }

    sc.onload = () => {
      resolve(sc)
    }
    sc.onerror = () => {
      reject(new Error('script load error'))
    }

    document.head.appendChild(sc)
  })
}

export const cssLoad = (attr) => {
  return new Promise((resolve, reject) => {
    const org = document.getElementById(attr.id)
    if (org) {
      resolve(org)
      return
    }
    const sc = document.createElement('link')
    sc.rel = 'stylesheet'
    sc.type = 'text/css'

    for (let key in attr) {
      sc[key] = attr[key]
    }

    sc.onload = () => {
      resolve(sc)
    }
    sc.onerror = () => {
      reject(new Error('script load error'))
    }

    document.head.appendChild(sc)
  })
}

export const simpleClone = (obj) => {
  return JSON.parse(JSON.stringify(obj))
}

export const sleep = (m) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(m)
    }, m)
  })
}

export default {
  textCopy,
  simpleClone,
  cssLoad,
  jsLoad,
  sleep,
}
