import AuthService from '@/service/auth/AuthService'

function isLoanAdmin(to, from, next) {
  if (
    AuthService.hasAnyRole(['ROLE_LOAN_ADMIN', 'ROLE_SUPERADMIN']) === false
  ) {
    alert('권한없음')
    next(false)
  } else {
    next()
  }
}

export default [
  {
    name: 'fundanow/loans',
    path: '/fundanow/loans',
    component: require('@/pages/fundanow/loan/AbstractView.vue').default,
    redirect: {
      name: 'fundanow/loans/list',
    },
    children: [
      {
        name: 'fundanow/loans/list',
        path: 'list',
        meta: { title: '일반 채권 목록' },
        props: (route) => ({
          query: {
            ...route.query,
            loanTypes: route.query.loanTypes || ['normal'],
            statuses: route.query.statuses || ['investing', 'invested', 'executing'],
            page: route.query.page ? Number(route.query.page) : 1,
            size: route.query.size ? Number(route.query.size) : 10
          }
        }),
        component: require('@/pages/fundanow/loan/Loans.vue').default,
        beforeEnter: (to, from, next) => {
          isLoanAdmin(to, from, next)
        },
      }
    ],
  },
  {
    name: 'fundanow/loan',
    path: '/fundanow/loans/:loanId',
    component: require('@/pages/fundanow/loan/AbstractView.vue').default,
    props: (route) => ({
      loanId: route.params.loanId ? Number(route.params.loanId) : null
    }),
    redirect: (to) => ({
      name: 'fundanow/loans/detail',
      params: { loanId: to.params.loanId }
    }),
    children: [
      {
        name: 'fundanow/loans/detail',
        path: 'detail',
        meta: { title: '대출 정보' },
        props: (route) => ({
          loanId: route.params.loanId ? Number(route.params.loanId) : null
        }),
        component: require('@/pages/fundanow/loan/LoanDetail.vue').default,
      }
    ]
  }
]
