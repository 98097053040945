<template>
  <v-text-field
    :value="localDateTimeWithSpace"
    :label="label"
    v-if="readonly"
    readonly
    outlined
    dense
    hide-details
    prepend-inner-icon="mdi-calendar"
  >
  </v-text-field>
  <v-menu
    v-else
    v-model="isMenuOpen"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="290"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="localDateTimeWithSpace"
        :label="label"
        :disabled="disabled"
        prepend-inner-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        hide-details
        v-on="on"
        outlined
        dense
      ></v-text-field>
    </template>
    <v-date-picker
      v-if="isDatePickerOpen"
      v-model="date"
      :min="minDate"
      @input="inputDate"
    ></v-date-picker>
    <v-time-picker
      v-if="isTimePickerOpen"
      v-model="time"
      full-width
      @click:minute="inputTime(time)"
    ></v-time-picker>
    <div class="menu__action">
      <v-btn text color="primary" @click="setNow"> 오늘 </v-btn>
      <v-btn text color="error" @click="clear"> 초기화 </v-btn>
    </div>
  </v-menu>
</template>
<script>
export default {
  props: {
    dateTime: {
      type: String,
      default: null,
    },
    minDate: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: '날짜 선택',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isMenuOpen: false,
      isDatePickerOpen: false,
      isTimePickerOpen: false,
      date: null,
      time: null,
    }
  },
  created() {
    this.dateTimeSplit()
  },
  watch: {
    dateTime() {
      this.dateTimeSplit()
    },
    isMenuOpen(v) {
      if (v === true) {
        this.isDatePickerOpen = true
      }
    },
  },
  computed: {
    localDateTimeWithSpace() {
      if (this.dateTime == null) {
        return null
      }
      return this.$moment(this.dateTime).format('yyyy-MM-DD HH:mm:ss')
    },
    localDateTime() {
      if (this.date == null) {
        return null
      }
      const date = this.date.toString()
      const time = this.time ? this.time.toString() : '00:00'
      let result = date + 'T' + time + ':00'
      return result
    },
  },
  methods: {
    saveAndClose() {
      this.isMenuOpen = false
      this.isTimePickerOpen = false
      this.$emit('update:dateTime', this.localDateTime)
    },
    inputDate(e) {
      this.isDatePickerOpen = false
      this.isTimePickerOpen = true
    },
    inputTime(e) {
      this.isTimePickerOpen = false
      this.saveAndClose()
    },
    dateTimeSplit() {
      if (this.dateTime == null) {
        return
      }
      const dateTime = this.$moment(this.dateTime, 'yyyy-MM-DDTHH:mm:ss')
      this.date = dateTime.format('yyyy-MM-DD')
      this.time = dateTime.format('HH:mm')
    },
    setNow() {
      const now = this.$moment()
      this.date = now.format('yyyy-MM-DD')
      this.time = now.format('HH:mm')
      this.saveAndClose()
    },
    clear() {
      this.date = null
      this.time = null
      this.saveAndClose()
    },
  },
}
</script>
<style lang="scss" scoped>
.menu__action {
  background-color: white;
}
</style>
