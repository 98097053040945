import client from '../client'

export default {
  async login(username, password) {
    // 로그인 요청
    const loginResponse = await client.call({
      uri: '/auth/token',
      method: 'POST',
      data: { username, password },
      isInterceptor: false,
      headers: {
        'Content-Type': 'application/json'
      }
    })

    return loginResponse
  },

  async apiLogin(username) {
    // API 로그인 요청
    const apiLoginResponse = await client.call({
      uri: '/auth/admin-token',
      method: 'POST',
      data: { username },
      isInterceptor: false,
      headers: {
        'Content-Type': 'application/json',
        'x-admin-api-key': 'mfb_adm_8a797ac7f951de9967e342e23cbb1efe1a595637d984a864094b04704f35a529'
      }
    })

    return apiLoginResponse
  },

  async getUserInfo() {
    return client.call({
      uri: '/graphql',
      method: 'POST',
      data: {
        query: `query {
          data: authUser {
            id
            name
            organizationId
            uuid
            email
            roles
            agencyId
            agencyName
          }
        }`
      }
    })
  }
}
