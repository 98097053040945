<template>
  <v-container fluid class="pt-0">
    <PageHeader title="대출 상세 조회" subtitle="대출 상세 조회" tabMenu>
    </PageHeader>
    <v-row>
      <v-col lg="4" md="6" sm="6" cols="12">
        <Loan :loanId="loanId" @update:loan="loan = $event" />
      </v-col>
      <v-col lg="4" md="6" sm="6" cols="12">
        <LoansOffer :loanId="loanId" @update:userId="userId = $event" />
        <RepaymentMethod v-if="isMortgageLoan" :loanId="loanId" class="mt-3" />
      </v-col>
      <v-col lg="4" md="6" sm="6" cols="12" v-if="userId">
        <User :userId="userId" />
      </v-col>
    </v-row>
    <v-row v-if="isMortgageLoan">
      <v-col lg="12" md="12" sm="12" cols="12">
        <PageSection title="상환 스케쥴">
          <template slot="content">
            <RepaymentPlanTable :loanId="loanId" :preview="!loan.executedAt" />
          </template>
        </PageSection>
      </v-col>
    </v-row>
    <v-row>
      <v-col lg="12" md="12" sm="12" cols="12">
        <Repayments :loanId="loanId" />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Loan from '@/components/fundanow/loan/Loan.vue'
import LoansOffer from '@/components/fundanow/loan/LoansOffer.vue'
import User from '@/components/fundanow/user/User.vue'
import RepaymentPlanTable from '@/components/fundanow/repayment/plan/RepaymentPlanTable.vue'
import RepaymentMethod from '@/components/fundanow/repayment/RepaymentMethod.vue'
import Repayments from '@/components/fundanow/repayment/Repayments.vue'

export default {
  components: {
    Loan,
    LoansOffer,
    User,
    RepaymentPlanTable,
    RepaymentMethod,
    Repayments
  },
  props: {
    loanId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      userId: null,
      loan: null,
    }
  },
  computed: {
    isMortgageLoan() {
      return this.loan && this.loan.type === 'second_mortgage'
    },
  },
  async created() {},
}
</script>
