<template>
  <div class="global-error">
    <v-alert
      class="alert"
      v-for="(item, index) in $store.state.errors"
      border="top"
      color="red lighten-2"
      dark
      :key="index"
      @click="show(item, index)"
    >
      title: {{ item.title }}<br />
      message: {{ item.message }}
    </v-alert>
  </div>
</template>
<script>
export default {
  methods: {
    async show(item, index) {
      const jsonStr = JSON.stringify(item.message)
      const result = await this.$swal.basic.error({
        title: item.title,
        text: jsonStr.length > 2 ? jsonStr : item.message,
      })
      if (result.isConfirmed) {
        console.error('지금 누른 error -> ', item.message)
        this.$store.dispatch('errors/remove', item)
      }
    },
  },
}
</script>
<style scoped>
.global-error {
  right: 5px;
  bottom: 0;
  position: fixed;
  z-index: 1000;
}
.alert {
  cursor: pointer;
  max-width: 300px;
}
</style>
