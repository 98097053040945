<template>
  <v-row>
    <v-card tile width="100%">
      <v-card-text class="pt-0 pb-0 mb-n1">
        <v-row>
          <v-col
            cols="12"
            :lg="!!$slots.right ? leftCols : 12"
            :md="!!$slots.right ? leftCols : 12"
            sm="12"
          >
            <p class="headline mb-0 black--text">{{ title }}</p>
            <p
              v-if="subtitle"
              class="subtitle-2 mb-0"
              :class="`${color}--text`"
              :style="`color: ${color};`"
            >
              {{ subtitle }}
            </p>
          </v-col>
          <v-col
            v-if="!!$slots.right"
            cols="12"
            :lg="rightCols"
            :md="rightCols"
            sm="12"
            class="py-0"
          >
            <v-row>
              <slot name="right"></slot>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card
      tile
      v-if="navigation != null"
      :color="$colors['f-white-blue-4']"
      width="100%"
    >
      <v-card-text class="px-4 py-2">
        <v-breadcrumbs :items="navigation" class="pa-0 body-2">
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
      </v-card-text>
    </v-card>
    <v-row v-if="tabMenu === true" no-gutters>
      <v-tabs
        show-arrows
        color="#08a7f6"
        slider-size="4"
        class="elevation-3"
        background-color="white"
        active-class="active-tab"
      >
        <v-tab
          v-for="(route, index) in tabRoutes"
          :key="index"
          :to="{ name: route.name, query: route.query }"
        >
          {{ route.meta.title }}
        </v-tab>
      </v-tabs>
    </v-row>
  </v-row>
</template>
<script>
export default {
  props: {
    rightCols: {
      type: Number,
      default: 8,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: String,
    color: String,
    navigation: {
      type: Array,
      default: (_) => {
        return null
      },
    },
    tabMenu: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    leftCols() {
      return 12 - this.rightCols
    },
    tabRoutes() {
      const current = this.$route.matched.find(
        (route) => route.name === this.$route.name
      )
      const parent = this.$router.options.routes.find(
        (route) => route.name === current.parent.name
      )
      return parent.children
    },
  },
  created() {
    // console.log(this.$route)
  },
}
</script>
<style lang="scss" scoped>
.headline {
  line-height: 1.5rem;
}
.v-tabs ::v-deep .v-tab:not(.v-tab--active) {
  color: #8a94a2 !important;
  font-weight: 500 !important;
}
.active-tab {
  font-weight: 700;
}
</style>
