<template>
  <v-container fluid class="pt-0">
    <v-row>
      <v-col lg="4" md="6" sm="6" cols="12">
        <div class="mb-4">
          <v-text-field
            v-model="accountNo"
            hide-details
            outlined
            dense
            class="mb-2"
            label="계좌번호 입력"
            background-color="white"
            @paste.prevent="paste"
            @input="accountNoInput"
          ></v-text-field>
          <v-text-field
            v-model="birthDate"
            hide-details
            outlined
            dense
            class="mb-2"
            label="생년월일 입력"
            background-color="white"
            @input="birthDateInput"
          ></v-text-field>
          <v-btn color="primary" block large @click="submit">조회</v-btn>
        </div>
        <CollectionAccountTabs
          v-if="key"
          :key="key"
          bankCode="39"
          :accountNo="accountNo"
          :birthDate="birthDate"
        />
      </v-col>

      <v-col lg="4" md="6" sm="6" cols="12">
        <v-card>
          <v-card-text>
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr v-for="(item, idx) in testDatas" :key="idx">
                    <td>{{ item.accountNo }} | {{ item.birthDate }}</td>
                    <td class="text-right">
                      <v-btn @click="pick(item)" color="primary" depressed>
                        조회
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CollectionAccountTabs from '@/components/fundanow/user/bnk/CollectionAccountTabs.vue'

export default {
  components: { CollectionAccountTabs },
  data() {
    return {
      accountNo: '',
      birthDate: '',
      key: '',
      count: 0,
      testDatas: [
        {
          accountNo: '600-22-0154278',
          birthDate: '820716',
        },
        {
          accountNo: '537-22-0096995',
          birthDate: '510319',
        },
        {
          accountNo: '600-22-0104143',
          birthDate: '530528',
        },
        {
          accountNo: '600-21-0164997',
          birthDate: '721001',
        },
        {
          accountNo: '548-22-0334870',
          birthDate: '660503',
        },
      ],
    }
  },
  methods: {
    pick(item) {
      this.accountNoInput(item.accountNo)
      this.birthDateInput(item.birthDate)

      this.submit()
    },
    paste(v) {
      const clipboardData = v.clipboardData.getData('text')
      if (clipboardData.indexOf('/') === -1) {
        this.accountNoInput(clipboardData)
        return
      }
      const [accountNo, birthDate] = clipboardData.split('/')

      this.accountNoInput(accountNo)
      this.birthDateInput(birthDate)
    },
    accountNoInput(accountNo) {
      this.accountNo = accountNo.replace(/\D/g, '')
    },
    birthDateInput(birthDate) {
      this.birthDate = birthDate.replace(/\D/g, '')

      if (
        this.birthDate.length === 6 &&
        this.birthDate.indexOf('19') !== 0 &&
        this.birthDate.indexOf('20') !== 0
      ) {
        this.birthDate = '19' + this.birthDate
      }

      if (this.birthDate.length === 8) {
        this.birthDate = [
          this.birthDate.substring(0, 4),
          '-',
          this.birthDate.substring(4, 6),
          '-',
          this.birthDate.substring(6, 8),
        ].join('')
      }
    },
    submit() {
      this.key = `${this.accountNo}-${this.birthDate}-${++this.count}`
    },
  },
}
</script>

<style></style>
