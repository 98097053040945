import Vue from 'vue'
import sms from './sms'

const api = {
  ...sms,
}

const pluginName = '$smsApi'

Vue.use({
  install(Vue) {
    window[pluginName] = api
    Vue.prototype[pluginName] = api
  },
})

export default api
