<template>
  <PageSection
    title="집금 계좌 정보"
    :loading="loading || getAccountLoading"
    :expandable="isBnkAccount && !!collectionAccountNumber"
    :isExpanded="isExpanded"
    @update:isExpanded="$emit('update:isExpanded', $event)"
  >
    <template v-if="collectionAccountNumber" slot="empty">
      <!-- 탭메뉴 -->
      <v-toolbar
        v-if="isBnkAccount"
        color="#333333"
        dark
        flat
        dense
        height="36"
      >
        <v-tabs v-model="tab">
          <v-tabs-slider color="white"></v-tabs-slider>
          <v-tab v-for="item in items" :key="item.idx" @click="keyCount++">
            {{ item.name }}
          </v-tab>
        </v-tabs>
      </v-toolbar>

      <v-tabs-items v-model="tab" class="rounded" :touchless="!isBnkAccount">
        <v-tab-item
          v-for="item in items"
          :key="`${item.idx}-${keyCount}`"
          class="rounded"
        >
          <v-card flat :min-height="tab !== 0 ? '236' : ''">
            <v-card-text>
              <component
                :is="item.tab"
                :accountNo="collectionAccountNumber"
                :birthDate="birthDate"
                :account="userAccount"
              />
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </template>
    <template v-else-if="!loading && !getAccountLoading" slot="empty">
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <tbody>
              <tr>
                <td class="py-5 text-center subtitle-1 grey--text">
                  아직 집금 계좌 정보가 없습니다.
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </template>
  </PageSection>
</template>

<script>
export default {
  props: {
    // userId 또는 계좌번호로 조회
    userId: {
      type: Number,
      required: false,
    },

    // 경남 은행인 경우에만
    accountNo: {
      type: String,
      required: false,
    },

    birthDate: {
      type: String,
      required: false,
    },

    loading: {
      type: Boolean,
      required: false,
    },
    isExpanded: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    collectionAccountNumber() {
      // userId를 props로 받은 경우
      if (this.userId) {
        return this.userAccount?.accountNumber
      }

      // 계좌번호를 props로 받은 경우
      return this.accountNo
    },
    isBnkAccount() {
      return !this.userAccount || this.userAccount.bank === '39'
    },
  },
  data() {
    return {
      keyCount: 0,
      tab: 0,
      items: [
        {
          idx: 0,
          name: '계좌 정보',
          tab: () =>
            import('@/components/fundanow/user/bnk/CollectionAccount.vue'),
        },
        {
          idx: 1,
          name: 'ARS 인증 내역',
          tab: () =>
            import('@/components/fundanow/user/bnk/CollectionArsHistories.vue'),
        },
        {
          idx: 2,
          name: '출금 이체 동의 내역',
          tab: () =>
            import(
              '@/components/fundanow/user/bnk/CollectionAccountHistories.vue'
            ),
        },
        {
          idx: 3,
          name: '배치 등록 내역',
          tab: () =>
            import(
              '@/components/fundanow/user/bnk/CollectionBatchHistories.vue'
            ),
        },
      ],
      getAccountLoading: false,
      userAccount: null,
    }
  },
  async created() {
    if (this.userId) {
      this.getAccountLoading = true
      try {
        const { data } = await this.$fundaNowApi.getUserCollectionaccount(
          this.userId
        )
        this.userAccount = data
      } finally {
        this.getAccountLoading = false
      }
    }
  },
}
</script>

<style></style>
