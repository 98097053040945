<template>
  <div>
    <v-data-table
      :items-per-page="7"
      :headers="dataTable.headers"
      :items="dataTable.items"
      :loading="dataTable.isLoading"
      :server-items-length="dataTable.totalCount"
      :footer-props="dataTable.footer"
      @update:options="updateOption"
    >
      <template v-slot:[`item.amount`]="{ item: { amount } }">
        {{ amount | commaFormat }} 원
      </template>

      <template v-slot:[`item.status`]="{ item: { status } }">
        <span :class="{ 'primary--text': status == 'SUCCESS' }">
          {{ status | payment.code('UserWithdraw.Status') }}
        </span>
      </template>

      <template v-slot:[`item.createTime`]="{ item: { createTime } }">
        {{ createTime | moment('yyyy-MM-DD HH:mm:ss') }}
      </template>

      <template v-slot:[`item.updateTime`]="{ item: { updateTime, status } }">
        <template v-if="status == 'SUCCESS'">
          {{ updateTime | moment('yyyy-MM-DD HH:mm:ss') }}
        </template>
        <template v-else> - </template>
      </template>

      <template v-slot:[`item.expireTime`]="{ item: { expireTime } }">
        {{ expireTime | moment('yyyy-MM-DD HH:mm:ss') }}
      </template>
    </v-data-table>
  </div>
</template>
<script>
export default {
  props: {
    paymentUserIdx: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      param: {
        page: 1,
        size: 7,
      },
      dataTable: {
        isLoading: false,
        options: null,
        headers: [
          {
            text: '금액',
            value: 'amount',
            sortable: false,
            width: '20%',
            align: 'center',
          },
          {
            text: '상태',
            value: 'status',
            sortable: false,
            width: '20%',
            align: 'center',
          },
          {
            text: '출금 요청 시간',
            value: 'createTime',
            sortable: false,
            width: '20%',
            align: 'center',
          },
          {
            text: '출금 완료 시간',
            value: 'updateTime',
            sortable: false,
            width: '20%',
            align: 'center',
          },
          {
            text: '인증 만료 시간',
            value: 'expireTime',
            sortable: false,
            width: '20%',
            align: 'center',
          },
        ],
        items: [],
        totalCount: 0,
        footer: {
          'items-per-page-options': [1, 5, 7, 10, 20],
        },
      },
    }
  },
  async created() {
    await this.getPageData()
  },
  methods: {
    async updateOption(option) {
      this.param.size = option.itemsPerPage
      this.param.page = option.page
      await this.getPageData()
    },
    async getPageData() {
      if (this.dataTable.isLoading === true) {
        return
      }

      try {
        this.dataTable.isLoading = true
        const {
          result: {
            data,
            paging: { totalCount },
          },
        } = await this.$fundaApi.query(gql`{
          result: getPaymentUserWithdrawals(paymentUserIdx: ${this.paymentUserIdx}, search: {
            page: ${this.param.page},
            size: ${this.param.size}
          }) {
            data {
              amount
              status
              createTime
              updateTime
              expireTime
            }
            paging {
              totalCount
            }
          }
        }`)

        this.dataTable.items = data
        this.dataTable.totalCount = totalCount
      } finally {
        this.dataTable.isLoading = false
      }
    },
  },
}
</script>
