<template>
  <v-card>
    <v-card-title>예약 발송 여부</v-card-title>
    <v-card-text :class="{ 'pb-0': isReservSend }">
      <v-checkbox
        v-model="isReservSend"
        label="예약 발송"
        hide-details
        class="mt-0"
      />
    </v-card-text>
    <v-scroll-y-transition>
      <template v-if="isReservSend">
        <v-card-text class="pt-0">
          <v-row>
            <v-col cols="12" lg="6" md="6" sm="6">
              <v-menu
                v-model="isDatePickerOpen"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date"
                    label="예약일 선택"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    hide-details
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  :min="minDate"
                  @input="isDatePickerOpen = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="6">
              <v-menu
                ref="timePicker"
                v-model="isTimePickerOpen"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="time"
                    label="예약 시간 선택"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    hide-details
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="isTimePickerOpen"
                  v-model="time"
                  full-width
                  @click:minute="$refs.timePicker.save(time)"
                ></v-time-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-card-text>
      </template>
    </v-scroll-y-transition>
  </v-card>
</template>
<script>
export default {
  data() {
    return {
      isReservSend: false,
      isDatePickerOpen: false,
      isTimePickerOpen: false,
      date: null,
      time: null,
      minDate: null,
    }
  },
  watch: {
    date() {
      this.localDateTime()
    },
    time() {
      this.localDateTime()
    },
    isReservSend(v) {
      if (v === true) {
        this.date = this.$moment().format()
        this.time = this.$moment().format('HH:mm')
        this.minDate = this.$moment().format()
        this.localDateTime()
      } else {
        this.$emit('change', null)
      }
    },
  },
  methods: {
    localDateTime() {
      const date = this.date.toString()
      const time = this.time ? this.time.toString() : '00:00'
      let result = date + 'T' + time + ':00'
      this.$emit('change', result)
    },
  },
}
</script>
