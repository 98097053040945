<template>
  <div class="px-3">
    <PageHeader title="연체 상태 관리" />
    <v-row class="my-5" no-gutters>
      <v-col cols="3" md="3"></v-col>
      <v-col cols="6" md="6">
        <h2 class="text-center"></h2>
      </v-col>
      <v-col cols="3" md="3" class="text-right">
        <v-btn
          x-large
          width="200px"
          color="cyan"
          dark
          @click="isShowTab = !isShowTab"
        >
          {{ isShowTab ? '탭 접기' : '탭 펼치기' }}
        </v-btn>
      </v-col>
    </v-row>

    <v-row class="mb-5" no-gutters>
      <v-col v-show="isShowTab" cols="12" sm="12" md="12">
        <v-tabs
          v-model="tab"
          height="70"
          fixed-tabs
          color="#ffffff"
          background-color="#393939"
          dark
        >
          <v-tab @click="showTab = 'storeName'"> 펀다나우 상점 </v-tab>

          <v-tab @click="showTab = 'fundingData'"> 일반 상품 </v-tab>
        </v-tabs>

        <v-card min-width="100%" min-height="400" flat>
          <v-tabs-items v-model="tab" style="width: 100%">
            <div>
              <v-tab-item>
                <v-row class="mt-3" no-gutters>
                  <v-col cols="12" sm="12" md="12">
                    <v-tabs
                      v-model="fnStoreTab"
                      height="50"
                      fixed-tabs
                      color="purple"
                      background-color="white"
                    >
                      <v-tab @click="showTab = 'storeName'">
                        상점명으로 찾기
                      </v-tab>

                      <v-tab @click="showTab = 'calendar'">
                        달력에서 찾기
                      </v-tab>
                    </v-tabs>

                    <v-card min-width="100%" min-height="400" flat>
                      <v-tabs-items v-model="fnStoreTab" style="width: 100%">
                        <v-tab-item>
                          <template v-if="showTab === 'storeName'">
                            <FnStoreFundingData
                              :hide-fn-store-search="false"
                              :fn-store-funding-data-idx="fnStoreFundingDataIdx"
                              @selectFnStoreFundingData="
                                updateFnStoreFundingData
                              "
                            ></FnStoreFundingData>
                          </template>
                        </v-tab-item>

                        <v-tab-item>
                          <template v-if="showTab === 'calendar'">
                            <FundingDataSearch
                              :show-calendar="true"
                              @selectFundingData="updateFnFundingData"
                            ></FundingDataSearch>

                            <FnStoreFundingData
                              :is-overdue="true"
                              :funding-data-idx="fnFundingDataIdx"
                              @selectFnStoreFundingData="
                                updateFnStoreFundingData
                              "
                            ></FnStoreFundingData>
                          </template>
                        </v-tab-item>
                      </v-tabs-items>
                    </v-card>
                  </v-col>
                </v-row>
              </v-tab-item>

              <v-tab-item>
                <template v-if="showTab === 'fundingData'"> </template>
              </v-tab-item>
            </div>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>

    <v-divider
      v-show="!isShowTab"
      class="my-5"
      style="border-width: 10px 0 0; border-color: #1baaf1"
    ></v-divider>

    <v-row no-gutters>
      <v-col cols="12">
        <FundingDataOverdueStatus
          :fn-store-funding-data-idx="fnStoreFundingDataIdx"
        >
        </FundingDataOverdueStatus>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import FundingDataSearch from '@/components/funda/funding/FundingDataSearch'
import FnStoreFundingData from '@/components/funda/fundanow/FnStoreFundingData'
import FundingDataOverdueStatus from '@/components/funda/funding/FundingDataOverdueStatus'

export default {
  components: {
    FundingDataSearch,
    FundingDataOverdueStatus,
    FnStoreFundingData,
  },
  data() {
    return {
      isShowTab: true,
      tab: null,
      fnStoreTab: null,
      showTab: 'storeName',
      fundingDataIdx: null,
      fnFundingDataIdx: null,
      fnStoreFundingDataIdx: null,
    }
  },
  created() {
    let qs = this.$route.query
    if (!!qs.fnIdx) {
      this.isShowTab = false
      this.showTab = 'storeName'
      this.tab = 1
      this.fnStoreFundingDataIdx = parseInt(qs.fnIdx)
    }

    if (!!qs.fundingDataIdx) {
      this.isShowTab = false
      this.showTab = 'fundingData'
      this.tab = 2
      this.fundingDataIdx = parseInt(qs.fundingDataIdx)
    }
  },
  methods: {
    updateFundingData(v) {
      this.fundingDataIdx = !!v ? v.idx : null
    },
    updateFnFundingData(v) {
      this.fnFundingDataIdx = !!v ? v.idx : null
    },
    updateFnStoreFundingData(v) {
      this.fnStoreFundingDataIdx = !!v ? v.idx : null
    },
  },
}
</script>

<style lang="scss" scoped></style>
