<template>
  <div>
    <PageHeader title="쿠폰 및 포인트 관리" />

    <v-row no-gutters>
      <v-col cols="12" class="my-2 pa-2">
        <CouponData @emitSelectCoupon="(v) => (selectedCoupon = v)" />
      </v-col>

      <v-col cols="12" class="my-2 pa-2">
        <v-row class="mb-3" no-gutters>
          <v-col cols="6">
            <UserList @selectUser="(v) => (selectedUser = v)" shortcut />
          </v-col>

          <v-col cols="6">
            <v-list>
              <v-list-group no-action :value="true">
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title class="font-size--18">
                      포인트 지급
                    </v-list-item-title>
                  </v-list-item-content>
                </template>

                <v-list-item class="pa-0">
                  <ProvidePoint :coupon="selectedCoupon" :user="selectedUser" />
                </v-list-item>
              </v-list-group>
            </v-list>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CouponData from '@/components/funda/coupon/CouponData.vue'
import UserList from '@/components/funda/user/List.vue'
import ProvidePoint from '@/components/funda/coupon/ProvidePoint.vue'

export default {
  components: {
    CouponData,
    UserList,
    ProvidePoint,
  },
  data() {
    return {
      selectedCoupon: null,
      selectedUser: null,
    }
  },
}
</script>
