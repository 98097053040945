<template>
  <v-container fluid class="pt-0">
    <PageHeader title="고객 목록 조회" subtitle="고객 목록 조회"> </PageHeader>
    <UsersTable :query="query" :isQueryPaging="true" />
  </v-container>
</template>
<script>
import UsersTable from '@/components/fundanow/user/UsersTable.vue'
export default {
  components: { UsersTable },
  props: {
    query: {
      type: Object,
    },
  },
}
</script>
