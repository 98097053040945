<template>
  <div class="pa-3">
    <v-row>
      <v-col lg="4" md="6" sm="6" cols="12">
        <v-card>
          <v-card-title> 문자 발송 </v-card-title>
          <v-card-text>
            <SmsSenderTest :formData="form">
              <template v-slot:form="{ submit, prop }">
                <form @submit.prevent="beforeSubmit(submit)">
                  {{ prop }}
                  <v-text-field
                    v-model="form.sendNo"
                    class="my-3"
                    type="tel"
                    label="발송 번호"
                    hide-details
                    outlined
                  />
                  <v-text-field
                    v-model="form.mobileNo"
                    class="my-3"
                    type="tel"
                    label="수신 번호"
                    hide-details
                    outlined
                  />
                  <v-text-field
                    v-model="form.title"
                    class="my-3"
                    type="text"
                    label="제목(LMS발송시)"
                    hide-details
                    outlined
                  />
                  <v-textarea
                    v-model="form.content"
                    class="my-3"
                    type="text"
                    label="내용"
                    hide-details
                    outlined
                    auto-grow
                    rows="1"
                  />
                  <v-checkbox v-model="isReservation" label="예약발송" />
                  <CommonDateTimePicker
                    v-if="isReservation == true"
                    :dateTime.sync="form.reserveTime"
                    label="예약시간"
                  />
                  <v-btn
                    color="primary"
                    type="submit"
                    block
                    large
                    class="title mt-3"
                    >발송</v-btn
                  >
                </form>
              </template>
            </SmsSenderTest>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import SmsSenderTest from '../../components/sms/SmsSender.vue'
import CommonDateTimePicker from '../../components/common/form/CommonDateTimePicker.vue'

export default {
  components: { CommonDateTimePicker, SmsSenderTest },
  data() {
    return {
      isReservation: false,
      form: {
        refId: null,
        sendNo: '025400360',
        mobileNo: null,
        title: null,
        content: null,
        reserveTime: null,
      },
    }
  },
  watch: {
    isReservation(v) {
      if (v === false) {
        this.form.reserveTime = null
      }
    },
  },
  methods: {
    async beforeSubmit(submit) {
      try {
        await submit()
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
