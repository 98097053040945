<template>
  <v-list dense v-if="pages != null && pages.length > 0">
    <v-list-group :value="true">
      <template v-slot:activator>
        <v-list-item-content>
          <v-list-item-title>최근 방문 페이지</v-list-item-title>
        </v-list-item-content>
      </template>
      <v-list-item
        v-for="(page, index) in pages"
        :key="index"
        :to="{
          name: page.name,
          params: page.params,
        }"
        exact
        style="min-height: 25px"
        class="pl-10"
        :class="[
          {
            'router-link-active--false': activeMenu != page.name,
          },
          {
            'v-list-item--active': activeMenu == page.name,
          },
        ]"
      >
        <v-list-item-content class="py-1">
          <v-list-item-title class="font-size--13">
            {{ page.title }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-group>
  </v-list>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    activeMenu: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapGetters('admin', {
      pages: 'getVisitPages',
    }),
  },
}
</script>
