import { render, staticRenderFns } from "./BalanceDepositTable.vue?vue&type=template&id=172e3665&scoped=true&"
import script from "./BalanceDepositTable.vue?vue&type=script&lang=js&"
export * from "./BalanceDepositTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "172e3665",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VProgressCircular})
