<template>
  <div v-if="investUser">
    <v-card style="border: 1px solid #00000061" elevation="0">
      <v-card-title>
        <span class="mx-1">(</span>
        <router-link
          target="_blank"
          :to="{
            name: 'fundaUserDetails',
            params: {
              userIdx: investUser.idx,
            },
          }"
        >
          {{ investUser.idx }}
        </router-link>
        <span class="mx-1">)</span>
        {{ investUser.name }}
      </v-card-title>
      <v-card-text v-if="userVirtualAccount">
        <v-list class="py-0">
          <v-list-item class="px-0">
            <v-list-item-content class="py-0">
              <v-list-item-subtitle class="d-flex align-center flex-wrap">
                가상계좌 : {{ userVirtualAccount.accountName }}
                <span class="mx-1">(</span>
                <router-link
                  target="_blank"
                  :to="{
                    name: 'payment/users/detail',
                    params: {
                      userIdx: userVirtualAccount.userIdx,
                    },
                  }"
                >
                  {{ userVirtualAccount.userIdx }}
                </router-link>
                <span class="mx-1">/</span>
                <router-link
                  target="_blank"
                  :to="{
                    name: 'payment/users/virtual/accounts/transactions',
                    params: {
                      userIdx: userVirtualAccount.userIdx,
                      userVirtualAccountIdx: userVirtualAccount.idx,
                    },
                  }"
                >
                  {{ userVirtualAccount.idx }}
                </router-link>
                <span class="mx-1">)</span>
              </v-list-item-subtitle>
              <v-list-item-title>{{
                userVirtualAccount.accountNo
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="px-0">
            <v-list-item-content class="py-0">
              <v-list-item-subtitle>투자 가능 잔액</v-list-item-subtitle>
              <v-list-item-title
                >{{ investUserBalance | commaFormat }} 원</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState('marketing/loan', [
      'investUser',
      'userVirtualAccount',
      'investUserBalance',
    ]),
  },
}
</script>
