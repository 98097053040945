<template>
  <PageSection title="대출 정보" :isLoading="isLoading">
    <template slot="content" v-if="loan != null">
      <v-simple-table>
        <template v-slot:default>
          <tbody>
            <tr>
              <td>index</td>
              <td>{{ loan.id }}</td>
            </tr>
            <tr>
              <td>상품 유형</td>
              <td>
                {{ loan.type | fundanow.code('LoansLoan.Type') }}
              </td>
            </tr>
            <tr>
              <td>상태</td>
              <td>
                {{ loan.status | fundanow.code('LoansLoan.Status') }}
              </td>
            </tr>
            <tr>
              <td>최근 상태 변경</td>
              <td>{{ loan.statusChanged | moment('YYYY-MM-DD HH:mm:ss') }}</td>
            </tr>
            <tr>
              <td>신청시간</td>
              <td>{{ loan.created | moment('YYYY-MM-DD HH:mm:ss') }}</td>
            </tr>
            <tr v-if="loan.canceledAt">
              <td>취소시간</td>
              <td>{{ loan.canceledAt | moment('YYYY-MM-DD HH:mm:ss') }}</td>
            </tr>
            <tr v-if="loan.investedAt">
              <td>투자완료 시간</td>
              <td>{{ loan.investedAt | moment('YYYY-MM-DD HH:mm:ss') }}</td>
            </tr>
            <tr v-if="loan.executedAt">
              <td>실행완료 시간</td>
              <td>{{ loan.executedAt | moment('YYYY-MM-DD HH:mm:ss') }}</td>
            </tr>
            <tr v-if="loan.finishedAt">
              <td>상환완료 시간</td>
              <td>{{ loan.finishedAt | moment('YYYY-MM-DD HH:mm:ss') }}</td>
            </tr>
            <tr>
              <td>금액</td>
              <td v-if="!fundaPaygateLoan">
                {{ loan.amount | commaFormat }} 원
              </td>
              <td v-else class="py-2">
                <LoanAmount
                  :loan="loan"
                  :fundaPaygateLoanId="fundaPaygateLoan.id"
                />
              </td>
            </tr>
            <tr v-if="loan.status === 'executed'">
              <td>잔여원금</td>
              <td>
                <RemainPrincipal :loanId="loanId" />
              </td>
            </tr>
            <tr v-if="loan.commission">
              <td>선취수수료</td>
              <td>{{ loan.commission | commaFormat }} 원</td>
            </tr>
            <tr v-if="fundaPaygateLoan">
              <td>payment Loan Idx</td>
              <td>{{ fundaPaygateLoan.id }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </template>
  </PageSection>
</template>
<script>
import LoanAmount from './cell/LoanAmount.vue'
import RemainPrincipal from './cell/RemainPrincipal.vue'

export default {
  components: { LoanAmount, RemainPrincipal },
  props: {
    loanId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loan: null,
      fundaPaygateLoan: null,
      isLoading: false,
    }
  },
  watch: {
    loan(v) {
      if (v) {
        this.$emit('update:loan', v)
      }
    },
  },
  async created() {
    try {
      this.isLoading = true

      const { data } = await this.$fundaNowApi.getLoan(this.loanId)
      this.loan = data

      if (this.loan.finishedAt) {
        const finishedDate = new Date(this.loan.finishedAt)
        finishedDate.setDate(finishedDate.getDate() + 1)
        this.loan.finishedAt = finishedDate.toISOString()
      }

      const { data: fundaPaygateLoan } =
        await this.$fundaNowApi.getFundaPaygateLoan(this.loanId)
      this.fundaPaygateLoan = fundaPaygateLoan
    } finally {
      this.isLoading = false
    }
  },
}
</script>
<style lang="scss" scoped>
tr > td:last-child {
  word-break: break-all;
}
</style>
