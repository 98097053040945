<template>
  <v-btn large class="mr-2" @click="downloadExcel">
    등록용 엑셀 양식 다운로드
  </v-btn>
</template>
<script>
import XLSX from 'xlsx'

export default {
  props: {
    headers: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
    }
  },
  methods: {
    downloadExcel() {
      const headers = this.headers

      let obj = {}
      for (let i = 0; i < headers.length; i++) {
        const field = headers[i].value
        if (field !== 'actions') {
          obj[field] = ''
        }
      }

      const sheet = XLSX.utils.json_to_sheet([obj])
      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, sheet, 'template')
      XLSX.writeFile(
        workbook,
        `${this.$moment().format('YYMMDD_HHmmss')}_template.xlsx`
      )
    },
  },
}
</script>
