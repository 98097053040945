import AuthService from '@/service/auth/AuthService'
import axios from 'axios'
import Vue from 'vue'

const _axios = axios.create({
  baseURL: process.env.common.myfinbizApi.host,
  headers: {
    'Content-Type': 'application/json',
  },
})

_axios.interceptors.request.use(
  (config) => {
    if (config.isInterceptor !== false) {
      if (
        config.headers.Authorization == null ||
        config.headers.Authorization.startsWith('Basic') === false
      ) {
        let token = localStorage.getItem('myfinbiz_token')
        if (token != null) {
          config.headers.Authorization = `Bearer ${token}`
        }
      }
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

_axios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response?.status === 401) {
      Vue.prototype.$store.dispatch('myfinbiz/auth/logout')
      Vue.prototype.$router.push({ name: 'myfinbiz/login' })
    }
    return Promise.reject(error.response)
  }
)

_axios.call = async function (option) {
  if (option.data != null && option.data.isAwait === false) {
    delete option.data.isAwait
  } else {
    await AuthService.sync()
  }
  const config = {
    ...option,
    url: option.uri.startsWith('/graphql') ? process.env.common.myfinbizApi.graphqlEndpoint : option.uri,
    headers: {
      ...option.headers,
      'Content-Type': 'application/json',
    }
  }
  delete config.uri

  return _axios(config)
}

export default _axios
