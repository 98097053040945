import fundaApi from '@/plugins/api/api'
import store from '@/store'
import { InvestmentTypes } from './types/investment'

export default {
  name: 'www/investment',
  namespaced: true,
  state: {
    isUserPureLoading: false,
    userPure: 0,
    isUserP2PLoading: false,
    userP2P: 0,
    isUserInvestmentLoading: false,
    userInvestment: null,
    isProfitLossLoading: false,
    profitLoss: null,
    isUserEarningLoading: false,
    userEarning: {
      totalInvestmentAmount: 0,
      earningsRate: 0,
      earningsRateAfterCost: 0,
      donePrincipal: 0,
      doneInterest: 0,
      doneOverdueInterest: 0,
      doneAdditionalInterest: 0,
      doneTax: 0,
      doneFee: 0,
      scheduleInterest: 0,
      scheduleOverdueInterest: 0,
      scheduleAdditionalInterest: 0,
      scheduleTax: 0,
      scheduleFee: 0,
      lostPrincipal: 0,

      totalSchedulePrincipal: 0,
      totalPrincipal: 0,
    },
    isRepaymentLoading: false,
    isInsolventAmountLoading: false,
    insolventAmount: 0,
    isLastInvestmentLoading: false,
    lastInvestment: null,
    isUserCntLoading: false,
    userCnt: {
      cntTotal: 0,
      cntWaiting: 0,
      cntDoneInvest: 0,
      cntRepay: 0,
      cntOverdue: 0,
      cntInsolvent: 0,
      cntDoneRepay: 0,
    },
  },
  getters: {
    [InvestmentTypes.getters.GET_USER_PURE](state) {
      return state.userPure
    },
    [InvestmentTypes.getters.GET_USER_P2P](state) {
      return state.userP2P
    },
    [InvestmentTypes.getters.GET_USER_INVESTMENT_LOADING](state) {
      return state.isUserInvestmentLoading
    },
    [InvestmentTypes.getters.GET_USER_INVESTMENT](state) {
      return state.userInvestment
    },
    [InvestmentTypes.getters.GET_PROFIT_LOSS_LOADING](state) {
      return state.isProfitLossLoading
    },
    [InvestmentTypes.getters.GET_PROFIT_LOSS](state) {
      return state.profitLoss
    },
    [InvestmentTypes.getters.GET_USER_EARNING_LOADING](state) {
      return state.isUserEarningLoading
    },
    [InvestmentTypes.getters.GET_USER_EARNING](state) {
      return state.userEarning
    },
    [InvestmentTypes.getters.GET_REPAYMENT_LOADING](state) {
      return state.isRepaymentLoading
    },
    [InvestmentTypes.getters.GET_INSOLVENT_AMOUNT_LOADING](state) {
      return state.isInsolventAmountLoading
    },
    [InvestmentTypes.getters.GET_INSOLVENT_AMOUNT](state) {
      return state.insolventAmount
    },
    [InvestmentTypes.getters.GET_LAST_INVESTMENT_LOADING](state) {
      return state.isLastInvestmentLoading
    },
    [InvestmentTypes.getters.GET_LAST_INVESTMENT](state) {
      return state.lastInvestment
    },
    [InvestmentTypes.getters.GET_USER_CNT_LOADING](state) {
      return state.isUserCntLoading
    },
    [InvestmentTypes.getters.GET_USER_CNT](state) {
      return state.userCnt
    },
  },
  mutations: {
    [InvestmentTypes.mutations.SET_USER_PURE_LOADING](state, v) {
      state.isUserPureLoading = v
    },
    [InvestmentTypes.mutations.SET_USER_PURE](state, v) {
      state.userPure = v
    },
    [InvestmentTypes.mutations.SET_USER_P2P_LOADING](state, v) {
      state.isUserP2PLoading = v
    },
    [InvestmentTypes.mutations.SET_USER_P2P](state, v) {
      state.userP2P = v
    },
    [InvestmentTypes.mutations.SET_USER_INVESTMENT_LOADING](state, v) {
      state.isUserInvestmentLoading = v
    },
    [InvestmentTypes.mutations.SET_USER_INVESTMENT](state, v) {
      state.userInvestment = v
    },
    [InvestmentTypes.mutations.SET_PROFIT_LOSS_LOADING](state, v) {
      state.isProfitLossLoading = v
    },
    [InvestmentTypes.mutations.SET_PROFIT_LOSS](state, v) {
      state.profitLoss = v
    },
    [InvestmentTypes.mutations.SET_USER_EARNING_LOADING](state, v) {
      state.isUserEarningLoading = v
    },
    [InvestmentTypes.mutations.SET_USER_EARNING](state, v) {
      state.userEarning = v
    },
    [InvestmentTypes.mutations.SET_REPAYMENT_LOADING](state, v) {
      state.isRepaymentLoading = v
    },
    [InvestmentTypes.mutations.SET_INSOLVENT_AMOUNT_LOADING](state, v) {
      state.isInsolventAmountLoading = v
    },
    [InvestmentTypes.mutations.SET_INSOLVENT_AMOUNT](state, v) {
      state.insolventAmount = v
    },
    [InvestmentTypes.mutations.SET_LAST_INVESTMENT_LOADING](state, v) {
      state.isLastInvestmentLoading = v
    },
    [InvestmentTypes.mutations.SET_LAST_INVESTMENT](state, v) {
      state.lastInvestment = v
    },
    [InvestmentTypes.mutations.SET_USER_CNT_LOADING](state, v) {
      state.isUserCntLoading = v
    },
    [InvestmentTypes.mutations.SET_USER_CNT](state, v) {
      state.userCnt = v
    },
  },
  actions: {
    async [InvestmentTypes.actions.GET_USER_PURE]({ commit, state }, userIdx) {
      if (state.isUserPureLoading) {
        return
      }

      if (!!!userIdx) {
        return
      }

      commit(InvestmentTypes.mutations.SET_USER_PURE_LOADING, true)

      const q = `pureInvestment: getPureInvestment(userIdx: ${userIdx})`
      let pureInvestment = 0

      try {
        const pure = await fundaApi.query(gql`{${q}}`)
        pureInvestment = pure.pureInvestment
      } finally {
        commit(InvestmentTypes.mutations.SET_USER_PURE, pureInvestment)
        commit(InvestmentTypes.mutations.SET_USER_PURE_LOADING, false)
      }
    },
    async [InvestmentTypes.actions.GET_USER_P2P]({ commit, state }, userIdx) {
      if (state.isUserP2PLoading) {
        return
      }

      if (!!!userIdx) {
        return
      }

      commit(InvestmentTypes.mutations.SET_USER_P2P_LOADING, true)

      const q = `p2pInvestment: getP2PInvestment(userIdx: ${userIdx})`
      let p2pInvestment = 0

      try {
        const p2p = await fundaApi.query(gql`{${q}}`)
        p2pInvestment = p2p.p2pInvestment
      } catch {
        p2pInvestment = -999
      } finally {
        commit(InvestmentTypes.mutations.SET_USER_P2P, p2pInvestment)
        commit(InvestmentTypes.mutations.SET_USER_P2P_LOADING, false)
      }
    },
    async [InvestmentTypes.actions.GET_USER_INVESTMENT](
      { commit, state, getters },
      userIdx
    ) {
      if (state.isUserInvestmentLoading) {
        return
      }

      commit(InvestmentTypes.mutations.SET_USER_INVESTMENT_LOADING, true)

      const q1 = store.dispatch(
        'www/investment/' + InvestmentTypes.actions.GET_USER_PURE,
        userIdx
      )
      const q2 = store.dispatch(
        'www/investment/' + InvestmentTypes.actions.GET_USER_P2P,
        userIdx
      )

      await q1
      await q2

      const pureInvestment = getters[InvestmentTypes.getters.GET_USER_PURE]
      let p2pInvestment = getters[InvestmentTypes.getters.GET_USER_P2P]

      if (p2pInvestment === -999) {
        p2pInvestment = pureInvestment
      }

      const sum = pureInvestment + p2pInvestment

      const userInvestment = {
        pure: pureInvestment,
        p2p: p2pInvestment,
        total: sum,
      }

      commit(InvestmentTypes.mutations.SET_USER_INVESTMENT, userInvestment)
      commit(InvestmentTypes.mutations.SET_USER_INVESTMENT_LOADING, false)
    },
    async [InvestmentTypes.actions.REMOVE_PROFIT_LOSS]({ commit }) {
      commit(InvestmentTypes.mutations.SET_PROFIT_LOSS, null)
    },
    async [InvestmentTypes.actions.GET_PROFIT_LOSS](
      { commit, state },
      userIdx
    ) {
      if (state.profitLoss || state.isProfitLossLoading) {
        return
      }
      commit(InvestmentTypes.mutations.SET_PROFIT_LOSS, null)
      commit(InvestmentTypes.mutations.SET_PROFIT_LOSS_LOADING, true)

      const q = `
        data: getProfitLoss(userIdx: ${userIdx}, isRealtime: false) {
          totalInterest
          totalTax
          totalFee
          totalInsolvent
          showUpdateTime
          
          earningsRate
          earningsRateAfterCost
        }
      `

      try {
        let data = await fundaApi.query(gql`{${q}}`)

        if (data) {
          data.earningsRate = data.earningsRate.toFixed(2)
          data.earningsRateAfterCost = data.earningsRateAfterCost.toFixed(2)
          commit(InvestmentTypes.mutations.SET_PROFIT_LOSS, data)
        }
      } finally {
        commit(InvestmentTypes.mutations.SET_PROFIT_LOSS_LOADING, false)
      }
    },
    async [InvestmentTypes.actions.GET_USER_EARNING](
      { commit, state },
      userIdx
    ) {
      if (state.isUserEarningLoading) {
        return
      }
      if (!!!userIdx) {
        return
      }

      commit(InvestmentTypes.mutations.SET_USER_EARNING_LOADING, true)

      const q = `
        data: getUserInvestmentBoardByAdmin(userIdx: ${userIdx}) {
          totalInvestmentAmount
          earningsRate
          earningsRateAfterCost
          donePrincipal
          doneInterest
          doneOverdueInterest
          doneAdditionalInterest
          doneTax
          doneFee
          schedulePrincipal
          scheduleInterest
          scheduleOverdueInterest
          scheduleAdditionalInterest
          scheduleTax
          scheduleFee
          lostPrincipal
          createTime
          updateTime

          totalSchedulePrincipal
          totalPrincipal
        }
      `

      try {
        const data = await fundaApi.query(gql`{${q}}`)

        if (!!data) {
          data.earningsRate = data.earningsRate.toFixed(2)
          data.earningsRateAfterCost = data.earningsRateAfterCost.toFixed(2)
          commit(InvestmentTypes.mutations.SET_USER_EARNING, data)
        }
      } finally {
        commit(InvestmentTypes.mutations.SET_USER_EARNING_LOADING, false)
      }
    },
    async [InvestmentTypes.actions.GET_INSOLVENT_AMOUNT](
      { commit, state },
      userIdx
    ) {
      if (state.isInsolventAmountLoading) {
        return
      }
      if (!!!userIdx) {
        return
      }

      commit(InvestmentTypes.mutations.SET_INSOLVENT_AMOUNT_LOADING, true)

      const q = `
        data: getInsolventBalance(userIdx: ${userIdx})
      `

      try {
        let data = await fundaApi.query(gql`{${q}}`)
        commit(InvestmentTypes.mutations.SET_INSOLVENT_AMOUNT, data)
      } finally {
        commit(InvestmentTypes.mutations.SET_INSOLVENT_AMOUNT_LOADING, false)
      }
    },
    async [InvestmentTypes.actions.GET_LAST_INVESTMENT](
      { commit, state },
      userIdx
    ) {
      if (state.isLastInvestmentLoading) {
        return
      }
      if (!!!userIdx) {
        return
      }

      commit(InvestmentTypes.mutations.SET_LAST_INVESTMENT_LOADING, true)

      const q = `
        data: getLastInvestment(userIdx: ${userIdx}) {
          investmentAmount
          pointAmount
          investmentState
          datetime
          isAuto
          investDateDiff
        }
      `

      try {
        let data = await fundaApi.query(gql`{${q}}`)

        if (!!data) {
          commit(InvestmentTypes.mutations.SET_LAST_INVESTMENT, data)
        }
      } finally {
        commit(InvestmentTypes.mutations.SET_LAST_INVESTMENT_LOADING, false)
      }
    },
    async [InvestmentTypes.actions.GET_USER_CNT]({ commit, state }, userIdx) {
      if (state.isUserCntLoading) {
        return
      }

      if (!!!userIdx) {
        return
      }

      const q = `
        data: getInvestmentCounting(userIdx: ${userIdx}) {
          cntTotal
          cntWaiting
          cntDoneInvest
          cntRepay
          cntOverdue
          cntInsolvent
          cntWriteOff
          cntDoneRepay
          cntEarlyRepayment
        }
      `

      commit(InvestmentTypes.mutations.SET_USER_CNT_LOADING, true)

      try {
        let data = await fundaApi.query(gql`{${q}}`)

        if (!!data) {
          data.totalInsolvent = data?.cntInsolvent + data?.cntWriteOff
          commit(InvestmentTypes.mutations.SET_USER_CNT, data)
        }
      } finally {
        commit(InvestmentTypes.mutations.SET_USER_CNT_LOADING, false)
      }
    },
  },
}
