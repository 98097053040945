<template>
  <div>
    <v-row v-if="totalCount > 0" no-gutters>
      <v-col cols="12" sm="12" md="12" lg="12" class="text-center">
        <div class="inv-pagination d-block">
          <div
            class="paging-remote position--absolute align-self-end"
            style="position: unset"
          >
            <button
              class="btn-funda-basic btn-paging__prev font-weight-black"
              @click="changePage(--cPage)"
              :disabled="dataLoading"
              :style="isWhite ? '' : 'background-color: #f6f8fa !important'"
            >
              &lt;
            </button>
            <input
              class="margin-x-10 text-center font-weight-black"
              v-model="cPage"
              type="text"
              :disabled="dataLoading"
              :style="isWhite ? '' : 'background-color: #f6f8fa !important'"
              @keyup.enter="changePage()"
              @input="inputOnlyNumber"
            />
            <button
              class="btn-funda-basic btn-paging__next font-weight-black"
              @click="changePage(++cPage)"
              :disabled="dataLoading"
              :style="isWhite ? '' : 'background-color: #f6f8fa !important'"
            >
              >
            </button>
            <span class="margin-l-5"> / {{ lastPage }}</span>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    page: {
      type: Number,
      default: 1,
    },
    size: {
      type: Number,
      default: 1,
    },
    totalCount: {
      type: Number,
      default: 0,
    },
    rawData: {
      type: Array,
      default() {
        return []
      },
    },
    dataLoading: {
      type: Boolean,
      default: false,
    },
    isReverse: {
      type: Boolean,
      default: false,
    },
    isWhite: {
      type: Boolean,
      default: true,
    },
  },
  data: function () {
    return {
      cData: null,
      cPage: this.page,
      cTotalCount: this.totalCount,
      lastPage: 1,
      offset: 0,
      dataLength: this.size,
    }
  },
  created: function () {
    if (this.cTotalCount === 0) {
      this.cTotalCount = this.rawData.length
    }
    this.lastPage = Math.ceil(this.cTotalCount / this.size)
    if (this.lastPage < 1) {
      this.lastPage = 1
    }
    this.changePage(this.cPage)
  },
  methods: {
    inputOnlyNumber() {
      const num = this.cPage
      if (num != null && num.length > 0) {
        this.cPage = Number(num.replace(/[^\d]/g, ''))
      }
    },
    changePage(page) {
      if (!!!page) {
        page = this.cPage
      }

      page = parseInt(page, 10)

      if (isNaN(page)) {
        page = 1
      }

      if (page < 1) {
        page = 1
      } else if (page > this.lastPage) {
        page = this.lastPage
      }

      this.cPage = page
      if (this.isReverse === false) {
        this.offset = (page - 1) * this.size
        this.getDataLength = this.offset === 0 ? this.size : this.size * page

        this.cData = this.rawData.slice(this.offset, this.getDataLength)
      } else {
        // isReverse prop가 true면 데이터를 역순으로 페이징 처리
        this.offset = this.cTotalCount - this.size * page
        let arr = this.rawData.slice(
          this.offset < 0 ? 0 : this.offset,
          this.offset + this.size
        )
        this.cData = arr.reverse()
      }

      this.$emit('currentPage', page)
      this.$emit('currentData', this.cData)
    },
  },
}
</script>

<style lang="scss" scoped></style>
