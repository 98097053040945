import fundaApi from '@/plugins/api/api'

const countStore = {
  name: 'count',
  namespaced: true,
  state: {
    investorAuthLoading: false,
    investorAuth: 0,
    repaymentAuthLoading: false,
    repayment: 0,
  },
  getters: {
    getInvestorAuth(state) {
      return state.investorAuth
    },
  },
  mutations: {
    setInvestorAuth(state, count) {
      state.investorAuth = count
    },
    setRepayment(state, count) {
      state.repayment = count
    },
  },
  actions: {
    async getInvestorAuth({ commit }) {
      if (countStore.state.investorAuthLoading === true) {
        return
      }

      let q = 'data: getCountUserAuthList'

      try {
        const data = await fundaApi.query(gql`{${q}}`)
        commit('setInvestorAuth', data)
      } finally {
        countStore.state.investorAuthLoading = false
      }
    },
  },
}

export default countStore
