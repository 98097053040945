<template>
  <div>
    <PageHeader title="알람 관리" />

    <v-row no-gutters class="mb-10">
      <v-col cols="12">
        <v-list>
          <v-list-group no-action :value="true">
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>
                  <h2 class="black--text text-center">
                    알람 내용 확인 및 수정
                  </h2>
                </v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item class="pa-4">
              <v-row class="mt-3" no-gutters>
                <v-col cols="4" class="pr-2 text-center">
                  <p>고객에게 보이는 화면</p>

                  <div class="alarm-board">
                    <div class="alarm-box">
                      <p class="alarm-box__category" :style="categoryColor">
                        [ {{ categoryText }} ]
                      </p>
                      <div class="alarm-box__contents">
                        <v-badge class="alarm-box__unread" color="pink" dot>
                        </v-badge>
                        <div v-html="convertedContents" class="mb-1"></div>
                        <p class="alarm-box__create-time">
                          {{ createTime | dateFormat }}
                        </p>
                      </div>
                    </div>

                    <p class="mt-3 mb-0 text-left">
                      설정된 링크:
                      <a
                        v-if="convertedLink"
                        :href="convertedLink"
                        target="_blank"
                      >
                        {{ convertedLinkText }}
                      </a>
                    </p>
                  </div>

                  <v-row no-gutters class="mt-3">
                    <v-col cols="5">
                      <v-btn
                        color="warning"
                        large
                        block
                        :loading="sendLoading"
                        :disabled="sendDisabled"
                        @click="sendAlarm()"
                      >
                        알람 전송!!
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="8" class="px-2 text-center">
                  <p>알람 컨텐츠 설정</p>

                  <v-row no-gutters>
                    <v-col cols="4" class="pr-1">
                      <p class="mb-2">카테고리 설정</p>
                      <v-select
                        v-model="alarm.alarmCategoryIdx"
                        outlined
                        hide-details
                        :items="categoryList"
                        item-text="name"
                        item-value="idx"
                      ></v-select>
                      <p class="mt-5 mb-2">링크 설정</p>
                      <v-text-field
                        v-model="alarm.link"
                        outlined
                        hide-details
                      ></v-text-field>
                      <p class="font-size--14 mb-1 text-left">
                        1. 펀다 상세페이지인 경우 슬래시("/")로 시작
                        <br />
                        <span> ex) /myfunda/schedule/detail/#{상품호수} </span>
                      </p>

                      <p class="font-size--14 mb-1 text-left">
                        2. 외부 페이지는 풀링크 다 쓸 것
                        <br />
                        <span> ex) https://bit.ly/3PLLH0c </span>
                      </p>
                    </v-col>

                    <v-col cols="8" class="pl-1">
                      <p class="mb-2">내용 수정</p>

                      <v-textarea
                        v-model="alarm.contents"
                        outlined
                        hide-details
                        full-width
                        rows="7"
                      >
                      </v-textarea>

                      <p class="font-size--14 mb-1 text-left">
                        - 변수는 변수명을 #{} 로 감싸주세요
                        <br />
                        <span> ex) #{유저인덱스} </span>
                      </p>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-list-item>
          </v-list-group>
        </v-list>
        <v-list>
          <hr class="width-100 my-3" style="border-top-width: 5px" />

          <v-list-group no-action :value="true">
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>
                  <h2 class="black--text text-center">대상 및 변수 설정</h2>
                </v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item class="pa-4">
              <v-row class="mt-3" no-gutters>
                <v-col cols="12" class="mb-2">
                  <v-checkbox
                    v-model="isAll"
                    hide-details
                    class="my-0"
                    label="모든 유저를 대상으로 설정합니다"
                  ></v-checkbox>
                </v-col>
                <v-col cols="4" class="pr-1">
                  <div class="mb-4">
                    <v-row no-gutters>
                      <v-col cols="5">
                        <ExcelReader
                          label="엑셀 업로드"
                          block
                          :disabled="isAll"
                          :readExcel.sync="excelData"
                        ></ExcelReader>
                      </v-col>
                      <v-col cols="2"> </v-col>
                      <v-col cols="5">
                        <v-btn block large dark @click="excelData = null">
                          목록 초기화
                        </v-btn>
                      </v-col>
                    </v-row>

                    <p class="font-size--14 font-weight-bold my-1 red--text">
                      - A열은 반드시 유저인덱스여야 합니다
                    </p>
                    <p class="font-size--14 mb-0">
                      - B열부터 변수이며 총 4개까지 설정할 수 있습니다
                    </p>
                  </div>
                  <div>
                    <hr class="width-100 my-5" style="border-top-width: 3px" />

                    <UserList @selectUser="(v) => addUserList(v)" shortcut />
                  </div>
                </v-col>

                <v-col cols="8" class="pl-1">
                  <v-data-table
                    :headers="variables"
                    :items="receiverList"
                    :items-per-page="30"
                    hide-default-header
                  >
                    <template v-slot:header="{ props: { headers } }">
                      <thead>
                        <tr>
                          <th
                            v-for="(variable, index) in headers"
                            :key="`variable_${index}`"
                            width="120px"
                            class="text-center"
                          >
                            <p class="font-size--15 my-0">
                              {{
                                index === 0 ? '유저인덱스' : variables[index]
                              }}
                            </p>
                          </th>
                        </tr>
                      </thead>
                    </template>

                    <template v-slot:body="{ items }">
                      <tbody>
                        <tr
                          v-for="receiver in items"
                          :key="receiver[primaryKey]"
                          @click="selectedReceiver = receiver"
                        >
                          <td
                            v-for="k in variables"
                            class="text-center"
                            :key="`${receiver[primaryKey]}_${k}`"
                          >
                            {{ receiver[k] }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-list-item>
          </v-list-group>
        </v-list>

        <v-list>
          <hr class="width-100 my-3" style="border-top-width: 5px" />
        </v-list>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ExcelReader from '@/components/common/datatable/ExcelReader.vue'
import UserList from '@/components/funda/user/List.vue'

export default {
  components: {
    ExcelReader,
    UserList,
  },
  data() {
    return {
      categoryList: [],
      categoryColorObj: {
        1: '#1baaf1',
        2: '#f9356a',
        3: '#ff893a',
        4: '#58b147',
      },
      createTime: new Date().toISOString().substr(0, 10),
      alarm: {
        alarmCategoryIdx: 1,
        contents: `<p class="mb-2 font-weight-bold font-size--14 line-height--18">
  * 알림 * 기능이 업데이트 되었습니다!
</p>
<p class="my-0 font-size--13 line-height--18">
  알림 서비스가 오픈되었습니다 <br />
  <a>자세히 보기 ></a>
</p>`,
        link: null,
      },
      excelData: null,
      users: [],
      selectedReceiver: null,
      isAll: false,
      sendLoading: false,
    }
  },
  computed: {
    selectedCategory() {
      return this.categoryList[this.alarm.alarmCategoryIdx - 1]
    },
    categoryText() {
      return this.selectedCategory?.name
    },
    categoryColor() {
      // const color = this.selectedCategory?.colorCode
      const color = this.categoryColorObj[this.alarm.alarmCategoryIdx]
      return `color: ${color}`
    },
    variables() {
      let temp = this.excelData?.header || [
        '유저인덱스',
        '변수1',
        '변수2',
        '변수3',
        '변수4',
      ]
      temp = temp.slice(0, 5)

      return temp
    },
    primaryKey() {
      return this.variables[0]
    },
    receiverList() {
      let excelValues = this.excelData?.data || []
      excelValues = excelValues.reduce((result, value) => {
        const isInclude = result.some((r) => {
          return r[this.primaryKey] === value[this.primaryKey]
        })

        if (isInclude === false) {
          result.push(value)
        }

        return result
      }, [])

      return [...this.users, ...excelValues]
    },
    convertedContents() {
      let temp = this.alarm.contents
      if (this.selectedReceiver === null || this.receiverList.length < 1) {
        return temp
      }

      temp = this.convertText(temp, this.variables, this.selectedReceiver)
      return temp
    },
    convertedLinkText() {
      let temp = this.alarm.link
      if (this.selectedReceiver === null || this.receiverList.length < 1) {
        return temp
      }

      temp = this.convertText(temp, this.variables, this.selectedReceiver)
      return temp
    },
    convertedLink() {
      if (this.convertedLinkText === null) {
        return null
      }

      if (this.convertedLinkText.startsWith('http')) {
        return this.convertedLinkText
      } else {
        return `https://www.funda.kr${this.convertedLinkText}`
      }
    },
    sendDisabled() {
      if (this.sendLoading) {
        return true
      }

      return this.isAll === false && this.receiverList.length < 1
    },
  },
  watch: {
    variables() {
      this.users = []
    },
  },
  created() {
    this.getCategories()
  },
  methods: {
    addUserList(user) {
      if (!!user === false) {
        return
      }

      const has = this.users.some((u) => {
        return u[this.primaryKey] === user.idx
      })

      if (has === false && user) {
        const temp = {}

        this.variables.forEach((v, i) => {
          if (i === 0) {
            temp[v] = user.idx
          } else {
            temp[v] = null
          }
        })
        this.users.push(temp)
      }
    },
    convertText(text, searchValues, replacer) {
      if (!text) {
        return null
      }
      let result = text
      for (let i = 0; i < searchValues.length; i++) {
        const key = i === 0 ? '유저인덱스' : searchValues[i]
        let replaceText = i === 0 ? replacer[this.primaryKey] : replacer[key]
        if (typeof replaceText === 'string') {
          replaceText = replaceText.trim()
        }
        const pattern = `\\#\\{${key}\\}`
        const regex = new RegExp(pattern, 'gi')
        result = result.replace(regex, replaceText)
      }
      return result
    },
    async getCategories() {
      const q = `
        data: getAlarmCategories {
          idx
          name
          description
        }
      `
      const data = await this.$fundaApi.query(gql`{${q}}`)
      this.categoryList = data
    },
    async sendAlarm() {
      const text = `총 ${this.receiverList.length} 건의 알람을 전송합니다`
      const confirm = await this.$swal.basic.confirm(text)

      if (confirm.isConfirmed === false) {
        return
      }

      this.sendLoading = true

      const params = []
      for (let i = 0; i < this.receiverList.length; i++) {
        const receiver = this.receiverList[i]
        const param = {
          userIdx: receiver[this.primaryKey],
          isRead: false,
          alarmCategoryIdx: this.alarm.alarmCategoryIdx,
          contents: this.convertText(
            this.alarm.contents,
            this.variables,
            receiver
          ),
          link: this.convertText(this.alarm.link, this.variables, receiver),
        }
        params.push(param)
      }

      const q = 'sendUserAlarm(userAlarms: $input)'

      try {
        await this.$fundaApi.mutation(
          gql`{${q}}`,
          'call($input: [InputUserAlarm]!)',
          { input: params }
        )

        await this.$swal.basic.success('알람이 전송되었습니다.')
      } finally {
        this.sendLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.alarm-board {
  background-color: #eee;

  padding: 16px;
}

.alarm-box {
  margin-top: 8px;
  margin-bottom: 15px;

  &__category {
    width: 100%;
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: bold;
    text-align: left;
  }

  &__contents {
    position: relative;
    padding: 16px 15px 28px;
    background-color: #ffffff !important;
    border-radius: 8px;
    box-shadow: 0 8px 16px 0 rgba(23, 25, 26, 0.1);
    cursor: pointer;

    text-align: left;
  }

  &__unread {
    position: absolute;
    top: 15px;
    right: 15px;
  }

  &__create-time {
    position: absolute;
    bottom: 10px;
    right: 15px;
    color: #727d8c;
    margin: 0;
  }
}

thead {
  tr {
    th {
      border: 2px solid #eee;

      padding-top: 16px !important;
      padding-bottom: 16px !important;
    }
  }
}
</style>
