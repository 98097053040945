<template>
  <div id="dashboard-left">
    <div
      v-if="myDashboardData == null"
      class="
        dashboard-box
        mx-md-auto
        px-6
        py-6
        d-flex
        justify-center
        align-center
      "
      style="height: 617px"
    >
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <div v-else class="dashboard-box mx-md-auto px-6 py-6 overflow--hidden">
      <v-row class="pb-5 hr-b-dotted" no-gutters>
        <v-col cols="6" sm="6" md="6" class="color--funda-basic font-size--28">
          <EarningsRateText :user-idx="myDashboardData.userIdx" />
        </v-col>

        <v-col
          cols="6"
          sm="6"
          md="6"
          class="d-flex justify-end align-center text-right"
        >
        </v-col>
      </v-row>

      <v-row class="mt-5 mb-1" no-gutters>
        <v-col cols="5" xs="6" sm="5" md="5">
          <p class="left-key">투자 가능 금액</p>
        </v-col>
        <v-col cols="7" xs="6" sm="7" md="7" class="text-right">
          <span class="right-value color--funda-basic">
            {{ totalBalance | commaFormat }}
          </span>
          <span class="right-value color--black"> 원</span>
        </v-col>
      </v-row>

      <v-row class="mb-1" no-gutters>
        <v-col cols="4" sm="4" md="4">
          <p class="left-key">총 자산</p>
        </v-col>
        <v-col cols="8" sm="8" md="8" class="text-right">
          <span class="right-value color--black">
            {{ totalAsset | commaFormat }}
          </span>
          <span class="right-value color--black"> 원</span>
        </v-col>
      </v-row>

      <v-row class="pb-5 hr-b-dotted" no-gutters>
        <v-col cols="6" sm="4" md="4">
          <p class="left-key">누적 수익금</p>
        </v-col>
        <v-col cols="6" sm="8" md="8" class="text-right">
          <span class="right-value color--black">
            {{ totalEarning | commaFormat }}
          </span>
          <span class="right-value color--black"> 원</span>
        </v-col>
      </v-row>

      <div class="mt-5 mb-5">
        <v-row no-gutters>
          <v-col cols="12" sm="12" md="12">
            <p class="left-subtitle mx-0 mb-2 color--black font-weight-bold">
              투자 가능
            </p>
          </v-col>
        </v-row>

        <v-row class="mb-1" no-gutters>
          <v-col cols="4" sm="4" md="4">
            <p class="left-key">예치금</p>
          </v-col>
          <v-col cols="8" sm="8" md="8" class="text-right">
            <span class="right-value color--gray">
              <BalanceText :user-idx="myDashboardData.userIdx" />
            </span>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="4" sm="4" md="4">
            <p class="left-key">포인트</p>
          </v-col>
          <v-col cols="8" sm="8" md="8" class="text-right">
            <span class="right-value color--gray">
              {{ myDashboardData.sumPointBalance | commaFormat }} 원
            </span>
          </v-col>
        </v-row>
      </div>

      <div class="mb-5">
        <v-row no-gutters>
          <v-col cols="12" sm="12" md="12">
            <p class="left-subtitle mx-0 mb-2 color--black font-weight-bold">
              총 자산
            </p>
          </v-col>
        </v-row>

        <v-row class="mb-1" no-gutters>
          <v-col cols="4" sm="4" md="4">
            <p class="left-key">예치금</p>
          </v-col>
          <v-col cols="8" sm="8" md="8" class="text-right">
            <span class="right-value color--gray">
              <BalanceText :user-idx="myDashboardData.userIdx" />
            </span>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="5" sm="5" md="5">
            <p class="left-key">투자 중 원금</p>
          </v-col>
          <v-col cols="7" sm="7" md="7" class="text-right">
            <span class="right-value color--gray">
              <LoadingText :is-loading="userInvestmentLoading">
                {{ !!userInvestment ? userInvestment.pure : 0 | commaFormat }}
              </LoadingText>
              원
            </span>
          </v-col>
        </v-row>
      </div>

      <div style="margin-bottom: 25px">
        <v-row no-gutters>
          <v-col cols="12" sm="12" md="12">
            <p class="left-subtitle mx-0 mb-2 color--black font-weight-bold">
              누적 수익
            </p>
          </v-col>
        </v-row>

        <v-row class="mb-1" no-gutters>
          <v-col cols="4" sm="4" md="4">
            <p class="left-key">
              이자
              <span class="font-size--12" style="margin-left: -2px">
                (세전)
              </span>
            </p>
          </v-col>
          <v-col cols="8" sm="8" md="8" class="text-right">
            <span class="right-value color--gray">
              <LoadingText :is-loading="userEarningLoading">
                {{ userEarning ? userEarning.totalInterest : 0 | commaFormat }}
              </LoadingText>
              원
            </span>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="4" sm="4" md="4">
            <p class="left-key">포인트 등</p>
          </v-col>
          <v-col cols="8" sm="8" md="8" class="text-right">
            <span class="right-value color--gray">
              {{ totalPoint | commaFormat }}
              원
            </span>
          </v-col>
          <v-col cols="12">
            <span class="font-size--13">
              - [투자가능+사용액]으로 소멸된 포인트는 제외
            </span>
          </v-col>
        </v-row>
      </div>

      <div class="my-limit padding-x-10 padding-y-10 color--gray">
        <InvestmentLimitText
          :myDashboardData="myDashboardData"
          :showDetail="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { InvestmentTypes } from '@/store/www/types/investment'
import EarningsRateText from '@/components/common/text/EarningsRateText.vue'
import LoadingText from '@/components/common/text/LoadingText.vue'
import BalanceText from '@/components/common/text/BalanceText.vue'
import InvestmentLimitText from '@/components/funda/myfunda/InvestmentLimitText.vue'

export default {
  props: {
    myDashboardData: {
      type: Object,
      default: null,
    },
  },
  components: {
    EarningsRateText,
    LoadingText,
    BalanceText,
    InvestmentLimitText,
  },
  data() {
    return {
      totalBalance: 0,
      myLimitType: 'LIMIT',
      myFundaClasses: [],
    }
  },
  computed: {
    ...mapGetters('www/withdraw', {
      withdrawBalance: 'getUserWithdraw',
    }),
    ...mapGetters('www/investment', {
      userInvestmentLoading:
        InvestmentTypes.getters.GET_USER_INVESTMENT_LOADING,
      userInvestment: InvestmentTypes.getters.GET_USER_INVESTMENT,
      userEarningLoading: InvestmentTypes.getters.GET_PROFIT_LOSS_LOADING,
      userEarning: InvestmentTypes.getters.GET_PROFIT_LOSS,
    }),
    totalAsset() {
      let withdraw = this.withdrawBalance ?? 0
      let pure = !!this.userInvestment ? this.userInvestment.pure : 0
      return withdraw + pure
    },
    totalPoint() {
      let v = 0
      if (!!this.myDashboardData) {
        v =
          this.myDashboardData.sumAllPoint -
          this.myDashboardData.sumExpiredPoint
      }

      return v
    },
    totalEarning() {
      const sumInterest = this.userEarning?.totalInterest
      return sumInterest + this.totalPoint
    },
  },
  watch: {
    myDashboardData: {
      immediate: true,
      handler(v) {
        if (!!v) {
          this.setByMyDashBoardData()
        }
      },
    },
    withdrawBalance: {
      immediate: true,
      handler(v) {
        if (this.myDashboardData == null) {
          return
        }
        this.totalBalance = v + this.myDashboardData.sumPointBalance
      },
    },
  },
  created() {
    this.setByMyDashBoardData()
  },
  methods: {
    setByMyDashBoardData() {
      if (!!!this.myDashboardData) {
        return
      }

      this.myFundaClasses = this.myDashboardData.user.userFundaClasses
    },
  },
}
</script>

<style lang="scss" scoped>
.btn__show-detail {
  background-color: #f6f8fa;
  color: #727d8c;
  width: 160px;
  height: 38px;
  border-radius: 20px;

  span img {
    transition: all 0.5s;
  }
}

#dashboard-left:not(.fold) {
  .btn__show-detail span img {
    transform: rotate(180deg);
  }
}

.image__funda-class {
  width: 55px;
}

.left {
  &-subtitle {
    font-size: 16px;
  }

  &-key {
    margin: 0;
    color: #8a94a2;
    font-size: 14px;
    line-height: 1.4;
  }
}

.right {
  &-subtitle {
    font-size: 16px;
    font-weight: 700;
  }

  &-value {
    font-size: 16px;
    font-weight: 700;
  }
}

.my-limit {
  border-radius: 4px;
  background-color: #f6f8fa;

  margin-top: 16px;
  padding: 10px !important;
}
</style>
