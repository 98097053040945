import Vue from 'vue'

import code from './code'
import profile from './profile'
import alimtalk from './alimtalk'
import bizm from './bizm'

const api = {
  ...code,
  ...profile,
  ...alimtalk,
  ...bizm,
}

const pluginName = '$alimtalkApi'

Vue.use({
  install(Vue) {
    window[pluginName] = api
    Vue.prototype[pluginName] = api
  },
})

export default api
