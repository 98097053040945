import fundaApi from '@/plugins/api/api'
import jwtDecode from 'jwt-decode'
import env from './env'

const TOKEN_NAME = env.state.token.name
const REFRESH_TOKEN_NAME = env.state.token.refreshTokenName

const auth = {
  name: 'auth',
  namespaced: true,
  state: {
    info: null,
    refresh: null,
    isProfileLoading: false,
    profile: null,
  },
  getters: {
    isSignin(state) {
      return state.info != null
    },
    getSession(state) {
      return state
    },
    getInfo(state) {
      return state.info
    },
    getProfile(state) {
      return state.profile
    },
    hasAnyRole: (state) => (roles) => {
      if (state.info == null) {
        return false
      }
      if (state.info.authorities == null) {
        return false
      }
      return !!state.info.authorities.filter((obj) => {
        return roles.indexOf(obj) >= 0
      }).length
    },
    getTokenExpireTime(state) {
      if (state.info == null) {
        return null
      }
      return state.info.exp
    },
    getRefreshTokenExpireTime(state) {
      if (state.refresh == null) {
        return null
      }
      return state.refresh.exp
    },
  },
  mutations: {
    signin(state) {
      state.info = jwtDecode(localStorage[TOKEN_NAME])
      state.info.exp *= 1000
      state.refresh = jwtDecode(localStorage[REFRESH_TOKEN_NAME])
      state.refresh.exp *= 1000
    },
    signout(state) {
      for (const k in state) {
        state[k] = null
      }
    },
    setProfileLoading(state, flag) {
      state.isProfileLoading = flag
    },
    setProfile(state, profile) {
      state.profile = profile
    },
  },
  actions: {
    signin({ commit }) {
      commit('signin')
    },
    signout({ commit }) {
      commit('signout')
    },
    async getProfile({ commit, getters }) {
      if (auth.state.isProfileLoading) {
        return
      }

      const info = getters.getInfo
      const userId = info?.email

      if (!userId) {
        return
      }

      const q = `
        data: getUserByUserId(userId: "${userId}") {
          idx
          id
          name
        }
      `

      commit('setProfileLoading', true)

      try {
        const data = await fundaApi.query(gql`{${q}}`)
        commit('setProfile', data)
      } finally {
        commit('setProfileLoading', false)
      }
    },
  },
}

export default auth
