var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.dataTable.headers,"items":_vm.dataTable.items,"options":_vm.dataTable.options,"loading":_vm.dataTable.isLoading,"server-items-length":_vm.dataTable.totalCount,"footer-props":_vm.dataTable.footer},on:{"update:options":function($event){return _vm.$set(_vm.dataTable, "options", $event)}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var id = ref.item.id;
return [_c('router-link',{attrs:{"to":{ name: 'fundanow/repayment/manual/histories/detail', params: { loansRepaymentPreDataId: id } }}},[_vm._v(" "+_vm._s(id)+" ")])]}},{key:"item.incomeAmount",fn:function(ref){
var incomeAmount = ref.item.incomeAmount;
return [_vm._v(" "+_vm._s(_vm._f("commaFormat")(incomeAmount))+" 원 ")]}},{key:"item.repaymentTarget",fn:function(ref){
var repaymentTarget = ref.item.repaymentTarget;
return [_vm._v(" "+_vm._s(_vm._f("commaFormat")(repaymentTarget))+" 원 ")]}},{key:"item.refund",fn:function(ref){
var refund = ref.item.refund;
return [_vm._v(" "+_vm._s(_vm._f("commaFormat")(refund))+" 원 ")]}},{key:"item.userVirtualAccount",fn:function(ref){
var fundaPaygateRepaymentvirtualaccountId = ref.item.fundaPaygateRepaymentvirtualaccountId;
return [_c('TransactionsRouterLink',{attrs:{"userVirtualAccountIdx":fundaPaygateRepaymentvirtualaccountId}})]}},{key:"item.created",fn:function(ref){
var created = ref.item.created;
return [_vm._v(" "+_vm._s(_vm._f("moment")(created,'yyyy-MM-DD HH:mm:ss'))+" ")]}},{key:"item.modified",fn:function(ref){
var modified = ref.item.modified;
return [_vm._v(" "+_vm._s(_vm._f("moment")(modified,'yyyy-MM-DD HH:mm:ss'))+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }