import VueCookies from 'vue-cookies'
const VISIT_PAGES_DATA_NAME = 'rvp'

export default {
  name: 'admin',
  namespaced: true,
  state: {
    visitPages: null,
  },
  getters: {
    getVisitPages(state) {
      return state.visitPages
    },
  },
  mutations: {
    setVisitPages(state, to) {
      // 보여질 메뉴 이름 세팅
      let title = to.meta.title ? to.meta.title : to.name
      let params = Object.getOwnPropertyNames(to.params)
      if (params.length > 0) {
        title +=
          '(' +
          params
            .map((param) => {
              return to.params[param]
            })
            .join('/') +
          ')'
      }

      // 최근 목록 조회
      let recentVisitPages = VueCookies.get(VISIT_PAGES_DATA_NAME)
      // 쿠키에서 localStorage로 이동
      if (recentVisitPages) {
        recentVisitPages = decodeURIComponent(recentVisitPages)
        VueCookies.remove(VISIT_PAGES_DATA_NAME)
        localStorage.setItem(VISIT_PAGES_DATA_NAME, recentVisitPages)
      } else {
        recentVisitPages = localStorage[VISIT_PAGES_DATA_NAME]
      }

      if (recentVisitPages == null) {
        recentVisitPages = []
      } else {
        recentVisitPages = JSON.parse(decodeURIComponent(recentVisitPages))
        recentVisitPages.forEach((page, index) => {
          if (to.name && page.name === to.name) {
            recentVisitPages.splice(index, 1)
          }
        })
      }

      // 현재 페이지 추가
      if (to.path !== '/' && /^\/sign/gi.test(to.path) === false) {
        recentVisitPages.unshift({
          name: to.name,
          params: to.params,
          title,
        })
      }

      // 최대 5개까지만 저장
      if (recentVisitPages.length > 5) {
        recentVisitPages.splice(5)
      }

      localStorage.setItem(
        VISIT_PAGES_DATA_NAME,
        encodeURIComponent(JSON.stringify(recentVisitPages))
      )
      state.visitPages = recentVisitPages
    },
  },
}
