<template>
  <v-container fluid class="pt-0">
    <PageHeader
      title="시스템 계좌 관리"
      subtitle="펀다 내부 거래용 계좌"
      tabMenu
      :right-cols="4"
    >
      <template v-slot:right>
        <v-spacer />
        <v-col>
          <SystemUserAddDialogButton @success="refreshTable" />
        </v-col>
      </template>
    </PageHeader>
    <SystemUsersTable :key="tableKey" />
  </v-container>
</template>
<script>
import SystemUserAddDialogButton from '../../../../components/payment/system/SystemUserAddDialogButton.vue'
import SystemUsersTable from '../../../../components/payment/system/SystemUsersTable.vue'

export default {
  components: { SystemUsersTable, SystemUserAddDialogButton },
  data() {
    return {
      tableKey: 'SystemUsersTable',
    }
  },
  methods: {
    refreshTable() {
      this.tableKey = 'SystemUsersTable' + Date.now()
    },
  },
}
</script>
