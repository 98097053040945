<template>
  <div>
    <v-card class="my-3" width="100%">
      <v-card-text>
        <v-row no-gutters>
          <v-col cols="12" lg="2" md="4" sm="6">
            <v-select
              v-model="param.status"
              :items="fundanowCodes['MailMail.Status']"
              item-text="name"
              item-value="code"
              label="메일상태"
              outlined
              hide-details
              @change="updateOption(param)"
            >
              <template v-slot:prepend-item>
                <v-list-item ripple @click="clear"> 전체 </v-list-item>
              </template>
            </v-select>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-data-table
      :headers="dataTable.headers"
      :items="dataTable.items"
      :options.sync="dataTable.options"
      :loading="dataTable.isLoading"
      :server-items-length="dataTable.totalCount"
      :footer-props="dataTable.footer"
      class="elevation-2"
    >
      <template v-slot:[`item.id`]="{ item: { id } }">
        <router-link
          :to="{
            name: 'fundanow/mails/detail',
            params: { mailId: id },
          }"
        >
          {{ id }}
        </router-link>
      </template>
      <template v-slot:[`item.created`]="{ item: { created } }">
        {{ created | moment('yyyy-MM-DD HH:mm:ss') }}
      </template>
      <template v-slot:[`item.status`]="{ item: { status } }">
        {{ status | fundanow.code('MailMail.Status') }}
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  props: {
    isQueryPaging: {
      type: Boolean,
      default: false,
    },
    query: {
      type: Object,
    },
  },
  computed: {
    ...mapState('fundanow/code', {
      fundanowCodes: 'code',
    }),
  },
  data() {
    return {
      param: {
        page:
          this.isQueryPaging && this.query.page ? Number(this.query.page) : 1,
        size: this.query.size ? Number(this.query.size) : 20,
        status: this.query.status,
      },
      dataTable: {
        isLoading: false,
        options: null,
        headers: [
          { text: 'index', value: 'id', sortable: false },
          { text: '발신주소', value: 'fromAddr', sortable: false },
          { text: '수신주소', value: 'toAddr', sortable: false },
          { text: '제목', value: 'subject', sortable: false },
          { text: '상태', value: 'status', sortable: false },
          { text: '등록 시간', value: 'created', sortable: false },
          { text: '수정 시간', value: 'modified', sortable: false },
        ],
        items: [],
        totalCount: 0,
        footer: {
          'items-per-page-options': [1, 5, 10, 20, 50, 100],
        },
      },
      statuses: [],
    }
  },
  watch: {
    'dataTable.options': {
      handler: function (options, oldValue) {
        if (!oldValue) {
          return
        }
        const optionObj = {
          page: options.page,
          size: options.itemsPerPage,
        }
        this.updateOption(optionObj)
      },
      deep: true,
    },
  },
  created() {
    console.log(this.param)
    this.dataTable.options = {
      page: this.param.page,
      itemsPerPage: this.param.size,
    }
    this.getPageData(this.param)
  },
  methods: {
    updateOption(option) {
      if (this.isQueryPaging === true) {
        return this.$router.push({
          query: option,
        })
      } else {
        this.getPageData(option)
      }
    },
    async getPageData(option) {
      if (this.dataTable.isLoading === true) {
        return
      }
      try {
        this.dataTable.isLoading = true
        const {
          data: {
            paging: { totalCount },
            data,
          },
        } = await this.$fundaNowApi.getMails(option)
        this.dataTable.items = data
        this.dataTable.totalCount = totalCount
      } finally {
        this.dataTable.isLoading = false
      }
    },
    searchParam() {
      this.dataTable.options = Object.assign(
        this.dataTable.options,
        this.param,
        {
          page: 1,
        }
      )
    },
    clear() {
      delete this.param.status
      this.updateOption(this.param)
    },
  },
}
</script>
