<template>
  <PageSection title="연결된 거래" :isLoading="isLoading">
    <template slot="content" v-if="isLoading == false">
      <v-simple-table>
        <template v-slot:default>
          <tbody>
            <template v-if="transaction != null">
              <tr v-for="(value, label, index) in transaction" :key="index">
                <td>{{ label }}</td>
                <td>{{ value }}</td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td class="py-5 text-center subtitle-1 grey--text">
                  연결된 거래 조회 불가
                </td>
              </tr>
            </template>
          </tbody>
        </template>
      </v-simple-table>
    </template>
  </PageSection>
</template>
<script>
export default {
  props: {
    callbackIdx: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      transaction: null,
      isLoading: false,
    }
  },
  async created() {
    this.isLoading = true
    try {
      const { data } =
        await this.$paymentApi.getPaymentTransactionByCallbackIdx(
          this.callbackIdx
        )
      if (data != null && data instanceof Object) {
        this.transaction = data
        this.$emit('change', this.transaction)
      }
    } catch (e) {
      console.error(e)
    } finally {
      this.isLoading = false
    }
  },
}
</script>
