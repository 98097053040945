<template>
  <v-container fluid class="pt-0">
    <PageHeader title="유저 상세" subtitle="유저 상세">
      <template v-slot:right>
        <v-col cols="4" lg="4" mg="4" sm="4" align-self="center" class="pr-0">
          <div class="text-right font-weight-bold">상세 바로가기</div>
        </v-col>
        <v-col cols="4" lg="4" mg="4" sm="4">
          <UserAutocomplete :dense="true" @select="shortcut" />
        </v-col>
        <v-col cols="4" lg="4" mg="4" sm="4">
          <SystemUserSelect
            label="시스템 유저 상세 바로가기"
            :dense="true"
            @change="shortcut({ idx: $event.userIdx })"
          />
        </v-col>
      </template>
    </PageHeader>
    <v-row>
      <v-col lg="4" md="6" sm="6" cols="12">
        <PaymentUser :userIdx="userIdx" />
      </v-col>
      <v-col lg="4" md="6" sm="6" cols="12">
        <PaymentUserWithdrawAccount
          v-if="selectedVirtualAccount"
          :userVirtualAccountIdx="selectedVirtualAccount.idx"
        />
        <PageSection
          class="my-6"
          v-if="selectedVirtualAccount != null"
          :key="selectedVirtualAccount.idx"
          :title="`${
            selectedVirtualAccount.label
              ? selectedVirtualAccount.label
              : selectedVirtualAccount.accountName
          }(${selectedVirtualAccount.idx})가상계좌 잔액 조회`"
        >
          <template v-slot:content>
            <WelcomeBalance
              :userIdx="userIdx"
              :name="
                selectedVirtualAccount.label
                  ? selectedVirtualAccount.label
                  : selectedVirtualAccount.accountName
              "
              :userVirtualAccountIdx="selectedVirtualAccount.idx"
              :paymentType="selectedVirtualAccount.paymentType"
            />
          </template>
        </PageSection>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <PageSection title="사용자 반환 내역">
          <template v-slot:content>
            <UserRefundsTable :query="{ userIdx, size: 5 }" />
          </template>
        </PageSection>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <PageSection title="사용자 대출 내역">
          <template v-slot:content>
            <UserLoansTable :query="{ userIdx, size: 5 }" />
          </template>
        </PageSection>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <PageSection title="사용자 가상계좌 목록">
          <template v-slot:content>
            <PaymentUserVirtualAccounts
              :key="virtualAccountsKey"
              :userIdx="userIdx"
              @select="selectedVirtualAccount = $event"
            />
          </template>
          <template
            v-if="
              selectedVirtualAccount != null && hasAnyRole(['ROLE_SUPERADMIN'])
            "
            v-slot:action
          >
            <v-spacer />
            <span class="mr-4 font-weight-bold">선택한 계좌에서</span>
            <TransferDialogButton
              :propsFromAccount="selectedVirtualAccount"
              @success="virtualAccountsKey++"
            />
            <div class="mr-3"></div>
            <UserRefundDialogButton
              :propsFromAccount="selectedVirtualAccount"
              @success="virtualAccountsKey++"
            />
          </template>
        </PageSection>
      </v-col>
    </v-row>
    <v-slide-y-transition hide-on-leave>
      <v-row
        v-if="selectedVirtualAccount != null"
        :key="selectedVirtualAccount.idx"
      >
        <v-col cols="12">
          <PageSection title="선택된 가상계좌 거래내역">
            <template slot="content">
              <PaymentVirtualAccountTransactions
                :userIdx="selectedVirtualAccount.userIdx"
                :userVirtualAccountIdx="selectedVirtualAccount.idx"
              />
            </template>
          </PageSection>
        </v-col>
      </v-row>
    </v-slide-y-transition>
  </v-container>
</template>

<script>
import PaymentUser from '@/components/payment/user/User'
import PaymentUserWithdrawAccount from '../../../components/payment/user/WithdrawAccount.vue'
import PaymentUserVirtualAccounts from '../../../components/payment/user/VirtualAccounts.vue'
import PaymentVirtualAccountTransactions from '../../../components/payment/PaymentVirtualAccountTransactions.vue'
import TransferDialogButton from '../../../components/payment/user/TransferDialogButton.vue'
import WelcomeBalance from '../../../components/payment/common/WelcomeBalance.vue'
import SystemUserSelect from '../../../components/payment/common/select/SystemUserSelect.vue'
import UserAutocomplete from '../../../components/payment/user/UserAutocomplete.vue'
import UserRefundsTable from '../../../components/payment/user/refund/UserRefundsTable.vue'
import UserRefundDialogButton from '../../../components/payment/user/refund/UserRefundDialogButton.vue'
import UserLoansTable from '../../../components/payment/user/loan/UserLoansTable.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    PaymentUser,
    PaymentUserWithdrawAccount,
    PaymentUserVirtualAccounts,
    PaymentVirtualAccountTransactions,
    TransferDialogButton,
    WelcomeBalance,
    SystemUserSelect,
    UserAutocomplete,
    UserRefundsTable,
    UserRefundDialogButton,
    UserLoansTable,
  },
  props: {
    userIdx: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      selectedVirtualAccount: null,
      virtualAccountsKey: 0,
    }
  },
  created() {
    console.log('created', this.$route.params)
  },
  computed: {
    ...mapGetters('auth', ['hasAnyRole']),
  },
  methods: {
    shortcut(e) {
      if (e == null) {
        return
      }
      this.$router.push({ params: { userIdx: e.idx } })
    },
  },
}
</script>
