<template>
  <ValidationObserver ref="validForm" v-slot="{ handleSubmit, invalid }">
    <v-form @submit.prevent="handleSubmit(submit)">
      <!-- formdata: {{ JSON.stringify(form) }} -->
      <v-row>
        <v-col cols="12" class="px-4">
          <BankSelect
            v-model="form.bankCode"
            :bankCode.sync="form.bankCode"
            :isLoading="disabled"
            :isAllSelectable="true"
            outlined
            dense
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="px-4">
          <CommonDateTimePicker
            :dateTime.sync="form.startTime"
            label="점검 시작 시간 선택"
            :readonly="disabled"
          />
          <p
            v-if="!form.startTime || form.startTime.length < 2"
            class="mb-n2 font-size--13"
          >
            점검 시작 시간을 선택해주세요.
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="px-4">
          <CommonDateTimePicker
            :dateTime.sync="form.endTime"
            label="점검 종료 시간 선택"
            :readonly="disabled"
          />
          <p
            v-if="!form.endTime || form.endTime.length < 2"
            class="mb-n2 font-size--13"
          >
            점검 종료 시간을 선택해주세요.
          </p>
        </v-col>
      </v-row>
      <p v-if="computeInspectionTimeRangeText != null" class="error--text pl-2">
        {{ computeInspectionTimeRangeText }}
      </p>
      <p v-if="!isValidTimeRange" class="error--text pl-2">
        종료시간을 시작시간 이후로 설정해주세요.
      </p>
      <v-row>
        <v-col cols="12" class="px-4">
          <ValidationProvider
            v-slot="{ errors }"
            name="title"
            rules="required|min:2"
          >
            <v-text-field
              v-model="form.title"
              outlined
              dense
              label="제목"
              :readonly="disabled"
              :error-messages="errors"
              :hide-details="errors.length == 0"
            />
          </ValidationProvider>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="px-4">
          <ValidationProvider
            v-slot="{ touched, errors }"
            name="content"
            rules="required"
          >
            <v-textarea
              v-model="form.content"
              name="content"
              label="내용"
              placeholder="내용을 입력하세요"
              outlined
              :clearable="!disabled"
              no-resize
              auto-grow
              :readonly="disabled"
              :error-messages="touched == false ? [] : errors"
            />
          </ValidationProvider>
        </v-col>
      </v-row>
      <v-row v-if="!orgData || !disabled">
        <v-col>
          <v-btn
            color="primary"
            type="submit"
            block
            :disabled="disabled || invalid || !isSubmitable"
          >
            {{ orgData ? '수정 사항 저장하기' : '등록하기' }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </ValidationObserver>
</template>
<script>
import { simpleClone } from '@/util'
import CommonDateTimePicker from '@/components/common/form/CommonDateTimePicker.vue'
import BankSelect from '@/components/payment/common/select/BankSelect.vue'

export default {
  components: { CommonDateTimePicker, BankSelect },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    // 원본 폼데이터 있는 경우
    orgData: {
      type: Object,
      default: (_) => {
        return null
      },
    },
  },
  data() {
    return {
      form: {
        bankCode: null,
        startTime: null,
        endTime: null,
        title: null,
        content: null,
      },
    }
  },
  computed: {
    /**
     * 폼 제출 가능 여부
     */
    isSubmitable() {
      return (
        this.form.bankCode &&
        this.form.startTime &&
        this.form.endTime &&
        this.isValidTimeRange
      )
    },
    /**
     * 유효한 시간범위인지
     */
    isValidTimeRange() {
      if (!this.form.startTime || !this.form.endTime) {
        return true
      }
      return this.$moment(this.form.endTime).isAfter(
        this.$moment(this.form.startTime)
      )
    },
    /**
     * 점검 기간 계산
     */
    computeInspectionTimeRangeText() {
      if (
        !this.form.startTime ||
        !this.form.endTime ||
        !this.isValidTimeRange
      ) {
        return null
      }

      let text =
        '※ 점검 기간: ' +
        this.getDiffTimeText(this.form.startTime, this.form.endTime)

      // 아직 시작 안된 경우 몇시간 후 시작인지 추가
      if (this.$moment(this.form.startTime).diff(new Date()) > 0) {
        text += ` (${this.getDiffTimeText(
          new Date(),
          this.form.startTime
        )} 후 시작)`
      }

      return text
    },
  },
  watch: {
    disabled(value) {
      // 조회해온 데이터가 있으면서 비활성화로 변경되는 경우(=> 수정취소case)
      if (value === true && this.orgData) {
        this.initFormFromReadData()
      }
    },
  },
  created() {
    if (this.orgData) {
      this.initFormFromReadData()
    }
  },
  methods: {
    /**
     * 처음 조회해온 데이터로 폼 초기화
     */
    initFormFromReadData() {
      this.form = simpleClone(this.orgData)
    },
    /**
     * 폼 제출 이벤트 emit
     */
    submit() {
      if (!this.isSubmitable) {
        return
      }
      this.$emit('submit', this.form)
    },
    /**
     * 시간 차이 dd일 hh시간 mm분 텍스트 만들기
     */
    getDiffTimeText(startTime, endTime) {
      const start = this.$moment(startTime)
      const end = this.$moment(endTime)

      return `${end.diff(start, 'days')}일
      ${end.diff(start, 'hours') % 24}시간 
      ${end.diff(start, 'minutes') % 60}분`
    },
  },
}
</script>
