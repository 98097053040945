<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12" v-show="false">
        <TemplateSelect :profileIdx="1" @change="template = $event" />
      </v-col>
      <v-col cols="12" md="6" class="pr-2">
        <v-list>
          <v-list-group no-action :value="true">
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>
                  <h2 class="black--text text-center">알림톡 미리보기</h2>
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item class="d-block pa-0">
              <div>
                <TemplatePreview
                  v-if="preview !== null"
                  :key="'preview_' + sendKey"
                  :template="preview"
                  :variables="variables"
                />
              </div>

              <div v-show="false">
                <AlimtalkSendButton
                  v-if="preview !== null"
                  ref="authTalkSendButton"
                  :receivers="receivers"
                  :template="preview"
                  talkTitle="인증 승인 알림톡"
                />
              </div>
            </v-list-item>
          </v-list-group>
        </v-list>
      </v-col>

      <v-col cols="12" md="6"> </v-col>
    </v-row>
  </div>
</template>

<script>
import TemplateSelect from '@/components/alimtalk/template/TemplateSelect.vue'
import TemplatePreview from '@/components/alimtalk/template/TemplatePreview.vue'
import AlimtalkSendButton from '@/components/alimtalk/form/AlimtalkSendButton.vue'

export default {
  components: {
    TemplateSelect,
    TemplatePreview,
    AlimtalkSendButton,
  },
  data() {
    return {
      userName: null,
      userPhone: null,
      template: null,
      preview: null,
      variables: null,
      templateStr: null,
      form: {},
      receivers: null,
    }
  },
  computed: {
    sendKey() {
      return `${this.userName}${this.userPhone}`
    },
  },
  watch: {
    template: {
      handler(v) {
        if (v === null) {
          return
        }

        if (v?.name !== '인증승인완료') {
          return
        }

        // 변수 목록 다시 계산
        if (v != null) {
          this.variables = this.getVariables(v)
        } else {
          this.variables = null
        }

        this.preview = v
        this.templateStr = JSON.stringify(v)
      },
    },
    sendKey() {
      this.insertRow()
    },
  },
  methods: {
    setUser(userName, userPhone) {
      this.userName = userName
      this.userPhone = userPhone
    },
    send() {
      this.$refs.authTalkSendButton.send(false)
    },
    getVariables(template) {
      const templateStr = JSON.stringify(template)
      let variables = templateStr.match(/#{([^{}]+)}/g)

      if (variables == null) {
        return []
      }

      variables = variables.map((variable) => variable.replace(/[#{}]/g, ''))
      return Array.from(new Set(variables))
    },
    insertRow() {
      this.form.mobileNo = this.userPhone
      this.variables.forEach((v) => {
        // 알림톡 컴포넌트가 넘 어렵게 되어 있어서 가져다 쓰긴 넘 빡세고 걍 하드코딩한다
        if (v === '이름') {
          this.form[v] = this.userName
        }
      })
      this.form.replacedTemplate = this.getReplacedTemplate(this.template)

      this.preview = this.form.replacedTemplate
      this.receivers = [this.form]
    },
    getReplacedTemplate() {
      let replacedStr = this.templateStr
      this.variables.forEach((key) => {
        let regex = new RegExp(`#{${key}}`, 'g')
        replacedStr = replacedStr.replace(regex, this.form[key])
      })
      return JSON.parse(replacedStr)
    },
  },
}
</script>

<style lang="scss" scoped></style>
