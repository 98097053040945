<template>
  <v-container fluid class="pt-0">
    <PageHeader
      title="수기 상환 내역 상세"
      subtitle="상세"
    >
    </PageHeader>
    <RepaymentTransactionsTable
      :loansRepaymentPreDataId="loansRepaymentPreDataId"
    />
  </v-container>
</template>
<script>
import RepaymentTransactionsTable from '@/components/fundanow/repayment/manual/RepaymentTransactionsTable.vue'
export default {
  components: { RepaymentTransactionsTable },
  props: {
    loansRepaymentPreDataId: {
      type: Number,
      required: true
    }
  },
}
</script>
