<template>
  <div v-if="isLoading == false" class="d-flex justify-end align-center">
    <div class="text-center mr-3">
      <p class="mb-0">포인트 잔액 : {{ balance | commaFormat }} 원</p>
      <v-chip small
        >알림톡 예상 발송량 :
        {{ parseInt(balance / 8, 10) | commaFormat }}건</v-chip
      >
    </div>
    <v-btn
      link
      href="https://www.bizmsg.kr/myinfo/charge"
      target="_blank"
      color="warning"
      class="font-weight-bold"
    >
      충전하기
    </v-btn>
  </div>
  <div v-else>
    <v-progress-circular indeterminate size="20" color="grey" />
  </div>
</template>
<script>
export default {
  data() {
    return {
      balance: null,
      isLoading: false,
    }
  },
  async created() {
    this.isLoading = true
    try {
      const { data } = await this.$alimtalkApi.getBizmBalance()
      this.balance = data
    } finally {
      this.isLoading = false
    }
  },
}
</script>
