// import fs from 'fs'
import Vue from 'vue'
import Vuex from 'vuex'
import snackbar from './modules/snackbar'

const modules = [
  require('./auth'),
  require('./env'),
  require('./time'),
  require('./errors'),
  require('./ui'),
  require('./alimtalk/code'),
  require('./alimtalk/messageGroups'),
  require('./payment/bank'),
  require('./payment/code'),
  require('./payment/system'),
  require('./system'),
  require('./admin'),
  require('./www/withdraw'),
  require('./www/investment'),
  require('./count'),
  require('./fundanow/code'),
  require('./marketing/loan'),
  require('./modules/myfinbiz/auth'),
].reduce((map, module) => {
  const d = module.default
  map[d.name] = d
  return map
}, {})

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    ...modules,
    snackbar
  }
})
