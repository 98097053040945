import client from '../client'
import qs from 'query-string'

export default {
  /**
   * 프로필 목록 조회
   */
  getProfiles() {
    return client.call({
      url: '/profiles',
      method: 'GET',
    })
  },
  getTemplates(profileIdx, param) {
    return client.call({
      url: `/profiles/${profileIdx}/templates?${qs.stringify(param)}`,
      method: 'GET',
    })
  },
  getProfilesDetailTemplate(profileIdx, profileTemplateIdx) {
    return client.call({
      url: `/profiles/${profileIdx}/templates/${profileTemplateIdx}`,
      method: 'GET',
    })
  },
  postProfilesTemplates(profileIdx, data) {
    return client.call({
      url: `/profiles/${profileIdx}/templates`,
      method: 'POST',
      data: data,
    })
  },
  getSearchTemplates(param) {
    return client.call({
      url: `/profiles/templates?${qs.stringify(param)}`,
      method: 'GET',
    })
  },
  getProfileTemplate(profileTemplateIdx) {
    return client.call({
      url: `/profiles/templates/${profileTemplateIdx}`,
      method: 'GET',
    })
  },
  putProfileTemplate(profileIdx, profileTemplateIdx, form) {
    return client.call({
      url: `/profiles/${profileIdx}/templates/${profileTemplateIdx}`,
      method: 'PUT',
      data: form,
    })
  },
}
