<template>
  <div v-if="value !== null">
    <template v-if="field === 'text'">
      <v-text-field
        v-if="!!filter"
        :value="value | datetimeFormat"
        :disabled="disabled"
        :hint="!!hint ? hint : ''"
        :persistent-hint="!!hint"
        :hide-details="!!!hint"
        filled
        dense
      ></v-text-field>

      <v-text-field
        v-else
        :value="value"
        :disabled="disabled"
        :hint="!!hint ? hint : ''"
        :persistent-hint="!!hint"
        :hide-details="!!!hint"
        filled
        dense
        @input="$emit('input', $event)"
      ></v-text-field>
    </template>

    <template v-if="field === 'textarea'">
      <v-textarea
        :value="value"
        :disabled="disabled"
        :hint="!!hint ? hint : ''"
        :persistent-hint="!!hint"
        :hide-details="!!!hint"
        filled
        dense
        rows="3"
        class="mb-2"
      ></v-textarea>
    </template>

    <template v-else-if="field === 'checkbox'">
      <v-switch
        v-model="model"
        :disabled="disabled"
        hide-details
        dense
        class="ma-2"
        @change="$emit('input', $event)"
      ></v-switch>
    </template>

    <template v-else-if="field === 'select'">
      <v-select
        :value="value"
        :items="items"
        item-text="text"
        item-value="val"
        hide-details
        filled
        dense
        @change="$emit('input', $event)"
      ></v-select>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    field: {
      required: true,
    },
    value: {
      required: true,
    },
    filter: {
      default: null,
    },
    hint: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: true,
    },
    items: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      model: null,
    }
  },
  created() {
    this.model = this.value
  },
}
</script>

<style lang="scss" scoped></style>
