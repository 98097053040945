<template>
  <v-container fluid class="pt-0" :key="virtualAccountsKey">
    <PageHeader
      title="가상 계좌 거래 내역 조회"
      subtitle="가상 계좌 거래 내역 조회"
    >
    </PageHeader>
    <v-row class="mb-n3">
      <v-col class="">
        <v-card v-if="userVirtualAccount != null">
          <v-card-title>계좌 정보({{ userVirtualAccount.label }})</v-card-title>
          <v-card-text class="pa-2">
            <v-row no-gutters>
              <v-col>
                <v-simple-table>
                  <template v-slot:default>
                    <tbody>
                      <tr>
                        <td>은행</td>
                        <td>
                          {{ userVirtualAccount.bankCode | bankCodeName }}
                        </td>
                      </tr>
                      <tr>
                        <td>계좌주</td>
                        <td>{{ userVirtualAccount.accountName }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
              <v-col>
                <v-simple-table>
                  <template v-slot:default>
                    <tbody>
                      <tr>
                        <td>계좌번호</td>
                        <td>{{ userVirtualAccount.accountNo }}</td>
                      </tr>
                      <tr>
                        <td>잔액</td>
                        <td>{{ userVirtualAccount.balance | commaFormat }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card v-if="userVirtualAccount">
          <transfer-dialog-button
            :propsFromAccount="userVirtualAccount"
            v-if="userVirtualAccount.paymentType === 'WELCOME'"
            @success="virtualAccountsKey++"
          />

          <user-refund-dialog-button
            v-if="
              userVirtualAccount.label &&
              (userVirtualAccount.label.indexOf('이익금') > -1 ||
                userVirtualAccount.label.indexOf('테이스티홀딩스') > -1 ||
                userVirtualAccount.paymentType === 'HYPHEN')
            "
            :propsFromAccount="userVirtualAccount"
            @success="virtualAccountsKey++"
          />
        </v-card>
      </v-col>
      <v-col cols="12" lg="7" md="6" sm="12">
        <SystemUserButtonGroup
          :userVirtualAccountIdx="userVirtualAccountIdx"
          @click="selectSystemUser"
        />
      </v-col>
    </v-row>
    <PaymentVirtualAccountTransactions
      v-if="userVirtualAccount"
      :userIdx="userVirtualAccount.userIdx"
      :userVirtualAccountIdx="userVirtualAccountIdx"
      :isQueryPaging="true"
      :isDoubleEntry="true"
    />
  </v-container>
</template>
<script>
import UserRefundDialogButton from '@/components/payment/user/refund/UserRefundDialogButton.vue'
import SystemUserButtonGroup from '../../../../components/payment/common/SystemUserButtonGroup.vue'
import PaymentVirtualAccountTransactions from '../../../../components/payment/PaymentVirtualAccountTransactions'
import TransferDialogButton from '@/components/payment/user/TransferDialogButton.vue'
export default {
  components: {
    SystemUserButtonGroup,
    PaymentVirtualAccountTransactions,
    UserRefundDialogButton,
    TransferDialogButton,
  },
  props: {
    userVirtualAccountIdx: {
      type: Number,
      required: true,
    },
    query: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      userVirtualAccount: null,
      virtualAccountsKey: 0,
    }
  },
  async created() {
    const { data } = await this.$paymentApi.getUserVirtualAccount(
      this.userVirtualAccountIdx
    )
    this.userVirtualAccount = data
  },
  methods: {
    selectSystemUser(systemUser) {
      if (!systemUser) {
        return
      }
      this.$router.push({
        params: {
          userVirtualAccountIdx: systemUser.idx,
        },
        query: null,
      })
    },
  },
}
</script>
