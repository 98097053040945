<template>
  <div>
    <v-card class="my-3" width="100%">
      <v-card-text class="py-1">
        <v-form @submit.prevent="searchParam()">
          <v-row>
            <v-col cols="12" lg="2" md="3" sm="6">
              <CodeSelect
                label="계좌 유형"
                :selected.sync="param.type"
                nameSpace="payment"
                type="UserVirtualAccount.Type"
              />
            </v-col>
            <v-col cols="12" lg="2" md="3" sm="6">
              <BooleanSelect
                label="해지 여부"
                trueLabel="해지"
                falseLabel="미해지"
                :selected.sync="param.isClosed"
              />
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6">
              <v-text-field
                v-model.trim="param.keyword"
                outlined
                dense
                clearable
                label="검색"
                placeholder="계좌명, 사용자 index, 가상계좌 index"
                prepend-inner-icon="mdi-magnify"
                hide-details
              />
            </v-col>
            <v-col cols="12" lg="2" md="2" sm="6" align-self="center">
              <v-btn color="primary" type="submit"> 검색 </v-btn>
            </v-col>
            <!-- <v-col cols="12" lg="3" md="4" sm="6" align-self="center">
              <ExportExcelButton
                :data="dataTable.items"
                fileName="payment_user_virtual_accounts"
                label="현재 페이지 엑셀 다운"
                right
              />
            </v-col> -->
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <v-data-table
      :headers="dataTable.headers"
      :items="dataTable.items"
      :options.sync="dataTable.options"
      :loading="dataTable.isLoading"
      :server-items-length="dataTable.totalCount"
      :footer-props="dataTable.footer"
      class="elevation-2"
    >
      <template v-slot:[`item.idx`]="{ item }">
        <router-link
          :to="{
            name: 'payment/users/virtual/accounts/transactions',
            params: {
              userIdx: item.userIdx,
              userVirtualAccountIdx: item.idx,
            },
          }"
        >
          {{ item.idx }}
        </router-link>
      </template>

      <template v-slot:[`item.userIdx`]="{ item }">
        <router-link
          :to="{
            name: 'payment/users/detail',
            params: {
              userIdx: item.userIdx,
            },
          }"
        >
          {{ item.userIdx }}
        </router-link>
      </template>

      <template v-slot:[`item.bankCode`]="{ item }">
        {{ item.bankCode | bankCodeName }}
      </template>

      <template v-slot:[`item.type`]="{ item }">
        {{ item.type | payment.code('UserVirtualAccount.Type') }}
      </template>

      <template v-slot:[`item.balance`]="{ item }">
        {{ item.balance | commaFormat }}
      </template>

      <template v-slot:[`item.isClosed`]="{ item }">
        <v-switch
          class="mt-0"
          :input-value="item.isClosed"
          readonly
          disabled
          hide-details
        />
      </template>

      <template v-slot:[`item.isDepositStopped`]="{ item }">
        <v-switch
          class="mt-0"
          :input-value="item.isDepositStopped"
          readonly
          disabled
          hide-details
        />
      </template>

      <template v-slot:[`item.isAllDepositable`]="{ item }">
        <v-switch
          class="mt-0"
          :input-value="!item.isAllDepositable"
          readonly
          disabled
          hide-details
        />
      </template>

      <template v-slot:[`item.createTime`]="{ item }">
        {{ item.createTime | moment('YYYY-MM-DD HH:mm:ss') }}
      </template>
    </v-data-table>
  </div>
</template>
<script>
import CodeSelect from '../../../components/common/form/CodeSelect.vue'
import BooleanSelect from '../../../components/common/form/BooleanSelect.vue'
export default {
  components: { BooleanSelect, CodeSelect },
  props: {
    isQueryPaging: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      param: {
        page:
          this.isQueryPaging && this.$route.query.page
            ? Number(this.$route.query.page)
            : 1,
        size:
          this.isQueryPaging && this.$route.query.size
            ? Number(this.$route.query.size)
            : 20,
        keyword:
          this.isQueryPaging && this.$route.query.keyword
            ? this.$route.query.keyword
            : null,
        type:
          this.isQueryPaging && this.$route.query.type
            ? this.$route.query.type
            : null,
        isClosed:
          this.isQueryPaging && this.$route.query.isClosed != null
            ? this.$route.query.isClosed === true ||
              this.$route.query.isClosed === 'true'
            : null,
      },
      dataTable: {
        isLoading: false,
        options: null,
        headers: [
          { text: 'index', value: 'idx', sortable: false },
          { text: '사용자 index', value: 'userIdx', sortable: false },
          { text: '계좌명', value: 'accountName', sortable: false },
          { text: '은행', value: 'bankCode', sortable: false },
          { text: '계좌번호', value: 'accountNo', sortable: false },
          { text: '타입', value: 'type', sortable: false },
          { text: '잔액', value: 'balance', sortable: false },
          { text: '해지여부', value: 'isClosed', sortable: false },
          { text: '입금정지', value: 'isDepositStopped', sortable: false },
          { text: '입금계좌 제한', value: 'isAllDepositable', sortable: false },
        ],
        items: [],
        totalCount: 0,
        footer: {
          'items-per-page-options': [1, 5, 10, 20, 50, 100],
        },
      },
    }
  },
  watch: {
    'dataTable.options': {
      handler: function (options, oldValue) {
        if (oldValue === null) {
          return
        }
        const optionObj = {
          page: options.page,
          size: options.itemsPerPage,
          type: options.type,
          keyword: options.keyword,
          isClosed: options.isClosed,
        }
        this.updateOption(optionObj)
      },
      deep: true,
    },
  },
  created() {
    this.initTableOption()
    this.getPageData(this.param)
  },
  methods: {
    initTableOption() {
      this.dataTable.options = {
        page: this.param.page,
        itemsPerPage: this.param.size,
        type: this.param.type,
        keyword: this.param.keyword,
        isClosed: this.param.isClosed,
      }
    },
    updateOption(option) {
      if (this.isQueryPaging === true) {
        return this.$router.push({
          query: option,
        })
      } else {
        this.getPageData(option)
      }
    },
    async getPageData(option) {
      if (this.dataTable.isLoading === true) {
        return
      }
      try {
        this.dataTable.isLoading = true
        const {
          data: {
            paging: { totalCount },
            data,
          },
        } = await this.$paymentApi.getSearchUserVirtualAccounts(option)
        this.dataTable.items = data
        this.dataTable.totalCount = totalCount
      } finally {
        this.dataTable.isLoading = false
      }
    },
    searchParam() {
      this.dataTable.options = Object.assign(
        this.dataTable.options,
        this.param,
        {
          page: 1,
        }
      )
    },
  },
}
</script>
