<template>
  <v-btn
    color="primary"
    block
    large
    :loading="isLoading"
    :disabled="
      isLoading ||
      realAccountName != null ||
      bankCode == null ||
      accountNo == null
    "
    @click="getBankInfo"
  >
    계좌주 조회
  </v-btn>
</template>
<script>
export default {
  props: {
    bankCode: {
      type: String,
      default: null,
    },
    accountNo: {
      type: String,
      default: null,
    },
    realAccountName: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
    }
  },
  methods: {
    async getBankInfo() {
      if (
        this.isLoading === true ||
        this.bankCode == null ||
        this.accountNo == null
      ) {
        return
      }
      try {
        this.isLoading = true

        const { data } = await this.$paymentApi.getAccountInfo({
          bankCode: this.bankCode,
          accountNo: this.accountNo,
        })

        if (data.isSearch === false) {
          return await this.$swal.basic.alert({
            title: '조회 실패',
            text: '존재하지 않는 계좌입니다.',
          })
        }

        this.$emit('update:realAccountName', data.realAccountName)
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
