<template>
  <div>
    <v-progress-linear v-if="isLoading" indeterminate />
    <ValidationObserver v-slot="{ invalid }" ref="validForm">
      <v-form @submit.prevent="refund()">
        <v-row>
          <v-col cols="12" lg="6" md="6" sm="6">
            <v-card>
              <v-card-title> 보내는 계좌 </v-card-title>
              <v-card-text>
                <v-spacer class="my-3" />
                <PaymentUserVirtualAccountAutocomplete
                  :propsAccount="fromAccount"
                  :propsIsLoading="isLoading"
                  @select="fromAccount = $event"
                  ref="fromAccountAutocomplete"
                />
              </v-card-text>
              <v-slide-y-transition>
                <v-card-text v-if="fromAccount">
                  <PaymentUserTransferVirtualAccount
                    :userIdx="fromAccount.userIdx"
                    :userVirtualAccountIdx="fromAccount.idx"
                  />
                </v-card-text>
              </v-slide-y-transition>
            </v-card>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="6">
            <v-card>
              <v-card-title> 받는 계좌 </v-card-title>
              <v-card-text>
                <div v-if="isHyphen">
                  <ValidationProvider name="bankCode" rules="required">
                    <BankSelect
                      v-model="bankCode"
                      :bankCode.sync="bankCode"
                      :isLoading="isLoading"
                    />
                  </ValidationProvider>
                  <v-spacer class="my-3" />
                  <ValidationProvider
                    name="accountNo"
                    rules="required|numeric"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      label="계좌 번호"
                      v-model="accountNo"
                      :error-messages="errors"
                      :disabled="isLoading"
                      autofocus
                      outlined
                      :hide-details="errors.length == 0"
                    />
                  </ValidationProvider>
                  <v-spacer class="my-3" />
                  <AccountInfoButton
                    :bankCode="bankCode"
                    :accountNo="accountNo"
                    :realAccountName.sync="realAccountName"
                  />
                  <p v-if="realAccountName" class="primary--text">
                    조회된 계좌 명: {{ realAccountName }}
                  </p>
                </div>
                <v-spacer class="my-3" />
                <ValidationProvider
                  v-slot="{ errors }"
                  name="amount"
                  :rules="`required|numeric|min_value:1|max_value:${fromAccount.balance}`"
                >
                  <v-text-field
                    label="보낼 금액"
                    v-model="amount"
                    v-input-comma-format
                    :error-messages="errors"
                    :hide-details="errors.length == 0"
                    :disabled="isLoading"
                    outlined
                  />
                </ValidationProvider>
                <v-spacer class="my-3" />
                <ValidationProvider
                  v-slot="{ errors }"
                  name="remark"
                  rules="required|min:2"
                >
                  <v-text-field
                    label="remark"
                    v-model="remark"
                    :error-messages="errors"
                    :hide-details="errors.length == 0"
                    :disabled="isLoading"
                    outlined
                  />
                </ValidationProvider>
                <v-spacer class="my-3" />
                <v-btn
                  type="submit"
                  color="primary"
                  block
                  large
                  :loading="isLoading"
                  :disabled="isLoading || invalid"
                >
                  반환하기
                </v-btn>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-form>
    </ValidationObserver>
  </div>
</template>
<script>
import AccountInfoButton from '../../common/button/AccountInfoButton.vue'
import BankSelect from '../../common/select/BankSelect.vue'
import PaymentUserTransferVirtualAccount from '../TransferVirtualAccount.vue'
import PaymentUserVirtualAccountAutocomplete from '../VirtualAccountAutocomplete.vue'
export default {
  components: {
    PaymentUserVirtualAccountAutocomplete,
    PaymentUserTransferVirtualAccount,
    BankSelect,
    AccountInfoButton,
  },
  props: {
    propsFromAccount: {
      type: Object,
      default: (_) => {
        return null
      },
    },
  },
  computed: {
    isHyphen() {
      return this.fromAccount?.paymentType === 'HYPHEN'
    },
  },
  data() {
    return {
      fromAccount: this.propsFromAccount,
      toAccount: null,
      amount: null,
      remark: null,
      isLoading: false,
      bankCode: null,
      accountNo: null,
      realAccountName: null,
    }
  },
  watch: {
    isLoading(v) {
      this.$emit('loading', v)
    },
    bankCode() {
      this.realAccountName = null
    },
    accountNo() {
      this.realAccountName = null
    },
  },
  methods: {
    async refund() {
      if (this.isLoading === true) {
        return
      }
      if ((await this.$refs.validForm.validate()) === false) {
        return
      }
      let loadingAlert = null
      try {
        this.isLoading = true

        let btnResult = await this.$swal.basic.confirm({
          title: '반환',
          text: '반환하시겠습니까?',
          cancelButtonColor: 'red',
          focusConfirm: true,
        })

        if (btnResult.isConfirmed !== true) {
          return
        }

        loadingAlert = this.$swal.basic.loading()
        const param = {
          amount: this.amount,
          remark: this.remark,
        }
        /**
         * 하이픈의 경우에만 추가?
         */
        if (this.accountNo && this.bankCode) {
          param.accountNo = this.accountNo
          param.bankCode = this.bankCode
        }

        await this.$paymentApi.postUserRefundToAccount(
          this.fromAccount.userIdx,
          this.fromAccount.idx,
          param
        )

        loadingAlert.close()
        loadingAlert = null

        await this.$swal.basic.alert({
          title: '반환 요청 완료',
          text: '완료되었습니다.',
        })
        this.$emit('success')
      } finally {
        this.isLoading = false
        if (loadingAlert != null) {
          loadingAlert.close()
        }
      }
    },
  },
}
</script>
