<template>
  <v-container fluid class="pt-0">
    <PageHeader
      title="마케팅론"
      subtitle="강제투자 1건 완료될 때마다 채권 목록 새로고침됨"
      color="error"
      tabMenu
    >
    </PageHeader>
    <v-row>
      <v-col lg="12" md="12" sm="12" cols="12">
        <PageSection title="투자">
          <template slot="content">
            <Invest />
          </template>
        </PageSection>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Invest from '@/components/marketing-loan/Invest.vue'

export default {
  components: { Invest },
}
</script>
