<template>
  <div>
    <h1>404 not found!</h1>
    <router-link :to="{ name: 'main' }">메인으로 이동</router-link>
  </div>
</template>
<script>
export default {
  metaInfo() {
    return this.metaInfo
  },
  created() {
    this.metaInfo = _seo({
      titles: ['페이지가 없습니다.'],
    })
  },
}
</script>
