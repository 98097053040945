<template>
  <div>
    <v-data-table
      v-model="selected"
      :headers="dataTable.headers"
      :items="dataTable.items"
      :options.sync="dataTable.options"
      :loading="dataTable.isLoading"
      :footer-props="dataTable.footer"
      class="elevation-2 my-3"
      hide-default-footer
      :items-per-page="-1"
      show-select
      item-key="idx"
      @input="$emit('update:selectedItems', selected)"
    >
      <template v-slot:[`item.idx`]="{ item }">
        {{ item.idx }}
      </template>
      <template v-slot:[`item.createTime`]="{ item: { createTime } }">
        {{ createTime | moment('yyyy-MM-DD HH:mm:ss') }}
      </template>
    </v-data-table>
  </div>
</template>
<script>
export default {
  props: {
    selectedItems: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selected: [],
      dataTable: {
        isLoading: false,
        options: null,
        headers: [
          { text: 'index', value: 'idx', sortable: false },
          { text: '상태', value: 'status', sortable: false },
          { text: '생성 시간', value: 'createTime', sortable: false },
        ],
        items: [],
        footer: {
          'items-per-page-options': [1, 5, 10, 20, 50, 100, -1],
        },
      },
    }
  },
  async created() {
    this.getData()
  },
  methods: {
    test() {
      console.log(this.selected.length)
    },
    async getData() {
      this.dataTable.isLoading = true
      try {
        const { data } = await this.$paymentApi.getPayoutReserves({
          status: 'TRANSFER_PROGRESS'
          // status: 'TRANSFER_SUCCESS'
        })

        this.dataTable.items = data
      } finally {
        this.dataTable.isLoading = false
      }
    },
  },
}
</script>
