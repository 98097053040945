<template>
  <v-fade-transition>
    <v-card v-if="hasVariables || hasButtons">
      <v-card-title>
        템플릿&nbsp;
        <template v-if="hasVariables"> 변수 </template>
        <template v-if="hasVariables && hasButtons"> 및 </template>
        <template v-if="hasButtons"> 버튼 </template>
        &nbsp;목록
      </v-card-title>
      <template v-if="variables != null && variables.length > 0">
        <v-card-text>
          <p v-for="(variable, index) in variables" :key="index">
            변수{{ index + 1 }}: {{ variable }}
          </p>
        </v-card-text>
      </template>
      <template v-if="template.buttons != null && template.buttons.length > 0">
        <v-card-text>
          <p
            v-for="(button, index) in template.buttons"
            :key="index"
            class="my-1 button-info"
          >
            버튼{{ index + 1 }}: {{ button.name }} &#40;<a
              :href="button.link"
              >{{ button.link }}</a
            >&#41;
          </p>
        </v-card-text>
      </template>
    </v-card>
  </v-fade-transition>
</template>
<script>
export default {
  props: {
    template: {
      type: Object,
      required: true,
    },
    variables: {
      type: Array,
      default: (_) => {
        return []
      },
    },
  },
  computed: {
    hasVariables() {
      return this.variables != null && this.variables.length > 0
    },
    hasButtons() {
      return this.template.buttons != null && this.template.buttons.length > 0
    },
  },
}
</script>
<style lang="scss" scoped>
.button-info {
  word-break: break-all;
}
</style>
