<template>
  <div>
    <v-row>
      <v-col lg="4" md="4" sm="12" cols="12">
        <v-select
          :items="fundingTypes"
          item-text="label"
          item-value="value"
          label="채권타입"
          outlined
          hide-details
          v-model="fundingType"
        >
        </v-select>
        <br />
        <UserAutocomplete @select="setInvestUser" :key="count" />
        <SystemInvestUserSelect class="my-2" @select="selectSystemInvestUser" />
        <v-slide-y-transition>
          <UserAccount />
        </v-slide-y-transition>
      </v-col>
      <v-col lg="8" md="8" sm="12" cols="12">
        <InvestFundingTable :fundingType="fundingType" />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import InvestFundingTable from './invest/InvestFundingTable.vue'
import SystemInvestUserSelect from './invest/SystemInvestUserSelect.vue'
import UserAccount from './invest/UserAccount.vue'
import UserAutocomplete from './invest/UserAutocomplete.vue'
import { mapActions, mapState } from 'vuex'
export default {
  components: {
    UserAutocomplete,
    InvestFundingTable,
    UserAccount,
    SystemInvestUserSelect,
  },
  computed: {
    ...mapState('marketing/loan', ['investUser']),
  },
  data() {
    return {
      count: 0,
      fundingTypes: [
        {
          label: '펀다나우일반',
          value: 'FUNDANOW',
        },
        {
          label: '마케팅론',
          value: 'MARKETING_LOAN',
        },
        {
          label: '선정산',
          value: 'EARLY_PAYMENT',
        },
        {
          label: '아파트론',
          value: 'SECOND_MORTGAGE',
        },
        {
          label: '식기세척기론',
          value: 'INSTALLMENT',
        },
      ],
      fundingType: 'MARKETING_LOAN',
    }
  },
  methods: {
    ...mapActions('marketing/loan', ['setInvestUser']),
    selectSystemInvestUser() {
      this.count++
    },
  },
}
</script>
