<template>
  <div style="background-color: white">
    <keep-alive>
      <OverdueNotice1
        ref="noticePopup"
        :content="!!fundingData ? fundingData.memoCreditor : ''"
      >
      </OverdueNotice1>
    </keep-alive>

    <div class="contents__wrapper">
      <div class="contents__main">
        <v-row no-gutters>
          <v-col cols="12" class="text-center">
            <p class="ma-0 font-size--23 font-weight-bold line-height--27">
              {{ fNum }}호 {{ !!fundingData ? fundingData.title : '' }}
            </p>
          </v-col>
        </v-row>
        <hr />

        <v-row v-if="!!fundingData" class="pt-8" no-gutters>
          <v-col class="table-col" cols="12">
            <div class="data-table-wrapper">
              <table class="width-100">
                <thead>
                  <tr>
                    <th class="text-center" width="7%">회차</th>
                    <th width="10%" style="min-width: 100px">상환예정일</th>
                    <th width="10%" style="min-width: 150px">상환예정 금액</th>
                    <th>원금</th>
                    <th>기본이자</th>
                    <th v-if="showOverdueInterest">연체이자</th>
                    <th v-if="showAddInterest">추가이자</th>
                    <th>세금</th>
                    <th>수수료</th>
                    <th>회수한 상환금</th>
                    <th style="min-width: 120px">상환상태</th>
                    <th>비고</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(v, index) in result">
                    <tr
                      :key="v.dueDate"
                      :class="{
                        'cursor--pointer': realRpyInfo(v).length > 0,
                        active: v.showInfo,
                      }"
                      @click="v.showInfo = !v.showInfo"
                    >
                      <td>
                        <span
                          v-if="realRpyInfo(v).length > 0"
                          class="detail-arrow"
                          :class="{ active: v.showInfo }"
                        ></span>
                        {{ index }}차
                      </td>
                      <td class="px-0">{{ v.dueDate }}</td>
                      <td>{{ v.totalAmount | commaFormat }}원</td>
                      <td>{{ v.principal | commaFormat }}원</td>
                      <td>{{ v.interest | commaFormat }}원</td>
                      <td v-if="showOverdueInterest">
                        {{ v.overdueInterest | commaFormat }}원
                      </td>
                      <td v-if="showAddInterest">
                        {{ v.additionalInterest | commaFormat }}원
                      </td>
                      <td>{{ v.tax | commaFormat }}원</td>
                      <td>{{ v.fee | commaFormat }}원</td>
                      <td>{{ v.repaymentTotalAmount | commaFormat }}원</td>
                      <td
                        v-if="showNotice(v.repaymentStatus)"
                        class="color--funda-basic cursor--pointer"
                        @click.stop="showNoticePopup()"
                      >
                        {{ v.repaymentStatus }} <i class="icon-doc-text-2"></i>
                      </td>
                      <td
                        v-else
                        :class="{
                          'color--green': v.repaymentStatus === '상환완료',
                        }"
                      >
                        {{ v.repaymentStatus }}
                      </td>
                      <td>{{ v.overdueDetail }}</td>
                    </tr>

                    <template v-if="realRpyInfo(v).length > 0">
                      <tr
                        v-show="v.showInfo"
                        :key="index"
                        class="active"
                        style="border-bottom: 1px solid black"
                      >
                        <td colspan="3">실상환일</td>
                        <td>원금</td>
                        <td>기본이자</td>
                        <td v-if="showOverdueInterest">연체이자</td>
                        <td v-if="showAddInterest">추가이자</td>
                        <td>세금</td>
                        <td>수수료</td>
                        <td>합계</td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr
                        v-show="v.showInfo"
                        v-for="(inf, index2) in realRpyInfo(v)"
                        :key="inf.dueDate + index2"
                        class="active"
                      >
                        <td colspan="3">
                          {{ inf.transactTime | dateFormat }}
                        </td>
                        <td>{{ inf.repaymentPrincipal | commaFormat }}원</td>
                        <td>{{ inf.repaymentInterest | commaFormat }}원</td>
                        <td v-if="showOverdueInterest">
                          {{ inf.repaymentOverdueInterest | commaFormat }}원
                        </td>
                        <td v-if="showAddInterest">
                          {{ inf.repaymentAdditionalInterest | commaFormat }}원
                        </td>
                        <td>{{ inf.repaymentTax | commaFormat }}원</td>
                        <td>{{ inf.repaymentFee | commaFormat }}원</td>
                        <td>{{ inf.repaymentTotalAmount | commaFormat }}원</td>
                        <td></td>
                        <td></td>
                      </tr>
                    </template>
                  </template>
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="2">상환완료 총액</td>
                    <td>
                      {{
                        totalSummary.done.repaymentTotalAmount | commaFormat
                      }}원
                    </td>
                    <td>
                      {{ totalSummary.done.repaymentPrincipal | commaFormat }}원
                    </td>
                    <td>
                      {{ totalSummary.done.repaymentInterest | commaFormat }}원
                    </td>
                    <td v-if="showOverdueInterest">
                      {{
                        totalSummary.done.repaymentOverdueInterest
                          | commaFormat
                      }}원
                    </td>
                    <td v-if="showAddInterest">
                      {{
                        totalSummary.done.repaymentAdditionalInterest
                          | commaFormat
                      }}원
                    </td>
                    <td>
                      {{ totalSummary.done.repaymentTax | commaFormat }}원
                    </td>
                    <td>
                      {{ totalSummary.done.repaymentFee | commaFormat }}원
                    </td>
                    <td>
                      {{
                        totalSummary.done.repaymentTotalAmount | commaFormat
                      }}원
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td colspan="2">상환예정 총액</td>
                    <td>
                      {{ totalSummary.expect.totalAmount | commaFormat }}원
                    </td>
                    <td>{{ totalSummary.expect.principal | commaFormat }}원</td>
                    <td>{{ totalSummary.expect.interest | commaFormat }}원</td>
                    <td v-if="showOverdueInterest">0원</td>
                    <td v-if="showAddInterest">0원</td>
                    <td>{{ totalSummary.expect.tax | commaFormat }}원</td>
                    <td>{{ totalSummary.expect.fee | commaFormat }}원</td>
                    <td>
                      {{ totalSummary.expect.totalAmount | commaFormat }}원
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td colspan="2">합계</td>
                    <td>
                      {{
                        (totalSummary.done.repaymentTotalAmount +
                          totalSummary.expect.totalAmount)
                          | commaFormat
                      }}원
                    </td>
                    <td>
                      {{
                        (totalSummary.done.repaymentPrincipal +
                          totalSummary.expect.principal)
                          | commaFormat
                      }}원
                    </td>
                    <td>
                      {{
                        (totalSummary.done.repaymentInterest +
                          totalSummary.expect.interest)
                          | commaFormat
                      }}원
                    </td>
                    <td v-if="showOverdueInterest">
                      {{
                        totalSummary.done.repaymentOverdueInterest
                          | commaFormat
                      }}원
                    </td>
                    <td v-if="showAddInterest">
                      {{
                        totalSummary.done.repaymentAdditionalInterest
                          | commaFormat
                      }}원
                    </td>
                    <td>
                      {{
                        (totalSummary.done.repaymentTax +
                          totalSummary.expect.tax)
                          | commaFormat
                      }}원
                    </td>
                    <td>
                      {{
                        (totalSummary.done.repaymentFee +
                          totalSummary.expect.fee)
                          | commaFormat
                      }}원
                    </td>
                    <td>
                      {{
                        (totalSummary.done.repaymentTotalAmount +
                          totalSummary.expect.totalAmount)
                          | commaFormat
                      }}원
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import OverdueNotice1 from '@/components/funda/popups/OverdueNotice1'

export default {
  props: {
    userIdx: {
      type: Number,
      default: null,
    },
    fNum: {
      type: Number,
      default: null,
    },
  },
  components: {
    OverdueNotice1,
  },
  data: function () {
    return {
      fundingData: null,
      result: null,
      totalSummary: null,
    }
  },
  computed: {
    showNotice() {
      return (repaymentStatus) => {
        return (
          repaymentStatus === '연체' ||
          repaymentStatus === '부도' ||
          repaymentStatus === '연체해소' ||
          repaymentStatus === '채권매각완료'
        )
      }
    },
    realRpyInfo() {
      return (detail) => {
        if (detail.info.length > 1) {
          return detail.info
        } else if (detail.info.length === 1) {
          let rpyDate = detail.info[0].transactTime

          let d = rpyDate.split('T')
          let ymd = d[0]

          if (detail.dueDate === ymd) {
            return []
          } else {
            return detail.info
          }
        } else {
          return []
        }
      }
    },
    showOverdueInterest() {
      let result = false
      if (!!this.result) {
        for (const v in this.result) {
          const r = this.result[v]
          if (r.overdueInterest > 0) {
            result = true
            break
          }
        }
      }
      return result
    },
    showAddInterest() {
      return false
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      let data = await this.$fundaApi.query(
        gql`
          {
            data: getScheduleAndRepayment(userIdx: ${this.userIdx}, fundingDataIdx: ${this.fNum})
          }
        `
      )

      let d = JSON.parse(data)

      if (!!d.data) {
        if (!!d.data.fundingData.memoCreditor) {
          d.data.fundingData.memoCreditor =
            d.data.fundingData.memoCreditor.replace(/(?:\r\n|\r|\n)/g, '<br />')
        }

        this.fundingData = d.data.fundingData
        this.result = d.data.result
        this.totalSummary = d.data.totalSummary
      }
    },
    showNoticePopup() {
      this.$refs.noticePopup.showToggle()
    },
  },
}
</script>

<style lang="scss" scoped>
.contents {
  &__wrapper {
    max-width: 1185px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 60px;
    padding-bottom: 60px;
  }

  &__main {
    background: #fff;
    border: 1px solid #e7edf6;
    border-radius: 3px;
    position: relative;
    padding: 30px 25px 20px 25px;
    margin-bottom: 30px;

    .table-col {
      position: relative;

      @media screen and (max-width: 425px) {
        &::before {
          content: '';
          position: absolute;
          top: 33px;
          width: 15px;
          height: 15px;
          right: 10px;
          background: url('~@/assets/images/myfunda/btn-btn-arrow-b.svg')
            no-repeat center;
          z-index: 5;
        }
      }
    }

    .data-table-wrapper {
      position: relative;
      overflow: auto;

      table {
        border-collapse: collapse;
        border-top: 1px solid #333333;
        border-bottom: 1px solid #8a94a2;

        .sticky-v-col {
          &.due {
            left: 90px;
          }
        }

        td {
          min-width: 90px;
        }

        thead {
          background-color: #f9fcff;

          th {
            margin: 0;
            padding-top: 20px;
            padding-bottom: 20px;
            text-align: center;

            &:not(.rpy).sticky-v-col {
              background-color: #f9fcff !important;
            }
          }
        }

        tbody {
          tr {
            &.active,
            &.cursor--pointer:hover,
            &.cursor--pointer.active {
              background-color: #f5f5f5 !important;

              .sticky-v-col {
                background-color: #f5f5f5;
              }
            }
          }

          td {
            padding: 12px 0;
            color: #565a5c;
            text-align: center;
          }
        }

        tfoot {
          border-top: 1px solid #e7edf6;

          td {
            padding: 12px 0;
            color: #565a5c;
            font-weight: 700;
            text-align: center;
          }
        }
      }
    }
  }
}
</style>
