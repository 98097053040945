<template>
  <v-dialog v-model="dialog" max-width="500px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn large class="mr-2" v-bind="attrs" v-on="on" @click="dialog = true">
        수신 번호 추가 - 직접 입력
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">수신 번호 추가</span>
      </v-card-title>
      <ValidationObserver v-slot="{ invalid }" ref="validForm">
        <v-form @submit.prevent="save()">
          <v-card-text>
            <v-container>
              <ValidationProvider
                v-slot="{ errors }"
                name="phone"
                rules="required|numeric|phone"
              >
                <v-text-field
                  v-model="form.mobileNo"
                  label="mobileNo"
                  :error-messages="errors"
                  autofocus
                ></v-text-field>
              </ValidationProvider>
              <ValidationProvider
                v-for="(variable, index) in variables"
                :key="index"
                v-slot="{ errors }"
                :name="variable"
                rules="required"
              >
                <v-text-field
                  v-model="form[variable]"
                  :label="variable"
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              type="button"
              @click="dialog = false"
            >
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text :disabled="invalid" type="submit">
              Save
            </v-btn>
          </v-card-actions>
        </v-form>
      </ValidationObserver>
    </v-card>
  </v-dialog>
</template>
<script>
import { simpleClone } from '@/util'

export default {
  props: {
    variables: {
      type: Array,
      default: (_) => {
        return []
      },
    },
  },
  data() {
    return {
      dialog: false,
      form: {
        mobileNo: null,
      },
      emptyform: {
        mobileNo: null,
      },
    }
  },
  watch: {
    dialog(v) {
      if (v === false) {
        this.$nextTick((_) => {
          this.form = simpleClone(this.emptyform)
          this.$refs.validForm.reset()
        })
      }
    },
  },
  methods: {
    save() {
      this.$emit('add', this.form)
      this.dialog = false
    },
  },
}
</script>
