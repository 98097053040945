import Vue from 'vue'
import store from '@/store'

Vue.filter('fundanow.code', (code = '', category) => {
  const rVal = store.getters['fundanow/code/getCategory'](category)
  if (rVal) {
    return rVal.find((r) => r.code === code).name
  }
  return ''
  //   return rVal ? code : rVal.find(r => r.code === code).name
})
