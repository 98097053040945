<template>
  <v-select
    :items="items"
    :label="label"
    item-text="name"
    item-value="code"
    outlined
    dense
    hide-details
    @change="$emit('update:selected', $event)"
    v-model="selectedValue"
    class="mr-3"
  ></v-select>
</template>
<script>
export default {
  props: {
    selected: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      required: true,
    },
    nameSpace: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  computed: {
    items() {
      let items =
        this.$store.getters[`${this.nameSpace}/code/getCategory`](this.type) ||
        []
      return [{ name: '전체', code: null }, ...items]
    },
  },
  data() {
    return {
      selectedValue: this.selected,
    }
  },
}
</script>
