<template>
  <div class="pa-3">
    <v-row>
      <v-form @submit.prevent="search()">
        <v-text-field type="text" label="수신 번호" v-model="mobileNo" />
        <v-text-field type="text" label="검색어" v-model="keyword" />
        <v-btn type="submit">검색</v-btn>
      </v-form>
    </v-row>
    <v-row>
      <SmsesViewer :options.sync="options">
        <template v-slot:table="{ loading, totalCount, items }">
          <v-data-table
            :headers="smsesHeaders"
            :items="items"
            :loading="loading"
            :server-items-length="totalCount"
            :page.sync="options.page"
            :items-per-page.sync="options.size"
            :footer-props="footer"
          />
        </template>
      </SmsesViewer>
    </v-row>
  </div>
</template>
<script>
import SmsesViewer from '../../components/sms/SmsesViewer.vue'

export default {
  components: { SmsesViewer },
  data() {
    return {
      smsesHeaders: [
        { text: 'index', value: 'idx', sortable: false },
        { text: 'refId', value: 'refId', sortable: false },
        { text: 'sendNo', value: 'sendNo', sortable: false },
        { text: 'mobileNo', value: 'mobileNo', sortable: false },
        { text: 'title', value: 'title', sortable: false },
        { text: 'content', value: 'content', sortable: false },
        { text: 'status', value: 'status', sortable: false },
        { text: 'createTime', value: 'createTime', sortable: false },
        { text: 'reserveTime', value: 'reserveTime', sortable: false },
      ],
      footer: {
        'items-per-page-options': [1, 5, 10, 20, 50, 100],
      },
      options: {
        page: 5,
        size: 20,
        mobileNo: null,
      },
      mobileNo: null,
      keyword: null,
    }
  },
  async created() {
    this.options.page = 1
  },
  methods: {
    search() {
      let newOptions = JSON.parse(JSON.stringify(this.options))
      newOptions.mobileNo = this.mobileNo
      newOptions.keyword = this.keyword

      this.options = newOptions
    },
  },
}
</script>
