import client from '../client'

export default {
  getList(params) {
    return client.call({
      uri: '/graphql',
      method: 'POST',
      data: {
        query: `query($input: UserSalesStatisticsInput!) {
          data: getPaginatedUserSalesStatistics(input: $input) {
            data {
              id
              customer_id
              name
              date
              sum_amount
              earlypay_status
              sales {
                provider
                payment_company
                amount
                scraping_status
                scraping_status_id
              }
            }
            total
            page
            limit
            total_sum_amount
          }
        }`,
        variables: { input: params }
      }
    })
  },

  getInvestorList(params) {
    return client.call({
      uri: '/graphql',
      method: 'POST',
      data: {
        query: `query($input: SearchInvestorsInput!) {
          data: paginatedInvestors(searchInvestorsInput: $input) {
            data {
              id
              investmentRate
              fundaUser {
                idx
                userId
                userName
                withdrawBalance
                userPhone
              }
            }
          }
        }`,
        variables: { input: params }
      }
    })
  },

  async confirm(params) {
    return client.call({
      uri: '/graphql',
      method: 'POST',
      data: {
        query: `mutation($input: SetEarlyPayConfirmedByIdsInput!) {
          data: setEarlyPayConfirmedByIds(input: $input)
        }`,
        variables: { input: params }
      }
    })
  },

  async getPaymentRecords(params) {
    return client.call({
      uri: '/graphql',
      method: 'POST',
      data: {
        query: `query($input: SearchEarlyPaymentsInput!) {
          data: paginatedEarlyPayments(searchEarlyPaymentsInput: $input) {
            page
            limit
            total
            data {
              date
              amount
              fee
              adjust
              interest
              deductibleAmount
              refund
              loanId
              connectTransactions {
                type
                amount
                remark
              }
              user {
                customer {
                  id
                }
                store {
                  name
                }
              }
              loan {
                id
                status
                amount
                balance
              }
              salesTotal {
                salesAmount
                expectedFee
              }
            }
          }
        }`,
        variables: { input: params }
      }
    })
  },

  getPaymentSummary(params) {
    return client.call({
      uri: '/graphql',
      method: 'POST',
      data: {
        query: `query($input: SearchEarlyPaymentsInput!) {
          data: earlyPaymentsSummary(searchEarlyPaymentsInput: $input) {
            totalServiceFee
          }
        }`,
        variables: { input: params }
      }
    })
  }
}
