<template>
  <PageSection title="상환 방식" :isLoading="isLoading">
    <template slot="content" v-if="repaymentsMethod != null">
      <v-simple-table>
        <template v-slot:default>
          <tbody>
            <tr>
              <td>상환 방식</td>
              <td>
                {{
                  repaymentsMethod.type | fundanow.code('RepaymentsMethod.Type')
                }}
                ({{ repaymentsMethod.count }}회 상환)
              </td>
            </tr>
            <tr>
              <td>상환 주기</td>
              <td>
                {{
                  repaymentsMethod.frequency
                    | fundanow.code('RepaymentsMethod.Frequency')
                }}
                <span v-if="repaymentsMethod.frequency === 'monthly'"
                  >({{ repaymentsMethod.date }} 일)</span
                >
              </td>
            </tr>
            <tr>
              <td>만기일</td>
              <td>
                <DueDate :loanId="loanId" />
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </template>
  </PageSection>
</template>
<script>
import DueDate from '../loan/cell/DueDate.vue'
export default {
  components: { DueDate },
  props: {
    loanId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      repaymentsMethod: null,
      isLoading: false,
    }
  },
  watch: {
    loanId(v) {
      if (v) {
        this.getData()
      }
    },
  },
  async created() {
    await this.getData()
  },
  methods: {
    async getData() {
      try {
        this.isLoading = true

        const { data } = await this.$fundaNowApi.getLoansRepaymentsMethod(
          this.loanId
        )
        this.repaymentsMethod = data
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
<style lang="scss" scoped>
tr > td:last-child {
  word-break: break-all;
}
</style>
