<template>
  <div class="pb-10">
    <v-row no-gutters>
      <v-col cols="6" md="4" class="pr-2">
        <v-row no-gutters>
          <v-col cols="12" class="mb-2">
            <v-btn
              v-for="template in templates"
              :key="template.text"
              color="#393939"
              class="mr-3"
              dark
              depressed
              @click="changeTemplate(template)"
            >
              {{ template.text }}
            </v-btn>
          </v-col>

          <v-col cols="12">
            <v-card outlined>
              <v-card-title class="justify-center"> 문자 발송 </v-card-title>
              <v-card-text>
                <v-text-field
                  v-model="form.sendNo"
                  class="my-3"
                  type="tel"
                  label="발송 번호"
                  hide-details
                  outlined
                />
                <v-text-field
                  v-model="form.toNo"
                  class="my-3"
                  type="tel"
                  label="수신 번호"
                  hide-details
                  outlined
                />
                <v-text-field
                  v-model="form.title"
                  class="my-3"
                  type="text"
                  label="제목(LMS발송시)"
                  hide-details
                  outlined
                />
                <v-row no-gutters>
                  <v-col cols="6" class="pr-1">
                    <p class="content-header">템플릿</p>

                    <v-textarea
                      v-model="form.templateText"
                      label="내용"
                      rows="15"
                      outlined
                      hide-details
                    >
                    </v-textarea>
                  </v-col>

                  <v-col cols="6" class="pl-2">
                    <p class="content-header">치환</p>

                    <v-textarea
                      v-model="showText"
                      label="내용"
                      rows="15"
                      outlined
                      hide-details
                    >
                    </v-textarea>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  color="#393939"
                  width="100%"
                  height="50px"
                  depressed
                  :dark="(isLoading || smsData.length < 1) === false"
                  :disabled="isLoading || smsData.length < 1"
                  :loading="isLoading"
                  @click="sendSms()"
                >
                  전송
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="6" md="8" class="pl-1">
        <v-row no-gutters>
          <v-col cols="12" class="mb-2 text-right">
            <v-btn color="#393939" dark depressed @click="clearData()">
              비우기
            </v-btn>
          </v-col>

          <v-col cols="12">
            <v-card outlined>
              <v-card-title>
                <p class="text-center width-100">
                  전송용 데이터
                  <br />
                  '#{컬럼명}' 으로 치환됩니다.(직접수정가능)
                </p>
                <p class="ma-0">
                  현재 총 {{ smsData.length | commaFormat }} 건
                </p>
                <p v-if="failures.length > 0">
                  전송 실패: {{ failures.length }} 건
                  <br />
                  {{ failures }}
                </p>
              </v-card-title>

              <v-card-text>
                <v-data-table
                  no-data-text="데이터가 없습니다."
                  no-results-text="데이터가 없습니다."
                  :headers="dataColumns"
                  :items="smsData"
                  hide-default-header
                  @click:row="clickData"
                >
                  <template v-slot:header="{ props: { headers } }">
                    <thead>
                      <tr>
                        <th
                          v-for="(header, index) in headers"
                          :key="index"
                          class="text-center cursor--pointer font-size--15"
                        >
                          <p class="transfer-key">
                            <input
                              v-model="header.text"
                              type="text"
                              class="text-center"
                              :style="{
                                'max-width': header.text.length + 'rem',
                              }"
                            />
                          </p>
                        </th>
                      </tr>
                    </thead>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-dialog v-model="loadingDialog" persistent width="500">
      <v-card color="primary" dark>
        <v-card-text class="pt-3 text-center">
          <p class="mt-5 mb-6 font-size--17">문자 전송중입니다..</p>
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    dataColumns: {
      type: Array,
      default() {
        return []
      },
    },
    smsData: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      isLoading: false,
      loadingDialog: false,
      templates: [
        {
          text: '초기화',
          form: {
            sendNo: null,
            toNo: null,
            title: null,
            templateText: null,
          },
        },
        {
          text: '샘플 템플릿1',
          form: {
            sendNo: '025400360',
            toNo: null,
            title: '[펀다] 안내',
            templateText: `[테스트 문자입니다]

#{이름} 님, 안녕하세요.
펀다입니다.
이메일은 #{이메일} 입니다.

상품호수: #{상품호수}
상품명: #{상품명}
총상환액: #{총상환액}
`,
          },
        },
        {
          text: '템플릿2',
          form: {
            sendNo: '025400360',
            toNo: null,
            title: '[펀다] 상환 안내',
            templateText: '#{이름}',
          },
        },
      ],
      senderTab: null,
      showText: null,
      headers: [],
      transkferKey: {},
      form: {
        sendNo: '025400360',
        toNo: null,
        title: null,
        templateText: null,
      },
      selectedData: null,
      failures: [],
    }
  },
  watch: {
    dataColumns: {
      immediate: true,
      deep: true,
      handler(arr) {
        arr.forEach((v) => {
          if (!!v.value) {
            this.transkferKey[`#{${v.text}}`] = v.value
          }
        })
      },
    },
  },
  methods: {
    changeTemplate(t) {
      this.form = {
        ...t.form,
      }
      this.setForm()
    },
    clickData(item, row) {
      row.select(true)
      this.selectedData = item

      this.setForm()
    },
    setForm() {
      if (!!this.selectedData) {
        const tempText = this.transferText(this.selectedData)
        if (!!tempText) {
          this.form.toNo = this.selectedData.userPhone
        }
        this.showText = tempText
      }
    },
    transferText(formData) {
      if (!!!formData) {
        return null
      }

      if (this.form.templateText === null) {
        this.showText = null
        return null
      }

      let tempText = this.form.templateText
      let obj = formData

      for (let k in this.transkferKey) {
        let re = new RegExp(k, 'g')

        if (
          Number.isInteger(obj[this.transkferKey[k]]) ||
          !!obj[this.transkferKey[k]]
        ) {
          tempText = tempText.replace(re, obj[this.transkferKey[k]])
        }
      }
      return tempText
    },
    async clearData() {
      let alert = await this.$swal.basic.confirm(
        '현재 전송용 리스트를 비웁니다?'
      )

      if (alert.isConfirmed === false) {
        return
      }

      this.$emit('clearData')
      Object.keys(this.form).forEach((k) => {
        if (k !== 'sendNo') {
          this.form[k] = null
        }
      })
      this.showText = null
    },
    async sendSms() {
      let alert = await this.$swal.basic.confirm(
        `총 ${this.smsData.length} 건의 문자메시지를 전송합니다??`
      )

      if (alert.isConfirmed === false) {
        return
      }

      let params = this.smsData.map((v) => {
        let p = {
          fromNumber: `${this.form.sendNo}`,
          toNumber: `${v.userPhone}`,
          msg: `${this.transferText(v)}`,
        }

        if (!!v.userIdx) {
          p.userIdx = v.userIdx
        }

        if (!!v.fundingDataIdx) {
          p.fundingDataIdx = v.fundingDataIdx
        }
        return p
      })

      if (params.length < 1) {
        await this.$swal.basic.error('전송할 대상이 없습니다.')
      }

      let q = `
          data: sendSmses(smses: $input) {
          totalCount
          failures {
            idx
            userIdx
            fundingDataIdx
            fromNumber
            toNumber
            msg
            status
            createTime
          }
        }
      `

      this.isLoading = true
      this.loadingDialog = true

      try {
        let data = await this.$fundaApi.mutation(
          gql`{${q}}`,
          'call($input: [InputSMS]!)',
          { input: params }
        )

        this.failures = data.failures
        this.smsData = []
        this.$swal.basic.success({
          text: `총 ${data.totalCount} 건 전송완료!!`,
          timer: 0,
          timerProgressBar: false,
          allowOutsideClick: true,
        })
      } finally {
        this.isLoading = false
        this.loadingDialog = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.content-header {
  margin: 0;
  padding-top: 15px;
  padding-bottom: 15px;
  border: 1px solid rgba(0, 0, 0, 0.38);
  border-bottom: unset;
  color: black;
  font-size: 17px;
  text-align: center;
}
</style>
