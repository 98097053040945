<template>
  <v-container fluid class="pt-0">
    <PageHeader title="사용자" subtitle="페이먼트 사용자 목록 조회">
      <template v-slot:right>
        <v-col cols="4" lg="4" md="4" sm="4" align-self="center" class="pr-0">
          <div class="text-right font-weight-bold">상세 바로가기</div>
        </v-col>
        <v-col cols="4" lg="4" md="4" sm="4">
          <UserAutocomplete :dense="true" @select="shortcut" />
        </v-col>
        <v-col cols="4" lg="4" md="4" sm="4">
          <SystemUserSelect
            label="시스템 유저 상세 바로가기"
            :dense="true"
            @change="shortcut({ idx: $event.userIdx })"
          />
        </v-col>
      </template>
    </PageHeader>
    <v-card class="my-3" width="100%">
      <v-card-text>
        <v-row no-gutters>
          <v-col cols="12" lg="2" md="4" sm="6">
            <form @submit.prevent="search(keyword)">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    label="검색어"
                    v-model="keyword"
                    outlined
                    hide-details
                    dense
                    clearable
                    append-icon="mdi-magnify"
                    @click:append="search(keyword)"
                    @click:clear="search(null)"
                    v-bind="attrs"
                    v-on="on"
                  >
                  </v-text-field>
                </template>
                <span
                  >검색 가능 : 이름 / 핸드폰 번호 / 출금계좌명 / 출금계좌번호 /
                  가상계좌번호 / 가상계좌명 / guid</span
                >
              </v-tooltip>
            </form>
          </v-col>
          <!-- <v-spacer />
          <v-col cols="12" lg="2" md="3" sm="6">
            <ExportExcelButton
              :data="userTable.items"
              fileName="payment_users"
              label="현재 페이지 엑셀 다운"
            />
          </v-col> -->
        </v-row>
      </v-card-text>
    </v-card>
    <v-data-table
      :headers="userTable.headers"
      :footer-props="userTable.footer"
      :items="userTable.items"
      :options.sync="userTable.options"
      :loading="userTable.isLoading"
      :server-items-length="userTable.totalCount"
      class="elevation-2"
    >
      <template v-slot:[`item.idx`]="{ item }">
        <router-link
          :to="{ name: 'payment/users/detail', params: { userIdx: item.idx } }"
          >{{ item.idx }}</router-link
        >
      </template>
      <template v-slot:[`item.createTime`]="{ item }">
        {{ item.createTime | moment('YYYY-MM-DD HH:mm:ss') }}
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import UserAutocomplete from '../../../components/payment/user/UserAutocomplete.vue'
import SystemUserSelect from '../../../components/payment/common/select/SystemUserSelect.vue'

export default {
  components: { UserAutocomplete, SystemUserSelect },
  props: {
    query: {
      type: Object,
      default: (_) => {
        return null
      },
    },
  },
  data() {
    return {
      keyword: this.$route.query.keyword,
      userTable: {
        options: null,
        isLoading: false,
        items: [],
        totalCount: 0,
        headers: [
          { text: '유저번호', value: 'idx', sortable: false },
          { text: 'id', value: 'id', sortable: false },
          { text: '핸드폰', value: 'mobileNo', sortable: false },
          { text: '이름', value: 'name', sortable: false },
          { text: '타입', value: 'paymentType', sortable: false },
          { text: '가입시간', value: 'createTime', sortable: false },
        ],
        footer: {
          'items-per-page-options': [1, 5, 10, 20, 50, 100],
        },
      },
      searchParam: {
        page: this.query.page ? Number(this.query.page) : 1,
        size: this.query.size ? Number(this.query.size) : 20,
        isWithdrawal: this.query.isWithdrawal || false,
        keyword: this.query.keyword,
      },
    }
  },
  watch: {
    'userTable.options': {
      handler: function (options, oldValue) {
        if (oldValue == null) {
          return
        }
        const optionObj = {
          page: options.page,
          size: options.itemsPerPage,
        }
        this.updateParam(optionObj)
      },
      deep: true,
    },
  },
  created() {
    this.userTable.options = {
      page: this.searchParam.page,
      itemsPerPage: this.searchParam.size,
    }
    this.getUsers(this.searchParam)
  },
  methods: {
    async getUsers(param) {
      try {
        this.userTable.isLoading = true

        const {
          data: { paging, data },
        } = await this.$paymentApi.getUsers(param)
        this.userTable.items = data
        this.userTable.totalCount = paging.totalCount
      } finally {
        this.userTable.isLoading = false
      }
    },
    async updateParam(param) {
      await this.$router.push({ query: Object.assign(this.searchParam, param) })
    },
    search(keyword) {
      if (this.searchParam.keyword == null && keyword == null) {
        return
      }
      if (this.searchParam.keyword !== keyword) {
        this.updateParam({
          keyword,
          page: 1,
        })
      }
    },
    shortcut(e) {
      if (e == null) {
        return
      }
      this.$router.push({
        name: 'payment/users/detail',
        params: { userIdx: e.idx },
      })
    },
  },
}
</script>
