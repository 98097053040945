<template>
  <div>
    <v-card class="my-3" width="100%">
      <v-card-text class="py-1">
        <v-form @submit.prevent="searchParam()">
          <v-row>
            <v-col cols="12" lg="3" md="4" sm="6">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-bind="attrs"
                    v-on="on"
                    v-model.trim="param.keyword"
                    outlined
                    dense
                    clearable
                    label="검색"
                    placeholder="고객명, 상점명, 핸드폰번호, 사업자번호 검색 가능"
                    prepend-inner-icon="mdi-magnify"
                    hide-details
                  />
                </template>
                <span>고객명, 상점명, 핸드폰번호, 사업자번호 검색 가능</span
                >
              </v-tooltip>
            </v-col>
            <v-col cols="12" lg="2" md="2" sm="6" align-self="center">
              <v-btn color="primary" type="submit"> 검색 </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <v-data-table
      :headers="dataTable.headers"
      :items="dataTable.items"
      :options.sync="dataTable.options"
      :loading="dataTable.isLoading"
      :server-items-length="dataTable.totalCount"
      :footer-props="dataTable.footer"
      class="elevation-2"
    >
      <template v-slot:[`item.id`]="{ item: { id } }">
        <router-link
          :to="{ name: 'fundanow/users/detail', params: { userId: id } }"
        >
          {{ id }}
        </router-link>
      </template>

      <template v-slot:[`item.store`]="{ item: { id } }">
        <CellUserStore :userId="id" />
      </template>

      <template v-slot:[`item.phone`]="{ item: { phone } }">
        {{ phone | fundanow.phone }}
      </template>

      <template v-slot:[`item.personalData`]="{ item }">
        {{ item.birthdate }}({{ item.gender | gender }})
      </template>

      <template v-slot:[`item.created`]="{ item: { created } }">
        {{ created | moment('yyyy-MM-DD HH:mm:ss') }}
      </template>

    </v-data-table>
  </div>
</template>
<script>
import CellUserStore from './CellUserStore.vue'
export default {
  components: { CellUserStore },
  props: {
    isQueryPaging: {
      type: Boolean,
      default: false,
    },
    query: {
      type: Object,
    },
  },
  data() {
    return {
      param: {
        page:
          this.isQueryPaging && this.query.page ? Number(this.query.page) : 1,
        size: this.query.size ? Number(this.query.size) : 20,
        keyword:
          this.isQueryPaging && this.query.keyword ? this.query.keyword : null,
      },
      dataTable: {
        isLoading: false,
        options: null,
        headers: [
          { text: 'index', value: 'id', sortable: false },
          { text: '이름', value: 'name', sortable: false },
          { text: '상점', value: 'store', sortable: false },
          { text: '핸드폰', value: 'phone', sortable: false },
          { text: '생일(성별)', value: 'personalData', sortable: false },
          { text: '생성 시간', value: 'created', sortable: false },
        ],
        items: [],
        totalCount: 0,
        footer: {
          'items-per-page-options': [1, 5, 10, 20, 50, 100],
        },
      },
    }
  },
  watch: {
    'dataTable.options': {
      handler: function (options, oldValue) {
        if (!oldValue) {
          return
        }
        const optionObj = {
          page: options.page,
          size: options.itemsPerPage,
          keyword: options.keyword,
        }
        this.updateOption(optionObj)
      },
      deep: true,
    },
  },
  created() {
    this.dataTable.options = {
      page: this.param.page,
      itemsPerPage: this.param.size,
      keyword: this.param.keyword,
    }
    this.getPageData(this.param)
  },
  methods: {
    updateOption(option) {
      if (this.isQueryPaging === true) {
        return this.$router.push({
          query: option,
        })
      } else {
        this.getPageData(option)
      }
    },
    async getPageData(option) {
      if (this.dataTable.isLoading === true) {
        return
      }
      try {
        this.dataTable.isLoading = true
        const {
          data: {
            paging: { totalCount },
            data,
          },
        } = await this.$fundaNowApi.getUsers(option)
        this.dataTable.items = data
        this.dataTable.totalCount = totalCount
      } finally {
        this.dataTable.isLoading = false
      }
    },
    searchParam() {
      this.dataTable.options = Object.assign(
        this.dataTable.options,
        this.param,
        {
          page: 1,
        }
      )
    },
  },
}
</script>
