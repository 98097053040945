<template>
  <div>
    <v-row class="dashboard-box d-flex" no-gutters>
      <v-col cols="6" class="d-flex align-center">
        <v-row no-gutters>
          <v-col cols="1" class="d-flex align-center">
            <span
              v-show="isMultipleDate === false"
              class="cursor--pointer"
              @click="changeDate('minus')"
            >
              <img
                src="@/assets/images/myfunda/btn-btn-my-left-p.svg"
                style="width: 42px"
              />
            </span>
          </v-col>
          <v-col cols="8">
            <v-row no-gutters>
              <v-col cols="12" md="12" lg="12">
                <p
                  v-if="isMultipleDate"
                  class="mb-0 cursor--pointer font-size--16 text-center"
                  @click="dateModal = true"
                >
                  조회기간

                  <v-icon color="#1baaf1" size="24"> mdi-calendar </v-icon>
                </p>
                <v-dialog
                  ref="dateDialog"
                  v-model="dateModal"
                  width="300px"
                  persistent
                  :return-value.sync="dateRange"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on" class="calendar-text">
                      <template v-if="isMultipleDate">
                        <p class="solid">
                          {{ startDate | dateDot }}
                        </p>
                        <p class="mx-4 font-size--18">~</p>
                        <p class="solid">
                          {{ endDate | dateDot }}
                        </p>
                      </template>
                      <template v-else>
                        <p class="position--relative font-size--16">
                          {{ startDate | dateKorean }}

                          <v-icon color="#1baaf1" size="24">
                            mdi-calendar
                          </v-icon>
                        </p>
                      </template>
                    </div>
                  </template>
                  <v-date-picker
                    v-model="dateRange"
                    :min="minDate"
                    :max="maxDate"
                    :disabled="isLoading"
                    range
                    @input="dateSave"
                  >
                    <div class="width-100 d-flex justify-space-between">
                      <v-checkbox
                        v-model="isMultipleDate"
                        hide-details
                        label="기간으로 조회하기"
                        class="mt-0 mb-2 pa-0"
                      ></v-checkbox>

                      <p
                        class="
                          mb-0
                          color--funda-basic
                          cursor--pointer
                          font-size--16
                          font-weight-bold
                        "
                        style="margin-top: 3px"
                        @click="closeDateDialog()"
                      >
                        취소
                      </p>
                    </div>
                  </v-date-picker>
                </v-dialog>
              </v-col>

              <v-col
                cols="12"
                md="12"
                lg="12"
                class="d-flex align-center justify-center"
              >
                <span class="margin-r-15 color--gray font-size--15">
                  총 상환
                </span>
                <span class="color--black font-weight-black font-size--19">
                  <LoadingText :is-loading="isLoading">
                    {{ summary.totalRepaymentAmount | commaFormat }}
                  </LoadingText>
                </span>
                <span class="color--black font-weight-black font-size--19 ml-1">
                  원
                </span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="3" class="d-flex align-center">
            <span
              v-show="isMultipleDate === false"
              class="cursor--pointer"
              @click="changeDate('plus')"
            >
              <img
                src="@/assets/images/myfunda/btn-btn-my-right-p.svg"
                style="width: 42px"
              />
            </span>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="6">
        <v-row
          v-if="isLoading"
          class="d-flex align-center text-center height-100"
          style="min-height: 80px"
        >
          <v-col cols="12">
            <MyLoader class="mx-auto" />
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="6" class="summary-detail">
            <v-row no-gutters>
              <v-col cols="3" md="3" lg="3">
                <span class="color--gray font-weight-bold">원금</span>
              </v-col>

              <v-col cols="9" md="9" lg="9" class="text-right">
                <span class="color--black font-size--15">
                  {{ summary.repaymentPrincipal | commaFormat }}
                  원
                </span>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="3" md="3" lg="3">
                <span class="color--gray font-weight-bold">세전이자</span>
              </v-col>

              <v-col cols="9" md="9" lg="9" class="text-right">
                <span class="color--black font-size--15">
                  {{ summary.totalInterest | commaFormat }} 원
                </span>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="3" md="3" lg="3">
                <span class="color--gray font-weight-bold">세후이자</span>
              </v-col>

              <v-col cols="9" md="9" lg="9" class="text-right">
                <span class="color--black font-size--15">
                  {{
                    (summary.totalInterest - summary.totalCost) | commaFormat
                  }}
                  원
                </span>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="6" class="summary-detail">
            <v-row no-gutters>
              <v-col cols="3" md="3" lg="3">
                <span class="color--gray font-weight-bold"> 세금 </span>
              </v-col>

              <v-col cols="9" md="9" lg="9" class="text-right">
                <span class="color--black font-size--15">
                  {{ summary.repaymentTax | commaFormat }} 원
                </span>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="3" md="3" lg="3">
                <span class="color--gray font-weight-bold"> 수수료 </span>
              </v-col>

              <v-col cols="9" md="9" lg="9" class="text-right">
                <span class="color--black font-size--15">
                  {{ summary.repaymentFee | commaFormat }} 원
                </span>
              </v-col>
            </v-row>

            <v-row class="opacity-0" no-gutters>
              <v-col cols="3" md="3" lg="3">
                <span class="color--gray font-weight-bold">세후이자</span>
              </v-col>

              <v-col cols="9" md="9" lg="9" class="text-right">
                <span class="color--black font-size--15">
                  {{
                    (summary.totalInterest - summary.totalCost) | commaFormat
                  }}
                  원
                </span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MyLoader from '@/components/funda/MyLoader.vue'
import LoadingText from '@/components/common/text/LoadingText.vue'

export default {
  props: {
    userIdx: {
      required: true,
    },
  },
  components: {
    MyLoader,
    LoadingText,
  },
  data() {
    return {
      isLoading: false,
      isMultipleDate: false,
      dateModal: false,
      dateRange: [new Date().toISOString().substr(0, 10)],
      lastDateRange: [],
      summaryTable: {},
      summary: {
        totalCount: 0,
        repaymentPrincipal: 0,
        repaymentInterest: 0,
        repaymentTax: 0,
        repaymentFee: 0,
        repaymentPressFee: 0,
        totalPI: 0,
        totalInterest: 0,
        totalCost: 0,
        totalRepaymentAmount: 0,
      },
      myRepayments: [],
    }
  },
  computed: {
    startDate() {
      return this.dateRange[0]
    },
    endDate() {
      return this.dateRange[1]
    },
    minDate() {
      if (this.isMultipleDate) {
        return this.startDate
      } else {
        return null
      }
    },
    maxDate() {
      return new Date().toISOString().substr(0, 10)
    },
    summaryKey() {
      const k = this.dateRange.join('_')
      return k
    },
  },
  watch: {
    dateModal(v) {
      if (v === true) {
        this.lastDateRange = this.dateRange
      }
    },
    dateRange: {
      immediate: true,
      deep: true,
      handler(nv, ov) {
        let changed = false

        if (nv?.length < 1) {
          return
        }

        if (!!!ov) {
          ov = []
        }

        const nvStart = nv[0]
        const ovStart = ov[0]

        if (this.isMultipleDate) {
          if (nv.length > 1) {
            const nvEnd = nv[1]
            const ovEnd = ov[1]

            changed = nvStart !== ovStart || nvEnd !== ovEnd
          }
        } else {
          changed = nvStart !== ovStart
        }

        if (changed) {
          this.getSummary()
        }
      },
    },
    isMultipleDate() {
      this.dateRange = []
    },
    summary(v) {
      if (!!v) {
        this.$emit('update:dateRange', this.dateRange)
        this.$emit('update:rpyCount', v.totalCount)
      }
    },
  },
  methods: {
    changeDate(calc) {
      if (this.isMultipleDate) {
        return
      }

      if (this.isLoading) {
        return
      }

      let tempDate = new Date(this.startDate)
      let date = parseInt(tempDate.getDate(), 10)
      if (calc === 'plus') {
        date += 1
      } else if (calc === 'minus') {
        date -= 1
      }
      tempDate.setDate(date)

      if (new Date() < tempDate) {
        return
      }

      this.dateRange = [tempDate.toISOString().substr(0, 10)]
    },
    dateSave(date) {
      if (this.isMultipleDate === true) {
        if (date.length > 1) {
          if (this.startDate === date[1]) {
            this.dateRange = []
          } else {
            this.$refs.dateDialog.save(date)
          }
        }
      } else {
        if (date.length > 1) {
          date = [date[1]]
        } else {
          date = [date[0]]
        }
        this.$refs.dateDialog.save(date)
      }
    },
    closeDateDialog() {
      this.dateRange = this.lastDateRange

      this.dateSave(this.dateRange)
    },
    setSummary() {
      if (!!this.summaryTable[this.summaryKey]) {
        this.summary = this.summaryTable[this.summaryKey]
      } else {
        this.summary = {
          totalCount: 0,
          repaymentPrincipal: 0,
          repaymentInterest: 0,
          repaymentTax: 0,
          repaymentFee: 0,
          repaymentPressFee: 0,
          totalPI: 0,
          totalInterest: 0,
          totalCost: 0,
          totalRepaymentAmount: 0,
        }
      }
    },
    async getSummary() {
      if (this.isLoading) {
        return
      }

      if (!!this.summaryTable[this.summaryKey]) {
        this.setSummary()
        return
      }

      this.isLoading = true

      let q = `data: getMyRepaymentInfo(req: {
          userIdx: ${this.userIdx}
          startDate: "${this.startDate}"
          ${!!this.endDate ? `endDate: "${this.endDate}"` : ''}
          isSummary: true
        }) {
          summary {
            totalCount
            repaymentPrincipal
            repaymentInterest
            repaymentTax
            repaymentFee
            repaymentPressFee
            totalPI
            totalInterest
            totalCost
            totalRepaymentAmount
          }
        }`

      try {
        let data = await this.$fundaApi.query(gql`{${q}}`)

        if (!!data.summary) {
          this.summaryTable[this.summaryKey] = data.summary
        }
      } catch (e) {
        this.$swal.basic.error(e?.message)
      } finally {
        this.setSummary()
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.summary-detail {
  display: flex;
  flex-direction: column;
  min-height: 80px;

  &.mobile {
    display: block;
    min-height: unset;

    > .row {
      margin: 2px 0;
    }
  }

  > .row {
    display: flex;
    align-content: center;
  }
}

.calendar-text {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 45px;
  color: #333333;
  font-weight: 900;
  margin-bottom: 10px;

  > p {
    margin-bottom: 0;
    line-height: 1;
    text-align: center;

    &.solid {
      border: 1px solid rgb(114, 125, 140, 0.5);
      border-radius: 4px;
      font-size: 14px;
      padding: 10px 20px 7px;
    }

    > i {
      position: absolute;
      top: -15px;
      right: -27px;
    }
  }
}

ul.my-schedule-list > li > div.row > div:nth-child(2) {
  padding-left: 25px;
}

.schedule-summary {
  &__dropdown {
    position: absolute;
    width: 100%;
    left: 0;
    margin-top: 5px !important;
    padding: 21px !important;
    border-radius: 0 0 8px 8px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
    background-color: #f6f8fa;
    z-index: 99;
  }
}
</style>
