import Vue from 'vue'
import pluginValue from '@/assets/scss/common/color.scss'
const pluginName = '$colors'

const plugin = {
  install(Vue) {
    Vue.prototype[pluginName] = pluginValue
  },
}

Vue.use(plugin)

export default plugin
