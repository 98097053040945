import axios from 'axios'
// import Vue from 'vue'
import AuthService from '@/service/auth/AuthService'

// funda-now-api로의 프록시
const _axios = axios.create({
  baseURL: `${process.env.common.fundaApi.host}/px/now-api`,
})

_axios.interceptors.request.use(
  (config) => {
    if (config.isInterceptor !== false) {
      if (
        config.headers.Authorization == null ||
        config.headers.Authorization.startsWith('Basic') === false
      ) {
        let token = localStorage.getItem(process.env.common.token.name)
        if (token != null) {
          config.headers.Authorization = `Bearer ${token}`
        }
      }
    }

    return config
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error)
  }
)

// Add a response interceptor
_axios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response.status === 401) {
      location.reload()
    }
    // Do something with response error
    return Promise.reject(error.response)
  }
)

_axios.call = async function (option) {
  if (option.data != null && option.data.isAwait === false) {
    delete option.data.isAwait
  } else {
    await AuthService.sync()
  }
  return _axios({ method: 'POST', data: option })
}

export default _axios
