import paymentApi from '../../plugins/api/payment'
import store from '@/store'
let isLoading = false
let isLoaded = false

const st = {
  name: 'payment/bank',
  namespaced: true,
  state: {
    banks: [],
  },
  getters: {
    getBanks(state) {
      store.dispatch(`${st.name}/init`)
      return state.banks
    },
    getBank: (state) => (code) => {
      store.dispatch(`${st.name}/init`)
      return state.banks.find((el) => el.code === code)
    },
  },
  mutations: {
    setBanks(state, banks) {
      state.banks = banks
    },
  },
  actions: {
    async init({ commit }) {
      if (isLoading || isLoaded) {
        return false
      }
      console.log('로딩?')
      try {
        isLoading = true
        const { data } = await paymentApi.getAllowBanks()
        commit('setBanks', data)
        isLoaded = true
      } finally {
        isLoading = false
      }
      return true
    },
  },
}

export default st
