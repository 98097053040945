<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12" sm="12" md="12">
        <h3 class="text-center">전송 리스트</h3>
      </v-col>
    </v-row>

    <v-row class="mt-3" no-gutters>
      <v-col cols="12" sm="12" md="12">
        <v-tabs
          v-model="tab"
          height="60"
          fixed-tabs
          color="#ffffff"
          background-color="#393939"
          dark
        >
          <v-tab @click="showTab = 'byFundingDataIdx'"> 상품호수별 </v-tab>

          <v-tab @click="showTab = 'findAll'"> 전체 </v-tab>
        </v-tabs>
      </v-col>
    </v-row>

    <v-card min-width="100%" min-height="400" flat>
      <v-tabs-items v-model="tab" style="width: 100%">
        <v-tab-item>
          <template v-if="showTab === 'byFundingDataIdx'"> </template>
        </v-tab-item>

        <v-tab-item>
          <template v-if="showTab === 'findAll'">
            <v-row no-gutters>
              <v-col cols="12" sm="12" md="12" class="py-2">
                <v-data-table
                  loading-text="데이터를 가져오는 중입니다..."
                  :headers="headers"
                  :loading="isLoading"
                  :items="smsDatas"
                  :items-per-page="30"
                  :footer-props="{ 'items-per-page-options': [10, 30, 50, -1] }"
                  :server-items-length="paging.totalCount"
                  @update:options="changePage"
                >
                  <template v-slot:item="{ item }">
                    <tr class="clickable-data-row" style="cursor: pointer">
                      <td class="text-center">{{ item.fromNumber }}</td>
                      <td class="text-center">{{ item.userIdx }}</td>
                      <td class="text-center">{{ item.fundingDataIdx }}</td>
                      <td class="text-center">{{ item.toNumber }}</td>
                      <td class="text-center py-2" style="white-space: pre">
                        {{ item.msg }}
                      </td>
                      <td
                        class="text-center"
                        :class="item.status ? 'blue--text' : 'red--text'"
                      >
                        {{ item.status ? '성공' : '실패' }}
                      </td>
                      <td class="text-center">
                        {{ item.createTime | datetimeFormat }}
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </template>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: true,
      tab: null,
      showTab: 'creditorFunding',
      headers: [
        {
          text: '보낸번호',
          value: 'fromNumber',
          align: 'center',
          sortable: false,
        },
        { text: 'to유저', value: 'userIdx', align: 'center', sortable: false },
        {
          text: 'to채권',
          value: 'fundingDataIdx',
          align: 'center',
          sortable: false,
        },
        {
          text: '받은번호',
          value: 'toNumber',
          align: 'center',
          sortable: false,
        },
        { text: 'message', value: 'msg', align: 'center', sortable: false },
        { text: '상태', value: 'status', align: 'center' },
        { text: '전송일시', value: 'createTime', align: 'center' },
      ],
      search: {
        page: 1,
        size: 30,
      },
      paging: {
        page: 1,
        size: 30,
        totalCount: 0,
      },
      smsDatas: [],
    }
  },
  created() {},
  methods: {
    changePage(option) {
      this.search.size =
        option.itemsPerPage === -1
          ? this.paging.totalCount
          : option.itemsPerPage
      this.search.page = option.page
      this.getData()
    },
    async getData() {
      let q = `
        smses: getSmsesByCreditor(paging: {
          page: ${this.search.page}
          size: ${this.search.size}
        }) {
          data {
            idx
            userIdx
            fundingDataIdx
            fromNumber
            toNumber
            msg
            status
            createTime
          }
          paging {
            size
            page
            totalCount
          }
        }
      `

      let { smses } = await this.$fundaApi.query(gql`{${q}}`)

      this.smsDatas = smses.data
      this.paging = smses.paging

      this.isLoading = false
    },
  },
}
</script>
