<template>
  <LoadingText
    :is-loading="balanceLoading"
    :loading-size="loadingSize"
    :loading-width="loadingWidth"
  >
    {{ userWithdraw | commaFormat }} 원
  </LoadingText>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import LoadingText from './LoadingText'

export default {
  props: {
    userIdx: {
      required: true,
    },
    type: {
      type: String,
      default: 'withdraw',
    },
    loadingSize: {
      default: 16,
    },
    loadingWidth: {
      default: 2,
    },
  },
  components: {
    LoadingText,
  },
  computed: {
    ...mapGetters('www/withdraw', {
      balanceLoading: 'isLoading',
      userWithdraw: 'getUserWithdraw',
    }),
  },
  created() {
    this.getUserWithdraw(this.userIdx)
  },
  methods: {
    ...mapActions('www/withdraw', ['getUserWithdraw']),
  },
}
</script>
