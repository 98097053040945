<template>
  <section>
    <RepaymentSummary
      :userIdx="userIdx"
      :dateRange.sync="searchDateRange"
      :rpyCount.sync="totalCount"
    />

    <RepaymentTable
      :userIdx="userIdx"
      :dateRange="searchDateRange"
      :totalCount="totalCount"
    />
  </section>
</template>

<script>
import RepaymentSummary from './RepaymentSummary.vue'
import RepaymentTable from './RepaymentTable.vue'

export default {
  props: {
    userIdx: {
      required: true,
    },
  },
  components: {
    RepaymentSummary,
    RepaymentTable,
  },
  data() {
    return {
      searchDateRange: [],
      totalCount: 0,
    }
  },
}
</script>
