import AuthService from '@/service/auth/AuthService'
const ignores = ['signin', 'signoutAuto']

// ignore router 체크
function isIgnore(to) {
  for (let i = 0; i < ignores.length; i++) {
    if (ignores[i] === to.name) {
      return true
    }
  }
  return false
}

/**
 * 토큰 갱신용 인터셉터
 */
export default async (to, from, next) => {
  // 비 로그인 시 로그인 페이지로 redirect
  if (isIgnore(to) === false && AuthService.isSignin() === false) {
    next({ path: `/signin?redirectUri=${AuthService.getRedirectUri(to)}` })
    return
  }
  next()
}
