<template>
  <v-container fluid class="pt-0">
    <PageHeader title="페이지 제목" subtitle="페이지 설명입니다" />
    <v-row class="pt-3">
      <v-col lg="4" md="6" sm="6" cols="12">
        <PageSection title="유저 기본 정보">
          <template slot="content">
            <v-list class="subtitle-1 pa-0">
              <v-list-item class="px-0 pt-0 pb-0">
                <v-list-item-content>
                  <v-list-item-subtitle>유저아이디</v-list-item-subtitle>
                  <v-list-item-title class="title font-weight-bold"
                    >funda-admin</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="px-0 pt-0 pb-0">
                <v-list-item-content>
                  <v-list-item-subtitle>유저이름</v-list-item-subtitle>
                  <v-list-item-title class="title font-weight-bold"
                    >가나다</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="pa-0">
                <v-list-item-content>
                  <v-list-item-subtitle>휴대폰번호</v-list-item-subtitle>
                  <v-list-item-title>
                    <v-text-field
                      value="01012345678"
                      class="title font-weight-bold"
                      placeholder="휴대폰번호"
                      hide-details
                      filled
                      :background-color="colors['f-white-blue-4']"
                    >
                    </v-text-field>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item dense class="pa-0">
                <v-list-item-content class="pa-0 pl-2">
                  <v-checkbox label="어드민 여부"></v-checkbox>
                </v-list-item-content>
                <v-list-item-content class="pa-0 pl-2">
                  <v-checkbox label="어드민 여부"></v-checkbox>
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="pa-0">
                <v-list-item-content>
                  <v-list-item-subtitle>유저등급</v-list-item-subtitle>
                  <v-list-item-title>
                    <v-select
                      :items="[{ text: '선택하세요', value: null }, 1, 2, 3]"
                      :item-disabled="(item) => item.value === null"
                      v-model="user.select"
                      hide-details
                      filled
                      :background-color="colors['f-white-blue-4']"
                    ></v-select>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </template>
          <template slot="action">
            <v-btn
              large
              class="subtitle-1 font-weight-black"
              color="warning"
              block
            >
              유저 정보 수정
            </v-btn>
          </template>
        </PageSection>
      </v-col>
      <v-col lg="3" md="6" sm="6" cols="12">
        <PageSection title="투자 정보" :isLoading="user.invest.isLoading">
          <template v-if="user.invest.info != null" slot="content">
            <v-list dense class="subtitle-1 pa-0">
              <v-list-item class="px-0 pt-0 pb-0">
                <v-list-item-content>
                  <v-list-item-subtitle>누적 투자액(ALL)</v-list-item-subtitle>
                  <v-list-item-title class="subtitle-1 font-weight-bold">
                    {{
                      user.invest.info.investmentAndRepayment.investmentAmount
                        | commaFormat
                    }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="px-0 pt-0 pb-0">
                <v-list-item-content>
                  <v-list-item-subtitle>누적 상환액(ALL)</v-list-item-subtitle>
                  <v-list-item-title class="subtitle-1 font-weight-bold">
                    {{
                      user.invest.info.investmentAndRepayment.repaymentPrincipal
                        | commaFormat
                    }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="px-0 pt-0 pb-0">
                <v-list-item-content>
                  <v-list-item-subtitle
                    >누적 투자원금(ALL)</v-list-item-subtitle
                  >
                  <v-list-item-title class="subtitle-1 font-weight-bold">
                    {{
                      user.invest.info.investmentAndRepayment
                        .pureInvestmentAmount | commaFormat
                    }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="px-0 pt-0 pb-0">
                <v-list-item-content>
                  <v-list-item-subtitle>투자자타입</v-list-item-subtitle>
                  <v-list-item-title class="subtitle-1 font-weight-bold"
                    >개인 투자자</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="px-0 pt-0 pb-0">
                <v-list-item-content>
                  <v-list-item-subtitle>잔여투자원금</v-list-item-subtitle>
                  <v-list-item-title class="subtitle-1 font-weight-bold"
                    >무제한</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-divider class="my-2" />
              <v-list-item class="px-0 pt-0 pb-0">
                <v-list-item-content>
                  <v-list-item-subtitle
                    >펀다나우 누적투자액</v-list-item-subtitle
                  >
                  <v-list-item-title class="subtitle-1 font-weight-bold"
                    >1,234,567</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="px-0 pt-0 pb-0">
                <v-list-item-content>
                  <v-list-item-subtitle
                    >펀다나우 누적투자원금</v-list-item-subtitle
                  >
                  <v-list-item-title class="subtitle-1 font-weight-bold"
                    >1,234,567</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </template>
          <template v-else slot="action">
            <v-btn
              large
              class="subtitle-1 font-weight-black"
              block
              color="primary"
              @click="getInvestInfo"
              :loading="user.invest.isLoading"
              :disabled="user.invest.isLoading"
            >
              투자 정보 불러오기
            </v-btn>
          </template>
        </PageSection>
      </v-col>
      <v-col lg="3" md="6" sm="6" cols="12">
        <PageSection title="form" :isLoading="form1.isLoading">
          <template slot="content">
            <VeeTextField
              v-model="form1.amount"
              name="widthraw_amount"
              :rules="`required|numeric|min_value:1|max_value:${balance}`"
            />
          </template>
        </PageSection>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <PageSection title="투자 내역">
          <template slot="content"> </template>
        </PageSection>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import VeeTextField from '../../components/common/form/TestVeeTextField.vue'
import testData from './testData'

export default {
  components: {
    VeeTextField,
  },
  computed: {
    errorMsg() {
      console.log(this.$v.form2.amount.required)
      if (this.$v.form2.amount.required === false) {
        return '출금할 금액을 입력하세요'
      } else if (this.$v.form2.amount.numeric === false) {
        return '숫자만 입력 가능합니다.'
      } else if (this.$v.form2.amount.minValue === false) {
        return '1원부터 출금 신청이 가능합니다'
      } else if (this.$v.form2.amount.maxValue === false) {
        return `출금 가능 금액(${this.balance})을 초과했습니다.`
      } else {
        return null
      }
    },
  },
  data() {
    return {
      balance: 1000,
      form1: {
        amount: null,
        isLoading: false,
      },
      form2: {
        amount: null,
      },
      user: {
        select: null,
        invest: {
          isLoading: false,
          data: null,
        },
      },
      colors: this.$colors,
      grid: testData.grid,
      transactions: testData.transactions,
    }
  },
  created() {
    console.log(this.$v)
  },
  methods: {
    async getInvestInfo() {
      if (this.user.invest.isLoading) {
        return
      }
      this.user.invest.isLoading = true
      await setTimeout((_) => {
        this.user.invest.info = {
          investmentAndRepayment: {
            investmentAmount: 1278316,
            repaymentPrincipal: 1208694,
            pureInvestmentAmount: 69622,
          },
          investmentAmount: 1278316,
          pureInvestmentAmount: 69622,
          repaymentPrincipal: 1208694,
          investmentBalance: 69622,
          totalInvestmentAmount: 1238316,
        }
        this.user.invest.isLoading = false
      }, 3000)
    },
  },
}
</script>
<style lang="scss" scoped>
.tui-grid-container {
  font-family: NanumSquare;
}
</style>
