<template>
  <div class="width-100">
    <v-row no-gutters>
      <v-col cols="12">
        <v-card width="100%">
          <v-card-title class="justify-center"> 유저 검색 </v-card-title>
          <v-card-text>
            <v-row class="my-2" no-gutters>
              <v-col v-if="shortcut === false" cols="2" sm="2" md="2"> </v-col>
              <v-col :cols="shortcut ? 10 : 8">
                <v-row no-gutters>
                  <v-col cols="12" class="d-flex align-center">
                    <div class="pr-2" style="width: 150px">
                      <v-select
                        v-model="searchType"
                        :items="searchItems"
                        item-text="text"
                        item-value="value"
                        label="검색 타입"
                        height="56"
                        hide-details
                        dense
                        outlined
                      >
                      </v-select>
                    </div>
                    <v-text-field
                      v-model.trim="search.keyword"
                      :disabled="getDataLoading"
                      hide-details
                      placeholder="검색어를 입력하세요(idx/id/이름/휴대폰 등)"
                      @keyup.enter="getUsersByKeyword()"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="2" class="d-flex align-center">
                <v-btn
                  :disabled="search.keyword.length < 1 || getDataLoading"
                  :loading="getDataLoading"
                  color="#393939"
                  class="white--text"
                  height="41"
                  depressed
                  @click="getUsersByKeyword()"
                >
                  검색
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col cols="12">
        <v-data-table
          no-data-text="이곳에 유저정보가 표시됩니다."
          no-results-text="유저정보가 없습니다."
          loading-text="데이터를 가져오는 중입니다..."
          :headers="showHeaders"
          :loading="getDataLoading"
          :items="users"
          :footer-props="{ 'items-per-page-options': [20, 50, 100, -1] }"
          :server-items-length="paging.totalCount"
          @update:options="changePage"
        >
          <template v-slot:item="{ item }">
            <tr
              class="clickable-data-row cursor--pointer"
              :class="selectedUser.idx == item.idx ? 'selected' : ''"
              @click="selectUser(item)"
            >
              <td class="text-center">
                <a @click="openDetails(item.idx)">
                  {{ item.idx }}
                </a>
              </td>
              <td class="text-center">
                <a @click="openDetails(item.idx)">
                  {{ item.id }}
                </a>
              </td>
              <td class="text-center">{{ item.name }}</td>
              <td class="text-center">{{ item.userPhone }}</td>
              <template v-if="shortcut === false">
                <td class="text-center">{{ item.email }}</td>
                <td class="text-center">
                  {{ `${age(item.userBirthdate)}세/${item.gender}` }}
                </td>
                <td class="text-center">
                  {{ item.registerDate | dateFormat }}
                </td>
                <td class="text-center">{{ item.affiliateCode }}</td>
                <td
                  class="text-center"
                  @click="
                    openRecommenderDialog(
                      !!item.codeRecommender ? item.codeRecommender.code : ''
                    )
                  "
                >
                  {{ !!item.codeRecommender ? item.codeRecommender.code : '' }}
                </td>
                <td class="text-center" style="max-width: 100px">
                  <template v-if="!!item.listUsedCode">
                    <p
                      class="ma-0"
                      @click="
                        openRecommenderDialog(
                          item.listUsedCode.codeRecommender.code
                        )
                      "
                    >
                      {{ item.listUsedCode.codeRecommender.code }}
                    </p>
                    (<a
                      @click="
                        openDetails(item.listUsedCode.codeRecommender.user.idx)
                      "
                      >{{ item.listUsedCode.codeRecommender.user.id }}</a
                    >)
                  </template>
                </td>
              </template>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col cols="12">
        <v-dialog v-model="recommender.dialog" width="1300px">
          <v-card>
            <v-card-title>
              <span class="headline">
                {{ recommender.title }}
              </span>
            </v-card-title>
            <v-card-text>
              <keep-alive>
                <ListUsedCode
                  :key="recommender.code"
                  :recommender-code="recommender.code"
                ></ListUsedCode>
              </keep-alive>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="green darken-1"
                text
                @click="recommender.dialog = false"
              >
                닫기
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import ListUsedCode from '@/components/funda/user/ListUsedCode'
export default {
  props: {
    userIdx: {
      type: Number,
      default: null,
    },
    getFirst: {
      type: Boolean,
      default: false,
    },
    shortcut: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ListUsedCode,
  },
  data() {
    return {
      getDataLoading: false,
      searchItems: [
        {
          text: '일반',
          value: 'idx',
        },
        {
          text: '추천인코드',
          value: 'code',
        },
      ],
      searchType: 'idx',
      headers: [
        { text: '인덱스', value: 'idx', align: 'center', width: '110px' },
        {
          text: '아이디',
          value: 'id',
          align: 'center',
          sortable: false,
        },
        {
          text: '이름',
          value: 'name',
          align: 'center',
          width: '110px',
          sortable: false,
        },
        {
          text: '휴대폰번호',
          value: 'userPhone',
          align: 'center',
          sortable: false,
        },
        {
          text: '이메일',
          value: 'email',
          align: 'center',
          sortable: false,
          shortcut: false,
        },
        {
          text: '나이(만)/성별',
          align: 'center',
          width: '100px',
          sortable: false,
          shortcut: false,
        },
        {
          text: '가입일',
          value: 'registerDate',
          align: 'center',
          sortable: false,
          shortcut: false,
        },
        {
          text: '프로모션',
          value: 'affiliateCode',
          align: 'center',
          sortable: false,
          shortcut: false,
        },
        {
          text: '추천인',
          align: 'center',
          sortable: false,
          shortcut: false,
        },
        {
          text: '피추천인',
          align: 'center',
          width: '100px',
          sortable: false,
          shortcut: false,
        },
      ],
      selectedUser: {
        idx: null,
      },
      users: [],
      search: {
        page: 1,
        size: 20,
        keyword: '',
      },
      paging: {
        page: 1,
        size: 20,
        totalCount: 0,
      },
      recommender: {
        dialog: false,
        code: null,
        title: null,
      },
    }
  },
  computed: {
    showHeaders() {
      return this.headers.filter((h) => {
        if (Object.prototype.hasOwnProperty.call(h, 'shortcut')) {
          return h.shortcut === this.shortcut
        } else {
          return true
        }
      })
    },
    age() {
      return (userBirthDate) => {
        if (!!!userBirthDate) {
          return '-'
        }

        let now = new Date()
        let d = new Date(userBirthDate)

        return parseInt(now.getFullYear()) - parseInt(d.getFullYear())
      }
    },
  },
  created() {
    if (!!this.userIdx) {
      this.search.keyword = this.userIdx
    }

    if (this.getFirst || !!this.userIdx) {
      this.getUsersByKeyword()
    }
  },
  methods: {
    changePage(option) {
      if (this.paging.page === option.page) {
        return
      }

      let itemsPerPage = parseInt(option.itemsPerPage)
      this.search.size =
        itemsPerPage === -1 ? this.paging.totalCount : itemsPerPage
      this.search.page = option.page

      this.getUsersByKeyword()
    },
    async getUsersByKeyword() {
      this.getDataLoading = true

      try {
        let q = `
            users: getUsersByKeyword(req: {
                page: ${this.search.page}
                size: ${this.search.size}
                keyword: "${this.search.keyword}"
              }) {
              data {
                idx
                id
                name
                email
                userPhone
                userBirthdate
                gender
                isAdmin
                registerDate
                lastLoginDate
                investmentLimitType
                affiliateCode
                memo

                codeRecommender {
                  code
                }
                listUsedCode {
                  codeRecommender {
                    code
                    user {
                      idx
                      id
                    }
                  }
                }
              },

              paging {
                size
                page
                totalCount
              }
            }
        `

        let { users } = await this.$fundaApi.query(gql`{${q}}`)

        this.users = users.data
        this.paging = users.paging

        if (this.userIdx === this.search.keyword) {
          this.selectUser(this.users[0])
        }
      } finally {
        this.getDataLoading = false
      }
    },
    selectUser(user) {
      if (user.idx === null || this.selectedUser.idx === user.idx) {
        return
      }
      console.log('selectUser!')
      this.selectedUser = user
      this.$emit('selectUser', user)
    },
    openDetails(idx) {
      let r = this.$router.resolve({
        name: 'fundaUserDetails',
        params: { userIdx: idx },
      })

      window.open(r.href, '_blank')
    },
    openRecommenderDialog(code) {
      this.recommender.title = `${code} 를 추천한 유저목록`
      this.recommender.code = code
      this.recommender.dialog = true
    },
  },
}
</script>
<style lang="scss" scoped>
.page {
  &__content {
    min-height: 400px;
  }
}
</style>
