<template>
  <div class="px-3" style="padding-bottom: 100px">
    <PageHeader title="고객 인증 신청 목록" />

    <v-row class="my-3" no-gutters>
      <v-col cols="12" sm="2" md="2" lg="2" class="mb-3 pr-2">
        <v-card>
          <v-card-text>
            <v-btn
              color="#393939"
              class="white--text mr-2"
              width="100%"
              height="40px"
              @click="$router.push({ name: 'reqChangeLimit' })"
            >
              타입 변경 요청
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="2" md="2" lg="2" class="mb-3 pr-2">
        <v-card>
          <v-card-text>
            <v-btn
              color="#393939"
              class="white--text"
              width="100%"
              height="40px"
              @click="changeStatus()"
            >
              {{ currentStatus === 'WAITING' ? '완료목록' : '대기목록' }} 보기
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="2" md="2" lg="2" class="mb-3 pr-2">
        <v-card>
          <v-card-text class="d-flex justify-space-between">
            <v-btn
              v-for="limitType in investmentLimitTypes"
              :key="'btn_' + limitType.text"
              :color="
                userAuthsearch.investmentLimitType === limitType.value
                  ? '#1baaf1'
                  : '#393939'
              "
              class="white--text mr-2"
              width="90px"
              height="40px"
              @click="changeLimitType(limitType.value)"
            >
              {{ limitType.text }}
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="6" lg="6" class="mb-3">
        <v-card>
          <v-card-text>
            <form @submit.prevent="search(userAuthsearch.keyword)">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    label="검색어"
                    v-model="userAuthsearch.keyword"
                    outlined
                    hide-details
                    dense
                    clearable
                    append-icon="mdi-magnify"
                    @click:append="search(userAuthsearch.keyword)"
                    @click:clear="search(null)"
                    v-bind="attrs"
                    v-on="on"
                  >
                  </v-text-field>
                </template>
                <span> 검색 가능: 유저인덱스 </span>
              </v-tooltip>
            </form>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" v-show="false">
        <SendAuthTalk ref="sendAuthTalk" />
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col cols="12">
        <v-card>
          <v-card-title class="align-end justify-space-between">
            <p class="ma-0">
              [ {{ currentStatus === 'WAITING' ? '대기' : '완료' }} ] 리스트
            </p>

            <template v-if="currentStatus === 'APPROVAL'">
              <v-btn
                icon
                :loading="exportExcelLoading"
                :disabled="exportExcelLoading"
                @click="downloadExcel()"
              >
                <v-icon>mdi-export-variant</v-icon>
              </v-btn>

              <div style="position: absolute; opacity: 0">
                <ExportExcelButton2
                  ref="btnExportExcel"
                  :headers="exportHeaders"
                  :options="{ skipHeader: true }"
                  fileName="유저_인증완료"
                >
                </ExportExcelButton2>
              </div>
            </template>
          </v-card-title>

          <v-card-text>
            <v-data-table
              :headers="headers"
              :footer-props="footer"
              :items="userAuthList"
              :loading="isLoading"
              class="elevation-2"
              @update:options="changePage"
              @click:row="openDetails"
            >
              <template v-slot:[`item.investmentLimit.type`]="{ item }">
                <span
                  class="font-weight-bold"
                  :class="
                    item.investmentLimit.idx > 1 ? 'error--text' : 'black--text'
                  "
                >
                  {{ item.investmentLimit.type }}
                </span>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>

        <v-dialog v-model="dialog.details" max-width="1500px" width="90%">
          <v-card v-if="!!selectedUser">
            <v-card-title>
              <span class="headline">
                {{ selectedUser.idx }} // {{ selectedUser.id }} //
                {{ selectedUser.investmentLimit.type }}
              </span>
            </v-card-title>
            <v-card-text>
              <keep-alive>
                <UserAuthDetails
                  :key="selectedUser.id"
                  :userIdx="selectedUser.idx"
                  :isApproval="currentStatus === 'APPROVAL'"
                  @decision-complete="decisionComplete"
                ></UserAuthDetails>
              </keep-alive>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="green darken-1"
                text
                @click="dialog.details = false"
              >
                닫기
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ExportExcelButton2 from '@/components/common/datatable/ExportExcelButton2.vue'
import UserAuthDetails from '@/components/funda/user/auth/UserAuthDetails.vue'
import SendAuthTalk from '@/components/alimtalk/funda/SendAuthTalk.vue'

export default {
  components: {
    ExportExcelButton2,
    UserAuthDetails,
    SendAuthTalk,
  },
  data() {
    return {
      isLoading: false,
      userAuthsearch: {
        page: 0,
        size: 20,
        keyword: null,
        userIdx: null,
        isValidIdentification: false,
        isValidEdd: false,
        investmentLimitType: null,
      },
      investmentLimitTypes: [
        {
          text: '개인',
          value: 1,
        },
        {
          text: '법인',
          value: 3,
        },
      ],
      headers: [
        { text: '인덱스', value: 'idx', align: 'center', width: '8%' },
        { text: '아이디', value: 'id', align: 'center', sortable: false },
        { text: '이름', value: 'name', align: 'center', sortable: false },
        {
          text: '휴대폰',
          value: 'userPhone',
          align: 'center',
          sortable: false,
        },
        {
          text: '생년월일',
          value: 'userBirthdate',
          align: 'center',
          sortable: false,
        },
        {
          text: '투자자 타입',
          value: 'investmentLimit.type',
          align: 'center',
          sortable: false,
        },
        {
          text: '투자자 메모',
          value: 'memo',
          align: 'center',
          sortable: false,
        },
        {
          text: '입력코드',
          value: 'affiliateCode',
          align: 'center',
          sortable: false,
        },
      ],
      footer: {
        'items-per-page-options': [20, 30, 40, 50],
      },
      exportHeaders: [
        { text: '인덱스', value: 'idx' },
        { text: '아이디', value: 'id' },
        { text: '이름', value: 'name' },
        { text: '휴대폰', value: 'userPhone' },
        { text: '문자수신여부', value: 'promotionSms' },
        { text: '투자자타입 번호', value: 'limitIdx' },
        { text: '투자자타입', value: 'limitText' },
        { text: '휴대폰 인증 일시', value: 'phoneValidTime' },
        { text: '출금계좌 인증 일시', value: 'identificationValidTime' },
        { text: '신분증 승인 일시', value: 'accountValidTime' },
        { text: 'EDD 승인 일시', value: 'eddValidTime' },
      ],
      dialog: {
        details: false,
      },
      selectedUser: null,
      userAuthList: [],
      exportExcelLoading: false,
      excelData: null,
    }
  },
  computed: {
    currentStatus() {
      if (this.userAuthsearch.isValidIdentification === true) {
        return 'APPROVAL'
      } else {
        return 'WAITING'
      }
    },
  },
  watch: {
    selectedUser(n) {
      this.$refs.sendAuthTalk.setUser(n?.name, n?.userPhone)
    },
  },
  methods: {
    async sendTalk() {
      await this.$refs.sendAuthTalk.send()
    },
    changeStatus() {
      let query = !this.userAuthsearch.isValidIdentification
      this.userAuthsearch.isValidIdentification = query
      this.userAuthsearch.isValidEdd = query
      this.userAuthsearch.investmentLimitType = null

      this.changePage({
        page: 1,
        itemsPerPage: this.userAuthsearch.size,
      })
    },
    changeLimitType(v) {
      let value = v
      if (this.userAuthsearch.investmentLimitType === v) {
        value = null
      }
      this.userAuthsearch.investmentLimitType = value

      this.changePage({
        page: 1,
        itemsPerPage: this.userAuthsearch.size,
      })
    },
    changePage(option) {
      const page = option.page
      const size = option.itemsPerPage

      if (page < 1) {
        return
      }

      this.userAuthsearch.page = page
      this.userAuthsearch.size = size

      this.getUserAuthList()
    },
    openDetails(item) {
      this.selectedUser = item
      this.dialog.details = true
    },
    search(keyword) {
      const intKeyword = parseInt(keyword)
      if (isNaN(intKeyword) === false) {
        this.userAuthsearch.userIdx = intKeyword
      }

      this.getUserAuthList()
    },
    decisionComplete(isApproval) {
      if (isApproval === true) {
        this.sendTalk()
      }
      this.selectedUser = null
      this.dialog.details = false
      this.getUserAuthList()
    },
    async downloadExcel() {
      this.exportExcelLoading = true

      try {
        if (!!!this.excelData) {
          const q = 'data: getValidUsers'
          let data = await this.$fundaApi.query(gql`{${q}}`)
          data = JSON.parse(data)
          this.excelData = data
        }

        this.$refs.btnExportExcel.setExcelData(this.excelData)
        this.$refs.btnExportExcel.download()
      } finally {
        this.exportExcelLoading = false
      }
    },
    async getUserAuthList() {
      this.isLoading = true

      const q = `
        data: getUserAuthList(search: $input) {
          idx
          id
          name
          userPhone
          userBirthdate
          regNum
          address
          affiliateCode
          memo
          promotionSms

          investmentLimit {
            idx
            type
          }
        }
      `

      try {
        let data = await this.$fundaApi.query(
          gql`{${q}}`,
          'call($input: UserAuthSearch)',
          { input: this.userAuthsearch }
        )

        this.userAuthList = data
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
