<template>
  <v-container fluid class="pt-0">
    <PageHeader title="가상계좌간 이체" subtitle="가상계좌간 이체">
    </PageHeader>
    <MultipleTransfer />
  </v-container>
</template>
<script>
import MultipleTransfer from '../../../../../components/payment/user/virtualAccount/transfer/MultipleTransfer.vue'
export default {
  components: { MultipleTransfer },
}
</script>
