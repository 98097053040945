<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12"> </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  created() {},
  methods: {},
}
</script>

<style lang="scss" scoped></style>
