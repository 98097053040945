/* eslint-disable no-async-promise-executor */
import store from '@/store'
import { jsLoad } from '@/util'
import fundaApi from '@/plugins/api/api'

const TOKEN_NAME = process.env.common.token.name
const REFRESH_TOKEN_NAME = process.env.common.token.refreshTokenName
const GOOGLE_APP_ID = process.env.common.sns.google.appId

function getRedirectUri(to) {
  let uri
  if (to) {
    uri = to.fullPath
  } else {
    uri = location.pathname + location.search
  }
  return uri
}

const getToken = () => {
  if (localStorage.getItem(TOKEN_NAME) === 'undefined') {
    localStorage.clear()
  }

  return {
    access_token: localStorage.getItem(TOKEN_NAME),
    refresh_token: localStorage.getItem(REFRESH_TOKEN_NAME),
  }
}

function signout() {
  localStorage.removeItem(TOKEN_NAME)
  localStorage.removeItem(REFRESH_TOKEN_NAME)
  store.dispatch('auth/signout')
}

// 값 셋팅
async function setSignin(token) {
  localStorage.setItem(TOKEN_NAME, token.access_token)
  localStorage.setItem(REFRESH_TOKEN_NAME, token.refresh_token)
  return await store.dispatch('auth/signin')
}

// 성공시에 호출
async function signinSuccess(token) {
  await setSignin(token)
  expireTokenProcess()
  return token
}

let isLoaded = false
function init() {
  return new Promise(async (resolve, reject) => {
    if (isLoaded === true) {
      resolve(isLoaded)
    }
    try {
      await jsLoad({
        id: 'gapi',
        src: '//apis.google.com/js/platform.js',
      })

      gapi.load('auth2', () => {
        gapi.auth2.init({ client_id: GOOGLE_APP_ID })
        isLoaded = true
        resolve(isLoaded)
      })
    } catch (e) {
      reject(e)
    }
  })
}

function sync() {
  return new Promise(async (resolve, reject) => {
    if (refreshLoading) {
      resolves.push(resolve)
    } else if (
      refreshLoading === false &&
      store.state.auth.info != null &&
      authService.isTokenExpire()
    ) {
      refreshLoading = true
      resolves.push(resolve)
      try {
        await authService.refresh()
      } catch (e) {
        signout()
        global.$vm._router.push({
          name: 'signin',
          query: { redirectUri: getRedirectUri() },
        })
      } finally {
        while (resolves.length > 0) {
          const r = resolves.pop()
          r(getToken())
        }
        refreshLoading = false
      }
    } else {
      resolve(getToken().token)
    }
  })
}

// 자동 로그아웃
let autoSignoutTimeout = null
function expireTokenProcess() {
  clearTimeout(autoSignoutTimeout)
  // 자동 타임아웃 시간
  const autoExpTime =
    store.getters['auth/getRefreshTokenExpireTime'] - Date.now()
  autoSignoutTimeout = setTimeout((_) => {
    authService.signout()
    global.$vm._router.push({
      path: '/signout/auto',
      query: { redirectUri: getRedirectUri() },
    })
  }, autoExpTime)
}

// function getHttpBasicAuth() {
//   return btoa(`${client.id}:${client.secret}`)
// }

let refreshLoading = false
const resolves = [] // 토큰 갱신시 블럭걸린 resolve
const authService = {
  isSignin() {
    return store.getters['auth/isSignin']
  },
  isTokenExpire() {
    return store.getters['auth/getTokenExpireTime'] < Date.now()
  },
  isRefreshTokenExpire() {
    return store.getters['auth/getRefreshTokenExpireTime'] < Date.now()
  },
  getToken,
  hasAnyRole(roles) {
    return store.getters['auth/hasAnyRole'](roles)
  },
  signin() {
    return new Promise(async (resolve, reject) => {
      try {
        await init()
        const GoogleAuth = gapi.auth2.getAuthInstance()
        const googleRes = await GoogleAuth.signIn()
        let accessToken
        for (const k in googleRes) {
          const o = googleRes[k]
          if (typeof o === 'object' && o.access_token) {
            accessToken = o.access_token
            break
          }
        }
        const data = await fundaApi.signinAdmin({
          scope: 'default',
          grant_type: 'token',
          access_token: accessToken,
        })
        await signinSuccess(data)
        resolve(data)
      } catch (e) {
        reject(e)
      }
    })
  },
  async refresh() {
    return new Promise(async (resolve, reject) => {
      try {
        const data = await fundaApi.signinAdmin({
          scope: 'default',
          grant_type: 'refresh_token',
          refresh_token: getToken().refresh_token,
        })
        await signinSuccess(data)
        resolve(data)
      } catch (e) {
        reject(e)
      }
    })
  },
  signout,
  sync,
  getRedirectUri,
  async init() {
    // 초기값 세팅
    if (getToken().refresh_token != null) {
      await store.dispatch('auth/signin')
      if (authService.isRefreshTokenExpire()) {
        signout()
        global.$vm._router.push({
          name: 'signin',
          query: { redirectUri: getRedirectUri() },
        })
      } else {
        expireTokenProcess()
        await sync()
      }
    }
  },
}

export default authService
