<template>
  <div>
    <v-card elevation="0" tile>
      <v-card-title>
        <span class="headline"> 휴대폰 번호 변경 </span>
      </v-card-title>
      <v-card-text>
        <p class="mb-0 f-dark-grey--text">
          <v-icon small>mdi-chevron-right</v-icon>고객 휴대폰 번호가 변경되어
          펀다 홈페이지에서 휴대폰 인증 링크를 받을 수 없는 경우에만 사용합니다.
        </p>
        <p class="mb-0 f-dark-grey--text">
          <v-icon small>mdi-chevron-right</v-icon>이미 휴대폰을 인증한 유저인
          경우, 휴대폰 번호를 변경하면
          <span class="color--pink">미인증 상태가 되며</span> 고객에게 휴대폰
          재인증을 요청하여야 합니다.
        </p>
      </v-card-text>
      <v-card-text>
        <v-row no-gutters>
          <v-col cols="12">
            <p class="mb-1 font-size--20 black--text">변경</p>

            <div class="d-flex align-center px-5">
              <p class="mb-0 black--text font-size--15">
                현재 휴대폰 번호:
                <span class="font-size--18"> {{ fromPhone }} </span>
              </p>

              <p class="mb-0 mx-3">
                <v-icon> mdi-arrow-right-circle </v-icon>
              </p>

              <v-text-field
                v-model.trim="toPhone"
                outlined
                hide-details
              ></v-text-field>

              <v-btn
                class="white--text"
                depressed
                height="56px"
                :color="$colors['f-dark-grey']"
                :disabled="isLoading || !!!toPhone || fromPhone === toPhone"
                :loading="isLoading"
                @click="modifyPhone()"
              >
                변경하기
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    userIdx: {
      required: true,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      userInfo: null,
      toPhone: null,
    }
  },
  computed: {
    fromPhone() {
      return this.userInfo?.userPhone
    },
  },
  created() {
    this.getUser()
  },
  methods: {
    async getUser() {
      this.isLoading = true

      const q = `
        data: getUserInfo(userIdx: ${this.userIdx}) {
          idx
          userPhone
          
          userValidation {
            isValidPhone
            phoneValidTime
          }
        }
      `

      try {
        const data = await this.$fundaApi.query(gql`{${q}}`)
        this.userInfo = data
      } finally {
        this.isLoading = false
      }
    },
    async modifyPhone() {
      if (!!!this.toPhone) {
        return
      }

      const confirm = await this.$swal.basic.confirm(
        '휴대폰 번호를 변경하시겠습니까?'
      )

      if (confirm.isConfirmed !== true) {
        return
      }

      this.isLoading = true

      const q = `
        data: modifyUserPhone(userIdx: ${this.userIdx}, userPhone: "${this.toPhone}") {
          idx
          userPhone
          
          userValidation {
            isValidPhone
            phoneValidTime
          }
        }
      `

      try {
        const data = await this.$fundaApi.mutation(gql`{${q}}`)
        this.userInfo = data
        if (this.userInfo?.userPhone === this.toPhone) {
          this.$swal.basic.success('휴대폰 번호가 변경되었습니다.')
        }
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .v-text-field {
  max-width: 200px;
}
</style>
