<template>
  <div>
    <v-row dense>
      <!-- 기표 전 플랜에는 회차만 있고 날짜 개념이 없어서 기표 후에만 상환 예정일 지정 가능 -->
      <template v-if="preview">
        <v-col lg="6" md="6" sm="12" cols="12">
          <p class="error--text">
            ※ 아직 기표가 되지 않았기 때문에 연이자의 한 달 치 금액으로 가계산한
            상환 계획표가 표시됩니다.
          </p>
        </v-col>
      </template>
      <template v-else>
        <v-col lg="2" md="3" sm="12" cols="12">
          <CommonDatePicker
            v-model="repaymentDate"
            label="상환 예정일 선택"
            @change="setRepaymentDate"
          />
        </v-col>
      </template>
      <v-spacer />
      <v-col v-if="dataTable.items.length > 0" lg="2" md="3" sm="12" cols="12">
        <span class="d-flex justify-end">
          <v-checkbox
            v-model="showRate"
            label="기준 금리 표기"
            hide-details
            class="mt-0 mr-4"
            dense
            @click="toggleShowRate"
          />
          <ExportExcelButton2
            :headers="preview ? dataTable.previewHeaders : dataTable.headers"
            :data="dataTable.items"
            :fileName="`fundanow_loan_${loanId}_repayment_plan`"
          >
            <template v-slot:button="{ download }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="mr-3"
                    v-bind="attrs"
                    v-on="on"
                    @click="download"
                  >
                    <v-icon>mdi-microsoft-excel</v-icon>
                  </v-btn>
                </template>
                <span>엑셀 다운로드</span>
              </v-tooltip>
            </template>
          </ExportExcelButton2>
        </span>
      </v-col>
    </v-row>

    <v-data-table
      :headers="preview ? dataTable.previewHeaders : dataTable.headers"
      :items="dataTable.items"
      :options.sync="dataTable.options"
      :loading="dataTable.isLoading"
      :footer-props="dataTable.footer"
      class="elevation-2 my-3"
      hide-default-footer
      :items-per-page="-1"
      :item-class="getRowClasses"
    >
      <template v-slot:[`item.turnNo`]="{ item }">
        <span :class="{ 'primary--text font-weight-black': item.isCurrent }">{{
          item.turnNo
        }}</span>
      </template>

      <template v-slot:[`item.interest`]="{ item: { interest } }">
        {{ interest | commaFormat }} 원
      </template>
      <template v-slot:[`item.usedDays`]="{ item: { usedDays } }">
        {{ usedDays | commaFormat }} 일
      </template>
      <template v-slot:[`item.remainInterest`]="{ item }">
        {{ item.remainInterest | commaFormat }} 원
      </template>

      <template v-slot:[`item.overdueInterest`]="{ item: { overdueInterest } }">
        {{ overdueInterest | commaFormat }} 원
      </template>
      <template v-slot:[`item.overdueDays`]="{ item: { overdueDays } }">
        {{ overdueDays | commaFormat }} 일
      </template>
      <template v-slot:[`item.remainOverdueInterest`]="{ item }">
        {{ item.remainOverdueInterest | commaFormat }} 원
      </template>

      <template v-slot:[`item.principal`]="{ item: { principal } }">
        {{ principal | commaFormat }} 원
      </template>
      <template v-slot:[`item.remainPrincipal`]="{ item: { remainPrincipal } }">
        {{ remainPrincipal | commaFormat }} 원
      </template>
      <template v-slot:[`item.repaymentDate`]="{ item: { repaymentDate } }">
        {{ repaymentDate | moment('yyyy-MM-DD') }}
      </template>

      <template v-slot:[`item.totalAmount`]="{ item: { totalAmount } }">
        {{ totalAmount | commaFormat }} 원
      </template>

      <template v-slot:[`item.isRepaid`]="{ item }">
        <span :class="{ 'primary--text': item.isRepaid }">
          {{ item.isRepaid ? '상환' : '미상환' }}
        </span>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import CommonDatePicker from '@/components/common/form/CommonDatePicker.vue'
import ExportExcelButton2 from '@/components/common/datatable/ExportExcelButton2.vue'

export default {
  components: { CommonDatePicker, ExportExcelButton2 },
  props: {
    loanId: {
      type: Number,
      required: true,
    },
    preview: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    async loanId(v) {
      if (v) {
        await this.initRepaymentDate()
        await this.getData()
      }
    },
  },
  data() {
    return {
      showRate: false,
      repaymentDate: null,
      dataTable: {
        isLoading: false,
        options: null,
        previewHeaders: [
          { text: '회차', value: 'turnNo', sortable: false },
          { text: '이자', value: 'interest', sortable: false },
          {
            text: '이자율',
            value: 'interestRate',
            sortable: false,
            align: ' d-none',
          },
          { text: '원금', value: 'principal', sortable: false },
          { text: '잔여 원금', value: 'remainPrincipal', sortable: false },
          { text: '상환 합계', value: 'totalAmount', sortable: false },
        ],
        headers: [
          { text: '회차', value: 'turnNo', sortable: false },
          { text: '상환일', value: 'repaymentDate', sortable: false },

          { text: '이자 사용일수', value: 'usedDays', sortable: false },
          {
            text: '이자율',
            value: 'interestRate',
            sortable: false,
            align: ' d-none',
          },
          { text: '이자', value: 'interest', sortable: false },
          { text: '미수 이자', value: 'remainInterest', sortable: false },

          { text: '연체 사용일수', value: 'overdueDays', sortable: false },
          {
            text: '연체이자율',
            value: 'overdueInterestRate',
            sortable: false,
            align: ' d-none',
          },
          { text: '연체이자', value: 'overdueInterest', sortable: false },
          {
            text: '미수 연체이자',
            value: 'remainOverdueInterest',
            sortable: false,
          },

          { text: '원금', value: 'principal', sortable: false },
          { text: '잔여 원금', value: 'remainPrincipal', sortable: false },

          { text: '상환 합계', value: 'totalAmount', sortable: false },
          { text: '상환여부', value: 'isRepaid', sortable: false },
        ],
        items: [],
        footer: {
          'items-per-page-options': [1, 5, 10, 20, 50, 100, -1],
        },
      },
    }
  },
  async created() {
    await this.initRepaymentDate()
    await this.getData()
  },
  methods: {
    async getData() {
      try {
        // 테이블 비움
        this.dataTable.items.splice(0, this.dataTable.items.length)

        // 플랜 조회
        const { data } = await this.$fundaNowApi.getLoansRepaymentsPlan(
          this.loanId,
          { repaymentDate: this.repaymentDate }
        )

        // 현재 회차 표시
        const currentPlan = data.find((plan) => !plan.isRepaid)
        if (currentPlan) {
          currentPlan.isCurrent = true
        }

        this.dataTable.items = data
      } catch (e) {
        try {
          // 에러 메세지 파싱 테스트..
          const reg = new RegExp(/\[.*\]/, 'gm')
          this.$swal.basic.error({
            title: '플랜 조회 실패',
            text: JSON.parse(e.data.message.match(reg)[0])[0].message,
          })
        } catch (e1) {
          throw e
        }
      }
    },
    async setRepaymentDate(date) {
      // 상환 예정일을 선택할 때 마다 플랜 새로 조회
      this.repaymentDate = date
      await this.getData()
    },
    async initRepaymentDate() {
      // 다음 상환일을 조회해와서 상환 예정일을 초기화해준다
      let { data } = await this.$fundaNowApi.getNextRepaymentDate(this.loanId)
      if (!data) {
        data = this.$moment().format()
      }
      this.repaymentDate = data
    },
    getRowClasses(item) {
      if (this.preview) {
        return ''
      }
      return item.isCurrent ? 'current-row' : ''
    },
    toggleShowRate() {
      // 기준 금리 표기 여부
      let headers = this.preview
        ? this.dataTable.previewHeaders
        : this.dataTable.headers

      headers.forEach((header) => {
        if (header.value.indexOf('Rate') > -1) {
          header.align = this.showRate ? '' : ' d-none'
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .current-row {
  background-color: #f5faff;
}
</style>
