<template>
  <v-container fluid class="pt-0">
    <PageHeader
      title="알림톡 템플릿 등록"
      subtitle="템플릿은 복사 붙여넣기(Ctrl + v)로만 입력이 가능합니다."
      color="error"
    />
    <!-- <v-row class="pt-3">
      {{ template }}
      {{valid}}isAllValid:{{isAllValid}}
    </v-row> -->
    <v-row class="pb-16">
      <v-col lg="4" md="6" sm="6" cols="12">
        <TemplateContentForm
          @change="changeTemplate"
          @valid="valid.isContentValid = $event"
        />
      </v-col>
      <v-col lg="4" md="6" sm="6" cols="12">
        <TemplateButtonForm
          @change="changeTemplate"
          @valid="valid.isButtonsValid = $event"
        />
      </v-col>
      <v-fade-transition>
        <v-col v-if="template.content != null" lg="4" md="6" sm="6" cols="12">
          <TemplatePreview
            class="mb-3"
            :template="template"
            :variables="variables"
          />
          <TemplateOption :template="template" :variables="variables" />
        </v-col>
      </v-fade-transition>
    </v-row>
    <v-row
      class="mt-10"
      justify="center"
      style="position: absolute; width: 100%; bottom: 0"
    >
      <v-col cols="12" lg="6" md="6" sm="6">
        <v-btn
          color="primary"
          type="button"
          @click="submitTemplate(false)"
          :disabled="isLoading || !isAllValid"
          block
          x-large
          class="title"
        >
          템플릿 저장
        </v-btn>
      </v-col>
      <v-col cols="12" lg="6" md="6" sm="6">
        <v-btn
          color="primary"
          type="button"
          @click="submitTemplate(true)"
          :disabled="isLoading || !isAllValid"
          block
          x-large
          class="title"
        >
          템플릿 저장 후 발송
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import TemplateContentForm from '@/components/alimtalk/template/form/TemplateContentForm.vue'
import TemplateButtonForm from '@/components/alimtalk/template/form/TemplateButtonForm.vue'
import TemplatePreview from '@/components/alimtalk/template/TemplatePreview.vue'
import TemplateOption from '@/components/alimtalk/template/TemplateOption'
export default {
  components: {
    TemplateContentForm,
    TemplateButtonForm,
    TemplatePreview,
    TemplateOption,
  },
  data() {
    return {
      bizmTemplatesLink:
        'https://www.bizmsg.kr/template/list?select_pf=ALL&inspect_status=APR&tmpl_status=ALL&comment_status=ALL&tmpl_msg_type=ALL&search_type=ALL&page=1',
      template: {
        id: null,
        name: null,
        profileIdx: null,
        content: null,
        buttons: null,
      },
      variables: [],
      isLoading: false,
      valid: {
        isButtonsValid: false,
        isContentValid: false,
      },
    }
  },
  computed: {
    isAllValid() {
      return (
        this.valid.isButtonsValid === true && this.valid.isContentValid === true
      )
    },
  },
  methods: {
    changeTemplate(e) {
      this.template = Object.assign(this.template, e)
      const templateStr = JSON.stringify(this.template)
      let variables = templateStr.match(/#{([^{}]+)}/g)

      if (variables == null) {
        return []
      }

      variables = variables.map((variable) => variable.replace(/[#{}]/g, ''))
      this.variables = Array.from(new Set(variables))
    },
    async submitTemplate(isAfterSend) {
      if (this.isLoading === true) {
        return
      }
      try {
        let btnResult = await this.$swal.basic.confirm({
          title: '템플릿 등록',
          text: '템플릿을 등록하시겠습니까?',
          cancelButtonColor: 'red',
        })

        if (btnResult.isConfirmed !== true) {
          return
        }
        this.isLoading = true

        let form = {
          id: this.template.id,
          name: this.template.name,
          content: this.template.content,
          buttons: this.template.buttons,
        }

        const { data: template } =
          await this.$alimtalkApi.postProfilesTemplates(
            this.template.profileIdx,
            form
          )
        await this.$swal.basic.alert({
          title: '템플릿 등록 완료',
          text: '템플릿을 등록이 완료되었습니다.',
        })

        if (isAfterSend === true) {
          this.$router.push({
            name: 'alimtalk/templates/detail/send/form',
            params: { idx: template.idx },
          })
        } else {
          this.$router.push({
            name: 'alimtalk/templates/detail',
            params: { templateIdx: template.idx },
          })
        }
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
