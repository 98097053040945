<template>
  <div>
    <v-card elevation="0" tile>
      <v-card-title>
        <span class="headline"> 이름 변경 </span>
      </v-card-title>
      <v-card-text>
        <p class="mb-0 f-dark-grey--text">
          <v-icon small>mdi-chevron-right</v-icon>웰컴어드민 주소:
          <a
            href="https://wbiz.welcomefin.com/#/merchantfds/DepositError"
            target="_blank"
          >
            https://wbiz.welcomefin.com/#/merchantfds/DepositError
          </a>
        </p>
        <p class="mb-0 f-dark-grey--text">
          <v-icon small>mdi-chevron-right</v-icon>
          <span class="color--pink">출금계좌 미인증</span>
          유저는 웰컴페이먼츠에 변경 요청을 할 필요가
          <span class="color--pink">없습니다.</span>
        </p>
        <p class="mb-0 f-dark-grey--text">
          <v-icon small>mdi-chevron-right</v-icon>
          <span class="blue--text">출금계좌 인증</span> 유저는
          <span class="blue--text">먼저 웰컴페이먼츠에 변경 요청</span>을 하여야
          합니다.
        </p>
        <p class="mb-0 f-dark-grey--text">
          <v-icon small>mdi-chevron-right</v-icon>휴대폰 인증 여부는 상관 없을
          무
        </p>
        <p class="mt-2 mb-0 f-dark-grey--text font-size--16">
          <v-icon small>mdi-chevron-right</v-icon>현재 고객의 출금계좌는 [
          <span :class="isValidAccount ? 'blue--text' : 'color--pink'">
            {{ isValidAccount ? '인증' : '미인증' }}
          </span>
          ] 상태입니다.
        </p>
      </v-card-text>
      <v-card-text>
        <v-row no-gutters>
          <v-col cols="12">
            <v-checkbox
              v-model="skipPayment"
              class="color--black"
              label="출금계좌 인증 유저에 대해 웰컴에서 먼저 처리가 완료되었습니다."
              :disabled="isValidAccount === false"
            ></v-checkbox>
          </v-col>
          <v-col cols="12" class="mb-8">
            <p class="mb-1 font-size--20 black--text">개명</p>

            <div class="d-flex align-center px-5">
              <p class="mb-0 black--text font-size--15">
                현재이름:
                <span class="font-size--18"> {{ fromName }} </span>
              </p>

              <p class="mb-0 mx-3">
                <v-icon> mdi-arrow-right-circle </v-icon>
              </p>

              <v-text-field
                v-model.trim="toName"
                outlined
                hide-details
              ></v-text-field>

              <v-btn
                class="white--text"
                depressed
                height="56px"
                :color="$colors['f-dark-grey']"
                :disabled="isDisabled || !!!toName || fromName === toName"
                :loading="isLoading"
                @click="modifyName(false)"
              >
                변경하기
              </v-btn>
            </div>
          </v-col>

          <v-col cols="12" class="mt-8">
            <p class="mb-2 font-size--20 black--text">계좌 이름과 일치시키기</p>

            <div class="d-flex align-center px-5">
              <p class="mb-0 black--text font-size--15">
                현재이름:
                <span class="font-size--18"> {{ fromName }} </span>
              </p>

              <p class="mb-0 mx-3">
                <v-icon> mdi-arrow-right-circle </v-icon>
              </p>

              <p class="mb-0 black--text font-size--18">
                {{ accountName }}
              </p>

              <v-btn
                class="ml-3 white--text"
                depressed
                :color="$colors['f-dark-grey']"
                :disabled="isDisabled || fromName === accountName"
                :loading="isLoading"
                @click="modifyName(true)"
              >
                변경하기
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    userIdx: {
      required: true,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      userInfo: null,
      skipPayment: false,
      toName: null,
    }
  },
  computed: {
    fromName() {
      return this.userInfo?.name
    },
    accountName() {
      return this.userInfo?.bankAccount?.accountHolder
    },
    isValidAccount() {
      let result = this.userInfo?.userValidation?.isValidAccount === true
      return result
    },
    isDisabled() {
      if (this.isValidAccount) {
        return this.skipPayment !== true
      }

      return this.isLoading
    },
  },
  created() {
    this.getUser()
  },
  methods: {
    async getUser() {
      this.isLoading = true

      const q = `
        data: getUserInfo(userIdx: ${this.userIdx}) {
          idx
          name
          
          bankAccount {
            accountHolder
            bank
            accountWoHyphen
          }
          
          userValidation {
            isValidAccount
            accountValidTime
          }
        }
      `

      try {
        const data = await this.$fundaApi.query(gql`{${q}}`)
        this.userInfo = data
      } finally {
        this.isLoading = false
      }
    },
    async modifyName(isAccount) {
      const toName = isAccount ? this.accountName : this.toName

      if (!!!toName) {
        return
      }

      const confirm = await this.$swal.basic.confirm(
        `유저의 이름을 '${this.fromName}' 에서 '${toName}' 으로 변경하시겠습니까?`
      )

      if (confirm.isConfirmed !== true) {
        return
      }

      this.isLoading = true

      const q = `
        data:modifyUserName(userIdx: ${this.userIdx}, rename: "${toName}", skipPayment: ${this.skipPayment}) {
          idx
          name

          bankAccount {
            accountHolder
            bank
            accountWoHyphen
          }
          
          userValidation {
            isValidAccount
            accountValidTime
          }
        }
      `

      try {
        const data = await this.$fundaApi.mutation(gql`{${q}}`)
        this.userInfo = data
        if (this.userInfo?.name === toName) {
          this.$swal.basic.success('이름이 변경되었습니다.')
        }
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .v-text-field {
  max-width: 200px;
}

::v-deep .color--black label {
  color: black !important;
}
</style>
