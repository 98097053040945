<template>
  <v-container fluid class="pt-0">
    <PageHeader title="메일 발송 내역 조회" subtitle="발송 내역 조회">
    </PageHeader>
    <MailsTable :query="query" :isQueryPaging="true" class="pt-3" />
  </v-container>
</template>
<script>
import MailsTable from '../../../components/fundanow/mail/MailsTable.vue'
export default {
  components: { MailsTable },
  props: {
    query: {
      type: Object,
      default: (_) => {
        return null
      },
    },
  },
}
</script>
