export default [
  {
    name: 'design/list',
    path: '/design/list',
    component: require('@/pages/design/List.vue').default,
  },
  {
    name: 'design/detail',
    path: '/design/detail',
    component: require('@/pages/design/Detail.vue').default,
  },
]
