export default [
  {
    path: '/ai/smart-store',
    component: () => import('@/pages/ai/smart-store/Layout.vue'),
    children: [
      {
        path: 'list',
        name: 'ai/smart-store/list',
        component: () => import('@/pages/ai/smart-store/List.vue'),
        meta: {
          title: '스마트스토어 목록',
        },
      },
      {
        path: 'reviews/:id',
        name: 'ai/smart-store/reviews',
        component: () => import('@/pages/ai/smart-store/Reviews.vue'),
        meta: {
          title: '스마트스토어 리뷰',
        },
      },
    ],
  },
  {
    path: '/ai/smart-store/search',
    name: 'ai/smart-store/search',
    component: () => import('@/pages/ai/smart-store/Search.vue'),
    meta: {
      title: '스토어 검색'
    }
  },
]
