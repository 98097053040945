import client from '../client'

export default {
  getFundingInvestors(fundingIdx) {
    return client.call({
      uri: '/graphql',
      method: 'POST',
      data: {
        query: `query {
          fundaCreditorsByFunding(fundingIdx: ${fundingIdx}) {
            idx
            investmentAmount
            investmentState
            user {
              idx
              userId
              userName
            }
          }
        }`,
      },
    })
  },
}
