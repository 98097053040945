<template>
  <div>
    <v-row no-gutters>
      <v-dialog v-model="dialog" max-width="900px" width="80%">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :color="$colors['f-dark-grey']"
            dark
            depressed
            block
            v-bind="attrs"
            v-on="on"
          >
            휴대폰 번호 변경
          </v-btn>
        </template>

        <div>
          <ModifyUserPhone :userIdx="userIdx" />
          <v-row no-gutters style="background-color: white">
            <v-col cols="12" class="text-right">
              <v-btn color="green darken-1" text @click="dialog = false">
                닫기
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import ModifyUserPhone from './ModifyUserPhone.vue'

export default {
  props: {
    userIdx: {
      required: true,
      default: null,
    },
  },
  components: {
    ModifyUserPhone,
  },
  data() {
    return {
      dialog: false,
    }
  },
}
</script>
