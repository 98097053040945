<template>
  <div>
    <v-card class="my-3" width="100%">
      <v-card-text class="py-1">
        <v-form @submit.prevent="searchParam()">
          <v-row>
            <v-col cols="12" lg="3" md="4" sm="6">
              <VirtualAccountAutocomplete
                dense
                label="보내는 계좌"
                :userVirtualAccountIdx.sync="param.userVirtualAccountIdx"
              />
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6">
              <VirtualAccountAutocomplete
                dense
                label="받는 계좌"
                :userVirtualAccountIdx.sync="param.toUserVirtualAccountIdx"
              />
            </v-col>
            <v-col cols="12" lg="2" md="2" sm="6" align-self="center">
              <v-btn color="primary" type="submit"> 검색 </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <v-data-table
      :headers="dataTable.headers"
      :items="dataTable.items"
      :options.sync="dataTable.options"
      :loading="dataTable.isLoading"
      :server-items-length="dataTable.totalCount"
      :footer-props="dataTable.footer"
      class="elevation-2"
    >
      <template v-slot:[`item.userVirtualAccountIdx`]="{ item }">
        {{ item.userVirtualAccountIdx }}
      </template>
      <template v-slot:[`item.toUserVirtualAccountIdx`]="{ item }">
        {{ item.toUserVirtualAccountIdx }}
      </template>
      <template v-slot:[`item.status`]="{ item }">
        {{
          item.status | payment.code('UserVirtualAccountTransferReserve.Status')
        }}
      </template>
      <template v-slot:[`item.refBody`]="{ item }">
        <div style="word-break: break-all">
          {{ JSON.stringify(item.refBody) }}
        </div>
      </template>
      <template v-slot:[`item.createTime`]="{ item }">
        {{ item.createTime | moment('yyyy-MM-DD HH:mm:ss') }}
      </template>
      <template v-slot:[`item.reserveTime`]="{ item }">
        {{ item.reserveTime | moment('yyyy-MM-DD HH:mm:ss') }}
      </template>
      <template v-slot:[`item.startTime`]="{ item }">
        {{ item.startTime | moment('yyyy-MM-DD HH:mm:ss') }}
      </template>
      <template v-slot:[`item.successTime`]="{ item }">
        {{ item.successTime | moment('yyyy-MM-DD HH:mm:ss') }}
      </template>
      <template v-slot:[`item.failTime`]="{ item }">
        {{ item.failTime | moment('yyyy-MM-DD HH:mm:ss') }}
      </template>
    </v-data-table>
  </div>
</template>
<script>
import VirtualAccountAutocomplete from '../../VirtualAccountAutocomplete.vue'
export default {
  components: { VirtualAccountAutocomplete },
  props: {
    isQueryPaging: {
      type: Boolean,
      default: false,
    },
    query: {
      type: Object,
      default: (_) => {
        return null
      },
    },
  },
  data() {
    return {
      param: {
        page:
          this.isQueryPaging && this.query.page ? Number(this.query.page) : 1,
        size: this.query.size ? Number(this.query.size) : 20,
        userVirtualAccountIdx: this.query.userVirtualAccountIdx
          ? Number(this.query.userVirtualAccountIdx)
          : null,
        toUserVirtualAccountIdx: this.query.toUserVirtualAccountIdx
          ? Number(this.query.toUserVirtualAccountIdx)
          : null,
      },
      dataTable: {
        isLoading: false,
        options: null,
        headers: [
          { text: 'idx', value: 'idx', sortable: false },
          {
            text: '보내는 계좌 idx',
            value: 'userVirtualAccountIdx',
            sortable: false,
          },
          {
            text: '받는 계좌 idx',
            value: 'toUserVirtualAccountIdx',
            sortable: false,
          },
          {
            text: '플랫폼 이체 idx',
            value: 'transferTargetIdx',
            sortable: false,
          },
          { text: '금액', value: 'amount', sortable: false, width: 100 },
          { text: '상태', value: 'status', sortable: false },
          { text: 'remark', value: 'remark', sortable: false },
          { text: '참조', value: 'refBody', sortable: false },
          { text: '생성 시간', value: 'createTime', sortable: false },
          { text: '예약 시간', value: 'reserveTime', sortable: false },
          { text: '이체 시작 시간', value: 'startTime', sortable: false },
          { text: '이체 성공 시간', value: 'successTime', sortable: false },
          { text: '이체 실패 시간', value: 'failTime', sortable: false },
        ],
        items: [],
        totalCount: 0,
        footer: {
          'items-per-page-options': [1, 5, 10, 20, 50, 100],
        },
      },
    }
  },
  watch: {
    'dataTable.options': {
      handler: function (options, oldValue) {
        if (oldValue === null) {
          return
        }
        const optionObj = {
          page: options.page,
          size: options.itemsPerPage,
          userVirtualAccountIdx: options.userVirtualAccountIdx,
          toUserVirtualAccountIdx: options.toUserVirtualAccountIdx,
        }
        this.updateOption(optionObj)
      },
      deep: true,
    },
  },
  created() {
    this.initTableOption()
    this.getPageData(this.param)
  },
  methods: {
    initTableOption() {
      this.dataTable.options = {
        page: this.param.page,
        itemsPerPage: this.param.size,
        userVirtualAccountIdx: this.param.userVirtualAccountIdx,
        toUserVirtualAccountIdx: this.param.toUserVirtualAccountIdx,
      }
    },
    updateOption(option) {
      if (this.isQueryPaging === true) {
        return this.$router.push({
          query: option,
        })
      } else {
        this.getPageData(option)
      }
    },
    async getPageData(option) {
      if (this.dataTable.isLoading === true) {
        return
      }
      try {
        this.dataTable.isLoading = true
        const {
          data: {
            paging: { totalCount },
            data,
          },
        } = await this.$paymentApi.getUserVirtualAccountTransferReservations(
          option
        )
        this.dataTable.items = data
        this.dataTable.totalCount = totalCount
      } finally {
        this.dataTable.isLoading = false
      }
    },
    searchParam() {
      this.dataTable.options = Object.assign(
        this.dataTable.options,
        this.param,
        {
          page: 1,
        }
      )
    },
    async getUserVirtualAccount(userVirtualAccountIdx) {
      // UserVirtualAccountLink
      this.$paymentApi.getUserVirtualAccountByIdx(userVirtualAccountIdx)
    },
  },
}
</script>
