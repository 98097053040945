export default {
  namespaced: true,
  state: {
    show: false,
    text: '',
    color: 'success',
    timeout: 3000,
  },
  mutations: {
    SET_SHOW(state, show) {
      state.show = show
    },
    SET_TEXT(state, text) {
      state.text = text
    },
    SET_COLOR(state, color) {
      state.color = color
    },
  },
  actions: {
    showSnackbar({ commit }, { text, color = 'success' }) {
      commit('SET_TEXT', text)
      commit('SET_COLOR', color)
      commit('SET_SHOW', true)
    },
    hideSnackbar({ commit }) {
      commit('SET_SHOW', false)
    },
  },
  getters: {
    show: (state) => state.show,
    text: (state) => state.text,
    color: (state) => state.color,
    timeout: (state) => state.timeout,
  },
}
