<template>
  <div
    v-if="isLoading"
    class="d-flex justify-center align-center"
    style="height: 100px"
  >
    <MyLoader />
  </div>
  <div
    v-else
    style="min-height: 100px"
    :class="{ 'd-flex justify-center align-center': doesNotExistInsolvent }"
  >
    <template v-if="doesNotExistInsolvent === false">
      <v-row class="margin-b-5" no-gutters>
        <v-col cols="4" sm="5" md="4" lg="5">
          <p class="invest-details__key ma-0 color--gray-2">
            {{ title.principal }}
          </p>
        </v-col>
        <v-col cols="8" sm="7" md="8" lg="7" class="text-right">
          <p class="invest-details__val ma-0 color--black">
            <span class="font-weight-bold color--black">
              {{ insolventAmount | commaFormat }}
            </span>
            원
          </p>
        </v-col>
      </v-row>

      <v-row class="margin-b-5" no-gutters>
        <v-col cols="4" sm="4" md="4" lg="4">
          <p class="invest-details__key ma-0 color--gray-2">
            {{ title.rate }}
          </p>
        </v-col>
        <v-col cols="8" sm="8" md="8" class="text-right">
          <p class="invest-details__val ma-0 color--black">
            <span class="font-weight-bold color--black">
              {{ insolventRate | rateFormat }}
            </span>
            %
          </p>
        </v-col>
      </v-row>

      <v-row no-gutters style="margin-bottom: 2px">
        <v-col cols="4" sm="4" md="4" lg="4">
          <p class="invest-details__key ma-0 color--gray-2">
            {{ title.countFunding }}
          </p>
        </v-col>
        <v-col cols="8" sm="8" md="8" class="text-right">
          <p class="invest-details__val ma-0 color--black">
            <span class="font-weight-bold color--black">
              {{ userCnt.totalInsolvent | commaFormat }}
            </span>
            건
          </p>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="4" sm="4" md="4" lg="4">
          <p class="invest-details__key color--gray-2 ma-0 pl-1">
            ㄴ{{ title.countWriteOff }}<a target="_blank">(상세)</a>
          </p>
        </v-col>
        <v-col cols="8" sm="8" md="8" class="text-right">
          <p class="invest-details__val ma-0 color--black">
            <span class="font-weight-bold color--black">
              {{ userCnt.cntWriteOff | commaFormat }}
            </span>
            건
          </p>
        </v-col>
      </v-row>
    </template>
    <p v-else class="color--gray-1 ma-0">
      투자내역 중 부실채권이 존재하지 않습니다.
    </p>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { InvestmentTypes } from '@/store/www/types/investment'
import MyLoader from '@/components/funda/MyLoader.vue'

export default {
  props: {
    userIdx: {
      default: null,
    },
  },
  data() {
    return {
      title: {
        principal: '부실원금',
        rate: '부실채권비율',
        countFunding: '부실 채권수',
        countWriteOff: '매각 채권',
      },
    }
  },
  components: {
    MyLoader,
  },
  computed: {
    ...mapGetters('www/investment', {
      userCnt: InvestmentTypes.getters.GET_USER_CNT,
      isLoading: InvestmentTypes.getters.GET_INSOLVENT_AMOUNT_LOADING,
      insolventAmount: InvestmentTypes.getters.GET_INSOLVENT_AMOUNT,
    }),
    insolventRate() {
      return this.userCnt?.totalInsolvent / this.userCnt?.cntTotal
    },
    doesNotExistInsolvent() {
      return this.insolventAmount < 1 && this.userCnt?.totalInsolvent < 1
    },
  },
  watch: {
    userIdx: {
      immediate: true,
      handler(v) {
        if (!!v) {
          this.getInsolventAmount(this.userIdx)
        }
      },
    },
  },
  methods: {
    ...mapActions('www/investment', {
      getInsolventAmount: InvestmentTypes.actions.GET_INSOLVENT_AMOUNT,
    }),
  },
}
</script>

<style lang="scss" scoped></style>
