<template>
  <PageSection title="사용자 정보" :isLoading="isLoading">
    <template slot="content" v-if="user != null">
      <v-simple-table>
        <template v-slot:default>
          <tbody>
            <tr>
              <td>index</td>
              <td v-if="$route.name === 'fundanow/users/detail'">
                {{ user.id }}
              </td>
              <td v-else>
                <router-link
                  :to="{
                    name: 'fundanow/users/detail',
                    params: { userId: user.id },
                  }"
                  target="_blank"
                >
                  {{ user.id }}
                </router-link>
              </td>
            </tr>
            <tr>
              <td>이름(닉네임)</td>
              <td>{{ user.name }}({{ user.nickname }})</td>
            </tr>
            <tr>
              <td>이메일</td>
              <td>{{ user.email }}</td>
            </tr>
            <tr>
              <td>휴대폰번호</td>
              <td>{{ user.phone | fundanow.phone }}</td>
            </tr>
             <tr>
              <td>생년월일(성별)</td>
              <td>{{ user.birthdate }}({{ user.gender | gender }})</td>
            </tr>
            <tr>
              <td>가입일</td>
              <td>{{ user.dateJoined | moment('YYYY-MM-DD HH:mm:ss') }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </template>
  </PageSection>
</template>
<script>
export default {
  props: {
    userId: {
      type: Number,
      required: true
    },
  },
  data() {
    return {
      user: null,
      isLoading: false,
    }
  },
  async created() {
    try {
      this.isLoading = true

      const { data } = await this.$fundaNowApi.getUser(this.userId)
      this.user = data
      this.$emit('update:user', this.user)
    } finally {
      this.isLoading = false
    }
  },
}
</script>
<style lang="scss" scoped>
tr > td:last-child {
  word-break: break-all;
}
</style>
