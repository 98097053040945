import Vue from 'vue'
import './code'

Vue.filter('gender', (gender) => {
  if (!gender) {
    return ''
  }

  if (gender === 'male') {
    return '남'
  }

  if (gender === 'female') {
    return '여'
  }

  return '???'
})

Vue.filter('fundanow.phone', (phone) => {
  if (!phone) {
    return ''
  }

  const countryCode = '+82'
  if (phone.indexOf(countryCode) === 0) {
    let result = phone.replace(countryCode, '')
    result = '0' + result
    return result
  }

  return phone
})
