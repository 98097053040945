<template>
  <v-btn
    type="button"
    :color="stats.isVisible == true ? 'warning' : 'primary'"
    block
    @click="updateVisible"
  >
    {{ stats.isVisible == true ? '비공개' : '공개' }}
  </v-btn>
</template>
<script>
export default {
  props: {
    stats: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
    }
  },
  methods: {
    async updateVisible() {
      if (this.isLoading === true) {
        return
      }
      try {
        this.isLoading = true

        const updateStatus = this.stats.isVisible === true ? '비공개' : '공개'
        let btnResult = await this.$swal.basic.confirm({
          title: '연체율 공시 ' + updateStatus,
          text: updateStatus + '로 변경하시겠습니까?',
          cancelButtonColor: 'red',
        })

        if (btnResult.isConfirmed !== true) {
          return
        }

        const inputStats = JSON.parse(JSON.stringify(this.stats))
        delete inputStats.body.totalLoanBalance
        delete inputStats.body.totalOverdueAmount
        delete inputStats.body.totalOverdueRate
        delete inputStats.body.creditOverdueRate
        delete inputStats.body.movableOverdueRate

        inputStats.isVisible = !inputStats.isVisible
        const data = await this.$fundaApi.mutation(
          gql`
            {
              data: updateMainOfficialStats(inputMainOfficialStats: $value) {
                idx
                body {
                  totalLoanBalance
                  totalOverdueAmount
                  totalOverdueRate
                  creditLoanBalance
                  creditLoanOverdueBalance
                  creditOverdueRate
                  movableLoanBalance
                  movableLoanOverdueBalance
                  movableOverdueRate
                }
                isVisible
                baseTime
              }
            }
          `,
          'test($value: InputMainOfficialStats!)',
          {
            value: inputStats,
          }
        )
        await this.$swal.basic.alert({
          title: '변경 완료',
          text: '변경 취소 완료되었습니다.',
        })

        this.$emit('update:stats', data)
      } catch (e) {
        this.$swal.basic.alert({
          text: '변경에 실패했습니다.',
        })
        throw e
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
