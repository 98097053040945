<template>
  <v-card class="mt-3 px-2">
    <v-card-title>
      이체 목록
      <v-spacer />
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            color="error"
            class="mr-3"
            v-bind="attrs"
            v-on="on"
            :disabled="isTransferProgress"
            @click="deleteAllRow"
          >
            <v-icon>mdi-delete-sweep</v-icon>
          </v-btn>
        </template>
        <span>전체 삭제</span>
      </v-tooltip>
      <ExportExcelButton2
        :headers="dataTable.headers"
        :data="dataTable.items"
        fileName="payment_user_virtual_account_transfers"
      >
        <template v-slot:button="{ download }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                class="mr-3"
                v-bind="attrs"
                v-on="on"
                @click="download"
              >
                <v-icon>mdi-export-variant</v-icon>
              </v-btn>
            </template>
            <span>이체 목록 다운로드</span>
          </v-tooltip>
        </template>
      </ExportExcelButton2>
      <ExcelUploadDialogButton
        @success="addItems"
        :disabled="isTransferProgress"
      />
    </v-card-title>
    <v-card-text>
      <v-data-table
        hide-default-footer
        :items="dataTable.items"
        :headers="dataTable.headers"
        :items-per-page="-1"
      >
        <template
          v-slot:[`item.fromUserVirtualAccount`]="{
            item: { fromUserVirtualAccount },
          }"
        >
          <router-link
            :to="{
              name: 'payment/users/virtual/accounts/transactions',
              params: {
                userIdx: fromUserVirtualAccount.userIdx,
                userVirtualAccountIdx: fromUserVirtualAccount.idx,
              },
            }"
            target="_blank"
          >
            {{ fromUserVirtualAccount.accountName }}({{
              fromUserVirtualAccount.userIdx
            }}/{{ fromUserVirtualAccount.idx }})
          </router-link>
        </template>
        <template
          v-slot:[`item.toUserVirtualAccount`]="{
            item: { toUserVirtualAccount },
          }"
        >
          <router-link
            :to="{
              name: 'payment/users/virtual/accounts/transactions',
              params: {
                userIdx: toUserVirtualAccount.userIdx,
                userVirtualAccountIdx: toUserVirtualAccount.idx,
              },
            }"
            target="_blank"
          >
            {{ toUserVirtualAccount.accountName }}({{
              toUserVirtualAccount.userIdx
            }}/{{ toUserVirtualAccount.idx }})
          </router-link>
        </template>

        <template v-slot:[`item.amount`]="{ item: { amount } }">
          {{ amount | commaFormat }}
        </template>

        <template v-slot:[`item.remark`]="{ item: { remark } }">
          <span style="word-break: break-all">{{ remark }}</span>
        </template>

        <template v-slot:[`item.status`]="{ item: { status } }">
          <span
            :class="status == '요청 완료' ? 'primary--text' : 'error--text'"
            >{{ status }}</span
          >
        </template>

        <template v-slot:[`item.error`]="{ item: { error } }">
          <span style="word-break: break-all">{{ error }}</span>
        </template>

        <template v-slot:[`item._action`]="{ item }">
          <v-btn
            icon
            color="error"
            :disabled="isTransferProgress"
            @click="deleteRow(item)"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
        <template v-slot:footer>
          <v-divider class="mt-0" />
          <p class="px-4 title mb-0">
            총 {{ dataTable.items.length }}건, 합계 금액
            {{ amount | commaFormat }}원
          </p>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>
<script>
import ExportExcelButton2 from '../../../../../../components/common/datatable/ExportExcelButton2.vue'
import ExcelUploadDialogButton from '../datatable/ExcelUploadDialogButton.vue'

export default {
  components: { ExcelUploadDialogButton, ExportExcelButton2 },
  props: {
    amount: {
      type: Number,
      default: 0,
    },
    transfers: {
      type: Array,
      default: (_) => {
        return []
      },
    },
    isTransferProgress: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dataTable: {
        headers: [
          { text: '보내는 계좌', value: 'fromUserVirtualAccount' },
          { text: '받는 계좌', value: 'toUserVirtualAccount' },
          { text: '금액', value: 'amount' },
          { text: '메모', value: 'remark', width: 200 },
          { text: '상태', value: 'status', width: 100 },
          { text: '에러메세지', value: 'error', width: 200 },
          { text: '삭제', value: '_action', width: 80 },
        ],
        items: this.transfers,
      },
    }
  },
  watch: {
    transfers(v) {
      this.dataTable.items = v
      this.sumAmount()
    },
  },
  methods: {
    addItems(items) {
      this.dataTable.items = this.dataTable.items.concat(items)
      this.update()
    },
    deleteAllRow() {
      this.dataTable.items.splice(0, this.dataTable.items.length)
      this.update()
    },
    deleteRow(item) {
      const index = this.dataTable.items.indexOf(item)
      this.dataTable.items.splice(index, 1)
      this.update()
    },
    update() {
      this.$emit('update:transfers', this.dataTable.items)
    },
    sumAmount() {
      this.$emit(
        'update:amount',
        this.dataTable.items.reduce((sumAmount, item) => {
          return sumAmount + Number(item.amount)
        }, 0)
      )
    },
  },
}
</script>
