<template>
  <v-card>
    <v-card-title class="d-flex align-center pb-0">
      <span>템플릿 버튼 {{ disabled ? '조회' : '추가' }}</span>
      <v-spacer />
      <v-btn
        v-if="!disabled && buttons.length < 5"
        color="primary"
        icon
        small
        @click="addButton"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <ValidationObserver ref="validForm">
        <v-list v-if="buttons.length > 0" three-line class="py-0">
          <v-slide-y-transition group hide-on-leave>
            <v-list-item
              v-for="(button, index) in buttons"
              :key="index"
              class="px-0"
            >
              <v-list-item-content>
                <v-list-item-title class="d-flex align-center">
                  <span>버튼{{ index + 1 }}</span>
                  <v-spacer />
                  <v-btn
                    v-if="!disabled"
                    icon
                    small
                    color="error"
                    @click="deleteButton(index)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-list-item-title>
                <v-list-item-subtitle>
                  <ValidationProvider
                    v-slot="{ touched, errors }"
                    name="alimtalk_button_name"
                    rules="required"
                  >
                    <v-text-field
                      v-model="buttons[index].name"
                      name="name"
                      class="my-3"
                      type="text"
                      label="버튼명"
                      placeholder="버튼명을 붙여넣으세요.(ctrl + v)"
                      :hide-details="touched == false || errors.length == 0"
                      :error-messages="touched == false ? [] : errors"
                      outlined
                      readonly
                      :clearable="!disabled"
                      dense
                      @paste="paste(index, $event)"
                    />
                  </ValidationProvider>
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  <ValidationProvider
                    v-slot="{ touched, errors }"
                    name="alimtalk_button_link"
                    rules="required"
                  >
                    <v-textarea
                      v-model="buttons[index].link"
                      name="link"
                      class="my-3"
                      type="text"
                      label="버튼링크"
                      placeholder="버튼링크를 붙여넣으세요.(ctrl + v)"
                      :hide-details="touched == false || errors.length == 0"
                      :error-messages="touched == false ? [] : errors"
                      outlined
                      readonly
                      :clearable="!disabled"
                      dense
                      @paste="paste(index, $event)"
                      auto-grow
                      rows="1"
                    />
                  </ValidationProvider>
                </v-list-item-subtitle>
                <v-divider v-if="buttons.length !== index + 1" class="mt-3" />
              </v-list-item-content>
            </v-list-item>
          </v-slide-y-transition>
        </v-list>
      </ValidationObserver>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  props: {
    template: {
      type: Object,
      default: (_) => {
        return null
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      buttons: [],
    }
  },
  watch: {
    template() {
      this.setPropsTemplateButtons()
    },
    buttons: {
      handler(v) {
        this.$emit('change', { buttons: v })
        this.validate()
      },
      deep: true,
    },
  },
  created() {
    this.validate()
    this.setPropsTemplateButtons()
  },
  methods: {
    setPropsTemplateButtons() {
      if (this.template != null) {
        const templateCopy = JSON.parse(JSON.stringify(this.template))
        templateCopy.buttons.forEach((button) => {
          this.buttons.push({
            link: button.link,
            name: button.name,
            type: 'WL',
          })
        })
      }
    },
    validate() {
      if (this.buttons.length === 0) {
        this.$emit('valid', true)
      } else {
        this.$nextTick(async (_) => {
          const isValid = await this.$refs.validForm.validate()
          this.$emit('valid', isValid)
        })
      }
    },
    addButton() {
      this.buttons.push({
        name: null,
        link: null,
        type: 'WL',
      })
    },
    deleteButton(index) {
      this.buttons.splice(index, 1)
    },
    paste(index, e) {
      if (this.disabled === true) {
        return
      }
      this.buttons[index][e.target.name] = e.clipboardData
        .getData('text')
        .trim()
    },
  },
}
</script>
