<template>
  <div>
    <v-data-table
      :headers="dataTable.headers"
      :items="dataTable.items"
      :options.sync="dataTable.options"
      :loading="dataTable.isLoading"
      :footer-props="dataTable.footer"
      class="elevation-2 my-3"
      hide-default-footer
      :items-per-page="-1"
    >
      <template v-slot:[`item.id`]="{ item }">
        {{ item.id }}
      </template>
      <template v-slot:[`item.incomeAmount`]="{ item: { incomeAmount } }">
        {{ incomeAmount | commaFormat }} 원
      </template>
      <template
        v-slot:[`item.userVirtualAccount`]="{ item: { fundaPaygateRepaymentvirtualaccountId } }"
      >
       <TransactionsRouterLink
          :userVirtualAccountIdx="fundaPaygateRepaymentvirtualaccountId"
        />
      </template>
      <template v-slot:[`item.created`]="{ item: { created } }">
        {{ created | moment('yyyy-MM-DD HH:mm:ss') }}
      </template>
      <template v-slot:[`item.modified`]="{ item: { modified } }">
        {{ modified | moment('yyyy-MM-DD HH:mm:ss') }}
      </template>
      <template v-slot:[`item.repay`]="{ item }">
        <v-btn
          color="primary"
          block
          :to="{
            name: 'fundanow/repayment/manual/targets/form',
            params: {
              loansRepaymentPreDataId: item.id,
            },
          }"
        >
          상환하기
        </v-btn>
      </template>
      <template v-slot:[`item.refund`]="{ item }">
        <v-btn
          color="red"
          block
          @click="refund(item.id)"
        >
          반환하기
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import TransactionsRouterLink from '@/components/payment/user/virtualAccount/TransactionsRouterLink.vue'
export default {
  components: { TransactionsRouterLink },
  data() {
    return {
      dataTable: {
        isLoading: false,
        options: null,
        headers: [
          { text: 'index', value: 'id', sortable: false },
          { text: '입금액', value: 'incomeAmount', sortable: false },
          { text: '가상계좌', value: 'userVirtualAccount', sortable: false },
          { text: '등록 시간', value: 'created', sortable: false },
          { text: '수정 시간', value: 'modified', sortable: false },
          { text: '상환하기', value: 'repay', sortable: false },
          { text: '반환하기', value: 'refund', sortable: false },
        ],
        items: [],
        footer: {
          'items-per-page-options': [1, 5, 10, 20, 50, 100, -1],
        },
      },
    }
  },
  async created() {
    this.getData()
  },
  methods: {
    async refund(index) {
      let yes = await this.$swal.basic.confirm('전액 반환 확인 합니다.\n 반환 처리된 금액은 다시 되돌릴 수 없습니다.')
      if (yes.isConfirmed === true) {
        await this.$swal.basic.alert('전액 반환...' + index)
        try {
          await this.$fundaNowApi.refund(index)
        } catch (e) {
          this.$swal.basic.alert('전액 반환 오류..' + e)
        }

        this.$router.go(0)
      }
    },
    async getData() {
      this.dataTable.isLoading = true
      try {
        const { data } = await this.$fundaNowApi.getLoansRepaymentPreDataManualRepaymentTargets()

        this.dataTable.items = data
      } finally {
        this.dataTable.isLoading = false
      }
    },
  },
}
</script>
