<template>
  <div>
    <slot
      name="form"
      :submit="submit"
      :prop="formData"
      :loading="isLoading"
    ></slot>
  </div>
</template>
<script>
export default {
  props: {
    formData: {
      type: Object,
      default: (_) => {
        return {
          refId: null,
          sendNo: '025400360',
          mobileNo: null,
          title: null,
          content: null,
          reserveTime: null,
        }
      },
    },
  },
  data() {
    return {
      isLoading: false,
    }
  },
  watch: {
    isLoading(v) {
      this.$emit('loading', v)
    },
  },
  methods: {
    async submit() {
      if (this.isLoading === true) {
        return
      }
      this.isLoading = true
      try {
        console.log(this.formData)
        const { data } = await this.$smsApi.postSms(this.formData)

        this.$emit('complete')
        return data
      } catch (e) {
        this.$emit('error', e)
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
