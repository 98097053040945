import Vue from 'vue'

import account from './account'
import admin from './admin'
import callback from './callback'
import code from './code'
import loan from './loan'
import user from './user'
import system from './system'
import refund from './refund'
import payout from './payout'

const api = {
  ...account,
  ...admin,
  ...callback,
  ...code,
  ...loan,
  ...user,
  ...system,
  ...refund,
  ...payout,
}

const pluginName = '$paymentApi'

Vue.use({
  install(Vue) {
    window[pluginName] = api
    Vue.prototype[pluginName] = api
  },
})

export default api
