<template>
  <div>
    <FrequentRouteButtonGroup
      :fromAccount.sync="fromAccount"
      :toAccount.sync="toAccount"
    />
    <v-card>
      <v-card-text class="pt-2">
        <v-row no-gutters>
          <v-col class="pa-2" cols="12" lg="5">
            <VirtualAccount title="보내는 계좌" :vAccount.sync="fromAccount" />
          </v-col>
          <v-col
            cols="12"
            lg="2"
            class="d-flex justify-center align-center py-lg-0 py-6 pt-lg-10"
          >
            <SwapButton
              :fromAccount.sync="fromAccount"
              :toAccount.sync="toAccount"
            />
          </v-col>
          <v-col class="pa-2" cols="12" lg="5">
            <VirtualAccount title="받는 계좌" :vAccount.sync="toAccount" />
          </v-col>
        </v-row>
      </v-card-text>
      <ValidationObserver ref="validForm" v-slot="{ invalid }">
        <form>
          <v-card-text class="py-0">
            <v-row class="px-2">
              <v-col class="pb-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="amount"
                  :rules="`required|numeric|min_value:1|max_value:${
                    fromAccount ? fromAccount.balance : 0
                  }`"
                >
                  <v-text-field
                    v-model="amount"
                    outlined
                    label="금액"
                    v-input-comma-format
                    :error-messages="errors"
                    :hide-details="errors.length == 0"
                  />
                </ValidationProvider>
              </v-col>
              <v-col class="pb-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="remark"
                  rules="required|min:2"
                >
                  <v-text-field
                    v-model="remark"
                    outlined
                    label="메모"
                    :error-messages="errors"
                    :hide-details="errors.length == 0"
                  />
                </ValidationProvider>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="pt-0 px-4">
            <v-spacer />
            <v-btn
              text
              right
              x-large
              type="button"
              color="primary"
              :disabled="invalid || isTransferProgress"
              @click="addItem"
            >
              이체 목록에 추가
            </v-btn>
            <v-btn
              text
              right
              x-large
              type="button"
              color="primary"
              :disabled="invalid || isTransferProgress"
              @click="transfer"
            >
              즉시 이체
            </v-btn>
          </v-card-actions>
        </form>
      </ValidationObserver>
    </v-card>
  </div>
</template>
<script>
import SwapButton from './SwapButton.vue'
import VirtualAccount from './VirtualAccount.vue'
export default {
  components: {
    VirtualAccount,
    SwapButton,
    FrequentRouteButtonGroup: () => {
      if (process.env.NODE_ENV === 'production') {
        return import('./FrequentRouteButtonGroup.vue')
      }
    },
  },
  props: {
    isTransferProgress: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fromAccount: null,
      toAccount: null,
      amount: null,
      remark: null,
    }
  },
  methods: {
    async beforeSubmit() {
      const isFormValid = await this.$refs.validForm.validate()
      if (isFormValid !== true) {
        throw new Error('금액 및 메모가 유효하지 않습니다.')
      } else if (this.fromAccount == null || this.toAccount == null) {
        throw new Error('계좌를 선택해주세요.')
      }
    },
    async transfer() {
      try {
        await this.beforeSubmit()
      } catch (e) {
        this.$swal.basic.error({
          text: e,
        })
        return
      }
      this.emit('submit')
    },
    async addItem() {
      try {
        await this.beforeSubmit()
      } catch (e) {
        this.$swal.basic.error({
          text: e,
        })
        return
      }
      this.emit('add')
    },
    emit(name) {
      this.$emit(name, {
        fromUserVirtualAccount: this.fromAccount,
        fromUserIdx: this.fromAccount.userIdx,
        fromUserVirtualAccountIdx: this.fromAccount.idx,
        toUserVirtualAccount: this.toAccount,
        toUserIdx: this.toAccount.userIdx,
        toUserVirtualAccountIdx: this.toAccount.idx,
        amount: this.amount,
        remark: this.remark,
      })
    },
  },
}
</script>
