<template>
  <v-container fluid class="pt-0">
    <PageHeader title="알림톡 템플릿 조회" subtitle="템플릿 목록 조회">
      <template v-slot:right>
        <v-spacer />
        <v-col cols="12" lg="5" md="6" sm="6">
          <AlimtalkBizmBalance />
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="6">
          <v-btn
            text
            absolute
            right
            href="https://www.bizmsg.kr/template/list"
            target="_blank"
            color="primary"
          >
            비즈엠 템플릿 목록 바로가기
          </v-btn>
        </v-col>
      </template>
    </PageHeader>
    <v-card class="my-3" width="100%">
      <v-card-text class="py-0">
        <v-row>
          <v-col cols="12" lg="2" md="3" sm="6">
            <v-btn
              block
              color="primary"
              link
              large
              :to="{ name: 'alimtalk/templates/form' }"
            >
              새 템플릿 등록
            </v-btn>
          </v-col>
          <v-col cols="12" lg="2" md="3" sm="6">
            <TemplateExcelUploadDialog />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-data-table
      :items="templates.data"
      :loading="templates.isLoading"
      :headers="templates.headers"
      :page="templates.paging.page"
      :items-per-page="templates.paging.size"
      :server-items-length="templates.paging.totalCount"
      :footer-props="templates.footer"
      :options="templates.options"
      class="elevation-1"
      @update:items-per-page="changeSize"
      @update:page="changePage"
      show-expand
    >
      <template v-slot:loading>
        <p class="text-center title my-2">Loading items...</p>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <v-btn
          color="primary"
          link
          :to="{
            name: 'alimtalk/templates/detail/send/form',
            params: { idx: item.idx },
          }"
          :disabled="!item.isActive"
        >
          발송
        </v-btn>
      </template>

      <template v-slot:[`item.idx`]="{ item }">
        <router-link
          :to="{
            name: 'alimtalk/templates/detail',
            params: { templateIdx: item.idx },
          }"
        >
          {{ item.idx }}
        </router-link>
      </template>

      <template v-slot:[`item.createTime`]="{ item }">
        {{ item.createTime | moment('YYYY-MM-DD HH:mm:ss') }}
      </template>

      <template v-slot:[`item.isActive`]="{ item }">
        <span :class="{ 'error--text': item.isActive == false }">
          {{ item.isActive == true ? '사용가능' : '사용불가' }}
        </span>
      </template>

      <template v-slot:[`item.profileIdx`]="{ item }">
        {{ item.profileIdx | objValue(profilesObj) }}
      </template>

      <template v-slot:[`item.content`]="{ item }">
        <template v-if="item.content != null && item.content.length < 30">
          {{ item.content }}
        </template>
        <template v-else> {{ item.content.substring(0, 30) }}... </template>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td
          class="py-4 px-8"
          :colspan="headers.length"
          style="white-space: break-spaces"
        >
          {{ item.content }}
        </td>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import TemplateExcelUploadDialog from '../../../components/alimtalk/template/TemplateExcelUploadDialog.vue'
import AlimtalkBizmBalance from '../../../components/alimtalk/bizm/AlimtalkBizmBalance.vue'

export default {
  components: { TemplateExcelUploadDialog, AlimtalkBizmBalance },
  props: {
    query: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      profilesObj: null,
      queryCopy: JSON.parse(JSON.stringify(this.query)),
      templates: {
        isLoading: false,
        paging: {
          page: this.query.page ? Number(this.query.page) : 1,
          size: this.query.size ? Number(this.query.size) : 20,
          totalCount: 0,
        },
        headers: [
          { text: '발송', value: 'action', width: '40', sortable: false },
          { text: 'index', value: 'idx', width: '50', sortable: false },
          { text: '프로필', value: 'profileIdx', width: '80', sortable: false },
          { text: '템플릿 명', value: 'name', width: '200', sortable: false },
          { text: '템플릿 코드', value: 'id', width: '80', sortable: false },
          { text: '내용', value: 'content', width: '250', sortable: false },
          { text: '상태', value: 'isActive', width: '85', sortable: false },
          {
            text: '등록 시간',
            value: 'createTime',
            width: '130',
            sortable: false,
          },
        ],
        data: [],
        footer: {
          'items-per-page-options': [1, 5, 10, 20],
        },
      },
    }
  },
  async created() {
    try {
      this.templates.isLoading = true

      await this.getProfiles()

      const { data: templates } = await this.$alimtalkApi.getSearchTemplates({
        page: this.query.page,
        size: this.query.size,
        keyword: this.query.keyword,
      })

      this.templates.paging = templates.paging
      this.templates.data = templates.data
    } finally {
      this.templates.isLoading = false
    }
  },
  methods: {
    async getProfiles() {
      const { data: profiles } = await this.$alimtalkApi.getProfiles()
      let obj = {}
      profiles.forEach((profile) => {
        obj[profile.idx] = `${profile.name}(${profile.channelId})`
      })
      this.profilesObj = obj
    },
    changeSize(size) {
      if (this.templates.paging.size !== size) {
        this.$router.push({
          name: this.$route.name,
          query: Object.assign(this.queryCopy, {
            page: 1,
            size,
          }),
        })
      }
    },
    changePage(page) {
      if (this.templates.paging.page !== page) {
        this.$router.push({
          name: this.$route.name,
          query: Object.assign(this.queryCopy, {
            page,
          }),
        })
      }
    },
  },
  filters: {
    objValue(v, obj) {
      return obj[v]
    },
  },
}
</script>
