<template>
  <div>
    <v-data-table
      :headers="dataTable.headers"
      :items="dataTable.items"
      :options.sync="dataTable.options"
      :loading="dataTable.isLoading"
      :server-items-length="dataTable.totalCount"
      :footer-props="dataTable.footer"
      class="elevation-2"
    >
      <template v-slot:[`item.id`]="{ item: { id } }">
        <router-link
          :to="{ name: 'fundanow/repayment/manual/histories/detail', params: { loansRepaymentPreDataId: id } }"
        >
          {{ id }}
        </router-link>
      </template>
      <template v-slot:[`item.incomeAmount`]="{ item: { incomeAmount } }">
        {{ incomeAmount | commaFormat }} 원
      </template>
      <template v-slot:[`item.repaymentTarget`]="{ item: { repaymentTarget } }">
        {{ repaymentTarget | commaFormat }} 원
      </template>
      <template v-slot:[`item.refund`]="{ item: { refund } }">
        {{ refund | commaFormat }} 원
      </template>
      <template
        v-slot:[`item.userVirtualAccount`]="{ item: { fundaPaygateRepaymentvirtualaccountId } }"
      >
        <TransactionsRouterLink
          :userVirtualAccountIdx="fundaPaygateRepaymentvirtualaccountId"
        />
      </template>
      <template v-slot:[`item.created`]="{ item: { created } }">
        {{ created | moment('yyyy-MM-DD HH:mm:ss') }}
      </template>
      <template v-slot:[`item.modified`]="{ item: { modified } }">
        {{ modified | moment('yyyy-MM-DD HH:mm:ss') }}
      </template>

    </v-data-table>
  </div>
</template>
<script>
import TransactionsRouterLink from '@/components/payment/user/virtualAccount/TransactionsRouterLink.vue'
export default {
  components: { TransactionsRouterLink },
  props: {
    isQueryPaging: {
      type: Boolean,
      default: false,
    },
    query: {
      type: Object,
    },
  },
  data() {
    return {
      param: {
        page:
          this.isQueryPaging && this.query.page ? Number(this.query.page) : 1,
        size: this.query.size ? Number(this.query.size) : 20,
      },
      dataTable: {
        isLoading: false,
        options: null,
        headers: [
          { text: 'index', value: 'id', sortable: false },
          { text: '가상계좌', value: 'userVirtualAccount', sortable: false },
          { text: '입금액', value: 'incomeAmount', sortable: false },
          { text: '상환액', value: 'repaymentTarget', sortable: false },
          { text: '반환액', value: 'refund', sortable: false },
          { text: '등록 시간', value: 'created', sortable: false },
          { text: '수정 시간', value: 'modified', sortable: false },
        ],
        items: [],
        totalCount: 0,
        footer: {
          'items-per-page-options': [1, 5, 10, 20, 50, 100],
        },
      },
    }
  },
  watch: {
    'dataTable.options': {
      handler: function (options, oldValue) {
        if (!oldValue) {
          return
        }
        const optionObj = {
          page: options.page,
          size: options.itemsPerPage,
        }
        this.updateOption(optionObj)
      },
      deep: true,
    },
  },
  created() {
    this.dataTable.options = {
      page: this.param.page,
      itemsPerPage: this.param.size,
    }
    this.getPageData(this.param)
  },
  methods: {
    updateOption(option) {
      if (this.isQueryPaging === true) {
        return this.$router.push({
          query: option,
        })
      } else {
        this.getPageData(option)
      }
    },
    async getPageData(option) {
      if (this.dataTable.isLoading === true) {
        return
      }
      try {
        this.dataTable.isLoading = true
        const {
          data: {
            paging: { totalCount },
            data,
          },
        } = await this.$fundaNowApi.getLoansRepaymentPreDataPage(Object.assign(option, {
          manualStatus: 'SUCCESS'
        }))
        this.dataTable.items = data
        this.dataTable.totalCount = totalCount
      } finally {
        this.dataTable.isLoading = false
      }
    },
    searchParam() {
      this.dataTable.options = Object.assign(
        this.dataTable.options,
        this.param,
        {
          page: 1,
        }
      )
    },
  },
}
</script>
