<template>
  <v-simple-table :dense="dense">
    <template v-slot:default>
      <tbody>
        <tr>
          <td>index</td>
          <td>
            <router-link
              :to="{
                name: 'payment/users/virtual/accounts/transactions',
                params: {
                  userIdx: vAccount.userIdx,
                  userVirtualAccountIdx: vAccount.idx,
                },
              }"
              target="_blank"
            >
              {{ vAccount.idx }}
            </router-link>
          </td>
        </tr>
        <tr>
          <td>사용자 index</td>
          <td>
            <router-link
              :to="{
                name: 'payment/users/detail',
                params: { userIdx: vAccount.userIdx },
              }"
              target="_blank"
            >
              {{ vAccount.userIdx }}
            </router-link>
          </td>
        </tr>
        <tr>
          <td>label</td>
          <td>{{ vAccount.label }}</td>
        </tr>
        <tr>
          <td>target index</td>
          <td>{{ vAccount.targetIdx }}</td>
        </tr>
        <tr>
          <td>가상계좌 type</td>
          <td>{{ vAccount.type | payment.code('UserVirtualAccount.Type') }}</td>
        </tr>
        <tr>
          <td>은행</td>
          <td>{{ vAccount.bankCode | bankCodeName }}</td>
        </tr>
        <tr>
          <td>계좌 번호</td>
          <td>{{ vAccount.accountNo }}</td>
        </tr>
        <tr>
          <td>계좌 명</td>
          <td>{{ vAccount.accountName }}</td>
        </tr>
        <tr>
          <td>잔액</td>
          <td>{{ vAccount.balance | commaFormat }}</td>
        </tr>
        <tr>
          <td>해지 여부</td>
          <td class="pt-2 pb-3">
            <v-switch
              class="mt-0"
              :input-value="vAccount.isClosed"
              readonly
              disabled
              hide-details
            />
          </td>
        </tr>
        <tr>
          <td>입금 정지 여부</td>
          <td class="pt-2 pb-3">
            <v-switch
              class="mt-0"
              :input-value="vAccount.isDepositStopped"
              readonly
              disabled
              hide-details
            />
          </td>
        </tr>
        <tr>
          <td>입금 계좌 제한 여부</td>
          <td class="pt-2 pb-3">
            <v-switch
              class="mt-0"
              :input-value="!vAccount.isAllDepositable"
              readonly
              disabled
              hide-details
            />
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  props: {
    vAccountData: {
      type: Object,
      default: (_) => {
        return null
      },
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      vAccount: this.vAccountData,
    }
  },
  watch: {
    vAccountData(v) {
      this.vAccount = v
    },
  },
}
</script>
<style lang="scss" scoped>
tr > td:last-child {
  word-break: break-all;
}
</style>
