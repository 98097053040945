import client from '../client'
import qs from 'query-string'

export default {
  /**
   * 대출 단건 조회
   * @param idx 대출 idx
   */
  getLoan(idx) {
    return client.call({
      url: `/loan/${idx}`,
      method: HttpMethod.GET,
    })
  },
  /**
   * 사용자 대출 목록 조회
   * @param userIdx 사용자 idx
   */
  getUserLoans(userIdx, param) {
    return client.call({
      url: `/user/${userIdx}/loans?${qs.stringify(param, {
        skipNull: true,
        skipEmptyString: true,
      })}`,
      method: HttpMethod.GET,
    })
  },
}
