<template>
  <div class="px-3">
    <PageHeader title="유저 투자 파악" />

    <v-row class="py-3" no-gutters>
      <v-col cols="12">
        <v-card class="pa-2">
          <v-row no-gutters>
            <v-col cols="2">
              <v-select
                v-model="searchType"
                :items="searchItems"
                item-text="text"
                item-value="value"
                label="검색 타입"
                height="56"
                hide-details
                dense
                outlined
              >
              </v-select>
            </v-col>
            <v-col cols="10" class="d-flex">
              <v-text-field
                v-model="keyword"
                type="text"
                placeholder="쉼표로 구분"
                outlined
                hide-details
                clearable
                :loading="isLoading"
                :disabled="isLoading || isAll"
                @keyup.enter="getPersonalInfo()"
              />
              <v-btn
                color="#1baaf1"
                fab
                :dark="isLoading === false"
                :disabled="isLoading"
                @click="getPersonalInfo()"
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-row class="text-right" no-gutters>
            <v-col cols="12">
              <ExportExcelButton2
                v-if="datas.length > 0"
                :headers="dataHeaders"
                :data="datas"
                :options="{ skipHeader: true }"
                fileName="투자자_특성_파악"
              >
                <template v-slot:button="{ download }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on" @click="download">
                        <v-icon>mdi-export-variant</v-icon>
                      </v-btn>
                    </template>
                  </v-tooltip>
                </template>
              </ExportExcelButton2>
            </v-col>
          </v-row>

          <v-row class="my-3" no-gutters>
            <v-col cols="12">
              <v-data-table
                no-data-text="데이터가 없습니다"
                no-results-text="데이터가 없습니다"
                loading-text="데이터를 가져오는 중입니다..."
                :headers="dataHeaders"
                :loading="isLoading"
                :items="datas"
                :items-per-page="-1"
                hide-default-footer
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td>
                      <a @click="openDetails(item.userIdx)">
                        {{ item.userIdx }}
                      </a>
                    </td>
                    <td>
                      {{ item.userId }}
                    </td>
                    <td>
                      {{ item.userName }}
                    </td>
                    <td>
                      {{ item.affiliateCode }}
                    </td>
                    <td>
                      {{ item.withdraw | commaFormat }}
                    </td>
                    <td>
                      {{ item.pureInvestmentAmount | commaFormat }}
                    </td>
                    <td>
                      {{ item.remain | commaFormat }}
                    </td>
                    <td>
                      {{ item.usedFnAutoInvest }}
                    </td>
                    <td>
                      {{ item.lastInvestmentDate }}
                    </td>
                    <td>
                      {{ item.overdueFNAmount | commaFormat }}
                    </td>
                    <td>
                      {{ item.lastLoginDate }}
                    </td>
                    <td>
                      {{ item.overdueDonsanLen }}
                    </td>
                    <td>
                      {{ item.overdueDongsanAmount | commaFormat }}
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ExportExcelButton2 from '@/components/common/datatable/ExportExcelButton2'

export default {
  components: {
    ExportExcelButton2,
  },
  data() {
    return {
      isLoading: false,
      searchItems: [
        {
          text: '인덱스',
          value: 'idx',
        },
        {
          text: '아이디',
          value: 'userId',
        },
      ],
      searchType: 'idx',
      keyword: null,
      dataHeaders: [
        { text: '인덱스', value: 'userIdx' },
        { text: '아이디', value: 'userId' },
        { text: '이름', value: 'userName' },
        { text: '코드', value: 'affiliateCode' },
        { text: '예치금', value: 'withdraw' },
        { text: '투자중원금', value: 'pureInvestmentAmount' },
        { text: '잔여투자한도', value: 'remain' },
        { text: 'FN자동투자 여부', value: 'usedFnAutoInvest' },
        { text: '마지막 자동투자일', value: 'lastInvestmentDate' },
        { text: 'FN 연체금액', value: 'overdueFNAmount' },
        { text: '마지막 로그인', value: 'lastLoginDate' },
        { text: '동산 연체 건수', value: 'overdueDonsanLen' },
        { text: '동산 연체 금액', value: 'overdueDongsanAmount' },
      ],
      datas: [],
    }
  },
  computed: {
    isAll() {
      return false
      // 전체검색은 안되게 막는다...
      // return this.searchType === 'all'
    },
  },
  methods: {
    openDetails(idx) {
      let r = this.$router.resolve({
        name: 'fundaUserDetails',
        params: { userIdx: idx },
      })

      window.open(r.href, '_blank')
    },
    getSearchParams() {
      if (!!!this.keyword) {
        return []
      }

      let params = this.keyword.split(',')
      let temp = []

      if (this.searchType === 'idx') {
        params.forEach((p) => {
          const userIdx = parseInt(p)
          if (isNaN(userIdx) === false) {
            temp.push(userIdx)
          }
        })
      } else {
        params.forEach((p) => {
          const rep = p.replace(/ /g, '')
          if (!!rep) {
            temp.push(rep)
          }
        })
      }

      params = temp

      return params
    },
    getPureInvestmentAmount(investmentAndRepayment) {
      if (!!investmentAndRepayment) {
        const iAmt = investmentAndRepayment.investmentAmount
        const rAmt = investmentAndRepayment.repaymentPrincipal
        return iAmt - rAmt
      }

      return 0
    },
    getRemain(investmentLimit, pureInvestmentAmount) {
      return investmentLimit.totalAmountLimit - pureInvestmentAmount
    },
    getOverdueAmount(repayments) {
      let overdueAmount = 0

      repayments.forEach((rpy) => {
        let invstAmt = rpy.investmentAmount
        let rpyAmt = rpy.repaymentPrincipal

        overdueAmount += invstAmt - rpyAmt
      })

      return overdueAmount
    },
    async getPersonalInfo() {
      if (this.isLoading) {
        return
      }

      let conf = await this.$swal.basic.confirm(
        '유저 정보를 가져옵니다(수분의 시간이 걸릴 수 있습니다)'
      )

      if (conf.isConfirmed !== true) {
        return
      }

      let params = this.getSearchParams()

      if (this.isAll === false && params.length < 1) {
        this.$swal.basic.alert('검색타입 확인 혹은 검색어를 입력해주세요')
        return
      }

      this.isLoading = true

      let q = null
      let variables = null

      if (this.isAll || this.searchType === 'idx') {
        q = `data: getPersonalInfo(userIdxes: $input, isAll: ${this.isAll})`
        variables = 'call($input: [Int])'
      } else {
        q = 'data: getPersonalInfoByUserId(userIds: $input)'
        variables = 'call($input: [String]!)'
      }

      try {
        let data = await this.$fundaApi.query(gql`{${q}}`, variables, {
          input: params,
        })

        if (data.data === null) {
          // 데이터가 없으면 null
          // 있으면 undefined 임
          this.datas = []
          return
        }

        data = JSON.parse(data)

        let temp = []

        data.forEach((d) => {
          let obj = {}

          obj.userIdx = d.user.idx
          obj.userId = d.user.id
          obj.userName = d.user.name
          obj.affiliateCode = d.user.affiliateCode
          obj.withdraw = d.balance.investBalance

          const pureInvestmentAmount = this.getPureInvestmentAmount(
            d.investmentAndRepayment
          )
          obj.pureInvestmentAmount = pureInvestmentAmount
          obj.remain = this.getRemain(d.investmentLimit, pureInvestmentAmount)
          obj.usedFnAutoInvest = d.usedFnAutoInvest ? 'O' : 'X'
          obj.lastInvestmentDate = this.$options.filters.dateFormat(
            d.lastAuto.datetime
          )
          obj.overdueFNAmount = this.getOverdueAmount(d.fundaNowOverdue)
          obj.lastLoginDate = this.$options.filters.dateFormat(
            d.lastLogin.loginDatetime
          )
          obj.overdueDonsanLen = d.dongsanRepayments.length
          obj.overdueDongsanAmount = this.getOverdueAmount(d.dongsanRepayments)

          temp.push(obj)
        })

        this.datas = temp
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
