<template>
  <v-dialog v-model="dialog" width="900">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :color="$colors['f-dark-grey']"
        dark
        v-bind="attrs"
        v-on="on"
        :disabled="userInfoLoading || dataTable.isLoading"
        @click="clickModal"
      >
        변경 기록
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        입금용 주계좌 변경 기록 &#40;&nbsp;현재 입금용 주계좌:&nbsp;
        {{ withdrawAccount.bank }}&nbsp;{{
          withdrawAccount.accountWoHyphen
        }}&nbsp;{{ withdrawAccount.accountHolder }}&nbsp;&#41;
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              icon
              x-large
              color="primary"
              v-bind="attrs"
              v-on="on"
              :disabled="userInfoLoading || dataTable.isLoading"
              @click="refreshData"
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>고객 정보와 입금용 주계좌 변경 기록을 새로 불러옵니다.</span>
        </v-tooltip>
        <v-spacer></v-spacer>
        <v-btn
          color="black"
          text
          icon
          x-large
          :disabled="dataTable.isLoading"
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="mt-n4">
        <p class="mb-0 f-dark-grey--text">
          <v-icon small>mdi-chevron-right</v-icon>인증 요청 시간으로부터 5분이
          지나면 유효 시간이 만료되어 인증을 다시 받아야 합니다.
        </p>
        <p class="mb-0 f-dark-grey--text">
          <v-icon small>mdi-chevron-right</v-icon>웰컴페이먼츠 이관 후 요청 건만
          표시됩니다.
        </p>
      </v-card-text>
      <v-card-text class="py-0">
        <v-row>
          <v-col cols="12" lg="2" md="3" sm="12">
            <BooleanSelect
              trueLabel="완료"
              falseLabel="미완"
              nullLabel="전체"
              label="인증 완료 여부"
              :isNullable="true"
              :selected.sync="isVerify"
              @update:selected="getData"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text>
        <v-data-table
          :items-per-page="5"
          :headers="dataTable.headers"
          :items="dataTable.items"
          :loading="dataTable.isLoading"
          :footer-props="dataTable.footer"
        >
          <template> </template>
          <template v-slot:[`item.bankCode`]="{ item }">
            {{ item.bankCode | bankCodeName }}
          </template>
          <template v-slot:[`item.isVerify`]="{ item: { isVerify } }">
            <span :class="`${isVerify ? 'primary' : 'error'}--text`">{{
              isVerify ? '완료' : '미완'
            }}</span>
          </template>
          <template v-slot:[`item.createTime`]="{ item: { createTime } }">
            {{ createTime | moment('yyyy-MM-DD HH:mm:ss') }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import BooleanSelect from '../../../common/form/BooleanSelect.vue'
export default {
  components: { BooleanSelect },
  props: {
    paymentUserIdx: {
      type: Number,
      required: true,
    },
    withdrawAccount: {
      type: Object,
      required: true,
    },
    userInfoLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      isVerify: null,
      dataTable: {
        isInit: false,
        isLoading: false,
        items: [],
        headers: [
          { text: '은행', value: 'bankCode' },
          { text: '계좌 번호', value: 'accountNo' },
          { text: '인증 완료 여부', value: 'isVerify' },
          { text: '인증 요청 시간', value: 'createTime' },
        ],
        footer: {
          'items-per-page-options': [1, 5, 10, 20],
        },
      },
    }
  },
  methods: {
    async clickModal() {
      if (this.dataTable.isInit === false) {
        await this.getData()
        this.dataTable.isInit = true
      }
    },
    async getData() {
      if (this.dataTable.isLoading === true) {
        return
      }
      try {
        this.dataTable.isLoading = true

        const data = await this.$fundaApi.query(gql`{
          data: getPaymentUserWithdrawAccountHistories(paymentUserIdx: ${this.paymentUserIdx}, isVerify: ${this.isVerify}) {
            accountNo
            bankCode
            createTime
            isVerify
          }
        }`)

        this.dataTable.items = data
      } finally {
        this.dataTable.isLoading = false
      }
    },
    async refreshData() {
      this.$emit('refresh:userInfo')
      await this.getData()
    },
  },
}
</script>
