<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12">
        <FnStoreFundingData
          :hideFnStoreSearch="false"
          @selectFnStoreFundingData="getFnStoreFundingDatas"
        ></FnStoreFundingData>
      </v-col>
    </v-row>

    <v-row class="mb-5" no-gutters>
      <v-col cols="12">
        <FnStoreRepayment
          :key="repaymentKey"
          :funding-data-idx="fundingDataIdx"
          :fn-store-idx="fnStoreIdx"
        ></FnStoreRepayment>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import FnStoreFundingData from '@/components/funda/fundanow/FnStoreFundingData'
import FnStoreRepayment from '@/components/funda/fundanow/FnStoreRepayment'

export default {
  components: {
    FnStoreFundingData,
    FnStoreRepayment,
  },
  data() {
    return {
      fundingDataIdx: null,
      fnStoreIdx: null,
    }
  },
  computed: {
    repaymentKey() {
      if (!!!this.fundingDataIdx || !!!this.fnStoreIdx) {
        return null
      }
      return `${this.fundingDataIdx}-${this.fnStoreIdx}`
    },
  },
  methods: {
    getFnStoreFundingDatas(v) {
      if (v === null) {
        this.fundingDataIdx = v
        this.fnStoreIdx = v
        return
      }

      if (!!v.fundingDataIdx) {
        this.fundingDataIdx = v.fundingDataIdx
        this.fnStoreIdx = v.fnStoreIdx
      } else {
        this.fundingDataIdx = v.idx
        this.fnStoreIdx = v.fnStoreIdx
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
