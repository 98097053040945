import Vue from 'vue'

import repayment from './repayment'
import user from './user'
import fundaPaygate from './fundaPaygate'
import mail from './mail'
import code from './code'
import loan from './loan'

const api = {
  ...repayment,
  ...user,
  ...fundaPaygate,
  ...mail,
  ...code,
  ...loan,
}

const pluginName = '$fundaNowApi'

Vue.use({
  install(Vue) {
    window[pluginName] = api
    Vue.prototype[pluginName] = api
  },
})

export default api
