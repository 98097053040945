var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"py-3"},[_c('v-card-title',{staticClass:"py-1 headline f-light-grey justify-center text-center",staticStyle:{"background-color":"white"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"2"}}),_c('v-col',{staticClass:"position--relative",attrs:{"cols":"8"}},[_vm._v(" "+_vm._s(_vm.searchDate)+" 투자자 ")]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"2"}},[_c('ExportExcelButton2',{attrs:{"headers":_vm.exportHeaders,"data":_vm.investors,"options":{ skipHeader: true },"fileName":"투자자들"},scopedSlots:_vm._u([{key:"button",fn:function(ref){
var download = ref.download;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":download}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-export-variant")])],1)]}}],null,true)})]}}])})],1)],1)],1),_c('v-card-text',{staticClass:"py-2"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.investors,"no-data-text":_vm.noDataText,"no-results-text":_vm.noDataText,"loading":_vm.isLoading,"footer-props":{
              'items-per-page-options': [10, 20],
            }},scopedSlots:_vm._u([{key:"item",fn:function(ref){
            var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(_vm._s(item.userIdx))]),_c('td',[_vm._v(_vm._s(item.userId))]),_c('td',[_vm._v(_vm._s(item.userName))]),_c('td',[_vm._v(_vm._s(_vm._f("commaFormat")(item.sumInvestmentAmount))+" 원")])])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }