<template>
  <v-card class="position--sticky" style="top: 60px">
    <v-card-title> 이체 합계 </v-card-title>
    <v-card-text>
      <v-list class="py-0 mb-3">
        <v-list-item class="px-0">
          <v-list-item-content class="py-0">
            <v-list-item-subtitle>이체 건수</v-list-item-subtitle>
            <v-list-item-title>{{ transfers.length }} 건</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item class="px-0">
          <v-list-item-content class="py-0">
            <v-list-item-subtitle>합계 금액</v-list-item-subtitle>
            <v-list-item-title>{{ amount | commaFormat }} 원</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-btn
        large
        block
        class="title"
        color="primary"
        :disabled="transfers.length < 1 || isTransferProgress"
        :loading="isTransferProgress"
        @click="$emit('transfer')"
      >
        이체 실행
      </v-btn>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  props: {
    amount: {
      type: Number,
      default: 0,
    },
    transfers: {
      type: Array,
      default: (_) => {
        return []
      },
    },
    isTransferProgress: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
