<template>
  <v-chip :color="color" class="px-4 font-weight-bold">{{ text }}</v-chip>
</template>
<script>
import Status from '@/const/messageGroupStatus'
export default {
  props: {
    status: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      text: null,
      color: null,
    }
  },
  watch: {
    status() {
      this.setStatusText()
    },
  },
  created() {
    this.setStatusText()
  },
  methods: {
    setStatusText() {
      switch (this.status) {
        case 'WAIT':
          this.color = null
          break
        case 'PROGRESS':
          this.color = 'warning'
          break
        case 'COMPLETE':
          this.color = 'primary'
          break
        case 'FAIL':
          this.color = 'error'
          break
        case 'CANCEL':
          this.color = null
          break
        default:
          break
      }
      this.text = Status[this.status]
    },
  },
}
</script>
