<template>
  <div>
    <v-row class="mb-3" no-gutters>
      <v-col cols="12" md="12">
        <h3 class="mb-1 text-center">상환 기록</h3>
        <p class="ma-0 text-center">
          ( * 2020년 7월 31일 이후 내역만 볼 수 있습니다.)
        </p>
      </v-col>
    </v-row>

    <v-row v-if="showCalendar" class="mb-3" no-gutters>
      <v-col cols="3" sm="3" md="3"></v-col>
      <v-col cols="6" sm="6" md="6">
        <v-dialog ref="dialog" v-model="modal" persistent width="320px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="repaymentDate"
              label="상환일"
              prepend-icon="event"
              v-bind="attrs"
              v-on="on"
              readonly
              hide-details
            ></v-text-field>
          </template>
          <v-date-picker v-model="repaymentDate" scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="modal = false">Cancel</v-btn>
            <v-btn text color="primary" @click="datePick()">OK</v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>
      <v-col cols="3" sm="3" md="3"></v-col>
    </v-row>

    <v-row class="mb-3" no-gutters>
      <v-col cols="12" sm="12" md="12">
        <v-data-table
          no-data-text="상환 기록이 없습니다."
          no-results-text="상환 기록이 없습니다."
          loading-text="데이터를 가져오는 중입니다..."
          :headers="repaymentHeaders"
          :loading="isLoading"
          :items="repayments"
          :footer-props="{ 'items-per-page-options': [5, 20, 50, -1] }"
        >
          <template v-slot:item="{ item }">
            <tr
              class="clickable-data-row cursor--pointer"
              :class="selectedRepayment.idx === item.idx ? 'selected' : ''"
              @click="selectRepayment(item)"
            >
              <td class="text-center">
                {{ item.turnNo }}
              </td>
              <td class="text-center">
                {{ item.fundingDataIdx }}
              </td>
              <td class="text-center font-weight-bold">
                {{ item.createTime | dateFormat }}
              </td>
              <td class="text-center">
                {{ item.totalAmount | commaFormat }} 원
              </td>
              <td
                class="text-center"
                :class="item.isExecuted ? 'blue--text' : 'red--text'"
              >
                {{ item.isExecuted ? '지급완료' : '미지급' }}
              </td>
              <td class="text-center">
                {{ item.investUserCount | commaFormat }} 명
              </td>
              <td class="text-center">
                {{ item.investUserPrincipal | commaFormat }} 원
              </td>
              <td class="text-center">
                {{ item.investUserInterest | commaFormat }} 원
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-row class="mb-3" no-gutters>
      <v-col cols="12" sm="12" md="12">
        <CreditorRepayment
          :funding-data-repayment-idx="selectedRepayment.idx"
          @passCreditorList="(v) => (smsCreditorList = v)"
        >
        </CreditorRepayment>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CreditorRepayment from '../user/CreditorRepayment'
export default {
  props: {
    fundingDataIdx: {
      type: Number,
      default: null,
    },
    showCalendar: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CreditorRepayment,
  },
  watch: {
    fundingDataIdx: {
      immediate: true,
      handler(v) {
        if (!!v) {
          this.getFundingDataRepayments()
        }
      },
    },
    smsCreditorList(v) {
      this.$emit('passCreditorList', v)
    },
  },
  data: function () {
    return {
      isLoading: false,
      modal: false,
      repaymentDate: null,
      repaymentHeaders: [
        { text: '회차', value: 'turnNo', align: 'center' },
        {
          text: '상품호수',
          value: 'fundingDataIdx',
          align: 'center',
          sortable: false,
        },
        {
          text: '상환일',
          value: 'createTime',
          align: 'center',
          sortable: false,
        },
        {
          text: '대출자상환액',
          value: 'totalAmount',
          align: 'center',
          sortable: false,
        },
        { text: '상환금지급', value: 'isExecuted', align: 'center' },
        {
          text: '투자자수',
          value: 'investUserCount',
          align: 'center',
          sortable: false,
        },
        { text: '투자자원금', value: 'investUserPrincipal', align: 'center' },
        { text: '투자자이자', value: 'investUserInterest', align: 'center' },
      ],
      repayments: [],
      selectedRepayment: {},
      smsCreditorList: null,
    }
  },
  created: function () {},
  methods: {
    datePick() {
      this.$refs.dialog.save(this.repaymentDate)
      // this.getFundingDataRepayments()
    },
    selectRepayment(item) {
      console.log('selectRepayment!')
      this.selectedRepayment = item
      this.$emit('selectRepayment', item)
    },
    async getFundingDataRepayments() {
      if (!!!this.fundingDataIdx) {
        return
      }

      this.isLoading = true

      let q = `
          data: getFundingDataRepaymentByFundingDataIdx(fundingDataIdx: ${this.fundingDataIdx}) {
            idx
            fundingDataIdx
            turnNo
            totalAmount
            investUserPrincipal
            investUserInterest
            investUserCount
            isExecuted
            createTime
          }
        `

      try {
        let data = await this.$fundaApi.query(gql`{${q}}`)

        this.repayments = data
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
