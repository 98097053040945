<template>
  <v-container fluid class="pt-0">
    <PageHeader title="콜백 조회" subtitle="콜백 조회" />
    <v-card class="my-3">
      <v-card-text class="py-1">
        <v-form @submit.prevent="search()">
          <v-row>
            <v-col lg="2">
              <IsProcessSelect
                v-model="searchParam.isProcess"
                @change="searchParam.isProcess = $event"
              />
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" align-self="center">
              <v-btn color="primary" type="submit"> 검색 </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <v-data-table
      :loading="callbackTable.isLoading"
      :options.sync="callbackTable.options"
      :headers="callbackTable.headers"
      :items="callbackTable.items"
      :server-items-length="callbackTable.totalCount"
      :footer-props="callbackTable.footer"
    >
      <template v-slot:no-data>
        <p class="py-5 mb-0 text-center subtitle-1 grey--text">
          내역이 존재하지 않습니다.
        </p>
      </template>
      <template v-slot:[`item.idx`]="{ item }">
        <router-link
          :to="{
            name: 'payment/callbacks/detail',
            params: { callbackIdx: item.idx },
          }"
          >{{ item.idx }}</router-link
        >
      </template>
      <template v-slot:[`item.createTime`]="{ item }">
        {{ item.createTime | moment('YYYY-MM-DD HH:mm:ss') }}
      </template>
      <template v-slot:[`item.trnsctnType`]="{ item }">
        <div style="word-break: break-all">
          {{ item.trnsctnType }}
        </div>
      </template>
      <template v-slot:[`item.body`]="{ item }">
        <div style="word-break: break-all">
          {{ JSON.stringify(item.body) }}
        </div>
      </template>
      <template v-slot:[`item.jsonMemo`]="{ item }">
        {{ item.jsonMemo ? JSON.stringify(item.jsonMemo) : '' }}
      </template>
      <template v-slot:[`item.isProcess`]="{ item }">
        {{ item.isProcess ? '완료' : '미완' }}
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import IsProcessSelect from '../../../components/payment/callback/select/IsProcessSelect.vue'

export default {
  components: { IsProcessSelect },
  props: {
    query: {
      type: Object,
    },
  },
  data() {
    return {
      callbackTable: {
        options: null,
        isLoading: false,
        items: [],
        totalCount: 0,
        headers: [
          { text: 'idx', value: 'idx', sortable: false },
          { text: 'tid', value: 'tid', sortable: false },
          { text: 'trnsctnState', value: 'trnsctnState', sortable: false },
          {
            text: 'trnsctnType',
            value: 'trnsctnType',
            sortable: false,
            width: 200,
          },
          { text: 'body', value: 'body', sortable: false, width: 400 },
          { text: 'jsonMemo', value: 'jsonMemo', sortable: false },
          { text: 'isProcess', value: 'isProcess', sortable: false },
          {
            text: 'createTime',
            value: 'createTime',
            sortable: false,
            width: 200,
          },
        ],
        footer: {
          'items-per-page-options': [1, 5, 10, 20, 50, 100],
        },
      },
      searchParam: {
        page: this.query.page ? Number(this.query.page) : 1,
        size: this.query.size ? Number(this.query.size) : 20,
        isProcess:
          this.query.isProcess != null
            ? this.query.isProcess === true || this.query.isProcess === 'true'
            : null,
      },
    }
  },
  watch: {
    'callbackTable.options': {
      handler: function (options, oldValue) {
        if (oldValue == null) {
          return
        }
        const optionObj = {
          page: options.page,
          size: options.itemsPerPage,
        }
        this.updateParam(optionObj)
      },
      deep: true,
    },
  },
  async created() {
    console.log(this.query, this.searchParam)
    // dataTable 초기값 설정
    this.callbackTable.options = {
      page: this.searchParam.page,
      itemsPerPage: this.searchParam.size,
    }

    try {
      this.callbackTable.isLoading = true

      const {
        data: {
          paging: { totalCount },
          data,
        },
      } = await this.$paymentApi.getCallbacks(this.searchParam)
      this.callbackTable.items = data
      this.callbackTable.totalCount = totalCount
    } finally {
      this.callbackTable.isLoading = false
    }
  },
  methods: {
    updateParam(option) {
      this.$router.push({
        name: this.$route.name,
        query: Object.assign(this.searchParam, option),
      })
    },
    search() {
      this.updateParam({
        page: 1,
      })
    },
  },
}
</script>
