<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :loading="isLoading"
    :server-items-length="totalCount"
    :items-per-page="-1"
    hide-default-footer
    show-select
    single-select
    v-model="selectedItem"
    @item-selected="select($event.item, $event.value)"
    item-key="idx"
  >
    <template v-slot:no-data>
      <p class="py-5 mb-0 text-center subtitle-1 grey--text">
        아직 등록된 가상 계좌가 없습니다.
      </p>
    </template>

    <template v-slot:[`item.idx`]="{ item }">
      <router-link
        :to="{
          name: 'payment/users/virtual/accounts/transactions',
          params: {
            userIdx: item.userIdx,
            userVirtualAccountIdx: item.idx,
          },
        }"
      >
        {{ item.idx }}
      </router-link>
    </template>

    <template v-slot:[`item.bankCode`]="{ item }">
      {{ item.bankCode | bankCodeName }}
    </template>

    <template v-slot:[`item.type`]="{ item: { type } }">
      <template v-if="type">
        {{ type | payment.code('UserVirtualAccount.Type') }}
      </template>
      <template v-else> - </template>
    </template>

    <template v-slot:[`item.balance`]="{ item }">
      {{ item.balance | commaFormat }}
    </template>

    <template v-slot:[`item.isClosed`]="{ item }">
      <v-switch
        class="mt-0"
        :input-value="item.isClosed"
        readonly
        disabled
        hide-details
      />
    </template>

    <template v-slot:[`item.isDepositStopped`]="{ item }">
      <v-switch
        class="mt-0"
        :input-value="item.isDepositStopped"
        readonly
        disabled
        hide-details
      />
    </template>

    <template v-slot:[`item.isAllDepositable`]="{ item }">
      <v-switch
        class="mt-0"
        :input-value="!item.isAllDepositable"
        readonly
        disabled
        hide-details
      />
    </template>

    <template v-slot:[`item.createTime`]="{ item }">
      {{ item.createTime | moment('YYYY-MM-DD HH:mm:ss') }}
    </template>

    <template v-slot:[`item.guid`]="{ item }">
      <WelcomeGuid
        v-if="item.targetIdx"
        :userIdx="item.userIdx"
        :userVirtualAccountIdx="item.idx"
      />
      <template v-else>-</template>
    </template>
  </v-data-table>
</template>
<script>
import WelcomeGuid from './WelcomeGuid.vue'
export default {
  components: { WelcomeGuid },
  props: {
    userIdx: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        { text: 'index', value: 'idx', sortable: false },
        { text: '계좌명', value: 'accountName', sortable: false },
        { text: '은행', value: 'bankCode', sortable: false },
        { text: '계좌번호', value: 'accountNo', sortable: false },
        { text: '타입', value: 'type', sortable: false },
        { text: '잔액', value: 'balance', sortable: false },
        { text: '해지여부', value: 'isClosed', sortable: false },
        { text: '입금정지', value: 'isDepositStopped', sortable: false },
        { text: '입금계좌 제한', value: 'isAllDepositable', sortable: false },
        { text: 'guid', value: 'guid', sortable: false },
        { text: '플랫폼', value: 'paymentType', sortable: false },
      ],
      items: [],
      isLoading: false,
      totalCount: 0,
      selectedItem: [],
    }
  },
  async created() {
    try {
      this.isLoading = true
      const {
        data: {
          paging: { totalCount },
          data,
        },
      } = await this.$paymentApi.getUserVirtualAccounts(this.userIdx)

      if (data != null && data.length > 0) {
        // 임시: 처음 선택되는 데이터에서 이상한 데이터 거르는용
        let idx = data.findIndex(
          (item) => item.isClosed === false && item.type && item.targetIdx
        )
        if (!idx) {
          idx = 0
        }
        this.select(data[idx], true)
        this.items = data
        this.totalCount = totalCount
      }
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    select(item, value) {
      this.selectedItem = value === true ? [item] : []
      this.$emit('select', value === true ? item : null)
    },
  },
}
</script>
