<template>
  <div>
    <v-data-table
      :headers="dataTable.headers"
      :items="dataTable.items"
      :options.sync="dataTable.options"
      :loading="dataTable.isLoading"
      :footer-props="dataTable.footer"
      :items-per-page="-1"
      class="elevation-2 my-3"
      hide-default-footer
    >
      <template v-slot:[`item.idx`]="{ item }">
        <router-link
          :to="{
            name: 'payment/users/virtual/accounts/transactions',
            params: {
              userIdx: item.userIdx,
              userVirtualAccountIdx: item.idx,
            },
          }"
        >
          {{ item.idx }}
        </router-link>
      </template>

      <template v-slot:[`item.userIdx`]="{ item }">
        <router-link
          :to="{
            name: 'payment/users/detail',
            params: {
              userIdx: item.userIdx,
            },
          }"
        >
          {{ item.userIdx }}
        </router-link>
      </template>

      <template v-slot:[`item.bankCode`]="{ item }">
        {{ item.bankCode | bankCodeName }}
      </template>

      <template v-slot:[`item.type`]="{ item }">
        {{ item.type | payment.code('UserVirtualAccount.Type') }}
      </template>

      <template v-slot:[`item.balance`]="{ item }">
        {{ item.balance | commaFormat }}
      </template>

      <template v-slot:[`item.isClosed`]="{ item }">
        <v-switch
          class="mt-0"
          :input-value="item.isClosed"
          readonly
          disabled
          hide-details
        />
      </template>

      <template v-slot:[`item.isDepositStopped`]="{ item }">
        <v-switch
          class="mt-0"
          :input-value="item.isDepositStopped"
          readonly
          disabled
          hide-details
        />
      </template>

      <template v-slot:[`item.isAllDepositable`]="{ item }">
        <v-switch
          class="mt-0"
          :input-value="!item.isAllDepositable"
          readonly
          disabled
          hide-details
        />
      </template>

      <template v-slot:[`item.createTime`]="{ item }">
        {{ item.createTime | moment('YYYY-MM-DD HH:mm:ss') }}
      </template>
    </v-data-table>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dataTable: {
        isLoading: false,
        options: null,
        headers: [
          { text: '가상계좌 index', value: 'idx', sortable: false },
          { text: '사용자 index', value: 'userIdx', sortable: false },
          { text: 'label', value: 'label', sortable: false },
          { text: '계좌명', value: 'accountName', sortable: false },
          { text: '은행', value: 'bankCode', sortable: false },
          { text: '계좌번호', value: 'accountNo', sortable: false },
          { text: '타입', value: 'type', sortable: false },
          { text: '잔액', value: 'balance', sortable: false },
          { text: '해지여부', value: 'isClosed', sortable: false },
          { text: '입금정지', value: 'isDepositStopped', sortable: false },
          { text: '입금계좌 제한', value: 'isAllDepositable', sortable: false },
        ],
        items: [],
      },
    }
  },
  async created() {
    this.getData()
  },
  methods: {
    async getData() {
      this.dataTable.isLoading = true
      try {
        const { data } = await this.$paymentApi.getSystemUserVirtualAccounts()

        this.dataTable.items = data
      } finally {
        this.dataTable.isLoading = false
      }
    },
  },
}
</script>
