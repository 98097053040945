<template>
  <div>
    <v-fade-transition>
      <div v-if="isLoading == true" class="py-8 text-center">
        <v-progress-circular indeterminate size="24" color="grey" />
      </div>
      <div v-else>
        <v-row>
          <v-col class="py-0">로컬잔액</v-col>
          <v-col class="py-0 font-weight-bold text-right">{{
            balance.localBalance | commaFormat
          }}</v-col>
        </v-row>
        <v-row>
          <v-col class="py-0">api가용잔액</v-col>
          <v-col class="py-0 font-weight-bold text-right">{{
            (balance.balanceTotAmt - balance.pendingTrnsfrAmt) | commaFormat
          }}</v-col>
        </v-row>
        <v-divider class="my-2" />
        <v-row>
          <v-col class="py-0">api전체잔액</v-col>
          <v-col class="py-0 font-weight-bold text-right">{{
            balance.balanceTotAmt | commaFormat
          }}</v-col>
        </v-row>
        <v-row>
          <v-col class="py-0">api지연잔액</v-col>
          <v-col class="py-0 font-weight-bold text-right">{{
            balance.pendingTrnsfrAmt | commaFormat
          }}</v-col>
        </v-row>
      </div>
    </v-fade-transition>
    <div class="mt-3">
      <v-btn @click="getBalance" block color="primary">잔액 조회</v-btn>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    name: String,
    userIdx: Number,
    userVirtualAccountIdx: Number,
    paymentType: String,
  },
  data() {
    return {
      balance: {
        localBalance: 0,
        balanceTotAmt: 0,
        pendingTrnsfrAmt: 0,
        changeDttm: null,
      },
      isLoading: false,
    }
  },
  created() {
    console.log(this.paymentType)
  },
  methods: {
    async getBalance() {
      try {
        this.isLoading = true
        if (this.paymentType === 'WELCOME') {
          let { data } =
            await this.$paymentApi.getUserVirtualAccountBalanceWelcome(
              this.userIdx,
              this.userVirtualAccountIdx
            )
          this.balance = data
          this.$emit('change', data)
        } else {
          const r1 = await this.$paymentApi.getUserVirtualAccountBalance(
            this.userIdx,
            this.userVirtualAccountIdx,
            { isApiBalance: true }
          )
          const r2 = await this.$paymentApi.getUserVirtualAccountBalance(
            this.userIdx,
            this.userVirtualAccountIdx
          )
          this.balance = {
            localBalance: r2.data,
            balanceTotAmt: r1.data,
            pendingTrnsfrAmt: 0,
            changeDttm: null,
          }
          this.$emit('change', this.balance)
        }
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
<style lang="scss" scoped></style>
