<template>
  <div>
    <v-card class="my-3" width="100%">
      <v-card-text class="py-1">
        <v-form @submit.prevent="searchParam()">
          <v-row>
            <v-col cols="12" lg="2" md="4" sm="6">
              <CommonDatePicker
                v-model="param.fromDate"
                label="검색 시작일 선택"
                @change="param.fromDate = $event"
              />
            </v-col>
            <v-col cols="12" lg="2" md="4" sm="6">
              <CommonDatePicker
                v-model="param.toDate"
                label="검색 종료일 선택"
                @change="param.toDate = $event"
              />
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6">
              <v-text-field
                v-model="param.keyword"
                outlined
                dense
                clearable
                label="remark"
                prepend-inner-icon="mdi-magnify"
                hide-details
              />
            </v-col>
            <v-col cols="12" lg="1" md="4" sm="6" align-self="center">
              <v-btn
                color="primary"
                type="submit"
                :disabled="dataTable.isLoading"
              >
                검색
              </v-btn>
            </v-col>
            <v-spacer />
            <v-col cols="12" lg="2" md="4" sm="6" align-self="center">
              <ExportExcelButton
                block
                :headers="
                  isDoubleEntry == true
                    ? dataTable.doubleEntryHeaders
                    : dataTable.headers
                "
                :data="dataTable.items"
                fileName="payment_user_virtual_account_transactions"
                label="현재 페이지 엑셀 다운"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <v-data-table
      :loading="dataTable.isLoading"
      :options.sync="dataTable.options"
      :headers="
        isDoubleEntry == true ? dataTable.doubleEntryHeaders : dataTable.headers
      "
      :items="dataTable.items"
      :server-items-length="dataTable.totalCount"
      :footer-props="dataTable.footer"
      @current-items="test"
      class="elevation-2"
    >
      <template v-slot:no-data>
        <p class="py-5 mb-0 text-center subtitle-1 grey--text">
          거래내역이 존재하지 않습니다.
        </p>
      </template>

      <template v-slot:[`item.typeName`]="{ item }">
        {{
          (item.typeName = $options.filters['payment.code'](
            item.type,
            'UserVirtualAccountTransaction.Type'
          ))
        }}
      </template>

      <template v-slot:[`item.inOutName`]="{ item }">
        <span :class="item.inOut == 'IN' ? 'error--text' : 'blue--text'">
          {{
            (item.inOutName = $options.filters['payment.code'](
              item.inOut,
              'UserVirtualAccountTransaction.InOut'
            ))
          }}
        </span>
      </template>

      <template v-slot:[`item.amount`]="{ item }">
        <span :class="item.inOut == 'IN' ? 'error--text' : 'blue--text'">
          {{ item.amount | commaFormat }}
        </span>
      </template>

      <template v-slot:[`item.inAmount`]="{ item }">
        <span class="error--text">
          {{
            (item.inAmount = item.inOut == 'IN' ? item.amount : 0) | commaFormat
          }}
        </span>
      </template>

      <template v-slot:[`item.outAmount`]="{ item }">
        <span class="blue--text">
          {{
            (item.outAmount = item.inOut == 'OUT' ? item.amount : 0)
              | commaFormat
          }}
        </span>
      </template>

      <template v-slot:[`item.balance`]="{ item }">
        {{ item.balance | commaFormat }}
      </template>

      <template v-slot:[`item.createTime`]="{ item }">
        {{ item.createTime | moment('YYYY-MM-DD HH:mm:ss') }}
      </template>
    </v-data-table>
  </div>
</template>
<script>
import ExportExcelButton from '../common/datatable/ExportExcelButton.vue'
import CommonDatePicker from '../common/form/CommonDatePicker.vue'

export default {
  components: {
    CommonDatePicker,
    ExportExcelButton,
  },
  props: {
    userIdx: {
      type: Number,
      required: false,
    },
    userVirtualAccountIdx: {
      type: Number,
      required: true,
    },
    isQueryPaging: {
      type: Boolean,
      default: false,
    },
    isDoubleEntry: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      param: {
        page:
          this.isQueryPaging && this.$route.query.page
            ? Number(this.$route.query.page)
            : 1,
        size:
          this.isQueryPaging && this.$route.query.size
            ? Number(this.$route.query.size)
            : 20,
        fromDate:
          this.isQueryPaging && this.$route.query.fromDate
            ? this.$route.query.fromDate
            : null,
        toDate:
          this.isQueryPaging && this.$route.query.toDate
            ? this.$route.query.toDate
            : null,
        keyword:
          this.isQueryPaging && this.$route.query.keyword
            ? this.$route.query.keyword
            : null,
      },
      dataTable: {
        isLoading: false,
        options: null,
        headers: [
          { text: 'index', value: 'idx', sortable: false },
          { text: '거래유형', value: 'typeName', sortable: false },
          { text: '입금/출금', value: 'inOutName', sortable: false },
          { text: '금액', value: 'amount', sortable: false },
          { text: '잔액', value: 'balance', sortable: false },
          { text: 'remark', value: 'remark', sortable: false },
          { text: '시간', value: 'createTime', sortable: false },
        ],
        doubleEntryHeaders: [
          { text: 'index', value: 'idx', sortable: false },
          { text: '거래유형', value: 'typeName', sortable: false },
          { text: '입금/출금', value: 'inOutName', sortable: false },
          { text: '입금', value: 'inAmount', sortable: false },
          { text: '출금', value: 'outAmount', sortable: false },
          { text: '잔액', value: 'balance', sortable: false },
          { text: '메모', value: 'remark', sortable: false },
          { text: '시간', value: 'createTime', sortable: false },
        ],
        items: [],
        totalCount: 0,
        footer: {
          'items-per-page-options': [
            1, 5, 10, 20, 50, 100, 500, 1000, 2000, 5000, 10000, 20000, 50000,
          ],
        },
      },
    }
  },
  watch: {
    'dataTable.options': {
      handler: function (options, oldValue) {
        if (oldValue === null) {
          return
        }
        const optionObj = {
          page: options.page,
          size: options.itemsPerPage,
          fromDate: options.fromDate,
          toDate: options.toDate,
          keyword: options.keyword,
        }
        this.updateOption(optionObj)
      },
      deep: true,
    },
  },
  created() {
    this.initTableOption()
    this.getPageData(this.param)
  },
  methods: {
    initTableOption() {
      this.dataTable.options = {
        page: this.param.page,
        itemsPerPage: this.param.size,
        fromDate: this.param.fromDate,
        toDate: this.param.toDate,
        keyword: this.param.keyword,
      }
    },
    updateOption(option) {
      if (this.isQueryPaging === true) {
        return this.$router.push({
          params: this.$route.params,
          query: option,
        })
      } else {
        this.getPageData(option)
      }
    },
    async getPageData(option) {
      if (this.dataTable.isLoading === true) {
        return
      }
      try {
        this.dataTable.isLoading = true
        const {
          data: {
            paging: { totalCount },
            data,
          },
        } = await this.$paymentApi.getUserVirtualAccountTransactions(
          this.userVirtualAccountIdx,
          option
        )
        this.dataTable.items = data
        this.dataTable.totalCount = totalCount
      } finally {
        this.dataTable.isLoading = false
      }
    },
    searchParam() {
      const copyOption = JSON.parse(JSON.stringify(this.dataTable.options))
      this.dataTable.options = Object.assign(copyOption, this.param, {
        page: 1,
      })
    },
    test(e) {
      console.log(e)
    },
  },
}
</script>

<style>
.hide1 {
  display: none;
}
</style>
