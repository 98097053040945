<template>
  <div v-if="showText">
    <v-row no-gutters>
      <v-col cols="6" sm="6" md="6" class="d-flex pr-0">
        <p class="left-key d-flex">
          개인 투자한도
          <a
            class="ml-1"
            href="//blog.naver.com/fundamaster/222484638992"
            target="_blank"
          >
          </a>
        </p>
      </v-col>
      <v-col cols="6" sm="6" md="6" class="text-right pl-0">
        <LoadingText
          :is-loading="isLoading"
          :loading-size="16"
          :loading-width="2"
        >
          <span v-if="myLimitType === 'UNLIMIT'" class="right-value">
            무제한
          </span>
          <span v-else class="right-value">
            {{
              myDashboardData.user.investmentLimit.totalAmountLimit
                | commaFormat
            }}
            원
          </span>
        </LoadingText>
      </v-col>
    </v-row>

    <v-row no-gutters v-if="showDetail === true && myLimitType !== 'UNLIMIT'">
      <v-col cols="7" class="d-flex align-center mb-0">
        <p class="font-size--12 ma-0 pl-1">(-)온투업권 투자 중 원금</p>
      </v-col>
      <v-col cols="5" class="text-right mb-0 pl-0">
        <LoadingText
          :is-loading="isLoading"
          :loading-size="16"
          :loading-width="2"
        >
          <span class="font-size--13 right-value">
            <template v-if="!!userInvestment">
              {{ (userInvestment.p2p - userInvestment.pure) | commaFormat }}
            </template>
            <template v-else> 0 </template>
            원
          </span>
        </LoadingText>
      </v-col>

      <v-col cols="7" class="d-flex align-center mb-0">
        <p class="font-size--12 ma-0 pl-1">(-)펀다 투자 중 원금</p>
      </v-col>
      <v-col cols="5" class="text-right mb-0 pl-0">
        <LoadingText
          :is-loading="isLoading"
          :loading-size="16"
          :loading-width="2"
        >
          <span class="font-size--13 right-value">
            <template v-if="!!userInvestment">
              {{ userInvestment.pure | commaFormat }}
            </template>
            <template v-else> 0 </template>
            원
          </span>
        </LoadingText>
      </v-col>

      <hr class="width-100 mt-1 mb-2" />
    </v-row>

    <v-row no-gutters>
      <v-col cols="6" sm="6" md="6" class="d-flex">
        <p class="left-key d-flex align-center">잔여 투자한도</p>
      </v-col>
      <v-col cols="6" sm="6" md="6" class="text-right pl-0">
        <LoadingText
          :is-loading="isLoading"
          :loading-size="16"
          :loading-width="2"
        >
          <span v-if="myLimitType === 'UNLIMIT'" class="right-value">
            무제한
          </span>
          <span v-else class="right-value">
            <template v-if="!!userInvestment">
              {{ remainTotalLimit &lt; 0 ? 0 : remainTotalLimit | commaFormat }}
            </template>
            <template v-else> 0 </template>
            원
          </span>
        </LoadingText>
      </v-col>
    </v-row>

    <v-row v-if="showIncreaseBtn" class="mt-3" no-gutters>
      <v-col cols="12">
        <v-btn class="btn-increase" depressed tile :to="`/consult`">
          투자 한도 증액하기
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { InvestmentTypes } from '@/store/www/types/investment'
import LoadingText from '@/components/common/text/LoadingText.vue'

export default {
  props: {
    myDashboardData: {
      type: Object,
    },
    showText: {
      type: Boolean,
      default: true,
    },
    showBtn: {
      type: Boolean,
      default: false,
    },
    showDetail: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    LoadingText,
  },
  data() {
    return {
      myLimitType: 'LIMIT',
    }
  },
  computed: {
    ...mapGetters('www/investment', {
      isLoading: InvestmentTypes.getters.GET_USER_INVESTMENT_LOADING,
      userInvestment: InvestmentTypes.getters.GET_USER_INVESTMENT,
    }),
    remainTotalLimit() {
      const v =
        this.myDashboardData.user.investmentLimit.totalAmountLimit -
        this.userInvestment.p2p
      return v
    },
    showIncreaseBtn() {
      return this.showBtn && this.myDashboardData.user.investmentLimit.idx === 1
    },
  },
  watch: {
    myDashboardData: {
      deep: true,
      immediate: true,
      handler(v) {
        if (!!v) {
          this.getUserInvestment(v.userIdx)
          if (v.user.investmentLimit.totalAmountLimit === 0) {
            this.myLimitType = 'UNLIMIT'
          }
        }
      },
    },
  },
  methods: {
    ...mapActions('www/investment', {
      getUserInvestment: InvestmentTypes.actions.GET_USER_INVESTMENT,
    }),
  },
}
</script>

<style lang="scss" scoped>
.left-key {
  display: flex;
  align-items: center;
  margin: 0 !important;
  padding: 0;
  font-size: 14px;
  color: #727d8c;

  a {
    img {
      width: 16px;
      height: 16px;
    }
  }
}

.right-value {
  color: #333;
  font-size: 14px;
  font-weight: 400;
}

.btn-increase {
  border: 1px solid #d2d3d4;
  color: #666666;
  background-color: #f8f9fb;
  width: 100%;
  font-size: 14px;
  letter-spacing: -0.25px !important;
  height: 34px;

  @media (max-width: 960px) {
    height: 44px;
  }

  .mdi-chevron-right::before {
    font-size: 18px;
    color: rgb(102, 102, 102) !important;
  }
}
</style>
