<template>
  <div>
    <v-row class="my-3" no-gutters>
      <v-col cols="12" sm="12" md="12">
        <h3 class="text-center">
          <template v-if="!!repayments">
            {{ fundingDataIdx }}호 [ {{ fnStore.storeName }} ] 상환내역
          </template>
          <template v-else> 상환내역 </template>
        </h3>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col cols="12">
        <TuiGrid
          :columns="columns"
          :data="repayments"
          :summary="summary"
        ></TuiGrid>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TuiGrid from '@/components/toast/Grid'

export default {
  props: {
    userIdx: {
      type: Number,
      default: null,
    },
    fundingDataIdx: {
      type: Number,
      default: null,
    },
    fnStoreIdx: {
      type: Number,
      default: null,
    },
  },
  components: {
    TuiGrid,
  },
  data() {
    return {
      isLoading: false,
      columns: [
        {
          header: '상환일',
          name: 'createdDate',
          align: 'center',
          sortable: true,
        },
        {
          header: '투자잔액',
          name: 'balance',
          align: 'center',
          sortable: true,
          formatter({ value }) {
            return `${Intl.NumberFormat().format(value)} 원`
          },
        },
        {
          header: '총 세후',
          name: 'totalRepaymentAfterCost',
          align: 'center',
          sortable: true,
          formatter({ value }) {
            return `${Intl.NumberFormat().format(value)} 원`
          },
        },
        {
          header: '상환원금',
          name: 'principal',
          align: 'center',
          sortable: true,
          formatter({ value }) {
            return `${Intl.NumberFormat().format(value)} 원`
          },
        },
        {
          header: '이자',
          name: 'interest',
          align: 'center',
          sortable: true,
          formatter({ value }) {
            return `${Intl.NumberFormat().format(value)} 원`
          },
        },
        {
          header: '연체이자',
          name: 'overdueInterest',
          align: 'center',
          sortable: true,
          formatter({ value }) {
            return `${Intl.NumberFormat().format(value)} 원`
          },
        },
        {
          header: '세금',
          name: 'tax',
          align: 'center',
          sortable: true,
          formatter({ value }) {
            return `${Intl.NumberFormat().format(value)} 원`
          },
        },
        {
          header: '수수료',
          name: 'fee',
          align: 'center',
          sortable: true,
          formatter({ value }) {
            return `${Intl.NumberFormat().format(value)} 원`
          },
        },
      ],
      summary: {
        height: 40,
        position: 'top',
        columnContent: {
          totalRepaymentAfterCost: {
            template: function (valueMap) {
              return `<p class="ma-0 text-center">총 세후: ${Intl.NumberFormat().format(
                valueMap.sum
              )} 원</p>`
            },
          },
          principal: {
            template: function (valueMap) {
              return `<p class="ma-0 text-center">총 원금: ${Intl.NumberFormat().format(
                valueMap.sum
              )} 원</p>`
            },
          },
          interest: {
            template: function (valueMap) {
              return `<p class="ma-0 text-center">총 이자: ${Intl.NumberFormat().format(
                valueMap.sum
              )} 원</p>`
            },
          },
          overdueInterest: {
            template: function (valueMap) {
              return `<p class="ma-0 text-center">총 연체이자: ${Intl.NumberFormat().format(
                valueMap.sum
              )} 원</p>`
            },
          },
          tax: {
            template: function (valueMap) {
              return `<p class="ma-0 text-center">총 세금: ${Intl.NumberFormat().format(
                valueMap.sum
              )} 원</p>`
            },
          },
          fee: {
            template: function (valueMap) {
              return `<p class="ma-0 text-center">총 수수료: ${Intl.NumberFormat().format(
                valueMap.sum
              )} 원</p>`
            },
          },
        },
      },
      fnStore: {
        storeName: '',
      },
      fnCreditorRepayments: [],
    }
  },
  computed: {
    repayments() {
      if (!!!this.fundingDataIdx || !!!this.fnStoreIdx) {
        return null
      }
      return this.fnCreditorRepayments
    },
  },
  created() {
    this.getRepayments()
  },
  methods: {
    async getRepayments() {
      let q = `
        fnStore: getFnStoreByIdx(fnStoreIdx: ${this.fnStoreIdx}) {
          storeName
        }

        repayments: getFnCreditorRepayments(userIdx: ${this.userIdx}, fundingDataIdx: ${this.fundingDataIdx}, fnStoreIdx: ${this.fnStoreIdx}) {
          paybackPresentIdx
          totalRepayment
          totalRepaymentAfterCost
          totalJeon
          balance
          principal
          interest
          overdueInterest
          tax
          taxNational
          taxLocal
          fee
          createdAt
          createdDate
        }
      `

      this.isLoading = true

      try {
        let { fnStore, repayments } = await this.$fundaApi.query(gql`{${q}}`)

        this.fnStore = fnStore
        this.fnCreditorRepayments = repayments
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
