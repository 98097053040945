import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import ko from 'vuetify/src/locale/ko.ts'
import colors from '@/assets/scss/common/color.scss'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'mdi', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  breakpoint: {
    scrollBarWidth: 6,
    mobileBreakpoint: 'xs',
  },
  theme: {
    themes: {
      light: {
        primary: colors['f-blue-base'],
        warning: colors['f-warning'],
        error: colors['f-error'],
      },
    },
  },
  lang: {
    locales: { ko },
    current: 'ko',
  },
})
