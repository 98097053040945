<template>
  <div class="official-notice">
    <router-view></router-view>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.official-notice {
  padding: 0 16px;

  *,
  ::v-deep {
    line-height: 1.3 !important;

    .v-tabs-bar {
      margin-bottom: 20px;
    }

    .v-tabs-items {
      background-color: #f6f8fa !important;
    }

    .data-table-wrapper {
      overflow: scroll;
    }

    table {
      width: 100%;
      border-collapse: collapse;

      th,
      td {
        border: 1px solid #333;
        padding-top: 8px !important;
        padding-bottom: 8px !important;
        padding-left: 5px !important;
        padding-right: 5px !important;
      }

      thead {
        th {
          background-color: #eee !important;
        }
      }
    }
  }
}
</style>
