<template>
  <div>
    <PageHeader title="문자 관리" />

    <v-row class="mb-3 pa-3" no-gutters>
      <v-col cols="12" sm="12" md="12">
        <v-tabs
          v-model="tab"
          fixed-tabs
          show-arrows
          color="deep-purple accent-4"
          flat
        >
          <v-tab @click="showTab = 'main'"> 메인 </v-tab>

          <v-tab @click="showTab = 'debtor'"> 대출자 </v-tab>

          <v-tab @click="showTab = 'creditor'"> 투자자 </v-tab>

          <v-tab @click="showTab = 'done'"> 전송리스트 </v-tab>
        </v-tabs>

        <v-card class="pa-2" min-height="300" outlined>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-row no-gutters>
                <v-col cols="12" sm="12" md="12">
                  <UserList
                    ref="smsUserList"
                    :get-first="false"
                    @selectUser="addMain"
                  />
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item> </v-tab-item>

            <v-tab-item>
              <v-row no-gutters>
                <v-col cols="12">
                  <SmsCreditorList
                    ref="smsCreditorList"
                    v-if="showTab === 'creditor'"
                    @passCreditorList="addCreditor"
                  ></SmsCreditorList>
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item>
              <v-row no-gutters>
                <v-col cols="12">
                  <SmsDoneList v-if="showTab === 'done'"></SmsDoneList>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="my-3 px-3" no-gutters>
      <v-col cols="12">
        <Sms
          :data-columns="dataColumns"
          :sms-data="smsData"
          @clearData="clear"
        ></Sms>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import UserList from '@/components/funda/user/List'
import SmsCreditorList from '@/components/sms/SmsCreditorList'
import SmsDoneList from '@/components/sms/SmsDoneList'
import Sms from '@/components/sms/Sms'

export default {
  components: {
    UserList,
    SmsCreditorList,
    SmsDoneList,
    Sms,
  },
  data() {
    return {
      tab: null,
      showTab: 'main',
      creditorTab: null,
      numberValues: [
        'totalAmountAfterTax',
        'repaymentPrincipal',
        'repaymentInterest',
        'repaymentOverdueInterest',
        'repaymentAdditionalInterest',
        'repaymentTax',
        'repaymentFee',
      ],
      dateValues: ['repaymentTime'],
      dataColumns: [],
      smsData: [],
    }
  },
  watch: {
    showTab() {
      this.clear()
    },
  },
  methods: {
    addSmsData(v) {
      let tempArr = Array.isArray(v) ? v : [v]
      let result = [...this.smsData]

      tempArr.forEach((temp) => {
        if (this.showTab === 'main') {
          temp.userIdx = temp.idx
        }

        // 기존 smsData 목록에 유저가 없으면
        if (
          this.smsData.some((sms) => sms.userIdx === temp.userIdx) === false
        ) {
          this.numberValues.forEach((key) => {
            if (!!temp[key]) {
              temp[key] = Intl.NumberFormat().format(temp[key])
            }
          })

          this.dateValues.forEach((key) => {
            if (!!temp[key]) {
              temp[key] = this.$options.filters.dateFormat2(temp[key])
            }
          })

          result.push(temp)
        }
      })

      this.smsData = result
    },
    addMain(v) {
      if (this.dataColumns.length < 1) {
        this.dataColumns = this.$refs.smsUserList.headers
      }
      this.addSmsData(v)
    },
    addCreditor(v) {
      if (!!!v.item) {
        return
      }
      if (this.dataColumns.length < 1) {
        this.dataColumns = v.headers
      }
      this.addSmsData(v.item)
    },
    clear() {
      this.dataColumns = []
      this.smsData = []
    },
  },
}
</script>

<style lang="scss" scoped></style>
