<template>
  <v-container fluid class="pt-0">
    <PageHeader
      title="알림톡 템플릿 상세 조회"
      subtitle="알림톡 템플릿 상세 조회"
    />
    <v-card class="mt-3" width="100%">
      <v-card-text class="py-0">
        <v-row>
          <v-col cols="12" lg="2" md="3" sm="6">
            <v-btn
              color="primary"
              large
              link
              :to="{
                name: 'alimtalk/templates/detail/send/form',
                params: { idx: templateIdx },
              }"
              block
            >
              알림톡 발송
            </v-btn>
          </v-col>
          <v-col cols="12" lg="2" md="3" sm="6">
            <v-btn
              color="warning"
              large
              link
              :to="{
                name: 'alimtalk/templates/detail/form',
                params: { templateIdx: templateIdx },
              }"
              block
            >
              템플릿 수정
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-row>
      <v-col lg="4" md="4" sm="12" cols="12">
        <TemplateContentForm :template="template" :disabled="true" />
      </v-col>
      <v-col v-if="template != null" lg="4" md="4" sm="6" cols="12">
        <TemplateOption :template="template" :variables="variables" />
      </v-col>
      <v-col v-if="template != null" lg="4" md="4" sm="6" cols="12">
        <TemplatePreview
          class="mb-3"
          :template="template"
          :variables="variables"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import TemplateContentForm from '../../../components/alimtalk/template/form/TemplateContentForm.vue'
import TemplateOption from '../../../components/alimtalk/template/TemplateOption.vue'
import TemplatePreview from '../../../components/alimtalk/template/TemplatePreview.vue'
export default {
  components: { TemplateContentForm, TemplatePreview, TemplateOption },
  props: {
    templateIdx: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      template: null,
      variables: [],
    }
  },
  async created() {
    try {
      this.isLoading = true

      const { data } = await this.$alimtalkApi.getProfileTemplate(
        this.templateIdx
      )
      this.template = data

      const templateStr = JSON.stringify(this.template)
      let variables = templateStr.match(/#{([^{}]+)}/g)

      if (variables == null) {
        return []
      }

      variables = variables.map((variable) => variable.replace(/[#{}]/g, ''))
      this.variables = Array.from(new Set(variables))
    } finally {
      this.isLoading = false
    }
  },
}
</script>
