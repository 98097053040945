import client from '../client'
import qs from 'query-string'

export default {
  getUserRefunds(param) {
    return client.call({
      url: `/user/refunds?${qs.stringify(param, {
        skipNull: true,
        skipEmptyString: true,
      })}`,
      method: HttpMethod.GET,
    })
  },
  deleteUserRefund(userIdx, userVirtualAccountIdx, userRefundIdx) {
    return client.call({
      url: `/user/${userIdx}/virtual/account/${userVirtualAccountIdx}/refund/${userRefundIdx}`,
      method: HttpMethod.DELETE,
    })
  },
  postUserRefundToAccount(userIdx, userVirtualAccountIdx, data) {
    return client.call({
      url: `/user/virtual/account/${userVirtualAccountIdx}/refund`,
      data,
      method: HttpMethod.POST,
    })
  },
}
