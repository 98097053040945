import Vue from 'vue'
import { commaFormat, commaRemove } from '../filter/number'

const numberObj = {
  common: {
    getInputTag(el) {
      if (el.tagName.toLowerCase() === 'input') {
        return el
      } else {
        return el.querySelector('input')
      }
    },
  },
  bind(el, bind, vnode) {
    const input = numberObj.common.getInputTag(el)
    const eventNames = ['input', 'focus', 'blur']
    eventNames.forEach((eventName) => {
      input.addEventListener(eventName, (event) => {
        event.target.value = commaRemove(event.target.value)
        numberObj.inputHandler(input, bind, vnode, event.target.value)
      })
    })
    const modelExp = vnode.data.model.expression
    numberObj.inputHandler(input, bind, vnode, vnode.context[modelExp])
  },
  update(el, bind, vnode) {
    const modelExp = vnode.data.model.expression

    const input = numberObj.common.getInputTag(el)
    const maxValue = parseInt(input.getAttribute('max'), 10)
    let v = vnode.context[modelExp]

    if (!isNaN(maxValue) && maxValue < v) {
      v = maxValue
    }

    setTimeout(() => {
      v = isNaN(v) ? null : commaFormat(v)
      v = v === '0' ? null : v
      input.value = v
    })
  },
  inputHandler(el, bind, vnode, eventValue) {
    const modelExp = vnode.data.model.expression
    if (vnode.context[modelExp] === eventValue) {
      numberObj.update(el, bind, vnode)
      return
    }
    vnode.context[modelExp] = eventValue
  },
}

Vue.directive('input-comma-format', numberObj)
