import Vue from 'vue'
import auth from './auth'
import customer from './customer'
import earlyPayment from './earlyPayment'
import investor from './investor'

const api = {
  auth,
  customer,
  earlyPayment,
  investor,
}

const pluginName = '$myfinbizApi'

Vue.use({
  install(Vue) {
    window[pluginName] = api
    Vue.prototype[pluginName] = api
  }
})

export default api
