import client from '../client'
import qs from 'query-string'

export default {
  getCallback(idx) {
    return client.call({
      url: `welcome/callbacks/${idx}`,
      method: HttpMethod.GET,
    })
  },
  // putCallback(idx) {
  //   // isProcess 강제로 true로 변경
  //   return client.call({
  //     url: `welcome/callbacks/${idx}`,
  //     method: HttpMethod.PUT
  //   })
  // },
  getCallbacks(param) {
    return client.call({
      url: `/welcome/callbacks?${qs.stringify(param, {
        skipNull: true,
      })}`,
      method: HttpMethod.GET,
    })
  },
  postCallbackRollback(callbackIdx, param) {
    return client.call({
      url: `/welcome/callback/${callbackIdx}/rollback`,
      method: HttpMethod.POST,
      data: param,
    })
  },
  getPaymentTransactionByCallbackIdx(idx) {
    return client.call({
      url: `/welcome/callbacks/${idx}/payment/transaction`,
      method: HttpMethod.GET,
    })
  },
}
