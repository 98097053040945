<template>
  <v-container fluid class="pt-0">
    <PageHeader title="은행 점검 내역 조회" subtitle="은행 점검 내역 조회">
      <template v-slot:right>
        <v-col>
          <BankInspectionAddButton />
        </v-col>
      </template>
    </PageHeader>
    <BankInspectionsTable :query="query" :isQueryPaging="true" class="pt-3" />
  </v-container>
</template>
<script>
import BankInspectionsTable from '../../../components/bank/inspection/BankInspectionsTable.vue'
import BankInspectionAddButton from '../../../components/bank/inspection/BankInspectionAddButton.vue'

export default {
  components: { BankInspectionsTable, BankInspectionAddButton },
  props: {
    query: {
      type: Object,
      default: (_) => {
        return null
      },
    },
  },
}
</script>
