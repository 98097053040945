<template>
  <PageSection title="callbackLog 상세" :isLoading="isLoading">
    <template slot="content" v-if="callback != null">
      <v-simple-table>
        <template v-slot:default>
          <tbody>
            <tr>
              <td>index</td>
              <td>{{ callback.idx }}</td>
            </tr>
            <tr>
              <td>tid</td>
              <td>{{ callback.tid }}</td>
            </tr>
            <tr>
              <td>trnsctnState</td>
              <td>{{ callback.trnsctnState }}</td>
            </tr>
            <tr>
              <td>trnsctnType</td>
              <td>{{ callback.trnsctnType }}</td>
            </tr>
            <tr>
              <td>body</td>
              <td>{{ callback.body }}</td>
            </tr>
            <tr>
              <td>jsonMemo</td>
              <td>{{ callback.jsonMemo }}</td>
            </tr>
            <tr>
              <td>isProcess</td>
              <td class="pt-2 pb-3">
                <v-switch
                  class="mt-0"
                  :input-value="callback.isProcess"
                  readonly
                  disabled
                  hide-details
                />
              </td>
            </tr>
            <tr>
              <td>createTime</td>
              <td>{{ callback.createTime | moment('YYYY-MM-DD HH:mm:ss') }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </template>
  </PageSection>
</template>
<script>
export default {
  props: {
    callbackIdx: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      callback: null,
      isLoading: false,
    }
  },
  async created() {
    this.isLoading = true
    try {
      const { data } = await this.$paymentApi.getCallback(this.callbackIdx)
      this.callback = data
      this.$emit('change', this.callback)
    } finally {
      this.isLoading = false
    }
  },
}
</script>
