<template>
  <v-autocomplete
    :items="banks"
    v-model="selectedBankCode"
    placeholder="은행을 선택해주세요"
    no-data-text="검색 결과가 없습니다"
    item-text="name"
    item-value="code"
    hide-details
    filled
    :dense="dense"
    @input="$emit('update:bankCode', $event)"
    :disabled="isLoading"
  ></v-autocomplete>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    bankCode: {
      type: String,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    isAllSelectable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      banks: [],
      selectedBankCode: this.bankCode,
    }
  },
  computed: {
    ...mapGetters('payment/bank', {
      originBanks: 'getBanks',
    }),
  },
  watch: {
    originBanks: {
      immediate: true,
      handler(v) {
        if (this.isAllSelectable === true) {
          this.banks = v.concat([
            {
              code: 'ALL',
              name: '전체',
            },
          ])
        } else {
          this.banks = v
        }
      },
    },
  },
}
</script>
