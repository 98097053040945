var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v(" 템플릿 내용 "+_vm._s(_vm.disabled ? '조회' : '입력')+" ")]),_c('v-card-text',[_c('ValidationObserver',{ref:"validForm"},[_c('ProfileSelect',{staticClass:"mb-3",attrs:{"rules":"required","disabled":_vm.disabled},on:{"change":_vm.setProfile},model:{value:(_vm.profile.idx),callback:function ($$v) {_vm.$set(_vm.profile, "idx", $$v)},expression:"profile.idx"}}),_c('ValidationProvider',{attrs:{"name":"alimtalk_template_code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var touched = ref.touched;
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"my-3",attrs:{"name":"id","type":"text","label":"템플릿 코드","placeholder":("템플릿 코드를 " + _vm.placeholder),"hide-details":touched == false || errors.length == 0,"error-messages":touched == false ? [] : errors,"outlined":"","readonly":!_vm.isInputable,"clearable":!_vm.disabled},on:{"paste":_vm.paste,"input":_vm.input},model:{value:(_vm.form.id),callback:function ($$v) {_vm.$set(_vm.form, "id", $$v)},expression:"form.id"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"alimtalk_template_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var touched = ref.touched;
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"my-3",attrs:{"name":"name","type":"text","label":"템플릿 명","placeholder":("템플릿 명을 " + _vm.placeholder),"hide-details":touched == false || errors.length == 0,"error-messages":touched == false ? [] : errors,"outlined":"","readonly":!_vm.isInputable,"clearable":!_vm.disabled},on:{"paste":_vm.paste,"input":_vm.input},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"alimtalk_template_content","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var touched = ref.touched;
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"name":"content","label":"내용","placeholder":"내용을 붙여넣으세요.(ctrl + v)","outlined":"","readonly":"","clearable":!_vm.disabled,"no-resize":"","auto-grow":"","error-messages":touched == false ? [] : errors},on:{"paste":_vm.paste,"input":_vm.input},model:{value:(_vm.form.content),callback:function ($$v) {_vm.$set(_vm.form, "content", $$v)},expression:"form.content"}})]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }