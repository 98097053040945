<template>
  <v-card :loading="isLoading" ref="card">
    <template v-slot:progress>
      <v-progress-linear absolute indeterminate color="white" />
    </template>
    <v-card-title
      class="py-0 pl-4 pr-2"
      style="background-color: #333333"
      :class="{ rounded: !isShowContent }"
    >
      <span class="subtitle-1 font-weight-bold white--text">{{ title }}</span>
      <v-spacer />

      <!-- 섹션 너비 확장 -->
      <v-tooltip top v-if="expandable">
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" @click="clickExpand">
            <v-icon color="white">
              {{ `mdi-arrow-${isExpanded ? 'collapse' : 'expand'}-horizontal` }}
            </v-icon>
          </v-btn>
        </template>
        <span>
          {{ isExpanded ? '작은 화면으로 전환' : '큰 화면으로 전환' }}
        </span>
      </v-tooltip>

      <v-btn icon @click="isShowContent = !isShowContent">
        <v-icon color="white">{{
          isShowContent ? 'mdi-chevron-up' : 'mdi-chevron-down'
        }}</v-icon>
      </v-btn>
    </v-card-title>
    <v-slide-y-transition hide-on-leave>
      <div v-show="isShowContent">
        <v-card-text v-if="!!$slots.content">
          <slot name="content"></slot>
        </v-card-text>
        <v-card-actions v-if="!!$slots.action">
          <slot name="action"></slot>
        </v-card-actions>
        <v-card-text v-if="!!$slots.empty" class="pa-0">
          <slot name="empty"></slot>
        </v-card-text>
      </div>
    </v-slide-y-transition>
  </v-card>
</template>
<script>
export default {
  props: {
    title: String,
    isShow: Boolean,
    isLoading: Boolean,
    expandable: Boolean,
    isExpanded: Boolean,
  },
  data() {
    return {
      isShowContent: true,
    }
  },
  watch: {
    isShow(v) {
      this.isShowContent = v
    },
  },
  methods: {
    clickExpand() {
      this.$emit('update:isExpanded', !this.isExpanded)

      setTimeout(() => {
        // eslint-disable-next-line no-unused-expressions
        this.$refs.card?.$el?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        })
      }, 200)
    },
  },
}
</script>
