<template>
  <div>
    <v-card class="px-8 pt-8" outlined>
      <template v-if="hideSearch === false">
        <v-row class="mb-3" no-gutters>
          <v-col cols="12" md="12">
            <h2 class="text-center">상품검색</h2>
          </v-col>
        </v-row>

        <v-row class="mb-3" no-gutters>
          <v-col cols="3" sm="3" md="3"></v-col>
          <v-col cols="6" sm="6" md="6">
            <v-dialog
              v-if="showCalendar"
              ref="dialog"
              v-model="modal"
              persistent
              width="320px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="search.registerDate"
                  label="상품생성일"
                  prepend-icon="event"
                  v-bind="attrs"
                  v-on="on"
                  readonly
                  hide-details
                ></v-text-field>
              </template>
              <v-date-picker v-model="search.registerDate" scrollable>
                <v-checkbox
                  v-model="isOverdue"
                  class="ma-0"
                  label="선택일 기준 연체"
                  color="warning"
                  hide-details
                ></v-checkbox>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modal = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="datePick()">OK</v-btn>
              </v-date-picker>
            </v-dialog>

            <v-text-field
              v-else
              v-model="search.keyword"
              :disabled="searchLoading"
              placeholder="검색어를 입력하세요(idx/상품명 등)"
              @keyup.enter="getFundingDatas()"
            >
            </v-text-field>
          </v-col>
          <v-col cols="3" sm="3" md="3">
            <v-btn
              :disabled="search.keyword.length < 1 || searchLoading"
              :loading="searchLoading"
              color="#393939"
              class="white--text"
              height="41"
              depressed
              @click="getFundingDatas()"
            >
              검색
            </v-btn>
          </v-col>
        </v-row>
      </template>

      <v-row class="mb-3" no-gutters>
        <v-col cols="12" sm="12" md="12">
          <v-card flat>
            <v-data-table
              no-data-text="이곳에 상품정보가 표시됩니다."
              no-results-text="이곳에 상품정보가 표시됩니다."
              loading-text="데이터를 가져오는 중입니다..."
              :headers="headers"
              :loading="getDataLoading"
              :items="fundingDatas"
              :items-per-page="5"
              :footer-props="{ 'items-per-page-options': [5, 10, 20] }"
              :server-items-length="paging.totalCount"
              @update:options="changePage"
            >
              <template v-slot:item="{ item }">
                <tr
                  class="clickable-data-row cursor--pointer"
                  :class="selectedFundingData.idx == item.idx ? 'selected' : ''"
                  @click="selectFundingData(item)"
                >
                  <td class="text-center">
                    <a
                      :href="'admin_funding_workspace/' + item.idx"
                      target="_blank"
                    >
                      {{ item.idx }}
                    </a>
                  </td>
                  <td class="text-center">{{ item.title }}</td>
                  <td
                    v-if="!!item.store && !!item.store.user"
                    class="text-center"
                  >
                    {{ item.store.user.name }}
                  </td>
                  <td v-else class="text-center">-</td>
                  <td class="text-center">
                    {{ item.totalAmount | commaFormat }} 원
                  </td>
                  <td class="text-center">{{ item.interestRate }}</td>
                  <td class="text-center">{{ item.status }}</td>
                  <td class="text-center">{{ item.repaymentStatus }}</td>
                  <td class="text-center">{{ item.visible }}</td>
                </tr>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    hideSearch: {
      type: Boolean,
      default: false,
    },
    fundingDataIdx: Number,
    showCalendar: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      headers: [
        { text: '인덱스', value: 'idx', align: 'center' },
        { text: '상품명', value: 'title', align: 'center', sortable: false },
        { text: '차주명', align: 'center', sortable: false },
        { text: '공시금액', value: 'totalAmount', align: 'center' },
        {
          text: '이율',
          value: 'interestRate',
          align: 'center',
          sortable: false,
        },
        { text: '채권상태', value: 'status', align: 'center', sortable: false },
        {
          text: '상환상태',
          value: 'repaymentStatus',
          align: 'center',
          sortable: false,
        },
        {
          text: 'visible',
          value: 'visible',
          align: 'center',
          sortable: false,
        }
      ],
      search: {
        page: 1,
        size: 5,
        keyword: '',
        registerDate: null,
      },
      isOverdue: false,
      searchLoading: false,
      paging: {
        page: 1,
        size: 5,
        totalCount: 0,
      },
      fundingDatas: [],
      getDataLoading: false,
      modal: false,
      selectedFundingData: { idx: null },
    }
  },
  created() {
    if (!!this.fundingDataIdx) {
      this.getFundingDataByIdx()
    }
  },
  methods: {
    changePage(option) {
      this.search.size =
        option.itemsPerPage === -1
          ? this.paging.totalCount
          : option.itemsPerPage
      this.search.page = option.page
      if (this.search.keyword.length > 0) {
        this.getFundingDatas()
      }
    },
    datePick() {
      this.$refs.dialog.save(this.search.registerDate)
      this.getFundingDataByRegisterDate()
    },
    async getFundingDataByIdx() {
      this.getDataLoading = true

      let q = `
        data: getFundingData(fundingDataIdx: ${this.fundingDataIdx}) {
          idx
          storeIdx
          title
          subTitle
          visible
          isBlind
          isEasyLoan
          extApiOpen
          totalAmount
          minInvestAmount
          maxInvestAmount
          unitInvestAmount
          monthlyRepaymentGrace
          monthlyRepayment
          interestRate
          accruedInterestRate
          fundaSupportInterestRate
          commissionRate
          repaymentMethod
          loanPeriod
          gracePeriod
          contractDate
          beginningDate
          interestDate
          initialAccountInterestDate
          monthlyRepaymentDate
          score
          fundaGrade
          defaultRate
          niceUse
          niceGrade
          niceDefaultRate
          kcbUse
          kcbGrade
          kcbDefaultRate
          updateDate
          disclosureDate
          investmentStartDate
          investmentClosureDate
          registerDate
          investmentApplicationDate
          fundingStyle
          isActual
          fundingType
          investmentType
          fundingRepresentativeFeature
          provisionFundIdx
          provisionFundAmount
          additionalInterestFlag
          status
          repaymentStatus
          depositStatus
          updateOverdueInterest
          personInCharge
          purpose1
          purpose2
          purpose3
          additionalInfoJson
          infoFromFundaSocialJson
          rowConcat
          rowChecksum
          memo
          mainBondStyle
          bankruptcyNotice
          bankruptcyNoticeForCreditors
          interestRateGrace
          bcNb
          periodSelectionParentIdx
          topNotice
          teamInCharge
          isAutoRefund
          isPercent
          refundAmount
          
          store {
            idx
            
            user {
              name
            }
          }
        }
      `

      try {
        let data = await this.$fundaApi.query(gql`{${q}}`)

        if (!!data) {
          this.fundingDatas.push(data)
          this.paging.totalCount++
          this.selectFundingData(data)
        } else {
          this.$swal.basic.error('검색된 상품이 없습니다.')
        }
      } finally {
        this.getDataLoading = false
      }
    },
    async getFundingDataByRegisterDate() {
      if (!!!this.search.registerDate) {
        this.$swal.basic.warning('날짜를 선택해주세요.')
        return
      }

      this.getDataLoading = true

      let q = `
        data: getFundingDataByRegisterDate(registerDate: "${this.search.registerDate}", isOverdue: ${this.isOverdue}) {
          idx
          title
          totalAmount
          interestRate
          loanPeriod
          registerDate
          status
          repaymentStatus
        }
      `

      try {
        let data = await this.$fundaApi.query(gql`{${q}}`)

        if (!!data) {
          this.fundingDatas.push(data)
          this.paging.totalCount++
          this.selectFundingData(data)
        } else {
          this.$swal.basic.error('선택일에 생성된 상품이 없습니다.')
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.getDataLoading = false
      }
    },
    async getFundingDatas() {
      this.getDataLoading = true

      let q = `
        resp: getFundingDatasByFilter(filter: {
          page: ${this.search.page}
          size: ${this.search.size}
          keyword: "${this.search.keyword}"
        }) {
          data {
            idx
            storeIdx
            title
            subTitle
            visible
            isBlind
            isEasyLoan
            extApiOpen
            totalAmount
            minInvestAmount
            maxInvestAmount
            unitInvestAmount
            monthlyRepaymentGrace
            monthlyRepayment
            interestRate
            accruedInterestRate
            fundaSupportInterestRate
            commissionRate
            repaymentMethod
            loanPeriod
            gracePeriod
            contractDate
            beginningDate
            interestDate
            initialAccountInterestDate
            monthlyRepaymentDate
            score
            fundaGrade
            defaultRate
            niceUse
            niceGrade
            niceDefaultRate
            kcbUse
            kcbGrade
            kcbDefaultRate
            updateDate
            disclosureDate
            investmentStartDate
            investmentClosureDate
            registerDate
            investmentApplicationDate
            fundingStyle
            isActual
            fundingType
            investmentType
            fundingRepresentativeFeature
            provisionFundIdx
            provisionFundAmount
            additionalInterestFlag
            status
            repaymentStatus
            depositStatus
            updateOverdueInterest
            personInCharge
            purpose1
            purpose2
            purpose3
            additionalInfoJson
            infoFromFundaSocialJson
            rowConcat
            rowChecksum
            memo
            mainBondStyle
            bankruptcyNotice
            bankruptcyNoticeForCreditors
            interestRateGrace
            bcNb
            periodSelectionParentIdx
            topNotice
            teamInCharge
            isAutoRefund
            isPercent
            refundAmount
            
            store {
              idx
              
              user {
                name
              }
            }
          }
            
          paging {
            size
            page
            totalCount
          }
        }
      `

      try {
        let {
          resp: { data, paging },
        } = await this.$fundaApi.query(gql`{${q}}`)

        this.fundingDatas = data
        this.paging = paging
      } finally {
        this.getDataLoading = false
        if (!!this.selectedFundingData.idx) {
          this.selectedFundingData = { idx: null }
        }
      }
    },
    selectFundingData(fundingData) {
      console.log('selectFundingData!')
      this.selectedFundingData = fundingData
      this.$emit('selectFundingData', fundingData)
    },
  },
}
</script>

<style lang="scss" scoped></style>
