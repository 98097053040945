<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12" sm="12" md="12">
        <div class="width-100 mt-1">
          <div class="balance-deposit width-100 font-nanumsquare mb-5">
            <div
              class="
                my-balance-deposit-table__filter
                type-balance-deposit
                d-flex
                mb-2
              "
              v-if="filters != null"
            >
              <div
                v-for="(filter, index) in filters"
                :key="index"
                class="text-center cursor--pointer px-4 py-2"
                :class="[
                  { active: filter.value == selectedFilter },
                  filter.value == selectedFilter
                    ? 'color-default-gray font-weight-black'
                    : 'color--gray-2 font-weight-bold',
                ]"
                @click="changeOption('filter', filter.value)"
              >
                {{ filter.name }}
              </div>
            </div>
            <BalanceDepositTable
              :key="badKey"
              :data="tableData.transactions"
              :headers="headers"
              :filters="filters"
              :is-loading="tableData.isLoading"
            >
              <template slot="col" slot-scope="slotProps">
                <div
                  :style="styles.transactTime.pc"
                  class="
                    justify-center
                    text-center
                    color--gray-2
                    font-weight-bold
                  "
                >
                  {{ slotProps.row.transactTime | date }}
                </div>
                <div
                  :style="styles.category.pc"
                  :class="
                    slotProps.row.amount < 0
                      ? 'color--funda-basic'
                      : 'color--pink'
                  "
                  class="justify-center font-weight-black"
                  style="font-size: 13px"
                >
                  <template v-if="slotProps.row.amount < 0"> 출금 </template>
                  <template v-else> 입금 </template>
                </div>
                <div
                  :style="styles.memo.pc"
                  class="
                    justify-center
                    font-weight-bold
                    my-balance-deposit-table__text
                  "
                >
                  <span>{{ slotProps.row.memo }}</span>
                </div>
                <div
                  :style="styles.amount.pc"
                  :class="
                    slotProps.row.amount < 0
                      ? 'color--funda-basic'
                      : 'color--pink'
                  "
                  class="justify-end font-weight-black font-size--15"
                  style="font-size: 14px"
                >
                  {{ slotProps.row.amount | commaFormat }}원
                </div>
                <div
                  :style="styles.balance.pc"
                  class="justify-end color--gray-2 font-weight-bold"
                >
                  {{ slotProps.row.balance | commaFormat }}원
                </div>
              </template>
            </BalanceDepositTable>
          </div>
          <PageMaker
            v-if="!!badKey"
            :key="badKey"
            :page="tableData.paging.page"
            :size="tableData.paging.size"
            :total-count="tableData.paging.totalCount"
            :raw-data="tableData.transactions"
            :is-white="false"
            :class="{ 'd-none': tableData.isLoading }"
            @currentPage="changeOption('page', $event)"
          />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import BalanceDepositTable from './BalanceDepositTable'
import PageMaker from '../PageMaker'

export default {
  props: {
    userIdx: Number,
  },
  components: {
    BalanceDepositTable,
    PageMaker,
  },
  data() {
    return {
      selectedFilter: null,
      filters: [
        { name: '전체', value: 'ALL' },
        { name: '입금', value: 'IN' },
        { name: '출금', value: 'OUT' },
        { name: '투자', value: 'INVEST' },
        { name: '지급', value: 'PAYOUT' },
      ],
      styles: {
        transactTime: {
          pc: {
            width: '19%',
          },
          mobile: {
            order: 4,
            width: '70%',
            'justify-content': 'end',
            'font-size': '13px',
          },
        },
        category: {
          pc: {
            width: '8%',
          },
          mobile: {
            order: 1,
            width: '10%',
            minWidth: '40px',
            justifyContent: 'end',
          },
        },
        memo: {
          pc: {
            width: '36%',
          },
          mobile: {
            order: 2,
            width: '90%',
            maxWidth: 'calc(100% - 40px)',
            justifyContent: 'flex-end',
            marginBottom: '7px',
          },
        },
        amount: {
          pc: {
            width: '18%',
          },
          mobile: {
            order: 3,
            width: '100%',
            justifyContent: 'flex-end',
          },
        },
        balance: {
          pc: {
            width: '19%',
          },
          mobile: {
            order: 5,
            width: '30%',
            justifyContent: 'flex-end',
          },
        },
      },
      headers: [
        {
          name: '날짜',
          width: '19%',
        },
        {
          name: '구분',
          width: '8%',
        },
        {
          name: '내역',
          width: '36%',
        },
        {
          name: '금액',
          width: '18%',
        },
        {
          name: '잔액',
          width: '19%',
        },
      ],
      tableData: {
        transactions: null,
        paging: null,
        isLoading: false,
      },
    }
  },
  computed: {
    badKey() {
      if (
        this.tableData.transactions === null ||
        this.tableData.transactions.length < 1
      ) {
        return
      }
      return (
        this.tableData.transactions[0].transactTime +
        '-' +
        this.tableData.transactions[0].memo
      )
    },
  },
  created() {
    const query = this.$route.query
    this.getTransactions(query)
  },
  methods: {
    changeOption(type, value) {
      if (this.tableData.isLoading === true) {
        return
      }
      let option = {}
      if (type === 'filter') {
        option.category = value
      } else if (type === 'page') {
        if (value === this.tableData.paging.page) {
          return
        }
        option.page = value
        option.category = this.selectedFilter
      }

      this.getTransactions(option)
    },
    async getTransactions(option) {
      this.tableData.isLoading = true
      const query = option
      this.selectedFilter = query.category ? query.category : 'ALL'
      this.page = query.page ? query.page : 1
      this.size = query.size ? query.size : 20

      let q = `
        transactions: getBalanceDepositTransactions(req: {
          page: ${this.page},
          size: ${this.size},
          userIdx: ${this.userIdx},
          category: "${this.selectedFilter}"
        })
        {
          data {
            amount
            balance
            memo
            transactTime
          }
          paging {
            size
            page
            startNum
            totalCount
            totalPage
          }
        }
      `

      let {
        transactions: { data, paging },
      } = await this.$fundaApi.query(gql`{${q}}`)

      if (data != null) {
        this.tableData.transactions = data
        this.tableData.paging = paging
      }
      this.tableData.isLoading = false
    },
  },
  filters: {
    date(value) {
      let datetime = ''
      if (value) {
        datetime = value
          .replace('T', ' ')
          .replace('Z', '')
          .substring(2, value.length)
          .replace(/ /g, '.')
      }
      return datetime
    },
  },
}
</script>

<style lang="scss" scoped></style>
