<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="fundingDatas"
      item-key="fundingDataIdx"
      :items-per-page="-1"
      :loading="getFundingDatasLoading"
    >
      <template v-slot:top>
        <v-btn block color="primary" @click="getFundings">
          목록 새로고침
        </v-btn>
      </template>
      <template v-slot:[`item.currentInvestmentAmount`]="{ item }">
        {{ item.currentInvestmentAmount | commaFormat }}원 /
        {{ item.announcedAmount | commaFormat }}원
      </template>
      <template v-slot:[`item._actionInputInvestAmount`]="{ item }">
        <v-text-field
          outlined
          dense
          hide-details
          suffix=" 원"
          @input="inputInvestAmount(item, $event)"
          :value="item._actionInputInvestAmount"
        >
          <template v-slot:append-outer>
            <v-btn
              type="button"
              height="40"
              class="px-1 font-size--11"
              small
              elevation="0"
              color="warning"
              @click="inputCloseInvestAmount(item)"
            >
              차액 입력
            </v-btn>
          </template>
        </v-text-field>
      </template>
      <template v-slot:[`item._actionInvest`]="{ item }">
        <InvestButton
          :investAmount="item._actionInputInvestAmount"
          :fundingData="item"
          :investUser="investUser"
          :investFn="invest"
          label="투자"
        />
      </template>
      <template v-slot:[`item._actionCloseInvest`]="{ item }">
        <v-btn-toggle>
          <InvestButton
            :investAmount="Math.floor(item.announcedAmount * 0.3)"
            :fundingData="item"
            :investUser="investUser"
            :investFn="invest"
            label="30%"
            v-if="item.fundanowType == 'EARLY_PAYMENT'"
          />
          <InvestButton
            :investAmount="Math.floor(item.announcedAmount * 0.4)"
            :fundingData="item"
            :investUser="investUser"
            :investFn="invest"
            label="40%"
            v-if="item.fundanowType == 'EARLY_PAYMENT'"
          />
          <InvestButton
            :investAmount="item.announcedAmount - item.currentInvestmentAmount"
            :fundingData="item"
            :investUser="investUser"
            :investFn="invest"
            color="error"
            label="마감"
          />
        </v-btn-toggle>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import InvestButton from './InvestButton.vue'
export default {
  components: { InvestButton },
  computed: {
    ...mapState('marketing/loan', {
      storeFundingDatas: 'fundingDatas',
      investUser: (state) => state.investUser,
      getFundingDatasLoading: (state) => state.getFundingDatasLoading,
    }),
  },
  props: {
    fundingType: {
      type: String,
    },
  },
  data() {
    return {
      fundingDatas: [],
      headers: [
        {
          text: 'index',
          align: 'start',
          sortable: false,
          value: 'fundingDataIdx',
        },
        { text: '제목', value: 'title' },
        { text: '모집/공시 금액', value: 'currentInvestmentAmount' },
        { text: '투자할 금액', value: '_actionInputInvestAmount' },
        { text: '투자', value: '_actionInvest' },
        { text: '%투자', value: '_actionCloseInvest' },
      ],
      totalInvestLoading: false,
    }
  },
  watch: {
    storeFundingDatas(v) {
      this.fundingDatas = v.filter(
        (funding) => funding.fundanowType === this.fundingType
      )
    },
    fundingType(v) {
      this.getFundings()
    },
  },
  async created() {
    this.getFundings()
  },
  methods: {
    ...mapActions('marketing/loan', ['getFundingDatas', 'invest']),
    getFundings() {
      this.getFundingDatas()
    },
    inputInvestAmount(item, e) {
      let value = isNaN(Number(e)) ? 0 : Number(e)
      const maxInvestAmount =
        item.announcedAmount - item.currentInvestmentAmount
      if (maxInvestAmount < value) {
        value = maxInvestAmount
      }
      item._actionInputInvestAmount = value
      this.updateRow(item)
    },
    inputCloseInvestAmount(item) {
      item._actionInputInvestAmount =
        item.announcedAmount - item.currentInvestmentAmount
      this.updateRow(item)
    },
    updateRow(item) {
      this.$set(this.fundingDatas, this.fundingDatas.indexOf(item), item)
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .v-input__append-outer {
  margin: 0 !important;
  > .v-btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
::v-deep .v-input__slot {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
</style>
