<template>
  <div v-if="!!myDashboardData" id="dashboard-left">
    <div class="dashboard-box mx-md-auto px-6 py-6 overflow--hidden">
      <v-row class="pb-2 hr-b-dotted" no-gutters>
        <v-col cols="6" sm="6" md="6">
          <p
            class="mx-0 mb-3 d-flex align-center color--gray font-size--14"
            style="line-height: 1"
          >
            <span class="mr-1">자산 변동액</span>
            <Tooltip text=" = 총자산 - (총 입금 - 총 출금)" size="16px" />
          </p>
          <p
            class="ma-0 font-size--24 font-weight-black"
            :class="[
              {
                'color--black': diffAsset === 0,
                'color--pink': diffAsset > 0,
                'blue--text': diffAsset < 0,
              },
            ]"
            style="white-space: nowrap"
          >
            <LoadingText :is-loading="userInvestmentLoading">
              <span v-if="diffAsset > 0" class="font-size--20">+</span
              >{{ diffAsset | commaFormat }}
            </LoadingText>
          </p>
        </v-col>

        <v-col
          cols="6"
          sm="6"
          md="6"
          class="d-flex justify-end align-center font-size--22 text-right"
          :class="[
            {
              'color--black': diffAsset === 0,
              'color--pink': diffAsset > 0,
              'blue--text': diffAsset < 0,
            },
          ]"
        >
          <EarningsRateText :user-idx="myDashboardData.userIdx" />
        </v-col>
      </v-row>

      <div class="py-5 hr-b-dotted">
        <v-row class="mb-3" no-gutters>
          <v-col cols="4" sm="4" md="4">
            <p class="left-subtitle mx-0 mb-0 color--black font-weight-bold">
              총 입금
            </p>
          </v-col>

          <v-col cols="8" sm="8" md="8" class="text-right">
            <span class="right-subtitle color--black">
              <LoadingText :is-loading="isDepositLoading">
                {{ depositDetails.deposit | commaFormat }}
              </LoadingText>
              원
            </span>
          </v-col>
        </v-row>

        <v-row class="mb-2" no-gutters>
          <v-col cols="4" sm="4" md="4">
            <p class="left-subtitle mx-0 mb-0 color--black font-weight-bold">
              총 출금
            </p>
          </v-col>

          <v-col cols="8" sm="8" md="8" class="text-right">
            <span class="right-subtitle color--black">
              <LoadingText :is-loading="isDepositLoading">
                {{ totalWithdraw | commaFormat }}
              </LoadingText>
              원
            </span>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="4" sm="4" md="4">
            <ul class="left-ul">
              <li>
                <p class="left-key">계좌 출금</p>
              </li>
            </ul>
          </v-col>
          <v-col cols="8" sm="8" md="8" class="text-right">
            <span class="right-value color--gray">
              <LoadingText :is-loading="isDepositLoading">
                {{ depositDetails.withdraw | commaFormat }}
              </LoadingText>
              원
            </span>
          </v-col>
        </v-row>

        <v-row class="mb-3" no-gutters>
          <v-col cols="5" sm="5" md="5">
            <ul class="left-ul">
              <li>
                <p class="left-key">세금</p>
              </li>
            </ul>
          </v-col>
          <v-col cols="7" sm="7" md="7" class="text-right">
            <span class="right-value color--gray">
              <LoadingText :is-loading="isDepositLoading">
                {{ depositDetails.totalTax | commaFormat }}
              </LoadingText>
              원
            </span>
          </v-col>
        </v-row>

        <v-row class="mb-2" no-gutters>
          <v-col cols="4" sm="4" md="4">
            <p class="left-subtitle mx-0 mb-0 color--black font-weight-bold">
              총 자산
            </p>
          </v-col>

          <v-col cols="8" sm="8" md="8" class="text-right">
            <span class="right-subtitle color--black">
              <LoadingText :is-loading="userInvestmentLoading">
                {{ totalAsset | commaFormat }}
              </LoadingText>
              원
            </span>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="4" sm="4" md="4">
            <ul class="left-ul">
              <li>
                <p class="left-key">예치금</p>
              </li>
            </ul>
          </v-col>
          <v-col cols="8" sm="8" md="8" class="text-right">
            <span class="right-value color--gray">
              <BalanceText :user-idx="myDashboardData.userIdx" />
            </span>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="5" sm="5" md="5">
            <ul class="left-ul">
              <li>
                <p class="left-key">투자 중 원금</p>
              </li>
            </ul>
          </v-col>
          <v-col cols="7" sm="7" md="7" class="text-right">
            <span class="right-value color--gray">
              <LoadingText :is-loading="userInvestmentLoading">
                {{ !!userInvestment ? userInvestment.pure : 0 | commaFormat }}
              </LoadingText>
              원
            </span>
          </v-col>
        </v-row>

        <div class="postscript mt-4 color--gray cursor--pointer">
          <v-row no-gutters>
            <v-col cols="12">
              <ul class="postscript__qna">
                <li>
                  <p class="postscript__q">총 입금은 무엇인가요?</p>
                  <p class="postscript__a">
                    예치금 입출금 '입금' 내역의 총 합을 의미합니다.
                  </p>
                </li>
                <template v-if="showPostscript">
                  <li>
                    <p class="postscript__q">총 출금은 무엇인가요?</p>
                    <p class="postscript__a">
                      예치금 입출금 '출금' 내역의 총 합을 의미합니다.
                    </p>
                  </li>
                  <li>
                    <p class="postscript__q">
                      '자산 변동액'과 '누적 수익금'의 차이는?
                    </p>
                    <p class="postscript__a">
                      '자산 변동액'은 <span class="color--pink">*</span>예치금
                      증감에 영향을 주는 모든 요소가 반영되어 있습니다.<span
                        class="color--gray font-size--11"
                        >(*예치금 증감 영향 요소: 이자, 세금, 수수료, 웰컴
                        리워드 등)
                      </span>
                    </p>
                    <p class="postscript__a mt-2">
                      '누적 수익금'의 이자는 세전이므로 이는 '자산 변동액'과
                      상이합니다.
                    </p>
                  </li>
                </template>
              </ul>
            </v-col>
            <v-col cols="12" class="text-center">
              <button
                type="button"
                class="btn__show-detail"
                :class="{ active: showPostscript }"
                @click="showPostscript = !showPostscript"
              >
                {{ showPostscript ? '접기' : '펼치기' }}
              </button>
            </v-col>
          </v-row>
        </div>
      </div>

      <div class="postscript color--gray">
        <InvestmentLimitText
          :myDashboardData="myDashboardData"
          :showDetail="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { InvestmentTypes } from '@/store/www/types/investment'
import EarningsRateText from '@/components/common/text/EarningsRateText.vue'
import LoadingText from '@/components/common/text/LoadingText.vue'
import BalanceText from '@/components/common/text/BalanceText.vue'
import Tooltip from '@/components/common/text/Tooltip.vue'
import InvestmentLimitText from '@/components/funda/myfunda/InvestmentLimitText.vue'

export default {
  props: {
    myDashboardData: {
      type: Object,
      default: null,
    },
  },
  components: {
    EarningsRateText,
    LoadingText,
    BalanceText,
    Tooltip,
    InvestmentLimitText,
  },
  data() {
    return {
      isDepositLoading: false,
      depositDetails: {
        deposit: 0,
        withdraw: 0,
        totalTax: 0,
      },
      showPostscript: true,
    }
  },
  computed: {
    ...mapGetters('www/withdraw', {
      withdrawBalance: 'getUserWithdraw',
    }),
    ...mapGetters('www/investment', {
      userInvestmentLoading:
        InvestmentTypes.getters.GET_USER_INVESTMENT_LOADING,
      userInvestment: InvestmentTypes.getters.GET_USER_INVESTMENT,
    }),
    totalAsset() {
      const bal = this.withdrawBalance ?? 0
      const pure = this.userInvestment?.pure ?? 0
      return bal + pure
    },
    totalWithdraw() {
      return this.depositDetails.withdraw + this.depositDetails.totalTax
    },
    diffAsset() {
      const balance = this.depositDetails.deposit - this.totalWithdraw
      return this.totalAsset - balance
    },
  },
  watch: {
    myDashboardData: {
      immediate: true,
      deep: true,
      handler(v) {
        if (!!v) {
          this.getUserInvestment(v.userIdx)
          this.getMyDeposit()
        }
      },
    },
  },
  methods: {
    ...mapActions('www/investment', {
      getUserInvestment: InvestmentTypes.actions.GET_USER_INVESTMENT,
    }),
    async getUserDeposit() {
      const userIdx = this.myDashboardData.userIdx
      const q = `
        data: getUserDepositContent(userIdx: ${userIdx}) {
          gubun
          amount
        }
      `

      try {
        let data = await this.$fundaApi.query(gql`{${q}}`)

        data.forEach((deposit) => {
          if (deposit?.gubun === '입금') {
            this.depositDetails.deposit = deposit.amount
          } else if (deposit?.gubun === '출금') {
            this.depositDetails.withdraw = Math.abs(deposit.amount)
          }
        })
      } catch (e) {}
    },
    async getUserTotalTax() {
      const userIdx = this.myDashboardData.userIdx
      const q = `data: getUserTotalTax(userIdx: ${userIdx})`

      try {
        let data = await this.$fundaApi.query(gql`{${q}}`)
        this.depositDetails.totalTax = data
      } catch (e) {}
    },
    async getMyDeposit() {
      if (this.isDepositLoading) {
        return
      }

      this.isDepositLoading = true

      const q1 = this.getUserDeposit()
      const q2 = this.getUserTotalTax()
      await q1
      await q2

      this.isDepositLoading = false
    },
  },
}
</script>

<style lang="scss" scoped>
.image__funda-class {
  width: 55px;
}

.left {
  &-subtitle {
    font-size: 16px;
  }

  &-key {
    margin: 0;
    color: #8a94a2;
    font-size: 14px;
    line-height: 1.4;
  }

  &-ul {
    color: #8a94a2;
    font-size: 14px;
    line-height: 1.4;
    padding-left: 20px;

    li > p {
      margin-left: -5px;
    }
  }
}

.right {
  &-subtitle {
    font-size: 16px;
    font-weight: 700;
  }

  &-value {
    font-size: 14px;
    font-weight: 700;
  }
}

.postscript {
  border-radius: 4px;
  background-color: #f6f8fa;

  padding: 15px 10px 10px !important;
  transition: all 0.5s;

  .detail-arrow {
    position: relative !important;
    left: 0;
    margin-top: 0;
  }

  &__qna {
    padding-left: 18px;

    li {
      margin-bottom: 12px;
    }
  }

  &__q {
    color: black;
    font-size: 13px;
    font-weight: bold;
    line-height: 1.5;
    margin-bottom: 0;
    margin-left: -4px;
  }

  &__a {
    color: black;
    font-size: 12px;
    line-height: 1.5;
    margin-bottom: 0;
    margin-left: -4px;
  }
}

.btn__show-detail {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f6f8fa;
  color: #727d8c;
  width: 160px;
  height: 33px;
  border: none;
  border-radius: 20px;
  font-size: 14px;
  font-weight: bold;
  outline: none;
  padding: 15px;
  margin: 0 auto;

  span img {
    margin-left: 5px;
    transition: all 0.5s;
    transform: scale(0.8);
  }

  &.active {
    span img {
      transform: scale(0.8) rotate(180deg);
    }
  }
}
</style>
