import AuthService from '@/service/auth/AuthService'

function isAccountingAdminUser(to, from, next) {
  if (
    AuthService.hasAnyRole(['ROLE_ACCOUNTING_ADMIN', 'ROLE_SUPERADMIN']) ===
    false
  ) {
    alert('권한없음')
    next(false)
  } else {
    next()
  }
}

let routes = [
  {
    name: 'accounting/users/virtual/accounts/transactions',
    path: '/accounting/users/virtual/accounts/:userVirtualAccountIdx/transactions',
    props: (route) => ({
      userVirtualAccountIdx: Number(route.params.userVirtualAccountIdx),
      query: route.query,
    }),
    meta: { title: '가상 계좌 거래내역' },
    component:
      require('@/pages/accounting/user/virtualAccount/UserVirtualAccountTransactions.vue')
        .default,
  },
  {
    name: 'cashReceipt',
    path: '/accounting/cashReceipt',
    meta: { title: '현금영수증' },
    component: require('@/pages/accounting/CashReceipt.vue').default,
  },
  {
    name: 'bankFees',
    path: '/accounting/bank-fees',
    meta: { title: '금융실비 내역' },
    component: require('@/pages/accounting/BankFees.vue').default,
  },
]

routes.forEach((router) => {
  router.beforeEnter = (to, from, next) => {
    isAccountingAdminUser(to, from, next)
  }
})

export default routes
