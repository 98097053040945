var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.isLoading,"server-items-length":_vm.totalCount,"items-per-page":-1,"hide-default-footer":"","show-select":"","single-select":"","item-key":"idx"},on:{"item-selected":function($event){return _vm.select($event.item, $event.value)}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('p',{staticClass:"py-5 mb-0 text-center subtitle-1 grey--text"},[_vm._v(" 아직 등록된 가상 계좌가 없습니다. ")])]},proxy:true},{key:"item.idx",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{
        name: 'payment/users/virtual/accounts/transactions',
        params: {
          userIdx: item.userIdx,
          userVirtualAccountIdx: item.idx,
        },
      }}},[_vm._v(" "+_vm._s(item.idx)+" ")])]}},{key:"item.bankCode",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("bankCodeName")(item.bankCode))+" ")]}},{key:"item.type",fn:function(ref){
      var type = ref.item.type;
return [(type)?[_vm._v(" "+_vm._s(_vm._f("payment.code")(type,'UserVirtualAccount.Type'))+" ")]:[_vm._v(" - ")]]}},{key:"item.balance",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("commaFormat")(item.balance))+" ")]}},{key:"item.isClosed",fn:function(ref){
      var item = ref.item;
return [_c('v-switch',{staticClass:"mt-0",attrs:{"input-value":item.isClosed,"readonly":"","disabled":"","hide-details":""}})]}},{key:"item.isDepositStopped",fn:function(ref){
      var item = ref.item;
return [_c('v-switch',{staticClass:"mt-0",attrs:{"input-value":item.isDepositStopped,"readonly":"","disabled":"","hide-details":""}})]}},{key:"item.isAllDepositable",fn:function(ref){
      var item = ref.item;
return [_c('v-switch',{staticClass:"mt-0",attrs:{"input-value":!item.isAllDepositable,"readonly":"","disabled":"","hide-details":""}})]}},{key:"item.createTime",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.createTime,'YYYY-MM-DD HH:mm:ss'))+" ")]}},{key:"item.guid",fn:function(ref){
      var item = ref.item;
return [(item.targetIdx)?_c('WelcomeGuid',{attrs:{"userIdx":item.userIdx,"userVirtualAccountIdx":item.idx}}):[_vm._v("-")]]}}],null,true),model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}})}
var staticRenderFns = []

export { render, staticRenderFns }