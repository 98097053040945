<template>
  <div>
    <div
      v-if="boards === null || fetchLoading"
      class="dashboard-box--pa-0 margin-y-5 d-flex justify-center align-center"
      style="height: 528px"
    >
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <div v-else class="dashboard-box--pa-0 margin-t-5">
      <InvestCountBoard v-if="!!myDashboardData" :userIdx="userIdx" />

      <div class="invest-details mt-4 px-6 py-6">
        <div v-for="key in boardKey" :key="key">
          <v-row class="invest-details__row" no-gutters>
            <v-col md="3" lg="2">
              <p
                class="
                  invest-details__sub-title
                  ma-0
                  font-weight-bold
                  font-size--15
                  color--black
                "
              >
                {{ boards[key]['title'] }}
              </p>
            </v-col>

            <v-col md="5" lg="5">
              <v-row class="margin-b-5" no-gutters>
                <v-col cols="4" sm="5" md="4" lg="5">
                  <p class="invest-details__key ma-0 color--gray-2">원금</p>
                </v-col>
                <v-col cols="8" sm="7" md="8" lg="7" class="text-right">
                  <p class="invest-details__val ma-0 color--black">
                    <template v-if="key === 'done'">
                      <span class="font-weight-bold color--black">
                        {{ boards[key]['principal'] | commaFormat }}
                      </span>
                    </template>
                    <template v-else>
                      <span class="font-weight-bold color--black">
                        <LoadingText :is-loading="userBalanceLoading">
                          {{ balance[key] | commaFormat }}
                        </LoadingText>
                      </span>
                    </template>
                    원
                  </p>
                </v-col>
              </v-row>

              <v-row class="margin-b-5" no-gutters>
                <v-col cols="4" sm="4" md="4" lg="4">
                  <p class="invest-details__key ma-0 color--gray-2">
                    이자
                    <span class="font-size--11" style="margin-left: -3px">
                      (세전)
                    </span>
                  </p>
                </v-col>
                <v-col cols="8" sm="8" md="8" class="text-right">
                  <p class="invest-details__val ma-0 color--black">
                    <span class="font-weight-bold color--black">
                      {{ boards[key]['interest'] | commaFormat }}
                    </span>
                    원
                  </p>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="4" sm="4" md="4" lg="4">
                  <p class="invest-details__key ma-0 color--gray-2">
                    연 수익률
                  </p>
                </v-col>
                <v-col cols="8" sm="8" md="8" lg="8" class="text-right">
                  <p class="invest-details__val ma-0 color--black">
                    <span class="font-weight-bold color--black">
                      {{ boards[key]['earningsRate'] }}
                    </span>
                    %
                  </p>
                </v-col>
              </v-row>
            </v-col>

            <v-col md="4" lg="5">
              <v-row class="margin-b-5" no-gutters>
                <v-col cols="4" sm="4" md="4" lg="4">
                  <p class="invest-details__key ma-0 color--gray-2">세금</p>
                </v-col>
                <v-col cols="8" sm="8" md="8" class="text-right">
                  <p class="invest-details__val ma-0 color--black">
                    <span class="font-weight-bold color--black">
                      {{ boards[key]['tax'] | commaFormat }}
                    </span>
                    원
                  </p>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="4" sm="4" md="4" lg="4">
                  <p class="invest-details__key ma-0 color--gray-2">
                    플랫폼 이용료
                  </p>
                </v-col>
                <v-col cols="8" sm="8" md="8" class="text-right">
                  <p class="invest-details__val ma-0 color--black">
                    <span class="font-weight-bold color--black">
                      {{ boards[key]['fee'] | commaFormat }}
                    </span>
                    원
                  </p>
                </v-col>
              </v-row>

              <v-row
                v-if="isRuby === true && key === 'expect'"
                class="margin-t-10"
                no-gutters
              >
                <v-col cols="12" sm="12" md="12" lg="12">
                  <p
                    class="ma-0 color--gray-2 font-size--12"
                    style="line-height: 1.5"
                  ></p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <hr class="hr-solid" />
        </div>
        <keep-alive>
          <OverdueAndInsolvent :userIdx="userIdx" />
        </keep-alive>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { InvestmentTypes } from '@/store/www/types/investment'
import InvestCountBoard from './invest/InvestCountBoard.vue'
import OverdueAndInsolvent from './invest/OverdueAndInsolvent.vue'
import LoadingText from '@/components/common/text/LoadingText.vue'

export default {
  props: {
    userIdx: {
      required: true,
    },
    myDashboardData: {
      type: Object,
      default: null,
    },
  },
  components: {
    InvestCountBoard,
    OverdueAndInsolvent,
    LoadingText,
  },
  data: function () {
    return {
      boardKey: ['total', 'done', 'expect'],
      isRuby: false,
    }
  },
  computed: {
    ...mapGetters('www/investment', {
      userBalanceLoading: InvestmentTypes.getters.GET_USER_INVESTMENT_LOADING,
      userBalance: InvestmentTypes.getters.GET_USER_INVESTMENT,
      userEarningLoading: InvestmentTypes.getters.GET_USER_EARNING_LOADING,
      userEarning: InvestmentTypes.getters.GET_USER_EARNING,
    }),
    fetchLoading() {
      // eslint-disable-next-line
      return this.userEarningLoading || !this.myDashboardData
    },
    boards() {
      if (this.fetchLoading === true) {
        return null
      }

      const data = {
        done: {
          title: '상환완료',
          principal: this.userEarning.donePrincipal,
          interest: this.userEarning.doneInterest,
          earningsRate: this.userEarning.earningsRate,
          tax: this.userEarning.doneTax,
          fee: this.userEarning.doneFee,
        },
        expect: {
          title: '상환예정',
          principal: 0,
          interest: this.userEarning.scheduleInterest,
          earningsRate: 0,
          tax: this.userEarning.scheduleTax,
          fee: this.userEarning.scheduleFee,
        },
        overdue: {
          title: '연체현황',
        },
      }

      data.total = {
        title: '총 투자',
        principal: 0,
        interest: data.done.interest + data.expect.interest,
        earningsRate: data.done.earningsRate,
        tax: data.done.tax + data.expect.tax,
        fee: data.done.fee + data.expect.fee,
      }

      let tempBoards = {}
      this.boardKey.forEach((v) => {
        tempBoards[v] = data[v]
      })
      return tempBoards
    },
    balance() {
      const pure = this.userBalance?.pure ?? 0
      const donePrincipal = this.boards?.done.principal ?? 0
      return {
        expect: pure,
        total: pure + donePrincipal,
      }
    },
  },
  async created() {
    await this.getUserEarning(this.userIdx)
  },
  methods: {
    ...mapActions('www/investment', {
      getUserEarning: InvestmentTypes.actions.GET_USER_EARNING,
    }),
  },
}
</script>

<style lang="scss" scoped>
#invest-board {
  .invest-summary-count {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}

.invest-details {
  @media screen and (max-width: 767px) {
    margin: 0;
  }
  @media screen and (min-width: 992px) and (max-width: 1200px) {
    &__sub-title {
      margin-bottom: 5px !important;
    }
  }
  &__mobile-menu {
    button {
      font-size: 16px;
      background-color: transparent;

      &.active {
        background-color: #f2f9ff;
        border-radius: 22px;
        color: #1baaf1;
        font-weight: bold;
      }
    }
  }

  &__row {
    .invest-details {
      &__key,
      &__val {
        line-height: 1.3;
      }
    }
    @media screen and (min-width: 1200px) {
      > div:nth-child(2) {
        padding-right: 40px;
      }
      > div:nth-child(3) {
        padding-left: 40px;
      }
    }
    &.mobile {
      .invest-details {
        &__key {
          font-size: 15px;
          white-space: nowrap;
        }

        &__val {
          font-size: 17px;
        }
      }
    }

    &:not(.mobile) {
      .invest-details {
        &__key {
          font-size: 14px;
          white-space: nowrap;
        }

        &__val {
          font-size: 16px;
        }
      }
    }
  }
}
</style>
