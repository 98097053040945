import qs from 'query-string'
import client from '../client'

/**
 * encode 두번되어 들어오는 현상때문에(proxy api에서 url을 body로 받아서?)
 * query-string stringify 할 때 encode false 로 설정;
 */
export default {
  /**
   * 고객 목록 조회
   * @param {*} param
   * @returns
   */
  getUsers(param) {
    return client.call({
      url: `/users?${qs.stringify(param, { encode: false })}`,
      method: 'GET',
    })
  },
  /**
   * 고객 조회
   * @param {*} userId
   * @returns
   */
  getUser(userId) {
    return client.call({
      url: `/users/${userId}`,
      method: 'GET',
    })
  },
  /**
   * 고객 상점 조회
   * @param {*} userId
   * @returns
   */
  getUserStore(userId) {
    return client.call({
      url: `/users/${userId}/store`,
      method: 'GET',
    })
  },
  /**
   * 고객 출금계좌 조회
   * @param {*} userId
   * @returns
   */
  getUserWithdrawalaccount(userId) {
    return client.call({
      url: `/users/${userId}/withdrawalaccount`,
      method: 'GET',
    })
  },
  /**
   * 고객 집금계좌 조회
   * @param {*} userId
   * @returns
   */
  getUserCollectionaccount(userId) {
    return client.call({
      url: `/users/${userId}/collectionaccount`,
      method: 'GET',
    })
  },
  /**
   * 고객 페이먼트유저 조회
   * @param {*} userId
   * @returns
   */
  getUserFundaPaygateUser(userId) {
    return client.call({
      url: `/users/${userId}/funda/paygate/user`,
      method: 'GET',
    })
  },
  /**
   * 고객 본인인증 조회
   * @param {*} userId
   * @returns
   */
  getUserKyc(userId) {
    return client.call({
      url: `/users/${userId}/kyc`,
      method: 'GET',
    })
  },
  /**
   * 고객 본인인증 수정
   * @param {*} userId
   * @param {*} kycId
   * @param {*} body
   * @returns
   */
  patchUserKyc(userId, kycId, body) {
    return client.call({
      url: `/users/${userId}/kyc/${kycId}`,
      method: 'PUT',
      body,
    })
  },
  /**
   * 고객 상환용 가상계좌 조회
   * @param {*} userId
   * @returns
   */
  getUsersFundaPaygateRepaymentvirtualaccount(userId) {
    return client.call({
      url: `/users/${userId}/funda/paygate/repaymentvirtualaccount`,
      method: 'GET',
    })
  },
}
