<template>
  <v-data-table
    :headers="dataTable.headers"
    :items="dataTable.items"
    :options.sync="dataTable.options"
    :loading="dataTable.isLoading"
    class="elevation-2 my-3"
    hide-default-footer
    :items-per-page="-1"
  >
    <template v-slot:[`item.id`]="{ item }">
      {{ item.id }}
    </template>
    <template v-slot:[`item.payInterest`]="{ item }">
      <input
        label="상환이자"
        @input="inpuInterest(item, $event)"
        :value="item.payInterest"
      />
    </template>
    <template v-slot:[`item.payOverdueInterest`]="{ item }">
      <input
        label="상환연체이자"
        @input="inputOverdueInterest(item, $event)"
        :value="item.payOverdueInterest"
      />
    </template>
    <template v-slot:[`item.payFee`]="{ item }">
      <input
        label="상환수수료"
        @input="inputFee(item, $event)"
        :value="item.payFee"
      />
    </template>
    <template v-slot:[`item.payPrincipal`]="{ item }">
      <input
        label="상환원금"
        @input="inputPrincipal(item, $event)"
        :value="item.payPrincipal"
      />
    </template>
    <template v-slot:[`item.payLegalFee`]="{ item }">
      <input
        label="상환법무비"
        @input="inputLegalFee(item, $event)"
        :value="item.payLegalFee"
      />
    </template>
    <template v-slot:[`item.totalRepayment`]="{ item: { totalRepayment } }">
      {{ totalRepayment | commaFormat }} 원
    </template>
    <template v-slot:[`item.totalUnpaidRepayment`]="{ item: { totalUnpaidRepayment } }">
      {{ totalUnpaidRepayment | commaFormat }} 원
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: {
    plans: {
      type: Array,
      default: () => {
        return []
      }
    },
    transactions: {
      type: Array,
      default: () => {
        return []
      }
    },
  },
  data() {
    return {
      dataTable: {
        isLoading: false,
        options: null,
        headers: [
          { text: '대출index', value: 'loanId', sortable: false },
          // 이자
          { text: '이자율', value: 'interestRate', sortable: false },
          { text: '사용일수', value: 'usedDays', sortable: false },
          { text: '미수이자', value: 'unpaidInterest', sortable: false },
          { text: '발생이자', value: 'interest', sortable: false },
          { text: '상환이자', value: 'payInterest', sortable: false },
          { text: '상환 후 미수이자', value: 'payAfterUnpaidInterest', sortable: false },
          // 연체
          { text: '연체율', value: 'overdueInterestRate', sortable: false },
          { text: '연체사용일수', value: 'overdueDays', sortable: false },
          { text: '미수연체이자', value: 'unpaidOverdueInterest', sortable: false },
          { text: '연체이자', value: 'overdueInterest', sortable: false },
          { text: '상환 연체이자', value: 'payOverdueInterest', sortable: false },
          { text: '상환 후 미수연체이자', value: 'payAfterUnpaidOverdueInterest', sortable: false },
          // 수수료
          { text: '수수료율', value: 'feeRate', sortable: false },
          { text: '미수 수수료', value: 'unpaidFee', sortable: false },
          { text: '발생 수수료', value: 'fee', sortable: false },
          { text: '상환 수수료', value: 'payFee', sortable: false },
          { text: '상환 후 미수 수수료', value: 'payAfterUnpaidFee', sortable: false },
          // 법무비
          { text: '미수법무비', value: 'unpaidLegalFee', sortable: false },
          { text: '상환 미수법무비', value: 'payLegalFee', sortable: false },
          { text: '상환 후 미수법무비', value: 'payAfterUnpaidLegalFee', sortable: false },
          // 원금
          { text: '상환 전 잔여원금', value: 'orgRemainPrincipal', sortable: false },
          { text: '상환원금', value: 'payPrincipal', sortable: false },
          { text: '상환 후 잔여원금', value: 'remainPrincipal', sortable: false },
          // 합계
          { text: '상환 총액', value: 'totalRepayment', sortable: false },
          // 미수총합
          { text: '발생 미수 총액', value: 'totalUnpaidRepayment', sortable: false },
        ],
        items: [],
      },
    }
  },
  created() {
    this.dataTable.items = this.plans.map(plan => {
      let transaction = {
        loanId: plan.loan.id,
        feeRate: plan.feeRate,
        interestRate: plan.interestRate,
        overdueInterestRate: plan.overdueInterestRate,
        usedDays: plan.useDaysInfo.useDays,
        overdueDays: plan.useDaysInfo.overdueUseDays,
        fee: (plan.remainCommission < 0 ? 0 : plan.remainCommission) + plan.fee,
        interest: plan.interest,
        overdueInterest: plan.overdueInterest,
        orgRemainPrincipal: plan.remainPrincipal,
        // 미수금 설정
        unpaidInterest: plan.unpaidInterest,
        unpaidOverdueInterest: plan.unpaidOverdueInterest,
        unpaidFee: plan.unpaidFee,
        unpaidLegalFee: plan.unpaidLegalFee,
        // 상환후 미수금 설정
        payAfterUnpaidInterest: plan.unpaidInterest + plan.interest,
        payAfterUnpaidOverdueInterest: plan.unpaidOverdueInterest + plan.overdueInterest,
        payAfterUnpaidFee: plan.unpaidFee + plan.fee,
        payAfterUnpaidLegalFee: plan.unpaidLegalFee,
      }
      transaction.payInterest = 0
      transaction.payOverdueInterest = 0
      transaction.payFee = 0
      transaction.payLegalFee = 0
      transaction.payPrincipal = 0
      transaction.totalRepayment = 0

      // 상환 합산금
      // eslint-disable-next-line no-unused-vars
      let payableAmount = plan.repaySource

      // 상환 순서 : 법무비 > 수수료 > 연체이자 > 이자 > 원금
      // 법무비 상환
      transaction.payLegalFee = this.subtract(payableAmount, plan.unpaidLegalFee)
      payableAmount -= transaction.payLegalFee
      transaction.payAfterUnpaidLegalFee = transaction.unpaidLegalFee - transaction.payLegalFee

      // 수수료 상환
      // 수수료는 미수관리가 필요 없다 ( 항상 계산이 받을 금액 - 받은금액 으로 전달된다 )
      transaction.payFee = this.subtract(payableAmount, plan.unpaidFee + plan.fee)
      payableAmount -= this.subtract(payableAmount, plan.unpaidFee + plan.fee)
      transaction.payAfterUnpaidFee = 0 // transaction.unpaidFee + plan.fee - transaction.payFee

      // 연체이자 상환
      transaction.payOverdueInterest = this.subtract(payableAmount, plan.unpaidOverdueInterest + plan.overdueInterest)
      payableAmount -= this.subtract(payableAmount, plan.unpaidOverdueInterest + plan.overdueInterest)
      transaction.payAfterUnpaidOverdueInterest = plan.unpaidOverdueInterest + plan.overdueInterest - transaction.payOverdueInterest

      // 이자 상환
      transaction.payInterest = this.subtract(payableAmount, plan.unpaidInterest + plan.interest)
      payableAmount -= this.subtract(payableAmount, plan.unpaidInterest + plan.interest)
      transaction.payAfterUnpaidInterest = plan.unpaidInterest + plan.interest - transaction.payInterest

      // 원금 상환
      transaction.payPrincipal = this.subtract(payableAmount, plan.remainPrincipal)
      payableAmount -= this.subtract(payableAmount, plan.remainPrincipal)
      transaction.remainPrincipal = plan.remainPrincipal - transaction.payPrincipal

      // 총 상환금액 계산
      transaction.totalRepayment = plan.repaySource - payableAmount

      // 합계 계산
      const totalUnpaidRepayment = transaction.payAfterUnpaidInterest + transaction.payAfterUnpaidOverdueInterest + transaction.payAfterUnpaidFee + transaction.payAfterUnpaidLegalFee
      transaction.totalUnpaidRepayment = totalUnpaidRepayment

      // 상환처리 후 잔여원금 계산
      transaction.remainPrincipal = transaction.orgRemainPrincipal - transaction.payPrincipal

      return transaction
    })
    this.setTotalRepaymentAmount()
  },
  methods: {
    subtract(mainAmount, subAmount) {
      if (subAmount <= 0) {
        return 0
      }
      if (mainAmount > subAmount) {
        let result = mainAmount - subAmount
        if (result < 0) {
          result = 0
        }
        return subAmount
      } else {
        return mainAmount
      }
    },
    // 상환이자 입력
    inpuInterest(item, e) {
      const value = isNaN(Number(e.target.value)) ? 0 : Number(e.target.value)
      const payableAmount = item.unpaidInterest + item.interest
      item.payInterest = (payableAmount > value) ? value : item.unpaidInterest + item.interest
      item.payAfterUnpaidInterest = payableAmount - item.payInterest

      this.setData(item)
    },
    // 상환연체 이자 입력
    inputOverdueInterest(item, e) {
      const value = isNaN(Number(e.target.value)) ? 0 : Number(e.target.value)
      const payableAmount = item.unpaidOverdueInterest + item.overdueInterest
      item.payOverdueInterest = (payableAmount > value) ? value : item.unpaidOverdueInterest + item.overdueInterest
      item.payAfterUnpaidOverdueInterest = payableAmount - item.payOverdueInterest
      this.setData(item)
    },
    // 상환 수수료 입력
    inputFee(item, e) {
      const value = isNaN(Number(e.target.value)) ? 0 : Number(e.target.value)
      const payableAmount = item.unpaidFee + item.fee
      item.payFee = (payableAmount > value) ? value : item.unpaidFee + item.fee
      item.payAfterUnpaidFee = payableAmount - item.payFee
      this.setData(item)
    },
    // 상환 법무비 입력
    inputLegalFee(item, e) {
      const value = isNaN(Number(e.target.value)) ? 0 : Number(e.target.value)
      const payableAmount = item.unpaidLegalFee
      item.payLegalFee = (payableAmount > value) ? value : item.unpaidLegalFee
      item.payAfterUnpaidLegalFee = payableAmount - item.payLegalFee
      this.setData(item)
    },
    // 상환 원금 입력
    inputPrincipal(item, e) {
      const value = isNaN(Number(e.target.value)) ? 0 : Number(e.target.value)

      const payableAmount = item.orgRemainPrincipal
      item.payPrincipal = (payableAmount > value) ? value : item.orgRemainPrincipal
      item.remainPrincipal = payableAmount - item.payPrincipal
      this.setData(item)
    },
    // 재 계산...
    setData(item) {
      // 총 상환금액 계산
      item.totalRepayment = item.payFee + item.payInterest + item.payOverdueInterest + item.payPrincipal + item.payLegalFee
      // 총 발생 미수금 계산
      item.totalUnpaidRepayment = item.payAfterUnpaidInterest + item.payAfterUnpaidOverdueInterest + item.payAfterUnpaidFee + item.payAfterUnpaidLegalFee
      this.$set(
        this.dataTable.items,
        this.dataTable.items.indexOf(item),
        item
      )

      this.setTotalRepaymentAmount()
    },
    setTotalRepaymentAmount() {
      let total = 0
      let totalUnpaid = 0
      let totalFee = 0
      let totalTargetFee = 0
      this.dataTable.items.forEach(item => {
        total += item.totalRepayment
        totalFee += item.payFee
        totalTargetFee += item.fee
        totalUnpaid += item.payAfterUnpaidInterest + item.payAfterUnpaidOverdueInterest + item.payAfterUnpaidFee + item.payAfterUnpaidLegalFee
      })
      this.$emit('update:totalTargetFee', totalTargetFee)
      this.$emit('update:totalPaidFee', totalFee)
      this.$emit('update:totalRepaymentAmount', total)
      this.$emit('update:transactions', this.dataTable.items)
      this.$emit('update:totalUnpaidRepayment', totalUnpaid)
    },
  }
}
</script>
<style lang="scss" scoped>
input {
  height: 40px;
  width: 80px;
  text-align: right;
  background-color: #9ecbe9;
  border: 1px solid #aaa;
}
</style>
