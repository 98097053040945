<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on: on1, attrs: attrs1 }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            class="mr-3"
            v-bind="{ ...attrs, ...attrs1 }"
            v-on="{ ...on, ...on1 }"
            :disabled="isTransferProgress"
          >
            <v-icon>mdi-microsoft-excel</v-icon>
          </v-btn>
        </template>
        <span>파일 이체</span>
      </v-tooltip>
    </template>

    <v-card>
      <v-card-title class="py-1 headline pr-2">
        파일 이체
        <v-spacer></v-spacer>
        <v-btn
          color="black"
          text
          icon
          x-large
          :disabled="isLoading"
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pb-0">
        <p class="subtitle mb-0">양식 다운로드 / 업로드 옵션</p>
        <v-checkbox
          v-model="isSingleFromAccount"
          hide-details
          class="mt-0 mb-2"
          label="보내는 계좌 지정(한 곳에서 보내는 경우 선택)"
        >
        </v-checkbox>
        <VirtualAccountAutocomplete
          v-if="isSingleFromAccount"
          dense
          :propsAccount="singleFromAccount"
          :propsIsLoading="isLoading"
          @select="singleFromAccount = $event"
          label="보내는 계좌"
        />
        <v-checkbox
          v-model="isSingleToAccount"
          hide-details
          class="my-2"
          label="받는 계좌 지정(한 곳에서 받는 경우 선택)"
        >
        </v-checkbox>
        <VirtualAccountAutocomplete
          v-if="isSingleToAccount"
          dense
          :propsAccount="singleToAccount"
          :propsIsLoading="isLoading"
          @select="singleToAccount = $event"
          label="받는 계좌"
        />
        <v-checkbox
          v-model="isSingleRemark"
          hide-details
          class="my-2"
          label="이체 메모 지정(이체 메모 통일하는 경우 선택)"
        >
        </v-checkbox>
        <v-text-field
          v-if="isSingleRemark"
          v-model="singleRemark"
          label="이체 메모(remark)"
          dense
          outlined
        />
      </v-card-text>
      <v-card-actions>
        <ExcelTemplateDownloadButton
          v-if="templateHeaders != null"
          :headers="templateHeaders"
          text
          label="현재 옵션으로 양식 다운로드"
        />
        <v-spacer />
        <ExcelTemplateUploadButton
          v-if="templateHeaders != null"
          text
          label="현재 옵션으로 파일 업로드"
          :headers="templateHeaders"
          @success="onUploadSuccess"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import ExcelTemplateUploadButton from '../../../../../../components/common/datatable/ExcelTemplateUploadButton.vue'
import ExcelTemplateDownloadButton from '../../../../../../components/common/datatable/ExcelTemplateDownloadButton.vue'
import VirtualAccountAutocomplete from '../../../VirtualAccountAutocomplete.vue'
export default {
  components: {
    VirtualAccountAutocomplete,
    ExcelTemplateUploadButton,
    ExcelTemplateDownloadButton,
  },
  props: {
    isTransferProgress: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      isLoading: false,
      isSingleFromAccount: false,
      singleFromAccount: null,
      isSingleToAccount: false,
      singleToAccount: null,
      isSingleRemark: false,
      singleRemark: null,
      templateHeaders: null,
      localIndex: 0,
    }
  },
  created() {
    this.setTemplateHeaders()
  },
  watch: {
    isSingleFromAccount(v) {
      if (v !== true) {
        this.singleFromAccount = null
      }
      this.setTemplateHeaders()
    },
    isSingleToAccount(v) {
      if (v !== true) {
        this.singleToAccount = null
      }
      this.setTemplateHeaders()
    },
    isSingleRemark(v) {
      if (v !== true) {
        this.singleRemark = null
      }
      this.setTemplateHeaders()
    },
  },
  methods: {
    setTemplateHeaders() {
      let headers = [{ text: '금액', value: 'amount' }]

      if (this.isSingleFromAccount !== true) {
        headers.push({ text: '보내는 유저', value: 'fromUserIdx' })
        headers.push({
          text: '보내는 계좌',
          value: 'fromUserVirtualAccountIdx',
        })
      }

      if (this.isSingleToAccount !== true) {
        headers.push({ text: '받는 유저', value: 'toUserIdx' })
        headers.push({ text: '받는 계좌', value: 'toUserVirtualAccountIdx' })
      }

      if (this.isSingleRemark !== true) {
        headers.push({ text: '메모', value: 'remark' })
      }

      this.templateHeaders = headers
    },
    async onUploadSuccess(rows) {
      let items = []
      let loadingAlert
      try {
        loadingAlert = this.$swal.basic.loading({
          title: '계좌 조회중',
        })
        for (let i = 0; i < rows.length; i++) {
          const row = rows[i]

          let item = {}
          item.localIndex = ++this.localIndex
          // item.status = '-'

          // 보내는 계좌 설정
          if (this.isSingleFromAccount !== true) {
            try {
              item.fromUserVirtualAccount = await this.getAccount(
                row.fromUserIdx,
                row.fromUserVirtualAccountIdx
              )
            } catch (e) {
              this.$swal.basic.error({
                text: e,
              })
              return
            }
          } else {
            item.fromUserVirtualAccount = this.singleFromAccount
          }
          item.fromUserIdx = item.fromUserVirtualAccount.userIdx
          item.fromUserVirtualAccountIdx = item.fromUserVirtualAccount.idx

          // 받는 계좌 설정
          if (this.isSingleToAccount !== true) {
            try {
              item.toUserVirtualAccount = await this.getAccount(
                row.toUserIdx,
                row.toUserVirtualAccountIdx
              )
            } catch (e) {
              this.$swal.basic.error({
                text: e,
              })
              return
            }
          } else {
            item.toUserVirtualAccount = this.singleToAccount
          }
          item.toUserIdx = item.toUserVirtualAccount.userIdx
          item.toUserVirtualAccountIdx = item.toUserVirtualAccount.idx

          // remark 설정
          if (this.isSingleRemark !== true) {
            item.remark = row.remark
          } else {
            item.remark = this.singleRemark
          }

          // 금액 설정
          item.amount = row.amount

          items.push(item)
        }

        this.$emit('success', items)
        this.dialog = false
      } finally {
        loadingAlert.close()
      }
    },
    async getAccount(userIdx, userVirtualAccountIdx) {
      let result = null
      try {
        const { data } = await this.$paymentApi.getUserVirtualAccount(
          userVirtualAccountIdx
        )
        result = data
      } catch (e) {
        const msg = e.data.message || e.data.errors[0].message
        throw new Error(
          `userIdx: ${userIdx}/userVirtualAccountIdx: ${userVirtualAccountIdx} 계좌 조회 에러: ${msg}`
        )
      }

      if (result.isClosed === true) {
        throw new Error(
          `userIdx: ${userIdx}/userVirtualAccountIdx: ${userVirtualAccountIdx} 계좌는 해지된 계좌입니다.`
        )
      }
      return result
    },
  },
}
</script>
