<template>
  <PageSection title="본인인증 정보" :isLoading="isLoading">
    <template slot="content" v-if="kyc != null">
      <v-simple-table>
        <template v-slot:default>
          <tbody>
            <template v-if="kyc">
              <tr>
                <td>index</td>
                <td>{{ kyc.id }}</td>
              </tr>
              <tr>
                <td>휴대폰 본인인증 여부</td>
                <td>
                  <KycVerificationSwitch
                    label="휴대폰 본인인증 여부"
                    fieldName="mobileVerificationIsDone"
                    :kycId="kyc.id"
                    :userId="userId"
                    :isDone="kyc.mobileVerificationIsDone"
                    @update:kyc="kyc=$event"
                  />
                </td>
              </tr>
              <tr>
                <td>신분증 진위 확인 여부</td>
                <td>
                  <KycVerificationSwitch
                    label="신분증 진위 확인 여부"
                    fieldName="idCardVerificationIsDone"
                    :kycId="kyc.id"
                    :userId="userId"
                    :isDone="kyc.idCardVerificationIsDone"
                    @update:kyc="kyc=$event"
                  />
                </td>
              </tr>
              <tr>
                <td>계좌인증 여부</td>
                <td>
                  <KycVerificationSwitch
                    label="계좌인증 여부"
                    fieldName="bankVerificationIsDone"
                    :kycId="kyc.id"
                    :userId="userId"
                    :isDone="kyc.bankVerificationIsDone"
                    @update:kyc="kyc=$event"
                  />
                </td>
              </tr>
              <tr>
                <td>수정일</td>
                <td>{{ kyc.modified | moment('YYYY-MM-DD HH:mm:ss') }}</td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td class="py-5 text-center subtitle-1 grey--text">
                  아직 본인 인증 정보가 없습니다.
                </td>
              </tr>
            </template>
          </tbody>
        </template>
      </v-simple-table>
    </template>
  </PageSection>
</template>
<script>
import KycVerificationSwitch from './KycVerificationSwitch.vue'
export default {
  components: { KycVerificationSwitch },
  props: {
    userId: {
      type: Number,
      required: true
    },
  },
  data() {
    return {
      kyc: null,
      isLoading: false,
    }
  },
  async created() {
    try {
      this.isLoading = true

      const { data } = await this.$fundaNowApi.getUserKyc(this.userId)
      this.kyc = data
    } finally {
      this.isLoading = false
    }
  },
}
</script>
<style lang="scss" scoped>
tr > td:last-child {
  word-break: break-all;
}
</style>
