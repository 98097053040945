/**
 * 순서대로 인터셉터 스텝이 실행된다.
 * 순서 잘 지키길
 *  */
const arr = [
  require('./signinRedirect'),
  require('./auth'),
  require('./popState'),
]

export default arr
