<template>
  <div :style="block ? 'width: 100%;' : ''">
    <v-btn
      large
      :loading="isLoading || isSelecting"
      color="primary"
      :disabled="disabled || isLoading"
      @click="clickUploadButton"
      :block="block"
      :text="text"
    >
      <template v-slot:default>
        {{ label }}
        <v-icon right dark> mdi-cloud-upload</v-icon>
      </template>
      <template v-slot:loader>
        <template v-if="loadingPercent && loadingPercent != 100">
          업로드 중
          <v-progress-linear
            absolute
            bottom
            :buffer-value="100 - loadingPercent"
            :value="loadingPercent"
            stream
            color="primary"
          />
        </template>
        <template v-else>
          <v-progress-circular indeterminate size="20" />
        </template>
      </template>
    </v-btn>
    <input
      ref="uploader"
      class="d-none"
      type="file"
      @change="uploadFile($event.target.files)"
    />
  </div>
</template>
<script>
export default {
  props: {
    singleFile: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loadingPercent: {
      type: Number,
      default: null,
    },
    block: {
      type: Boolean,
      default: false,
    },
    text: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '파일 업로드',
    },
  },
  data() {
    return {
      isSelecting: false,
    }
  },
  methods: {
    clickUploadButton() {
      this.isSelecting = true
      window.addEventListener(
        'focus',
        () => {
          this.isSelecting = false
        },
        {
          once: true,
        }
      )

      this.$refs.uploader.click()
    },
    uploadFile(files) {
      this.$emit('update:files', this.singleFile === true ? files[0] : files)
      this.$refs.uploader.value = null
    },
  },
}
</script>
