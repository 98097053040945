<template>
  <v-container fluid class="pt-0">
    <PageHeader
      title="수동 상환 대상 입금 목록"
      subtitle="입금 내역"
      color="error"
      tabMenu
    >
    </PageHeader>
    <TargetsTable />
  </v-container>
</template>
<script>
import TargetsTable from '@/components/fundanow/repayment/manual/TargetsTable.vue'
export default { components: { TargetsTable } }
</script>
