<template>
  <div v-if="hasVote === true">
    <v-btn
      class="font-size--16 mt-4"
      color="primary"
      @click="showPopup = true"
      tile
    >
      매각 투표하기
    </v-btn>

    <v-dialog v-model="showPopup" max-width="1024" width="95%">
      <v-card>
        <v-card-title class="justify-end px-4">
          <a @click.stop="showPopup = false" class="cursor--pointer">
            <img src="@/assets/images/myfunda/btn-close.svg" />
          </a>
        </v-card-title>
        <v-card-text class="px-2">
          <v-row no-gutters class="mb-5">
            <v-col cols="12">
              <p class="font-size--22 font-weight-bold mb-1">매각 안내사항</p>
            </v-col>
            <v-col cols="12">
              <p
                class="mb-1 font-size--14 font-weight-bold"
                style="line-height: 1.4"
              >
                * 투표 기한 내에 소중한 의사 표명을 꼭 부탁드립니다.
                <br />
                의사를 표명하시지 않는 경우에는 찬성 의사로 간주되어 채권 매각
                진행에 반영됩니다.
              </p>
              <v-btn
                class="mt-1 mb-2 btn-funda--black font-size--16"
                @click="showNotice = !showNotice"
              >
                {{ showNotice ? '접기' : '안내문 보기' }}
              </v-btn>

              <p
                v-show="showNotice"
                class="mb-0 font-size--14 pb-3"
                style="border-bottom: 1px solid #eee; line-height: 1.4"
              >
                ※ 당사는 연체 90일이 경과한 채권 중, 1)개인회생 2)폐업으로 인한
                상환 불가능 등 인한 채권 회수율이 지속 낮아질 것으로 판단 되는
                일부 채권을 대상으로 채권 매각 동의 절차를 거쳐 투자자가
                동의하는 경우 채권 매각을 진행하고자 합니다.
                <br /><br />
                ※2020년 9월 이후 실행된 펀다나우 66,355개의 채권 중 905건의
                채권매각을 진행한 바 있으며, 금번 매각 대상 채권은 212개의
                채권을 대상으로 진행됩니다.
                <br /><br />※ 매각 대상 채권의 경우, 연체 발생 채권 모두가
                포함되는 내용은 아니며 회수가 가능할 것으로 판단되는 채권의 경우
                회수가능 채권으로 별도 분류하여 관리됩니다.<br /><br />
                &lt;투표 내용>
                <br /><br />
                ■ 채권 매입가액
                <br />
                - 채권 매각은 각 채권별 잔액의 16% 금액으로 매입가를
                협의하였습니다. (신용회복 채권의 경우 55%의 매입가율 협의)
                <br /><br />
                ■ 채권 매각 절차
                <br />
                1) 채권 매각 동의 설문
                <br />
                2) 채권별 매각에 동의한 투자자의 총 투자금이 매각 대상 채권
                원금의 2/3 이상인 경우 채권 매각 계약 체결 <br />
                3) 1개월 이내 매각 대금 투자자 분배
                <br /><br />
                ■ 채권 매각 투표 일정
                <br />
                2022년 12월 5일(월) ~ 12월 11일(일) 23시 59분
                <br /><br />
                *위의 투표 기한 내에 소중한 의사 표명을 꼭 부탁드립니다. 의사를
                표명하시지 않는 경우에는 찬성 의사로 간주되어 채권 매각 진행에
                반영됩니다.
                <br /><br />
                ■ 투표 대상자 매각 대상 채권의 원리금수취권 보유
                <br />
                투자자 (*매각 대상 채권을 보유한 투자자에게는 개별 문자 안내가
                함께 진행됩니다.)
                <br /><br />
                ■ 투표 결과에 따른 추후 절차 안내
                <br />
                - 각 채권별 매각 동의 시 : 채권매각 계약 체결 및 1개월 이내 매각
                대금 분배
                <br />
                - 채권 매각에 미동의 시 : 자체 추심
                <br /><br />
                감사합니다.
              </p>
            </v-col>
          </v-row>
          <v-row no-gutters class="mb-5">
            <v-col cols="12">
              <p class="font-size--22 font-weight-bold mb-1">투표 내역</p>
            </v-col>

            <v-col class="table-col" cols="12">
              <div class="data-table-wrapper">
                <table class="width-100 vote-list">
                  <thead>
                    <tr>
                      <th width="5%">번호</th>
                      <th style="min-width: 80px">상태</th>
                      <th style="min-width: 140px">투표명</th>
                      <th style="min-width: 120px">시작일시</th>
                      <th style="min-width: 120px">종료일시</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr
                      v-for="(vote, index) in voteList"
                      :key="vote.name"
                      @click="selectedVote = vote"
                    >
                      <td>{{ index + 1 }}</td>
                      <td :class="vote.isEnd ? 'red--text' : 'blue--text'">
                        {{ vote.isEnd ? '종료' : '진행중' }}
                      </td>
                      <td>{{ vote.name }}</td>
                      <td>{{ vote.startTime | datetimeFormat }}</td>
                      <td>{{ vote.endTime | datetimeFormat }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </v-col>
          </v-row>

          <v-row no-gutters class="pt-5">
            <v-col
              v-if="selectedVote === null"
              cols="12"
              class="my-vote-area non-selected"
            >
              <p class="font-size--17 font-weight-bold">투표를 선택해주세요</p>
            </v-col>
            <v-col v-else class="my-vote-area" cols="12">
              <v-row no-gutters v-if="isGetLoading">
                <v-col cols="12" class="text-center">
                  <MyLoader />
                </v-col>
              </v-row>
              <template v-else>
                <v-row no-gutters class="pt-5">
                  <v-col cols="12">
                    <p class="font-size--22 font-weight-bold mb-1">나의 투표</p>

                    <p class="font-size--15 my-3">
                      **평가비율: 신용회복건의 경우 평가율이 상이할 수 있습니다.
                    </p>
                  </v-col>
                </v-row>

                <v-row no-gutters>
                  <v-col class="table-col" cols="12">
                    <div class="data-table-wrapper">
                      <table class="width-100">
                        <thead>
                          <tr>
                            <th width="5%">번호</th>
                            <th style="min-width: 140px">상점명</th>
                            <th style="min-width: 100px" width="10%">
                              **평가비율
                            </th>
                            <th style="min-width: 70px" width="15%">
                              매각 찬성
                            </th>
                            <th style="min-width: 70px" width="15%">
                              매각 반대
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          <template v-for="(store, index) in voteStores">
                            <tr :key="store.sNum" class="tr__store">
                              <td>{{ index + 1 }}</td>
                              <td>
                                {{ store.storeName }}
                              </td>
                              <td></td>
                              <td>
                                <v-btn
                                  v-if="store.values.length > 1"
                                  class="btn-funda--black"
                                >
                                  일괄 찬성
                                </v-btn>
                              </td>
                              <td>
                                <v-btn
                                  v-if="store.values.length > 1"
                                  class="btn-funda--black"
                                >
                                  일괄 반대
                                </v-btn>
                              </td>
                            </tr>

                            <tr
                              v-for="display in store.values"
                              :key="display.fnStoreListCreditorIdx"
                            >
                              <td></td>
                              <td>
                                <a
                                  style="line-height: 1.4"
                                  @click="toLink(display)"
                                >
                                  {{ `${display.fNum}호` }}
                                  <br />
                                  {{ display.storeName }}
                                </a>
                              </td>
                              <td>{{ display.valueRatio | rateFormat }} %</td>
                              <td>
                                <input
                                  type="checkbox"
                                  class="outline-color--funda-blue"
                                  :class="{ checked: display.result === 1 }"
                                  @click="display.result = 1"
                                  :disabled="!canSubmit"
                                />
                              </td>
                              <td>
                                <input
                                  type="checkbox"
                                  class="outline-color--funda-blue"
                                  :class="{ checked: display.result === 0 }"
                                  @click="display.result = 0"
                                  :disabled="!canSubmit"
                                />
                              </td>
                            </tr>
                          </template>
                        </tbody>
                      </table>
                    </div>
                  </v-col>

                  <v-col cols="12" class="text-center pt-5">
                    <p v-if="canSubmit" class="font-size--17 red--text">
                      투표를 아직 진행하지 않은 유저입니다.
                    </p>

                    <p v-else class="font-size--17 red--text">
                      투표가 종료되었거나 이미 투표를 진행하였습니다.
                    </p>
                  </v-col>
                </v-row>
              </template>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import MyLoader from '@/components/funda/MyLoader.vue'

export default {
  props: {
    userIdx: {
      type: Number,
      default: null,
      required: true,
    },
  },
  components: {
    MyLoader,
  },
  data() {
    return {
      showPopup: false,
      showNotice: false,
      isGetLoading: false,
      isSubmitLoading: false,
      voteList: [],
      selectedVote: null,
      isDone: false,
      voteDisplays: [],
      voteStores: [],
    }
  },
  computed: {
    hasVote() {
      return this.voteList.length > 0
    },
    checkedResults() {
      const result = []
      this.voteStores.forEach((store) => {
        store.values.forEach((v) => {
          if (v.result !== null) {
            result.push(v)
          }
        })
      })
      return result
    },
    isAllChecked() {
      return this.checkedResults.length === this.voteDisplays.length
    },
    canSubmit() {
      return this.selectedVote?.isEnd === false && this.isDone === false
    },
  },
  watch: {
    selectedVote(v) {
      if (v) {
        this.getVoteDisplays()
      }
    },
    voteStores(v) {
      if (!!v) {
        this.$emit('update:vote', v)
      }
    },
  },
  created() {
    this.getVoteList()
  },
  methods: {
    toLink(display) {
      const r = this.$router.resolve({
        name: 'myfunda-fundanow-detail',
        params: { fNum: display.fNum },
      })

      window.open(r.href, '_blank')
    },
    async getVoteList() {
      if (this.isGetLoading) {
        return
      }

      const q = `
        data: getWriteOffVoteListByUserIdx(userIdx: ${this.userIdx}) {
          idx
          name
          description
          startTime
          endTime

          isEnd
        }
      `

      this.isGetLoading = true
      try {
        const data = await this.$fundaApi.query(gql`{${q}}`)
        this.voteList = data
      } finally {
        this.isGetLoading = false
      }
    },
    async getVoteDisplays() {
      if (this.isGetLoading || this.selectedVote === null) {
        return
      }

      const q = `
        data: getWriteOffVoteDisplay(userIdx: ${this.userIdx}, voteIdx: ${this.selectedVote.idx}) {
          sNum
          storeName
          writeOffVoteFundIdx
          valueRatio
          fNum
          userIdx
          fnStoreListCreditorIdx
          investmentAmount
          investmentTime
          result
          voteTime
        }
      `

      this.isGetLoading = true
      try {
        const data = await this.$fundaApi.query(gql`{${q}}`)
        this.isDone = data.every((d) => d.result !== null)
        this.voteDisplays = data
      } finally {
        this.isGetLoading = false
      }

      const storeObj = {}
      this.voteDisplays.forEach((disp) => {
        const sNum = disp.sNum
        const temp = storeObj[sNum] ?? {
          sNum,
          storeName: disp.storeName,
          values: [],
        }
        temp.values.push(disp)
        storeObj[sNum] = temp
      })

      const stores = []
      for (const key in storeObj) {
        const store = storeObj[key]
        stores.push(store)
      }
      this.voteStores = stores
    },
  },
}
</script>

<style lang="scss" scoped>
.table-col {
  position: relative;

  @media screen and (max-width: 425px) {
    &::before {
      content: '';
      position: absolute;
      top: 24px;
      width: 15px;
      height: 15px;
      right: 10px;
      background: url('~@/assets/images/myfunda/btn-btn-arrow-b.svg') no-repeat
        center;
      z-index: 5;
    }
  }
}

.data-table-wrapper {
  position: relative;
  overflow: auto;

  table {
    border-collapse: collapse;
    border-top: 1px solid #333333;
    border-bottom: 1px solid #8a94a2;

    thead {
      background-color: #f9fcff;

      th {
        font-size: 17px;
        font-weight: bold;
        text-align: center;

        margin: 0;
        padding: 20px 10px !important;
      }
    }

    tbody {
      tr {
        font-size: 16px;
        text-align: center;

        &.active {
          background-color: #f5f5f5;
        }

        &.tr__store {
          border-top: 1px solid gray;
          border-bottom: 1px solid #eee;
        }
      }

      td {
        color: #565a5c;
        text-align: center;

        padding: 12px 10px !important;
      }
    }
  }
}

.vote-list {
  tbody {
    tr {
      td {
        cursor: pointer;
      }
    }
  }
}

.my-vote-area {
  &.non-selected {
    display: flex;
    align-items: center;
    justify-content: center;

    min-height: 300px;
  }
}

input[type='checkbox'] {
  width: 22px !important;
  height: 22px;
  margin: 0;
  padding: 0 !important;
  border: 1px solid rgb(38, 139, 239, 0.3);
  border-radius: 100%;
  background: url('~@/assets/images/common/icons/etc-safe-checkbox.svg')
    transparent no-repeat;
  background-size: contain;
  cursor: pointer;
  opacity: 1 !important;
  visibility: visible !important;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}
input[type='checkbox'].checked {
  background: url('~@/assets/images/common/icons/etc-checkbox-selected.svg')
    transparent no-repeat;
  background-size: contain;
}
</style>
