<template>
  <div>
    <v-data-table
      :headers="dataTable.headers"
      :items="dataTable.items"
      :options.sync="dataTable.options"
      :loading="dataTable.isLoading"
      :server-items-length="dataTable.totalCount"
      :footer-props="dataTable.footer"
      class="elevation-2"
    >
      <template v-slot:[`item.idx`]="{ item: { idx } }">
        <router-link
          :to="{
            name: 'bank/inspections/detail',
            params: { idx },
          }"
        >
          {{ idx }}
        </router-link>
      </template>
      <template v-slot:[`item.bankCode`]="{ item: { bankCode } }">
        {{ bankCode | bankCodeName }}
      </template>
      <template v-slot:[`item.startTime`]="{ item: { startTime } }">
        {{ startTime | moment('yyyy-MM-DD HH:mm:ss') }}
      </template>
      <template v-slot:[`item.endTime`]="{ item: { endTime } }">
        {{ endTime | moment('yyyy-MM-DD HH:mm:ss') }}
      </template>
      <template v-slot:[`item.isActive`]="{ item: { isActive } }">
        <span :class="{ 'primary--text': isActive }">
          {{ isActive == true ? '활성화' : '비활성화' }}
        </span>
      </template>
    </v-data-table>
  </div>
</template>
<script>
export default {
  props: {
    isQueryPaging: {
      type: Boolean,
      default: false,
    },
    query: {
      type: Object,
    },
  },
  data() {
    return {
      param: {
        page:
          this.isQueryPaging && this.query.page ? Number(this.query.page) : 1,
        size: this.query.size ? Number(this.query.size) : 20,
      },
      dataTable: {
        isLoading: false,
        options: null,
        headers: [
          { text: 'index', value: 'idx', sortable: false },
          { text: '점검은행', value: 'bankCode', sortable: false },
          { text: '점검시작', value: 'startTime', sortable: false },
          { text: '점검종료', value: 'endTime', sortable: false },
          { text: '제목', value: 'title', sortable: false },
          { text: '활성화 여부', value: 'isActive', sortable: false },
        ],
        items: [],
        totalCount: 0,
        footer: {
          'items-per-page-options': [1, 5, 10, 20, 50, 100],
        },
      },
    }
  },
  watch: {
    'dataTable.options': {
      handler: function (options, oldValue) {
        if (!oldValue) {
          return
        }
        const optionObj = {
          page: options.page,
          size: options.itemsPerPage,
        }
        this.updateOption(optionObj)
      },
      deep: true,
    },
  },
  created() {
    this.dataTable.options = {
      page: this.param.page,
      itemsPerPage: this.param.size,
    }
    this.getPageData(this.param)
  },
  methods: {
    updateOption(option) {
      if (this.isQueryPaging === true) {
        return this.$router.push({
          query: option,
        })
      } else {
        this.getPageData(option)
      }
    },
    async getPageData(option) {
      if (this.dataTable.isLoading === true) {
        return
      }
      try {
        this.dataTable.isLoading = true
        const {
          data: {
            paging: { totalCount },
            data,
          },
        } = await this.$paymentApi.getBankInspections(option)
        this.dataTable.items = data
        this.dataTable.totalCount = totalCount
      } finally {
        this.dataTable.isLoading = false
      }
    },
    searchParam() {
      this.dataTable.options = Object.assign(
        this.dataTable.options,
        this.param,
        {
          page: 1,
        }
      )
    },
  },
}
</script>
