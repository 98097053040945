import api from '../../plugins/api/payment'
import store from '@/store'

const st = {
  name: 'payment/system',
  namespaced: true,
  state: {
    virtualAccounts: [],
  },
  getters: {
    getMerchant(state) {
      if (state.virtualAccounts.length === 0) {
        store.dispatch(`${st.name}/getVirtualAccounts`)
      }
      return state.virtualAccounts.find((account) => account.label === '머천트')
    },
    getVirtualAccounts(state) {
      if (state.virtualAccounts.length === 0) {
        store.dispatch(`${st.name}/getVirtualAccounts`)
      }
      return state.virtualAccounts
    },
  },
  mutations: {
    setVirtualAccounts(state, virtualAccounts) {
      state.virtualAccounts = virtualAccounts
    },
  },
  actions: {
    async getVirtualAccounts({ commit }) {
      const { data } = await api.getSystemUserVirtualAccounts()
      commit('setVirtualAccounts', data)
      return data
    },
  },
}

export default st
