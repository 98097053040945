var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PageSection',{attrs:{"title":"상환 내역","isLoading":_vm.isLoading}},[_c('template',{slot:"content"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.repayments,"items-per-page":10},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.created,'YYYY-MM-DD HH:mm:ss'))+" ")]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.calculateAmount(item).toLocaleString())+" ")]}},{key:"item.principal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.principal.toLocaleString())+" ")]}},{key:"item.interest",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.interest.toLocaleString())+" ")]}},{key:"item.fee",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.fee.toLocaleString())+" ")]}},{key:"item.overdueInterest",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.overdueInterest.toLocaleString())+" ")]}}],null,true)})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }