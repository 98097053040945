<template>
  <v-container fluid class="pt-0">
    <PageHeader title="예약 이체 목록 조회" subtitle="예약 이체 목록 조회">
    </PageHeader>
    <ReservationsTable :query="query" :isQueryPaging="true" />
  </v-container>
</template>
<script>
import ReservationsTable from '../../../../../components/payment/user/virtualAccount/transfer/ReservationsTable.vue'
export default {
  components: { ReservationsTable },
  props: {
    query: {
      type: Object,
      default: (_) => {
        return null
      },
    },
  },
}
</script>
