<template>
  <div>
    <v-row no-gutters>
      <v-dialog v-model="dialog" max-width="1300px" width="90%">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :color="$colors['f-dark-grey']"
            dark
            depressed
            x-large
            block
            v-bind="attrs"
            v-on="on"
          >
            유저 인증 내역
          </v-btn>
        </template>

        <v-card>
          <v-card-title>
            <v-row no-gutters>
              <v-col cols="6">
                <span class="headline"> 유저 인증 내역 </span>
              </v-col>
              <v-col cols="6" class="d-flex justify-end">
                <div class="width-100" style="max-width: 200px">
                  <ModifyUserNameButton
                    :key="userIdx + '_이름변경'"
                    :userIdx="parseInt(userIdx)"
                  />
                </div>

                <div class="width-100 ml-3" style="max-width: 200px">
                  <ModifyUserPhoneButton
                    :key="userIdx + '_번호변경'"
                    :userIdx="parseInt(userIdx)"
                  />
                </div>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <keep-alive>
              <UserValidationAlarm
                :key="userIdx + '_인증'"
                :userIdx="parseInt(userIdx)"
              />
            </keep-alive>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="dialog = false">
              닫기
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import ModifyUserNameButton from './ModifyUserNameButton.vue'
import ModifyUserPhoneButton from './ModifyUserPhoneButton.vue'
import UserValidationAlarm from './UserValidationAlarm.vue'
export default {
  props: {
    userIdx: {
      required: true,
      default: null,
    },
  },
  components: {
    ModifyUserNameButton,
    ModifyUserPhoneButton,
    UserValidationAlarm,
  },
  data() {
    return {
      dialog: false,
      userDetails: null,
    }
  },
}
</script>

<style lang="scss" scoped></style>
