<template>
  <div class="pa-3">
    <v-row no-gutters>
      <v-col cols="12">
        <v-row no-gutters>
          <v-col cols="12" sm="12" md="8" class="pr-2">
            <InvestmentStat :selectDate.sync="searchDate" />
          </v-col>

          <v-col cols="12" sm="12" md="4">
            <InvestorsByDate :searchDate="searchDate" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import InvestmentStat from './InvestmentStat.vue'
import InvestorsByDate from './InvestorsByDate.vue'

export default {
  components: {
    InvestmentStat,
    InvestorsByDate,
  },
  data() {
    return {
      searchDate: null,
    }
  },
}
</script>
