var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.title != null)?_c('p',{staticClass:"title mb-2 font-weight-bold"},[_vm._v(_vm._s(_vm.title))]):_vm._e(),_c('SystemUserSelect',{staticClass:"mb-3",attrs:{"userVirtualAccountIdx":_vm.vAccount ? _vm.vAccount.idx : null},on:{"change":_vm.changeAccount}}),_c('VirtualAccountAutocomplete',{attrs:{"propsAccount":_vm.vAccount},on:{"select":_vm.changeAccount}}),_c('v-slide-y-transition',[(_vm.vAccount != null)?_c('div',{staticClass:"mt-3"},[_c('v-card',{staticStyle:{"border":"1px solid #00000061"},attrs:{"elevation":"0"}},[_c('v-card-title',[(!!_vm.vAccount.label)?[_vm._v(_vm._s(_vm.vAccount.label))]:[_vm._v(_vm._s(_vm.vAccount.accountName))],_c('span',{staticClass:"mx-1"},[_vm._v("(")]),_c('router-link',{attrs:{"target":"_blank","to":{
              name: 'payment/users/detail',
              params: {
                userIdx: _vm.vAccount.userIdx,
              },
            }}},[_vm._v(" "+_vm._s(_vm.vAccount.userIdx)+" ")]),_c('span',{staticClass:"mx-1"},[_vm._v("/")]),_c('router-link',{attrs:{"target":"_blank","to":{
              name: 'payment/users/virtual/accounts/transactions',
              params: {
                userIdx: _vm.vAccount.userIdx,
                userVirtualAccountIdx: _vm.vAccount.idx,
              },
            }}},[_vm._v(" "+_vm._s(_vm.vAccount.idx)+" ")]),_c('span',{staticClass:"mx-1"},[_vm._v(")")])],2),_c('v-card-text',[_c('v-list',{staticClass:"py-0"},[_c('v-list-item',{staticClass:"px-0"},[_c('v-list-item-content',{staticClass:"py-0"},[_c('v-list-item-subtitle',{staticClass:"d-flex align-center flex-wrap",class:_vm.isFullLetter(_vm.vAccount.accountName.trim()) ? 'ml-n2' : ''},[_vm._v(" "+_vm._s(_vm.vAccount.accountName.trim())+" "),_c('v-chip',{staticClass:"ml-1 px-2 font-weight-bold",attrs:{"x-small":"","color":_vm.$colors['f-blue-grey-lighten-4'],"text-color":_vm.$colors['f-blue-grey-darken-1']}},[_vm._v(" "+_vm._s(_vm._f("payment.code")(_vm.vAccount.type,'UserVirtualAccount.Type'))+" ")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.vAccount.accountNo))])],1)],1),_c('v-list-item',{staticClass:"px-0"},[_c('v-list-item-content',{staticClass:"py-0"},[_c('v-list-item-subtitle',[_vm._v("잔액")]),_c('v-list-item-title',[_vm._v(_vm._s(_vm._f("commaFormat")(_vm.vAccount.balance))+" 원")])],1)],1)],1)],1)],1)],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }