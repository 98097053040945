import Vue from 'vue'
import store from '@/store'

// window.onerror = function (msg, src, linenum, colnum, error) {
//   console.log(msg, src, linenum, colnum, error)
// }

Vue.config.errorHandler = (err, vm, info) => {
  if (err.name === 'NavigationDuplicated') {
    store.dispatch('time/refresh')
    return
  } else {
    store.dispatch('errors/add', {
      title: '정의되지 않은 에러 발생',
      message: err,
    })
  }
  console.error(err)
}
