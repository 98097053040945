<template>
  <v-container fluid class="pt-0">
    <PageHeader title="대출 목록" subtitle="대출 목록" tabMenu>
    </PageHeader>
    <LoansTable
      :isQueryPaging="true"
      :query="query"
      @error="handleError"
    />
  </v-container>
</template>

<script>
import LoansTable from '@/components/fundanow/loan/LoansTable.vue'
import PageHeader from '@/components/common/page/PageHeader.vue'

export default {
  components: { LoansTable, PageHeader },
  props: {
    query: {
      type: Object,
      default() {
        return {
          loanTypes: ['normal'],
          statuses: ['investing', 'invested', 'executing', 'executed'],
          page: 1,
          size: 10
        }
      },
    },
  },
  methods: {
    handleError(error) {
      console.error('API Error:', error)
      // 에러 처리 로직 추가
    }
  }
}
</script>
