<template>
  <div class="px-3">
    <PageHeader title="상환내역" />
    <v-row class="my-3" no-gutters>
      <v-col cols="3">
        <v-text-field
          v-model="dateRangeText"
          label="기간"
          class="date-range"
          width="80px"
          prepend-icon="mdi-calendar"
          readonly
        ></v-text-field>
        <v-date-picker v-model="fromToDate" range></v-date-picker>
      </v-col>
      <v-col cols="9">
        <v-row no-gutters>
          <v-col cols="3">
            <v-switch
              v-model="search.byDate"
              label="일자별"
              hint="ON시 일자별, OFF시 상품별"
              persistent-hint
              dense
              class="ma-2"
            ></v-switch>
          </v-col>

          <v-col cols="3">
            <v-switch
              v-model="search.includeWriteOff"
              label="채권매각 포함"
              hide-details
              dense
              class="ma-2"
            ></v-switch>
          </v-col>

          <v-col cols="6"> </v-col>

          <v-col cols="12" class="mt-3">
            <UserList
              :user-idx="search.userIdx"
              :get-first="false"
              @selectUser="(v) => (selectedUser = v)"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col cols="12">
        <h3 class="text-center mb-5">
          [ {{ !!selectedUser ? selectedUser.id : '' }} ] 상환 내역
        </h3>
      </v-col>
      <v-col cols="12">
        <TuiGrid
          :columns="gridProps.columns"
          :data="gridProps.data"
          :summary="gridProps.summary"
        ></TuiGrid>
      </v-col>
    </v-row>

    <v-dialog v-model="isLoading" persistent width="500">
      <v-card color="primary" dark>
        <v-card-text class="pt-3 text-center">
          <p class="mt-5 mb-6" style="font-size: 17px">
            상환내역을 가져오고 있습니다.
          </p>
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import UserList from '@/components/funda/user/List'
import TuiGrid from '@/components/toast/Grid'

export default {
  props: {
    userIdx: {
      type: String,
      default: null,
    },
  },
  components: {
    UserList,
    TuiGrid,
  },
  data() {
    return {
      search: {
        userIdx: parseInt(this.userIdx),
        byDate: false,
        includeWriteOff: true,
      },
      fromToDate: [],
      selectedUser: null,
      isLoading: false,
      gridProps: {
        columns: [
          {
            header: '상품호수',
            name: 'fundingDataIdx',
            align: 'center',
            maxWidth: 80,
            sortable: true,
          },
          {
            header: '상품명',
            name: 'title',
            align: 'center',
          },
          {
            header: '상품상태',
            name: 'fStatus',
            align: 'center',
            filter: 'select',
            sortable: true,
          },
          {
            header: '투자금액',
            name: 'investmentAmount',
            align: 'center',
            formatter({ value }) {
              return Intl.NumberFormat().format(value)
            },
            sortable: true,
          },
          {
            header: '투자일',
            name: 'investmentDateTime',
            align: 'center',
            formatter({ value }) {
              if (!!!value) {
                return ''
              }
              let d = value.split(' ')
              let ymd = d[0]
              return `${ymd}`
            },
            sortable: true,
          },
          {
            header: '상환원금',
            name: 'repaymentPrincipal',
            align: 'center',
            formatter({ value }) {
              return Intl.NumberFormat().format(value)
            },
            sortable: true,
          },
          {
            header: '기본이자',
            name: 'repaymentInterest',
            align: 'center',
            formatter({ value }) {
              return Intl.NumberFormat().format(value)
            },
            sortable: true,
          },
          {
            header: '연체이자',
            name: 'repaymentOverdueInterest',
            align: 'center',
            formatter({ value }) {
              return Intl.NumberFormat().format(value)
            },
            sortable: true,
          },
          {
            header: '추가이자',
            name: 'repaymentAdditionalInterest',
            align: 'center',
            formatter({ value }) {
              return Intl.NumberFormat().format(value)
            },
            sortable: true,
          },
          {
            header: '세금',
            name: 'tax',
            align: 'center',
            formatter({ value }) {
              return Intl.NumberFormat().format(value)
            },
            sortable: true,
          },
          {
            header: '수수료',
            name: 'fee',
            align: 'center',
            formatter({ value }) {
              return Intl.NumberFormat().format(value)
            },
            sortable: true,
          },
          {
            header: '상환일',
            name: 'repaymentDate',
            align: 'center',
            formatter({ value }) {
              if (!!!value) {
                return ''
              }
              let d = value.split(' ')
              let ymd = d[0]
              return `${ymd}`
            },
          },
        ],
        summary: {
          height: 40,
          position: 'top',
          columnContent: {
            repaymentPrincipal: {
              template(valueMap) {
                return `<p style="margin: 0; text-align: center;">합: ${valueMap.sum}</p>`
              },
            },
            repaymentInterest: {
              template(valueMap) {
                return `<p style="margin: 0; text-align: center;">합: ${valueMap.sum}</p>`
              },
            },
            repaymentOverdueInterest: {
              template(valueMap) {
                return `<p style="margin: 0; text-align: center;">합: ${valueMap.sum}</p>`
              },
            },
            repaymentAdditionalInterest: {
              template(valueMap) {
                return `<p style="margin: 0; text-align: center;">합: ${valueMap.sum}</p>`
              },
            },
            tax: {
              template(valueMap) {
                return `<p style="margin: 0; text-align: center;">합: ${valueMap.sum}</p>`
              },
            },
            fee: {
              template(valueMap) {
                return `<p style="margin: 0; text-align: center;">합: ${valueMap.sum}</p>`
              },
            },
          },
        },
        data: [],
      },
    }
  },
  watch: {
    fromToDate: {
      deep: true,
      handler() {
        if (this.search.userIdx && this.fromToDate.length === 2) {
          this.getRepayments()
        }
      },
    },
    selectedUser() {
      this.search.userIdx = this.selectedUser.idx
    },
    search: {
      deep: true,
      handler() {
        if (this.search.userIdx && this.fromToDate.length === 2) {
          this.getRepayments()
        }
      },
    },
  },
  computed: {
    dateRangeText() {
      return this.fromToDate.join(' ~ ')
    },
  },
  methods: {
    async getRepayments() {
      if (!!!this.search.userIdx || this.fromToDate.length !== 2) {
        return
      }

      let q = `
        data: getUserRepayments(search: {
          userIdx: ${this.search.userIdx}
          byDate: ${this.search.byDate}
          includeWriteOff: ${this.search.includeWriteOff}
          startDate: "${this.fromToDate[0]}"
          endDate: "${this.fromToDate[1]}"
        })
      `

      this.isLoading = true

      try {
        let data = await this.$fundaApi.query(gql`{${q}}`)

        data = JSON.parse(data)
        this.gridProps.data = data
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .date-range {
  width: 300px;
}
</style>
