<template>
  <div>
    <PageHeader title="알람 관리" />

    <v-row no-gutters class="mb-10">
      <v-col cols="12">
        <v-list>
          <v-list-group no-action :value="true">
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>
                  <h2 class="black--text text-center">
                    팝업 스타일 및 내용 확인
                  </h2>
                </v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item class="pa-4">
              <v-row class="mt-3" no-gutters>
                <v-col cols="12" class="mb-8">
                  <p class="mb-2 font-size--20 text-center">팝업 선택</p>

                  <v-simple-table style="border: 1px solid #eeeeee">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th width="20%" class="text-center">팝업 이름</th>
                          <th width="25%" class="text-center">팝업 타이틀</th>
                          <th width="20%" class="text-center">공개 범위</th>
                          <th width="35%" class="text-center">공개 기간</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="popup in popups"
                          :key="popup.name"
                          @click="selectPopup(popup)"
                        >
                          <td class="text-center">
                            {{ popup.name }}
                          </td>
                          <td class="text-center">
                            {{ popup.title }}
                          </td>
                          <td class="text-center">
                            {{ getVisibleText(popup.visible) }}
                          </td>
                          <td class="text-center">
                            <span>
                              {{ popup.onTime | datetimeFormat }}
                            </span>
                            ~
                            <span>
                              {{ popup.endTime | datetimeFormat }}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>

                <v-col cols="6" class="pr-2">
                  <p class="mb-3 font-size--18 text-center">
                    고객에게 보이는 화면
                  </p>

                  <div class="custom-popup show">
                    <div
                      v-if="popupForm.contents"
                      class="custom-popup__contents color--body"
                    >
                      <div
                        class="popup-body"
                        :class="{ 'my-4': !!popupForm.title }"
                      >
                        <h3 v-if="popupForm.title" class="text-header">
                          {{ popupForm.title }}
                        </h3>
                        <div v-html="popupForm.contents"></div>
                      </div>
                      <div class="popup-footer">
                        <div class="popup-not-showing">오늘하루 안보기</div>
                        <div class="popup-close-btn">닫기</div>
                      </div>
                    </div>
                  </div>
                </v-col>

                <v-col cols="6" class="pl-2">
                  <p class="mb-3 font-size--18 text-center">팝업 내용 설정</p>
                  <v-row no-gutters>
                    <v-col cols="12" class="text-right mb-4">
                      <S3FileUploadButton
                        ref="fileUploadButton"
                        uploadOnSelect
                        :bucketName="bucketName"
                        :path="path"
                        :tempFile.sync="tempFile"
                        :savedFile.sync="savedFile"
                      />
                      <p class="my-1">
                        이미지 팝업인 경우, 파일을 업로드하면 이미지 태그가
                        생성됩니다.
                        <br />
                        아래를 복붙해서 쓰세요
                      </p>
                      <p class="mb-0 font-size--16">
                        {{ filePath }}
                      </p>
                    </v-col>
                    <v-col cols="6" class="pr-2">
                      <v-text-field
                        v-model="popupForm.name"
                        label="팝업 이름"
                        hint="팝업의 고유한 이름입니다. (중복 안됨)"
                        outlined
                        persistent-hint
                      >
                      </v-text-field>
                    </v-col>

                    <v-col cols="6" class="pl-2">
                      <v-select
                        v-model="popupForm.visible"
                        label="공개 범위"
                        outlined
                        hide-details
                        :items="options"
                        item-text="text"
                        item-value="value"
                      ></v-select>
                    </v-col>

                    <v-col cols="6" class="mt-3 mb-5 pr-2">
                      <CommonDateTimePicker
                        label="시작일"
                        :dateTime.sync="popupForm.onTime"
                      />
                    </v-col>

                    <v-col cols="6" class="mt-3 mb-5 pl-2">
                      <CommonDateTimePicker
                        label="종료일"
                        :dateTime.sync="popupForm.endTime"
                      />
                    </v-col>

                    <v-col cols="12" class="mb-2">
                      <v-text-field
                        v-model="popupForm.title"
                        hide-details
                        full-width
                        outlined
                        label="팝업 타이틀"
                        placeholder="팝업 타이틀"
                      ></v-text-field>

                      <!-- vuetify 에서는 vue model의 .lazy modifier 기능이 안먹는다 -->
                      <v-textarea
                        outlined
                        hide-details
                        full-width
                        rows="20"
                        :value="popupForm.contents"
                        @change="(v) => (popupForm.contents = v)"
                      >
                      </v-textarea>
                    </v-col>

                    <v-col cols="12">
                      <v-btn
                        large
                        :dark="!isDisabled"
                        :loading="isLoading"
                        :disabled="isDisabled"
                        @click="setPopup"
                      >
                        세팅하기
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-list-item>
          </v-list-group>
        </v-list>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  components: {
    CommonDateTimePicker: () =>
      import('@/components/common/form/CommonDateTimePicker.vue'),
    S3FileUploadButton: () =>
      import('@/components/common/form/S3FileUploadButton.vue'),
  },
  data() {
    return {
      isLoading: false,
      popups: [],
      popupForm: {
        name: null,
        title: null,
        visible: null,
        onTime: null,
        endTime: null,
      },
      options: [
        { text: '전체 공개', value: 'ALL' },
        { text: '어드민 공개 (사전 테스트)', value: 'ADMIN' },
        { text: '비공개', value: 'INVISIBLE' },
      ],
      tempFile: null,
      savedFile: null,
      bucketName: 'cdn.funda.kr',
      path: 'funding/common/image/_popup',
    }
  },
  computed: {
    isDisabled() {
      return !this.popupForm?.idx || this.isLoading
    },
    filePath() {
      if (!!this.savedFile) {
        return `<div style="line-height: 0"> \n\n
        <img src="//${this.bucketName}/${this.savedFile.fileKey}" style="max-height: 580px" />
        \n\n
        </div>
        `
      } else {
        return '--- 파일을 업로드하면 이미지 태그가 나타납니다. ---'
      }
    },
  },
  watch: {
    tempFile(v) {
      if (!!v) {
        this.$refs.fileUploadButton.saveFile()
      }
    },
  },
  created() {
    this.getAll()
  },
  methods: {
    selectPopup(popup) {
      this.popupForm = { ...popup }
    },
    getVisibleText(visible) {
      return this.options.filter((o) => o.value === visible)[0]?.text
    },
    async getAll() {
      const q = `
        data: getAllPopups {
          idx
          visible
          name
          title
          contents
          onTime
          endTime
          updateTime
        }
      `

      const data = await this.$fundaApi.query(gql`{${q}}`)
      this.popups = data
    },
    async setPopup() {
      const confirm = await this.$swal.basic.confirm('팝업을 세팅합니다.')
      if (confirm.isConfirmed === false) {
        return
      }

      this.isLoading = true

      const q = 'data: updatePopup(inputPopupSetting: $input) { idx }'

      try {
        await this.$fundaApi.mutation(
          gql`{${q}}`,
          'call($input: InputPopupSetting)',
          {
            input: this.popupForm,
          }
        )

        for (const key in this.popupForm) {
          this.popupForm[key] = null
        }

        this.getAll()
        window.scrollTo({ top: 0, behavior: 'smooth' })
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss">
.popup-body {
  .text-popup {
    padding-left: 20px;
    padding-right: 20px;
    .text-body {
      margin-top: 20px !important;
      font-size: 15px;
      line-height: 1.5;
    }
    .table-area table {
      width: 100%;
      border-top: 1px solid #333333;
      border-bottom: 1px solid #bac1c8;
    }
    .table-area td {
      padding: 10px 0;
      line-height: 1.3;
    }
    .table-area td:last-child {
      padding-left: 10px;
    }
    .table-area .td-first {
      font-weight: 600;
    }
  }

  .text-header {
    font-size: 22px;
    font-weight: 600;
    padding: 5px 20px;
  }

  img {
    width: 100%;
    height: auto;
  }
}
</style>

<style lang="scss" scoped>
.custom-popup {
  width: 100%;
  min-height: 600px;
  background-color: rgb(0, 0, 0, 0.7);

  padding-top: 50px;
  padding-bottom: 50px;
}

.custom-popup > .custom-popup__contents {
  display: block;
  max-width: 500px;
  width: 90%;
  max-height: 90%;
  margin: 0 auto;
  border-radius: 5px;
  background-color: white;
  overflow-y: auto;
}

.custom-popup .popup-footer {
  white-space: nowrap;
  text-align: center;
  font-size: 17px;
  cursor: pointer;
}

.custom-popup .popup-footer .popup-not-showing {
  display: inline-block;
  width: 60%;
  padding: 20px;
  color: #8a94a2;
  font-weight: 600;
  background-color: #e7edf6;
}

.custom-popup .popup-footer .popup-close-btn {
  display: inline-block;
  width: 40%;
  padding: 20px;
  color: #ffffff;
  background-color: #333333;
}
</style>
