import alimtalkApi from '../../plugins/api/alimtalk'

let isLoading = false
let isLoaded = false
const st = {
  name: 'alimtalk/code',
  namespaced: true,
  state: {
    code: {},
  },
  getters: {
    getAll(state) {
      st.mutations.init(state)
      return state.code
    },
    getCategory: (state) => (key) => {
      st.mutations.init(state)
      return state.code[key]
    },
  },
  mutations: {
    async init(state) {
      if (isLoading || isLoaded) {
        return
      }
      try {
        isLoading = true
        const { data } = await alimtalkApi.getCodes()
        state.code = data
        isLoaded = true
      } finally {
        isLoading = false
      }
    },
  },
}

export default st
