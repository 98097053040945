<template>
  <v-dialog v-model="dialog" max-width="500px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        large
        color="primary"
        class="mr-2 float-right"
        v-bind="attrs"
        v-on="on"
        @click="dialog = true"
      >
        은행 점검 등록
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">은행 점검 등록</span>
      </v-card-title>
      <v-card-text>
        <BankInspectionForm
          :key="dialog"
          :disabled="isLoading"
          @submit="createBankInspection"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import BankInspectionForm from './BankInspectionForm.vue'

export default {
  components: { BankInspectionForm },
  props: {},
  data() {
    return {
      isLoading: false,
      dialog: false,
    }
  },
  methods: {
    async createBankInspection(formData) {
      if (this.isLoading === true) {
        return
      }
      try {
        this.isLoading = true

        let btnResult = await this.$swal.basic.confirm({
          title: '은행 점검 등록',
          text: '등록하시겠습니까?',
          cancelButtonColor: 'red',
        })
        if (btnResult.isConfirmed !== true) {
          return
        }

        // 신규 등록 후 상세페이지 이동용 idx
        const {
          data: { idx },
        } = await this.$paymentApi.postBankInspection(formData)

        // 이동
        this.dialog = false
        this.$router.push({
          name: 'bank/inspections/detail',
          params: { idx },
        })
      } catch (e) {
        this.$swal.basic.alert({
          text: '등록에 실패했습니다.',
        })
        throw e
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
