<template>
  <v-container fluid class="pt-0">
    <PageHeader title="반환 목록 조회" subtitle="반환 목록 조회"> </PageHeader>
    <UserRefundsTable :query="query" :isQueryPaging="true" />
  </v-container>
</template>
<script>
import UserRefundsTable from '../../../../components/payment/user/refund/UserRefundsTable.vue'
export default {
  components: { UserRefundsTable },
  props: {
    query: {
      type: Object,
      default: (_) => {
        return null
      },
    },
  },
}
</script>
