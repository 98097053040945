import client from '../client'
import qs from 'query-string'

export default {
  getPayoutReserves(param) {
    return client.call({
      url: `/payout/reserves?${qs.stringify(param, {
        skipNull: true,
        skipEmptyString: true,
      })}`,
      method: 'GET',
    })
  },
  postPayoutStep2(payoutReserveIdx) {
    return client.call({
      url: `payout/reserve/${payoutReserveIdx}/execute/step2`,
      method: 'POST',
    })
  },
}
