<template>
  <v-menu
    v-model="isDatePickerOpen"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="290"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="date"
        :label="label"
        prepend-inner-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        hide-details
        v-on="on"
        outlined
        dense
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      :min="minDate"
      @input="inputDate"
      show-current
    >
      <!-- 오늘 날짜 선택 버튼 -->
      <template v-slot>
        <v-btn
          color="primary"
          type="button"
          @click="inputDate($moment().format())"
          >오늘</v-btn
        >
      </template>
    </v-date-picker>
  </v-menu>
</template>
<script>
export default {
  props: {
    value: {
      type: String,
      default: null,
    },
    minDate: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: '날짜 선택',
    },
  },
  data() {
    return {
      isDatePickerOpen: false,
      date: this.value,
    }
  },
  watch: {
    value(v) {
      if (v) {
        this.date = v
      }
    },
  },
  methods: {
    inputDate(e) {
      this.isDatePickerOpen = false
      this.$emit('change', e)
    },
  },
}
</script>
