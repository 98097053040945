<template>
  <v-row no-gutters>
    <v-col class="img-wrapper" cols="12">
      <div v-if="!!fileUrl && isLoading === false" class="d-flex align-center">
        <v-row no-gutters>
          <v-col cols="12" class="mb-10">
            <v-btn @click="imgRotate(90)">
              <v-icon>mdi-refresh</v-icon> 회전
            </v-btn>
          </v-col>
          <v-col cols="12" class="my-2">
            <iframe
              v-if="isPdf"
              type="application/pdf"
              style="height: 800px; width: 100%"
              :src="fileUrl"
            ></iframe>
            <img
              v-else
              :src="fileUrl"
              :class="{ 'is-large': isLarge }"
              :style="{ transform: `rotate(${deg}deg)` }"
              @click="isLarge = !isLarge"
            />
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <v-progress-circular
          class="mx-2"
          size="20"
          width="3"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    awsS3FileIdx: {
      type: Number,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      isLoading: true,
      isLarge: false,
      deg: 0,
      isPdf: false,
      fileInfo: null,
      fileUrl: null,
    }
  },
  watch: {
    awsS3FileIdx: {
      immediate: true,
      handler(v) {
        if (!!v) {
          this.getFileUrl()
        }
      },
    },
  },
  methods: {
    imgRotate(deg) {
      this.deg += deg
    },
    async getFileInfo() {
      let q = `
          data: getS3FileInfo(awsS3FileIdx: ${this.awsS3FileIdx}) {
            idx
            orgName
            fileKey
          }
        `

      this.isLoading = true

      try {
        let data = await this.$fundaApi.query(gql`{${q}}`)
        this.fileInfo = data
      } finally {
        this.isLoading = false
      }
    },
    async getFileUrl() {
      let q = `
          data: getS3FileURL(awsS3FileIdx: ${this.awsS3FileIdx})
        `

      this.isLoading = true

      try {
        let data = await this.$fundaApi.query(gql`{${q}}`)
        this.fileUrl = data

        const extension = this.fileUrl.indexOf('.pdf')
        this.isPdf = extension > -1
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.img-wrapper {
  min-width: 296px;
  max-width: 100%;
  max-height: 600px;

  img {
    width: 296px;
    max-height: 225px;

    &.is-large {
      max-width: 800px;
      width: 95%;
      max-height: 100%;
    }
  }
}
</style>
