<template>
  <v-select
    v-model="idx"
    :items="systemUsers"
    item-text="label"
    item-value="idx"
    :label="label"
    :dense="dense"
    outlined
    :disabled="isDisabled || systemUsers.length === 0"
    :loading="systemUsers.length === 0"
    return-object
    hide-details
    @change="$emit('change', $event)"
  >
    <template v-if="optional" v-slot:prepend-item>
      <v-list-item ripple @click="clear"> 전체 </v-list-item>
    </template>
  </v-select>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    label: {
      type: String,
      default: '시스템 유저 선택',
    },
    dense: {
      type: Boolean,
      default: false,
    },
    userVirtualAccountIdx: {
      type: Number,
      default: null,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    optional: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      idx: this.userVirtualAccountIdx,
    }
  },
  computed: {
    ...mapGetters('payment/system', {
      systemUsers: 'getVirtualAccounts',
    }),
  },
  watch: {
    userVirtualAccountIdx(v) {
      this.idx = v
    },
  },
  methods: {
    clear() {
      this.$emit('update:userVirtualAccountIdx', null)
    },
  },
}
</script>
