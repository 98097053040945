import Vue from 'vue'

export function commaFormat(value) {
  if (!value) return '0'
  const strNumber = value.toString().replace(/[^\d\\-]/g, '')
  const plainNumber = Number(strNumber).toString()
  return plainNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
Vue.filter('commaFormat', commaFormat)

export function commaRemove(value) {
  return value.replace(/[^\d]/g, '')
}

Vue.filter('commaRemove', commaRemove)

Vue.filter('won', function (value) {
  if (!value) return '0'
  return value.toString() + '원'
})

export function rateFormat(value) {
  let v = parseFloat(value) * 100
  return parseFloat(v.toFixed(2))
}

Vue.filter('rateFormat', rateFormat)
