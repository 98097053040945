import Vue from 'vue'

Vue.component('PageHeader', (_) =>
  import('../components/common/page/PageHeader')
)

Vue.component('PageSection', (_) =>
  import('../components/common/page/PageSection')
)

/**
 * https://www.npmjs.com/package/vue-countup-v2
 * https://github.com/xlsdg/vue-countup-v2
 */
Vue.component('ICountUp', () => import('vue-countup-v2'))
