var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"large":""},on:{"click":function($event){_vm.dialog = true}}},'v-btn',attrs,false),on),[_vm._v(" 수신 번호 추가 - 직접 입력 ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("수신 번호 추가")])]),_c('ValidationObserver',{ref:"validForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.save()}}},[_c('v-card-text',[_c('v-container',[_c('ValidationProvider',{attrs:{"name":"phone","rules":"required|numeric|phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"mobileNo","error-messages":errors,"autofocus":""},model:{value:(_vm.form.mobileNo),callback:function ($$v) {_vm.$set(_vm.form, "mobileNo", $$v)},expression:"form.mobileNo"}})]}}],null,true)}),_vm._l((_vm.variables),function(variable,index){return _c('ValidationProvider',{key:index,attrs:{"name":variable,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":variable,"error-messages":errors},model:{value:(_vm.form[variable]),callback:function ($$v) {_vm.$set(_vm.form, variable, $$v)},expression:"form[variable]"}})]}}],null,true)})})],2)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","type":"button"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","disabled":invalid,"type":"submit"}},[_vm._v(" Save ")])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }