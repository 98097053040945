<template>
  <div v-if="isLoading || userDetails === null">
    <v-progress-circular
      class="mx-2"
      size="20"
      width="3"
      indeterminate
      color="primary"
    ></v-progress-circular>
  </div>
  <div v-else>
    <v-row no-gutters style="min-height: 1300px">
      <v-col cols="6" sm="12" md="6" style="pr-2">
        <S3FileReader :awsS3FileIdx="selectedFileIdx" />
      </v-col>
      <v-col
        cols="6"
        sm="12"
        md="6"
        class="pl-2"
        style="border-left: 1px solid #eee"
      >
        <v-list>
          <v-list-group no-action :value="!userIsValid">
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>
                  <h2 class="black--text text-center">
                    {{
                      userIsValid ? '이미 승인된 유저입니다.' : '승인 / 거절'
                    }}
                  </h2>
                </v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item v-if="userIsValid === false" class="d-block pa-0">
              <v-row class="mt-3" no-gutters>
                <v-col cols="6" class="text-center">
                  <v-btn
                    v-show="rejectionSelect === false"
                    color="primary"
                    width="220"
                    height="40"
                    :disabled="decisionLoading || isActivebtnDecision !== true"
                    :loading="decisionLoading"
                    @click="decision(true)"
                  >
                    인증 승인
                  </v-btn>
                </v-col>

                <v-col cols="6" class="text-center">
                  <v-btn
                    color="error"
                    width="220"
                    height="40"
                    :disabled="decisionLoading"
                    :loading="decisionLoading"
                    @click="rejectionSelect = !rejectionSelect"
                  >
                    인증 거절
                  </v-btn>
                </v-col>
              </v-row>

              <v-slide-y-transition hide-on-leave>
                <v-row v-if="rejectionSelect">
                  <hr class="width-100 my-3" />
                  <v-col cols="5">
                    <v-select
                      v-model="decisionForm.rejectionType"
                      :items="rejectionOptions.type"
                      attach
                      chips
                      multiple
                      hide-details
                      item-value="value"
                      item-text="text"
                      label="거절 타입"
                      placeholder="거절 타입"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="7">
                    <v-select
                      v-model="decisionForm.decisionMemo"
                      :items="rejectionOptions.options"
                      :menu-props="{ maxHeight: '500' }"
                      multiple
                      hide-details
                      label="거절 사유"
                      placeholder="거절 사유를 선택하세요"
                    ></v-select>
                  </v-col>

                  <v-col cols="6">
                    <v-textarea
                      v-model="decisionForm.typingMemo"
                      label="거절 사유 직접입력"
                      hide-details
                      rows="2"
                    >
                    </v-textarea>
                  </v-col>
                  <v-col cols="6">
                    <v-textarea
                      v-model="decisionForm.userMemo"
                      label="유저정보에 남는 메모"
                      hide-details
                      rows="2"
                    >
                    </v-textarea>
                  </v-col>

                  <v-col cols="8"> </v-col>

                  <v-col cols="4">
                    <v-btn
                      width="100%"
                      :disabled="decisionLoading"
                      :loading="decisionLoading"
                      @click="decision(false)"
                    >
                      거절
                    </v-btn>
                  </v-col>
                </v-row>
              </v-slide-y-transition>
            </v-list-item>

            <v-list-item v-if="userDetails.investmentLimit.idx === 3">
              <v-row no-gutters>
                <v-col cols="12">
                  <p class="my-1 font-size--15">
                    사업자등록번호

                    <span class="font-size--14">
                      (저장일시:
                      {{ userCorpRegNum.createTime | datetimeFormat }})
                    </span>
                  </p>
                </v-col>

                <v-col cols="8">
                  <v-text-field
                    v-model.trim="userCorpRegNum.regNum"
                    :rules="rules.userCorpRegNum"
                    :maxlength="10"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-btn
                    height="56px"
                    color="#393939"
                    class="white--text"
                    @click="registerCorpRegNum()"
                    :loading="corpRegNumLoading"
                    :disabled="corpRegNumLoading || isValidCorpRegNum !== true"
                  >
                    사업자등록번호 저장
                  </v-btn>
                </v-col>
              </v-row>
            </v-list-item>
          </v-list-group>
        </v-list>
        <v-list>
          <v-list-group no-action :value="true">
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>
                  <h2 class="black--text text-center">투자자 타입</h2>
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item class="d-block pa-0">
              <v-row no-gutters>
                <v-col cols="3">
                  <v-subheader class="subtitle-1 black--text pa-0">
                    현재 투자자 타입:
                  </v-subheader>
                </v-col>
                <v-col cols="9">
                  <v-subheader class="black--text font-size--18 pa-0">
                    <h3>
                      {{ userDetails.investmentLimit.type }}
                    </h3>
                  </v-subheader>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="3">
                  <v-subheader class="subtitle-1 black--text pa-0">
                    등록 신분증:
                  </v-subheader>
                </v-col>
                <v-col cols="9">
                  <v-subheader class="pa-0">
                    <h3
                      class="color--funda-basic cursor--pointer"
                      @click="
                        selectedFileIdx =
                          userDetails.userValidation.userCdds[0].userDoc
                            .awsS3FileIdx
                      "
                    >
                      {{
                        userDetails.userValidation.userCdds[0].type ===
                        'RESIDENT'
                          ? '주민등록증'
                          : '운전면허증'
                      }}
                    </h3>
                  </v-subheader>
                </v-col>

                <v-col cols="3">
                  <v-subheader class="subtitle-1 black--text pa-0">
                    입력값:
                  </v-subheader>
                </v-col>
                <v-col cols="9">
                  <v-subheader class="pa-0" style="height: 100%">
                    <h3 class="black--text" v-html="inputIdCard"></h3>
                  </v-subheader>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <hr class="width-100 my-3" />
                <template v-if="reqChangeLimit">
                  <v-col cols="3">
                    <v-subheader class="subtitle-1 black--text pa-0">
                      변경 요청 타입:
                    </v-subheader>
                  </v-col>
                  <v-col cols="9">
                    <v-subheader class="error--text font-size--18 pa-0">
                      <h3>
                        {{ reqChangeLimit.investmentLimit.type }}
                      </h3>
                    </v-subheader>
                  </v-col>

                  <v-col cols="3">
                    <v-subheader class="subtitle-1 black--text pa-0">
                      등록 서류:
                    </v-subheader>
                  </v-col>
                  <v-col cols="9">
                    <v-btn
                      v-for="(doc, index) in reqChangeLimit.userDocs"
                      :key="'req_file_' + doc.awsS3FileIdx"
                      color="#393939"
                      class="white--text mr-2"
                      width="80px"
                      height="40px"
                      @click="selectedFileIdx = doc.awsS3FileIdx"
                    >
                      서류_{{ index + 1 }}
                    </v-btn>
                  </v-col>

                  <v-col cols="3"></v-col>
                  <v-col cols="9">
                    <v-btn
                      color="primary"
                      width="150"
                      height="40"
                      class="mr-4"
                      :loading="reqChangeLimitLoading"
                      :disabled="reqChangeLimitLoading"
                      @click="decisionReqChange(true)"
                    >
                      투자자 변경 승인
                    </v-btn>

                    <v-btn
                      color="error"
                      width="150"
                      height="40"
                      :loading="reqChangeLimitLoading"
                      :disabled="reqChangeLimitLoading"
                      @click="decisionReqChange(false)"
                    >
                      투자자 변경 거절
                    </v-btn>
                  </v-col>
                </template>
              </v-row>
            </v-list-item>
          </v-list-group>
        </v-list>

        <v-list>
          <v-list-group no-action :value="true">
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>
                  <h2 class="black--text text-center">추가 정보(EDD)</h2>
                </v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item class="d-block pa-0">
              <v-row
                v-for="userEdd in userDetails.userValidation.userEdds"
                :key="`${userIdx}_${userEdd.question}`"
                no-gutters
              >
                <v-col cols="3">
                  <v-subheader
                    class="subtitle-1 edd pa-0"
                    :class="getEddWarningClass(userEdd)"
                  >
                    {{ userEdd.question }}:
                  </v-subheader>
                </v-col>
                <v-col cols="9">
                  <UserField
                    :value="userEdd.answer"
                    field="text"
                    disabled
                  ></UserField>
                </v-col>
              </v-row>
            </v-list-item>
          </v-list-group>
        </v-list>

        <v-list>
          <v-list-group no-action :value="true">
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>
                  <h2 class="black--text text-center">유저 기본정보</h2>
                </v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item class="d-block pa-0">
              <v-row
                v-for="userField in userFields"
                :key="userField.name"
                no-gutters
              >
                <v-col cols="3">
                  <v-subheader
                    class="subtitle-1 black--text pa-0"
                    :class="{ 'font-weight-black': userField.bold }"
                  >
                    {{ userField.title }}:
                  </v-subheader>
                </v-col>
                <v-col cols="9">
                  <UserField
                    :value="userDetails[userField.name]"
                    :field="!!userField.field ? userField.field : 'text'"
                    :hint="!!userField.hint ? userField.hint : null"
                    disabled
                  ></UserField>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="3">
                  <v-subheader class="subtitle-1 black--text pa-0">
                    투자자 타입:
                  </v-subheader>
                </v-col>
                <v-col cols="9">
                  <UserField
                    :value="userDetails.investmentLimit.type"
                    field="text"
                    disabled
                  ></UserField>
                </v-col>
              </v-row>
            </v-list-item>
          </v-list-group>
        </v-list>

        <v-list>
          <v-list-group no-action :value="true">
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>
                  <h2 class="black--text text-center">등록된 입금용 주계좌</h2>
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item class="d-block pa-0">
              <v-row no-gutters>
                <v-col cols="3">
                  <v-subheader class="subtitle-1 black--text pa-0">
                    등록상태:
                  </v-subheader>
                </v-col>
                <v-col cols="9">
                  <v-subheader class="black--text font-size--18 pa-0">
                    <h3
                      :class="
                        userDetails.userValidation.isValidAccount
                          ? 'blue--text'
                          : 'error--text'
                      "
                    >
                      {{
                        userDetails.userValidation.isValidAccount
                          ? '인증완료'
                          : '미인증'
                      }}

                      <span class="black--text font-size--15">
                        ({{
                          userDetails.userValidation.accountValidTime
                            | datetimeFormat
                        }})
                      </span>
                    </h3>
                  </v-subheader>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="3">
                  <v-subheader class="subtitle-1 black--text pa-0">
                    은행:
                  </v-subheader>
                </v-col>
                <v-col cols="9">
                  <v-subheader class="black--text font-size--18 pa-0">
                    <h3></h3>
                  </v-subheader>
                </v-col>
              </v-row>
            </v-list-item>
          </v-list-group>
        </v-list>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import S3FileReader from '@/components/common/reader/S3FileReader'
import UserField from '@/components/funda/user/UserField'

export default {
  props: {
    userIdx: {
      type: Number,
      required: true,
      default: null,
    },
    isApproval: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    S3FileReader,
    UserField,
  },
  data() {
    return {
      isLoading: false,
      decisionLoading: false,
      reqChangeLimitLoading: false,
      rejectionSelect: false,
      rejectionOptions: {
        type: [
          {
            text: '신분증',
            value: 'IDENTIFICATION',
          },
          {
            text: '출금계좌',
            value: 'ACCOUNT',
          },
          {
            text: '추가 정보(EDD)',
            value: 'EDD',
          },
        ],
        options: [
          '신분증 정보 식별불가',
          '신분증과 본인정보 불일치',
          '신분증 실물본 제출 요청 (복사본 혹은 캡쳐본 사용 제한)',
          '운전면허증 갱신 기간 만료',
          '위험 직업군으로 분류되어, 서비스 이용이 거절 되었습니다',
          '일회성 금융거래로 판단되어, 서비스 이용이 거절 되었습니다',
        ],
      },
      userFields: [
        { title: '이름', name: 'name', bold: true },
        { title: '휴대폰', name: 'userPhone', bold: true },
        { title: '생년월일', name: 'userBirthdate', bold: true },
        {
          title: '주민등록번호',
          name: 'regNum',
          bold: true,
          hint: '반드시 신분증(운전면허증)의 유효기간을 확인하세요',
        },
        {
          title: '주소',
          name: 'address',
          field: 'textarea',
          bold: true,
        },
        { title: '인덱스', name: 'idx', bold: false },
        { title: '아이디', name: 'id', bold: false },
        { title: '입력코드', name: 'affiliateCode', bold: false },
      ],
      eddFields: [
        { title: '질문', name: 'question', bold: false },
        { title: '답변', name: 'answer', bold: false },
      ],
      userDetails: null,
      reqChangeLimit: null,
      corpRegNumLoading: false,
      userCorpRegNum: {
        regNum: null,
        createTime: null,
      },
      rules: {
        userCorpRegNum: [
          (value) => !!value || '사업자등록번호는 필수입니다.',
          (value) => /\d{10}$/.test(value) || '10자리의 숫자를 입력해주세요',
        ],
      },
      decisionForm: {
        decision: false,
        rejectionType: [],
        decisionMemo: [],
        typingMemo: null,
        userMemo: null,
      },
      selectedFileIdx: null,
    }
  },
  computed: {
    userIsValid() {
      const uv = this.userDetails.userValidation

      return uv.isValid
    },
    isValidCorpRegNum() {
      let isPass = this.rules.userCorpRegNum.every((r) => {
        return r(this.userCorpRegNum.regNum) === true
      })

      return isPass
    },
    isActivebtnDecision() {
      if (this.userDetails.investmentLimit.idx !== 3) {
        return true
      }

      return !!this.userCorpRegNum.idx && this.isValidCorpRegNum === true
    },
    inputIdCard() {
      let status = this.isApproval === true ? 'APPROVAL' : 'WAITING'
      const userCdd = this.userDetails.userValidation.userCdds.filter(
        (cdd) => cdd.status === status
      )
      const infoJson = userCdd[0].decryptInfoJson
      let inputValue = null

      if (this.userDetails.investmentLimit.idx === 3) {
        inputValue = `${infoJson.regNum1}-${infoJson.regNum2}`
      } else {
        const JUMIN = infoJson.jumin
        inputValue = `${JUMIN.substr(0, 6)}-${JUMIN.substr(6)}`
      }

      if (!!infoJson?.driverLicense) {
        const DL = infoJson.driverLicense
        const jumin = inputValue
        inputValue = `면허번호: ${DL.areaNumber}-${DL.yearNumber}-${DL.serialNumber}-${DL.endingNumber}`
        inputValue += `<br />주민등록번호: ${jumin} <br />암호일련번호: ${DL.passwordSerialNumber}`
      }

      return inputValue
    },
  },
  watch: {
    isApproval() {
      this.userDetails = null
    },
    'decisionForm.rejectionType'(v) {
      if (!!v) {
        this.decisionForm.decisionMemo = [this.rejectionOptions.options[0]]
      }
    },
  },
  created() {
    this.getData()
  },
  methods: {
    ...mapActions('count', {
      getCountInvestorAuth: 'getInvestorAuth',
    }),
    decisionComplete(isApproval = false) {
      this.$emit('decision-complete', isApproval)
    },
    getEddWarningClass(edd) {
      let result = 'black--text'

      if (
        edd.question === '고위험 직업확인' &&
        edd.answer !== '해당사항 없음'
      ) {
        result = 'error--text font-weight-black'
      }

      return result
    },
    setUserCorpRegNum() {
      const userCorpRegNums = this.userDetails.userCorpRegNums
      if (userCorpRegNums.length > 0) {
        this.userCorpRegNum = userCorpRegNums[userCorpRegNums.length - 1]
      }
    },
    async getData() {
      this.isLoading = true

      const r1 = this.getUserAuthDetails()
      const r2 = this.getReqChangeLimit()
      Promise.all([r1, r2])

      this.isLoading = false
    },
    async getUserAuthDetails() {
      const q = `
        data: getUserDecrypt(userIdx: ${this.userIdx}) {
          idx
          id
          name
          userPhone
          userBirthdate
          regNum
          address
          affiliateCode
          memo
          
          investmentLimit {
            idx
            type
          }

          userValidation {
            userIdx
            
            isValid
            
            isValidPhone
            phoneValidTime
            isValidIdentification
            identificationValidTime
            isValidAccount
            accountValidTime
            isValidEdd
            eddValidTime
            
            createTime

            ${this.isApproval === true ? 'approvalUserCdds' : 'userCdds'} {
              idx
              type
              status
              decryptInfoJson

              userDoc {
                idx
                awsS3FileIdx
              }
            }

            userEdds {
              idx
              userIdx
              question
              answer
              createTime
            }
          }

          userCorpRegNums {
            idx
            regNum
            createTime
          }
        }
      `

      try {
        let data = await this.$fundaApi.query(gql`{${q}}`)

        if (this.isApproval === true) {
          data.userValidation.userCdds = data.userValidation.approvalUserCdds
        }

        data.userValidation.userCdds.forEach((cdd) => {
          cdd.decryptInfoJson = JSON.parse(cdd.decryptInfoJson)
        })

        this.decisionForm.userMemo = data.memo
        this.userDetails = data
        this.selectedFileIdx =
          data.userValidation.userCdds[0].userDoc.awsS3FileIdx
        this.setUserCorpRegNum()
      } catch (e) {
        console.log(e)
      }
    },
    async getReqChangeLimit() {
      const q = `
        getReqLimitWaitingStatus(userIdx: ${this.userIdx}) {
          investmentLimit {
            idx
            type
          }

          userDocs {
            awsS3FileIdx
          }
        }
      `

      try {
        const { getReqLimitWaitingStatus } = await this.$fundaApi.query(
          gql`{${q}}`
        )
        this.reqChangeLimit = getReqLimitWaitingStatus
      } catch (e) {
        this.$swal.basic.warning(e.message)
      }
    },
    async registerCorpRegNum() {
      let confirm = await this.$swal.basic.confirm(
        '사업자등록번호를 저장합니다'
      )

      if (confirm.isConfirmed !== true) {
        return
      }

      const q = `
        data: registerUserCorpRegNum(userIdx: ${this.userIdx}, corpRegNum: "${this.userCorpRegNum.regNum}") {
          idx
          userIdx
          regNum
          createTime
        }
      `

      this.corpRegNumLoading = true

      try {
        const data = await this.$fundaApi.mutation(gql`{${q}}`)

        this.userDetails.userCorpRegNums = data
        this.setUserCorpRegNum()
        this.$swal.basic.success('사업자등록번호가 저장되었습니다')
      } finally {
        this.corpRegNumLoading = false
      }
    },
    async decision(result) {
      if (typeof result !== 'boolean') {
        return
      }

      this.decisionForm.decision = result

      let confirmText = `${this.userDetails.id} / ${this.userDetails.name} / ${this.userDetails.investmentLimit.type}`
      if (this.decisionForm.decision === true) {
        confirmText += ' 투자자 인증을 승인합니다'
      } else {
        if (this.decisionForm.rejectionType === null) {
          this.$swal.basic.warning('거절 타입을 선택해주세요')
          return
        }
        confirmText += ' 투자자 인증을 거절합니다'
      }

      const confirm = await this.$swal.basic.confirm(confirmText)

      if (confirm.isConfirmed !== true) {
        return
      }

      const q = `
        data: decisionUserValidation(userIdx: ${this.userIdx}, userAuthDecision: $input) {
          userIdx
        }
      `

      this.decisionLoading = true

      try {
        let data = await this.$fundaApi.mutation(
          gql`{${q}}`,
          'call($input: UserAuthDecision!)',
          { input: this.decisionForm }
        )

        if (!!data?.userIdx) {
          await this.getCountInvestorAuth()
          this.$swal.basic.success(
            `투자자 인증이 ${result ? '승인' : '거절'}되었습니다`
          )
        } else {
          this.$swal.basic.error('에러가 발생하였습니다')
        }
      } finally {
        this.decisionLoading = false
        this.decisionComplete(result)
      }
    },
    async decisionReqChange(isApp) {
      const confirmText = `투자자 변경 요청을 ${isApp ? '승인' : '거절'} 합니다`

      const confirm = await this.$swal.basic.confirm(confirmText)

      if (confirm.isConfirmed !== true) {
        return
      }

      let q = null
      let memo = null
      if (isApp) {
        memo = `[${this.userDetails.investmentLimit.type}] -> [${this.reqChangeLimit.investmentLimit.type}]로 전환이 완료되었습니다.`

        q = `
          data: approveReqChangeLimit(userIdx: ${this.userIdx}, memo: "${memo}") {
            idx
            id
            
            investmentLimit {
              idx
              type
            }
          }
        `
      } else {
        q = `
          data: rejectReqChangeLimit(userIdx: ${this.userIdx}, memo: "서류 부적합 판정") {
            idx
            id
            
            investmentLimit {
              idx
              type
            }
          }
        `
      }

      this.reqChangeLimitLoading = true

      try {
        const data = await this.$fundaApi.mutation(gql`{${q}}`)
        this.userDetails.investmentLimit = data.investmentLimit
        this.reqChangeLimit = null
        this.$swal.basic.success(
          `투자자 변경 요청이 ${isApp ? '승인' : '거절'} 되었습니다`
        )
      } finally {
        this.reqChangeLimitLoading = false
        this.decisionComplete()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .error--text .v-messages__wrapper > .v-messages__message {
  color: #e73333 !important;
}

::v-deep .v-list-group__header.v-list-item--active {
  background-color: rgb(37, 174, 225, 0.1);
}

.subtitle-1 {
  justify-content: flex-end;
  padding-right: 20px !important;
}
</style>
