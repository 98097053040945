<template>
  <v-container fluid class="pt-0">
    <PageHeader title="페이지 제목" subtitle="페이지 설명입니다" />
    <v-card class="my-3" width="100%">
      <v-card-text>
        <v-row no-gutters>
          <v-col xl="1" lg="2">
            <v-select
              :items="['전체', '포함', '미포함']"
              label="단일 필터"
              outlined
              dense
              hide-details
              class="mr-3"
            ></v-select>
          </v-col>
          <v-col lg="2">
            <v-select
              :items="multiSelect.items"
              v-model="multiSelect.selectedValues"
              label="다중 선택 필터"
              outlined
              multiple
              dense
              hide-details
              class="mr-3"
            >
              <template v-slot:prepend-item>
                <v-list-item ripple @click="multiSelectToggle">
                  <v-list-item-action>
                    <v-icon
                      :color="
                        multiSelect.selectedValues.length > 0
                          ? 'indigo darken-4'
                          : ''
                      "
                    >
                      {{ icon }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title> 전체 </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
            </v-select>
          </v-col>
          <v-col xl="2" lg="2">
            <v-text-field
              dense
              hide-details
              clearable
              outlined
              append-icon="mdi-magnify"
              @click:append="keyword && search"
              @keyup.enter="keyword && search"
              label="번호, 적요"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-row class="text-center mt-3" justify="center" align="center">
      <v-col
        lg="2"
        md="3"
        sm="3"
        xs="5"
        :style="
          $vuetify.breakpoint.mdAndUp == true
            ? 'position: absolute; left: 0;'
            : ''
        "
      >
        <v-text-field
          dense
          hide-details
          solo
          append-icon="mdi-book-open-page-variant"
          @click:append="keyword && search"
          @keyup.enter="keyword && search"
          label="페이지 이동"
        ></v-text-field>
      </v-col>
      <v-spacer v-if="$vuetify.breakpoint.mdAndUp != true" />
      <v-col>
        <v-pagination
          v-model="transactions.paging.page"
          :length="transactions.paging.totalPage * 10"
          total-visible="10"
        ></v-pagination>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import testData from './testData'

export default {
  components: {},
  data() {
    return {
      multiSelect: {
        items: ['첫번째', '두번째', '세번째'],
        selectedValues: [],
      },
      keyword: null,
      transactions: {
        data: [],
        paging: testData.transactions.paging,
        headers: testData.transactions.headers,
      },
      isLoading: false,
    }
  },
  created() {
    console.log(this.$vuetify.breakpoint.name)
    this.isLoading = true
    setTimeout((_) => {
      this.transactions.data = testData.transactions.data
      this.isLoading = false
    }, 3000)
  },
  methods: {
    multiSelectToggle() {
      this.multiSelect.selectedValues.splice(
        0,
        this.multiSelect.selectedValues.length
      )
    },
    search() {
      console.log('search')
    },
  },
  watch: {
    'multiSelect.selectedValues'(v) {
      if (this.multiSelect.items.length === v.length) {
        this.multiSelectToggle()
      }
    },
  },
  computed: {
    selectAll() {
      return this.multiSelect.selectedValues.length === 0
    },
    selectSome() {
      return this.multiSelect.selectedValues.length > 0
    },
    icon() {
      if (this.selectAll) return 'mdi-close-box'
      if (this.selectSome) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
  },
}
</script>
<style lang="scss" scoped>
.page {
  &__content {
    min-height: 400px;
  }
}
</style>
