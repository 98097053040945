import api from '../../plugins/api/payment'
import store from '@/store'
let isLoading = false
let isLoaded = false

const st = {
  name: 'payment/code',
  namespaced: true,
  state: {
    code: {},
  },
  getters: {
    getAll(state) {
      store.dispatch(`${st.name}/init`)
      return state.code
    },
    getCategory: (state) => (key) => {
      store.dispatch(`${st.name}/init`)
      return state.code[key]
    },
  },
  mutations: {
    setCode(state, code) {
      state.code = code
    },
  },
  actions: {
    async init({ commit }) {
      if (isLoading || isLoaded) {
        return false
      }
      try {
        isLoading = true
        const { data } = await api.getCodes()
        commit('setCode', data)
        isLoaded = true
      } finally {
        isLoading = false
      }
      return true
    },
  },
}

export default st
