<template>
  <div v-if="store">
    <template v-if="!store.businessLicenseNumber">
      <span class="error--text">사업자번호 없음</span>
    </template>
    <template v-else>
      {{ store.name }}
      <p class="mb-0">({{ store.businessLicenseNumber }})</p>
    </template>
  </div>
</template>
<script>
export default {
  props: {
    userId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      store: null
    }
  },
  async created() {
    const { data } = await this.$fundaNowApi.getUserStore(this.userId)
    this.store = data
  }
}
</script>
