<template>
  <v-btn
    color="primary"
    :block="block"
    @click="download"
    :disabled="loading || isLoading || data == null || data.length == 0"
    :loading="loading || isLoading"
  >
    {{ label }}
  </v-btn>
</template>
<script>
import XLSX from 'xlsx'

export default {
  props: {
    label: {
      type: String,
      default: '엑셀 다운',
    },
    headers: {
      type: Array,
      default: (_) => {
        return null
      },
    },
    data: {
      type: Array,
      reauired: true,
    },
    fileName: {
      type: String,
      default: 'export',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
    }
  },
  methods: {
    download() {
      if (this.isLoading === true) {
        return
      }
      try {
        this.isLoading = true
        let arr = []
        if (this.headers != null) {
          arr.push(this.getExcelHeader())
        }
        arr = arr.concat(this.data)
        const sheet = XLSX.utils.json_to_sheet(arr)
        const workbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workbook, sheet, 'sheet1')
        XLSX.writeFile(
          workbook,
          `${this.$moment().format('YYMMDD_HHmmss')}_${this.fileName}.xlsx`
        )
      } finally {
        this.isLoading = false
      }
    },
    getExcelHeader() {
      let obj = {}
      this.headers.forEach((header) => {
        if (header.value != null) {
          obj[header.value] = header.text
        }
      })
      return obj
    },
  },
}
</script>
