<template>
  <v-container fluid class="pt-0">
    <PageHeader
      title="수동 상환 내역 목록"
      subtitle="상환 내역"
      tabMenu
    >
    </PageHeader>
    <HistoriesTable class="mt-3" :query="query" :isQueryPaging="true" />
  </v-container>
</template>
<script>
import HistoriesTable from '@/components/fundanow/repayment/manual/HistoriesTable.vue'
export default {
  components: { HistoriesTable },
  props: {
    query: {
      type: Object,
    },
  },
}
</script>
