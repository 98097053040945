<template>
  <v-container fluid class="pt-0">
    <PageHeader
      title="알림톡 발송"
      subtitle="처음 사용하는 템플릿의 경우 테스트 발송을 먼저 진행해주세요."
      color="error"
    >
      <template v-slot:right>
        <v-col>
          <AlimtalkBizmBalance />
        </v-col>
      </template>
    </PageHeader>
    <v-row no-gutters>
      <TemplateSendForm
        v-if="template"
        :template="template"
        :variables="variables"
      ></TemplateSendForm>
    </v-row>
  </v-container>
</template>

<script>
import TemplateSendForm from '../../../components/alimtalk/template/form/TemplateSendForm.vue'
import AlimtalkBizmBalance from '../../../components/alimtalk/bizm/AlimtalkBizmBalance.vue'

export default {
  components: {
    TemplateSendForm,
    AlimtalkBizmBalance,
  },
  data() {
    return {
      template: null,
      variables: null,
      receivers: null,
      previewVariableData: null,
      onDrag: false,
      reserveTime: null,
    }
  },
  async created() {
    let { data } = await this.$alimtalkApi.getProfileTemplateDetail(
      this.$route.params.idx
    )
    this.template = data
    this.variables = this.getVariables(this.template)
  },
  methods: {
    getVariables(template) {
      const templateStr = JSON.stringify(template)
      let variables = templateStr.match(/#{([^{}]+)}/g)

      if (variables == null) {
        return []
      }

      variables = variables.map((variable) => variable.replace(/[#{}]/g, ''))
      return Array.from(new Set(variables))
    },
  },
}
</script>

<style></style>
