<template>
  <div>
    <grid
      ref="tuiGrid"
      :data="data"
      :columns="columns"
      :pageOptions="pageOptions"
      :summary="summary"
      @dblclick="onDblClick"
      @click="onClick"
    ></grid>
  </div>
</template>

<script>
import { Grid } from '@toast-ui/vue-grid'

export default {
  props: {
    columns: {
      type: Array,
      default() {
        return []
      },
    },
    data: {
      type: Array,
      default() {
        return []
      },
    },
    options: {
      type: Object,
      default() {
        return {}
      },
    },
    pageOptions: {
      type: Object,
      default() {
        return {}
      },
    },
    summary: {
      type: Object,
      default() {
        return {}
      },
    },
    clickEvent: {
      type: Function,
      default: null,
    },
  },
  components: {
    Grid,
  },
  created() {
    this.options.scrollX = true
    this.options.scrollY = true
  },
  watch: {
    data(newVal) {
      this.$refs.tuiGrid.invoke('resetData', newVal)
    },
  },
  methods: {
    onDblClick(ev) {
      let currentPage = 1
      let perPage = 0
      let pagination = this.$refs.tuiGrid.invoke('getPagination')
      if (!!pagination) {
        currentPage = pagination.getCurrentPage()
        perPage = this.pageOptions.perPage
      }
      let rowKey = ev.rowKey

      let r = rowKey - (currentPage - 1) * perPage

      this.$refs.tuiGrid.invoke('setSelectionRange', {
        start: [r, 0],
        end: [r, this.$refs.tuiGrid.invoke('getColumns').length],
      })
    },
    onClick(ev) {
      if (typeof this.clickEvent === 'function') {
        this.clickEvent(ev)
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
