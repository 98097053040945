import { localize } from 'vee-validate'
import ko from 'vee-validate/dist/locale/ko.json'

localize(
  'ko',
  Object.assign(ko, {
    names: {
      name: '이름',
      id: '아이디',
      pwd: '비밀번호',
      address: '주소',
      detail_address: '상세주소',
      birthdate: '생년월일',
      mobile_carrier: '통신사',
      phone: '핸드폰번호',
      email: '이메일',
      widthraw_amount: '출금',
      remark: '적요',
      alimtalk_title: '제목',
      alimtalk_button_name: '버튼 명',
      alimtalk_button_link: '버튼 링크',
      alimtalk_profile: '프로필',
      alimtalk_template_code: '템플릿 코드',
      alimtalk_template_name: '템플릿 이름',
      alimtalk_template_content: '템플릿 내용',
      amount: '금액',
      title: '제목',
      content: '내용',
    },
    messages: {
      required: (fieldName) => `${fieldName}을(를) 입력해 주세요.`,
      birthdate: (_) => '생년월일을 형식에 맞게 입력해 주세요. 예) 19870131',
      co_kor: (_) => '한글로 입력해 주세요.',
      numeric: (_) => '숫자만 입력 가능합니다.',
      email: (_) => '이메일 형식에 맞게 입력해 주세요.',
      min: (name, obj) => `${obj.length}글자 이상 입력하세요.`,
      max: (name, obj) => `${obj.length}글자 이하로 입력하세요.`,
    },
    fields: {
      alimtalk_profile: {
        required: (_) => '발송 프로필을 선택하세요.',
      },
      widthraw_amount: {
        required: (_) => '출금할 금액을 입력하세요.',
        min_value: (name, obj) => `${obj.min}원부터 출금 신청이 가능합니다.`,
        max_value: (name, obj) =>
          `출금 가능 금액(${obj.max}원)을 초과했습니다.`,
      },
      amount: {
        required: (_) => '금액을 입력하세요.',
        min_value: (name, obj) => `${obj.min}원부터 가능합니다.`,
        max_value: (name, obj) => `가능 금액(${obj.max}원)을 초과했습니다.`,
      },
      remark: {
        min: (name, obj) => `${obj.length}글자 이상 입력하세요.`,
      },
      name: {
        required: (_) => '이름을 입력해 주세요.',
      },
      phone: {
        required: (_) => '핸드폰번호를 입력해 주세요.',
        phone: (_) => '핸드폰번호를 올바르게 입력해 주세요.',
      },
      email: {
        required: (_) => '이메일을 입력해 주세요.',
      },
      mobile_carrier: {
        required: (_) => '통신사를 선택해 주세요.',
      },
      address: {
        required: (_) => '사업장 주소를 선택해 주세요.',
      },
      birthdate: {
        required: (_) => '생년월일을 입력해 주세요.',
      },
      birthdate_dash: {
        required: (_) => '생년월일을 입력해 주세요.',
        birthdate_dash: (_) =>
          '생년월일을 형식에 맞게 입력해 주세요. 예) 1999-01-01',
      },
      code: {
        required: (_) => '자서 대행인 코드를 입력해 주세요.',
        digits: (_) => '6자리로 입력해 주세요.',
      },
    },
  })
)
