<template>
  <v-btn
    type="button"
    height="40"
    :loading="investLoading"
    :disabled="!isValid || investLoading"
    :color="color"
    @click="executeInvest"
  >
    {{ label }}
  </v-btn>
</template>
<script>
import { mapState, mapActions } from 'vuex'
// import { sleep } from '@/util'
export default {
  props: {
    investUser: {
      type: Object,
      default: (_) => {
        return null
      },
    },
    fundingData: {
      type: Object,
      default: (_) => {
        return null
      },
    },
    investAmount: {
      type: Number,
      default: null,
    },
    color: {
      type: String,
      default: 'primary',
    },
    label: {
      type: String,
      default: '투자하기',
    },
    investFn: {
      type: Function,
    },
  },
  computed: {
    ...mapState('marketing/loan', ['investLoading']),
    isValid() {
      return (
        this.investUser &&
        this.fundingData &&
        this.investAmount &&
        this.investAmount > 0 &&
        this.investAmount <=
          this.fundingData.announcedAmount -
            this.fundingData.currentInvestmentAmount
      )
    },
  },
  methods: {
    ...mapActions('marketing/loan', ['invest']),
    async executeInvest() {
      const msg = `
      ${this.investUser.name} 투자자로<br />
      ${this.fundingData.fundingDataIdx}호
      ${this.fundingData.title} 채권에<br />
      ${this.$options.filters.commaFormat(this.investAmount)} 원
      투자하시겠습니까?<br /><br /><br /><br />
    `
      let btnResult = await this.$swal.basic.confirm({
        title: '강제 투자',
        html: msg,
        cancelButtonColor: 'red',
        focusCancel: true,
      })

      if (btnResult.isConfirmed !== true) {
        return
      }
      await this.invest({
        userIdx: this.investUser.idx,
        fundingDataIdx: this.fundingData.fundingDataIdx,
        amount: this.investAmount,
      })
    },
  },
}
</script>
