const state = {
  token: localStorage.getItem('myfinbiz_token') || null,
  user: JSON.parse(localStorage.getItem('myfinbiz_user')) || null,
  codes: JSON.parse(localStorage.getItem('myfinbiz_codes')) || null,
  organization:
    JSON.parse(localStorage.getItem('myfinbiz_organization')) || null,
}

const mutations = {
  SET_TOKEN(state, token) {
    state.token = token
    if (token) {
      localStorage.setItem('myfinbiz_token', token)
    } else {
      localStorage.removeItem('myfinbiz_token')
    }
  },
  SET_USER(state, user) {
    state.user = user
    if (user) {
      localStorage.setItem('myfinbiz_user', JSON.stringify(user))
    } else {
      localStorage.removeItem('myfinbiz_user')
    }
  },
  SET_CODES(state, codes) {
    state.codes = codes
    if (codes) {
      localStorage.setItem('myfinbiz_codes', JSON.stringify(codes))
    } else {
      localStorage.removeItem('myfinbiz_codes')
    }
  },
  SET_ORGANIZATION(state, organization) {
    state.organization = organization
    if (organization) {
      localStorage.setItem(
        'myfinbiz_organization',
        JSON.stringify(organization)
      )
    } else {
      localStorage.removeItem('myfinbiz_organization')
    }
  },
}

const actions = {
  async loginMyfinbiz({ commit }, username) {
    try {
      // 1. 로그인하여 토큰 받기
      const loginResponse = await window.$myfinbizApi.auth.apiLogin(username)
      console.log('Login response:', loginResponse)

      const { accessToken } = loginResponse.data
      // 2. 토큰 저장
      commit('SET_TOKEN', accessToken)

      // 3. 저장된 토큰으로 사용자 정보 가져오기
      const userInfoResponse = await window.$myfinbizApi.auth.getUserInfo()
      console.log('User info response:', userInfoResponse.data.data.data)

      // 4. 사용자 정보 저장
      commit('SET_USER', userInfoResponse.data.data.data)

      // 5. 코드 목록 가져오기
      try {
        const codesResponse = await window.$myfinbizApi.customer.getCodes()
        if (codesResponse.data?.data?.codes) {
          commit('SET_CODES', codesResponse.data.data.codes)
        }
      } catch (error) {
        console.error('Failed to load codes:', error)
      }

      // 6. Organization 정보 가져오기
      try {
        const organizationResponse = await window.$myfinbizApi.customer.getOrganization(userInfoResponse.data.data.data.organizationId)
        if (organizationResponse.data?.data?.data) {
          commit('SET_ORGANIZATION', organizationResponse.data.data.data)
        }
      } catch (error) {
        console.error('Failed to load organization:', error)
      }

      return accessToken
    } catch (error) {
      commit('SET_TOKEN', null)
      commit('SET_USER', null)
      commit('SET_CODES', null)
      commit('SET_ORGANIZATION', null)
      throw error
    }
  },

  logout({ commit }) {
    commit('SET_TOKEN', null)
    commit('SET_USER', null)
    commit('SET_CODES', null)
    commit('SET_ORGANIZATION', null)
  },
}

const getters = {
  isAuthenticated: (state) => !!state.token,
  getToken: (state) => state.token,
  getUser: (state) => state.user,
  getCodes: (state) => state.codes,
  getContractStatusCodes: (state) => {
    if (!state.codes?.fundanow?.ContractsContractStatus) return []
    return state.codes.fundanow.ContractsContractStatus.map((status) => ({
      text: status.label,
      value: status.code,
    }))
  },
  getOrganization: (state) => state.organization,
}

export default {
  name: 'myfinbiz/auth',
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
