var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pt-0",attrs:{"fluid":""}},[_c('PageHeader',{attrs:{"title":"알림톡 템플릿 조회","subtitle":"템플릿 목록 조회"},scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","lg":"5","md":"6","sm":"6"}},[_c('AlimtalkBizmBalance')],1),_c('v-col',{attrs:{"cols":"12","lg":"4","md":"4","sm":"6"}},[_c('v-btn',{attrs:{"text":"","absolute":"","right":"","href":"https://www.bizmsg.kr/template/list","target":"_blank","color":"primary"}},[_vm._v(" 비즈엠 템플릿 목록 바로가기 ")])],1)]},proxy:true}])}),_c('v-card',{staticClass:"my-3",attrs:{"width":"100%"}},[_c('v-card-text',{staticClass:"py-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"2","md":"3","sm":"6"}},[_c('v-btn',{attrs:{"block":"","color":"primary","link":"","large":"","to":{ name: 'alimtalk/templates/form' }}},[_vm._v(" 새 템플릿 등록 ")])],1),_c('v-col',{attrs:{"cols":"12","lg":"2","md":"3","sm":"6"}},[_c('TemplateExcelUploadDialog')],1)],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"items":_vm.templates.data,"loading":_vm.templates.isLoading,"headers":_vm.templates.headers,"page":_vm.templates.paging.page,"items-per-page":_vm.templates.paging.size,"server-items-length":_vm.templates.paging.totalCount,"footer-props":_vm.templates.footer,"options":_vm.templates.options,"show-expand":""},on:{"update:items-per-page":_vm.changeSize,"update:page":_vm.changePage},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('p',{staticClass:"text-center title my-2"},[_vm._v("Loading items...")])]},proxy:true},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","link":"","to":{
          name: 'alimtalk/templates/detail/send/form',
          params: { idx: item.idx },
        },"disabled":!item.isActive}},[_vm._v(" 발송 ")])]}},{key:"item.idx",fn:function(ref){
        var item = ref.item;
return [_c('router-link',{attrs:{"to":{
          name: 'alimtalk/templates/detail',
          params: { templateIdx: item.idx },
        }}},[_vm._v(" "+_vm._s(item.idx)+" ")])]}},{key:"item.createTime",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.createTime,'YYYY-MM-DD HH:mm:ss'))+" ")]}},{key:"item.isActive",fn:function(ref){
        var item = ref.item;
return [_c('span',{class:{ 'error--text': item.isActive == false }},[_vm._v(" "+_vm._s(item.isActive == true ? '사용가능' : '사용불가')+" ")])]}},{key:"item.profileIdx",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("objValue")(item.profileIdx,_vm.profilesObj))+" ")]}},{key:"item.content",fn:function(ref){
        var item = ref.item;
return [(item.content != null && item.content.length < 30)?[_vm._v(" "+_vm._s(item.content)+" ")]:[_vm._v(" "+_vm._s(item.content.substring(0, 30))+"... ")]]}},{key:"expanded-item",fn:function(ref){
        var headers = ref.headers;
        var item = ref.item;
return [_c('td',{staticClass:"py-4 px-8",staticStyle:{"white-space":"break-spaces"},attrs:{"colspan":headers.length}},[_vm._v(" "+_vm._s(item.content)+" ")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }