import AuthService from '@/service/auth/AuthService'

function isSuperAdminUser(to, from, next) {
  if (AuthService.hasAnyRole(['ROLE_SUPERADMIN']) === false) {
    alert('권한없음')
    next(false)
  } else {
    next()
  }
}

let routes = [
  {
    name: 'test',
    path: '/test',
    component: require('@/pages/test/Test.vue').default,
  },
  {
    name: 'test/file',
    path: '/test/file',
    component: require('@/pages/test/FileUploadTest.vue').default,
  },
  {
    name: 'test/smses/send',
    path: '/test/smses/send',
    component: require('@/pages/test/SmsSendTest.vue').default,
  },
  {
    name: 'test/smses',
    path: '/test/smses',
    component: require('@/pages/test/SmsViewTest.vue').default,
  },
  {
    name: 'test/bnk',
    path: '/test/bnk',
    component: require('@/pages/test/BnkTest.vue').default,
  },
]

routes.forEach((router) => {
  router.beforeEnter = (to, from, next) => {
    isSuperAdminUser(to, from, next)
  }
})

export default routes
