import lib from '../lib'
import client from '../fundaApiClient'

// signin만 별도로 운영
export default {
  signinAdmin(params) {
    return client.post('/oauth/token/admin', null, {
      headers: lib.defaultHeader,
      params,
    })
  },
}
