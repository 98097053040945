<template>
  <FileUploadButton
    :isLoading="isLoading"
    singleFile
    :files.sync="file"
    @update:files="updateFile"
    :block="block"
    :text="text"
    :label="label"
  />
</template>
<script>
import XLSX from 'xlsx'
import FileUploadButton from '../form/FileUploadButton.vue'

export default {
  components: { FileUploadButton },
  props: {
    headers: {
      type: Array,
      required: true,
    },
    block: {
      type: Boolean,
      default: false,
    },
    text: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '파일 업로드',
    },
  },
  data() {
    return {
      file: null,
      isLoading: false,
    }
  },
  methods: {
    async updateFile(file) {
      try {
        if (/\.xlsx$/.test(file.name) === false) {
          await this.$swal.basic.error({
            title: '파일 형식 오류',
            text: 'xlsx형식의 파일만 업로드 가능합니다.',
          })
          return
        }

        const rows = await this.readFile(file)
        // 첫 행 제외
        this.$emit('success', rows)
      } catch (e) {
        await this.$swal.basic.error({
          title: '업로드 실패',
          text: e.message,
        })
      }
    },
    readFile(file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader()

        reader.onload = async (e) => {
          let data = reader.result
          let workbook = XLSX.read(data, {
            type: 'binary',
            cellText: false,
            cellDates: true,
            dateNF: 'yyyy-mm-dd',
          })
          const sheet = workbook.Sheets[workbook.SheetNames[0]]
          const rows = XLSX.utils.sheet_to_json(sheet, {
            raw: false,
            // dateNF: 'yyyy-mm-dd',
          })
          // console.log(rows)
          //   const header = rows[0]

          // const content = rows[1]
          if (this.validateHeader(sheet) === false) {
            return reject(new Error('양식에 맞는 파일이 아닙니다'))
          }

          for (let i = 0; i < rows.length; i++) {
            const row = rows[i]
            const RowColumnNames = Object.getOwnPropertyNames(row)
            if (
              RowColumnNames.length < this.headers.length ||
              this.validateRowData(row) === false
            ) {
              return reject(
                new Error(
                  `${
                    i + 1
                  }번째 데이터 오류: 일부 데이터 없음 \n ${JSON.stringify(row)}`
                )
              )
            }
          }

          return resolve(rows)
        }

        reader.readAsBinaryString(file)
      })
    },
    getHeaderRow(sheet) {
      let headers = []
      let range = XLSX.utils.decode_range(sheet['!ref'])
      let R = range.s.r /* start in the first row */
      /* walk every column in the range */
      for (let C = range.s.c; C <= range.e.c; ++C) {
        let cell =
          sheet[
            XLSX.utils.encode_cell({ c: C, r: R })
          ] /* find the cell in the first row */

        let hdr = 'UNKNOWN ' + C // <-- replace with your desired default
        if (cell && cell.t) hdr = XLSX.utils.format_cell(cell)

        headers.push(hdr.trim())
      }
      return headers
    },
    validateHeader(sheet) {
      const headerColumns = this.getHeaderRow(sheet)
      let columnNames = this.headers

      for (let i = 0; i < columnNames.length; i++) {
        const field = columnNames[i].value
        if (headerColumns.find((colName) => field === colName) == null) {
          console.error('양식에 맞는 파일이 아닙니다:notExistColumn', field)
          return false
        }
      }
      return true
    },
    validateRowData(row) {
      let columnNames = this.headers

      for (let i = 0; i < columnNames.length; i++) {
        const field = columnNames[i].value
        const colValue = row[field]
        if (
          colValue == null ||
          colValue.length === 0 ||
          colValue.replace(/ /g, '').length === 0
        ) {
          console.error('일부 데이터 없음', field, row)
          return false
        }
      }
      return true
    },
  },
}
</script>
