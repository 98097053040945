export default {
  callProxyPayment(paymentProxyDto) {
    if (paymentProxyDto.body != null) {
      paymentProxyDto.body = JSON.stringify(paymentProxyDto.body)
    }
    return this.mutation(
      gql`
        {
          data: callProxyPayment(
            paymentProxyDto: { uri: $uri, method: $method, body: $body }
          )
        }
      `,
      'call($uri: String!, $method: String!, $body: String)',
      paymentProxyDto
    )
  },
}
