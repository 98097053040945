export function getVariables(template) {
  const templateStr = JSON.stringify(template)
  let variables = templateStr.match(/#{([^{}]+)}/g)

  if (variables == null) {
    return []
  }

  variables = variables.map((variable) => variable.replace(/[#{}]/g, ''))
  return Array.from(new Set(variables))
}
