<template>
  <v-container fluid class="pt-0">
    <PageHeader
      title="알림톡 발송"
      subtitle="처음 사용하는 템플릿의 경우 테스트 발송을 먼저 진행해주세요."
      color="error"
    >
      <template v-slot:right>
        <v-col>
          <AlimtalkBizmBalance />
        </v-col>
      </template>
    </PageHeader>
    <v-stepper v-model="step" class="mt-3" vertical>
      <v-stepper-step editable step="1">
        <span>
          프로필 및 템플릿 선택
          <v-fade-transition group>
            <v-chip
              v-if="profile.idx != null"
              key="selected-profile"
              color="primary"
              class="mx-2"
              >선택 프로필: {{ profile.name }}</v-chip
            >
            <v-chip
              v-if="template != null"
              color="primary"
              key="selected-template"
              >선택 템플릿: {{ template.name }}</v-chip
            >
          </v-fade-transition>
        </span>
      </v-stepper-step>
      <v-stepper-content step="1">
        <v-row>
          <v-col cols="4" lg="4" md="4" sm="12">
            <ProfileSelect
              v-model="profile.idx"
              class="mb-3"
              @change="profile = $event"
            />
            <v-fade-transition>
              <TemplateSelect
                v-if="profile.idx != null"
                :profile-idx="Number(profile.idx)"
                @change="template = $event"
              />
            </v-fade-transition>
          </v-col>
          <v-fade-transition>
            <v-col v-if="template != null" cols="4" lg="4" md="4" sm="12">
              <TemplatePreview :template="template" :variables="variables" />
            </v-col>
          </v-fade-transition>
        </v-row>
        <v-row>
          <v-col cols="4" lg="4" md="4" sm="12">
            <v-btn
              class="my-3 title"
              color="primary"
              :disabled="profile.idx == null || template == null"
              large
              block
              @click="step = 2"
            >
              다음
            </v-btn>
          </v-col>
        </v-row>
      </v-stepper-content>

      <v-stepper-step
        step="2"
        :editable="profile.idx != null && template != null"
      >
        수신 번호 추가 및 발송 설정
      </v-stepper-step>

      <v-stepper-content
        v-if="profile.idx != null && template != null"
        step="2"
      >
        <AlimtalkTemplateSendForm
          :template="template"
          :variables="variables"
        ></AlimtalkTemplateSendForm>
        <v-row>
          <v-col sm="12" lg="4" md="4" cols="4">
            <v-btn class="my-3 title" large block @click="step = 1">
              이전단계로
            </v-btn>
          </v-col>
        </v-row>
      </v-stepper-content>
    </v-stepper>
  </v-container>
</template>
<script>
import ProfileSelect from '../../components/alimtalk/form/ProfileSelect.vue'
import TemplateSelect from '../../components/alimtalk/template/TemplateSelect.vue'
import TemplatePreview from '../../components/alimtalk/template/TemplatePreview.vue'
import AlimtalkTemplateSendForm from '@/components/alimtalk/template/form/TemplateSendForm'
import AlimtalkBizmBalance from '../../components/alimtalk/bizm/AlimtalkBizmBalance.vue'

export default {
  components: {
    ProfileSelect,
    TemplateSelect,
    TemplatePreview,
    AlimtalkTemplateSendForm,
    AlimtalkBizmBalance,
  },
  data() {
    return {
      step: 1,
      profile: {
        idx: null,
      },
      template: null,
      variables: null,
      receivers: null,
      previewVariableData: null,
      onDrag: false,
      reserveTime: null,
    }
  },
  watch: {
    template(v) {
      // 행 선택 제거
      this.previewVariableData = null
      // 변수 목록 다시 계산
      if (v != null) {
        this.variables = this.getVariables(v)
      } else {
        this.variables = null
      }
      // 수신인 목록 초기화
      if (this.receivers != null && this.receivers.length > 0) {
        this.receivers.splice(0, this.receivers.length)
      }
    },
    receivers() {
      this.previewReceiverData = null
    },
  },
  methods: {
    getVariables(template) {
      const templateStr = JSON.stringify(template)
      let variables = templateStr.match(/#{([^{}]+)}/g)

      if (variables == null) {
        return []
      }

      variables = variables.map((variable) => variable.replace(/[#{}]/g, ''))
      return Array.from(new Set(variables))
    },
  },
}
</script>
