export default [
  {
    name: 'error404',
    path: '*',
    meta: {
      layout: 'EmptyLayout',
    },
    component: require('@/pages/error/404.vue').default,
  },
]
