var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.pages != null && _vm.pages.length > 0)?_c('v-list',{attrs:{"dense":""}},[_c('v-list-group',{attrs:{"value":true},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("최근 방문 페이지")])],1)]},proxy:true}],null,false,1236082005)},_vm._l((_vm.pages),function(page,index){return _c('v-list-item',{key:index,staticClass:"pl-10",class:[
        {
          'router-link-active--false': _vm.activeMenu != page.name,
        },
        {
          'v-list-item--active': _vm.activeMenu == page.name,
        } ],staticStyle:{"min-height":"25px"},attrs:{"to":{
        name: page.name,
        params: page.params,
      },"exact":""}},[_c('v-list-item-content',{staticClass:"py-1"},[_c('v-list-item-title',{staticClass:"font-size--13"},[_vm._v(" "+_vm._s(page.title)+" ")])],1)],1)}),1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }