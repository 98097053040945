import fundaApi from '@/plugins/api/api'
import paymentApi from '@/plugins/api/payment'
// import { sleep } from '@/util'

export default {
  name: 'marketing/loan',
  namespaced: true,
  state: {
    investUser: null,
    fundingDatas: [],
    getFundingDatasLoading: false,
    investLoading: false,
    userVirtualAccount: null,
    investUserBalance: 0,
  },
  mutations: {
    setGetFundingDatasLoading(state, flag) {
      state.getFundingDatasLoading = flag
    },
    setFundingDatas(state, fundingDatas) {
      state.fundingDatas = fundingDatas
    },
    setInvestLoading(state, flag) {
      state.investLoading = flag
    },
    setInvestUser(state, investUser) {
      state.investUser = investUser
    },
    setUserVirtualAccount(state, uva) {
      state.userVirtualAccount = uva
    },
    setInvestUserBalance(state, balance) {
      state.investUserBalance = balance
    },
  },
  actions: {
    async getFundingDatas({ commit, state }) {
      try {
        commit('setGetFundingDatasLoading', true)
        const q = `
              data: getInvestFundingDatas(inputInvestFundingDatas: {
                offset: 1
                size: 10
                type: ""
                ${
                  state.investUser
                    ? 'ignoreListCreditorUserIdx: ' + state.investUser.idx
                    : ''
                }
              }) {
                fundingDataIdx
                title
                subTitle
                visible
                totalAmount
                announcedAmount
                interestRate
                loanPeriod
                investmentStartDate
                investmentClosureDate
                minInvestAmount
                maxInvestAmount
                unitInvestAmount
                status
                useDays
                currentInvestmentAmount
                alreadyInvested
                fundanowType
                currentInvestmentAmount
              }
            `

        const data = await fundaApi.query(gql`{${q}}`)

        commit(
          'setFundingDatas',
          data
          // data.filter((funding) => funding.fundanowType === param.fundingType)
        )
      } finally {
        commit('setGetFundingDatasLoading', false)
      }
    },
    async invest({ commit, dispatch }, obj) {
      try {
        commit('setInvestLoading', true)
        const q = `
          addInvest(inputAddInvestment: {
            userIdx: ${obj.userIdx}
            fundingDataIdx: ${obj.fundingDataIdx}
            amount: ${obj.amount}
            point: 0
            isAutoInvest: false
          }) {
            userIdx
            fundingDataIdx
            investmentAmount
            pointAmount
            investmentState
            datetime
          }
        `
        await fundaApi.mutation(gql`{${q}}`)
        dispatch('getFundingDatas')
        dispatch('getBalance')
      } finally {
        commit('setInvestLoading', false)
      }
    },
    async setInvestUser({ commit, dispatch }, investUser) {
      commit('setInvestUser', investUser)
      if (investUser == null) {
        return
      }

      dispatch('getVitualAccount')
      dispatch('getBalance')
      dispatch('getFundingDatas')
    },
    async getVitualAccount({ commit, state }) {
      const { data } = await paymentApi.getUserVirtualAccountByIdx(
        state.investUser.paymentUser.virtualAccounts[0].idx
      )
      commit('setUserVirtualAccount', data)
    },
    async getBalance({ commit, state }) {
      const q = `
        balance : getMyWithdrawBalance(
          userIdx: ${state.investUser.idx}
        )
      `
      const { balance } = await fundaApi.query(gql`{${q}}`)
      commit('setInvestUserBalance', balance)
    },
    async getUser(userIdx) {
      const q = `
        data: getUserInfo(userIdx: ${userIdx}) {
          idx
          name
          userPhone
          paymentUser {
            userIdx
            virtualAccounts {
              idx
            }
          }
        }
      `
      return await fundaApi.query(gql`{${q}}`)
    },
  },
}
