<template>
  <router-link
    :to="{ name: 'payment/users/detail', params: { userIdx: paymentUserIdx } }"
  >
    paymentUser상세정보
  </router-link>
</template>

<script>
export default {
  props: {
    paymentUserIdx: {
      require: true,
    },
  },
}
</script>
