<template>
  <div id="dashboard" class="container__inner">
    <v-row class="short-text" no-gutters>
      <div class="short-text__background"></div>
      <v-col cols="12" sm="12" md="12" class="short-text__contents">
        <p v-if="!!userInfo" class="mx-0 mb-2">
          <span class="font-weight-bold">{{ userInfo.name }}</span
          >님의 투자 현황!
        </p>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        sm="12"
        md="4"
        lg="4"
        class="dashboard-left-wrapper px-2 position--relative"
      >
        <div class="switch__old-new" @click="isNewLeft = !isNewLeft">
          <p class="ma-0 color--funda-basic font-weight-bold">
            {{ isNewLeft ? '이전 화면 보기 >' : '새로운 화면 보기 >' }}
          </p>
        </div>
        <keep-alive>
          <component
            v-bind:is="isNewLeft ? 'DashboardLeftVer2' : 'DashboardLeft'"
            :my-dashboard-data="myDashboardData"
          ></component>
        </keep-alive>
      </v-col>

      <v-col cols="12" sm="12" md="8" class="dashboard-main-wrapper px-2">
        <v-row no-gutters>
          <v-col cols="12" sm="12" md="12" lg="12">
            <div id="dashboard-top" class="dashboard-box">
              <div
                class="
                  dashboard-menu
                  d-flex
                  justify-space-between
                  width-100
                  ma-0
                  pa-0
                "
              >
                <a
                  v-for="menu in menuList"
                  :key="menu.value"
                  @click="selectedMenu = menu"
                >
                  {{ menu.text }}
                </a>
              </div>
            </div>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="12" sm="12" md="12" lg="12" class="position--relative">
            <keep-alive>
              <component
                v-bind:is="selectedMenu.content"
                :user-idx="userIdx"
                :my-dashboard-data="myDashboardData"
              ></component>
            </keep-alive>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DashboardLeft from '@/components/funda/myfunda/DashboardLeft.vue'
import DashboardLeftVer2 from '@/components/funda/myfunda/DashboardLeftVer2.vue'
import InvestBoard from '@/components/funda/myfunda/InvestBoard.vue'
import Schedule from '@/components/funda/myfunda/repayment/Index.vue'

export default {
  props: {
    userIdx: {
      default: null,
    },
    userInfo: {
      type: Object,
      default: null,
    },
  },
  components: {
    DashboardLeft,
    DashboardLeftVer2,
    InvestBoard,
    Schedule,
  },
  data() {
    return {
      isNewLeft: true,
      myDashboardData: null,
      menuList: [
        {
          text: '투자현황',
          content: 'InvestBoard',
        },
        {
          text: '투자내역',
          content: 'InvestBoard',
        },
        {
          text: '상환내역',
          content: 'Schedule',
        },
        {
          text: '예치금 입출금',
          content: 'InvestBoard',
        },
      ],
      selectedMenu: {
        text: '투자현황',
        content: 'InvestBoard',
      },
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      if (!!this.myDashboardData || this.userInfo === null) {
        return
      }

      const q = `
        data: getMyDashboardData(userIdx: ${this.userIdx}) {
          userIdx
          
          sumAllPoint
          sumExpiredPoint
          sumPointBalance
        }
      `

      this.isLoading = true

      try {
        let data = await this.$fundaApi.query(gql`{${q}}`)

        data.user = {
          ...this.userInfo,
        }

        this.myDashboardData = data
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.switch__old-new {
  position: absolute;
  top: -20px;
  right: 0;
  background-color: white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  box-shadow: 0 8px 16px 0 rgba(23, 25, 26, 0.1);
  cursor: pointer;
  margin-right: 8px;
  padding: 15px;
  z-index: -1;
}
</style>
