export default [
  {
    path: '/funda/notice',
    component: require('@/pages/funda/notice/monthly/Index.vue').default,
    children: [
      {
        name: 'noticeManagement',
        path: '',
        redirect: 'monthly',
      },
      {
        name: 'monthlyNotice',
        path: 'monthly',
        component: require('@/pages/funda/notice/monthly/MonthlyNotice.vue')
          .default,
      },
    ],
  },
  {
    name: 'funda/stats/overdue/amounts',
    path: '/funda/stats/overdue/amounts',
    props: (route) => ({
      query: route.query,
    }),
    meta: { title: '연체율 공시' },
    component: require('@/pages/funda/stats/OverdueAmounts.vue').default,
  },
  {
    name: 'smsManagement',
    path: '/sms',
    meta: { title: 'SMS 관리' },
    component: require('@/pages/sms/SmsManagement.vue').default,
  },
  {
    name: 'pointManagement',
    path: '/point',
    meta: { title: '쿠폰 및 포인트 관리' },
    component: require('@/pages/funda/coupon/Index.vue').default,
  },
  {
    name: 'alarmManagement',
    path: '/alarm',
    meta: { title: '유저 알람 관리' },
    component: require('@/pages/funda/alarm/Index.vue').default,
  },
  {
    name: 'popupManagement',
    path: '/popup',
    meta: { title: '펀다 팝업 관리' },
    component: require('@/pages/funda/popup/Index.vue').default,
  },
]
