<template>
  <v-dialog v-model="dialog" width="1200">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :color="$colors['f-dark-grey']"
        dark
        depressed
        x-large
        block
        v-bind="attrs"
        v-on="on"
      >
        출금 신청 내역
      </v-btn>
    </template>

    <v-card elevation="0">
      <v-card-title>
        출금 신청 내역
        <v-spacer></v-spacer>
        <v-btn color="black" text icon x-large @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="mt-n4">
        <p class="mb-0 f-dark-grey--text">
          <v-icon small>mdi-chevron-right</v-icon>
          <v-chip small :color="$colors['f-blue-grey-lighten-4']"
            >출금요청성공</v-chip
          >&nbsp;:&nbsp;인증 문자 발송 후 인증 번호 입력을 기다리는
          상태&nbsp;/&nbsp;
          <v-chip small :color="$colors['f-blue-grey-lighten-4']"
            >출금성공</v-chip
          >&nbsp;:&nbsp;고객이 올바른 인증 번호를 입력하여 출금이 실행된 상태
        </p>
        <p class="mb-0 f-dark-grey--text">
          <v-icon small>mdi-chevron-right</v-icon>인증 만료 시간이 지나면 인증을
          다시 받아야 합니다.
        </p>
        <p class="mb-0 f-dark-grey--text">
          <v-icon small>mdi-chevron-right</v-icon>웰컴페이먼츠 이관 후 요청 건만
          표시됩니다.
        </p>
      </v-card-text>
      <v-card-text>
        <WithdrawalsTable :paymentUserIdx="paymentUserIdx" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import WithdrawalsTable from './WithdrawalsTable.vue'
export default {
  components: { WithdrawalsTable },
  props: {
    paymentUserIdx: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
    }
  },
}
</script>
