<template>
  <div>
    <v-app-bar dense app :clipped-left="$vuetify.breakpoint.lgAndUp">
      <v-toolbar-title style="width: 300px" class="ml-0 pl-3">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <v-hover v-slot="{ hover }">
          <router-link
            :class="hover ? 'primary--text' : 'black--text'"
            class="hidden-sm-and-down text-decoration-none"
            :to="{ name: 'main' }"
          >
            Funda Admin
          </router-link>
        </v-hover>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon title="signout" @click="signout()">
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer
      app
      v-model="drawer"
      :clipped="$vuetify.breakpoint.lgAndUp"
    >
      <Left />
    </v-navigation-drawer>
  </div>
</template>
<script>
import Left from './Left'
import AuthService from '@/service/auth/AuthService'
export default {
  data() {
    return {
      drawer: null,
    }
  },
  components: {
    Left,
  },
  methods: {
    signout() {
      AuthService.signout()
      this.$router.push({ path: '/signin' })
    },
  },
}
</script>
