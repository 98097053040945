<template>
  <v-container fluid class="pt-0">
    <PageHeader title="콜백 상세" subtitle="콜백 상세" />
    <v-row>
      <v-col cols="12" lg="4" md="4" sm="12">
        <Callback :callbackIdx="callbackIdx" @change="callback = $event" />
        <v-spacer class="my-6" />
        <PageSection
          :title="`잔액 원복 대상 계좌(${vAccount.name}) 잔액`"
          v-if="merchant && vAccount != null && callback.isProcess == false"
        >
          <template slot="content">
            <WelcomeBalance
              :userIdx="Number(vAccount.userIdx)"
              :userVirtualAccountIdx="Number(vAccount.userVirtualAccountIdx)"
              @change="welcomeBalance = $event"
            />
            <v-spacer class="my-3 py-3" />
            <CallbackRollbackButton
              :callbackIdx="callbackIdx"
              :isRollbackable="isRollbackable"
              :defaultRemark="defaultRemark"
              @success="$store.dispatch('time/refresh')"
            />
            <p v-if="isRollbackable == false" class="error--text caption pb-0">
              api가용잔액이 local잔액보다 거래 금액 이상 많아야 실행 가능합니다.
            </p>
          </template>
        </PageSection>
      </v-col>
      <v-col cols="12" lg="4" md="4" sm="12">
        <CallbackPaymentTransaction
          :callbackIdx="callbackIdx"
          @change="transaction = $event"
        />
      </v-col>
      <v-col
        cols="12"
        lg="4"
        md="4"
        sm="12"
        v-if="transaction != null && transaction.userIdx != null"
      >
        <User :userIdx="Number(transaction.userIdx)" />
      </v-col>
    </v-row>
    <v-row v-if="vAccount != null">
      <v-col>
        <PageSection title="가상계좌 거래내역">
          <template slot="content">
            <PaymentVirtualAccountTransactions
              :userIdx="vAccount.userIdx"
              :userVirtualAccountIdx="vAccount.userVirtualAccountIdx"
            />
          </template>
        </PageSection>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import CallbackPaymentTransaction from '../../../components/payment/callback/CallbackPaymentTransaction.vue'
import Callback from '../../../components/payment/callback/Callback.vue'
import User from '../../../components/payment/user/User.vue'
import WelcomeBalance from '../../../components/payment/common/WelcomeBalance.vue'
import CallbackRollbackButton from '../../../components/payment/callback/button/CallbackRollbackButton.vue'
import PaymentVirtualAccountTransactions from '../../../components/payment/PaymentVirtualAccountTransactions.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    Callback,
    CallbackPaymentTransaction,
    User,
    WelcomeBalance,
    CallbackRollbackButton,
    PaymentVirtualAccountTransactions,
  },
  props: {
    callbackIdx: {
      type: Number,
      required: true,
      isLoading: false,
    },
  },
  data() {
    return {
      transaction: null,
      callback: null,
      welcomeBalance: null,
    }
  },
  computed: {
    ...mapGetters('payment/system', {
      merchant: 'getMerchant',
    }),
    isRollbackable() {
      if (
        this.transaction == null ||
        this.callback == null ||
        this.callback.isProcess === true ||
        this.welcomeBalance == null
      ) {
        return false
      }

      const balance = this.welcomeBalance
      // 거래 금액
      const transactionAmount = Number(this.transaction.amount)
      // api 잔액 : 전체 잔액 - 지연이체금액
      const availableApiBalance =
        Number(balance.balanceTotAmt) - Number(balance.pendingTrnsfrAmt)
      // local 잔액
      const localBalance = Number(balance.localBalance)

      // api잔액이 local잔액보다 최소 거래 금액 이상 많아야 됨
      return availableApiBalance - localBalance >= transactionAmount
    },
    vAccount() {
      if (this.transaction == null || this.callback == null) {
        return null
      }

      if (
        this.callback.trnsctnType === 'MERCHANT_BALANCE_CREDITOR_SEND_MONEY'
      ) {
        const merchant = this.merchant
        return {
          name: merchant.label,
          userIdx: merchant.userIdx,
          userVirtualAccountIdx: merchant.idx,
        }
      } else {
        return {
          name: '사용자',
          userIdx: this.transaction.userIdx,
          userVirtualAccountIdx: this.transaction.userVirtualAccountIdx,
        }
      }
    },
    defaultRemark() {
      if (this.transaction == null || this.callback == null) {
        return null
      }

      if (
        this.callback.trnsctnType === 'MERCHANT_BALANCE_CREDITOR_SEND_MONEY'
      ) {
        return '과입금 반환 실패 금액 원복'
      } else if (this.callback.trnsctnType === 'USER_WITHDRAW') {
        return '출금 실패 금액 원복'
      }

      return null
    },
  },
}
</script>
