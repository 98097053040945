import Vue from 'vue'
import lib from './lib'
import signin from './signin'
import proxy from './proxy'
import s3 from './s3'
import account from './account'
import fundanow from './fundanow'
import bnkagent from './bnkagent'

const api = {
  ...lib,
  ...signin,
  ...proxy,
  ...s3,
  ...account,
  ...fundanow,
  ...bnkagent,
}

const pluginName = '$fundaApi'

Vue.use({
  install(Vue) {
    window[pluginName] = api
    Vue.prototype[pluginName] = api
  },
})

export default api
