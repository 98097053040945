export default {
  name: 'errors',
  namespaced: true,
  state: [],
  mutations: {
    add(state, obj) {
      state.push(obj)
    },
    remove(state, obj) {
      state.splice(state.indexOf(obj), 1)
    },
  },
  actions: {
    add({ commit }, obj) {
      commit('add', obj)
    },
    remove({ commit }, obj) {
      commit('remove', obj)
    },
  },
}
