import AuthService from '@/service/auth/AuthService'

function isMarketingLoanAdmin(to, from, next) {
  if (
    AuthService.hasAnyRole([
      'ROLE_MARKETING_LOAN_SUPER_ADMIN',
      'ROLE_SUPERADMIN',
    ]) === false
  ) {
    alert('권한없음')
    next(false)
  } else {
    next()
  }
}

export default [
  {
    name: 'marketing-loan',
    path: '/marketing-loan',
    component: require('@/pages/marketing-loan/AbstractView.vue').default,
    props: (route) => ({ userId: Number(route.params.userId) }),
    redirect: {
      name: 'marketing-loan/loans',
    },
    children: [
      {
        name: 'marketing-loan/loans',
        query: {
          loanTypes: ['marketing'],
          statuses: ['investing', 'invested', 'executing'],
        },
        path: 'loans',
        meta: { title: '마케팅론 목록' },
        props: (route) => ({
          query: route.query,
        }),
        component: require('@/pages/marketing-loan/Loans.vue').default,
      },
      {
        name: 'marketing-loan/account/transactions',
        path: 'account/transactions',
        meta: { title: '테이스티 홀딩스 거래내역' },
        props: (route) => ({
          query: route.query,
        }),
        component: require('@/pages/marketing-loan/AccountTransactions.vue')
          .default,
      },
      {
        name: 'marketing-loan/invest',
        alias: '',
        path: 'invest',
        meta: { title: '투자' },
        component: require('@/pages/marketing-loan/Invest.vue').default,
        beforeEnter: (to, from, next) => {
          isMarketingLoanAdmin(to, from, next)
        },
      },
    ],
  },
]
