import alimtalkApi from '../../plugins/api/alimtalk'

const st = {
  name: 'alimtalk/message/groups',
  namespaced: true,
  state: {
    items: [],
    paging: {},
  },
  getters: {
    getItems(state) {
      return state.items
    },
    getPaging(state) {
      return state.paging
    },
  },
  mutations: {
    setItems(state, { data, paging }) {
      state.items = data
      state.paging = paging
    },
  },
  actions: {
    async setItems({ commit }, param) {
      if (window.popStateDetected === false) {
        const {
          data: { data, paging },
        } = await alimtalkApi.getMessageGroups(param)
        commit('setItems', {
          data,
          paging,
        })
      }
      return true
    },
  },
}

export default st
