import qs from 'query-string'
import client from '../client'

export default {
  getMails(param) {
    return client.call({
      url: `/mails?${qs.stringify(param)}`,
      method: 'GET',
    })
  },
  getMail(mailId) {
    return client.call({
      url: `/mails/${mailId}`,
      method: 'GET',
    })
  },
  getMailAttachments(mailId) {
    return client.call({
      url: `/mails/${mailId}/attachments`,
      method: 'GET',
    })
  },
  getMailAttachmentFile(mailId, attachmentId) {
    return client.call({
      url: `/mails/${mailId}/attachments/${attachmentId}/file`,
      method: 'GET',
    })
  },
  getLoanLinkedToMail(mailId) {
    return client.call({
      url: `/mails/${mailId}/loan`,
      method: 'GET',
    })
  },
}
