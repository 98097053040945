<template>
  <v-card>
    <v-card-title>
      <template v-if="variables.length > 0">
        수신 번호 및 개인화 데이터 목록
      </template>
      <template v-else> 알림톡 수신 번호 목록 </template>
    </v-card-title>
    <v-card-text>
      <v-data-table
        v-if="dataTable.headers.length > 0"
        ref="table"
        :headers="dataTable.headers"
        :items="dataTable.data"
        :items-per-page="-1"
        item-key="localIndex"
        :show-select="variables.length > 0"
        single-select
        v-model="selected"
        @item-selected="$emit('select', $event.item)"
      >
        <template v-slot:top>
          <v-row no-gutters>
            <ExcelDownloadButton :headers="dataTable.headers" />
            <AddButton :variables="variables" @add="insertRow" />
            <v-btn large color="error" @click="deleteAll"> 전체 삭제 </v-btn>
          </v-row>
          <v-row>
            <v-col cols="12">
              <ExcelUploadButton :variables="variables" @add="insertRows" />
            </v-col>
          </v-row>
        </template>
        <template v-slot:no-data>
          <div class="d-flex align-center" style="height: 300px">
            <p class="text-center headline" style="width: 100%">
              수신 번호를 등록하세요.
            </p>
          </div>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-btn color="error" icon small @click="deleteRow(item)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>
<script>
import ExcelDownloadButton from './ExcelDownloadButton.vue'
import ExcelUploadButton from './ExcelUploadButton.vue'
import AddButton from './AddButton.vue'

export default {
  components: { ExcelDownloadButton, ExcelUploadButton, AddButton },
  props: {
    template: {
      type: Object,
      required: true,
    },
    variables: {
      type: Array,
      default: (_) => {
        return []
      },
    },
  },
  data() {
    return {
      dataTable: {
        headers: [
          {
            text: 'mobileNo',
            value: 'mobileNo',
          },
        ],
        data: [],
      },
      selected: [],
      isSelecting: false,
      localIndex: 0,
      templateStr: JSON.stringify(this.template),
    }
  },
  watch: {
    template(v) {
      this.initTable()
      this.templateStr = JSON.stringify(v)
    },
    selected(v) {
      if (v.length === 0) {
        this.$emit('select', null)
      }
    },
  },
  created() {
    this.initTable()
  },
  methods: {
    initTable() {
      // 헤더 초기화
      this.dataTable.headers.splice(1, this.dataTable.headers.length - 1)

      // 내용 초기화
      this.deleteAll()

      // 헤더에 변수 추가
      const variables = this.variables
      if (variables != null && variables.length > 0) {
        variables.forEach((variable) => {
          this.dataTable.headers.push({ text: variable, value: variable })
        })
      }
      this.dataTable.headers.push({ text: '삭제', value: 'actions' })
    },
    insertRow(item) {
      item.localIndex = ++this.localIndex
      item.replacedTemplate = this.getReplacedTemplate(item)

      this.dataTable.data.push(item)
      this.afterInsert()
    },
    insertRows(items) {
      for (let i = 0; i < items.length; i++) {
        const item = items[i]
        item.localIndex = ++this.localIndex
        item.replacedTemplate = this.getReplacedTemplate(item)

        this.dataTable.data.push(item)
      }
      this.afterInsert()
    },
    getReplacedTemplate(item) {
      let replacedStr = this.templateStr
      this.variables.forEach((key) => {
        let regex = new RegExp(`#{${key}}`, 'g')
        replacedStr = replacedStr.replace(regex, item[key])
      })
      return JSON.parse(replacedStr)
    },
    afterInsert() {
      if (this.selected.length === 0 && this.variables.length > 0) {
        this.$refs.table.select(this.dataTable.data[0])
      }
      this.emitChange()
    },
    deleteRow(item) {
      const index = this.dataTable.data.indexOf(item)
      this.dataTable.data.splice(index, 1)
      this.emitChange()
    },
    deleteAll() {
      if (this.dataTable.data.length > 0) {
        this.dataTable.data.splice(0, this.dataTable.data.length)
        this.emitChange()
      }
    },
    emitChange() {
      this.$emit('change', this.dataTable.data)
    },
  },
}
</script>
