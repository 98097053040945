export default [
  {
    name: 'fnGongSi',
    path: '/funda/fundanow/gongsi',
    meta: { title: '펀다나우 공시/자동투자' },
    component: require('@/pages/funda/fundanow/GongSi.vue').default,
  },
  {
    name: 'fnStoreRepayment',
    path: '/funda/fundanow/repayment',
    meta: { title: '펀다나우 상환내역' },
    component: require('@/pages/funda/fundanow/Repayment.vue').default,
  },
  {
    name: 'fnOverdueManagement',
    path: '/funda/fundanow/overdue',
    meta: { title: '펀다나우 연체관리' },
    component: require('@/pages/funda/fundanow/OverdueManagement.vue').default,
  },
]
