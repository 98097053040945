<template>
  <v-simple-table dense>
    <template v-slot:default v-if="vAccount != null">
      <tbody>
        <tr>
          <td>사용자 index</td>
          <td>
            <router-link
              :to="{
                name: 'payment/users/detail',
                params: { userIdx: vAccount.userIdx },
              }"
              target="_blank"
            >
              {{ vAccount.userIdx }}
            </router-link>
          </td>
        </tr>
        <tr>
          <td>가상계좌 index</td>
          <td>{{ vAccount.idx }}</td>
        </tr>
        <tr>
          <td>가상계좌 type</td>
          <!-- <td>{{ vAccount.type | payment.code('UserVirtualAccount.Type') }}</td> -->
        </tr>
        <tr>
          <td>은행</td>
          <td>{{ vAccount.bankCode | bankCodeName }}</td>
        </tr>
        <tr>
          <td>계좌 번호</td>
          <td>{{ vAccount.accountNo }}</td>
        </tr>
        <tr>
          <td>계좌 명</td>
          <td>{{ vAccount.accountName }}</td>
        </tr>
        <tr>
          <td>잔액</td>
          <td>{{ vAccount.balance | commaFormat }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  props: {
    userIdx: {
      required: true,
    },
    userVirtualAccountIdx: {
      required: true,
    },
  },
  data() {
    return {
      vAccount: null,
      isLoading: false,
    }
  },
  watch: {
    userVirtualAccountIdx(v) {
      this.getVirtualAccount()
    },
  },
  created() {
    this.getVirtualAccount()
  },
  methods: {
    async getVirtualAccount() {
      if (this.userVirtualAccountIdx == null) {
        return
      }
      try {
        this.isLoading = true

        const { data } = await this.$paymentApi.getUserVirtualAccount(
          this.userVirtualAccountIdx
        )
        this.vAccount = data
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
<style lang="scss" scoped>
tr > td:last-child {
  word-break: break-all;
}
</style>
