export default [
  {
    name: 'main',
    path: '/',
    beforeEnter(to, from, next) {
      console.log('main beforEnter')
      next()
    },

    component: require('@/pages/main/Main.vue').default,
  },
  {
    path: '/myfinbiz',
    component: () => import('@/pages/myfinbiz/customers.vue'),
    name: 'myfinbiz/customers',
    meta: {
      auth: ['ROLE_SUPERADMIN', 'ROLE_ADMIN']
    }
  },
  {
    path: '/myfinbiz/early-payments',
    component: () => import('@/pages/myfinbiz/early-payments.vue'),
    name: 'myfinbiz/early-payments',
    meta: {
      auth: ['ROLE_SUPERADMIN', 'ROLE_ADMIN']
    }
  },
  {
    path: '/myfinbiz/accounting',
    component: () => import('@/pages/myfinbiz/accounting.vue'),
    name: 'myfinbiz/accounting',
    meta: {
      auth: ['ROLE_SUPERADMIN', 'ROLE_ADMIN']
    }
  },
  {
    path: '/myfinbiz/settings',
    component: () => import('@/pages/myfinbiz/settings.vue'),
    name: 'myfinbiz/settings',
    meta: {
      auth: ['ROLE_SUPERADMIN', 'ROLE_ADMIN']
    }
  },
  {
    path: '/myfinbiz/login',
    component: () => import('@/pages/myfinbiz/login.vue'),
    name: 'myfinbiz/login',
    meta: {
      auth: ['ROLE_SUPERADMIN', 'ROLE_ADMIN']
    }
  },
  {
    path: '/myfinbiz/customer/:id',
    component: () => import('@/pages/myfinbiz/customer/detail.vue'),
    name: 'myfinbiz/customer/detail',
    props: true,
    meta: {
      auth: ['ROLE_SUPERADMIN', 'ROLE_ADMIN']
    }
  },
  {
    path: '/myfinbiz/customer/:customerId/settlement/:settlementId',
    component: () => import('@/pages/myfinbiz/customer/components/SettlementDetail.vue'),
    name: 'myfinbiz/customer/settlement/detail',
    props: (route) => ({
      customerId: Number(route.params.customerId),
      settlementId: route.params.settlementId,
      date: route.query.date
    }),
    meta: {
      auth: ['ROLE_SUPERADMIN', 'ROLE_ADMIN']
    }
  },
]
