import store from '@/store'
import Vue from 'vue'
import Router from 'vue-router'
import _interceptor from './_interceptor'
const { isNavigationFailure, NavigationFailureType } = Router

const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject)
  }
  return originalPush.call(this, location).catch((err) => {
    if (isNavigationFailure(err, NavigationFailureType.cancelled)) {
      console.log(err, 'NavigationFailureType.cancelled')
      return
    }
    // if (err === undefined || err === false) {
    //   // next('/other') next(false) 고려..
    //   // console.log(err, 'location')
    //   Vue.config.errorHandler(err)
    // } else {
    //   // console.error(err, 'location')
    //   Vue.config.errorHandler(err)
    // }
    Vue.config.errorHandler(err)
  })
}

const routers = [
  require('./main'),
  require('./error'),
  require('./about'),
  require('./signin'),
  require('./signout'),
  require('./payment'),
  require('./design'),
  require('./alimtalk'),
  require('./test'),
  require('./accounting'),
  require('./funda/user'),
  require('./funda/fundanow'),
  require('./funda/funding'),
  require('./funda/etc'),
  require('./fundanow/user'),
  require('./fundanow/loan'),
  require('./fundanow/repayment'),
  require('./fundanow/mail'),
  require('./bank/inspection'),
  require('./marketing-loan'),
  require('./second-mortgage-loan'),
  require('./ai/smart-store'),
]

Vue.use(Router)

let mergeRouters = []
routers.forEach((route) => {
  mergeRouters = mergeRouters.concat(route.default)
})

const vueRouter = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: mergeRouters,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

_interceptor.forEach((f) => {
  vueRouter.beforeEach(f.default)
})

vueRouter.afterEach((to, from) => {
  store.commit('admin/setVisitPages', to)
})

export default vueRouter
