<template>
  <div>
    <template v-if="loan.status === 'investing'">
      <span v-if="investAmount != null" style="display: flex">
        <div class="mt-1 mr-3">
          <v-progress-circular
            :value="(investAmount / loan.amount) * 100"
            stream
            color="primary"
          >
          </v-progress-circular>
        </div>
        <div>
          <p class="mb-0">
            모집 {{ investAmount | commaFormat }} 원
            <span class="font-size--12"
              >({{ ((investAmount / loan.amount) * 100).toFixed(2) }} %)</span
            >
          </p>
          <p class="mb-1">신청 {{ loan.amount | commaFormat }} 원</p>
        </div>
      </span>
    </template>
    <template v-else> {{ loan.amount | commaFormat }} 원</template>
  </div>
</template>
<script>
export default {
  props: {
    loan: {
      type: Object,
      required: true,
    },
    fundaPaygateLoanId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      investAmount: null,
    }
  },
  watch: {
    loan: {
      deep: true,
      async handler() {
        console.log('handler!!!!!!!!!!!!!!')
        await this.getLoanAmountData()
      },
    },
  },
  async created() {
    await this.getLoanAmountData()
  },
  methods: {
    async getLoanAmountData() {
      if ((this.loan.status === 'investing' || this.loan.status === 'invested') && this.fundaPaygateLoanId) {
        const resp = await this.$fundaApi.getLoanInvestAmount(
          this.fundaPaygateLoanId
        )
        this.investAmount = resp.data != null ? resp.data : resp
      }
    },
  },
}
</script>
