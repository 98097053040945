import './polifill'
import Vue from 'vue'
import './config'
import './const'
import './directive'
import './filter'
import './validator'

import Plugins from './plugins'
import router from './router'
import store from './store'

import App from './components/App'
import './service/InitService' // 최초 로딩시 init을 담당

Vue.config.productionTip = false

global.$vm = new Vue({
  router,
  store,
  vuetify: Plugins.vuetify,
  render: (h) => h(App),
}).$mount('#app')
