<template>
  <v-select
    :items="systemUsers"
    item-text="label"
    item-value="idx"
    :label="label"
    outlined
    :disabled="isDisabled || systemUsers.length === 0"
    :loading="systemUsers.length === 0"
    return-object
    hide-details
    @change="selectInvestUser"
    :value="investUser ? investUser.idx : ''"
  >
  </v-select>
</template>
<script>
import { mapState, mapActions } from 'vuex'
export default {
  props: {
    label: {
      type: String,
      default: '투자용 유저 선택',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // 마케팅론 투자용 고객idx
      systemUsers: [],
      investUserIdxs: [
        41095, 41109, 41104, 41100, 47524, 37828, 47378, 47859, 47969, 48018,
      ],
    }
  },
  computed: {
    ...mapState('marketing/loan', ['investUser']),
  },
  async created() {
    this.systemUsers = await Promise.all(
      this.investUserIdxs.map((userIdx) => this.getUser(userIdx))
    )
    this.systemUsers.forEach(
      (user) => (user.label = `(${user.idx}) ${user.name}`)
    )
  },
  methods: {
    ...mapActions('marketing/loan', ['setInvestUser']),
    async getUser(userIdx) {
      const q = `
        data: getUserInfo(userIdx: ${userIdx}) {
          idx
          name
          userPhone
          paymentUser {
            userIdx
            virtualAccounts {
              idx
            }
          }
        }
      `
      return await this.$fundaApi.query(gql`{${q}}`)
    },
    selectInvestUser(investUser) {
      this.setInvestUser(investUser)
      this.$emit('select', investUser)
    },
  },
}
</script>
