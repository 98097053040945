import qs from 'query-string'
import client from '../client'

/**
 * encode 두번되어 들어오는 현상때문에(proxy api에서 url을 body로 받아서?)
 * query-string stringify 할 때 encode false 로 설정;
 */
export default {
  /**
   * 비상금 신청 목록 조회
   * @param {*} param
   * @returns
   */
  getFunds(param) {
    return client.call({
      url: `/funds?${qs.stringify(param, { encode: false })}`,
      method: 'GET',
    })
  },
  getBankFees(params) {
    return client.call({
      url: '/api/myfinbiz/bank-fees',
      method: 'GET',
      params,
    })
  },
}
