<template>
  <v-container fluid class="pt-0">
    <PageHeader
      title="상환"
      subtitle="상환 실행2  해야되는 목록?(임시)"
      tabMenu
    >
    </PageHeader>
    <v-btn
      @click="executeStep2"
      :disabled="payoutReserves.length < 1 || isLoading"
      :loading="isLoading"
      color="primary"
    >
      {{ payoutReserves.length }} 건 실행
    </v-btn>
    <PayoutReservesStep2Table
      :selectedItems.sync="payoutReserves"
    />
  </v-container>
</template>
<script>
import PayoutReservesStep2Table from '@/components/payment/payout/reserve/PayoutReservesStep2Table.vue'

export default {
  components: { PayoutReservesStep2Table },
  data() {
    return {
      payoutReserves: [],
      isLoading: false
    }
  },
  methods: {
    async executeStep2() {
      if (this.isLoading) {
        return
      }
      try {
        this.isLoading = true
        for (let i = 0; i < this.payoutReserves.length; i++) {
          const payoutReserve = this.payoutReserves[i]
          // console.log(payoutReserve.idx)
          await this.$paymentApi.postPayoutStep2(payoutReserve.idx)
        }
        await this.$swal.basic.alert({
          title: '완료',
          text: '처리 완료되었습니다.'
        })
      } finally {
        this.isLoading = false
      }
    }
  },
}
</script>
