<template>
  <v-badge color="red" :content="countInvestorAuth" inline>
    <slot></slot>
  </v-badge>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  props: {
    countType: {
      type: String,
      default: 'countInvestorAuth',
    },
  },
  computed: {
    ...mapGetters('count', {
      countInvestorAuth: 'getInvestorAuth',
    }),
  },
  created() {
    this.getCountInvestorAuth()
  },
  methods: {
    ...mapActions('count', {
      getCountInvestorAuth: 'getInvestorAuth',
    }),
  },
}
</script>

<style lang="scss" scoped></style>
