<template>
  <v-card
    :color="$colors[onDrag ? 'f-blue-grey-lighten-3' : 'f-white-blue-2']"
    flat
    class="upload-box"
    :style="`border: 1px dotted ${$colors['f-blue-grey-lighten-1']}`"
    @dragenter.prevent.stop="onDrag = true"
    @drop.prevent="drop"
  >
    <div
      v-if="onDrag == true"
      class="upload-box__drag-wrapper"
      @dragleave.prevent.stop="onDrag = false"
      @dragover.prevent
    ></div>
    <v-card-subtitle
      class="
        title
        text-center
        font-weight-bold
        pt-6
        pb-0
        f-blue-grey-lighten-1--text
      "
    >
      drag and drop file here
    </v-card-subtitle>
    <v-card-subtitle class="text-center pt-0 pb-2">or</v-card-subtitle>
    <v-card-text class="text-center pb-6">
      <v-btn
        large
        :loading="isSelecting"
        color="primary"
        elevation="0"
        rounded
        @click="clickUploadButton"
      >
        수신 번호 추가 - 엑셀 업로드
        <v-icon right dark> mdi-cloud-upload</v-icon>
      </v-btn>
      <input
        ref="uploader"
        class="d-none"
        type="file"
        accept=".xlsx"
        @change="uploadExcel($event.target.files[0])"
      />
    </v-card-text>
  </v-card>
</template>
<script>
import XLSX from 'xlsx'

export default {
  props: {
    variables: {
      type: Array,
      default: (_) => {
        return []
      },
    },
  },
  data() {
    return {
      isSelecting: false,
      columnNames: ['mobileNo', ...this.variables],
      onDrag: false,
    }
  },
  methods: {
    clickUploadButton() {
      this.isSelecting = true
      window.addEventListener(
        'focus',
        () => {
          this.isSelecting = false
        },
        {
          once: true,
        }
      )

      this.$refs.uploader.click()
    },
    async uploadExcel(file) {
      this.isSelecting = true
      try {
        if (/\.xlsx$/.test(file.name) === false) {
          await this.$swal.basic.error({
            title: '파일 형식 오류',
            text: 'xlsx형식의 파일만 업로드 가능합니다.',
          })
          return
        }

        await this.readFile(file)
      } catch (e) {
        await this.$swal.basic.error({
          title: '업로드 실패',
          text: e.message,
        })
      } finally {
        this.$refs.uploader.value = null
        this.isSelecting = false
      }
    },
    readFile(file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader()

        reader.onload = (e) => {
          let data = reader.result
          let workbook = XLSX.read(data, {
            type: 'binary',
            // cellText: false,
            cellDates: true,
            dateNF: 'yyyy-mm-dd',
          })

          // 파일 양식 검사
          const sheet = workbook.Sheets[workbook.SheetNames[0]]
          if (this.isValidTemplate(sheet) === false) {
            return reject(new Error('양식에 맞는 파일이 아닙니다'))
          }

          // 데이터 있는 지 검사
          const rows = XLSX.utils.sheet_to_json(sheet, {
            raw: false,
            // dateNF: 'yyyy-mm-dd',
          })
          if (this.isNotEmptyTemplate(rows) === false) {
            return reject(new Error('추가할 항목이 없습니다'))
          }

          let errorRows = []
          let successRows = []
          for (let i = 0; i < rows.length; i++) {
            const row = rows[i]
            const RowColumnNames = Object.getOwnPropertyNames(row)
            if (
              RowColumnNames.length < this.columnNames.length ||
              this.isValidRow(row) === false
            ) {
              row.error_reason = '일부 데이터 없음'
              errorRows.push(row)
              continue
            }

            // 0으로 시작하지 않으면 앞에 0붙여줌
            if (row.mobileNo.toString().indexOf(0) !== 0) {
              row.mobileNo = '0' + row.mobileNo
            }

            // 숫자 외 문자 제거
            row.mobileNo = row.mobileNo.replace(/[^\d]/g, '')

            if (this.isValidMobileNo(row.mobileNo) === false) {
              row.error_reason = '올바른 핸드폰 번호가 아님'
              errorRows.push(row)
              continue
            }

            successRows.push(row)
          }

          this.$emit('add', successRows)
          if (errorRows.length > 0) {
            this.downloadErrorReport(errorRows)
            return reject(
              new Error(`전체 ${rows.length}개 중 ${errorRows.length}개의 데이터에 문제가 있어 제외되었습니다.\n
              자세한 사항은 다운로드된 파일을 확인하세요.
            `)
            )
          } else {
            return resolve()
          }
        }

        reader.readAsBinaryString(file)
      })
    },
    isNotEmptyTemplate(rows) {
      if (rows.length === 0) {
        return false
      } else if (rows.length === 1) {
        const row = rows[0]
        return (
          Object.getOwnPropertyNames(row).every((col) => {
            return row[col] === ''
          }) === false
        )
      }

      return true
    },
    isValidTemplate(sheet) {
      const headerColumns = this.getHeaderRow(sheet)
      let columnNames = this.columnNames

      for (let i = 0; i < columnNames.length; i++) {
        const field = columnNames[i]
        if (headerColumns.find((colName) => field === colName) == null) {
          console.error('양식에 맞는 파일이 아닙니다:notExistColumn', field)
          return false
        }
      }
      return true
    },
    isValidRow(row) {
      let columnNames = this.columnNames

      for (let i = 0; i < columnNames.length; i++) {
        const field = columnNames[i]
        const colValue = row[field]
        if (
          colValue == null ||
          colValue.length === 0 ||
          colValue.replace(/ /g, '').length === 0
        ) {
          console.error('일부 데이터 없음', field, row)
          return false
        }
      }
      return true
    },
    isValidMobileNo(value) {
      if (value == null) {
        return false
      }
      let regex = /^01\d{8,9}$/
      return regex.test(value)
    },
    getHeaderRow(sheet) {
      let headers = []
      let range = XLSX.utils.decode_range(sheet['!ref'])
      let R = range.s.r /* start in the first row */
      /* walk every column in the range */
      for (let C = range.s.c; C <= range.e.c; ++C) {
        let cell =
          sheet[
            XLSX.utils.encode_cell({ c: C, r: R })
          ] /* find the cell in the first row */

        let hdr = 'UNKNOWN ' + C // <-- replace with your desired default
        if (cell && cell.t) hdr = XLSX.utils.format_cell(cell)

        headers.push(hdr.trim())
      }
      return headers
    },
    downloadErrorReport(errorRows) {
      const sheet = XLSX.utils.json_to_sheet(errorRows)
      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, sheet, 'error_report')
      XLSX.writeFile(
        workbook,
        `${this.$moment().format('YYMMDD_HHmmss')}_error_report.xlsx`
      )
    },
    drop(e) {
      if (e.dataTransfer.files.length > 0) {
        this.uploadExcel(e.dataTransfer.files[0])
        this.onDrag = false
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.upload-box {
  transition: all 0.5s;
  position: relative;

  &__drag-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
  }
}
</style>
