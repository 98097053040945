<template>
  <div class="my-balance-deposit-table width-100">
    <div class="flexible-table width-100">
      <div class="header-row">
        <div
          class="header font-weight--black justify-center"
          v-for="(header, index) in headers"
          :key="index"
          :style="{ width: header.width }"
        >
          {{ header.name }}
        </div>
      </div>
      <div
        v-if="data === null || data.length < 1 || isLoading"
        class="d-flex justify-center align-items--center mt-5"
        style="height: 226px"
      >
        <transition name="fade1" mode="out-in">
          <v-progress-circular
            v-if="isLoading"
            indeterminate
            color="primary"
          ></v-progress-circular>
          <div
            v-else
            key="empty"
            class="text-center font-weight--bold margin-x-auto width-100"
          >
            <p class="color--gray-2 margin-t-10 font-size--16">
              거래내역이 없습니다.
            </p>
          </div>
        </transition>
      </div>
      <div
        v-else
        class="body-row d-flex"
        v-for="(row, index) in data"
        :key="index"
        ref="bodyrow"
      >
        <slot name="col" :row="row"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default() {
        return []
      },
    },
    headers: {
      type: Array,
      default() {
        return []
      },
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style lang="scss" scoped></style>
