export default [
  {
    name: 'signoutAuto',
    path: '/signout/auto',
    meta: {
      layout: 'EmptyLayout',
    },
    component: require('@/pages/signout/Auto.vue').default,
  },
]
