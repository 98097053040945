export default {
  channels: [
    {
      uuid: 'fundainvest',
      name: '펀다_투자',
      senderKey: '882d8f3735aa40b853e41f4eacaca575fa54aaaa',
    },
    {
      uuid: 'salesalarm',
      name: '펀다 - 매출알리미',
      senderKey: '32be4e2707939005342375ec011852384101d214',
    },
    {
      uuid: 'fundanow',
      name: '펀다_나우',
      senderKey: 'f7640da2e52f7bda9f216ad018e6c2d831f1025e',
    },
  ],
  templates: {
    fundainvest: [
      {
        idx: 100001,
        id: 'INV_VOTE1',
        name: 'INV_VOTE1',
        content: `안녕하세요 펀다입니다.

        동산 [그룹1] 채권의 매각 진행 동의 투표 참여 부탁드립니다.
        
        감사합니다.`,
      },
      {
        idx: 100002,
        id: 'EXCEL_TEST1',
        name: 'INV_VOTE1',
        content: `#{name} 대표님, 펀다 심사팀입니다.
        #{loan_register_date}에 신청하신 대출심사 건이 최종 부결되었습니다.
        도움을 드리지 못해 진심으로 죄송합니다.
        펀다에 관심 가져주셔서 감사합니다.`,
      },
      {
        idx: 100003,
        id: 'INV_VOTE2',
        name: '테스트',
        content: `#{name} 대표님, 펀다 심사팀입니다.

        펀다 심사팀입니다.펀다 심사팀입니다. #{loan_register_date}에 신청하신 대출심사 건이 최종 부결되었습니다.
        도움을 드리지 못해 진심으로 죄송합니다.
        펀다에 관심 가져#{name}주셔서 감사합니다.`,
        buttons: [
          {
            idx: 1,
            profileTemplateIdx: 1,
            link: 'https://forms.gle/CM5Ntk1yB11DX8j17?#{param}&a=1',
            name: '설문조사 바로가기',
            type: 'WL',
          },
        ],
      },
      {
        idx: 100004,
        id: 'INV_VOTE3',
        name: '테스트222',
        content: `[펀다] 배달 매출 연동 안내

        안녕하세요, #{이름} 사장님 !
        
        #{문구1} 상점의 오늘 한도 : #{문구2}원
        배달 매출을 연동하면 한도가 최대 200% 상향 된다는 사실 알고 계셨나요?
        배달 매출로 사장님도 한도 올려 받으세요 !
        
        "자영업자 전문P2P FUNDA"`,
        buttons: [
          {
            idx: 1,
            profileTemplateIdx: 1,
            link: 'http://bit.ly/3aiA12A',
            name: '연동하고 한도 올리기',
            type: 'WL',
          },
        ],
      },
      {
        idx: 100005,
        id: 'BM',
        name: '변수테스트',
        content: `[펀다] 배달 매출 연동 안내

        안녕하세요, #{이름} 사장님 !
        
        #{문구1} 상점의 오늘 한도 : #{문구2}원
        배달 매출을 연동하면 한도가 최대 200% 상향 된다는 사실 알고 계셨나요?
        배달 매출로 사장님도 한도 올려 받으세요 !
        
        "자영업자 전문P2P FUNDA"`,
        buttons: [
          {
            idx: 1,
            profileTemplateIdx: 1,
            link: 'http://bit.ly/3aiA12A',
            name: '연동하고 한도 올리기',
            type: 'WL',
          },
        ],
      },
    ],
  },
}
