<template>
  <v-container fluid class="pt-0">
    <PageHeader title="연체율 공시" subtitle="연체율 공시 조회">
      <template v-slot:right>
        <v-spacer />
        <v-col
          cols="3"
          lg="3"
          md="3"
          sm="12"
          class="d-flex align-center justify-end title"
        >
          <v-btn
            text
            absolute
            href="https://www.funda.kr"
            target="_blank"
            color="primary"
          >
            펀다 바로가기
          </v-btn>
        </v-col>
        <v-col cols="3" lg="3" md="3" sm="12" align-self="center">
          <OverdueAmountDialogButton @success="refreshTable" />
        </v-col>
      </template>
    </PageHeader>
    <OverdueAmountsTable :isQueryPaging="true" :query="query" :key="tableKey" />
  </v-container>
</template>
<script>
import OverdueAmountDialogButton from '../../../components/funda/stats/OverdueAmountDialogButton.vue'
import OverdueAmountsTable from '../../../components/funda/stats/OverdueAmountsTable.vue'

export default {
  components: { OverdueAmountsTable, OverdueAmountDialogButton },
  props: {
    query: {
      type: Object,
      default: (_) => {
        return {}
      },
    },
  },
  data() {
    return {
      tableKey: 'OverdueAmountsTable',
    }
  },
  methods: {
    refreshTable() {
      this.tableKey = 'OverdueAmountsTable' + Date.now()
    },
  },
}
</script>
