<template>
  <v-container fluid class="pt-0">
    <PageHeader title="가상계좌 목록 조회" subtitle="가상계좌 목록 조회">
      <template v-slot:right>
        <v-col cols="4" lg="4" mg="4" sm="4" align-self="center" class="pr-0">
          <div class="text-right font-weight-bold">거래내역 바로가기</div>
        </v-col>
        <v-col cols="4" lg="4" mg="4" sm="4">
          <PaymentUserVirtualAccountAutocomplete
            :dense="true"
            @select="shortcut"
          />
        </v-col>
        <v-col cols="4" lg="4" mg="4" sm="4">
          <SystemUserSelect
            label="시스템 유저 거래내역 바로가기"
            :dense="true"
            @change="shortcut"
          />
        </v-col>
      </template>
    </PageHeader>
    <PaymentUserVirtualAccountsTable :isQueryPaging="true" />
  </v-container>
</template>
<script>
import PaymentUserVirtualAccountsTable from '../../../../components/payment/user/VirtualAccountsTable.vue'
import PaymentUserVirtualAccountAutocomplete from '../../../../components/payment/user/VirtualAccountAutocomplete.vue'
import SystemUserSelect from '../../../../components/payment/common/select/SystemUserSelect.vue'

export default {
  components: {
    PaymentUserVirtualAccountsTable,
    SystemUserSelect,
    PaymentUserVirtualAccountAutocomplete,
  },
  methods: {
    shortcut(e) {
      if (e == null) {
        return
      }
      this.$router.push({
        name: 'payment/users/virtual/accounts/transactions',
        params: {
          userIdx: e.userIdx,
          userVirtualAccountIdx: e.idx,
        },
      })
    },
  },
}
</script>
