<template>
  <span>
    <template v-if="guid">{{ guid }}</template>
  </span>
</template>
<script>
export default {
  props: {
    userVirtualAccountIdx: {
      type: Number,
      required: true,
    },
    userIdx: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      guid: null,
      isLoading: false,
    }
  },
  async created() {
    try {
      this.isLoading = true

      const { data } = await this.$paymentApi.getWelcomeUser(
        this.userIdx,
        this.userVirtualAccountIdx
      )
      this.guid = data.guid
    } finally {
      this.isLoading = false
    }
  },
}
</script>
