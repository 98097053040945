<template>
  <v-card class="pa-3" width="100%" outlined>
    <v-row no-gutters>
      <v-col cols="12" sm="12" md="12">
        <h4 class="ma-0 text-center">포인트 지급</h4>
      </v-col>
    </v-row>

    <v-row no-gutters class="mb-4">
      <v-col cols="12" sm="12" md="12" class="py-0">
        <h4 class="ma-0">선택된 쿠폰</h4>
      </v-col>

      <v-col cols="12" sm="12" md="12">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-center">인덱스</th>
                <th width="50%" class="text-center">쿠폰명</th>
                <th class="text-center">금액</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="coupon">
                <tr>
                  <td class="text-center">
                    {{ coupon.idx }}
                  </td>
                  <td class="text-center">
                    {{ coupon.title }}
                  </td>
                  <td class="text-center">
                    {{ coupon.amount | commaFormat }}
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="3">이곳에 선택한 쿠폰이 표시됩니다.</td>
                </tr>
              </template>
            </tbody>
          </template>
        </v-simple-table>
        <p v-if="!!coupon && !usableCoupon" class="ma-0 mt-1 red--text">
          종료 상태의 쿠폰은 사용할 수 없습니다.
        </p>
      </v-col>
    </v-row>

    <v-row no-gutters class="mb-4">
      <v-col cols="12" sm="12" md="12" class="py-0">
        <h4 class="ma-0">선택된 유저</h4>
      </v-col>

      <v-col cols="12" sm="12" md="12">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-center">인덱스</th>
                <th width="50%" class="text-center">아이디</th>
                <th class="text-center">이름</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="user">
                <tr>
                  <td class="text-center">
                    {{ user.idx }}
                  </td>
                  <td class="text-center">
                    {{ user.id }}
                  </td>
                  <td class="text-center">
                    {{ user.name }}
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="3">이곳에 선택한 유저가 표시됩니다.</td>
                </tr>
              </template>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col cols="12" sm="12" md="12" class="py-0">
        <h4 class="ma-0 mb-2">쿠폰지급사유:</h4>
      </v-col>
      <v-col cols="12" sm="12" md="12">
        <v-textarea
          v-model="provideForm.memo"
          placeholder="ex) 고객이 이벤트에 당첨되었다"
          hide-details
          outlined
          rows="2"
        ></v-textarea>
      </v-col>
    </v-row>

    <div>
      <v-divider class="mx-4"></v-divider>

      <v-row no-gutters>
        <v-col cols="9" class="text-center">
          <v-btn
            :loading="isLoading"
            :disabled="isDisabled"
            color="#393939"
            class="white--text"
            center
            depressed
            @click="providePoint()"
          >
            포인트 지급
          </v-btn>
        </v-col>

        <v-col cols="3" class="text-right">
          <v-dialog v-model="multipleDialog" width="1200px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                center
                depressed
                v-bind="attrs"
                v-on="on"
                :disabled="provideForm.memo.length < 5"
                @click="multipleDialog = !multipleDialog"
              >
                엑셀로 포인트 지급
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline"> 포인트 멀티 지급 </span>
              </v-card-title>
              <v-card-text>
                <UserTableFromExcel
                  :excelHeader.sync="excelHeader"
                  :excelTable.sync="excelTable"
                />
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  class="mr-5"
                  color="primary"
                  center
                  depressed
                  :loading="isLoading"
                  :disabled="isLoading || excelTable.length < 1"
                  @click="providePoint(true)"
                >
                  포인트 지급
                </v-btn>

                <v-btn dark @click="multipleDialog = false"> 닫기 </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  props: {
    coupon: {
      type: Object,
      default: null,
    },
    user: {
      default: null,
    },
  },
  components: {
    UserTableFromExcel: () =>
      import('@/components/common/datatable/UserTableFromExcel.vue'),
  },
  data() {
    return {
      isLoading: false,
      adminIdx: null,
      provideForm: {
        memo: '',
      },
      multipleDialog: false,
      excelHeader: [],
      excelTable: [],
    }
  },
  computed: {
    ...mapGetters('auth', {
      adminUser: 'getProfile',
    }),
    usableCoupon() {
      return this.coupon?.status === '사용중'
    },
    isDisabled() {
      if (this.isLoading) {
        return true
      }

      return !(
        !!this.adminIdx &&
        !!this.user?.idx &&
        !!this.coupon?.idx &&
        this.usableCoupon &&
        this.provideForm.memo.length > 5
      )
    },
  },
  watch: {
    adminUser: {
      immediate: true,
      deep: true,
      handler(v) {
        this.adminIdx = v?.idx
      },
    },
  },
  created() {
    this.getAdminProfile()
  },
  methods: {
    ...mapActions('auth', {
      getAdminProfile: 'getProfile',
    }),
    getParams(isMultiple = false) {
      let result = null
      const param = {
        adminIdx: this.adminIdx,
        memo: this.provideForm.memo,
        couponDataIdx: null,
        userIdx: null,
      }

      if (isMultiple === true) {
        const userKey = this.excelHeader[0]
        const couponKey = this.excelHeader[1]
        const params = this.excelTable.map((table) => {
          const p = { ...param }
          p.userIdx = table[userKey]
          p.couponDataIdx = table[couponKey]
          return p
        })
        result = params
      } else {
        param.couponDataIdx = this.coupon.idx
        param.userIdx = this.userIdx
        result = [param]
      }

      if (result.length < 1) {
        throw new Error('보낼 대상이 없습니다.')
      }
      return result
    },
    async providePoint(isMultiple = false) {
      const confirm = await this.$swal.basic.confirm('포인트를 지급합니다.')
      if (confirm.isConfirmed === false) {
        return
      }

      this.isLoading = true

      const q = `data: providePointByAdmin(inputProvidePoints: $input) {
        idx
        userIdx
        couponDataIdx
        couponAmount
        couponBalance
        status
        datetime
        expirationDatetime
      }`

      try {
        const params = this.getParams(isMultiple)
        const data = await this.$fundaApi.mutation(
          gql`{${q}}`,
          'call($input: [InputProvidePoint]!)',
          { input: params }
        )
        if (data) {
          this.$swal.basic.success('포인트 지급 성공!')
        }
      } catch (e) {
        console.log(e)
        if (!!e.message) {
          this.$swal.basic.error(e.message)
        } else {
          this.$swal.basic.error('포인트 지급 에러 ㅠㅠㅠ')
        }
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
