<template>
  <router-link
    v-if="fundaUser"
    :to="{
      name: 'fundaUserDetails',
      params: { userIdx: fundaUser.idx.toString() },
    }"
  >
    {{ `${fundaUser.name} (${fundaUser.id})` }}
  </router-link>
</template>

<script>
export default {
  props: {
    user: {
      require: true,
    },
  },
  data() {
    return {
      fundaUser: null,
    }
  },
  async created() {
    if (this.user.apiClientIdx === 3) {
      this.fundaUser = await this.$fundaApi.query(gql`{
          data: getUserByPaymentUserIdx(paymentUserIdx: ${this.user.idx}){
            idx
            id
            name
          }
        }`)
    }
  },
}
</script>
