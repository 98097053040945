<template>
  <v-container fluid class="pt-0">
    <PageHeader
      title="테이스티 홀딩스 수수료 계좌 거래내역"
      subtitle="테이스티 홀딩스 수수료 계좌 거래내역"
      tabMenu
    >
    </PageHeader>
    <PaymentVirtualAccountTransactions
      :userIdx="38209"
      :userVirtualAccountIdx="41367"
      :isQueryPaging="true"
    />
  </v-container>
</template>
<script>
import PaymentVirtualAccountTransactions from '@/components/payment/PaymentVirtualAccountTransactions.vue'

export default {
  components: { PaymentVirtualAccountTransactions },
}
</script>
