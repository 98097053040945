export default [
  {
    name: 'about',
    path: '/about',
    beforeEnter(to, from, next) {
      console.log('abount beforEnter')
      next()
    },
    component: require('@/pages/about/About.vue').default,
  },
]
