<template>
  <v-container fluid class="pt-0">
    <PageHeader
      title="알림톡 발송 내역 상세 조회"
      subtitle="알림톡 발송 내역 상세 조회 페이지"
    />
    <v-row class="my-3" no-gutters>
      <v-col cols="12">
        <PageSection
          title="알림톡 발송 상세 정보"
          :isLoading="messageGroup.isLoading"
        >
          <template v-if="messageGroup.data" slot="content">
            <v-row>
              <v-col
                cols="12"
                lg="4"
                md="4"
                sm="4"
                class="vertical-divider__col py-0"
              >
                <v-list class="subtitle-1 pa-0">
                  <v-list-item class="px-0 pt-0 pb-0">
                    <v-list-item-content>
                      <v-list-item-subtitle
                        >message Group Index</v-list-item-subtitle
                      >
                      <v-list-item-title class="title font-weight-bold">
                        {{ messageGroup.data.idx }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item class="px-0 pt-0 pb-0">
                    <v-list-item-content>
                      <v-list-item-subtitle>제목</v-list-item-subtitle>
                      <v-list-item-title class="title font-weight-bold">
                        {{ messageGroup.data.title }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item class="px-0 pt-0 pb-0">
                    <v-list-item-content>
                      <v-list-item-subtitle>발송 상태</v-list-item-subtitle>
                      <v-list-item-title class="title font-weight-bold">
                        {{
                          messageGroup.data.status
                            | alimtalk.code('MessageGroup.Status')
                        }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-if="messageGroup.data.status === 'WAIT'"
                    class="px-0 pt-0 pb-0"
                  >
                    <v-list-item-content>
                      <v-btn
                        color="error"
                        large
                        block
                        @click="cancelReserve"
                        :loading="messageGroup.isLoading"
                        :disabled="
                          messageGroup.isLoading ||
                          messageGroup.data.status !== 'WAIT'
                        "
                      >
                        예약 취소
                      </v-btn>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-divider vertical class="d-none d-sm-block" />
              </v-col>
              <v-col
                cols="12"
                lg="4"
                md="4"
                sm="3"
                class="vertical-divider__col py-0"
              >
                <v-list class="subtitle-1 pa-0">
                  <v-list-item class="px-0 pt-0 pb-0">
                    <v-list-item-content>
                      <v-list-item-subtitle>총 발송 건수</v-list-item-subtitle>
                      <v-list-item-title class="title font-weight-bold">
                        {{ messageGroup.data.totalCount | commaFormat }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item class="px-0 pt-0 pb-0">
                    <v-list-item-content>
                      <v-list-item-subtitle
                        >발송 성공 건수</v-list-item-subtitle
                      >
                      <v-list-item-title class="title font-weight-bold">
                        {{ messageGroup.data.successCount | commaFormat }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item class="px-0 pt-0 pb-0">
                    <v-list-item-content>
                      <v-list-item-subtitle
                        >발송 실패 건수</v-list-item-subtitle
                      >
                      <v-list-item-title class="title font-weight-bold">
                        {{ messageGroup.data.failCount | commaFormat }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-divider vertical class="d-none d-sm-block" />
              </v-col>
              <v-col cols="12" lg="4" md="4" sm="5" class="py-0">
                <v-list class="subtitle-1 pa-0">
                  <v-list-item class="px-0 pt-0 pb-0">
                    <v-list-item-content>
                      <v-list-item-subtitle>생성 시간</v-list-item-subtitle>
                      <v-list-item-title class="title font-weight-bold">
                        {{
                          messageGroup.data.createTime
                            | moment('YYYY-MM-DD HH:mm:ss')
                        }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item class="px-0 pt-0 pb-0">
                    <v-list-item-content>
                      <v-list-item-subtitle>예약 시간</v-list-item-subtitle>
                      <v-list-item-title class="title font-weight-bold">
                        {{
                          messageGroup.data.reserveTime
                            | moment('YYYY-MM-DD HH:mm:ss')
                        }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-if="messageGroup.data.startTime"
                    class="px-0 pt-0 pb-0"
                  >
                    <v-list-item-content>
                      <v-list-item-subtitle
                        >발송 시작 시간</v-list-item-subtitle
                      >
                      <v-list-item-title class="title font-weight-bold">
                        {{
                          messageGroup.data.startTime
                            | moment('YYYY-MM-DD HH:mm:ss')
                        }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-if="messageGroup.data.completeTime"
                    class="px-0 pt-0 pb-0"
                  >
                    <v-list-item-content>
                      <v-list-item-subtitle
                        >발송 완료 시간</v-list-item-subtitle
                      >
                      <v-list-item-title class="title font-weight-bold">
                        {{
                          messageGroup.data.completeTime
                            | moment('YYYY-MM-DD HH:mm:ss')
                        }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-if="messageGroup.data.failTime"
                    class="px-0 pt-0 pb-0"
                  >
                    <v-list-item-content>
                      <v-list-item-subtitle
                        >발송 실패 시간</v-list-item-subtitle
                      >
                      <v-list-item-title class="title font-weight-bold">
                        {{
                          messageGroup.data.failTime
                            | moment('YYYY-MM-DD HH:mm:ss')
                        }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </template>
        </PageSection>
      </v-col>
    </v-row>
    <v-row class="my-3" no-gutters>
      <v-col>
        <PageSection
          title="알림톡 발송 상세 목록"
          :isLoading="alimtalks.isLoading"
        >
          <template v-if="alimtalks.data" slot="content">
            <v-row>
              <v-col cols="12" lg="8" md="8" sm="8">
                <v-data-table
                  :items="alimtalks.data"
                  :loading="alimtalks.isLoading"
                  :headers="alimtalks.headers"
                  :page.sync="alimtalks.paging.page"
                  :items-per-page.sync="alimtalks.paging.size"
                  :server-items-length="alimtalks.paging.totalCount"
                  :footer-props="alimtalks.footer"
                  class="elevation-1"
                  @update:options="alimtalksChangeOption"
                  single-select
                  show-select
                  v-model="alimtalks.selected"
                  item-key="idx"
                  @item-selected="selectItem($event.item, $event.value)"
                >
                  <template v-slot:loading>
                    <p class="text-center title my-2">Loading items...</p>
                  </template>
                  <template v-slot:top>
                    <v-row class="pa-3" no-gutters>
                      <v-col cols="12" lg="2" mg="4" sm="6">
                        <MultiSelect
                          v-if="selectStatusItems"
                          :items="selectStatusItems"
                          label="발송 상태"
                          v-model="alimtalks.param.statuses"
                          @select="selectStatuses"
                        />
                      </v-col>
                      <v-col cols="12" lg="3" mg="4" sm="6">
                        <v-text-field
                          label="키워드 검색(내용/핸드폰 번호)"
                          append-icon="mdi-magnify"
                          clearable
                          outlined
                          dense
                          hide-details
                          v-model.trim="alimtalks.searchWord"
                          @keyup.enter="searchAlimtalks"
                          @click:clear="clearSearchAlimtalks"
                        />
                      </v-col>
                    </v-row>
                  </template>

                  <template v-slot:[`item.isFailSendSms`]="{ item }">
                    {{ item.isFailSendSms == false ? '-' : '발송' }}
                  </template>

                  <template v-slot:[`item.status`]="{ item }">
                    {{ item.status | alimtalk.code('Alimtalk.Status') }}
                  </template>

                  <template v-slot:[`item.createTime`]="{ item }">
                    {{ item.createTime | moment('YYYY-MM-DD HH:mm:ss') }}
                  </template>

                  <template v-slot:[`item.reserveTime`]="{ item }">
                    {{ item.reserveTime | moment('YYYY-MM-DD HH:mm:ss') }}
                  </template>
                </v-data-table>
              </v-col>
              <v-col cols="12" lg="4" md="4" sm="4">
                <TemplatePreview
                  v-if="alimtalkPreview"
                  :template="alimtalkPreview"
                />
              </v-col>
            </v-row>
          </template>
        </PageSection>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import MessageGroupStatus from '@/const/messageGroupStatus'
import AlimtalkStatus from '@/const/alimtalkStatus'
import TemplatePreview from '../../components/alimtalk/template/TemplatePreview.vue'
import MultiSelect from '../../components/alimtalk/MultiSelect.vue'

export default {
  components: { TemplatePreview, MultiSelect },
  props: {
    messageGroupIdx: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      selectStatusItems: null,
      constMessageGroupStatus: MessageGroupStatus,
      constAlimtalkStatus: AlimtalkStatus,
      messageGroup: {
        isLoaging: false,
        data: null,
      },
      alimtalks: {
        isLoading: false,
        paging: {
          page: 1,
          size: 20,
          totalCount: 0,
        },
        param: {
          statuses: [],
          keyword: this.$route.query.keyword,
        },
        headers: [
          { text: 'index', value: 'idx', width: '80', sortable: false },
          { text: '상태', value: 'status', width: '100', sortable: false },
          {
            text: '핸드폰번호',
            value: 'mobileNo',
            width: '120',
            sortable: false,
          },
          { text: '내용', value: 'content', width: '200', sortable: false },
          {
            text: '실패시 문자 발송 여부',
            value: 'isFailSendSms',
            width: '85',
            sortable: false,
          },
          {
            text: '등록 시간',
            value: 'createTime',
            width: '150',
            sortable: false,
          },
          {
            text: '예약 시간',
            value: 'reserveTime',
            width: '150',
            sortable: false,
          },
        ],
        data: [],
        footer: {
          'items-per-page-options': [1, 5, 10, 20, 50, 100],
        },
        selected: [],
        searchWord: this.$route.query.keyword,
      },
      alimtalkPreview: null,
    }
  },
  created() {
    this.getSelectStatusItems()
    this.getMessageGroup()
    this.getAlimtalks(this.alimtalks.paging.page, this.alimtalks.paging.size)
  },
  methods: {
    getSelectStatusItems() {
      let items = []
      const keys = Object.getOwnPropertyNames(AlimtalkStatus)
      keys.forEach((key) => {
        items.push({
          text: AlimtalkStatus[key],
          value: key,
        })
      })
      this.selectStatusItems = items
    },
    async getMessageGroup() {
      try {
        this.messageGroup.isLoading = true

        const { data } = await this.$alimtalkApi.getMessageGroup(
          this.messageGroupIdx
        )

        this.messageGroup.data = data
      } finally {
        this.messageGroup.isLoading = false
      }
    },
    async getAlimtalks(page, size) {
      try {
        this.alimtalks.isLoading = true

        const statuses = this.alimtalks.param.statuses
        const keyword = this.alimtalks.param.keyword
        const { data } = await this.$alimtalkApi.getMessageGroupAlimtalks(
          this.messageGroupIdx,
          {
            page,
            size,
            statuses: statuses && statuses.length > 0 ? statuses : null,
            keyword: keyword && keyword.length > 0 ? keyword : null,
          }
        )

        this.alimtalks.paging = data.paging
        this.alimtalks.data = data.data
        if (data.data != null && data.data.length > 0) {
          this.alimtalks.selected = [data.data[0]]
          this.selectItem(data.data[0], true)
        }
      } finally {
        this.alimtalks.isLoading = false
      }
    },
    alimtalksChangeOption(option) {
      if (this.alimtalks.paging.size !== option.itemsPerPage) {
        return this.getAlimtalks(1, option.itemsPerPage)
      }
      if (this.alimtalks.paging.page !== option.page) {
        return this.getAlimtalks(option.page, this.alimtalks.paging.size)
      }
    },
    selectStatuses(e) {
      this.alimtalks.param.statuses = e
      this.getAlimtalks(1, this.alimtalks.paging.size)
    },
    clearSearchAlimtalks() {
      this.alimtalks.searchWord = null
      this.searchAlimtalks()
    },
    searchAlimtalks() {
      const word = this.alimtalks.searchWord
      if (this.alimtalks.param.keyword == null && word == null) {
        return
      }
      if (this.alimtalks.param.keyword !== word) {
        this.alimtalks.param.keyword = word
        this.getAlimtalks(1, this.alimtalks.paging.size)
      }
    },
    async cancelReserve() {
      if (
        this.messageGroup.isLoading === true ||
        this.messageGroup.data.status !== 'WAIT'
      ) {
        return
      }
      try {
        let btnResult = await this.$swal.basic.confirm({
          title: '알림톡 발송 예약 취소',
          text: '알림톡 발송 예약을 취소 하시겠습니까?',
          cancelButtonColor: 'red',
        })

        if (btnResult.isConfirmed !== true) {
          return
        }

        this.messageGroup.isLoading = true

        await this.$alimtalkApi.putMessageGroupCancel(this.messageGroupIdx)

        await this.getMessageGroup()
        this.$swal.basic.alert({
          title: '알림톡 취소 완료',
          text: '취소가 완료되었습니다.',
        })
      } finally {
        this.messageGroup.isLoading = false
      }
    },
    async selectItem(item, value) {
      if (value === true) {
        const { data } = await this.$alimtalkApi.getAlimtalkDetail(item.idx)

        let obj = {
          content: item.content,
          buttons: data.buttons,
        }

        this.alimtalkPreview = obj
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.vertical-divider__col {
  position: relative;

  hr {
    position: absolute;
    right: 0;
    top: 0;
  }
}
</style>
