import client from '../fundaApiClient'

export default {
  getLoanInvestAmount(fnLoanIdx) {
    return client.call({
      url: `/fundanow/loan/${fnLoanIdx}/invest/amount`,
      method: 'GET',
    })
  },
}
