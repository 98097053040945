<template>
  <component :is="layout">
    <slot />
  </component>
</template>
<script>
import { computed } from '@vue/composition-api'
import DefaultLayout from './DefaultLayout'
import EmptyLayout from './EmptyLayout'

export default {
  name: 'RootLayout',
  components: {
    DefaultLayout,
    EmptyLayout,
  },
  setup(props, { root }) {
    const layout = computed(() => root.$route.meta.layout || 'DefaultLayout')
    return {
      layout,
    }
  },
}
</script>
