<template>
  <v-card min-height="450">
    <v-row class="mb-3" no-gutters>
      <v-col cols="8" class="pa-3">
        <v-row class="mb-3" no-gutters>
          <v-col cols="12">
            <h2 class="ma-0 text-center">쿠폰 정보</h2>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="3" sm="3" md="3"> </v-col>
          <v-col cols="6" sm="6" md="6">
            <v-text-field
              v-model="search.keyword"
              :disabled="getDataLoading"
              placeholder="검색어를 입력하세요(idx/쿠폰명 등)"
              @keyup.enter="getCouponDataByKeyword()"
            >
            </v-text-field>
          </v-col>
          <v-col cols="3" sm="3" md="3">
            <v-btn
              :disabled="search.keyword.length < 1 || getDataLoading"
              :loading="getDataLoading"
              color="#393939"
              class="white--text"
              height="41"
              depressed
              @click="getCouponDataByKeyword()"
            >
              검색
            </v-btn>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="12" sm="12" md="12">
            <v-card flat>
              <v-data-table
                no-data-text="이곳에 쿠폰정보가 표시됩니다."
                :headers="headers"
                :items="couponDatas"
                :items-per-page="5"
                :footer-props="{ 'items-per-page-options': [5, 10, 20] }"
                :server-items-length="paging.totalCount"
                @update:options="changePage"
              >
                <template v-slot:item="{ item }">
                  <tr
                    class="clickable-data-row"
                    :class="selectedCoupon.idx == item.idx ? 'selected' : ''"
                    style="cursor: pointer"
                    @click="selectCouponData(item)"
                  >
                    <td class="text-center">{{ item.idx }}</td>
                    <td class="text-center">{{ item.title }}</td>
                    <td class="text-center">
                      {{ item.amount | commaFormat }}
                    </td>
                    <td class="text-center">{{ item.status }}</td>
                    <td class="text-center">
                      {{ item.registerDate | dateFormat }}
                    </td>
                    <td class="text-center">
                      {{ item.expirationDays | commaFormat }} 일
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="4">
        <v-list>
          <v-list-group no-action :value="true">
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title class="font-size--18">
                  쿠폰 생성
                </v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item class="pa-0">
              <v-card class="pa-3" width="100%" outlined>
                <v-row no-gutters>
                  <v-col cols="12" sm="12" md="12">
                    <h4 class="ma-0 text-center">쿠폰 생성</h4>
                  </v-col>
                </v-row>

                <v-row class="mb-2" no-gutters>
                  <v-col cols="3" sm="3" md="3">
                    <v-subheader class="subtitle-1 pa-0 black--text">
                      쿠폰명:
                    </v-subheader>
                  </v-col>
                  <v-col cols="9" sm="9" md="9">
                    <v-text-field
                      v-model="inputCouponData.title"
                      hint="고객한테 보여지는 내용이에요."
                      placeholder="ex) 999호 마감대기포인트"
                      persistent-hint
                      outlined
                      dense
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row class="mb-2" no-gutters>
                  <v-col cols="3" sm="3" md="3">
                    <v-subheader class="subtitle-1 pa-0 black--text">
                      타입:
                    </v-subheader>
                  </v-col>
                  <v-col cols="9" sm="9" md="9"> 이벤트, 프로모션 등등 </v-col>
                </v-row>

                <v-row no-gutters>
                  <v-col cols="3" sm="3" md="3">
                    <v-subheader class="subtitle-1 pa-0 black--text">
                      지급액:
                    </v-subheader>
                  </v-col>
                  <v-col cols="9" sm="9" md="9">
                    <input
                      v-input-number-format="inputCouponData.amount"
                      class="pa-2"
                      style="
                        width: 100%;
                        border: 1px solid rgba(0, 0, 0, 0.38);
                        border-radius: 4px;
                      "
                      placeholder="숫자만"
                    />
                  </v-col>
                </v-row>

                <v-row no-gutters>
                  <v-col cols="3" sm="3" md="3">
                    <v-subheader class="subtitle-1 pa-0 black--text">
                      유효기간:
                    </v-subheader>
                  </v-col>
                  <v-col cols="9" sm="9" md="9">
                    <input
                      v-input-number-format="inputCouponData.expirationDays"
                      class="pa-2"
                      style="
                        width: 100%;
                        border: 1px solid rgba(0, 0, 0, 0.38);
                        border-radius: 4px;
                      "
                      placeholder="숫자만"
                    />
                    <p
                      v-if="inputCouponData.expirationDateTime"
                      class="ma-0 mt-1"
                    >
                      소멸일시:
                      {{
                        inputCouponData.expirationDateTime.toLocaleDateString()
                      }}
                      10:00:00
                    </p>
                  </v-col>
                </v-row>

                <v-divider class="mx-4"></v-divider>

                <v-row no-gutters>
                  <v-col cols="12" class="text-center">
                    <p class="ma-0 mb-2 red--text">
                      먼저 동일한 쿠폰이 있는지 확인후 실행바랍니다.
                    </p>
                    <v-btn
                      :loading="inputCouponData.loading"
                      :disabled="
                        inputCouponData.loading ||
                        !(
                          inputCouponData.title &&
                          inputCouponData.amount &&
                          inputCouponData.expirationDays
                        )
                      "
                      color="#393939"
                      class="white--text"
                      center
                      depressed
                      @click="createCouponData()"
                    >
                      쿠폰 생성
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-list-item>
          </v-list-group>
        </v-list>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        { text: '인덱스', value: 'idx', align: 'center', sortable: false },
        { text: '쿠폰명', value: 'title', align: 'center', sortable: false },
        { text: '금액', value: 'amount', align: 'right', sortable: false },
        { text: '상태', value: 'status', align: 'center', sortable: false },
        {
          text: '생성일',
          value: 'registerDate',
          align: 'center',
          sortable: false,
        },
        {
          text: '유효기간',
          value: 'expirationDays',
          align: 'center',
          sortable: false,
        },
      ],
      search: {
        page: 1,
        size: 5,
        keyword: '',
      },
      getDataLoading: false,
      paging: {
        page: 1,
        size: 5,
        totalCount: 0,
      },
      couponDatas: [],
      selectedCoupon: { idx: null, status: null },
      inputCouponData: {
        loading: false,
        memo: '',
        title: null,
        amount: null,
        expirationDays: 0,
        expirationDateTime: null,
      },
    }
  },
  watch: {
    'inputCouponData.expirationDays': function (v) {
      if (!!v) {
        let date = new Date()
        this.inputCouponData.expirationDateTime = new Date(
          date.setDate(date.getDate() + parseInt(v, 10))
        )
      }
    },
  },
  methods: {
    changePage(option) {
      this.search.size =
        option.itemsPerPage === -1
          ? this.paging.totalCount
          : option.itemsPerPage
      this.search.page = option.page
      this.getCouponDataByKeyword()
    },
    async getCouponDataByKeyword() {
      if (this.search.keyword.length < 1) {
        return
      }

      this.getDataLoading = true

      const q = `
        coupons: getCouponDatasByTitle(title: "${this.search.keyword}", paging:{
            page: ${this.search.page}
            size: ${this.search.size}
          }) {
            data {
              idx
              title
              amount
              status
              type
              registerDate
              expirationDays
            }
            
            paging {
              size
              page
              totalCount
            }
          }
        `

      try {
        const { coupons } = await this.$fundaApi.query(gql`{${q}}`)
        this.couponDatas = coupons.data
        this.paging = coupons.paging
      } finally {
        this.getDataLoading = false
      }
    },
    selectCouponData(couponData) {
      console.log('selectCouponData!')
      this.selectedCoupon = couponData
      this.$emit('emitSelectCoupon', couponData)
    },
    async createCouponData() {
      const confirm = await this.$swal.basic.confirm('쿠폰을 생성합니다.')

      if (confirm.isConfirmed === false) {
        return
      }

      this.inputCouponData.loading = true

      const q = `
        data: createCouponData(couponData: {
          title: "${this.inputCouponData.title}"
          amount: ${this.inputCouponData.amount}
          expirationDays: ${this.inputCouponData.expirationDays}
          type: "보상"
          memo: ""
        }) {
          idx
          title
          amount
          status
          type
          registerDate
          expirationDays
          memo
        }
      `

      try {
        const data = await this.$fundaApi.mutation(gql`{${q}}`)

        if (!!data.idx) {
          this.search.keyword = this.inputCouponData.title
          this.getCouponDataByKeyword()
          this.selectCouponData(data)
          this.$swal.basic.success('쿠폰이 생성되었습니다.')
        } else {
          this.$swal.basic.error('쿠폰 생성 에러 ㅠㅠㅠ')
        }
      } finally {
        this.inputCouponData.loading = false
      }
    },
  },
}
</script>
