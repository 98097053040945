import fundaApi from '@/plugins/api/api'

const withdrawStore = {
  name: 'www/withdraw',
  namespaced: true,
  state: {
    isLoading: false,
    userWithdraw: 0,
  },
  getters: {
    isLoading(state) {
      return state.isLoading
    },
    getUserWithdraw(state) {
      return state.userWithdraw
    },
  },
  mutations: {
    setLoading(state, flag) {
      if (typeof flag !== 'boolean') {
        flag = !state.isLoading
      }
      state.isLoading = flag
    },
    setWithdraw(state, withdraw) {
      state.userWithdraw = withdraw
    },
  },
  actions: {
    async getUserWithdraw({ commit }, userIdx) {
      if (withdrawStore.state.isLoading === true) {
        return
      }
      withdrawStore.state.isLoading = true

      let q = `
        data: getMyWithdrawBalance(userIdx: ${userIdx})
      `

      try {
        const data = await fundaApi.query(gql`{${q}}`)
        commit('setWithdraw', data)
      } finally {
        withdrawStore.state.isLoading = false
      }
    },
  },
}

export default withdrawStore
