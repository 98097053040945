<template>
  <v-card class="pa-2">
    <v-card-title class="pt-2">발송 내용 미리보기</v-card-title>
    <v-card-text>
      <v-card flat :color="$colors['f-white-blue-2']">
        <!-- eslint-disable vue/no-v-html -->
        <v-card-text
          class="template__preview"
          style="line-height: 2rem; word-break: break-all"
          v-html="previewContent"
        >
        </v-card-text>
        <template v-if="previewButtons != null && previewButtons.length > 0">
          <v-card-actions
            v-for="(button, index) in previewButtons"
            :key="index"
            class="pa-4"
          >
            <v-btn
              :color="$colors['f-blue-grey-lighten-1']"
              link
              :href="button.link"
              target="_blank"
              depressed
              large
              block
            >
              {{ button.name }}
            </v-btn>
          </v-card-actions>
        </template>
      </v-card>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  props: {
    template: {
      type: Object,
      required: true,
    },
    variables: {
      type: Array,
      default: (_) => {
        return []
      },
    },
    row: {
      type: Object,
      default: (_) => {
        return null
      },
    },
  },
  data() {
    return {
      previewContent: null,
      previewButtons: null,
    }
  },
  watch: {
    template: {
      handler() {
        this.setPreview()
      },
      deep: true,
    },
    row() {
      this.setPreview()
    },
  },
  created() {
    this.setPreview()
  },
  methods: {
    setPreview() {
      if (this.row != null) {
        let text = this.row.replacedTemplate.content
        this.previewContent = text.replace(/(?:\r\n|\r|\n)/g, '<br />')
        this.previewButtons = this.row.replacedTemplate.buttons
      } else {
        let text = this.template.content
        this.variables.forEach((key) => {
          const regex = new RegExp(`#{${key}}`, 'g')
          const prefix =
            '<span class="mx-1 rounded px-2 py-1" style="background-color: #eee;">'
          const suffix = '</span>'
          const replaceString = prefix + key + suffix
          text = text.replace(regex, replaceString)
        })
        text = text.replace(/(?:\r\n|\r|\n)/g, '<br />')
        this.previewContent = text
        this.previewButtons = this.template.buttons
      }
    },
    setVariables() {
      const templateStr = JSON.stringify(this.template)
      let variables = templateStr.match(/#{([^{}]+)}/g)

      if (variables == null) {
        return []
      }

      variables = variables.map((variable) => variable.replace(/[#{}]/g, ''))
      return Array.from(new Set(variables))
    },
  },
}
</script>
