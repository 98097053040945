<template>
  <div style="height: 100%">
    <Header />
    <Article />
    <!-- <Footer /> -->
  </div>
</template>
<script>
import { jsLoad } from '@/util'
import Header from '@/components/common/layout/Header'
import Article from '@/components/common/layout/Article'
// import Footer from './common/layout/Footer'

// 중복 문제 해결 필요
const scriptId = 'scGoogleChart2'

export default {
  name: 'DefaultLayout',
  components: {
    Header,
    Article,
  },
  async created() {
    await jsLoad({
      id: scriptId,
      src: '//www.gstatic.com/charts/loader.js',
    })
  },
}
</script>
