import AuthService from '@/service/auth/AuthService'

function isPersonalInfoAdmin(to, from, next) {
  if (
    AuthService.hasAnyRole(['ROLE_PERSONAL_INFO_ADMIN', 'ROLE_SUPERADMIN']) ===
    false
  ) {
    alert('권한없음')
    next(false)
  } else {
    next()
  }
}

export default [
  {
    name: 'fundaUserAuth',
    path: '/funda/users/auth',
    meta: { title: '투자자 인증 요청 목록' },
    component: require('@/pages/funda/user/auth/UserAuthList.vue').default,
    beforeEnter: (to, from, next) => {
      isPersonalInfoAdmin(to, from, next)
    },
  },
  {
    name: 'reqChangeLimit',
    path: '/funda/users/request/change-limit',
    meta: { title: '투자자 타입 변경 요청' },
    component: require('@/pages/funda/user/auth/ReqChangeLimitList.vue')
      .default,
    beforeEnter: (to, from, next) => {
      isPersonalInfoAdmin(to, from, next)
    },
  },
  {
    name: 'fundaUsers',
    path: '/funda/users',
    meta: { title: '투자자 목록' },
    component: require('@/pages/funda/user/List.vue').default,
  },
  {
    name: 'fundaUserDetails',
    path: '/funda/user/:userIdx',
    meta: { title: '투자자 상세' },
    component: require('@/pages/funda/user/Details.vue').default,
    props: true,
  },
  {
    name: 'fundaUserRepayments',
    path: '/funda/repayments/:userIdx?',
    meta: { title: '유저별 상환 내역' },
    component: require('@/pages/funda/user/Repayments.vue').default,
    props: true,
  },
  {
    name: 'fundaUserPersonal',
    path: '/funda/personal-info',
    meta: { title: '투자자 정보 데이터' },
    component: require('@/pages/funda/user/PersonalInfo.vue').default,
    props: true,
  },
]
