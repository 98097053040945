import axios from 'axios'

import store from '@/store'

import alimtalkClient from './alimtalk/client'
import fundaApiClient from './api/fundaApiClient'
import paymentClient from './payment/client'
import smsClient from './sms/client'
import nowClient from './now/client'

const clients = [alimtalkClient, fundaApiClient, paymentClient, smsClient, nowClient]

clients.forEach((client) => {
  client.interceptors.request.use((request) => {
    const info = store.state.auth.info
    if (info) {
      const url = request.baseURL.replace(/\/+$/, '') + request.url
      const user = info.email
      const body = request.data
      setTimeout(() => {
        auditLog(url, user, body)
      }, 0)
    }

    return request
  })
})

function auditLog(url, user, body = '') {
  const data = { url, user, body }

  if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'production') {
    axios.post(`${process.env.common.auditLogApi.url}`, data)
  } else {
    console.log('audit logging', JSON.stringify(data, null, 2))
  }
}
