<template>
  <PageSection title="상점 정보" :isLoading="isLoading">
    <template slot="content" v-if="store != null">
      <v-simple-table>
        <template v-slot:default>
          <tbody>
            <tr>
              <td>index</td>
              <td>{{ store.id }}</td>
            </tr>
            <tr>
              <td>상점명</td>
              <td>{{ store.name }}</td>
            </tr>
            <tr>
              <td>사업자번호</td>
              <td>{{ store.businessLicenseNumber }}</td>
            </tr>
            <tr>
              <td>휴폐업여부(휴폐업상태)</td>
              <td>{{ store.isClosed ? 'O' : 'X' }}({{ store.statusMsg }})</td>
            </tr>
            <tr>
              <td>수정일</td>
              <td>{{ store.modified | moment('YYYY-MM-DD HH:mm:ss') }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </template>
  </PageSection>
</template>
<script>
export default {
  props: {
    userId: {
      type: Number,
      required: true
    },
  },
  data() {
    return {
      store: null,
      isLoading: false,
    }
  },
  async created() {
    try {
      this.isLoading = true

      const { data } = await this.$fundaNowApi.getUserStore(this.userId)
      this.store = data
    } finally {
      this.isLoading = false
    }
  },
}
</script>
<style lang="scss" scoped>
tr > td:last-child {
  word-break: break-all;
}
</style>
