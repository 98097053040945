<template>
  <div>
    <v-row class="mb-2" no-gutters>
      <v-col cols="12" sm="12" md="12" class="pl-5">
        <v-row class="mb-3" no-gutters>
          <v-col cols="12" md="12">
            <h3 class="text-center">선택된 상품(혹은 상점)</h3>
          </v-col>

          <v-col cols="12" sm="12" md="12">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center">상품호수</th>
                    <th class="text-center">상품명</th>
                    <th class="text-center">FN상점명</th>
                    <th class="text-center">대출일</th>
                    <th class="text-center">대출액</th>
                    <th class="text-center">상환원금</th>
                    <th class="text-center">잔액</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="getDataLoading">
                    <tr>
                      <td colspan="7">
                        <v-progress-linear
                          indeterminate
                          color="blue"
                        ></v-progress-linear>
                      </td>
                    </tr>
                  </template>
                  <template v-else-if="fundingData !== null">
                    <tr class="clickable-data-row selected cursor--pointer">
                      <td class="text-center">{{ fundingData.idx }}</td>
                      <td class="text-center">{{ fundingData.title }}</td>
                      <td class="text-center">
                        <template v-if="fnStoreFundingData !== null">
                          {{ fnStoreFundingData.fnStore.storeName }}
                        </template>
                      </td>
                      <td class="text-center">
                        {{ fundingData.registerDate | dateFormat }}
                      </td>
                      <td class="text-center">
                        <template v-if="fnStoreFundingData !== null">
                          {{ fnStoreFundingData.bisangAmount | commaFormat }}
                        </template>
                        <template v-else>
                          {{ fundingData.totalAmount | commaFormat }}
                        </template>
                        원
                      </td>
                      <td class="text-center">
                        {{
                          fnStoreFundingData.repaymentPrincipal | commaFormat
                        }}
                        원
                      </td>
                      <td class="text-center">
                        {{
                          (fnStoreFundingData.bisangAmount -
                            fnStoreFundingData.repaymentPrincipal)
                            | commaFormat
                        }}
                        원
                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr class="text-center">
                      <td colspan="7">선택된 상품(혹은 상점)이 없습니다.</td>
                    </tr>
                  </template>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>

        <template v-if="!!fundingData">
          <v-row class="mb-4" no-gutters style="margin-top: 5rem">
            <v-col cols="12" md="12">
              <h3 class="text-center">연체상태 정보</h3>
            </v-col>

            <v-col cols="12" md="12">
              <v-data-table
                no-data-text="추심 현황이 없습니다."
                no-results-text="추심 현황이 없습니다."
                loading-text="데이터를 가져오는 중입니다..."
                :loading="getDataLoading"
                :headers="overdueStatusHeaders"
                :items="overdueStatuses"
                :hide-default-footer="true"
              >
                <template v-slot:item="{ item }">
                  <tr class="clickable-data-row cursor--pointer">
                    <td
                      class="text-center"
                      :class="'overdue-status__' + (item.overdueStatusIdx - 1)"
                    >
                      {{ item.overdueStatus.name }}
                    </td>
                    <td class="py-2 text-center">
                      <template
                        v-if="
                          isFundaNow === false ||
                          overdueStatuses.indexOf(item) === 0
                        "
                      >
                        <select
                          v-model="item.reason"
                          class="pa-2"
                          style="
                            background-color: white;
                            border: 1px solid #8a94a2;
                          "
                          @change="selectedOption(item, 'reason')"
                        >
                          <option
                            v-if="!!item.reason"
                            :value="item.reason"
                            selected
                          >
                            {{ item.reason }}
                          </option>

                          <option v-else disabled selected value="">
                            선택하세요
                          </option>

                          <option
                            v-for="option in reasonSelector"
                            :key="option"
                            :value="option"
                          >
                            {{ option }}
                          </option>
                        </select>

                        <v-textarea
                          v-show="item.edit === true"
                          v-model="item.reason"
                          rows="2"
                          class="mt-2"
                          background-color="white"
                          outlined
                          hide-details
                        ></v-textarea>
                      </template>

                      <template v-else> - </template>
                    </td>
                    <td class="text-center">
                      <template
                        v-if="
                          isFundaNow === false ||
                          overdueStatuses.indexOf(item) === 0
                        "
                      >
                        <span
                          v-if="!!item.memoCreditor"
                          class="memo-creditor"
                          @click="openDialog(item)"
                        >
                          {{ item.memoCreditor.substr(0, 20) + '...' }}
                        </span>

                        <v-btn
                          v-else
                          center
                          depressed
                          color="cyan"
                          class="white--text"
                          @click="openDialog(item)"
                        >
                          편집
                        </v-btn>
                      </template>

                      <template v-else> - </template>
                    </td>
                    <td class="text-center">
                      {{ item.createdTime | dateFormat }}
                    </td>
                    <td class="text-center">
                      <v-btn
                        color="#393939"
                        class="white--text"
                        center
                        depressed
                        :disabled="getDataLoading"
                        :loading="getDataLoading"
                        @click="saveFundingDataOverdueStatus(item)"
                      >
                        저장
                      </v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>

            <v-col cols="12" sm="12" md="12" class="mt-3 text-center">
              <v-btn
                color="#393939"
                class="white--text mb-5"
                center
                depressed
                :disabled="isResolve"
                @click="addFundingOverdueRow()"
              >
                연체상태추가
              </v-btn>

              <v-btn
                color="#393939"
                class="white--text mb-5 ml-3"
                center
                depressed
                :disabled="isResolve"
                @click="addFundingOverdueRow(true)"
              >
                연체해소
              </v-btn>
            </v-col>
          </v-row>

          <v-row class="mb-3" no-gutters>
            <v-col cols="6" md="6" class="pr-3">
              <v-row no-gutters>
                <v-col cols="12" md="12">
                  <h3 class="text-center">(어드민) 우리끼리 공유해염</h3>
                </v-col>

                <v-col cols="12" md="12">
                  <v-card
                    style="
                      box-shadow: 0 0px 5px 4px rgba(0, 0, 0, 0.2),
                        0 2px 2px 0 rgba(0, 0, 0, 0.14),
                        0 1px 5px 0 rgba(0, 0, 0, 0.12);
                    "
                  >
                    <v-card-text class="px-3">
                      <h4
                        v-if="
                          overdueStatuses.length < 1 ||
                          !!!overdueStatuses[0].idx
                        "
                        class="ma-0 text-center"
                      >
                        연체상태를 먼저 저장하여야 합니다.
                      </h4>
                      <v-textarea
                        v-else
                        v-model="overdueStatuses[0].memoAdmin"
                        hide-details
                        placeholder="내용을 입력해주세요"
                      ></v-textarea>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col cols="12" md="12" class="py-3 text-center">
                  <v-btn
                    large
                    :disabled="
                      getDataLoading ||
                      overdueStatuses.length < 1 ||
                      !!!overdueStatuses[0].idx
                    "
                    :loading="getDataLoading"
                    @click="saveFundingDataOverdueStatus(overdueStatuses[0])"
                  >
                    어드민 공지 저장
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="6" sm="6" md="6">
              <v-row no-gutters>
                <v-col cols="12" md="12">
                  <h3 class="text-center">추심 현황</h3>
                </v-col>

                <v-col cols="12" md="12">
                  <v-data-table
                    no-data-text="추심 현황이 없습니다."
                    no-results-text="추심 현황이 없습니다."
                    loading-text="데이터를 가져오는 중입니다..."
                    :headers="collectHeaders"
                    :loading="getDataLoading"
                    :items="collectDetails"
                    :hide-default-footer="true"
                  >
                    <template v-slot:item="{ item }">
                      <tr class="clickable-data-row">
                        <td class="text-center" @click="openCal(item)">
                          <v-text-field
                            v-model="item.entrustmentTime"
                            label="이관일/발생일"
                            prepend-icon="event"
                            class="pa-0"
                            readonly
                            hide-details
                          ></v-text-field>
                        </td>
                        <td class="text-center">
                          <select
                            v-model="item.description"
                            class="pa-2"
                            style="
                              background-color: white;
                              border: 1px solid #8a94a2;
                            "
                            @change="selectedOption(item, 'description')"
                          >
                            <option
                              v-if="!!item.description"
                              :value="item.description"
                              selected
                            >
                              {{ item.description }}
                            </option>

                            <option v-else disabled selected value="">
                              선택하세요
                            </option>

                            <option
                              v-for="desc in descSelector(item.description)"
                              :key="desc"
                              :value="desc"
                            >
                              {{ desc }}
                            </option>
                          </select>

                          <v-textarea
                            v-show="item.edit === true"
                            v-model="item.description"
                            rows="2"
                            class="mt-2"
                            background-color="white"
                            outlined
                            hide-details
                          ></v-textarea>
                        </td>
                        <td class="text-center">
                          <v-text-field
                            v-model="item.agent"
                            background-color="white"
                            hide-details
                            outlined
                          ></v-text-field>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-col>

                <v-col cols="12" md="12" class="py-3 text-center">
                  <v-btn class="mb-5" center @click="addCollectDetailRow()">
                    추심현황추가
                  </v-btn>

                  <v-btn
                    color="#393939"
                    class="white--text mb-5 ml-3"
                    center
                    depressed
                    @click="saveCollectDetails()"
                  >
                    저장
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </template>
      </v-col>
    </v-row>

    <v-row class="mb-5" no-gutters>
      <v-col cols="1" sm="1" md="1"></v-col>
      <v-col v-if="hideOverdueStatusTable === false" cols="4" sm="4" md="4">
        <OverdueStatus ref="overdueStatus"></OverdueStatus>
      </v-col>
      <v-col cols="1" sm="1" md="1"></v-col>
      <v-col cols="4" sm="4" md="4">
        <OverdueNoticePopup
          :is-funda-now="isFundaNow"
          :funding-data="fundingData"
          :fn-store-funding-data="fnStoreFundingData"
        >
        </OverdueNoticePopup>
      </v-col>
      <v-col cols="2" sm="2" md="2"></v-col>
    </v-row>

    <v-dialog v-model="dialog.show" width="60%" persistent>
      <v-card v-if="!!dialog.overdueStatus">
        <v-card-title>
          <h2 class="mt-1">
            편집중인 상태: {{ dialog.overdueStatus.overdueStatus.name }}
          </h2>
        </v-card-title>
        <v-card-text class="px-3">
          <p class="headline ma-0 mt-2 mb-1">투자자 공지</p>
          <v-textarea
            v-model="dialog.overdueStatus.memoCreditor"
            hide-details
            outlined
            rows="17"
            style="width: 95%"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            style="font-size: 16px"
            text
            @click="saveDialog()"
          >
            닫기
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog ref="calendar" v-model="cal.show" persistent width="320px">
      <v-date-picker
        v-model="cal.selectedCollectDetail.entrustmentTime"
        scrollable
      >
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="cal.show = false">Cancel</v-btn>
        <v-btn text color="primary" @click="datePick()">OK</v-btn>
      </v-date-picker>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    hideOverdueStatusTable: {
      type: Boolean,
      default: false,
    },
    fundingDataIdx: {
      type: Number,
      default: null,
    },
    fnStoreFundingDataIdx: {
      type: Number,
      default: null,
    },
  },
  components: {
    OverdueNoticePopup: () =>
      import('@/components/funda/funding/overdue/OverdueNoticePopup.vue'),
    OverdueStatus: () =>
      import('@/components/funda/funding/overdue/OverdueStatus.vue'),
  },
  watch: {
    fundingDataIdx: {
      immediate: true,
      handler(v) {
        if (!!v) {
          this.setIdxes(v, null)
        }
      },
    },
    fnStoreFundingDataIdx: {
      immediate: true,
      handler(v) {
        if (!!v) {
          this.setIdxes(null, v)
        }
      },
    },
  },
  data: function () {
    return {
      getDataLoading: false,
      isFundaNow: true,
      dialog: {
        show: false,
        selectedOverdueStatus: null,
      },
      cal: {
        show: false,
        selectedCollectDetail: {},
      },
      fundingData: null,
      fnStoreFundingData: null,
      overdueStatusHeaders: [
        {
          text: '연체 상태',
          value: 'overdueStatus.name',
          align: 'center',
          sortable: false,
        },
        {
          text: '연체 사유',
          value: 'reason',
          align: 'center',
          width: '30%',
          sortable: false,
        },
        {
          text: '투자자 참고사항',
          value: 'memoCreditor',
          align: 'center',
          width: '25%',
          sortable: false,
        },
        {
          text: '발생일',
          value: 'createdTime',
          align: 'center',
          width: '15%',
          sortable: false,
        },
        { text: '-', align: 'center', sortable: false },
      ],
      overdueStatuses: [],
      reasonOptions: [
        '차주의 개인회생 신청으로 추심이 금지된 상태입니다.',
        '차주의 신용회복 지원 상태로 추심이 금지된 상태입니다.',
        '차주의 상환능력 상실에 따른 파산 준비중으로 연체가 발생하였습니다.',
        '상점 폐업에 따른 연체가 발생하였습니다.',
        '차주 사망이 확인되어 연체가 발생하였습니다.',
        '상점 휴업에 따른 연체가 발생하였습니다.',
        '상점 매출 하락에 따른 연체가 발생하였습니다.',
        '상점 공사로 인한 연체가 발생하였습니다.',
        '개인회생 신청중',
        '직접 입력',
      ],
      collectHeaders: [
        {
          text: '이관일(발생일)',
          value: 'entrustmentTime',
          align: 'center',
          width: '30%',
        },
        {
          text: '진행 내용',
          value: 'description',
          align: 'center',
          sortable: false,
        },
        {
          text: '추심자',
          value: 'agent',
          align: 'center',
          sortable: false,
          width: '20%',
        },
      ],
      collectDetails: [],
      descOptions: [
        '연체가 해소되어 원리금 상환이 완료되었습니다.',
        '지급명령을 접수하였습니다.',
        '추심명령 접수(계좌 압류)를 신청하였습니다.',
        '추심명령 접수(카드매출 압류)를 신청하였습니다.',
        '추심명령 접수(보증금 압류)를 신청하였습니다.',
        '유체동산 압류(차주 자택)를 신청하였습니다.',
        '유체동산 압류(사업장)를 신청하였습니다.',
        '전문 추심기관에 채권 추심을 의뢰하였습니다.',
        'NPL사에 채권 매각을 의뢰하였습니다.',
        '전문 추심기관의 채권추심이 완료되었습니다.',
        '채권 매각을 완료하였습니다.',
        '직접 입력',
      ],
    }
  },
  computed: {
    isResolve() {
      return this.overdueStatuses.some((item) => {
        return item.overdueStatusIdx === 1
      })
    },
    reasonSelector() {
      const os = this.overdueStatuses[0]
      return this.reasonOptions.filter((reason) => {
        return reason !== os.reason
      })
    },
    descSelector() {
      return (selected) => {
        return this.descOptions.filter((desc) => {
          return desc !== selected
        })
      }
    },
  },
  methods: {
    getStatusName(statusIndex) {
      return this.$refs.overdueStatus.getStatusName(statusIndex)
    },
    addCollectDetailRow() {
      let row = {
        idx: null,
        fundingDataIdx: this.fundingData.idx,
        fnStoreFundingDataIdx: !!this.fnStoreFundingData
          ? this.fnStoreFundingData.idx
          : null,
        entrustmentTime: new Date().toISOString().substr(0, 10),
        description: '',
        agent: '펀다',
      }

      this.collectDetails.push(row)
    },
    addFundingOverdueRow(isResolve) {
      let lastItem = this.overdueStatuses[this.overdueStatuses.length - 1]
      let row = {
        idx: null,
        fundingDataIdx: this.fundingData.idx,
        overdueStatusIdx: null,
        fnStoreFundingDataIdx: !!this.fnStoreFundingData
          ? this.fnStoreFundingData.idx
          : null,
        reason: '',
        memoCreditor: null,

        overdueStatus: {
          name: null,
        },
      }

      if (isResolve === true) {
        row.overdueStatusIdx = 1
      } else {
        row.overdueStatusIdx = !!lastItem ? lastItem.overdueStatusIdx + 1 : 2
      }

      try {
        row.overdueStatus.name = this.getStatusName(row.overdueStatusIdx)
        this.overdueStatuses.push(row)
      } catch (e) {
        this.$swal.basic.error({
          text: '더 이상 연체상태를 추가할 수 없습니다.',
        })
      }
    },
    setIdxes(fundingDataIdx, fnStoreFundingDataIdx) {
      if (!!fundingDataIdx) {
        this.fundingDataIdx = fundingDataIdx
        this.fnStoreFundingDataIdx = null
        this.isFundaNow = false
      }

      if (!!fnStoreFundingDataIdx) {
        this.fundingDataIdx = null
        this.fnStoreFundingDataIdx = fnStoreFundingDataIdx
        this.isFundaNow = true
      }

      this.getOverdueStatuses()
    },
    openDialog(obj) {
      this.dialog.overdueStatus = obj

      this.dialog.show = true
    },
    async saveDialog() {
      await this.$swal.basic.warning(
        '반드시!!! 팝업 밖에서 [저장] 버튼을 눌러주세요.'
      )
      this.dialog.show = false
    },
    openCal(obj) {
      this.cal.show = true
      this.cal.selectedCollectDetail = obj
    },
    datePick() {
      this.$refs.calendar.save(this.cal.selectedCollectDetail.entrustmentTime)
    },
    selectedOption(v, key) {
      if (!!!v.edit) {
        v.edit = false
      }

      if (v[key] === '직접 입력') {
        v.edit = true
      } else {
        v.edit = false
      }
    },
    async getDataByFundingDataIdx() {
      this.getDataLoading = true

      let q = `
          data: getFundingData(fundingDataIdx: ${this.fundingDataIdx}) {
            idx
            title
            totalAmount
            registerDate
            repaymentStatus
            bankruptcyNotice
            
            fundingDataOverdueStatuses {
              idx
              fundingDataIdx
              fnStoreIdx
              overdueStatusIdx
              reason
              memoAdmin
              memoCreditor
              createdTime

              overdueStatus {
                name
              }

              fundingDataOverdueRepaymentPlans {
                idx
                orderIdx
                fundingDataIdx
                fnStoreFundingDataIdx
                fundingDataOverdueStatusIdx
                plan
                createdTime
              }
            }

            fundingDataCollectDetails {
              idx
              fundingDataIdx
              fnStoreFundingDataIdx
              entrustmentTime
              description
              agent
            }
          }
        `

      try {
        let data = await this.$fundaApi.query(gql`{${q}}`)

        this.fundingData = data
        this.overdueStatuses = this.fundingData.fundingDataOverdueStatuses
      } finally {
        this.getDataLoading = false
      }
    },
    async getDataByFnStoreFundingDataIdx() {
      this.getDataLoading = true

      let q = `
          data: getFnStoreFundingData(idx: ${this.fnStoreFundingDataIdx}) {
            idx
            fundingDataIdx
            fnStoreIdx
            bisangAmount
            repaymentPrincipal

            fundingData {
              idx
              title
              totalAmount
              registerDate
              repaymentStatus
              bankruptcyNotice
            }
            
            fnStore {
              storeName
            }
            
            fundingDataOverdueStatuses {
              idx
              fundingDataIdx
              fnStoreFundingDataIdx
              overdueStatusIdx
              reason
              memoAdmin
              memoCreditor
              createdTime

              overdueStatus {
                name
              }
            }

            fundingDataOverdueRepaymentPlans {
              idx
              orderIdx
              fundingDataIdx
              fnStoreFundingDataIdx
              fundingDataOverdueStatusIdx
              plan
              createdTime
            }

            fundingDataCollectDetails {
              idx
              fundingDataIdx
              fnStoreFundingDataIdx
              entrustmentTime
              description
              agent
            }
          }
        `

      try {
        let data = await this.$fundaApi.query(gql`{${q}}`)

        this.fnStoreFundingData = data
        this.fundingData = this.fnStoreFundingData.fundingData
        this.overdueStatuses =
          this.fnStoreFundingData.fundingDataOverdueStatuses
        this.collectDetails = this.fnStoreFundingData.fundingDataCollectDetails
      } finally {
        this.getDataLoading = false
      }
    },
    getOverdueStatuses() {
      if (this.isFundaNow === true) {
        this.getDataByFnStoreFundingDataIdx()
      } else {
        this.getDataByFundingDataIdx()
      }
    },
    async saveFundingDataOverdueStatus(v) {
      let yes = await this.$swal.basic.confirm('연체상태를 업데이트합니다??')

      if (yes.isConfirmed === false) {
        return
      }

      this.getDataLoading = true

      let param = { ...v }
      delete param.createdTime
      delete param.overdueStatus
      delete param.edit
      if (param.reason === '') {
        param.reason = null
      }

      let q = `
          data: modifyFundingDataOverdueStatus(inputFundingDataOverdueStatus: $input) {
            idx
            fundingDataIdx
            fnStoreFundingDataIdx
            overdueStatusIdx
            reason
            memoAdmin
            memoCreditor
            createdTime
            
            overdueStatus {
              name
            }
          }
        `

      try {
        let data = await this.$fundaApi.mutation(
          gql`{${q}}`,
          'call($input: InputFundingDataOverdueStatus!)',
          { input: param }
        )

        if (!!data) {
          this.$swal.basic.success({ text: '연체상태가 업데이트되었습니다!!' })
          this.getOverdueStatuses()
        }
      } finally {
        this.getDataLoading = false
      }
    },
    async saveCollectDetails() {
      let yes = await this.$swal.basic.confirm({
        text: '추심현황을 업데이트합니다??',
      })

      if (yes.isConfirmed === false) {
        return
      }

      let params = this.collectDetails.reduce((arr, d) => {
        if (!!d.description && d.description !== '') {
          let param = { ...d }
          delete param.edit
          arr.push(param)
        }
        return arr
      }, [])

      if (params.length < 1) {
        await this.$swal.basic.warning('변경할 내용이 없습니다.')
        return
      }

      this.getDataLoading = true

      let q = `
          data: modifyCollectDetails(inputFundingDataCollectDetails: $input) {
            idx
            fundingDataIdx
            fnStoreFundingDataIdx
            entrustmentTime
            description
            agent
          }
        `

      try {
        let data = await this.$fundaApi.mutation(
          gql`{${q}}`,
          'call($input: [InputFundingDataCollectDetail]!)',
          { input: params }
        )

        if (!!data) {
          this.$swal.basic.success({ text: '추심현황이 업데이트되었습니다!!' })
          this.fnStoreFundingData.fundingDataCollectDetails = data
          this.collectDetails =
            this.fnStoreFundingData.fundingDataCollectDetails
        }
      } finally {
        this.getDataLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
