import client from '../client'
import qs from 'query-string'

export default {
  test() {
    return client.call({
      url: '/',
      method: 'GET',
    })
  },
  postSms(data) {
    return client.call({
      url: '/sms',
      method: 'POST',
      data: data,
    })
  },
  getSmses(param) {
    return client.call({
      url: `/smses?${qs.stringify(param, {
        skipNull: true,
      })}`,
      method: 'GET',
    })
  },
}
