import client from '../client'
import qs from 'query-string'

export default {
  getSystemUserVirtualAccounts() {
    return client.call({
      url: '/system/user/virtual/accounts',
      method: HttpMethod.GET,
    })
  },
  postSystemUserVirtualAccount(userVirtualAccountIdx, data) {
    return client.call({
      url: `/system/user/virtual/accounts/${userVirtualAccountIdx}`,
      method: HttpMethod.POST,
      data,
    })
  },
  getSystemInterestAutoTransfers() {
    return client.call({
      url: '/system/interest/auto/transfers',
      method: HttpMethod.GET,
    })
  },
  postSystemInterestAutoTransfer(data) {
    return client.call({
      url: '/system/interest/auto/transfers',
      method: HttpMethod.POST,
      data,
    })
  },
  deleteSystemInterestAutoTransfer(systemInterestAutoTransferIdx) {
    return client.call({
      url: `/system/interest/auto/transfers/${systemInterestAutoTransferIdx}`,
      method: HttpMethod.DELETE,
    })
  },
  getSystemInterestAutoTransferTransactions(param) {
    return client.call({
      url: `/system/interest/auto/transfers/transactions?${qs.stringify(param, {
        skipNull: true,
        skipEmptyString: true,
      })}`,
      method: HttpMethod.GET,
    })
  },
  /**
   * 은행 점검 내역 조회
   */
  getBankInspections(param) {
    return client.call({
      url: `/bank/inspections?${qs.stringify(param)}`,
      method: HttpMethod.GET,
    })
  },
  /**
   * 은행 점검 단건 조회
   */
  getBankInspection(idx) {
    return client.call({
      url: `/bank/inspection/${idx}`,
      method: HttpMethod.GET,
    })
  },
  /**
   * 은행 점검 생성
   */
  postBankInspection(data) {
    return client.call({
      url: '/bank/inspection',
      method: HttpMethod.POST,
      data,
    })
  },
  /**
   * 은행 점검 수정
   */
  putBankInspection(idx, data) {
    return client.call({
      url: `/bank/inspection/${idx}`,
      method: HttpMethod.PUT,
      data,
    })
  },
}
