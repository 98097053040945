<template>
  <v-list dense>
    <VisitHistory :activeMenu="activeMenu" />
    <template v-for="item in accessItems">
      <v-list-group
        v-if="item.children"
        :key="item.text"
        v-model="item.active"
        :prepend-icon="item.icon"
        no-action
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </template>
        <v-list-item
          v-for="(child, i) in accessChildren(item.children)"
          :key="i"
          :to="child.route"
          :href="child.href"
          :target="child.route ? '_self' : '_blank'"
          :class="[
            {
              'router-link-active--false':
                child.route && activeMenu != child.route.name,
            },
            {
              'v-list-item--active':
                child.route && activeMenu == child.route.name,
            },
          ]"
        >
          <v-list-item-content>
            <template v-if="!!child.count">
              <CountText :key="child.count" :count-type="child.count"
                ><v-list-item-title v-text="child.text"></v-list-item-title
              ></CountText>
            </template>
            <template v-else>
              <v-list-item-title v-text="child.text"></v-list-item-title>
            </template>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
      <v-list-item
        v-else
        :key="item.text"
        v-model="item.active"
        link
        :to="item.route"
        :class="{
          'router-link-active--false':
            item.route && activeMenu != item.route.name,
        }"
      >
        <v-list-item-action>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-list>
</template>

<script>
import CountText from '@/components/common/text/CountText.vue'
import VisitHistory from '../page/VisitHistory.vue'

export default {
  components: { CountText, VisitHistory },
  data() {
    return {
      items: [
        {
          icon: 'mdi-home',
          active: false,
          text: 'Home',
          route: { name: 'main' },
        },
        {
          icon: 'mdi-account-multiple',
          text: '투자자',
          auth: ['ROLE_SUPERADMIN', 'ROLE_ADMIN'],
          children: [
            {
              text: '유저 인증 리스트',
              route: { name: 'fundaUserAuth' },
              count: 'countInvestorAuth',
              auth: ['ROLE_PERSONAL_INFO_ADMIN', 'ROLE_SUPERADMIN'],
            },
            { text: '유저 목록', route: { name: 'fundaUsers' } },
            { text: '유저별 상환내역', route: { name: 'fundaUserRepayments' } },
            {
              text: '투자자 정보 데이터',
              route: { name: 'fundaUserPersonal' },
            },
          ],
        },
        {
          icon: 'mdi-cash-multiple',
          text: '대출자',
          auth: ['ROLE_SUPERADMIN', 'ROLE_LOAN_ADMIN'],
          children: [
            { text: '고객', route: { name: 'fundanow/users' } },
            {
              text: '(임시)가상계좌목록',
              route: {
                name: 'payment/users/virtual/accounts',
                query: { isClosed: false },
              },
            },
            {
              text: '대출 목록',
              route: {
                name: 'fundanow/loans/list',
                query: {
                  loanTypes: ['normal'],
                  statuses: ['investing', 'invested', 'executing'],
                },
              },
            },
            { text: '대출-상환 내역', route: { name: 'fnStoreRepayment' } },
            { text: '연체 관리', route: { name: 'fnOverdueManagement' } },
            { text: '공시상품', route: { name: 'fundingDetails' } },
            {
              text: '수동 상환',
              route: { name: 'fundanow/repayment/manual' },
            },
            {
              text: '메일',
              route: { name: 'fundanow/mails' },
            },
          ],
        },
        {
          icon: 'mdi-alpha-p-box-outline',
          text: 'Payment',
          auth: ['ROLE_SUPERADMIN', 'ROLE_PAYMENT_ADMIN'],
          children: [
            { text: '사용자', route: { name: 'payment/users' } },
            {
              text: '가상계좌',
              route: {
                name: 'payment/users/virtual/accounts',
                query: { isClosed: false },
              },
            },
            { text: '반환', route: { name: 'payment/user/refunds' } },
            {
              text: '이체',
              route: { name: 'payment/user/virtual/account/transfers' },
            },
            {
              text: 'callback',
              route: { name: 'payment/callbacks', query: { isProcess: false } },
            },
            {
              text: '예약 이체',
              route: {
                name: 'payment/user/virtual/account/transfers/reservations',
              },
            },
            { text: '관리', route: { name: 'payment/admin' } },
            {
              text: '상환',
              route: { name: 'payment/payout/reserve' },
            },
          ],
        },
        {
          icon: 'mdi-calendar-blank',
          text: '펀다 - 기타',
          auth: ['ROLE_SUPERADMIN', 'ROLE_ADMIN'],
          children: [
            { text: '월간 공시', route: { name: 'noticeManagement' } },
            {
              text: '연체율 공시',
              route: { name: 'funda/stats/overdue/amounts' },
            },
            { text: 'SMS 관리', route: { name: 'smsManagement' } },
            { text: '포인트 관리', route: { name: 'pointManagement' } },
            { text: '유저 알람 관리', route: { name: 'alarmManagement' } },
            { text: '펀다 팝업 관리', route: { name: 'popupManagement' } },
            { text: '동산 상환', route: { name: 'dongsanRepayment' } },
            {
              text: '마케팅론',
              route: {
                name: 'marketing-loan',
                query: {
                  loanTypes: ['marketing'],
                  statuses: ['investing', 'invested', 'executing'],
                },
              },
            },
            {
              text: '아파트론',
              route: {
                name: 'second-mortgage-loan',
                query: {
                  loanTypes: ['second_mortgage'],
                  statuses: ['executed'],
                },
              },
            },
          ],
        },
        {
          icon: 'mdi-cash-multiple',
          text: '선정산',
          auth: ['ROLE_SUPERADMIN', 'ROLE_ADMIN'],
          children: [
            { text: '사업자 선택', route: { name: 'myfinbiz/login' } },
            { text: '가맹점 관리', route: { name: 'myfinbiz/customers' } },
            { text: '선정산 관리', route: { name: 'myfinbiz/early-payments' } },
            { text: '회계 관리', route: { name: 'myfinbiz/accounting' } },
            { text: '설정 관리', route: { name: 'myfinbiz/settings' } },
          ],
        },
        {
          icon: 'mdi-message-text-clock-outline',
          text: '알림톡',
          auth: ['ROLE_ADMIN'],
          children: [
            {
              text: '알림톡 발송',
              route: { name: 'alimtalk/message/groups/form' },
            },
            {
              text: '발송 내역 조회',
              route: { name: 'alimtalk/message/groups' },
            },
            { text: '템플릿 조회', route: { name: 'alimtalk/templates' } },
            {
              text: '비즈엠 바로가기',
              href: 'https://www.bizmsg.kr/dashboard/',
            },
          ],
        },
        {
          icon: 'mdi-cash-check ',
          text: '회계',
          auth: ['ROLE_ACCOUNTING_ADMIN', 'ROLE_SUPERADMIN'],
          children: [
            {
              text: '펀다 가상 계좌 거래 내역',
              route: {
                name: 'accounting/users/virtual/accounts/transactions',
                params: {
                  userIdx: 1,
                  userVirtualAccountIdx: 1,
                },
              },
            },
            { text: '현금영수증', route: { name: 'cashReceipt' } },
            { text: '금융실비 내역', route: { name: 'bankFees' } },
          ],
        },
        {
          icon: 'mdi-test-tube',
          text: '테스트',
          auth: ['ROLE_SUPERADMIN'],
          children: [
            { text: '테스트', route: { name: 'test' } },
            { text: '파일 업로드 테스트', route: { name: 'test/file' } },
            { text: 'sms 발송 테스트', route: { name: 'test/smses/send' } },
            { text: 'sms 조회 테스트', route: { name: 'test/smses' } },
            { text: 'bnk 계좌 배치등록', route: { name: 'test/bnk' } },
          ],
        },
        {
          icon: 'mdi-pencil-ruler',
          text: 'Design',
          auth: ['ROLE_SUPERADMIN'],
          children: [
            { text: '목록', route: { name: 'design/list' } },
            { text: '상세', route: { name: 'design/detail' } },
          ],
        },
        {
          icon: 'mdi-bank-off',
          active: false,
          text: '은행 점검',
          route: { name: 'bank/inspections' },
        },
        // {
        //   icon: 'mdi-robot',
        //   text: 'AI CRM',
        //   auth: ['ROLE_SUPERADMIN', 'ROLE_ADMIN'],
        //   children: [
        //     {
        //       text: '스마트스토어 목록',
        //       route: { name: 'ai/smart-store/list' },
        //     },
        //   ],
        // },
      ],
    }
  },
  computed: {
    activeMenu() {
      const name = this.$route.name
      if (name == null) {
        return ''
      }

      // this.$route.matched[0] : parent 라우터 또는 자기 자신
      const firstMatchedRouter = this.$router.options.routes.filter(
        (router) => {
          return router.path === this.$route.matched[0].path
        }
      )[0]

      return firstMatchedRouter ? firstMatchedRouter.name : ''
    },
    accessItems() {
      let items = this.items.filter((v) => {
        return v.auth == null || this.$store.getters['auth/hasAnyRole'](v.auth)
      })

      return items
    },
  },
  methods: {
    accessChildren(items) {
      let result = items.filter((v) => {
        return v.auth == null || this.$store.getters['auth/hasAnyRole'](v.auth)
      })

      return result
    },
  },
}
</script>
<style lang="scss" scoped>
.router-link-active--false {
  color: #000000de;
  &::before {
    opacity: 0;
  }
}
</style>
