<template>
  <v-container fluid class="pt-0">
    <PageHeader title="아파트론 채권 목록" subtitle="채권 목록" tabMenu>
    </PageHeader>
    <LoansTable :isQueryPaging="true" :query="query" />
  </v-container>
</template>
<script>
import LoansTable from '@/components/fundanow/loan/LoansTable.vue'

export default {
  components: { LoansTable },
  props: {
    query: {
      type: Object,
      default() {
        return {}
      },
    },
  },
}
</script>
