import AuthService from '@/service/auth/AuthService'

export default [
  {
    name: 'signin',
    path: '/signin',
    beforeEnter(to, from, next) {
      if (AuthService.isSignin()) {
        next({ name: 'main' })
      } else {
        next()
      }
    },
    meta: {
      layout: 'EmptyLayout',
    },
    component: require('@/pages/signin/Signin.vue').default,
  },
]
