export default [
  {
    name: 'bank/inspections',
    path: '/bank/inspections',
    meta: { title: '은행 점검 내역' },
    component: require('@/pages/bank/inspection/BankInspections.vue').default,
    props: (route) => ({
      query: route.query,
    }),
  },
  {
    name: 'bank/inspections/detail',
    path: '/bank/inspections/:idx',
    props: (route) => ({
      idx: Number(route.params.idx),
    }),
    meta: { title: '은행 점검 내역 상세' },
    component: require('@/pages/bank/inspection/BankInspectionDetail.vue')
      .default,
  },
]
