var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.dataTable.headers,"items":_vm.dataTable.items,"options":_vm.dataTable.options,"loading":_vm.dataTable.isLoading,"server-items-length":_vm.dataTable.totalCount,"footer-props":_vm.dataTable.footer},on:{"update:options":function($event){return _vm.$set(_vm.dataTable, "options", $event)}},scopedSlots:_vm._u([{key:"item.idx",fn:function(ref){
var idx = ref.item.idx;
return [_c('router-link',{attrs:{"to":{
          name: 'bank/inspections/detail',
          params: { idx: idx },
        }}},[_vm._v(" "+_vm._s(idx)+" ")])]}},{key:"item.bankCode",fn:function(ref){
        var bankCode = ref.item.bankCode;
return [_vm._v(" "+_vm._s(_vm._f("bankCodeName")(bankCode))+" ")]}},{key:"item.startTime",fn:function(ref){
        var startTime = ref.item.startTime;
return [_vm._v(" "+_vm._s(_vm._f("moment")(startTime,'yyyy-MM-DD HH:mm:ss'))+" ")]}},{key:"item.endTime",fn:function(ref){
        var endTime = ref.item.endTime;
return [_vm._v(" "+_vm._s(_vm._f("moment")(endTime,'yyyy-MM-DD HH:mm:ss'))+" ")]}},{key:"item.isActive",fn:function(ref){
        var isActive = ref.item.isActive;
return [_c('span',{class:{ 'primary--text': isActive }},[_vm._v(" "+_vm._s(isActive == true ? '활성화' : '비활성화')+" ")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }