<template>
  <v-row class="invest-details__row" no-gutters>
    <v-col md="3" lg="2">
      <p
        class="
          invest-details__sub-title
          ma-0
          font-weight-bold
          font-size--15
          color--black
        "
      >
        연체/부실
      </p>
    </v-col>

    <v-col md="5" lg="5">
      <MyOverdue :userIdx="userIdx" />
    </v-col>

    <v-col md="4" lg="5">
      <MyInsolvent :userIdx="userIdx" />
    </v-col>
  </v-row>
</template>

<script>
import MyOverdue from './MyOverdue.vue'
import MyInsolvent from './MyInsolvent.vue'

export default {
  props: {
    userIdx: {
      default: null,
    },
  },
  components: {
    MyOverdue,
    MyInsolvent,
  },
}
</script>

<style lang="scss" scoped>
.invest-details__row ::v-deep {
  .invest-details {
    &__key,
    &__val {
      line-height: 1.3;
    }
  }
  @media screen and (min-width: 1200px) {
    > div:nth-child(2) {
      padding-right: 40px;
    }
    > div:nth-child(3) {
      padding-left: 40px;
    }
  }
  &.mobile {
    .invest-details {
      &__key {
        font-size: 15px;
        white-space: nowrap;
      }

      &__val {
        font-size: 17px;
      }
    }
  }

  &:not(.mobile) {
    .invest-details {
      &__key {
        font-size: 14px;
        white-space: nowrap;
      }

      &__val {
        font-size: 16px;
      }
    }
  }
}
</style>
