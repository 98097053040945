<template>
  <div>
    <v-card class="py-3">
      <v-card-title
        class="py-1 headline f-light-grey justify-center text-center"
        style="background-color: white"
      >
        <v-row no-gutters>
          <v-col cols="2"> </v-col>
          <v-col cols="8" class="position--relative">
            {{ rangeText }} <br />
            투자 현황

            <v-btn
              color="indigo"
              style="top: 0; right: 15px"
              absolute
              small
              fab
              dark
              @click="dateDialog = !dateDialog"
            >
              <v-icon dark> mdi-calendar </v-icon>
            </v-btn>

            <v-dialog v-model="dateDialog" width="320px">
              <v-date-picker
                v-model="searchRange"
                range
                scrollable
              ></v-date-picker>
            </v-dialog>
          </v-col>
          <v-col cols="2" class="text-right">
            <ExportExcelButton2
              :headers="exportHeaders"
              :data="investmentStat()"
              :options="{ skipHeader: true }"
              fileName="투자_현황"
            >
              <template v-slot:button="{ download }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" @click="download">
                      <v-icon>mdi-export-variant</v-icon>
                    </v-btn>
                  </template>
                </v-tooltip>
              </template>
            </ExportExcelButton2>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="py-2">
        <v-row no-gutters>
          <v-col cols="12" class="mb-2 text-right">
            <v-tabs v-model="tabs" left slider-color="indigo">
              <v-tab href="#tab-day"> 일별 </v-tab>
              <v-tab href="#tab-time"> 시간별 </v-tab>
            </v-tabs>
          </v-col>
        </v-row>

        <template v-if="isLoading">
          <div class="text-center">
            <v-progress-circular indeterminate size="25" />
          </div>
        </template>

        <template v-else>
          <v-row class="mb-2" no-gutters>
            <template v-if="!!showStat">
              <v-col cols="12" class="mb-2">
                <h2 class="d-flex align-center justify-center text-center">
                  <v-btn icon @click="dateArrow(false)">
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                  <v-menu offset-x offset-y max-height="60%">
                    <template v-slot:activator="{ on, attrs }">
                      <a
                        class="position--relative color--black"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ showDateText }}
                        <span
                          class="detail-arrow"
                          style="top: 4px; left: unset; right: -15px"
                        ></span>
                      </a>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(stat, index) in investmentStat()"
                        :key="`${stat.date}-${index}`"
                        class="cursor--pointer"
                        @click="selectStat(stat)"
                      >
                        <v-list-item-title>
                          {{ stat.date }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <v-btn class="ml-4" icon @click="dateArrow(true)">
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </h2>
              </v-col>
              <v-col cols="4">
                <h3 class="my-2 text-center">총 투자금액</h3>
                <p class="font-size--16 text-center">
                  {{ showStat.sumAmt | commaFormat }} 원
                </p>
              </v-col>
              <v-col cols="4" class="cursor--pointer" @click="clickInvestors()">
                <h3 class="my-2 text-center">투자자수</h3>
                <p class="font-size--16 text-center">
                  {{ showStat.cnt | commaFormat }} 명
                </p>
              </v-col>
              <v-col cols="4">
                <h3 class="my-2 text-center">평균 투자액</h3>
                <p class="font-size--16 text-center">
                  {{ showStat.avgAmt | commaFormat }} 원
                </p>
              </v-col>
            </template>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12">
              <v-tabs-items v-model="tabs">
                <v-tab-item value="tab-day">
                  <keep-alive>
                    <InvestmentLineChart
                      v-if="!!chart.daily"
                      :key="rangeText"
                      id="main-investment-stat"
                      :settings="chart.settings"
                      :rawData="chart.daily"
                      :options="chart.options"
                    />
                  </keep-alive>
                </v-tab-item>

                <v-tab-item value="tab-time">
                  <keep-alive>
                    <InvestmentLineChart
                      v-if="!!chart.timeline"
                      :key="showDateText"
                      id="main-investment-stat-time"
                      :settings="chart.settings"
                      :rawData="chart.timeline"
                      :options="chart.options"
                    />
                  </keep-alive>
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>
        </template>
      </v-card-text>

      <v-card-actions>
        <ul class="font-size--14">
          <li>
            집계 내용은 투자상품 기준이 아닌 투자일 기준입니다.
            <br />
            (어제 공시된 상품을 오늘 투자할 경우 오늘 투자자수에 집계됨)
          </li>
        </ul>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import ExportExcelButton2 from '@/components/common/datatable/ExportExcelButton2'
import InvestmentLineChart from '@/components/common/chart/InvestmentLineChart'

export default {
  components: {
    ExportExcelButton2,
    InvestmentLineChart,
  },
  data() {
    return {
      tabs: 'tab-day',
      isLoading: false,
      isChartLoading: false,
      nowDate: new Date(),
      searchRange: [],
      dateDialog: false,
      investmentStatData: {
        daily: null,
        timeline: {},
      },
      selectedStat: {
        daily: null,
        timeline: null,
      },
      chart: {
        settings: ['date', '투자자 수', '총 투자액(단위: 천원)'],
        options: {
          title: '최근 10일 투자 추이',
          height: 400,
          chartArea: {
            width: '75%',
          },
          legend: {
            position: 'bottom',
          },
          series: {
            0: { targetAxisIndex: 0 },
            1: { targetAxisIndex: 1 },
          },
          vAxes: {
            // Adds titles to each axis.
            0: { title: '투자자 수' },
            1: { title: '총 투자액 (단위: 천원)' },
          },
          fontSize: 14,
        },
        daily: null,
        timeline: null,
      },
      exportHeaders: [
        { text: '일자', value: 'date' },
        { text: '투자자 수', value: 'cnt' },
        { text: '총 투자액', value: 'sumAmt' },
        { text: '평균투자액', value: 'avgAmt' },
      ],
    }
  },
  computed: {
    rangeText() {
      return `${this.searchRange[0]} ~ ${this.searchRange[1]}`
    },
    isTime() {
      return this.tabs === 'tab-time'
    },
    statColumn() {
      let column = 'daily'
      if (this.isTime) {
        column = 'timeline'
      }
      return column
    },
    showStat() {
      return this.selectedStat[this.statColumn]
    },
    showDateText() {
      let text = this.showStat?.date
      if (this.isTime) {
        text = `${this.selectedStat.daily.date} ${text}시`
      }
      return text
    },
  },
  watch: {
    searchRange: {
      deep: true,
      handler(v) {
        if (v.length === 2) {
          if (v[0] < v[1]) {
            this.tabs = 'tab-day'
            this.dateDialog = false
            this.fetchInvestment()
          }
        }
      },
    },
    tabs() {
      if (this.isTime) {
        this.fetchInvestment()
      }
    },
  },
  created() {
    let tempStartDate = new Date().setDate(1)
    this.searchRange.push(new Date(tempStartDate).toISOString().substr(0, 10))
    this.searchRange.push(this.nowDate.toISOString().substr(0, 10))
  },
  methods: {
    clickInvestors() {
      if (this.isTime === false) {
        this.$emit('update:selectDate', this.selectedStat[this.statColumn].date)
      }
    },
    async fetchInvestment() {
      await this.getInvestmentStatData()
      this.chartSetting()
    },
    investmentStat() {
      let result = this.investmentStatData.daily
      if (this.isTime) {
        result = this.investmentStatData.timeline[this.selectedStat.daily.date]
      }
      return result
    },
    selectStat(stat) {
      this.selectedStat[this.statColumn] = stat
    },
    dateArrow(isNext) {
      let current = this.investmentStat().indexOf(this.showStat)

      if (isNext === false) {
        current--
      } else {
        current++
      }

      if (current < 0 || current >= this.investmentStat().length) {
        return
      }

      this.selectStat(this.investmentStat()[current])
    },
    chartSetting() {
      this.isChartLoading = true

      let newArr = []
      let chartStat = null

      if (this.isTime) {
        this.chart.options.title = '시간별 투자 추이'
        chartStat = this.investmentStat()
      } else {
        this.chart.options.title = '최근 10일 투자 추이'
        chartStat = this.investmentStat().slice(-10)
      }

      for (let index = 0; index < chartStat.length; index++) {
        const stat = chartStat[index]
        let arr = []

        if (this.isTime) {
          arr.push(`${String(stat.date).padStart(2, '0')}시`)
        } else {
          let tempDate = new Date(stat.date)

          arr.push(`${tempDate.getMonth() + 1}월 ${tempDate.getDate()}일`)
        }

        arr.push(stat.cnt)
        arr.push(parseInt(stat.sumAmt / 1000))

        newArr.push(arr)
      }

      this.isChartLoading = false
      this.chart[this.statColumn] = newArr
    },
    async getInvestmentStatData() {
      if (this.isTime && !!this.investmentStat()) {
        return
      }

      let startDate = this.searchRange[0]
      let endDate = this.searchRange[1]

      if (this.isTime) {
        startDate = this.selectedStat.daily.date
        endDate = startDate
      }

      let q = `
        data: getAdminInvestmentStat(
          startDate: "${startDate}", 
          endDate: "${endDate}", 
          isTime: ${this.isTime}
        )
      `

      this.isLoading = true

      try {
        let data = await this.$fundaApi.query(gql`{${q}}`)

        data = JSON.parse(data)
        data.forEach((d) => {
          d.avgAmt = parseInt(d.avgAmt)
          d.date = this.isTime
            ? d.date
            : this.$options.filters.dateFormat2(d.date)
        })

        if (this.isTime) {
          this.investmentStatData.timeline[this.selectedStat.daily.date] = data
        } else {
          this.investmentStatData.daily = data
        }

        this.selectStat(data[data.length - 1])
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
