<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12">
        <h4 class="my-2">인증 정보</h4>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th colspan="2" class="text-center border-r border-l">
                  휴대폰
                </th>
                <th colspan="2" class="text-center border-r">신분증</th>
                <th colspan="2" class="text-center border-r">출금계좌</th>
                <th colspan="2" class="text-center border-r">EDD</th>
              </tr>
              <tr>
                <th class="text-center border-l">인증여부</th>
                <th class="text-center border-r">인증시간</th>
                <th class="text-center">인증여부</th>
                <th class="text-center border-r">인증시간</th>
                <th class="text-center">인증여부</th>
                <th class="text-center border-r">인증시간</th>
                <th class="text-center">인증여부</th>
                <th class="text-center border-r">인증시간</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <template v-if="!!userValidation">
                  <td
                    class="text-center border-l"
                    :class="getValidStyle(userValidation.isValidPhone)"
                  >
                    {{ validPhoneText }}
                  </td>
                  <td class="text-center border-r">
                    {{ userValidation.phoneValidTime | datetimeFormat }}
                  </td>
                  <td
                    class="text-center"
                    :class="getValidStyle(userValidation.isValidIdentification)"
                  >
                    {{ validIdText }}
                  </td>
                  <td class="text-center border-r">
                    {{
                      userValidation.identificationValidTime | datetimeFormat
                    }}
                  </td>
                  <td
                    class="text-center"
                    :class="getValidStyle(userValidation.isValidAccount)"
                  >
                    {{ validAccountText }}
                  </td>
                  <td class="text-center border-r">
                    {{ userValidation.accountValidTime | datetimeFormat }}
                  </td>
                  <td
                    class="text-center"
                    :class="getValidStyle(userValidation.isValidEdd)"
                  >
                    {{ validEddText }}
                  </td>
                  <td class="text-center border-r">
                    {{ userValidation.eddValidTime | datetimeFormat }}
                  </td>
                </template>
                <template v-else>
                  <td colspan="8" class="text-center">인증정보가 없습니다.</td>
                </template>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>

    <v-row class="mt-5" no-gutters>
      <v-col cols="12">
        <h4 class="my-2">인증 결과</h4>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-center">승인/거절</th>
                <th class="text-center">거절사유</th>
                <th class="text-center">확인여부(고객)</th>
                <th class="text-center">승인/거절 일시</th>
                <th class="text-center">확인 일시</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="alarms.length > 0">
                <tr v-for="a in alarms" :key="a.idx">
                  <td
                    class="text-center"
                    :class="
                      a.type === 'APPROVAL' ? 'primary--text' : 'error--text'
                    "
                  >
                    {{ a.type === 'APPROVAL' ? '승인' : '거절' }}
                  </td>
                  <td class="text-center">
                    {{ a.memo }}
                  </td>
                  <td class="text-center">
                    {{ a.isClose ? '확인' : '미확인' }}
                  </td>
                  <td class="text-center">
                    {{ a.createTime | datetimeFormat }}
                  </td>
                  <td class="text-center">
                    {{ a.updateTime | datetimeFormat }}
                  </td>
                </tr>
              </template>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    userIdx: {
      type: Number,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      userValidation: null,
      alarms: [],
    }
  },
  computed: {
    validPhoneText() {
      return this.userValidation?.isValidPhone === true ? '완료' : '미인증'
    },
    validIdText() {
      let resp = '파일없음'
      if (this.userValidation?.isValidIdentification === true) {
        resp = '완료'
      } else if (this.userValidation?.userCdds.length > 0) {
        resp = '검토중'
      }

      return resp
    },
    validAccountText() {
      return this.userValidation?.isValidAccount === true ? '완료' : '미인증'
    },
    validEddText() {
      let resp = '미작성'

      if (this.userValidation?.isValidEdd === true) {
        resp = '완료'
      } else if (this.userValidation?.userEdds.length > 0) {
        resp = '검토중'
      }

      return resp
    },
  },
  created() {
    this.getUserValidation()
    this.getAlarm()
  },
  methods: {
    getValidStyle(isValid) {
      return isValid ? 'primary--text' : 'error--text'
    },
    async getUserValidation() {
      let q = `
          data: getUserValidation(userIdx: ${this.userIdx}) {
            isValidPhone
            phoneValidTime
            isValidIdentification
            identificationValidTime
            isValidAccount
            accountValidTime
            isValidEdd
            eddValidTime
            
            userCdds {
              status
            }
            
            userEdds {
              question
              answer
            }
          }
        `
      let data = await this.$fundaApi.query(gql`{${q}}`)
      this.userValidation = data
    },
    async getAlarm() {
      let q = `
          data: getUserValidationAlarm(userIdx: ${this.userIdx}) {
            idx
            userIdx
            userValidationIdx
            type
            memo
            isClose
            createTime
            updateTime
          }
        `

      this.isLoading = true

      try {
        let data = await this.$fundaApi.query(gql`{${q}}`)
        this.alarms = data
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.border-r {
  border-right: 1px solid rgb(0, 0, 0, 0.12);
}

.border-l {
  border-left: 1px solid rgb(0, 0, 0, 0.12);
}
</style>
