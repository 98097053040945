<template>
  <div class="pt-3">
    <v-data-table
      :headers="dataTable.headers"
      :items="dataTable.items"
      :options.sync="dataTable.options"
      :loading="dataTable.isLoading"
      :server-items-length="dataTable.totalCount"
      :footer-props="dataTable.footer"
      item-key="idx"
      class="elevation-2"
      show-expand
    >
      <template v-slot:[`item.body.creditOverdueRate`]="{ item }">
        {{ item.body.creditOverdueRate }}%
      </template>

      <template v-slot:[`item.body.movableOverdueRate`]="{ item }">
        {{ item.body.movableOverdueRate }}%
      </template>

      <template v-slot:[`item.body.totalOverdueRate`]="{ item }">
        {{ item.body.totalOverdueRate }}%
      </template>

      <template v-slot:[`item.isVisible`]="{ item }">
        <span :class="item.isVisible == true ? 'primary--text' : 'error--text'">
          {{ item.isVisible == true ? '공개' : '비공개' }}
        </span>
      </template>

      <template v-slot:[`item.baseTime`]="{ item }">
        {{ item.baseTime | moment('yyyy-MM-DD HH:mm:ss') }}
      </template>

      <template v-slot:[`item._updateIsVisible`]="{ item }">
        <OverdueAmountsUpdateVisibleButton
          :stats="item"
          @update:stats="updateItem(item, $event)"
        />
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td
          class="py-4 px-8"
          :colspan="headers.length"
          style="white-space: break-spaces"
        >
          <div class="v-data-table theme--light">
            <div class="v-data-table__wrapper">
              <table>
                <thead class="v-data-table-header">
                  <tr>
                    <th>구분</th>
                    <th>대출 잔액</th>
                    <th>연체 금액</th>
                    <th>연체율</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>신용</th>
                    <td>{{ item.body.creditLoanBalance | commaFormat }}</td>
                    <td>
                      {{ item.body.creditLoanOverdueBalance | commaFormat }}
                    </td>
                    <td>{{ item.body.creditOverdueRate }}%</td>
                  </tr>
                  <tr>
                    <th>동산</th>
                    <td>{{ item.body.movableLoanBalance | commaFormat }}</td>
                    <td>
                      {{ item.body.movableLoanOverdueBalance | commaFormat }}
                    </td>
                    <td>{{ item.body.movableOverdueRate }}%</td>
                  </tr>
                  <tr>
                    <th>합계</th>
                    <td>{{ item.body.totalLoanBalance | commaFormat }}</td>
                    <td>{{ item.body.totalOverdueAmount | commaFormat }}</td>
                    <td>{{ item.body.totalOverdueRate }}%</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </td>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import OverdueAmountsUpdateVisibleButton from './OverdueAmountsUpdateVisibleButton.vue'
export default {
  components: { OverdueAmountsUpdateVisibleButton },
  props: {
    isQueryPaging: {
      type: Boolean,
      default: false,
    },
    query: {
      type: Object,
      default: (_) => {
        return null
      },
    },
  },
  data() {
    return {
      param: {
        page:
          this.isQueryPaging && this.query.page ? Number(this.query.page) : 1,
        size: this.query.size ? Number(this.query.size) : 20,
      },
      dataTable: {
        isLoading: false,
        options: null,
        headers: [
          { text: '인덱스', value: 'idx', sortable: false },
          {
            text: '신용 대출 연체율',
            value: 'body.creditOverdueRate',
            sortable: false,
          },
          {
            text: '동산 대출 연체율',
            value: 'body.movableOverdueRate',
            sortable: false,
          },
          {
            text: '통합 연체율',
            value: 'body.totalOverdueRate',
            sortable: false,
          },
          { text: '공개여부', value: 'isVisible', sortable: false },
          { text: '기준일시', value: 'baseTime', sortable: false, width: 200 },
          {
            text: '공개여부 변경',
            value: '_updateIsVisible',
            sortable: false,
            width: 120,
          },
        ],
        items: [],
        totalCount: 0,
        footer: {
          'items-per-page-options': [1, 5, 10, 20, 50, 100],
        },
      },
    }
  },
  watch: {
    'dataTable.options': {
      handler: function (options, oldValue) {
        if (oldValue === null) {
          return
        }
        const optionObj = {
          page: options.page,
          size: options.itemsPerPage,
        }
        this.updateOption(optionObj)
      },
      deep: true,
    },
  },
  created() {
    this.dataTable.options = {
      page: this.param.page,
      itemsPerPage: this.param.size,
    }
    this.getPageData(this.param)
  },
  methods: {
    updateOption(option) {
      if (this.isQueryPaging === true) {
        return this.$router.push({
          query: option,
        })
      } else {
        this.getPageData(option)
      }
    },
    async getPageData(option) {
      if (this.dataTable.isLoading === true) {
        return
      }
      try {
        this.dataTable.isLoading = true
        let q = `
          statses: getMainOfficialStatses(mainOfficialStatsesSearch: {
            page: ${option.page}
            size: ${option.size}
          }) {
            data {
              idx
              body {
                totalLoanBalance
                totalOverdueAmount
                totalOverdueRate
                creditLoanBalance
                creditLoanOverdueBalance
                creditOverdueRate
                movableLoanBalance
                movableLoanOverdueBalance
                movableOverdueRate
              }
              isVisible
              baseTime
            },
            paging {
              size
              page
              totalCount
            }
          }
        `
        const {
          statses: {
            paging: { totalCount },
            data,
          },
        } = await this.$fundaApi.query(gql`{${q}}`)
        this.dataTable.items = data
        this.dataTable.totalCount = totalCount
      } finally {
        this.dataTable.isLoading = false
      }
    },
    updateItem(orgItem, newItem) {
      this.$set(
        this.dataTable.items,
        this.dataTable.items.indexOf(orgItem),
        newItem
      )
    },
  },
}
</script>
