import client from '../fundaApiClient'

export default {
  /**
   * ARS 인증 내역 조회
   * @param {*} accountNo
   * @returns
   */
  getBnkAccountarses({ accountNo, birthday }) {
    return client.call({
      url: `/bnkagent/accounts/${accountNo}/arses?birthday=${birthday}`,
      method: 'GET',
    })
  },
  /**
   * 출금 이체 등록 내역 조회
   * @param {*} accountNo
   * @returns
   */
  getBnkAccountTransferRegists(accountNo) {
    return client.call({
      url: `/bnkagent/accounts/${accountNo}/transfer/regists`,
      method: 'GET',
    })
  },
  /**
   * 배치 등록 내역 조회
   * @param {*} accountNo
   * @returns
   */
  getBnkAccountBatchesRegists(accountNo) {
    return client.call({
      url: `/bnkagent/accounts/${accountNo}/batches/regists`,
      method: 'GET',
    })
  },

  /**
   * 출금 이체 등록
   * @param {*} accountNo
   * @returns
   */
  postBnkAccountTransferRegists({ birthday, bankAccount, isLock }) {
    return client.call({
      url: `/bnkagent/accounts/${bankAccount}/transfer/regists`,
      method: 'POST',
      data: { birthday, bankAccount, isLock },
    })
  },

  /**
   * 출금 이체 등록 해지
   * @param {*} accountNo
   * @returns
   */
  deleteBnkAccountTransferRegists({ birthday, bankAccount, isLock }) {
    return client.call({
      url: `/bnkagent/accounts/${bankAccount}/transfer/regists`,
      method: 'DELETE',
      data: { birthday, bankAccount, isLock },
    })
  },

  /**
   * 배치 등록
   * @param {*} accountNo
   * @returns
   */
  postBnkAccountBatchesRegists(accountNo) {
    return client.call({
      url: `/bnkagent/accounts/${accountNo}/batches/regists`,
      method: 'POST',
    })
  },
  /**
   * 배치 등록 해지
   * @param {*} accountNo
   * @returns
   */
  deleteBnkAccountBatchesRegists(accountNo) {
    return client.call({
      url: `/bnkagent/accounts/${accountNo}/batches/regists`,
      method: 'DELETE',
    })
  },
}
