import client from '../client'

export default {
  getList(params) {
    return client.call({
      uri: '/graphql',
      method: 'POST',
      data: {
        query:
          'query($input: SearchCustomersInput!) { data: paginatedCustomers(searchCustomersInput: $input) { page limit total data { id created modified agencyId latestMemoContent user { id name phone isLoanable store { name address1 } contract { status isEarlyPaymentEnabled } } } } }',
        variables: { input: params },
      },
    })
  },

  getDetail(customerId) {
    return client.call({
      uri: '/graphql',
      method: 'POST',
      data: {
        query: `query($customerId: Int!) { 
          data: customer(customerId: $customerId) { 
            id 
            user { 
              id 
              name 
              email 
              phone 
              birthdate 
              residentRegistrationNumber 
              created 
              isLoanable 
              mobileCarrier
              customer {
                id
                organization {
                  name
                  id
                  host
                }
                modified
              }
              store { 
                id
                name 
                businessLicenseNumber 
                address1 
                address2 
                typeOfBusiness 
                isClosed
              }
              salesCredentials {
                id
                uuid
                provider
                userId
                userPassword
                status
                statusChanged
                isForSalesScraping
              }
              repaymentAccount {
                id
                bank
                accountNumber
                holderName
                modified
              }
              collectionAccount {
                id
                bank
                accountNumber
                holderName
                modified
              }
              withdrawalAccount {
                id
                bank
                accountNumber
                holderName
                modified
              }
              contract { 
                id
                status 
                isEarlyPaymentEnabled 
                isLoanEnabled 
                memoTm
                collectionaccountchanges {
                  id
                  uuid
                  cardCompany
                  isExcluded
                }
                agreements {
                  id
                  termId
                  created
                  agreedDate
                  expireDate
                  signatureFile {
                    url
                  }
                  imageFile {
                    url
                  }
                  term {
                    title
                  }
                  offerId
                }
              }
              acquisitionRoute {
                name
              }
            } 
          } 
        }`,
        variables: { customerId },
      },
    })
  },

  getDocuments(customerId) {
    return client.call({
      uri: '/graphql',
      method: 'POST',
      data: {
        query: `query($customerId: Int!) {
          data: usersDocument(customerId: $customerId) {
            id
            uuid
            ownerId
            identificationFile {
              url
            }
            bankbookFile {
              url
            }
            businessLicenseFile {
              url
            }
            certificateOfIncomeAmountFile {
              url
            }
          }
        }`,
        variables: { customerId },
      },
    })
  },

  getCreditEvaluations(customerId) {
    return client.call({
      uri: '/graphql',
      method: 'POST',
      data: {
        query: `query($input: SearchEvaluationsCreditevaluationInput!) {
          data: paginatedEvaluationsCreditevaluation(searchEvaluationsCreditevaluationInput: $input) {
            page
            total
            limit
            data {
              id
              uuid
              grade
              status
              isQualified
              hasOverdueLoans
              reason
              statusChanged
            }
          }
        }`,
        variables: {
          input: {
            page: 1,
            limit: 5,
            customerId,
          },
        },
      },
    })
  },

  getRefunds(customerId, params = {}) {
    return client.call({
      uri: '/graphql',
      method: 'POST',
      data: {
        query: `query($input: SearchRefundsInput!) {
          data: paginatedRefunds(searchRefundsInput: $input) {
            page
            limit
            total
            data {
              amount
              created
            }
          }
        }`,
        variables: {
          input: {
            page: params.page || 1,
            limit: params.limit || 10,
            customerId,
          },
        },
      },
    })
  },

  updateContract(input) {
    return client.call({
      uri: '/graphql',
      method: 'POST',
      data: {
        query:
          'mutation($input: UpdateContractsContractInput!) { data: updateContractsContract(updateContractsContractInput: $input) { id status isEarlyPaymentEnabled isLoanEnabled } }',
        variables: { input },
      },
    })
  },

  updateOrganization(organizationId, customerId) {
    return client.call({
      uri: '/graphql',
      method: 'POST',
      data: {
        query: `mutation {
          updateOrganization(organizationId: ${organizationId}, customerId: ${customerId}) {
            id
          }
        }`,
      },
    })
  },

  deleteAllSalesCredentials(userId) {
    return client.call({
      uri: '/graphql',
      method: 'POST',
      data: {
        query: `mutation {
          deleteAllSalesCredentials(userId: ${userId})
        }`,
      },
    })
  },

  getOrganizations() {
    return client.call({
      uri: '/graphql',
      method: 'POST',
      data: {
        query: `query {
          organizationsList {
            id
            name
          }
        }`,
      },
    })
  },

  getCodes() {
    return client.call({
      uri: '/graphql',
      method: 'POST',
      data: {
        query: `query {
          codes {
            payment
            funda
            fundanow
          }
        }`,
      },
    })
  },

  getOrganization(organizationId) {
    return client.call({
      uri: '/graphql',
      method: 'POST',
      data: {
        query: `query {
          data: organization(organizationId: ${organizationId}) {
            id
            name
            code
            host
            collectionBanks {
              bank
            }
          }
        }`,
      },
    })
  },

  updateLoanableStatus(userId, isLoanable) {
    return client.call({
      uri: '/graphql',
      method: 'POST',
      data: {
        query: `mutation($input: UpdateUserIsLoableInput!) {
          data: updateUserIsLoanable(updateUserIsLoableInput: $input) {
            id
          }
        }`,
        variables: {
          input: {
            userId,
            isLoanable,
          },
        },
      },
    })
  },

  updateIsForSalesScraping(input) {
    return client.call({
      uri: '/graphql',
      method: 'POST',
      data: {
        query: `mutation($input: UpdateIsForSalesScrapingInput!) {
          data: updateIsForSalesScraping(updateIsForSalesScrapingInput: $input)
        }`,
        variables: { input },
      },
    })
  },

  updateSalesCredentials(input) {
    return client.call({
      uri: '/graphql',
      method: 'POST',
      data: {
        query: `mutation($input: UpdateSalesCredentialsInput!) {
          data: updateSalesCredentials(updateSalesCredentialsInput: $input)
        }`,
        variables: { input },
      },
    })
  },

  getTransactions(customerId, params = {}) {
    return client.call({
      uri: '/graphql',
      method: 'POST',
      data: {
        query: `query($input: SearchSalesTransactionsInput!) {
          data: salesTransactions(searchSalesTransactionsInput: $input)
        }`,
        variables: {
          input: {
            customerId,
            searchDateType:
              params.searchType === '승인일' ? 'approvedDate' : 'purchaseDate',
            date: params.startDate && new Date(params.startDate).toISOString(),
            date2: params.endDate && new Date(params.endDate).toISOString(),
          },
        },
      },
    })
  },

  getSettlements(customerId, params = {}) {
    return client.call({
      uri: '/graphql',
      method: 'POST',
      data: {
        query: `query($input: SearchEarlyPaymentsInput!) {
          data: paginatedEarlyPayments(searchEarlyPaymentsInput: $input) {
            page
            limit
            total
            data {
              date
              amount
              fee
              adjust
              interest
              deductibleAmount
              refund
              loanId
              connectTransactions {
                type
                amount
                remark
              }
              user {
                store {
                  name
                }
              }
              loan {
                id
                status
                amount
                balance
              }
              salesTotal {
                salesAmount
                expectedFee
              }
            }
          }
        }`,
        variables: {
          input: {
            page: params.page || 1,
            limit: params.limit || 15,
            customerId,
            loanStatuses: params.loanStatus ? [params.loanStatus] : [''],
            startDate:
              params.startDate && new Date(params.startDate).toISOString(),
            endDate: params.endDate && new Date(params.endDate).toISOString(),
            keyword: '',
            organizationId: 4,
          },
        },
      },
    })
  },

  getSettlementDetail(params) {
    return client.call({
      uri: '/graphql',
      method: 'POST',
      data: {
        query: `query($input: SearchEarlyPaymentSalesInput!) {
          data: earlyPaymentSales(searchEarlyPaymentSalesInput: $input)

          earlyPaymentDeduction(searchEarlyPaymentDeductionInput: {
            customerId: ${params.customerId}
            date: "${params.date}"
          })

          earlyPaymentSettlement(
            searchEarlyPaymentSettlementInput: {
              customerId: ${params.customerId},
              date: "${params.date}"
            }
          )
        }`,
        variables: {
          input: {
            customerId: Number(params.customerId),
            date: params.date,
          },
        },
      },
    })
  },

  createPasswordResetToken(userId) {
    return client.call({
      uri: '/graphql',
      method: 'POST',
      data: {
        query: `mutation {
          data: createUserPasswordResetToken(userId: ${userId}) {
            id
            token
            expirationTime
          }
        }`
      }
    })
  },

  sendPasswordResetUrl(input) {
    return client.call({
      uri: '/graphql',
      method: 'POST',
      data: {
        query: `mutation($input: SendPasswordResetUrlInput!) {
          data: sendPasswordResetUrl(sendPasswordResetUrlInput: $input)
        }`,
        variables: { input }
      }
    })
  },

  resetPassword(token, newPassword) {
    return fetch('https://now-api.funda.kr/api/users/password-reset/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        token,
        new_password: newPassword,
        confirm_password: newPassword
      })
    }).then(response => {
      if (!response.ok) {
        throw new Error('비밀번호 변경에 실패했습니다')
      }
      return response
    })
  },

  sendContractSms(input) {
    return client.call({
      uri: '/graphql',
      method: 'POST',
      data: {
        query: `mutation($input: SendSmsToUserInput!) {
          data: sendSmsToUser(sendSmsToUserInput: $input)
        }`,
        variables: {
          input
        }
      }
    })
  },

  getLoginToken(userId) {
    return client.call({
      uri: '/graphql',
      method: 'POST',
      data: {
        query: `mutation($input: GetLoginTokenInput!) {
          data: getLoginToken(getLoginTokenInput: $input)
        }`,
        variables: {
          input: {
            userId
          }
        }
      }
    })
  },

  sendBulkSms(input) {
    return client.call({
      uri: '/graphql',
      method: 'POST',
      data: {
        query: `mutation($input: SendSmsToUserByIdsInput!) {
          data: sendSmsToUserByIds(sendSmsToUserByIdsInput: $input)
        }`,
        variables: { input }
      }
    })
  },

  getCustomerFeeSetting(customerId) {
    return client.call({
      uri: '/graphql',
      method: 'POST',
      data: {
        query: `query($input: Int!) {
          data: customerFeeSettingByOne(customerId: $input) {
            id
            appliedDate
            feeType
            feeValue
            interestRate
            transferFeeValue
          }
        }`,
        variables: {
          input: customerId
        }
      }
    })
  },

  createCustomerFeeSetting(input) {
    return client.call({
      uri: '/graphql',
      method: 'POST',
      data: {
        query: `mutation($input: CreateMyfinbizCustomerFeeSettingInput!) {
          createCustomerFeeSetting(createMyfinbizCustomerFeeSettingInput: $input) {
            id
          }
        }`,
        variables: { input }
      }
    })
  },

  updateAgreementExpireDate(input) {
    return client.call({
      uri: '/graphql',
      method: 'POST',
      data: {
        query: `mutation updateExpireDate($id: Int!, $expireDate: String!) {
          updateExpireDate(id: $id, expireDate: $expireDate) {
            id
            expireDate
          }
        }`,
        variables: input,
      }
    })
  },
}
