import client from '../client'
import qs from 'query-string'

export default {
  /**
   * 실 계좌주 조회
   * @param {
   *   bankCode : 은행코드
   *   accountNo : 계좌번호
   * } param
   */
  getAccountInfo(param) {
    return client.call({
      url: `/account/info?${qs.stringify(param)}`,
      method: HttpMethod.GET,
    })
  },
}
