<template>
  <v-dialog v-model="dialog" persistent width="600">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        class="font-size--16"
        block
        large
        v-bind="attrs"
        v-on="on"
      >
        시스템 계좌 등록
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="py-1 headline f-light-grey">
        시스템 계좌 등록
        <v-spacer></v-spacer>
        <v-btn
          color="black"
          text
          icon
          x-large
          :disabled="isLoading"
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pt-6">
        <v-card-text>
          <VirtualAccountAutocomplete
            dense
            :key="dialog"
            :propsAccount="vAccount"
            :propsIsLoading="isLoading"
            @select="selectAccount"
          />
        </v-card-text>
        <v-slide-y-transition>
          <v-card-text v-if="vAccount">
            <VirtualAccount dense :vAccountData="vAccount" />
            <ValidationObserver
              ref="validForm"
              v-slot="{ handleSubmit, invalid }"
            >
              <form @submit.prevent="handleSubmit(submit)">
                <v-row>
                  <v-col>
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="label"
                      rules="required|min:2|max:100"
                    >
                      <v-text-field
                        label="label(시스템 유저 이름으로 사용될 계좌 별칭)"
                        placeholder="시스템 유저 이름으로 사용될 계좌 별칭"
                        v-model="label"
                        :hide-details="errors.length == 0"
                        :error-messages="errors"
                        :disabled="isLoading"
                      />
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" lg="6" md="6" sm="6">
                    <v-btn
                      color="error"
                      block
                      large
                      :disabled="isLoading"
                      type="button"
                      @click="close"
                    >
                      취소하기
                    </v-btn>
                  </v-col>
                  <v-col cols="12" lg="6" md="6" sm="6">
                    <v-btn
                      color="primary"
                      block
                      large
                      :disabled="invalid || isLoading"
                      type="submit"
                    >
                      등록하기
                    </v-btn>
                  </v-col>
                </v-row>
              </form>
            </ValidationObserver>
          </v-card-text>
        </v-slide-y-transition>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import VirtualAccount from '../user/VirtualAccount.vue'
import VirtualAccountAutocomplete from '../user/VirtualAccountAutocomplete.vue'
export default {
  components: { VirtualAccountAutocomplete, VirtualAccount },
  data() {
    return {
      dialog: false,
      vAccount: null,
      isLoading: false,
      label: null,
    }
  },
  methods: {
    selectAccount(accountData) {
      this.vAccount = accountData
      this.label = accountData ? accountData.label : null
    },
    close() {
      this.label = null
      this.vAccount = null
      this.isLoading = false
      this.dialog = false
    },
    async submit() {
      if (this.isLoading === true || this.vAccount == null) {
        return
      }

      try {
        this.isLoading = true

        let btnResult = await this.$swal.basic.confirm({
          title: '시스템 계정 등록',
          text: '등록하시겠습니까?',
          focusConfirm: true,
        })

        if (btnResult.isConfirmed !== true) {
          return
        }

        await this.$paymentApi.postSystemUserVirtualAccount(this.vAccount.idx, {
          label: this.label,
        })

        await this.$swal.basic.alert({
          title: '등록 완료',
          text: '등록 완료되었습니다.',
        })

        this.$emit('success')
        this.close()
      } catch (e) {
        if (e.data && e.data.errors) {
          this.$swal.basic.error({
            title: '등록 실패',
            text: e.data.errors[0].message,
          })
        } else {
          throw e
        }
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
