export default {
  name: 'time',
  namespaced: true,
  state: {
    KR_TIME_DIFF: 9 * 60 * 60 * 1000,
    current: Date.now(),
  },
  getters: {
    getNow(state) {
      return state.current
    },
    getKrTimeDiff(state) {
      return state.KR_TIME_DIFF
    },
  },
  mutations: {
    refresh(state) {
      state.current = Date.now()
    },
  },
  actions: {
    refresh({ commit }) {
      commit('refresh')
    },
    getKrDate({ getters }) {
      const curr = new Date()
      const offset = curr.getTimezoneOffset() * 60 * 1000
      const utc = curr.getTime() + offset
      const krDate = new Date(utc + getters.getKrTimeDiff)
      return krDate
    },
  },
}
