<template>
  <div class="px-3" style="padding-bottom: 100px">
    <PageHeader title="현금영수증" />
    <v-row no-gutters>
      <v-col cols="12">
        <v-card class="my-3" width="100%">
          <v-card-text>
            <v-row no-gutters>
              <v-col xl="1" lg="2">
                <v-select
                  v-model="req.isIssued"
                  :items="issuedSelector"
                  item-text="text"
                  item-value="value"
                  label="발행여부"
                  outlined
                  dense
                  hide-details
                  class="mr-3"
                ></v-select>
              </v-col>
              <v-col xl="2" lg="2">
                <v-text-field
                  dense
                  hide-details
                  clearable
                  outlined
                  append-icon="mdi-magnify"
                  @keyup.enter="keyword"
                  label="아이디, 상품호수 등"
                ></v-text-field>
              </v-col>
              <v-col xl="2" class="pl-3">
                <v-btn
                  :loading="isLoading"
                  :disabled="isLoading"
                  @click="openBox()"
                >
                  문서함열기
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="3">
        <v-date-picker v-model="picker" show-current></v-date-picker>
      </v-col>
      <v-col cols="9">
        <UserList :get-first="false" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import UserList from '@/components/funda/user/List'

export default {
  components: {
    UserList,
  },
  data() {
    return {
      isLoading: false,
      req: {
        transactDate: '',
        fundingDataIdx: null,
        userIdx: null,
        isIssued: null,
      },
      issuedSelector: [
        { text: '전체', value: null },
        { text: '미발행', value: false },
        { text: '발행', value: true },
      ],
      keyword: null,
      picker: new Date().toISOString().substr(0, 10),
      repaymentData: null,
      boxName: 'PBOX',
    }
  },
  methods: {
    async openBox() {
      this.isLoading = true

      try {
        let url = await this.$fundaApi.getBoxUrl(this.boxName)

        console.log(url)

        window.open(url, '_blank')
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
