import './api'
import './colors'
import './components'
import './sweetalert2'
import './compositionApi'
import './moment'
import './sentry'
import './vueMeta'
import './vueCookies'
import vuetify from './vuetify'

export default {
  vuetify,
}
