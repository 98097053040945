import client from '../fundaApiClient'

// signin만 별도로 운영
export default {
  getBoxUrl(boxName) {
    return client.call({
      url: `/cash/receipt/box?boxName=${boxName}`,
      method: 'GET',
    })
  },
}
