<template>
  <div
    class="custom-popup invest-list-notice-popup"
    :class="{ show: showPopup }"
  >
    <div class="custom-popup__background" @click="showToggle()"></div>
    <div class="invest-list-notice-popup__contents overflow-hidden">
      <div
        class="
          popup-header
          d-flex
          justify-space-between
          align-center
          padding-x-25
          margin-t-30
        "
      >
        <h2 class="font-size--20 font-weight-black my-0">연체&#47;부실 안내</h2>
        <a @click="showToggle()" class="cursor--pointer">
          <img src="@/assets/images/myfunda/btn-close.svg" />
        </a>
      </div>
      <div
        v-html="content"
        class="
          invest-list-notice-popup__text
          margin-t-20
          padding-x-25
          overflow-y--scroll
          margin-b-25
        "
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showPopup: false,
    }
  },
  methods: {
    showToggle() {
      this.showPopup = !this.showPopup
      console.log(this.showPopup ? 'open' : 'close')
    },
  },
}
</script>

<style lang="scss" scoped>
.invest-list-notice-popup {
  .invest-list-notice-popup {
    &__contents {
      position: fixed;
      max-width: 600px;
      width: 100%;
      top: 10vh;
      left: 0;
      right: 0;
      margin: 0 auto;
      border-radius: 8px;
      background-color: white;
      z-index: 999;
      animation: moveDown 0.7s;
    }

    &__text {
      max-height: 502px;
    }
  }

  &.mobile {
    .invest-list-notice-popup {
      &__contents {
        max-width: 325px;
      }

      &__text {
        max-height: 400px;
      }
    }
  }
}
</style>
